import { IQueryOption } from './types'

export const TABLE_RESET_SEARCH = '?page=1&sort=updatedAt&sortOrder=DESC'

export const isArchived = (defaultValue: boolean | null = false): IQueryOption<boolean | null> => ({
  default: defaultValue,
  toValue: (value) => {
    if (value === 'true') {
      return true
    }

    if (value === 'false') {
      return false
    }

    if (value === null) {
      return null
    }

    return false
  },
})

export const DEFAULT_PAGE_SIZE = 25
