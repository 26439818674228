// extracted by mini-css-extract-plugin
var _1 = "Divider_dark__e5QG1";
var _2 = "Divider_dash__pgwx0";
var _3 = "Divider_free__nMap7";
var _4 = "Divider_horizontal__mg931";
var _5 = "Divider_large__BFrZ8";
var _6 = "Divider_medium__AC73z";
var _7 = "Divider_mini__2tGHJ";
var _8 = "Divider_vertical__c-VLm";
var _9 = "Divider_xmini__QoQlY";
export { _1 as "dark", _2 as "dash", _3 as "free", _4 as "horizontal", _5 as "large", _6 as "medium", _7 as "mini", _8 as "vertical", _9 as "xmini" }
