import { CodeSchemaType } from '@vedalib/editor/lib/brand'
import { CONFIG_ELEMENTS, CodeElementValue } from '@vedalib/editor/lib/elements'
import { LanguagesEnum } from '@vedalib/editor/lib/elements/elements/Code/constants'

import { DEMO_MODE } from 'services/Store/Project/constants'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './CodeElement'

const DEMO_VALUE: CodeElementValue = {
  code: t('elements.code.value.code'),
  language: LanguagesEnum.html,
}

const CodeDemo: React.FC<IElementDemoProps<CodeSchemaType>> = ({ styles, fonts }) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.code.type}-demo`,
        type: CONFIG_ELEMENTS.code.type,
        value: DEMO_VALUE,
        style: {},
        font: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

export default CodeDemo
