import {
  SectionTypeEnum,
  CourseStructureElementValue,
  CollapseIconPosition,
  ProgressIconPosition,
} from '@vedalib/editor/lib/elements'

export const getShowIcon = (type: SectionTypeEnum, value: CourseStructureElementValue) =>
  ({
    [SectionTypeEnum.chapter]: value.showChapterIcon,
    [SectionTypeEnum.landing]: value.showLandingIcon,
    [SectionTypeEnum.test]: value.showTestIcon,
    [SectionTypeEnum.cover]: false,
  })[type]

export const getGridTemplateStyle = (value: CourseStructureElementValue, showIcon?: boolean) => {
  let gridTemplateColumnsValue = ''
  const topRow: string[] = []
  const bottomRow: string[] = []
  const progressValue = 'minmax(min-content, max-content) '
  const iconValue = 'minmax(min-content, max-content) '
  const sectionNameValue = 'minmax(min-content, auto) '

  if (value.collapsePosition === CollapseIconPosition.leftOutside) {
    topRow.push('collapse')
    bottomRow.push('.')
    gridTemplateColumnsValue += iconValue
  }
  if (value.progressPosition === ProgressIconPosition.left) {
    topRow.push('progress')
    bottomRow.push('line')
    gridTemplateColumnsValue += progressValue
  }
  if (value.collapsePosition === CollapseIconPosition.left) {
    topRow.push('collapse')
    bottomRow.push('.')
    gridTemplateColumnsValue += iconValue
  }

  if (showIcon) {
    topRow.push('icon')
    bottomRow.push('.')
    gridTemplateColumnsValue += iconValue
  }

  topRow.push('section')
  bottomRow.push('description')
  gridTemplateColumnsValue += sectionNameValue

  if (value.collapsePosition === CollapseIconPosition.right) {
    topRow.push('collapse')
    bottomRow.push('.')
    gridTemplateColumnsValue += iconValue
  }
  if (value.progressPosition === ProgressIconPosition.right) {
    topRow.push('progress')
    bottomRow.push('line')
    gridTemplateColumnsValue += progressValue
  }
  if (value.collapsePosition === CollapseIconPosition.rightOutside) {
    topRow.push('collapse')
    bottomRow.push('.')
    gridTemplateColumnsValue += iconValue
  }

  const preparedTemplateAreas = [topRow, bottomRow]

  const getGridTemplateAreas = (gridLayout: string[][]) =>
    gridLayout.map((row) => `"${row.join(' ')}"`).join(' ')

  const gridTemplateAreasValue = getGridTemplateAreas(preparedTemplateAreas)

  return {
    gridTemplateAreas: gridTemplateAreasValue,
    gridTemplateColumns: gridTemplateColumnsValue.trim(),
  }
}
