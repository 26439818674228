// extracted by mini-css-extract-plugin
var _1 = "CourseSidebar_buttonBack__XQxHY";
var _2 = "CourseSidebar_content__L1DKq";
var _3 = "CourseSidebar_defaultStructure__cyP8J";
var _4 = "CourseSidebar_headerTitle__lCTbz";
var _5 = "CourseSidebar_headerTitleWrapper__3US4x";
var _6 = "CourseSidebar_progress__6dO7s";
var _7 = "CourseSidebar_resetOffset__zC5dw";
var _8 = "CourseSidebar_reverseProgress__KgZFr";
var _9 = "CourseSidebar_root__7qowD";
var _a = "CourseSidebar_row__T-9Af";
var _b = "CourseSidebar_searchWrapper__x5sI5";
export { _1 as "buttonBack", _2 as "content", _3 as "defaultStructure", _4 as "headerTitle", _5 as "headerTitleWrapper", _6 as "progress", _7 as "resetOffset", _8 as "reverseProgress", _9 as "root", _a as "row", _b as "searchWrapper" }
