import { useSubscription } from '@apollo/client'
import { useMemo, useState } from 'react'

import {
  FileMetaFor,
  FileMetaGroupsSearch,
  FileMetaTypeFilterEnum,
} from 'gql/__generated__/graphql'
import {
  IFileMetaGroupAllProps,
  useFileMetaGroupAll,
  useFileMetaGroupSubscription,
  useFilesSubscription,
} from 'gql/files/apollo'
import { fileMetaGroupSubscription, filesSubscription } from 'gql/files/gql/subscriptions'
import { FinderItem } from 'store/models/entry.model'

import { getIdFileMeta, prepareFinderItem } from './useFinder'

interface IParams {
  companyId: string
  parentId: string
  projectId?: string
}

const LIMIT = 50

export const useFinderInfinityLoad = (
  params: IParams,
  search: Partial<FileMetaGroupsSearch>,
  onRefetch: () => void,
  isInfiniteLoad: boolean = true,
) => {
  const { companyId, projectId, parentId } = params
  const [chunkLoading, setChunkLoading] = useState(false)

  const subscriptionVariables = useMemo(
    () => ({ companyId, parentId, projectId }),
    [companyId, projectId, parentId],
  )

  const searchVariables = useMemo<IFileMetaGroupAllProps>(
    () => ({
      companyId,
      parentId,
      search: {
        id:
          getIdFileMeta(params, search.fileMetaType as unknown as FileMetaFor) || params.companyId,
        fileMetaType: search.fileMetaType || FileMetaTypeFilterEnum.companies,
        pagination: { offset: 0, limit: LIMIT },
        ...search,
      },
      isInfiniteLoad,
    }),
    [companyId, params, parentId, search, isInfiniteLoad],
  )

  // "data" different between response and cache data because fileMetaMerge src/gql/__utils__/merge.ts
  const { data, loading, fetchMore, error, refetch } = useFileMetaGroupAll(searchVariables)
  const onData = () => {
    if (isInfiniteLoad) {
      refetch()
    }

    onRefetch()
  }

  useSubscription(filesSubscription, { onData, variables: subscriptionVariables })

  useSubscription(fileMetaGroupSubscription, { onData, variables: subscriptionVariables })

  const finderData: FinderItem[] = useMemo(
    () =>
      [...(data?.data.groups || []), ...(data?.data.fileMetas || [])].map((item) =>
        prepareFinderItem(item, search.query || ''),
      ),
    [data?.data.fileMetas, data?.data.groups, search.query],
  )

  const breadcrumbs = data?.breadcrumb?.breadcrumbs || []

  const total = data?.data?.total || 0

  const fetchNextChunk = async () => {
    setChunkLoading(true)
    const res = await fetchMore({
      variables: {
        search: {
          ...searchVariables.search,
          pagination: {
            ...searchVariables.search?.pagination,
            offset: finderData.length,
          },
        },
      },
    })
    setChunkLoading(false)
    return res
  }

  useFilesSubscription({
    companyId,
    parentId,
    search: searchVariables.search,
    projectId,
  })

  useFileMetaGroupSubscription({
    companyId,
    parentId,
    search: searchVariables.search,
    projectId,
  })

  return {
    data: finderData,
    loading,
    chunkLoading,
    breadcrumbs,
    error,
    total,
    fetchNextChunk,
  }
}
