import { useEffect } from 'react'

import { completeElement } from 'services/Store/Project/actions'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getIsInteractiveMode } from 'services/Store/Project/selectors'

export const useElementCompleted = (blockId: string, elementId: string, completed: boolean) => {
  const dispatch = useProjectDispatch()
  const isInteractive = useProjectContext(getIsInteractiveMode)

  useEffect(() => {
    if (completed && isInteractive) {
      dispatch(completeElement({ elementId, blockId, incomplete: false }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed])
}
