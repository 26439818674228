import Icon, { KeyIconsType } from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { ISelectLabelProps } from 'components/uiKit/Select'

import * as s from './OptionLabelWithIcon.module.scss'

interface IOptionLabelWithIconProps {
  icon: KeyIconsType
  label: string
}

const createOptionLabelWithIcon = ({ icon, label }: IOptionLabelWithIconProps) => {
  const OptionLabelWithIcon = ({ type }: ISelectLabelProps) => {
    return (
      <div className={s.root}>
        {type !== 'root' && (
          <div className={s.iconButton}>
            <Icon name={icon} size={KitSize.S} />
          </div>
        )}
        <div>{label}</div>
      </div>
    )
  }
  return OptionLabelWithIcon
}

export default createOptionLabelWithIcon
