import cn from 'classnames'
import { Link as ReactRouterLink, LinkProps } from 'react-router-dom'

import { testProps } from 'utils/test/qaData'

import * as s from './Link.module.scss'
import { isExternalLink } from './utils'

const TEST_ELEMENT_LINK = 'link'

interface ILinkPropsKit extends LinkProps {
  name: string
  children: React.ReactNode
  disabled?: boolean
  fluid?: boolean
  styleType?: 'base' | 'free'
  testData?: string
}

const Link: React.FC<ILinkPropsKit> = ({
  children,
  name,
  disabled,
  fluid,
  styleType = 'base',
  testData,
  className,
  ...rest
}) => {
  const testPropsLink = testProps({ el: TEST_ELEMENT_LINK, name, testData })
  const cnRoot = cn(s.root, className, s[styleType], { [s.fluid]: fluid, [s.disabled]: disabled })

  if (disabled || !rest.to) {
    return (
      <span {...testPropsLink} {...rest} className={cnRoot}>
        {children}
      </span>
    )
  }

  const { externalLink, path } = isExternalLink(rest.to)

  if (externalLink) {
    const { to, ...props } = rest
    return (
      <a {...testPropsLink} {...props} className={cnRoot} href={path}>
        {children}
      </a>
    )
  }

  return (
    <ReactRouterLink
      {...testPropsLink}
      {...rest}
      // eslint-disable-next-line react/forbid-component-props
      className={cnRoot}
    >
      {children}
    </ReactRouterLink>
  )
}

export default Link
