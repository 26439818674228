import InputNumber, { IInputNumberProps } from 'components/uiKit/InputNumber'
import { KitSize } from 'components/uiKit/KitTypes'

import { IArrayOption } from '../FieldNumber'
import * as s from './InputNumberArray.module.scss'

interface IFieldMarginProps extends Omit<IInputNumberProps, 'value' | 'onChange' | 'defaultValue'> {
  array: IArrayOption[]
  value?: (number | undefined)[]
  onChange: (value?: (number | undefined)[]) => void
  defaultValue?: (number | undefined)[]
  size?: KitSize
  onBlur?: () => void
  onFocus?: () => void
  name: string
}

const InputNumberArray: React.FC<IFieldMarginProps> = ({
  onChange,
  onBlur,
  onFocus,
  array,
  size,
  value,
  defaultValue,
  name,
  ...rest
}) => {
  const onChangeIndex = (index: number) => (inputValue?: number) => {
    const updatedValues = array.map((_option, i) => {
      if (i === index) {
        return inputValue ?? rest.min ?? undefined
      }

      return (value ?? defaultValue)?.[i] ?? rest.min ?? undefined
    })

    onChange(updatedValues)
  }

  return (
    <div className={s.inputWrapper}>
      {array.map((val, i) => (
        <div className={s.item} key={i}>
          <InputNumber
            {...rest}
            name={`${name}.${i}`}
            onBlur={onBlur}
            onChange={onChangeIndex(i)}
            onFocus={onFocus}
            size={size}
            value={value?.[i]}
            isInteger
          />
          <div className={s.icon}>{val.icon}</div>
        </div>
      ))}
    </div>
  )
}

export default InputNumberArray
