import { IconsAlignEnum } from '@vedalib/editor/lib/brand'

import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'

export const ICON_ALIGN_OPTIONS = [
  { value: IconsAlignEnum.hide, label: <Icon name='otherBlock' size={KitSize.S} /> },
  { value: IconsAlignEnum.leftUp, label: <Icon name='builderLeftUp' size={KitSize.S} /> },
  { value: IconsAlignEnum.rightUp, label: <Icon name='builderRightUp' size={KitSize.S} /> },
  { value: IconsAlignEnum.leftDown, label: <Icon name='builderLeftDown' size={KitSize.S} /> },
  { value: IconsAlignEnum.rightDown, label: <Icon name='builderRightDown' size={KitSize.S} /> },
]
