import { DragDropContext, Droppable, Draggable, DragStart, DropResult } from '@hello-pangea/dnd'
import { textToRtValue } from '@vedalib/rich-text'
import { useSize } from 'ahooks'
import { produce } from 'immer'
import { useRef } from 'react'
import { useContext } from 'use-context-selector'
import { swapItems } from 'utils'

import { TestAnswerFeedback } from 'components/editor-v2/EditorElements/decorators/TestAnswerFeedback/TestAnswerFeedback'
import { RichTextViewer } from 'components/form/RichText/RichTextViewer'
import { setQuestion } from 'services/Store/Project/actions'
import { useProjectDispatch } from 'services/Store/Project/hooks'

import { useGetSize } from '../../helper'
import TestMatchingContext from '../TestMatchingContext'
import * as s from './DefaultElementPreview.module.scss'
import PortalItem from './MatchingItem'

const DefaultElementPreview: React.FC = () => {
  const {
    setDraggableId,
    right,
    left,
    elementId,
    block,
    validOrderMap,
    styles,
    isVisible,
    isDisabled,
    value,
    mode,
    font,
  } = useContext(TestMatchingContext)
  const dispatch = useProjectDispatch()
  const ref = useRef<HTMLDivElement>(null)
  const state = useSize(ref) || { width: 1, height: 1 }
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const paddingsArr = (styles.indents.padding as string).split(' ')
  const paddings = parseInt(paddingsArr[0]) + parseInt(paddingsArr[2])
  const { width, height } = useGetSize(state, wrapperRef, value.items, paddings)

  const onBeforeDragStart = (initial: DragStart) => setDraggableId(initial.draggableId)

  const onDragEnd = (result: DropResult) => {
    setDraggableId(undefined)
    if (!result.destination) {
      return
    }

    const destIndex = right.findIndex((e) => e.value === result.destination?.droppableId)

    let newLeftItems = produce(left, (draft) => {
      if (result.destination) {
        const destEl = draft[destIndex]
        if (destEl) {
          destEl.isActive = false
        }

        const sourceEl = draft[result.source.index]
        if (sourceEl) {
          sourceEl.isActive = true
        }
      }
    })

    newLeftItems = swapItems(newLeftItems, result.source.index, destIndex)
    dispatch(
      setQuestion({
        elementId,
        value: {
          value: newLeftItems.map((item) => item.value),
          shuffledItems: newLeftItems,
        },
        isReady: newLeftItems.every(({ isActive }) => isActive),
        blockId: block?.uuid || '',
      }),
    )
  }

  return (
    <DragDropContext onBeforeDragStart={onBeforeDragStart} onDragEnd={onDragEnd}>
      <div ref={wrapperRef}>
        <div className={s.matchingElement} ref={ref}>
          {left.map((leftItem, index) => {
            const rightItem = right[index]
            return (
              <div
                key={index}
                style={{
                  height,
                  marginBottom:
                    value.items.length - 1 !== index ? styles.indents.marginBottom : undefined,
                }}
              >
                <TestAnswerFeedback
                  deviceMode={mode.deviceMode}
                  isTrueAnswer={validOrderMap[leftItem?.value] === index}
                  isVisible={!!isVisible}
                  showAnswerForCurrentElement={!!isVisible}
                >
                  <div className={s.row}>
                    <div className={s.item}>
                      <Droppable
                        direction='horizontal'
                        droppableId={'source' + index}
                        key={leftItem?.value}
                        isDropDisabled
                      >
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.droppableProps}>
                            <Draggable
                              draggableId={leftItem?.value}
                              index={index}
                              isDragDisabled={isDisabled}
                              key={leftItem?.value}
                            >
                              {(provided, snapshot) => (
                                <PortalItem
                                  font={font}
                                  height={height}
                                  index={index}
                                  isActive={leftItem?.isActive}
                                  isDragged={snapshot.isDragging}
                                  mode='left'
                                  provided={provided}
                                  snapshot={snapshot}
                                  styles={styles}
                                  width={width}
                                >
                                  <div className={s.text}>
                                    <RichTextViewer
                                      styles={font}
                                      value={leftItem?.label || textToRtValue('')}
                                    />
                                  </div>
                                </PortalItem>
                              )}
                            </Draggable>
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                    <div style={{ flex: '0 0 24px' }} />
                    <div className={s.item}>
                      <Droppable
                        direction='horizontal'
                        droppableId={rightItem?.value}
                        isDropDisabled={isDisabled}
                        key={rightItem?.value}
                      >
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.droppableProps}>
                            <PortalItem
                              font={font}
                              height={height}
                              index={index}
                              isActive={rightItem?.isActive}
                              mode='right'
                              styles={styles}
                            >
                              <div className={s.text}>
                                <RichTextViewer
                                  styles={font}
                                  value={rightItem?.label || textToRtValue('')}
                                />
                              </div>
                            </PortalItem>
                          </div>
                        )}
                      </Droppable>
                    </div>
                  </div>
                </TestAnswerFeedback>
              </div>
            )
          })}
        </div>
      </div>
    </DragDropContext>
  )
}

export default DefaultElementPreview
