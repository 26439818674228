import { Timeout } from 'ahooks/lib/useRequest/src/types'
import cn from 'classnames'
import { produce } from 'immer'
import * as R from 'ramda'
import { useRef, useState } from 'react'
import { useContext } from 'use-context-selector'

import GroupingContext from '../../GroupingContext'
import Result from '../Result'
import Answer from './Answer'
import Group from './Group'
import * as s from './MobileElement.module.scss'

const MobileElement = () => {
  const {
    styles,
    hasGroupImage,
    testResult,
    shuffledAnswers,
    validationMap,
    destination,
    setDestination,
    setShuffledAnswers,
  } = useContext(GroupingContext)
  const groupWrapperClasses = cn(s.groups, { [s.tableView]: hasGroupImage })
  const [activeId, setActiveId] = useState('')
  const ref = useRef<number | null | Timeout>()

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!activeId && shuffledAnswers.length) {
      if (ref.current) {
        clearTimeout(ref.current as number)
      }

      const destId = (e.target as Element).id
      setActiveId(destId)

      const answer = shuffledAnswers[shuffledAnswers.length - 1]?.value
      testResult[answer] = validationMap[answer] === destId
      const newDest = produce(destination, (draft) => {
        const dest = draft.find((e) => e.value === destId)
        dest?.items.push(shuffledAnswers[shuffledAnswers.length - 1])
      })

      setDestination(newDest)
      const shuffled = shuffledAnswers.filter((e) => e.value !== answer)

      ref.current = setTimeout(() => {
        setActiveId('')
        setShuffledAnswers(shuffled)
      }, 1000)
    }
  }

  return (
    <div className={s.root}>
      {shuffledAnswers.length === 0 && <Result />}
      {shuffledAnswers.length > 0 && (
        <div className={s.answers}>
          {shuffledAnswers.map((item) => (
            <Answer item={item} key={item.value} />
          ))}
        </div>
      )}
      <div
        className={groupWrapperClasses}
        onClick={onClick}
        style={{ gap: styles.indents.gap, marginTop: styles.indents.marginTop }}
      >
        {destination.map((item) => (
          <Group
            activeId={activeId}
            group={item}
            isCorrect={R.last(R.values(testResult)) as boolean}
            key={item.value}
          />
        ))}
      </div>
    </div>
  )
}

export default MobileElement
