import {
  TestKeyboardInputFontSchemaType,
  TestKeyboardInputSchemaType,
} from '@vedalib/editor/lib/brand'
import styled from 'styled-components'

import { font } from 'services/Branding/common.styled'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'

const InputStyled = styled.input<{
  $styles: ElementStyleCss<TestKeyboardInputSchemaType>
  $fonts: ElementFontCss<TestKeyboardInputFontSchemaType>
}>`
  width: 100%;
  border-radius: ${({ $styles }) => $styles.input.borderRadius};
  border-width: ${({ $styles }) => $styles.input.borderWidth};
  border-color: ${({ $styles }) => $styles.input.borderColor};
  border-style: ${({ $styles }) => $styles.input.borderStyle};
  padding: ${({ $styles }) => $styles.input.padding};
  background-color: ${({ $styles }) => $styles.input.backgroundColor};
  color: ${({ $fonts }) => $fonts.base.color};
  box-shadow: ${({ $styles }) => $styles?.effects?.boxShadow};
  outline: none;
  ${({ $fonts }) => font($fonts.base)}

  &[disabled] {
    background-color: var(--brand-inactiveColor);
    cursor: not-allowed;
  }
  &:hover {
    background-color: ${({ $styles }) => $styles.input_hover.backgroundColor};
  }
  &:focus {
    background-color: ${({ $styles }) => $styles.input_focus.backgroundColor};
  }
  &::placeholder {
    ${({ $fonts }) => font($fonts.placeholder)}
  }
`

export default InputStyled
