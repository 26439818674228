import { CONFIG_ELEMENTS } from '@vedalib/editor/lib/elements'

import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './TestMultipleChoiceBrandFormSchema'
import Demo from './TestMultipleChoiceDemo'
import TestMultipleChoice from './TestMultipleChoiceEditorActions'
import Element from './TestMultipleChoiceElement'
import Form from './TestMultipleChoiceForm'
import testMultipleChoiceIcon from './assets/testMultipleChoice.svg'

export default genElementConfig(CONFIG_ELEMENTS.testMultipleChoice, {
  label: t('elements.testMultipleChoice.label'),
  icon: testMultipleChoiceIcon,
  group: ElementGroup.test,
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  EditorActions: TestMultipleChoice,
  Demo,
})
