import { SCHEMA } from '@vedalib/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.testKeyboardInput.style, {
  input: {
    META: { hidden: true },
    backgroundColor: {},
    borderRadius: {},
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
    padding: { label: t('elements.testKeyboardInput.schema.padding') },
  },
  input_hover: {
    META: { hidden: true },
    backgroundColor: {
      label: t('style.hoverColor'),
      params: {
        resettable: true,
      },
    },
  },
  input_focus: {
    META: { hidden: true },
    backgroundColor: {
      label: t('style.focusColor'),
      params: {
        resettable: true,
      },
    },
  },
  effects: {
    META: { label: t('style.effects') },
    boxShadow: { label: t('elements.testKeyboardInput.schema.boxShadow') },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.testKeyboardInput.font, {
  base: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.testKeyboardInput.tags.placeholder') },
  },
  placeholder: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.testKeyboardInput.tags.baseFont') },
  },
})
