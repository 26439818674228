import * as s from './EmptyContent.module.scss'
import EmptyOverlay from './empty.svg?react'

const EmptyContent = ({ textError }: { textError?: string }) => {
  return (
    <div className={s.root}>
      <EmptyOverlay />
      <span>{textError || 'not found'}</span>
    </div>
  )
}

export default EmptyContent
