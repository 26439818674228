import { CONFIG_ELEMENTS } from '@vedalib/editor/lib/elements'

import EditableProMode from 'components/editor-v3/cource/layout/controls/ElementControls/EditableProMode'
import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './CardBrandFormSchema'
import Demo from './CardDemo'
import Element from './CardElement'
import Form from './CardForm'
import cardIcon from './assets/card.svg'

export default genElementConfig(CONFIG_ELEMENTS.card, {
  label: t('elements.card.label'),
  icon: cardIcon,
  group: ElementGroup.interactive,
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  EditorActions: EditableProMode,
  Demo,
})
