import { useSize } from 'ahooks'
import React, { useState } from 'react'

import * as s from './Zoom.module.scss'

interface IZoomProps {
  onClose: () => void
  imagePath: string
  imageStyle: React.CSSProperties
  containerElement: HTMLDivElement | null
}

const calcScale = (containerSize?: number, imageSize?: number): number => {
  return (containerSize || 100) / (imageSize || 100)
}

const MAX_ZOOM_SCALE = 4

const Zoom: React.FC<IZoomProps> = ({ onClose, imagePath, containerElement, imageStyle }) => {
  const modalSize = useSize(containerElement)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [imageSize, setImageSize] = useState<any>({ height: 500, width: 500 })

  const onLoadImage = (e: React.SyntheticEvent<HTMLImageElement>) => {
    setImageSize({
      width: e.currentTarget.naturalWidth,
      height: e.currentTarget.naturalHeight,
    })
  }
  const xScale = calcScale(modalSize?.width, imageSize.width)
  const yScale = calcScale(modalSize?.height, imageSize.height)
  const scale = Math.min(xScale, yScale, MAX_ZOOM_SCALE)
  const style = {
    ...imageStyle,
    height: imageSize.height * scale,
    width: imageSize.width * scale,
  }
  return (
    <img className={s.image} onClick={onClose} onLoad={onLoadImage} src={imagePath} style={style} />
  )
}

export default Zoom
