import React from 'react'

import { KitSize } from 'components/uiKit/KitTypes'

import ColorCircle from '../ColorCircle'
import * as s from './ColorOption.module.scss'

interface IColorOptionProps {
  name?: string
  color?: string | null
}

const ColorOption: React.FC<IColorOptionProps> = ({ color, name }) => {
  return (
    <div className={s.root}>
      <ColorCircle color={String(color)} size={KitSize.XS} /> {name}
    </div>
  )
}

export default ColorOption
