// extracted by mini-css-extract-plugin
var _1 = "Tabs_activeTab__CGlty";
var _2 = "Tabs_arrowLeft__38xui";
var _3 = "Tabs_arrowRight__sT7ja";
var _4 = "Tabs_content__0IhFV";
var _5 = "Tabs_contentPosition__fzBTy";
var _6 = "Tabs_controls__p+oBf";
var _7 = "Tabs_grow__B96jR";
var _8 = "Tabs_isDrawArrow__sALDC";
var _9 = "Tabs_left__nl8od";
var _a = "Tabs_right__URkxG";
var _b = "Tabs_root__RYePy";
var _c = "Tabs_tabBarExtraContentGrow__ukaLc";
var _d = "Tabs_tabList__mqw4Y";
var _e = "Tabs_tabListWrap__g5YTe";
var _f = "Tabs_tabsGrow__bPHJd";
export { _1 as "activeTab", _2 as "arrowLeft", _3 as "arrowRight", _4 as "content", _5 as "contentPosition", _6 as "controls", _7 as "grow", _8 as "isDrawArrow", _9 as "left", _a as "right", _b as "root", _c as "tabBarExtraContentGrow", _d as "tabList", _e as "tabListWrap", _f as "tabsGrow" }
