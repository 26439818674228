import { NavButtonSchemaType } from '@vedalib/editor/lib/brand'

import { ElementStyleCss } from 'services/Branding/types'

export type NavButtonStyles =
  | ElementStyleCss<NavButtonSchemaType>['nextButton__var']
  | ElementStyleCss<NavButtonSchemaType>['prevButton__var']

export const getIconUrl = (buttonStyles: NavButtonStyles) => {
  return buttonStyles.icon ? buttonStyles.icon.slice(4, -1) : null
}
