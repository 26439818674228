import React from 'react'

import * as s from './DoneProgressComponent.module.scss'

interface IDoneProgressComponentProps {
  total: number
  done: number
  fail?: number
}

const DoneProgressComponent: React.FC<IDoneProgressComponentProps> = ({ total, done, fail }) => {
  const failPercent = ((fail || 0) / total) * 100
  const donePercent = (done / total) * 100

  return (
    <div className={s.root}>
      <div className={s.progress}>
        <div className={s.done} style={{ width: donePercent + '%' }} />
        <div className={s.fail} style={{ width: failPercent + '%' }} />
      </div>
      {done.toString()}/{total.toString()}
    </div>
  )
}
export default DoneProgressComponent
