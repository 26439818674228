import { SplitterSchemaType } from '@vedalib/editor/lib/brand'
import styled from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

import SplitterIconStyled from './SplitterIconStyled'

const SplitterStyled = styled.div<{ $styles: ElementStyleCss<SplitterSchemaType> }>`
  position: absolute;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: ew-resize;
  background-color: ${({ $styles }) => $styles.splitter.borderColor};
  &:hover ${SplitterIconStyled} {
    color: ${({ $styles }) => $styles.hover.backgroundColor};
  }
`

export default SplitterStyled
