import { SCHEMA } from '@vedalib/editor/lib/brand'
import { CONFIG_ELEMENTS } from '@vedalib/editor/lib/elements'

import EditableProMode from 'components/editor-v3/cource/layout/controls/ElementControls/EditableProMode'
import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import Demo from './RichTextDemo'
import Element from './RichTextElement'
import ValueForm from './RichTextForm'
import textIcon from './assets/richText.svg'

export default genElementConfig(CONFIG_ELEMENTS.richText, {
  label: t('elements.richText.label'),
  icon: textIcon,
  group: ElementGroup.view,
  Element,
  ValueForm,
  STYLE_FORM_SCHEMA: {},
  FONT_FORM_SCHEMA: genFormSchema(SCHEMA.richText.font, FONT_SCHEMA_FIELDS),
  EditorActions: EditableProMode,
  Demo,
})
