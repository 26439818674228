import {
  PositionTabsElementType,
  TabsSchemaType,
  ViewTabsElementType,
} from '@vedalib/editor/lib/brand'
import styled, { css } from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

import { DirectionTabsEnum } from '../types'
import { Z_INDEX_NAV_BUTTOS } from './TabElementStyled'
import { OVERFLOW_OFFSET } from './TabItemsScrollStyled'

const BUTTON_INDENT = 8

const prevIconVertical = (isSplitted: boolean) => css`
  right: 0;
  top: ${BUTTON_INDENT + (isSplitted ? OVERFLOW_OFFSET : 0)}px;
  bottom: auto;
`

const nextIconVertical = (isSplitted: boolean) => css`
  left: 0;
  bottom: ${BUTTON_INDENT + (isSplitted ? OVERFLOW_OFFSET : 0)}px;
  top: auto;
`

const prevIconHorizontal = (isSplitted: boolean) => css`
  left: ${BUTTON_INDENT + (isSplitted ? OVERFLOW_OFFSET : 0)}px;
`

const nextIconHorizontal = (isSplitted: boolean) => css`
  right: ${BUTTON_INDENT + (isSplitted ? OVERFLOW_OFFSET : 0)}px;
`

const buttonIconsPositionVertical = ($iconSide: DirectionTabsEnum, isSplitted: boolean) => css`
  width: 100%;
  height: 32px;
  ${$iconSide === DirectionTabsEnum.next && nextIconVertical(isSplitted)};
  ${$iconSide === DirectionTabsEnum.prev && prevIconVertical(isSplitted)};
`

const buttonIconsPositionHorizontal = ($iconSide: DirectionTabsEnum, isSplitted: boolean) => css`
  ${$iconSide === DirectionTabsEnum.prev && prevIconHorizontal(isSplitted)};
  ${$iconSide === DirectionTabsEnum.next && nextIconHorizontal(isSplitted)};
`

const NavButtonContainerStyled = styled.div<{
  $styles: ElementStyleCss<TabsSchemaType>
  $iconSide: DirectionTabsEnum
  $isDesktop: boolean
}>`
  position: absolute;
  display: ${({ $isDesktop }) => ($isDesktop ? 'none' : 'flex')}; // show in parent hover
  width: 32px;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 20px;
  cursor: pointer;
  pointer-events: all;
  z-index: ${Z_INDEX_NAV_BUTTOS};
  color: ${({ $styles }) => $styles.iconsColor.color};
  ${({ $iconSide, $styles }) =>
    ($styles.tabs.position as PositionTabsElementType) === PositionTabsElementType.top
      ? buttonIconsPositionHorizontal($iconSide, $styles.view.type === ViewTabsElementType.splitted)
      : buttonIconsPositionVertical($iconSide, $styles.view.type === ViewTabsElementType.splitted)};
`

export default NavButtonContainerStyled
