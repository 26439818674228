import { IItemProps } from '../../ListElement.types'
import * as s from './ListPoint.module.scss'

const ListPoint: React.FC<IItemProps> = ({ children, styles }) => (
  <div className={s.centerOfPoint} style={styles}>
    {children}
  </div>
)

export default ListPoint
