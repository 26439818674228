// extracted by mini-css-extract-plugin
var _1 = "Element_active__DyP8x";
var _2 = "Element_annotation__OSOso";
var _3 = "Element_annotationTitle__rtEMN";
var _4 = "Element_bulletedList__FCPqz";
var _5 = "Element_caption__YXpZj";
var _6 = "Element_close__uxSnH";
var _7 = "Element_code__Cnf-f";
var _8 = "Element_elementBlockquote__gf+wP";
var _9 = "Element_heading1__0fTMO";
var _a = "Element_heading2__dq73S";
var _b = "Element_heading3__UrzdC";
var _c = "Element_heading4__sPYbI";
var _d = "Element_link__-YaPJ";
var _e = "Element_listItem__r10PG";
var _f = "Element_mobile__0AAFF";
var _10 = "Element_numberedList__r59BI";
var _11 = "Element_pseudoSelection__dNwHM";
var _12 = "Element_textBold__ZKXiH";
var _13 = "Element_textItalic__iiQ7b";
var _14 = "Element_textStrikethrough__hDsQg";
var _15 = "Element_textUnderline__eu4r6";
export { _1 as "active", _2 as "annotation", _3 as "annotationTitle", _4 as "bulletedList", _5 as "caption", _6 as "close", _7 as "code", _8 as "elementBlockquote", _9 as "heading1", _a as "heading2", _b as "heading3", _c as "heading4", _d as "link", _e as "listItem", _f as "mobile", _10 as "numberedList", _11 as "pseudoSelection", _12 as "textBold", _13 as "textItalic", _14 as "textStrikethrough", _15 as "textUnderline" }
