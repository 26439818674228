import { SlateElementMark, SlateMark } from '@vedalib/rich-text'

import { ALL_RT_CONTROLS, SlateCommand } from 'components/form/RichText/RichTextConstants'
import {
  getUpdateFormat,
  getSelectedFormat,
  useRichTextContext,
} from 'components/form/RichText/useRichTextContext'
import Radio from 'components/uiKit/Radio'
import { t } from 'services/Translation'

import HideControl from '../../HideControl'
import { ALIGN_OPTIONS, REGISTER_OPTIONS, TRANSFORM_OPTIONS } from '../../toolbarOptions'
import RadioButtonTool from '../RadioButtonTool'
import * as s from './OtherSettings.module.scss'

const OtherSettings = () => {
  const selectedFormat = useRichTextContext(getSelectedFormat)
  const onUpdateFormat = useRichTextContext(getUpdateFormat)
  return (
    <div>
      <HideControl enabled={ALL_RT_CONTROLS} name='textAlign'>
        <div className={s.list}>
          <div className={s.titleList}>{t('input.label.align')}</div>
          <Radio
            direction='horizontal'
            name='textAlign'
            onChange={(value) => onUpdateFormat(SlateElementMark.textAlign, value)}
            options={ALIGN_OPTIONS}
            value={selectedFormat[SlateElementMark.textAlign]}
            hideRadio
          />
        </div>
      </HideControl>

      <HideControl enabled={ALL_RT_CONTROLS} name='textTransform'>
        <div className={s.list}>
          <div className={s.titleList}>{t('elements.richText.settings.register')}</div>
          <Radio
            direction='horizontal'
            fluid={false}
            name='textTransform'
            onChange={(value) => onUpdateFormat(SlateMark.textTransform, value)}
            options={TRANSFORM_OPTIONS}
            value={selectedFormat[SlateMark.textTransform]}
            hideRadio
          />
        </div>
      </HideControl>

      <HideControl enabled={ALL_RT_CONTROLS} name='insertSymbol'>
        <div className={s.list}>
          <div className={s.titleList}>{t('uiKit.tooltip.insertNpbs')}</div>
          <RadioButtonTool name={SlateCommand.insertNpbs} />
        </div>
      </HideControl>

      <HideControl enabled={ALL_RT_CONTROLS} name='register'>
        <div className={s.list}>
          <div className={s.titleList}>{t('input.label.view')}</div>
          <Radio
            direction='horizontal'
            fluid={false}
            name='register'
            onChange={(value) => onUpdateFormat(SlateMark.register, value)}
            options={REGISTER_OPTIONS}
            value={selectedFormat[SlateMark.register]}
            hideRadio
          />
        </div>
      </HideControl>
      <div className={s.delimiter} />
      <div className={s.list}>
        <div className={s.titleList}>{t('elements.richText.settings.reset')}</div>
        <RadioButtonTool name={SlateCommand.clear} />
      </div>
    </div>
  )
}

export default OtherSettings
