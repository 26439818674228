import { useKeyPress } from 'ahooks'
import * as R from 'ramda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import Dropdown from 'components/uiKit/Dropdown'
import { IKitControl, ValueType } from 'components/uiKit/KitTypes'
import { flatOptions } from 'components/uiKit/KitUtils'
import { EMPTY_ARRAY } from 'constants/commonConstans'

import { IMenuRef } from '../Menu'
import SelectContext, { ISelectContext } from './SelectContext'
import SelectList from './SelectList'
import SelectRoot from './SelectRoot'
import { SelectValueType, ISelectOption } from './SelectTypes'
import { filterOption, recursiveFilter, setFocusVisible, splitBy } from './SelectUtils'

export interface ISelectProps<V extends SelectValueType> extends IKitControl<V> {
  /**
   * The style type of the select component.
   */
  styleType?: 'base' | 'ghost'

  /**
   * Determines if the select component should take up the full width of its container.
   */
  fluid?: boolean

  /**
   * The placeholder text to display when no option is selected.
   */
  placeholder?: string

  /**
   * Determines if the select component should be automatically focused when rendered.
   */
  autoFocus?: boolean

  /**
   * Determines if the select component allows multiple selections.
   */
  isMultiple?: boolean

  /**
   * Determines if the select component allows manual input.
   */
  inputable?: boolean

  /**
   * Determines if the select component supports search functionality.
   */
  isSearch?: boolean

  /**
   * Determines if the select component allows resetting the selected value to `null`.
   */
  resettable?: boolean

  /**
   * Determines if the select component is in a loading state.
   */
  loading?: boolean

  /**
   * The options to be displayed in the select component.
   */
  options?: ISelectOption<V>[]

  /**
   * Determines if the select component has an error state.
   */
  error?: boolean

  /**
   * The type of the input element when `inputable` is `true`.
   */
  inputType?: 'text' | 'number'

  /**
   * The type of the dropdown component.
   */
  dropdownType?: 'dropdown' | 'popover'

  /**
   * The separators to be used for multiple values when `isMultiple` is `true`.
   */
  separators?: string[]

  /**
   * Determines if empty list should be hidden.
   */
  hideEmptyList?: boolean

  /**
   * The minimum width of the select component.
   */
  minWidth?: number

  /**
   * A function to validate each option in the select component.
   */
  optionValidator?: <V extends SelectValueType>(option: ISelectOption<V>) => boolean

  /**
   * The offset of the dropdown component.
   */
  offset?: [number, number]
}

const Select = <V extends SelectValueType>(props: ISelectProps<V>) => {
  const ref = useRef<HTMLDivElement>(null)
  const rootRef = useRef<HTMLInputElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const listRef = useRef<IMenuRef<ValueType>>(null)

  const { name, options, disabled, loading, readOnly, fluid, dropdownType, hideEmptyList } = props
  const { isMultiple, isSearch, value, separators, inputable, hidden, offset } = props
  const { onChange, onBlur, onFocus } = props

  const [isOpen, setIsOpen] = useState(false)
  const [forceSearch, setForceSearch] = useState<boolean>(false)
  const [focus, setFocus] = useState(false)
  const [inputValue, setInputValue] = useState(inputable && !isMultiple ? String(value || '') : '')

  const map = useMemo(() => R.indexBy((i) => String(i.value), flatOptions(options)), [options])
  const listOptions = useMemo(
    () =>
      isSearch && inputValue
        ? recursiveFilter(options, filterOption(inputValue))
        : options || EMPTY_ARRAY,
    [isSearch, inputValue, options],
  )
  const flattenOptions = useMemo(() => flatOptions(listOptions), [listOptions])
  const hideList = Boolean(hideEmptyList && !listOptions?.length)

  const handleMouseUp = () => setFocusVisible(rootRef.current, 'off')

  const handleChange = useCallback(
    (value: SelectValueType) => !disabled && !loading && onChange?.(value as V),
    [onChange, disabled, loading],
  )

  const handleFocus = useCallback(() => {
    if (!disabled) {
      onFocus?.()
      setFocus(true)
      inputRef.current?.focus()
      if (!focus) {
        setFocusVisible(rootRef.current, 'on')
      }
    }
  }, [disabled, focus, onFocus])

  const handleBlur = useCallback(() => {
    if (!disabled) {
      onBlur?.()
      setFocus(false)
      setIsOpen(false)
      setForceSearch(false)
      setFocusVisible(rootRef.current, 'off')

      if (isMultiple && separators) {
        const parsed = splitBy(inputValue, separators)
        const newValue = R.uniq([...((value || []) as []), ...parsed])
        handleChange?.(isMultiple ? newValue : inputValue.trim())
        setInputValue('')
      }
    }
  }, [disabled, handleChange, inputValue, isMultiple, onBlur, separators, value])

  const handleChooseOption = useCallback(
    (item: ISelectOption<V>) => {
      inputRef.current?.focus()
      setInputValue('')
      setForceSearch(false)
      if (!isMultiple && !item.disabled) {
        setInputValue?.(inputable ? String(item.value) : '')
        handleChange?.(item.value)
        setIsOpen(false)
      }
      if (isMultiple) {
        setInputValue?.('')
        if (Array.isArray(value)) {
          if (value.includes(item.value)) {
            handleChange?.(value.filter((v) => v !== item.value))
          } else if (!item.disabled) {
            handleChange?.([...value, item.value])
          }
        } else if (!item.disabled) {
          handleChange?.([item.value])
        }
      }
    },
    [handleChange, inputable, isMultiple, value],
  )

  const handleSeparate = useCallback(() => {
    if (inputValue.trim()) {
      setInputValue('')
      const inputtedValue = separators ? splitBy(inputValue, separators) : [inputValue.trim()]
      const newValue = R.uniq([...((value || []) as []), ...inputtedValue])
      handleChange?.(newValue)
    }
  }, [handleChange, inputValue, separators, value])

  useEffect(() => {
    if (!isMultiple && inputable) {
      setInputValue(String(value || ''))
    }
  }, [inputable, isMultiple, value])

  useKeyPress(['esc'], () => setIsOpen(false), { target: ref })

  useKeyPress(['enter', 'space', 'downarrow', 'uparrow'], () => setIsOpen(true), { target: ref })

  const data = useMemo(
    () => ({ map, listOptions, flattenOptions }),
    [map, listOptions, flattenOptions],
  )
  const state = useMemo(
    () => ({ isOpen, focus, forceSearch, inputValue, hideList }),
    [isOpen, focus, forceSearch, inputValue, hideList],
  )
  const actions = useMemo(() => ({ setIsOpen, setForceSearch, setInputValue }), [])
  const handlers = useMemo(
    () => ({ handleChange, handleChooseOption, handleBlur, handleFocus, handleSeparate }),
    [handleChange, handleChooseOption, handleBlur, handleFocus, handleSeparate],
  )
  const contextValue: ISelectContext<V> = useMemo(
    () => ({ ...props, ...state, ...data, actions, handlers, listRef, inputRef, rootRef, ref }),
    [props, data, state, actions, handlers],
  )

  if (hidden) {
    return null
  }

  return (
    <SelectContext.Provider value={contextValue as unknown as ISelectContext<SelectValueType>}>
      <Dropdown
        disabled={readOnly || disabled}
        fluid={fluid}
        name={`${name}Dropdown`}
        offset={offset}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onMouseUp={handleMouseUp}
        onVisibleChange={setIsOpen}
        overlay={hideList ? null : <SelectList />}
        rootRef={ref}
        styleType='clear'
        tabIndex={-1}
        type={dropdownType || 'popover'}
        visible={isOpen}
        stopPropagation
      >
        <SelectRoot />
      </Dropdown>
    </SelectContext.Provider>
  )
}

export default React.memo(Select) as typeof Select
