import cn from 'classnames'
import React from 'react'

import { testProps } from 'utils/test/qaData'

import { KitSize } from '../KitTypes'
import * as s from './Icons.module.scss'
import { ICON_MAP } from './generate/iconMap'

type SVGDefaultElement = React.SVGProps<SVGSVGElement> & { title?: string | undefined }

export type KeyIconsType = keyof typeof ICON_MAP

/**
 * Props for the Icon component.
 */
export interface IIconProps extends SVGDefaultElement {
  /**
   * The name of the icon to display.
   */
  name: KeyIconsType
  /**
   * The size of the icon. Can be a KitSize(16/20/24/28) or a number in px.
   */
  size?: KitSize | number
  /**
   * Test data used for automated testing.
   */
  testData?: string
}

const Icon = React.forwardRef<SVGSVGElement, IIconProps>(
  ({ name, className, size, testData, ...rest }, ref) => {
    const Svg = ICON_MAP[name]
    const iconClass = cn(s.root, typeof size === 'string' && s[size], className)
    const iconStyle = { ...rest.style, ...(typeof size === 'number' && { fontSize: size }) }

    return (
      <Svg
        {...testProps({ el: 'icon', icon: name, testData })}
        {...rest}
        className={iconClass}
        ref={ref}
        style={iconStyle}
      />
    )
  },
)

Icon.displayName = 'Icon'

export default Icon
