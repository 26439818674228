import { HeaderSchemaType } from '@vedalib/editor/lib/brand'
import styled from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

import LineStyled from './LineStyled'

const BurgerStyled = styled.div<{
  $styles: ElementStyleCss<HeaderSchemaType>
  $disabled?: boolean
}>`
  position: relative;
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  padding: 13px;
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};

  &:hover ${LineStyled} {
    background-color: ${({ $styles: { hover }, $disabled }) => !$disabled && hover.color};
  }
`

export default BurgerStyled
