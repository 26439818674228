import { SlateElementMark, SlateElementType } from '@vedalib/rich-text'
import lodash from 'lodash'
import { Editor, Element, Transforms } from 'slate'

import { LIST_ELEMENTS, SlateElementConfig } from '../constants'
import { CustomEditor } from '../slate'

export const isElementActive = (editor: Editor, format: SlateElementType) => {
  const { selection } = editor
  if (!selection) {
    return false
  }

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === format,
    }),
  )

  return Boolean(match)
}

export const getActiveElements = (editor: CustomEditor) => {
  const elements = Object.values(SlateElementConfig).reduce<
    Partial<Record<SlateElementType, boolean>>
  >((acc, config) => {
    if (isElementActive(editor, config.name)) {
      acc[config.name] = true
    }

    return acc
  }, {})
  return elements
}

export const getElementMarks = (editor: CustomEditor) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => Element.isElement(n) && Editor.isBlock(editor, n),
    mode: 'lowest',
  })
  const element = match && match[0]
  return lodash.pick(element, Object.values(SlateElementMark))
}

export const toggleElement = (editor: Editor, format: SlateElementType) => {
  const isActive = isElementActive(editor, format)
  const isList = LIST_ELEMENTS.includes(format)
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && LIST_ELEMENTS.includes(n.type),
    split: true,
  })
  const newProperties = {
    type: isActive ? SlateElementType.elementDefault : isList ? SlateElementType.listItem : format,
  }
  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

export const updateElementMark = (
  editor: CustomEditor,
  format: SlateElementMark,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
): void => {
  Transforms.setNodes(
    editor,
    {
      [format]: value,
    },
    { match: (n) => Element.isElement(n) && Editor.isBlock(editor, n), mode: 'lowest' },
  )
}
