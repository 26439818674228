import cn from 'classnames'
import React from 'react'

import { testProps } from 'utils/test/qaData'

import { IconButton } from '../Button'
import { KitSize } from '../KitTypes'
import * as s from './Banner.module.scss'

interface IBannerProps {
  type: 'info' | 'warning' | 'error' | 'success'
  children: React.ReactNode
  onClose: () => void
}
const Banner: React.FC<IBannerProps> = ({ type, children, onClose }) => {
  return (
    <div className={cn(s.root, s[type])} {...testProps({ el: 'banner', type })}>
      <div className={s.content}>{children}</div>
      <div className={s.close}>
        <IconButton
          icon='otherClose'
          iconSize={KitSize.S}
          name='closeBunnerButton'
          onClick={onClose}
          size={KitSize.XS}
          styleType='ghost'
          inheritColor
        />
      </div>
    </div>
  )
}

export default Banner
