import { ButtonFileData } from '@vedalib/editor/lib/elements'
import { FileUsageTypeEnum } from '@vedalib/editor/lib/files'
import { saveAs } from 'file-saver'
import { useCallback } from 'react'

import { getFileByUsage } from 'utils/files'

import BaseButtonElement from '../BaseButtonElement'
import { ButtonElementType } from '../ButtonElement.types'

const DownloadFileElement: ButtonElementType = ({ element, styles, mode, font }) => {
  const label = element?.value?.label

  const actionData = (element.value.actionData || {}) as ButtonFileData

  const fileData = getFileByUsage(actionData.file, FileUsageTypeEnum.common)

  const onClick = useCallback(() => {
    if (fileData) {
      saveAs(fileData?.path || '', `${fileData?.name}`)
    }
  }, [fileData])

  return (
    <BaseButtonElement font={font} label={label} mode={mode} onClick={onClick} styles={styles} />
  )
}

export default DownloadFileElement
