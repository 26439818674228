import { ButtonElementValue } from '@vedalib/editor/lib/elements'

import { valueByAction } from './helper'

export const isTypeGuardAction = (
  action: ButtonElementValue['action'],
): action is keyof typeof valueByAction => {
  if (action && action in valueByAction) {
    return true
  }

  return false
}
