import * as amplitude from '@amplitude/analytics-browser'

let isInitialized = false

export const initAmplitude = () => {
  amplitude.init('39478528e5ff740135b9daae6e7effc2', undefined, {
    defaultTracking: true,
  })
  isInitialized = true
}

export type AmplitudeUserInfo = {
  companyId: string
  companyName: string
  employeeId: string
  employeeName: string
  employeeRole: string
  employeePermissions: string[]
  userEmail: string
}

export const setUserId = (userId: string) => {
  if (!isInitialized) {
    return
  }

  amplitude.setUserId(userId)
}
export const setUserInfo = (info: AmplitudeUserInfo) => {
  if (!isInitialized) {
    return
  }

  const identify = new amplitude.Identify()
  identify.set('companyId', info.companyId)
  identify.set('companyName', info.companyName)
  identify.set('employeeId', info.employeeId)
  identify.set('employeeRole', info.employeeRole)
  identify.set('employeePermissions', info.employeePermissions)
  identify.set('userEmail', info.userEmail)
  identify.set('windowWidth', window.innerWidth)
  identify.set('windowHeight', window.innerHeight)

  amplitude.identify(identify)
}

export const sendEvent = (event: string, data: object) => {
  if (!isInitialized) {
    return
  }

  amplitude.track(event, data)
}
