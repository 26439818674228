import { TestMatchingSchemaType } from '@vedalib/editor/lib/brand'
import styled, { css } from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const MatchingItemStyled = styled.div<{
  $styles: ElementStyleCss<TestMatchingSchemaType>
  $isDragged?: boolean
  $isActive?: boolean
  $isLeft?: boolean
}>`
  display: flex;
  height: 100%;
  min-height: 32px;
  align-items: center;
  padding: 16px;
  padding-left: 12px;
  border: 1px solid #ccc;
  box-shadow: ${({ $styles }) => $styles?.effects?.boxShadow};

  ${({ $isActive }) =>
    $isActive &&
    css`
      transform: translate(24px);
    `};

  ${({ $isDragged, $styles }) =>
    $isDragged &&
    css`
      //background-color: #fff;
      background-color: ${$styles.hover.backgroundColor} !important;
    `};

  ${({ $isLeft, $styles }) =>
    $isLeft &&
    css`
      position: relative;
      z-index: 1;

      &:hover {
        background-color: ${$styles.hover.backgroundColor} !important;
      }
    `}
`

export default MatchingItemStyled
