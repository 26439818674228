import { useMemo } from 'react'

import { useOpenModal } from 'components/uiKit/Modal'
import { EDITOR_PATHS } from 'constants/paths'
import { TemplateType } from 'gql/__generated__/graphql'
import { useBlocksMultiCreate } from 'gql/blocks/apollo'
import { usePathParams } from 'routes/hooks'
import { ScrollContainerEnum } from 'services/Scroll/enums'
import { setProjectNavigation } from 'services/Store/Project/actions'
import { BlockMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getBlockId, getBlocks, getSection, getProject } from 'services/Store/Project/selectors'

import TemplatePickerModal from './TemplatePickerModal'
import { TemplatePickerModalType } from './TemplatePickerModal/types'

export const useAddBlockTemplate = (defaultTag?: TemplateType) => {
  const blockId = useProjectContext(getBlockId)
  const { type } = useProjectContext(getSection) || { type: SectionTypeEnum.landing }
  const { companyId, projectId } = usePathParams(EDITOR_PATHS.main)
  const section = useProjectContext(getSection)
  const blocks = useProjectContext(getBlocks)
  const project = useProjectContext(getProject)

  const blocksByMode = useMemo(() => {
    return blocks.reduce(
      (acc, block) => {
        acc[block.mode] = true
        return acc
      },
      {} as Record<string, boolean>,
    )
  }, [blocks])

  let tag

  switch (type) {
    case SectionTypeEnum.test:
      tag = blocksByMode[BlockMode.start] ? TemplateType.QUESTIONS : TemplateType.START
      break
    case SectionTypeEnum.landing:
      tag = TemplateType.ALL
      break
    case SectionTypeEnum.cover:
      tag = TemplateType.COVER
      break
    default:
      tag = TemplateType.ALL
  }

  const finalTag = defaultTag || tag

  const pickTemplateModal = useOpenModal(TemplatePickerModal, {
    tag: finalTag,
    type,
    companyId,
    projectId,
    sectionId: section?.id,
    blocksByMode,
    modalType: TemplatePickerModalType.add,
    employeePreferences: project?.employeePreferences,
  })
  const dispatch = useProjectDispatch()
  const [createBlocks] = useBlocksMultiCreate(section?.id)

  const onBlocksAdd = async (insertAfter = true) => {
    const val = await pickTemplateModal.open({})
    if (!val) {
      return
    }

    const result = await createBlocks({
      variables: {
        payload: {
          insertAfter,
          uuids: val.selected,
          activeBlockUUID: blockId,
          sectionId: section?.id || '',
        },
      },
    })
    if (result?.data?.data.blocks) {
      const data = result.data?.data
      const blockIds = data.blocksOrder?.filter((id) => data.blocks.some((b) => b.uuid === id))
      const block = data.blocks.find((b) => b.uuid === blockIds?.[blockIds.length - 1])
      if (!block) {
        return
      }

      dispatch(
        setProjectNavigation({
          sectionId: block.sectionId,
          blockId: block.uuid,
          nodeId: null,
          scroll: [
            {
              container: ScrollContainerEnum.canvas,
              id: block.uuid,
              block: 'begin',
              scroll: ['never', 'always'],
            },
            {
              container: ScrollContainerEnum.blocksNav,
              id: block.uuid,
              block: 'begin',
              scroll: 'always',
            },
          ],
        }),
      )
    }
  }

  return onBlocksAdd
}
