import { TableFontSchemaType } from '@vedalib/editor/lib/brand'
import { TableElementValue } from '@vedalib/editor/lib/elements'
import { CONFIG_ELEMENTS } from '@vedalib/editor/lib/elements'
import { RichTextValue } from '@vedalib/rich-text'
import { produce } from 'immer'
import * as R from 'ramda'
import { createContext, useContext, Dispatch, SetStateAction, useCallback } from 'react'

import { useGetRichTextProps } from 'components/form/RichText/useGetRichTextProps'
// import { createContext, useContext } from 'use-context-selector'
import { NOOP } from 'constants/commonConstans'
import { ElementFontCss } from 'services/Branding/types'
import { PREVIEW_MODE } from 'services/Store/Project/constants'
import { IBlockMode } from 'services/Store/Project/types'

import { Properties } from '../../elements.types'
import {
  CellPosition,
  DndControlsCoordinates,
  DndControlsState,
  TableElementType,
} from './TableElement.types'
import { createCell, createColumn, createRow, delayedUpdate } from './utils'

export type TableContextValue = {
  setCellNodes: Dispatch<SetStateAction<HTMLTableDataCellElement[][]>>
  cellNodes: HTMLTableDataCellElement[][]
  styles: Properties<TableElementType>['styles'] | null
  tableValue: TableElementValue
  onChange?: (value: TableElementValue) => void
  dndControlsCoordinates: DndControlsCoordinates
  setDndControlsCoordinates: (value: DndControlsCoordinates) => void
  dragState: DndControlsState
  setDragState: (value: DndControlsState) => void
  selectedCells: CellPosition[]
  setSelectedCells: Dispatch<SetStateAction<CellPosition[]>>
  startSelectPosition: CellPosition | null
  setStartSelectPosition: Dispatch<SetStateAction<CellPosition | null>>
  mode: IBlockMode
  tableRef: React.RefObject<HTMLTableElement>
  value: TableElementValue
  id: string
  rtProps: ReturnType<typeof useGetRichTextProps>
  waiting?: boolean
  font: ElementFontCss<TableFontSchemaType>
}

type CellBlock = {
  start: [row: number, col: number]
  end: [row: number, col: number]
}

const TableContext = createContext<TableContextValue>({
  cellNodes: [],
  tableRef: { current: null },
  styles: null,
  tableValue: CONFIG_ELEMENTS.table.defaultValue,
  onChange: undefined,
  dndControlsCoordinates: {
    x: null,
    y: null,
  },
  setDndControlsCoordinates: NOOP,
  dragState: {
    dragType: null,
    dragIndex: null,
  },
  setDragState: NOOP,
  selectedCells: [],
  setSelectedCells: NOOP,
  startSelectPosition: null,
  setStartSelectPosition: NOOP,
  mode: PREVIEW_MODE,
  setCellNodes: NOOP,
  value: CONFIG_ELEMENTS.table.defaultValue,
  id: '',
  font: {} as ElementFontCss<TableFontSchemaType>,
  waiting: false,
  rtProps: {} as ReturnType<typeof useGetRichTextProps>,
})

export const useTableContext = () => {
  const {
    styles,
    cellNodes,
    mode,
    tableValue,
    onChange,
    dndControlsCoordinates,
    setDndControlsCoordinates: setDndControlsCoordinatesContext,
    dragState,
    setDragState: setDragStateContext,
    selectedCells,
    setSelectedCells,
    startSelectPosition,
    setStartSelectPosition,
    setCellNodes,
    tableRef,
    id,
    waiting,
    rtProps,
    font,
  } = useContext(TableContext)

  const addRow = () => {
    rtProps.onLabelSelect('')
    onChange?.(
      produce(tableValue, (draft: TableElementValue) => {
        draft.cells.push(createRow(draft.columns.length))
      }),
    )

    delayedUpdate(() => {
      setSelectedCells(
        Array(tableValue.columns.length)
          .fill(0)
          .map((_col, i) => ({
            row: tableValue.cells.length,
            col: i,
          })),
      )
    })
  }

  const addColumn = () => {
    rtProps.onLabelSelect('')
    onChange?.(
      produce(tableValue, (draft: TableElementValue) => {
        draft.cells.forEach((row) => row.push(createCell()))
        draft.columns.push(createColumn())
      }),
    )

    delayedUpdate(() => {
      setSelectedCells(
        Array(tableValue.cells.length)
          .fill(0)
          .map((_cell, i) => ({
            row: i,
            col: tableValue.columns.length,
          })),
      )
    })
  }

  const selectCol = (colNumber: number) => {
    rtProps.onLabelSelect('')
    setSelectedCells(
      tableValue.cells.map((_cell, row) => ({
        row,
        col: colNumber,
      })),
    )
  }

  const selectRow = (rowNumber: number) => {
    rtProps.onLabelSelect('')
    setSelectedCells(
      tableValue.cells[rowNumber].map((_col, col) => ({
        row: rowNumber,
        col,
      })),
    )
  }

  const changeOrder = (axis: 'row' | 'column', dragIndex: number, dropIndex: number) => {
    rtProps.onLabelSelect('')
    if (axis === 'row') {
      onChange?.(
        produce(tableValue, (draft: TableElementValue) => {
          draft.cells = R.move(dragIndex, dropIndex, draft.cells)
        }),
      )
    } else {
      onChange?.(
        produce(tableValue, (draft: TableElementValue) => {
          draft.cells = draft.cells.map((row) => R.move(dragIndex, dropIndex, row))
          draft.columns = R.move(dragIndex, dropIndex, draft.columns)
        }),
      )
    }
  }

  const resizeColumn = (colNumber: number, width: number) => {
    rtProps.onLabelSelect('')
    const newTableValue = produce(tableValue, (draft: TableElementValue) => {
      draft.columns[colNumber].width = width
    })

    onChange?.(newTableValue)
  }

  const getWidth = (colNumber: number) => {
    return tableValue.columns[colNumber].width
  }

  const onTableChange = (value: TableElementValue) => {
    onChange?.(value)
  }

  const setDndControlsCoordinates = (value: DndControlsCoordinates) => {
    if (!R.equals(value, dndControlsCoordinates)) {
      setDndControlsCoordinatesContext(value)
    }
  }

  const isSelected = (row: number, col: number) =>
    selectedCells.some((cell) => cell.row === row && cell.col === col) && selectedCells.length > 1

  const getCellBlocks = () => {
    const cellBlocks: CellBlock[] = []

    tableValue.cells.forEach((row, r) => {
      row.forEach((cell, c) => {
        const { colspan = 1, rowspan = 1 } = cell ?? {}
        if (cell && (colspan > 1 || rowspan > 1)) {
          cellBlocks.push({
            start: [r, c],
            end: [r + rowspan - 1, c + colspan - 1],
          })
        }
      })
    })

    return cellBlocks
  }

  const isBlockInSelected = (block: CellBlock, selectedBlock: CellBlock) => {
    const {
      start: [startRow, startCol],
      end: [endRow, endCol],
    } = block
    const {
      start: [selectedStartRow, selectedStartCol],
      end: [selectedEndRow, selectedEndCol],
    } = selectedBlock

    return (
      startRow >= selectedStartRow &&
      startCol >= selectedStartCol &&
      endRow <= selectedEndRow &&
      endCol <= selectedEndCol
    )
  }

  const getSelectedCells = useCallback(
    (first: CellPosition, current: CellPosition) => {
      const cellBlocks = getCellBlocks()
      const getCellBlock = (row: number, col: number) => {
        return cellBlocks.find((block) => {
          const [startRow, startCol] = block.start
          const [endRow, endCol] = block.end

          return row >= startRow && row <= endRow && col >= startCol && col <= endCol
        })
      }

      const getSelectedBorders = (selectedBlock: CellBlock): CellBlock => {
        const {
          start: [startRow, startCol],
          end: [endRow, endCol],
        } = selectedBlock

        const changeBorders = (block: CellBlock) => {
          const {
            start: [blockStartRow, blockStartCol],
            end: [blockEndRow, blockEndCol],
          } = block
          return getSelectedBorders({
            start: [Math.min(blockStartRow, startRow), Math.min(blockStartCol, startCol)],
            end: [Math.max(blockEndRow, endRow), Math.max(blockEndCol, endCol)],
          })
        }
        for (let i = startRow; i <= endRow; i++) {
          if (i === startRow || i === endRow) {
            for (let j = startCol; j <= endCol; ) {
              const { colspan = 1, rowspan = 1 } = tableValue.cells[i][j] ?? {}
              if (tableValue.cells[i][j] === null || colspan > 1 || rowspan > 1) {
                const block = getCellBlock(i, j)
                if (block && !isBlockInSelected(block, selectedBlock)) {
                  return changeBorders(block)
                } else {
                  const colEnd = block?.end[1] ?? j
                  j = colEnd + 1
                }
              } else {
                j++
              }
            }
          } else {
            if (tableValue.cells[i][startCol] === null) {
              const block = getCellBlock(i, startCol)
              if (block && !isBlockInSelected(block, selectedBlock)) {
                return changeBorders(block)
              }
            }
            if (tableValue.cells[i][endCol] === null) {
              const block = getCellBlock(i, endCol)
              if (block && !isBlockInSelected(block, selectedBlock)) {
                return changeBorders(block)
              }
            }
          }
        }

        return selectedBlock
      }

      const selectedBorders = getSelectedBorders({
        start: [Math.min(first.row, current.row), Math.min(first.col, current.col)],
        end: [Math.max(first.row, current.row), Math.max(first.col, current.col)],
      })

      const selectedCells = []

      const {
        start: [startRow, startCol],
        end: [endRow, endCol],
      } = selectedBorders
      for (let i = startRow; i <= endRow; i++) {
        for (let j = startCol; j <= endCol; j++) {
          if (tableValue.cells[i][j]) {
            selectedCells.push({ row: i, col: j })
          }
        }
      }

      return selectedCells
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableValue],
  )

  const setSelected = (selected: CellPosition | null, override = false) => {
    // rtProps.onLabelSelect('')
    if (!selected) {
      setSelectedCells([])
      return
    }

    if (selectedCells.length === 0 || override || R.equals(selected, startSelectPosition)) {
      setSelectedCells([selected])
      return
    }

    if (startSelectPosition) {
      const newSelectedCells = getSelectedCells(startSelectPosition, selected)
      setSelectedCells(newSelectedCells)
    }
  }
  const setDragState = (value: DndControlsState) => {
    rtProps.onLabelSelect('')
    setDragStateContext(value)
  }
  const onChangeCellText = useCallback(
    (labelValue: RichTextValue, row: number, col: number) => {
      onChange?.(R.assocPath(['cells', row, col, 'value'], labelValue, tableValue))
    },
    [onChange, tableValue],
  )

  return {
    cellNodes,
    styles,
    tableValue,
    dndControlsCoordinates,
    setDndControlsCoordinates,
    dragState,
    setDragState,
    addRow,
    addColumn,
    selectCol,
    selectRow,
    changeOrder,
    resizeColumn,
    getWidth,
    onTableChange,
    isSelected,
    setSelected,
    startSelectPosition,
    setStartSelectPosition,
    selectedCells,
    mode,
    tableRef,
    setCellNodes,
    id,
    rtProps,
    waiting,
    font,
    onChangeCellText,
  }
}

export default TableContext
