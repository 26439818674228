import {
  TestKeyboardInputFontSchemaType,
  TestKeyboardInputSchemaType,
} from '@vedalib/editor/lib/brand'
import { CONFIG_ELEMENTS, TestKeyboardInputElementValue } from '@vedalib/editor/lib/elements'

import { DEMO_MODE } from 'services/Store/Project/constants'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './TestKeyboardInputElement'

const DEMO_VALUE: TestKeyboardInputElementValue = {
  items: [],
}

const TestKeyboardInputDemo: React.FC<
  IElementDemoProps<TestKeyboardInputSchemaType, TestKeyboardInputFontSchemaType>
> = ({ styles, fonts }) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.testKeyboardInput.type}-demo`,
        type: CONFIG_ELEMENTS.testKeyboardInput.type,
        value: DEMO_VALUE,
        font: {},
        style: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

export default TestKeyboardInputDemo
