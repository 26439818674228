import cn from 'classnames'
import lodash from 'lodash'
import { useContextSelector } from 'use-context-selector'

import Icon from 'components/uiKit/Icon/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { BlockMode } from 'services/Store/Project/enums'
import { getEnumOption } from 'utils/enum'
import { testProps } from 'utils/test/qaData'

import { getTemplateStepperState } from '../helper'
import { TemplatePickerContext } from '../hooks'
import * as s from './Stepper.module.scss'

export const STEPS = [
  getEnumOption('BlockModeEnum', BlockMode.start),
  getEnumOption('BlockModeEnum', BlockMode.questions),
  getEnumOption('BlockModeEnum', BlockMode.end),
]

const Stepper = ({ selectedSteps }: { selectedSteps: number[] }) => {
  const {
    setStep,
    step,
    blocksByMode = {},
  } = useContextSelector(TemplatePickerContext, (value) => value)

  return (
    <div className={s.root}>
      {STEPS.map(({ value, label }, index) => {
        const emptySection = lodash.isEmpty(blocksByMode)
        const completed = selectedSteps.includes(index)
        const active = step === index
        const disabled = !emptySection && index !== 1 && blocksByMode[value]
        const alert = !emptySection ? !(index === 1 || completed || active || disabled) : false
        const icon = disabled || alert || completed
        const state = getTemplateStepperState(completed, disabled, alert, active)

        return (
          <div className={cn(s.stepWrapper, { [s.disabled]: disabled })} key={index}>
            <div
              className={s.step}
              onClick={() => !disabled && setStep(index)}
              {...testProps({ el: 'step', state, name: value })}
            >
              <div
                className={cn(s.circle, {
                  [s.alert]: alert,
                  [s.disabled]: disabled,
                  [s.active]: active,
                  [s.completed]: completed,
                })}
              >
                {icon ? (
                  <Icon
                    className={cn({
                      [s.completedIcon]: completed,
                      [s.disabledIcon]: disabled,
                    })}
                    name={alert ? 'alertDanger' : 'checkmark1'}
                    size={KitSize.XS}
                  />
                ) : (
                  index + 1
                )}
              </div>
              <div className={s.text}>{label}</div>
            </div>
            {index < STEPS.length - 1 && <div className={s.divider} />}
          </div>
        )
      })}
    </div>
  )
}

export default Stepper
