import { genField } from 'components/controls/Field'
import { BlockModeEnum, TemplateType } from 'gql/__generated__/graphql'
import { SectionTypeEnum } from 'services/Store/Project/enums'
import { t } from 'services/Translation'
import { getEnumOption } from 'utils/enum'

import { BASE_TAGS } from '../../editor-v3/Editor/Modals/TemplatePickerModal/helper'
import { blockModeToTemplateTag } from '../../editor-v3/Editor/Modals/TemplatePickerModal/types'

export const nameField = genField({
  label: t('input.label.name'),
  name: 'name',
  type: 'text',
  params: { placeholder: t('input.placeholder.name'), autoFocus: true },
  rules: [
    {
      required: true,
      message: t('input.error.required', { field: t('input.label.name') }),
    },
    {
      whitespace: true,
      message: t('input.error.whitespace'),
    },
  ],
})

export const createdForField = (options: { value: string; label: string }[]) =>
  genField({
    label: t('input.label.location'),
    name: 'editorTemplateGroupId',
    type: 'select',
    params: () => {
      return {
        options,
        fluid: true,
      }
    },
    rules: [
      {
        required: true,
      },
    ],
  })

export const templateTypes = ({
  sectionType,
  mode,
}: {
  sectionType?: SectionTypeEnum
  mode?: string
}) =>
  genField({
    label: t('input.label.elementGroup'),
    name: 'templateTypes',
    type: 'select',
    params: () => {
      const modeTyped = mode as BlockModeEnum
      const onlyModeTagAllowed = ([
        BlockModeEnum.start,
        BlockModeEnum.end,
        BlockModeEnum.questions,
        BlockModeEnum.cover,
      ] as string[]).includes(modeTyped)
      const modeToTag = blockModeToTemplateTag(modeTyped)
      const options = onlyModeTagAllowed
        ? modeToTag ?[getEnumOption('TemplateType', modeToTag)] : []
        : sectionType ? (BASE_TAGS[sectionType] || []).filter((o) => o.value !== TemplateType.ALL) : []
      return {
        disabled: onlyModeTagAllowed,
        fluid: true,
        isMultiple: true,
        options,
      }
    },
    rules: [
      {
        validator:
          sectionType === SectionTypeEnum.landing
            ? (_rule, value) => (!value ? t('modal.saveTemplate.elementGroup') : false)
            : undefined,
        message: t('modal.saveTemplate.elementGroup'),
        required: true,
      },
    ],
  })

export const InputErrorValues = {
  TEMPLATE_GROUP_NAME_NOT_UNIQUE: t('input.error.templateNotUnique'),
}
