import { EmbeddedSourceEnum, EmbeddedElementValue } from '@vedalib/editor/lib/elements'
import React from 'react'
import { chainName, getParent, iframeLinkValidation } from 'utils'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import { EmbeddedValueFormType } from './EmbeddedElement.types'

const genField = genDataField<Block>()

const type = genField({
  name: 'type',
  type: 'select',
  label: '',
  params: {
    options: [
      { label: t('input.option.html'), value: EmbeddedSourceEnum.html },
      { label: t('input.option.link'), value: EmbeddedSourceEnum.link },
      { label: t('input.option.archive'), value: EmbeddedSourceEnum.zip },
    ],
    fluid: true,
  },
  layout: 'horizontal',
})

const html = genField({
  name: 'html',
  type: 'textarea',
  params: (block, { name }) => {
    const { parent } = getParent<EmbeddedElementValue>(name, block)
    return {
      hidden: parent?.type !== EmbeddedSourceEnum.html,
      rows: 10,
    }
  },
  layout: 'horizontal',
})

const zipFile = genField({
  name: 'zipFile',
  type: 'file',
  params: (block, { name }) => {
    const { parent } = getParent<EmbeddedElementValue>(name, block)
    return {
      hidden: parent?.type !== EmbeddedSourceEnum.zip,
      nullable: true,
      fileType: 'document',
    }
  },
  layout: 'horizontal',
})

export const zipPath = genField({
  name: 'zipPath',
  label: t('elements.embedded.form.path'),
  type: 'text',
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<EmbeddedElementValue>(name, block)
    return {
      hidden: parent?.type !== EmbeddedSourceEnum.zip,
      placeholder: 'index.html',
    }
  },
})

export const link = genField({
  name: 'link',
  label: t('input.label.linkAddress'),
  type: 'text',
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<EmbeddedElementValue>(name, block)
    return {
      hidden: parent?.type !== EmbeddedSourceEnum.link,
      placeholder: t('input.placeholder.enterUrl'),
    }
  },
  rules: [
    {
      max: 2048,
      validator: iframeLinkValidation,
      message: t('input.error.invalidUrl'),
    },
  ],
  // layout: 'vertical',
})

const EmbeddedForm: EmbeddedValueFormType = ({ name }) => (
  <Padding sides>
    <RealtimeField config={chainName(name, type)} />
    <RealtimeField config={chainName(name, html)} />
    <RealtimeField config={chainName(name, link)} />
    <RealtimeField config={chainName(name, zipFile)} />
    <RealtimeField config={chainName(name, zipPath)} />
  </Padding>
)

export default EmbeddedForm
