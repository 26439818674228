import { LabeledGraphicSchemaType } from '@vedalib/editor/lib/brand'
import styled from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const RingsStyled = styled.div<{ $styles: ElementStyleCss<LabeledGraphicSchemaType> }>`
  position: relative;
  width: 100%;
  height: 100%;
  top: 100%;
  z-index: 1;
`

export default RingsStyled
