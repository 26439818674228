import { useMutation, useQuery } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'

import {
  EditorTemplateCollectionsQueryVariables,
  EditorTemplatesAllTotalQueryVariables,
  EditorTemplatesAndCollectionsQueryVariables,
  TemplatesAllQueryVariables,
} from 'gql/__generated__/graphql'

import {
  templateCopy,
  templateGroupCreate,
  templateGroupDelete,
  templateGroupDuplicate,
  templateGroupUpdate,
  templatesAddToFavs,
  templatesCreateFromBlock,
  templatesDeleteByIds,
  templatesRemoveFromFavs,
  templatesSave,
} from './gql/mutations'
import {
  editorTemplatesAllTotal,
  templatesGetAllQuery,
  templatesGetGroupAll,
  templatesGetTemplatesAndCollections,
} from './gql/queries'

export const useTemplatesAll = (variables: TemplatesAllQueryVariables, skip = false) =>
  useQuery(templatesGetAllQuery, {
    variables,
    fetchPolicy: 'network-only',
    skip,
    onError: (err) =>
      console.error('"useTemplatesAll" fn is crashed on operation: "useQuery"', err),
  })

export const useTemplatesCreate = () =>
  useMutation(templatesCreateFromBlock, {
    refetchQueries: [String(getOperationName(templatesGetAllQuery))],
    onError: (err) =>
      console.error('"useTemplatesCreate" fn is crashed on operation: "useMutation"', err),
  })

export const useTemplatesSave = () =>
  useMutation(templatesSave, {
    refetchQueries: [String(getOperationName(templatesGetAllQuery))],
    onError: (err) =>
      console.error('"useTemplatesCreate" fn is crashed on operation: "useMutation"', err),
  })

export const useTemplatesDeleteByIds = () =>
  useMutation(templatesDeleteByIds, {
    refetchQueries: [String(getOperationName(templatesGetAllQuery))],
    onError: (err) =>
      console.error('"useTemplatesDeleteByIds" fn is crashed on operation: "useMutation"', err),
  })

export const useTemplatesAddToFavs = () =>
  useMutation(templatesAddToFavs, {
    onError: (err) =>
      console.error('"useTemplatesAddToFavs" fn is crashed on operation: "useMutation"', err),
    refetchQueries: [
      String(getOperationName(templatesGetAllQuery)),
      String(getOperationName(editorTemplatesAllTotal)),
    ],
  })

export const useTemplatesRemoveFromFavs = () =>
  useMutation(templatesRemoveFromFavs, {
    onError: (err) =>
      console.error('"useTemplatesRemoveFromFavs" fn is crashed on operation: "useMutation"', err),
    refetchQueries: [
      String(getOperationName(templatesGetAllQuery)),
      String(getOperationName(editorTemplatesAllTotal)),
    ],
  })

export const useTemplatesGetGroup = (variables: EditorTemplateCollectionsQueryVariables) =>
  useQuery(templatesGetGroupAll, {
    variables,
    fetchPolicy: 'network-only',
    onError: (err) =>
      console.error('"useTemplatesGetGroup" fn is crashed on operation: "useQuery"', err),
  })

export const useTemplatesGetTemplatesAndCollections = (
  variables: EditorTemplatesAndCollectionsQueryVariables,
) =>
  useQuery(templatesGetTemplatesAndCollections, {
    variables,
    fetchPolicy: 'network-only',
    onError: (err) =>
      console.error('"useTemplatesGetGroup" fn is crashed on operation: "useQuery"', err),
  })

export const useTemplateGroupCreate = () =>
  useMutation(templateGroupCreate, {
    refetchQueries: [String(getOperationName(templatesGetTemplatesAndCollections))],
    onError: (err) =>
      console.error('"useTemplateGroupCreate" fn is crashed on operation: "useMutation"', err),
  })

export const useTemplateGroupDuplicate = () =>
  useMutation(templateGroupDuplicate, {
    refetchQueries: [String(getOperationName(templatesGetTemplatesAndCollections))],
    onError: (err) =>
      console.error('"useTemplateGroupDuplicate" fn is crashed on operation: "useMutation"', err),
  })

export const useTemplateGroupUpdate = () =>
  useMutation(templateGroupUpdate, {
    refetchQueries: [String(getOperationName(templatesGetTemplatesAndCollections))],
    onError: (err) =>
      console.error('"useTemplateGroupUpdate" fn is crashed on operation: "useMutation"', err),
  })

export const useTemplateGroupDelete = () =>
  useMutation(templateGroupDelete, {
    refetchQueries: [String(getOperationName(templatesGetTemplatesAndCollections))],
    onError: (err) =>
      console.error('"useTemplateGroupDelete" fn is crashed on operation: "useMutation"', err),
  })

export const useTemplatesAllTotal = (variables: EditorTemplatesAllTotalQueryVariables) =>
  useQuery(editorTemplatesAllTotal, {
    variables,
    fetchPolicy: 'network-only',
    onError: (err) =>
      console.error('"useTemplatesAllTotal" fn is crashed on operation: "useQuery"', err),
  })

export const useTemplateCopy = () =>
  useMutation(templateCopy, {
    refetchQueries: [String(getOperationName(templatesGetAllQuery))],
  })
