import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

import * as s from './LayoutToolbar.module.scss'

export type LayoutToolbarState = 'open' | 'closed' | 'icon'

interface ILayoutToolbar extends React.ComponentProps<typeof motion.aside> {
  styleType?: 'absolute' | 'static'
  fullHeight?: boolean
  children: React.ReactNode
  state: LayoutToolbarState
}

const LayoutToolbar: React.FC<ILayoutToolbar> = ({
  children,
  styleType = 'absolute',
  state,
  fullHeight,
  ...rest
}) => {
  const height = fullHeight ? '100%' : 'auto'
  const ANIMATIONS = {
    open: {
      x: 0,
      y: 0,
      borderRadius: 6,
      height: [300, height],
      width: 300,
      backgroundColor: 'var(--color-background-main)',
      padding: 0,
      borderWidth: 0,
    },
    closed: {
      x: '100%',
      y: 0,
      borderRadius: 6,
      height: height,
      backgroundColor: 'var(--color-background-main)',
      width: 300,
      padding: 0,
      borderWidth: 0,
    },
    icon: {
      x: -8,
      y: 8,
      borderRadius: 20,
      backgroundColor: 'var(--color-background-dark)',
      width: 36,
      borderWidth: 2,
      height: [300, 36],
      padding: [16, 0],
    },
  }

  return (
    <AnimatePresence initial={false}>
      {state !== 'closed' && (
        <motion.aside
          {...rest}
          animate={state}
          className={cn(s.root, s[styleType], { [s.fullHeight]: fullHeight })}
          exit='closed'
          initial='closed'
          style={state === 'icon' ? {} : { minHeight: '320' }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          variants={ANIMATIONS}
        >
          {children}
        </motion.aside>
      )}
    </AnimatePresence>
  )
}

export default React.memo(LayoutToolbar)
