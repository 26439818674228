import cn from 'classnames'
import React from 'react'

import { MARK_CONFIGS } from '../constants'
import { LeafProps } from '../slate'
import { withPseudoSelection, withRegister } from './Text.decorators'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNodeFormatting = (element: { [key: string]: any }) => {
  const activeConfigs = MARK_CONFIGS.filter((config) => element[config.name])
  const classes = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const style: { [key: string]: any } = {}
  for (const config of activeConfigs) {
    if (config.className) {
      classes.push(config.className)
    } else {
      style[config.name] = element[config.name]
    }
  }

  return { classes, style }
}

const Text = ({ attributes, children, leaf }: LeafProps): React.ReactElement => {
  const { style, classes } = getNodeFormatting(leaf)
  children = (
    <span className={cn(classes)} style={style} {...attributes}>
      {children}
    </span>
  )
  children = withRegister(leaf, children)
  children = withPseudoSelection(leaf, children)

  return children
}

export default Text
