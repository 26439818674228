// extracted by mini-css-extract-plugin
var _1 = "BlockHistory_active__RkRcO";
var _2 = "BlockHistory_avatar__9r-xL";
var _3 = "BlockHistory_blockPreview__9Dvta";
var _4 = "BlockHistory_button__3lzlE";
var _5 = "BlockHistory_date__YGHcl";
var _6 = "BlockHistory_history__MYwF0";
var _7 = "BlockHistory_historyList__l7Pm+";
var _8 = "BlockHistory_icon__7QnGT";
var _9 = "BlockHistory_panel__dzzj2";
var _a = "BlockHistory_release__TRRuJ";
var _b = "BlockHistory_root__PcXCy";
var _c = "BlockHistory_title__3tf2u";
var _d = "BlockHistory_version__O32ji";
var _e = "BlockHistory_versionName__8iChV";
export { _1 as "active", _2 as "avatar", _3 as "blockPreview", _4 as "button", _5 as "date", _6 as "history", _7 as "historyList", _8 as "icon", _9 as "panel", _a as "release", _b as "root", _c as "title", _d as "version", _e as "versionName" }
