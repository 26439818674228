import { FileImageUsage, FileUsageImageSource } from '@vedalib/editor/lib/files'
import { useSize } from 'ahooks'
import cn from 'classnames'
import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import ReactCrop, { PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Crop as CropType } from 'react-image-crop/src/types'

import Button from 'components/uiKit/Button'
import Modal, { ModalBody } from 'components/uiKit/Modal'
import { MCWithParams } from 'components/uiKit/Modal/ModalContext'
import TextArea from 'components/uiKit/TextArea'
import { t } from 'services/Translation'
import { getImageByUsage } from 'utils/files'

import CropActions from './CropActions'
import * as s from './ImageEditModal.module.scss'
import SizeFields from './SizeFields'
import { isDisabledApply, scaleCrop } from './cropUtils'

export interface IImageEditValue {
  cropParams?: {
    crop?: CropType
    round?: boolean
    aspect?: number
  }
  accessibility?: string
}

interface IImageEditModalProps extends IImageEditValue {
  image: Omit<FileImageUsage, 'type'>
}

const ImageEditModal: MCWithParams<IImageEditModalProps, IImageEditValue> = ({
  params: modalParams,
  onClose,
}) => {
  const { params, accessibility: accessibilityProp, source, url } = modalParams.image
  const { crop, round, aspect } = params || {}
  const [imgElement, setImageElement] = useState<HTMLImageElement | null>(null)
  const [localValue, setLocalValue] = useState<IImageEditValue>({
    cropParams: { crop, round, aspect },
    accessibility: accessibilityProp,
  })

  const imageFile = getImageByUsage(modalParams.image)
  const path = imageFile ? imageFile.originalFile?.path : ''
  const d = useSize(imgElement)
  const scale = d?.width && imgElement?.naturalWidth ? imgElement?.naturalWidth / d.width : 1
  const iw = imageFile?.originalFile?.width || imgElement?.naturalWidth || 0
  const ih = imageFile?.originalFile?.height || imgElement?.naturalHeight || 0

  const normalizedCrop = (crop: CropType | undefined) => {
    if (!imgElement || !crop) {
      return crop
    }

    let { width, height, x, y } = crop
    x = lodash.clamp(Math.round(x), 0, iw)
    y = lodash.clamp(Math.round(y), 0, ih)
    width = lodash.clamp(Math.round(width), 0, iw - x)
    height = lodash.clamp(Math.round(height), 0, ih - y)
    return { ...crop, width, height, x, y }
  }

  const disabledApply = isDisabledApply(
    { cropParams: { crop, round: round || false, aspect }, accessibility: accessibilityProp },
    localValue,
    imgElement,
  )
  const maxImageHeight = '80vh'

  const onApply = () => {
    onClose(localValue)
  }
  const onCancel = () => {
    onClose()
  }

  const onChangeCrop = (crop: PixelCrop) => {
    return setLocalValue((value) => {
      return {
        ...value,
        cropParams: { ...value.cropParams, crop: normalizedCrop(scaleCrop(crop, scale)) },
      }
    })
  }

  const onAccessibilityChange = (value: string) => {
    setLocalValue((val) => {
      return source === FileUsageImageSource.url
        ? { accessibility: value }
        : {
            ...val,
            accessibility: value,
          }
    })
  }

  useEffect(() => {
    setLocalValue({ accessibility: accessibilityProp, cropParams: { crop, round, aspect } })
  }, [crop, round, aspect, accessibilityProp])

  return (
    <Modal minHeight='100%' name='crop' styleType='crop'>
      <ModalBody>
        <div className={s.root}>
          <div className={s.content}>
            {source === FileUsageImageSource.file && (
              <CropActions
                id={modalParams.image.id}
                imgElement={imgElement}
                localValue={localValue}
                normalizedCrop={normalizedCrop}
                setLocalValue={setLocalValue}
              />
            )}
            <div className={s.reactCropWrapper}>
              {source === FileUsageImageSource.file ? (
                <ReactCrop
                  aspect={localValue.cropParams?.aspect}
                  circularCrop={localValue.cropParams?.round}
                  crop={scaleCrop(localValue.cropParams?.crop, 1 / scale)}
                  onChange={onChangeCrop}
                >
                  <img ref={setImageElement} src={path} style={{ maxHeight: maxImageHeight }} />
                </ReactCrop>
              ) : (
                <img src={url} style={{ maxHeight: maxImageHeight }} />
              )}
            </div>
          </div>
          <div className={s.side}>
            <div>
              <div className={s.title}>{t('modal.editorCrop.title')}</div>
              {source === FileUsageImageSource.file && (
                <SizeFields
                  ih={ih}
                  imgElement={imgElement}
                  iw={iw}
                  localValue={localValue}
                  setLocalValue={setLocalValue}
                />
              )}
              <div className={s.group}>{t('input.label.accessibility')}</div>
              <div className={s.fieldLayout}>
                <div className={cn(s.field, s.fluid)}>
                  <TextArea
                    autoSize={{ minRows: 7 }}
                    name='accessibility'
                    onChange={onAccessibilityChange}
                    placeholder={t('input.placeholder.altText')}
                    value={localValue.accessibility}
                    fluid
                  />
                </div>
              </div>
            </div>
            <div className={s.footer}>
              <Button name='cancelBtn' onClick={onCancel} styleType='ghost'>
                {t('uiKit.button.cancel')}
              </Button>
              <Button
                disabled={disabledApply}
                name='applyBtn'
                onClick={onApply}
                styleType='primary'
              >
                {t('uiKit.button.apply')}
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ImageEditModal
