import { motion } from 'framer-motion'
import { useSyncExternalStore } from 'react'

import Notification from './Notification'
import * as s from './Notification.module.scss'
import { INotificationProps, NotificationType } from './types'

const DEFAULT_NOT_ERROR_DURATION = 5000

let list: INotificationProps[] = []

let subscribeCallback: (() => void) | null = null

const ANIMATION_VARIANTS = {
  hidden: { opacity: 0, x: '100%' },
  visible: { opacity: 1, x: 0 },
}

const subscribe = (callback: () => void) => {
  subscribeCallback = callback
  return () => {
    subscribeCallback = null
  }
}

const getSnapshot = () => {
  return list
}

const getDuration = (type: NotificationType, duration?: number) => {
  return duration ?? (type === NotificationType.error ? 0 : duration) ?? DEFAULT_NOT_ERROR_DURATION
}

export const notify = (notification: INotificationProps) => {
  list = [...list, notification]
  subscribeCallback?.()

  const duration = getDuration(notification.type, notification.duration)

  if (duration) {
    setTimeout(() => {
      list = list.filter((item) => item !== notification)
      subscribeCallback?.()
    }, duration)
  }
}
const useNotificationList = () => {
  const list = useSyncExternalStore(subscribe, getSnapshot)
  return list
}

const NotificationRoot = () => {
  const list = useNotificationList()
  return (
    <div className={s.root}>
      <div className={s.list}>
        {list.map((item, i) => (
          <motion.div animate='visible' initial='hidden' key={i} variants={ANIMATION_VARIANTS}>
            <Notification key={i} message={item.message} type={item.type} />
          </motion.div>
        ))}
      </div>
    </div>
  )
}

export default NotificationRoot
