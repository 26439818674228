import React, { useState, useRef, useEffect, useMemo } from 'react'

import { IconButton } from 'components/uiKit/Button'
import Dropdown from 'components/uiKit/Dropdown'
import Input, { AdditionWrapper } from 'components/uiKit/Input'
import { t } from 'services/Translation'
import { secondsToTime } from 'utils/editorUtils'

import { KitSize } from '../KitTypes'
import TimePickerDropdownContent from './TimePickerDropdownContent'
import {
  getSecondsFromTime,
  getSecondsFromString,
  getTimeFromString,
  getStringFromTime,
  getTimeFromSeconds,
} from './helper'
import { InternalTime, TimeFormat } from './types'

export interface ITimePickerProps {
  value?: number | null
  size?: KitSize
  disabled?: boolean
  onChange: (value: number | null) => void
  onFocus?: () => void
  onBlur?: () => void
  format?: TimeFormat
  hideIcon?: boolean
  maxTime?: number | null
  minTime?: number | null
  onReset?: () => void
  placeholder?: string
  autoFocus?: boolean
  // error?: boolean // TODO
  name: string
}

const TimePicker = ({
  value,
  size,
  disabled,
  onChange,
  format = 'HH:mm:ss',
  onFocus,
  onBlur,
  hideIcon,
  maxTime,
  minTime,
  onReset,
  placeholder = t('uiKit.timePicker.placeholder'),
  autoFocus,
  name,
}: ITimePickerProps) => {
  const [open, setOpen] = useState(false)
  const [focus, setFocus] = useState(false)
  const initialValue = value ? secondsToTime(value, format) : undefined
  const [stateValue, setStateValue] = useState<string | undefined>(initialValue)
  const inputRef = useRef<HTMLInputElement>(null)
  const time = useMemo(
    () => getTimeFromString(stateValue || '00:00:00', format),
    [stateValue, format],
  )

  const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    const numericValue = inputValue.replace(/\D/g, '')
    const formattedValue = numericValue.replace(/(\d{2})/g, '$1:').slice(0, format.length)
    const timeInSec = getSecondsFromString(formattedValue, format)
    const newValue = Math.min(timeInSec, Math.trunc(maxTime || Infinity))
    setStateValue(formattedValue)

    const cursorPosition = event.target.selectionStart || 0
    const newPosition = cursorPosition + Math.ceil(formattedValue.length / 2) - 1
    setCursorPosition(newPosition)
    if (inputValue.length === format.length) {
      onChange(newValue)
    }
  }

  const setCursorPosition = (position: number) => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(position, position)
      }
    }, 0)
  }

  const handleReset = () => {
    setStateValue('')
    setCursorPosition(0)
    onChange(null)
  }
  const editing = focus || open

  const internalChange = (newValue: InternalTime) => {
    onChange(getSecondsFromTime(newValue))
    setStateValue(getStringFromTime(newValue, format))
  }

  const onStartEdit = () => {
    setFocus(true)
  }
  const onEndEdit = () => {
    setFocus(false)
  }
  const onOpen = (value: boolean) => {
    setOpen(value)
  }

  useEffect(() => {
    if (!editing && typeof value === 'number' && stateValue !== secondsToTime(value, format)) {
      setStateValue(getStringFromTime(getTimeFromSeconds(value), format))
    }
  }, [value, format, stateValue, editing])

  useEffect(() => {
    if (editing) {
      onFocus?.()
    } else {
      onBlur?.()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing /* , onFocus, onBlur */])

  return (
    <Dropdown
      disabled={disabled}
      onVisibleChange={onOpen}
      overlay={
        <TimePickerDropdownContent
          format={format}
          maxTime={maxTime}
          minTime={minTime}
          setNewValue={internalChange}
          value={time}
        />
      }
      overlayStyle={{ minWidth: 'unset' }}
      styleType='clear'
      visible={open}
      closeOnRootClick
    >
      <Input
        autoComplete='off'
        autoFocus={autoFocus}
        disabled={disabled}
        name={name}
        onBlur={onEndEdit}
        onChangeEvent={handleInputOnChange}
        onFocus={onStartEdit}
        parser={(value) => (value.length > format.length ? value.slice(0, format.length) : value)}
        pattern='\d*'
        placeholder={placeholder}
        postfix={
          disabled ? undefined : (
            <AdditionWrapper hideIcon={hideIcon}>
              <IconButton
                disabled={disabled}
                icon={stateValue ? 'otherClose' : 'otherClock'}
                name={stateValue ? 'reset' : 'clock'}
                onClick={onReset || handleReset}
                size={KitSize.XS}
                styleType='ghost'
                type='button'
              />
            </AdditionWrapper>
          )
        }
        ref={inputRef}
        size={size}
        value={stateValue}
      />
    </Dropdown>
  )
}

export default React.memo(TimePicker)
