import { useHistory, useRouteMatch } from 'react-router'

import { ITabItemProps } from './TabMenu'

export type ITabItem<V = string> = ITabItemProps<V> & { path: string }

interface IUseRouterTabs<T extends ITabItem<V>[], V> {
  tabList: T
  isExact?: boolean
}

export const useRouterTabs = <V extends string, T extends ITabItem<V>[]>({
  tabList,
  isExact,
}: IUseRouterTabs<T, V>) => {
  const history = useHistory()
  const tabsPaths = tabList.map(({ path }) => path)
  const match = useRouteMatch({ path: tabsPaths, exact: isExact })

  const activeKey = tabList.find(({ path }) => path === match?.path)?.value || tabList[0].value

  const onChange = (tab: V) => {
    const pathByTab = tabList.find(({ value }) => value === tab)?.path
    if (pathByTab) {
      history.replace(pathByTab)
    }
  }

  return { activeKey, onChange, tabList }
}
