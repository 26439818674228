import cn from 'classnames'
import { useEffect, useRef } from 'react'
import { Transforms } from 'slate'
import { useSlate } from 'slate-react'

import { insertMention } from 'components/AppText/formatOperations/commands'
import { getMentionTarget, useAppTextSelector } from 'components/AppText/useAppTextSelector'
import { ScrollRef } from 'components/LayoutPage/LayoutScroll/LayoutScroll'
import Employee, { AccountMock } from 'components/uiKit/Employee'
import { KitSize } from 'components/uiKit/KitTypes'
import { preventDefault } from 'constants/commonConstans'
import { testPropsEl } from 'utils/test/qaData'

import * as s from './MentionForm.module.scss'

interface IMentionItemProps {
  employee: AccountMock
  active?: boolean
  scrollRef: React.RefObject<ScrollRef>
}

const MentionItem: React.FC<IMentionItemProps> = ({ employee, active, scrollRef }) => {
  const ref = useRef<HTMLDivElement>(null)
  const editor = useSlate()
  const mentionTarget = useAppTextSelector(getMentionTarget)

  const handleClick = () => {
    if (mentionTarget) {
      Transforms.select(editor, mentionTarget)
    }
    insertMention(editor, employee)
  }

  useEffect(() => {
    if (active && ref.current) {
      scrollRef.current
        ?.osInstance()
        ?.scroll({ el: ref.current, block: 'nearest', scroll: 'ifneeded' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  return (
    <div
      {...testPropsEl('mentionItem', { label: employee.kUser.name })}
      className={cn(s.item, { [s.selected]: active })}
      key={employee.id}
      onClick={handleClick}
      onMouseDown={preventDefault}
      ref={ref}
    >
      <Employee employee={employee} size={KitSize.S} />
    </div>
  )
}

export default MentionItem
