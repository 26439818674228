import cn from 'classnames'
import { useMemo } from 'react'

import { DEPRECATED_Position } from '../../elements.types'
import * as s from './DemoWrapper.module.scss'

interface IProps {
  children?: React.ReactNode
  position?: DEPRECATED_Position
  center?: boolean
}

const getCellStyles = (position?: DEPRECATED_Position): React.CSSProperties => {
  if (!position) {
    return {}
  }

  const defaultCellSize = 32
  const { height = 0, width = 0, autoWidth, autoHeight } = position

  return {
    minHeight: height && height < defaultCellSize ? `1px` : `${defaultCellSize}px`,
    minWidth: width && width < defaultCellSize ? `1px` : `${defaultCellSize}px`,
    flexGrow: autoWidth ? 0 : 1,
    height: autoHeight ? 'auto' : height,
  }
}

const DemoWrapper: React.FC<IProps> = ({ position, children, center }) => {
  const cellStyles = useMemo(() => getCellStyles(position), [position])

  return (
    <div className={s.element} style={cellStyles}>
      <div className={cn(s.elementRoot, { [s.center]: center })}>{children}</div>
    </div>
  )
}

export default DemoWrapper
