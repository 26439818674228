import { BlockModeEnum } from 'gql/__generated__/graphql'

export enum ElementTab {
  layout = 'layout',
  element = 'element',
  fill = 'fill',
}

export enum NavigationEnum {
  project = 'project',
  section = 'section',
  block = 'block',
  templates = 'templates',
}

export enum CommentsTab {
  all = 'all',
  section = 'section',
}

export enum TasksTab {
  current = 'current',
  done = 'done',
}

export enum PreviewMode {
  editor = 'editor',
  preview = 'preview',
  publicLink = 'publicLink',
  skeleton = 'skeleton',
  scorm = 'scorm',
  demo = 'demo',
  pdf = 'pdf',
}

// export const CourseMode = {
//   app: 'app',
//   course: 'course', // TODO move course to lerna
//   pdf: 'pdf',
// } as const
// export type CourseMode = (typeof CourseMode)[keyof typeof CourseMode]

// export const BlockMode = {
//   ...CourseMode,
//   skeleton: 'skeleton',
//   demo: 'demo',
// }
// export type BlockMode = (typeof BlockMode)[keyof typeof BlockMode]

// export const AppMode = {
//   fill: 'fill',
//   edit: 'edit',
//   view: 'view',
//   task: 'task',
//   comment: 'comment',
// }
// export type AppMode = (typeof AppMode)[keyof typeof AppMode]

export enum DeviceMode {
  desktop = 'desktop',
  tablet = 'tablet',
  mobile = 'mobile',
}

export enum AppMode {
  pro = 'pro',
  fill = 'fill',
  // view = 'view',
  // task = 'task',
  comment = 'comment',
}

export enum SectionTypeEnum {
  landing = 'landing',
  test = 'test',
  chapter = 'chapter',
  cover = 'cover',
}

export const BlockMode = BlockModeEnum
export type BlockMode = (typeof BlockMode)[keyof typeof BlockMode]
