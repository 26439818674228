import React from 'react'

import { useActiveToolbar } from 'components/editor-v3/Editor/helpers/useActiveToolbar'
import { useBlockActions } from 'components/editor-v3/Editor/helpers/useBlockActions'
import { BlockActionUiEnum } from 'components/editor-v3/Editor/types'
import AbsolutePortal from 'components/editor-v3/components/AbsolutePortal'
import Button, { IconButton } from 'components/uiKit/Button'
import { KitSize } from 'components/uiKit/KitTypes'
import { optionToIconButton } from 'components/uiKit/Menu'
import { AppMode, BlockMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getBlock, getEditorMode, isBlocksMultiSelect } from 'services/Store/Project/selectors'
import { getEnumLabel } from 'utils/enum'

import ControlItem from '../ControlItem'
import ControlsGroup from '../ControlsGroup'
import Separator from '../Separator'

const BlockControls: React.FC<{ id: string }> = ({ id }) => {
  const { options, hasAction, handleAction } = useBlockActions()
  const isMultiSelect = useProjectContext(isBlocksMultiSelect)
  const activeToolbar = useActiveToolbar()

  const block = useProjectContext(getBlock, { id })

  const editorMode = useProjectContext(getEditorMode)

  if (!block) {
    return null
  }

  const addSeparate = editorMode === AppMode.fill && block.mode === BlockMode.view

  return (
    <>
      <AbsolutePortal
        name='blockControls'
        placement='topLeft'
        translateX='-1px'
        translateY='calc(-100% - 9px)'
      >
        <ControlsGroup>
          {hasAction(BlockActionUiEnum.GO_PREV, BlockActionUiEnum.GO_NEXT) && (
            <>
              <ControlItem handler={handleAction} option={options[BlockActionUiEnum.GO_PREV]} />
              <ControlItem handler={handleAction} option={options[BlockActionUiEnum.GO_NEXT]} />
              <Separator />
            </>
          )}
          {hasAction(BlockActionUiEnum.MOVE_UP, BlockActionUiEnum.MOVE_DOWN) && (
            <>
              <ControlItem handler={handleAction} option={options[BlockActionUiEnum.MOVE_UP]} />
              <ControlItem handler={handleAction} option={options[BlockActionUiEnum.MOVE_DOWN]} />
              <Separator />
            </>
          )}
          {!addSeparate && hasAction(BlockActionUiEnum.ADD_AFTER) && (
            <ControlItem handler={handleAction} option={options[BlockActionUiEnum.ADD_AFTER]} />
          )}
          {hasAction(BlockActionUiEnum.CLONE) && (
            <ControlItem handler={handleAction} option={options[BlockActionUiEnum.CLONE]} />
          )}
          {hasAction(BlockActionUiEnum.DELETE) && (
            <ControlItem handler={handleAction} option={options[BlockActionUiEnum.DELETE]} />
          )}
          {((!addSeparate && hasAction(BlockActionUiEnum.ADD_AFTER)) ||
            hasAction(BlockActionUiEnum.DELETE, BlockActionUiEnum.CLONE)) &&
            hasAction(
              BlockActionUiEnum.CHANGE,
              BlockActionUiEnum.SAVE_TEMPLATE,
              BlockActionUiEnum.SETTINGS,
            ) && <Separator />}
          {hasAction(BlockActionUiEnum.CHANGE) && (
            <ControlItem handler={handleAction} option={options[BlockActionUiEnum.CHANGE]} />
          )}
          {hasAction(BlockActionUiEnum.SAVE_TEMPLATE) && (
            <Button
              disabled={isMultiSelect}
              name={BlockActionUiEnum.SAVE_TEMPLATE}
              onClick={() => handleAction({ value: BlockActionUiEnum.SAVE_TEMPLATE })}
              size={KitSize.S}
              styleType='secondary'
            >
              {getEnumLabel('BlockActionUiEnum', BlockActionUiEnum.SAVE_TEMPLATE)}
            </Button>
          )}

          {hasAction(BlockActionUiEnum.SETTINGS) && (
            <ControlItem
              active={activeToolbar === BlockActionUiEnum.SETTINGS}
              handler={handleAction}
              option={options[BlockActionUiEnum.SETTINGS]}
            />
          )}
        </ControlsGroup>
      </AbsolutePortal>
      {addSeparate && hasAction(BlockActionUiEnum.ADD_BEFORE) && (
        <AbsolutePortal
          name='blockAddAbove'
          placement='top'
          translateY='calc(-50% - 1px)'
          zIndex={2}
        >
          <IconButton
            {...optionToIconButton(options[BlockActionUiEnum.ADD_BEFORE], handleAction)}
            size={KitSize.M}
            styleType='primary'
            round
          />
        </AbsolutePortal>
      )}
      {addSeparate && hasAction(BlockActionUiEnum.ADD_AFTER) && (
        <AbsolutePortal
          name='blockAddBelow'
          placement='bottom'
          translateY='calc(-50% + 1px)'
          zIndex={2}
        >
          <IconButton
            {...optionToIconButton(options[BlockActionUiEnum.ADD_AFTER], handleAction)}
            size={KitSize.M}
            styleType='primary'
            round
          />
        </AbsolutePortal>
      )}
    </>
  )
}

export default React.memo(BlockControls)
