import { Cell } from '@vedalib/editor/lib/elements'
import { DEFAULT_CELL_WIDTH } from '@vedalib/editor/lib/elements/elements/Table/constants'
import { textToRtValue } from '@vedalib/rich-text'

export const createColumn = () => ({ width: DEFAULT_CELL_WIDTH })

export const createCell = (): Cell => ({ value: textToRtValue('') })
export const createRow = (columns: number): Array<Cell> => Array(columns).fill(createCell())
export const delayedUpdate = (fn: () => void) => setTimeout(fn, 0)

export const isElementInViewport = (el: HTMLElement, container: HTMLElement) => {
  const rect = el.getBoundingClientRect()
  const containerRect = container.getBoundingClientRect()

  return (
    rect.top >= containerRect.top &&
    rect.left >= containerRect.left &&
    rect.left <= containerRect.right &&
    rect.top <= containerRect.bottom
  )
}
