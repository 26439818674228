import cn from 'classnames'
import * as R from 'ramda'
import React, { useMemo } from 'react'

import { CommentMock } from 'components/comments/types'
import { AccountMock, EmployeesGroup } from 'components/uiKit/Employee'
import { CommentStatus } from 'gql/__generated__/graphql'
import { notEmpty } from 'utils/notEmpty'

import * as s from './Authors.module.scss'

interface IAuthorsProps {
  comment: CommentMock
  maxCount?: number
  styleType?: 'base' | 'shifted'
  grey?: boolean | null
}

const Authors: React.FC<IAuthorsProps> = ({
  comment,
  maxCount = 4,
  styleType = 'shifted',
  grey,
}) => {
  const { children, createdBy, status, isRead } = comment
  const isResolved = status === CommentStatus.resolved

  const employees = useMemo(
    () =>
      R.compose<[(AccountMock | null | undefined)[]], AccountMock[], AccountMock[]>(
        R.uniqBy((item) => item?.id),
        R.filter<AccountMock | null, AccountMock>(notEmpty),
      )([createdBy, ...(children?.map((item) => item.createdBy) || [])]),
    [createdBy, children],
  )

  return (
    <div className={cn(s.root, { [s.resolved]: isResolved })}>
      <EmployeesGroup
        employees={employees}
        grey={Boolean(grey)}
        maxCount={maxCount}
        styleType={styleType}
        type='info'
      />
      {!isRead && <div className={s.dot} />}
    </div>
  )
}

export default Authors
