import { PermissionsKey } from '@vedalib/crud'
import cn from 'classnames'
import { AnimationProps, motion } from 'framer-motion'
import React, { useMemo } from 'react'

import EditorCommentBubble from 'components/comments/EditorCommentBubble'
import EditorCreateComment from 'components/comments/EditorCreateComment'
import { COURSE_TOOLTIP_TEST_MATCHING } from 'components/editor-v2/EditorElements/courseKit/portalsCurseKit'
import { AbsolutePortalProvider } from 'components/editor-v3/components/AbsolutePortal'
import CanvasTask from 'components/editor-v3/components/CanvasTask'
import { useEditorCanvasMouse } from 'components/editor-v3/context/EditorContext/actions'
import { getRootNode } from 'components/editor-v3/context/EditorContext/selectors/block'
import NodeContainer from 'components/editor-v3/cource/layout/Node/NodeContainer'
import { PortalTarget } from 'components/uiKit/Portal'
import { usePermissions } from 'services/Permission/PermissionContext'
import { scrollProps } from 'services/Scroll/ScrollService'
import { DeviceMode, AppMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import {
  getBlockWaiting,
  getIsActiveBlock,
  getIsSelectedBlock,
  getSection,
  getSettings,
  getSelectedBlocksCount,
  getBlockId,
  getIsCommentedBlock,
  getIsCommenting,
  getIsTaskedBlock,
} from 'services/Store/Project/selectors'
import { Block, IBlockMode } from 'services/Store/Project/types'
import { testProps } from 'utils/test/qaData'

import BlockControls from '../../controls/BlockControls/BlockControls'
import * as s from './EditorBlock.module.scss'

interface IEditorBlockProps {
  block: Block
  mode: IBlockMode
  index: number
  rootRef?: React.RefObject<HTMLDivElement>
}

const EditorBlock: React.FC<IEditorBlockProps> = ({ block, mode, index, rootRef }) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const canComment = usePermissions(PermissionsKey.project_comments_c)
  const root = getRootNode(block)
  const id = block.uuid
  const maxWidth = root?.style?.[DeviceMode.desktop]?.maxWidth
  const settings = useProjectContext(getSettings)
  const isPro = mode.editorMode === AppMode.pro
  const isFill = mode.editorMode !== AppMode.pro
  const isActive = useProjectContext(getIsActiveBlock, id)
  const task = useProjectContext(getIsTaskedBlock, id)
  const isSelected = useProjectContext(getIsSelectedBlock, id)
  const selectedCount = useProjectContext(getSelectedBlocksCount)
  const currentBlockId = useProjectContext(getBlockId)
  const isWaiting = useProjectContext(getBlockWaiting, id)
  const isCommented = useProjectContext(getIsCommentedBlock, block.uuid, null)
  const isCommenting = useProjectContext(getIsCommenting, block.uuid, null)
  const section = useProjectContext(getSection)
  const isLanding = section?.type === SectionTypeEnum.landing
  const showControls = isActive && selectedCount === 1 && isFill
  const animate: AnimationProps['animate'] = useMemo(() => {
    if (isPro && !isActive && currentBlockId) {
      return { opacity: 0.05 }
    }

    return { opacity: 1 }
  }, [currentBlockId, isActive, isPro])

  const mouseHandlers = useEditorCanvasMouse({ mode: mode.editorMode, blockId: id, nodeId: null })
  const active = isFill && isLanding && (isSelected || isActive)

  const cnWrapper = cn(s.wrapper, {
    [s.comments]: canComment && mode.editorMode === AppMode.comment,
  })
  const cnRoot = cn(s.root, {
    [s.active]: active,
    [s.waiting]: isWaiting,
    [s.commentsDeep]: canComment && mode.editorMode === AppMode.comment,
  })
  const cnBlock = cn(s.block, {
    [s.divider]: !active && isFill && settings.divider,
    [s.active]: active || isCommenting,
  })

  return (
    <div
      {...scrollProps(id)}
      {...mouseHandlers}
      className={cnWrapper}
      ref={ref}
      style={currentBlockId && isPro && !isActive ? { pointerEvents: 'none' } : {}}
    >
      <AbsolutePortalProvider viewport={rootRef}>
        <motion.div
          {...testProps({ el: 'editorBlock', index, id, name: block.name, active: isActive })}
          animate={animate}
          className={cnRoot}
        >
          <div className={cnBlock} style={{ maxWidth }}>
            <NodeContainer block={block} id={root.id} mode={mode} />
            {showControls && <BlockControls id={id} />}
            {isCommented && <EditorCommentBubble blockId={block.uuid} />}
            {isCommenting && <EditorCreateComment blockId={block.uuid} elementId={null} />}
            {task && <CanvasTask blockId={id} task={task} />}
          </div>
        </motion.div>
      </AbsolutePortalProvider>
      {/* eslint-disable-next-line react/forbid-component-props */}
      <PortalTarget className={s.tooltipPortal} id={COURSE_TOOLTIP_TEST_MATCHING} />
    </div>
  )
}

export default React.memo(EditorBlock)
