import { LabeledGraphicFontSchemaType } from '@vedalib/editor/lib/brand'
import cn from 'classnames'
import { AnimationDefinition } from 'framer-motion'
import React, { useCallback, useRef } from 'react'

import DropdownCourse from 'components/editor-v2/EditorElements/courseKit/DropDownCourse'
import { COURSE_TOOLTIP } from 'components/editor-v2/EditorElements/courseKit/portalsCurseKit'
import CourseSlider from 'components/editor-v3/cource/components/CourseSlider'
import { IconButton } from 'components/uiKit/Button'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { ElementFontCss } from 'services/Branding/types'
import { PreviewMode } from 'services/Store/Project/enums'
import { getImageByUsage } from 'utils/files'
import { extendTestData } from 'utils/test/qaData'

import { useLabeledGraphicContext } from '../LabeledGraphicContext'
import Point from '../Point'
import PointContent from '../Point/PointTooltip/PointContent'
import * as s from './MobilePointsView.module.scss'

interface IMobileViewProps {
  viewedPoints: Record<string, boolean>
  font: ElementFontCss<LabeledGraphicFontSchemaType>
}

// TODO: move to tooltip default animation
const OVERLAY_STYLE = { top: 0, left: 0, bottom: 0, right: 0 }
const ANIMATION_VARIANTS = {
  hidden: { opacity: 0, scale: 0.95 },
  visible: { opacity: 1, scale: 1 },
} as const
const TRANSITION = { duration: 0.3 }
const EXIT = { opacity: 0, scale: 0.95 }

const MobilePointsView: React.FC<IMobileViewProps> = ({ viewedPoints, font }) => {
  const toolTipRef = useRef<HTMLDivElement>(null)
  const getContainerInEditor = () => toolTipRef.current || document.body
  const getContainer = () => document.getElementById(COURSE_TOOLTIP) || document.body
  const styles = useLabeledGraphicContext((c) => c.styles)
  const mode = useLabeledGraphicContext((c) => c.mode)
  const element = useLabeledGraphicContext((c) => c.element)
  const setActivePoint = useLabeledGraphicContext((c) => c.setActivePoint)
  const activePoint = useLabeledGraphicContext((c) => c.activePoint)
  const activeIndex = element.value.items.findIndex((item) => item.value === activePoint)
  const isEditor = mode.previewMode === PreviewMode.editor
  const isFirst = activeIndex === 0
  const isLast = activeIndex === element.value.items.length - 1
  const isAnimated = styles.animation.fade && !isEditor
  const dropdownVisible = !!activePoint
  const animate = isAnimated ? (dropdownVisible ? 'visible' : 'hidden') : undefined
  const container = isEditor ? getContainerInEditor() : getContainer()

  const goPrev = useCallback(() => {
    const prevItem = element.value.items[activeIndex - 1]
    setActivePoint(prevItem?.value)
  }, [activeIndex, element.value.items, setActivePoint])

  const goNext = useCallback(() => {
    const nextItem = element.value.items[activeIndex + 1]
    setActivePoint(nextItem?.value)
  }, [activeIndex, element.value.items, setActivePoint])

  const onClosedropdown = useCallback(() => setActivePoint(null), [setActivePoint])

  const onAnimationComplete = useCallback(
    (definition: AnimationDefinition) => {
      if (definition === 'hidden') {
        setActivePoint(null)
      }
    },
    [setActivePoint],
  )

  const itemsContent = element.value.items?.map((item, index) => (
    <PointContent
      font={font}
      id={item.value}
      index={index}
      key={index}
      path={getImageByUsage(item.image)?.path}
      point={item}
    />
  ))

  return (
    <>
      <DropdownCourse
        animate={animate}
        container={container}
        exit={EXIT}
        initial='hidden'
        onAnimationComplete={onAnimationComplete}
        overlay={
          <div className={s.tooltip} style={{ ...styles.tooltip }}>
            <div className={s.close}>
              <IconButton
                icon='otherClose'
                name='closeTooltip'
                onClick={onClosedropdown}
                styleType='ghost'
              />
            </div>
            <div className={s.slider}>
              <CourseSlider
                activeIndex={activeIndex}
                goNext={goNext}
                goPrev={goPrev}
                items={itemsContent}
              />
            </div>
            {styles.navigation.switch && (
              <div className={s.navigation}>
                <Icon
                  className={cn(s.arrow, { [s.disabled]: isFirst })}
                  name='iconsForElementsLeft'
                  onClick={() => !isFirst && goPrev()}
                  size={KitSize.L}
                  testData={extendTestData({
                    index: activeIndex,
                    disabled: isFirst,
                  })}
                />
                {activeIndex + 1}/{element.value.items.length}
                <Icon
                  className={cn(s.arrow, { [s.disabled]: isLast })}
                  name='iconsForElementsRight'
                  onClick={() => !isLast && goNext()}
                  size={KitSize.L}
                  testData={extendTestData({
                    index: activeIndex,
                    disabled: isLast,
                  })}
                />
              </div>
            )}
          </div>
        }
        overlayStyle={OVERLAY_STYLE}
        styleType='clear'
        transition={TRANSITION}
        variants={ANIMATION_VARIANTS}
        visible={dropdownVisible}
      >
        <div>
          {element.value.items?.map((item, index) => (
            <div key={item.value} onClick={() => setActivePoint(item.value)}>
              <Point
                font={font}
                id={element.id}
                index={index}
                isActive={activePoint === item.value}
                isAnimated={styles.animation.pulse && !viewedPoints[item.value]}
                key={item.value}
                point={item}
                value={element.value}
              />
            </div>
          ))}
        </div>
      </DropdownCourse>
      <div ref={toolTipRef} />
    </>
  )
}

export default MobilePointsView
