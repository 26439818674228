import React, { useRef, useState } from 'react'

import Button, { IconButton } from 'components/uiKit/Button'
import Dropdown from 'components/uiKit/Dropdown'
import { IPlacement } from 'components/uiKit/Dropdown/types'
import Group from 'components/uiKit/Group'
import { KitSize, ValueType } from 'components/uiKit/KitTypes'
import Menu from 'components/uiKit/Menu'
import { IMenuOption } from 'components/uiKit/Menu/MenuItem'
import { t } from 'services/Translation'

interface ISortPopoverProps<T extends ValueType> {
  name: string
  value?: T
  onChange: (value: T) => void
  size?: KitSize
  options: IMenuOption<T>[]
  placement?: IPlacement
  isShort?: boolean
  defaultValue?: T
  disabled?: boolean
}

const SortPopover = <T extends ValueType>({
  name,
  value,
  defaultValue,
  options,
  size,
  isShort,
  placement = 'bottomLeft',
  onChange,
  disabled,
}: ISortPopoverProps<T>) => {
  const navRef = useRef<HTMLDivElement>(null)
  const isActive = value && (defaultValue ? value.toString() !== defaultValue.toString() : true)
  const [showSort, setShowSort] = useState(false)

  const handleChange = (item: IMenuOption<T>) => {
    setShowSort(false)
    onChange(item.value as T)
  }

  const handleReset = (e: React.MouseEvent) => {
    e.stopPropagation()
    setShowSort(false)
    onChange(null as T)
  }

  return (
    <Dropdown
      disabled={disabled}
      onVisibleChange={setShowSort}
      overlay={
        <Menu
          name={`${name}SortPopover`}
          onClick={handleChange}
          options={options}
          target={navRef.current}
          value={value}
        />
      }
      placement={placement}
      rootRef={navRef}
      styleType='clear'
      visible={showSort}
    >
      {isShort ? (
        <IconButton
          active={showSort || isActive}
          icon='sort'
          name='sort'
          size={size}
          styleType='ghost'
        />
      ) : (
        <Group join>
          <Button
            active={Boolean(isActive) || showSort}
            disabled={disabled}
            name={`${name}.sortButton`}
            size={size}
            styleType='ghost'
          >
            {t('uiKit.sortPopover.label')}
          </Button>
          {isActive && (
            <IconButton
              active={Boolean(isActive) || showSort}
              icon='otherClose'
              name='otherClose'
              onClick={handleReset}
              size={size}
              styleType='ghost'
            />
          )}
        </Group>
      )}
    </Dropdown>
  )
}

export default SortPopover
