import { useMemo, useRef } from 'react'
import { createContext, useContextSelector } from 'use-context-selector'

export interface IDropDownCourseContent {
  registerSubPopup: (id: string, node: HTMLElement) => void
  unregisterSubPopup: (id: string) => void
}

export const DropDownCourseContext = createContext<IDropDownCourseContent | null>(null)

const useDropDownCourseContextSelectors = () => {
  const registerSubPopup = useContextSelector(
    DropDownCourseContext,
    (context) => context?.registerSubPopup,
  )
  const unregisterSubPopup = useContextSelector(
    DropDownCourseContext,
    (context) => context?.unregisterSubPopup,
  )
  return useMemo(
    () => ({ unregisterSubPopup, registerSubPopup }),
    [unregisterSubPopup, registerSubPopup],
  )
}

export const useDropDownCourseContext = () => {
  const subPopupElements = useRef<Record<string, HTMLElement>>({})
  const parentContext = useDropDownCourseContextSelectors()
  const context = useMemo<IDropDownCourseContent>(() => {
    return {
      registerSubPopup: (id, subPopupEle) => {
        subPopupElements.current[id] = subPopupEle

        parentContext?.registerSubPopup?.(id, subPopupEle)
      },
      unregisterSubPopup: (id) => {
        delete subPopupElements.current[id]

        parentContext?.unregisterSubPopup?.(id)
      },
    }
  }, [parentContext])

  return { context, parentContext, subPopupElements }
}
