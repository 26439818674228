import { CONFIG_ELEMENTS } from '@vedalib/editor/lib/elements'

import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './CourseProgressBrandFormSchema'
import LayoutCourseProgressDemo from './CourseProgressDemo'
import Element from './CourseProgressElement'
import CourseProgressForm from './CourseProgressForm'
import icon from './assets/progress.svg'

export default genElementConfig(CONFIG_ELEMENTS.courseProgress, {
  icon,
  group: ElementGroup.cover,
  label: t('elements.courseProgress.label'),
  Element,
  ValueForm: CourseProgressForm,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  Demo: LayoutCourseProgressDemo,
})
