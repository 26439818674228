import lodash from 'lodash'

import { SortUiEnum } from 'components/uiKit/SortPopover/types'
import { EmployeePreferencesTab, TemplateType } from 'gql/__generated__/graphql'
import { BlockMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { t } from 'services/Translation'
import { getEnumLabel, getEnumOption } from 'utils/enum'

import { TemplateStepperState } from './types'

export const BASE_TAGS: Record<string, { value: TemplateType; label: string }[]> = {
  [SectionTypeEnum.landing]: [
    getEnumOption('TemplateType', TemplateType.ALL),
    getEnumOption('TemplateType', TemplateType.TEXT),
    getEnumOption('TemplateType', TemplateType.IMAGE),
    getEnumOption('TemplateType', TemplateType.LIST),
    getEnumOption('TemplateType', TemplateType.MULTIMEDIA),
    getEnumOption('TemplateType', TemplateType.INTERACTIVE),
    getEnumOption('TemplateType', TemplateType.QUOTE),
    getEnumOption('TemplateType', TemplateType.SEPARATORS),
    getEnumOption('TemplateType', TemplateType.AFFIRMATION),
    getEnumOption('TemplateType', TemplateType.QUESTIONS),
  ],
  [SectionTypeEnum.test]: [
    getEnumOption('TemplateType', TemplateType.START),
    getEnumOption('TemplateType', TemplateType.QUESTIONS),
    getEnumOption('TemplateType', TemplateType.END),
  ],
  [SectionTypeEnum.cover]: [getEnumOption('TemplateType', TemplateType.COVER)],
}

export const TABS: { value: EmployeePreferencesTab; label: string }[] = [
  getEnumOption('EmployeePreferencesTab', EmployeePreferencesTab.collections),
  getEnumOption('EmployeePreferencesTab', EmployeePreferencesTab.recently_used),
  getEnumOption('EmployeePreferencesTab', EmployeePreferencesTab.favorites),
]

export const singleBlockModes = [BlockMode.start, BlockMode.end, BlockMode.cover]

export const allTags = lodash.uniqBy(lodash.flatten(Object.values(BASE_TAGS)), 'value')

export function getTemplateStepperState(
  completed: boolean,
  disabled: boolean,
  alert: boolean,
  active: boolean,
) {
  if (completed) {
    return TemplateStepperState.completed
  }

  if (disabled) {
    return TemplateStepperState.disabled
  }

  if (alert) {
    return TemplateStepperState.alert
  }

  if (active) {
    return TemplateStepperState.active
  }

  return TemplateStepperState.notSelected
}

interface IBreadcrumbItem {
  id?: string
  name: string
}

export const generateBreadcrumbItems = (
  search?: string,
  activeCollection?: { id: string; name: string } | null,
  tab?: EmployeePreferencesTab,
): IBreadcrumbItem[] => {
  const baseBreadcrumbItems: IBreadcrumbItem[] = tab
    ? [{ name: getEnumLabel('EmployeePreferencesTab', tab) }]
    : [{ name: t('page.templates.title') }]

  if (activeCollection && tab === EmployeePreferencesTab.collections) {
    baseBreadcrumbItems.push({ id: activeCollection.id, name: activeCollection.name })
  }

  if (search) {
    baseBreadcrumbItems.push({ name: t('uiKit.breadcrumb.search'), id: 'search' })
  }

  return baseBreadcrumbItems
}

export const notLandingTab: string[] = [TemplateType.START, TemplateType.END]

export const SORT_OPTIONS = [
  getEnumOption('SortUiEnum', SortUiEnum.NAME_ASC),
  getEnumOption('SortUiEnum', SortUiEnum.NAME_DESC),
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const employeePreferencesToOrder = (order: any) => {
  return order && `${order.field}${lodash.capitalize(order.value)}`
}

export const SINGLE_MODE: string[] = [BlockMode.cover, BlockMode.end, BlockMode.start]

export const COUNT_LIMIT = 15
export const HORIZONTAL_GAP = 10
export const VERTICAL_GAP = 10
export const TEMPLATE_MIN_WIDTH = 298
export const SCALE_INITIAL = 0.9
export const SCALE_FINAL = 1
export const OPACITY_INITIAL = 0
export const OPACITY_FINAL = 1
export const TRANSITION_DURATION = 0.6
export const TRANSITION_EASE = [0.42, 0, 0.58, 1]
export const FAKE_SIZE = 100
