import { EditorElement } from '@vedalib/editor/lib/elements'

import { BlockMode, SectionTypeEnum } from 'services/Store/Project/enums'

import { getElementsPickerConfig } from './elementsPickerConfig'
import { testMode, viewMode, testConfig, testElementTypes, coverMode } from './helpers'

interface IFilterElementsProps {
  sectionType?: SectionTypeEnum
  mode?: BlockMode
  elements?: EditorElement[]
  element?: EditorElement | null
}

const filterTestElements = (
  mode?: BlockMode,
  sectionType?: SectionTypeEnum,
  elements?: EditorElement[],
  element?: EditorElement | null,
) => {
  const testAllElements = getElementsPickerConfig().filter((e) => testMode.includes(e.type))
  const excludedTypes = getExcludedTypes(elements, element)
  const validElem = sectionType && mode && testConfig[`${sectionType}|${mode}` as const]

  return testAllElements.map((elem) => ({
    ...elem,
    items: elem.items.filter(
      (item) => !excludedTypes.includes(item.type) && (!validElem || validElem.includes(item.type)),
    ),
  }))
}

export const getExcludedTypes = (
  usedElements?: { type: string }[],
  element?: { type: string } | null,
) => {
  const restrictTypes = [testElementTypes, ['testFeedback']].filter(
    (group) => !group.includes(element?.type || ''),
  )
  return restrictTypes.reduce((acc, group) => {
    if (usedElements?.some((elem) => group.includes(elem.type))) {
      return acc.concat(group)
    }

    return acc
  }, [])
}

export const filterElementsByBlockMode = ({
  sectionType,
  mode,
  elements,
  element,
}: IFilterElementsProps) => {
  const coverElements = getElementsPickerConfig().filter((e) => coverMode.includes(e.type))
  const landingElements = getElementsPickerConfig().filter((e) => viewMode.includes(e.type))
  const testElements = filterTestElements(mode, sectionType, elements, element)
  const testBlockElements = [...landingElements, ...testElements]

  const filteredElementsBlockTypeMap = {
    [BlockMode.start]: testBlockElements,
    [BlockMode.questions]: testBlockElements,
    [BlockMode.end]: testBlockElements,
    [BlockMode.cover]: coverElements,
    [BlockMode.view]: landingElements,
  }
  return mode ? filteredElementsBlockTypeMap[mode] : []
}
