import { EditorElement } from '@vedalib/editor/lib/elements'
import { useSize } from 'ahooks'
import * as R from 'ramda'
import React, { useRef } from 'react'

import EditorElementComponent from 'components/editor-v2/EditorElements/EditorElementComponent'
import { SKELETON_MODE } from 'services/Store/Project/constants'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getBlock } from 'services/Store/Project/selectors'

import * as s from './ScaledElementSkeleton.module.scss'

interface IProps {
  element: EditorElement
  width: number
  height: number
}

const RENDER_WIDTH = 400

const ScaledElementSkeleton: React.FC<IProps> = ({ element, width, height }) => {
  const scalableContainer = useRef<HTMLDivElement>(null)
  const { height: renderHeight = height || height } = useSize(scalableContainer) || {}
  const block = useProjectContext(getBlock)
  const elementNode = element && (
    <EditorElementComponent
      block={block!}
      element={element}
      mode={SKELETON_MODE}
      shellStyles={{}}
    />
  )

  const scaleH = height / renderHeight
  const scaleW = width / RENDER_WIDTH
  const scale = R.min(scaleH, scaleW)

  return (
    <div className={s.root} style={{ width, height: renderHeight * scale }}>
      <div style={{ width: RENDER_WIDTH, height: renderHeight }}>
        <div
          ref={scalableContainer}
          style={{
            transform: `scale(${scale})`,
            transformOrigin: '0 0',
          }}
        >
          {elementNode}
        </div>
      </div>
    </div>
  )
}

export default ScaledElementSkeleton
