import cn from 'classnames'
import React, { ReactNode } from 'react'

import { testProps } from 'utils/test/qaData'

import Tooltip from '../Dropdown/Tooltip'
import { KitDimensions, KitSize } from '../KitTypes'
import { KitIndents } from '../KitTypes'
import * as s from './Typography.module.scss'

const DEFAULT_WEIGHT = {
  base: 'regular',
  hint: 'regular',
  heading: 'medium',
  link: 'medium',
}

type HTMLSpan = Omit<
  React.HTMLAttributes<HTMLSpanElement>,
  'defaultValue' | 'onChange' | 'name' | 'onBlur' | 'onFocus'
>

export interface ITypographyProps extends HTMLSpan {
  children: ReactNode
  name?: string
  styleType?: 'base' | 'heading' | 'link' | 'hint'
  size?: KitSize
  weight?: 'regular' | 'medium' | 'semi' | 'bold'
  textAlign?: 'left' | 'center' | 'right'
  color?: string
  rows?: number
  tooltip?: React.ReactNode
  fluid?: boolean
  wordBreak?: React.CSSProperties['wordBreak']
  indents?: KitIndents
  sizes?: KitDimensions
  placeholder?: string
  onBlur?: () => void
  onFocus?: () => void
  focused?: boolean
}

const Typography = React.forwardRef<HTMLSpanElement, ITypographyProps>(
  (
    {
      children,
      name,
      styleType = 'base',
      size = KitSize.M,
      weight,
      color,
      rows: WebkitLineClamp,
      tooltip,
      fluid,
      wordBreak,
      indents,
      placeholder,
      sizes,
      textAlign = 'left',
      focused,
      ...rest
    },
    ref,
  ) => {
    const classnames = cn(
      s.root,
      s[styleType],
      s[size],
      s[`weight-${weight || DEFAULT_WEIGHT[styleType]}`],
      rest.onClick && [s.pointer],
      placeholder && [s.placeholder],
      fluid && [s.fluid],
    )

    return (
      <Tooltip fluid={fluid} mouseEnterDelay={1} overlay={tooltip}>
        <span
          {...testProps({ el: 'typography', name, focus: focused })}
          {...rest}
          className={classnames}
          data-placeholder={placeholder}
          ref={ref}
          style={{ ...indents, WebkitLineClamp, wordBreak, color, textAlign }}
        >
          {children}
        </span>
      </Tooltip>
    )
  },
)

Typography.displayName = 'Typography'
export default Typography
