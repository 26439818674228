// GENERATE BY ./generate.js
// DO NOT EDIT IT MANUALLY
import Group from '../icons/Group.svg?react'
import Add from '../icons/add.svg?react'
import AlertCheck from '../icons/alert-check.svg?react'
import AlertDangerTemp from '../icons/alert-danger-temp.svg?react'
import AlertDanger from '../icons/alert-danger.svg?react'
import AlertFailRed from '../icons/alert-fail-red.svg?react'
import AlertFailWhite from '../icons/alert-fail-white.svg?react'
import AlertInfo from '../icons/alert-info.svg?react'
import AlignmentAlignBottom from '../icons/alignment-align-bottom.svg?react'
import AlignmentAlignCenter from '../icons/alignment-align-center.svg?react'
import AlignmentAlignFull from '../icons/alignment-align-full.svg?react'
import AlignmentAlignLeft from '../icons/alignment-align-left.svg?react'
import AlignmentAlignMid from '../icons/alignment-align-mid.svg?react'
import AlignmentAlignRight from '../icons/alignment-align-right.svg?react'
import AlignmentAlignTop from '../icons/alignment-align-top.svg?react'
import Annotation from '../icons/annotation.svg?react'
import ArrowRight from '../icons/arrow-right.svg?react'
import ArrowTabs from '../icons/arrow-tabs.svg?react'
import BlocksFill from '../icons/blocks-fill.svg?react'
import Book from '../icons/book.svg?react'
import BookmarkFill from '../icons/bookmark-fill.svg?react'
import BookmarkStroke from '../icons/bookmark-stroke.svg?react'
import BookmarksDoubleFill from '../icons/bookmarks-double-fill.svg?react'
import BoxCollectionsArchive from '../icons/box-collections-archive.svg?react'
import BuilderAbsolut from '../icons/builder-absolut.svg?react'
import BuilderAddComponent from '../icons/builder-add-component.svg?react'
import BuilderAdd from '../icons/builder-add.svg?react'
import BuilderAi from '../icons/builder-ai.svg?react'
import BuilderAlignCenter from '../icons/builder-align-center.svg?react'
import BuilderAlignFullWidth from '../icons/builder-align-full-width.svg?react'
import BuilderAlignLeft from '../icons/builder-align-left.svg?react'
import BuilderAlignRight from '../icons/builder-align-right.svg?react'
import BuilderArrowDown from '../icons/builder-arrow-down.svg?react'
import BuilderArrowLeft from '../icons/builder-arrow-left.svg?react'
import BuilderArrowRight from '../icons/builder-arrow-right.svg?react'
import BuilderArrowScale from '../icons/builder-arrow-scale.svg?react'
import BuilderArrowUp from '../icons/builder-arrow-up.svg?react'
import BuilderBlockquote from '../icons/builder-blockquote.svg?react'
import BuilderBold from '../icons/builder-bold.svg?react'
import BuilderColumn from '../icons/builder-column.svg?react'
import BuilderContentModeSettings from '../icons/builder-content-mode-settings.svg?react'
import BuilderContent from '../icons/builder-content.svg?react'
import BuilderDesktop from '../icons/builder-desktop.svg?react'
import BuilderDrugAndDrop from '../icons/builder-drug-and-drop.svg?react'
import BuilderDrug from '../icons/builder-drug.svg?react'
import BuilderElement from '../icons/builder-element.svg?react'
import BuilderH1 from '../icons/builder-h-1.svg?react'
import BuilderH2 from '../icons/builder-h-2.svg?react'
import BuilderH3 from '../icons/builder-h-3.svg?react'
import BuilderH4 from '../icons/builder-h-4.svg?react'
import BuilderInputBack from '../icons/builder-input-back.svg?react'
import BuilderInputDown from '../icons/builder-input-down.svg?react'
import BuilderInputForward from '../icons/builder-input-forward.svg?react'
import BuilderInputUp from '../icons/builder-input-up.svg?react'
import BuilderItalic from '../icons/builder-italic.svg?react'
import BuilderLeftDown from '../icons/builder-left-down.svg?react'
import BuilderLeftUp from '../icons/builder-left-up.svg?react'
import BuilderListNumbers from '../icons/builder-list-numbers.svg?react'
import BuilderList from '../icons/builder-list.svg?react'
import BuilderLock from '../icons/builder-lock.svg?react'
import BuilderLower from '../icons/builder-lower.svg?react'
import BuilderMobile from '../icons/builder-mobile.svg?react'
import BuilderProMode from '../icons/builder-pro-mode.svg?react'
import BuilderPromode2 from '../icons/builder-promode-2.svg?react'
import BuilderRightDown from '../icons/builder-right-down.svg?react'
import BuilderRightUp from '../icons/builder-right-up.svg?react'
import BuilderRow from '../icons/builder-row.svg?react'
import BuilderSpace from '../icons/builder-space.svg?react'
import BuilderSwitchLeft from '../icons/builder-switch-left.svg?react'
import BuilderSwitchRight from '../icons/builder-switch-right.svg?react'
import BuilderSymbol from '../icons/builder-symbol.svg?react'
import BuilderTabBlocks from '../icons/builder-tab-blocks.svg?react'
import BuilderTabLayers from '../icons/builder-tab-layers.svg?react'
import BuilderTablet from '../icons/builder-tablet.svg?react'
import BuilderText from '../icons/builder-text.svg?react'
import BuilderTimeline from '../icons/builder-timeline.svg?react'
import BuilderTooltip from '../icons/builder-tooltip.svg?react'
import BuilderTypeParagraphCharacterAllCaps from '../icons/builder-type-paragraph-character-all-caps.svg?react'
import BuilderTypeParagraphCharacterTextSizeSmall from '../icons/builder-type-paragraph-character-text-size-small.svg?react'
import BuilderTypeParagraphCharacterTextSize from '../icons/builder-type-paragraph-character-text-size.svg?react'
import BuilderUnderline from '../icons/builder-underline.svg?react'
import BuilderUnderlinetextCross from '../icons/builder-underlinetext-cross.svg?react'
import BuilderUpper from '../icons/builder-upper.svg?react'
import BurgerMenu from '../icons/burger-menu.svg?react'
import Cc from '../icons/cc.svg?react'
import ChangeCompany from '../icons/change-company.svg?react'
import Checkbox from '../icons/checkbox.svg?react'
import Checkmark1 from '../icons/checkmark-1.svg?react'
import CheckmarkCircle1 from '../icons/checkmark-circle-1.svg?react'
import Collapse2Submenu2Reverse from '../icons/collapse-2-submenu-2-reverse.svg?react'
import Collapse2Submenu2 from '../icons/collapse-2-submenu-2.svg?react'
import CollapseOpen from '../icons/collapse-open.svg?react'
import ContentsChapter from '../icons/contents-chapter.svg?react'
import ContentsLanding from '../icons/contents-landing.svg?react'
import ContentsSlides from '../icons/contents-slides.svg?react'
import ContentsTest from '../icons/contents-test.svg?react'
import CourseAnswerCorrect from '../icons/course-answer-correct.svg?react'
import CourseAnswerIncorrect from '../icons/course-answer-incorrect.svg?react'
import CourseArrowBack from '../icons/course-arrow-back.svg?react'
import CourseArrowForward from '../icons/course-arrow-forward.svg?react'
import Dash from '../icons/dash.svg?react'
import Dots from '../icons/dots.svg?react'
import EmptyColor from '../icons/empty-color.svg?react'
import Error from '../icons/error.svg?react'
import EyeCloseEye from '../icons/eye-close-eye.svg?react'
import EyeOpenEye from '../icons/eye-open-eye.svg?react'
import FacebookOld from '../icons/facebookOld.svg?react'
import FixSpelling from '../icons/fix-spelling.svg?react'
import Folder from '../icons/folder.svg?react'
import Gear2 from '../icons/gear-2.svg?react'
import HeadphonesSupport from '../icons/headphones-support.svg?react'
import IconFillBell from '../icons/icon-fill-bell.svg?react'
import IconFillFolder from '../icons/icon-fill-folder.svg?react'
import IconFillOwner from '../icons/icon-fill-owner.svg?react'
import IconFillStatusMarked from '../icons/icon-fill-status-marked.svg?react'
import IconSendDisabled from '../icons/icon-send-disabled.svg?react'
import IconSend from '../icons/icon-send.svg?react'
import IconsBuilderLock from '../icons/icons-builder-lock.svg?react'
import IconsExpandLeft from '../icons/icons-expand-left.svg?react'
import IconsExpandRight from '../icons/icons-expand-right.svg?react'
import IconsForElementsDown from '../icons/icons-for-elements-down.svg?react'
import IconsForElementsLeftSlider from '../icons/icons-for-elements-left-slider.svg?react'
import IconsForElementsLeft from '../icons/icons-for-elements-left.svg?react'
import IconsForElementsRightSlider from '../icons/icons-for-elements-right-slider.svg?react'
import IconsForElementsRight from '../icons/icons-for-elements-right.svg?react'
import IconsForElementsRotateRight from '../icons/icons-for-elements-rotate-right.svg?react'
import IconsOtherAddColumn from '../icons/icons-other-add-column.svg?react'
import IconsOtherAddRow from '../icons/icons-other-add-row.svg?react'
import IconsOtherAudio from '../icons/icons-other-audio.svg?react'
import IconsOtherBoxHome from '../icons/icons-other-box-home.svg?react'
import IconsOtherButton from '../icons/icons-other-button.svg?react'
import IconsOtherCode from '../icons/icons-other-code.svg?react'
import IconsOtherDistribution from '../icons/icons-other-distribution.svg?react'
import IconsOtherElementCards from '../icons/icons-other-element-cards.svg?react'
import IconsOtherEmbedCode from '../icons/icons-other-embed-code.svg?react'
import IconsOtherFigure from '../icons/icons-other-figure.svg?react'
import IconsOtherFolderCheckmark from '../icons/icons-other-folder-checkmark.svg?react'
import IconsOtherGraphic from '../icons/icons-other-graphic.svg?react'
import IconsOtherHeader from '../icons/icons-other-header.svg?react'
import IconsOtherLanguageTranslateCircle from '../icons/icons-other-language-translate-circle.svg?react'
import IconsOtherLine from '../icons/icons-other-line.svg?react'
import IconsOtherMarginsLandingPages from '../icons/icons-other-margins-landing-pages.svg?react'
import IconsOtherMarginsTestPages from '../icons/icons-other-margins-test-pages.svg?react'
import IconsOtherMore from '../icons/icons-other-more.svg?react'
import IconsOtherMoveTo from '../icons/icons-other-move-to.svg?react'
import IconsOtherNavigationButtons from '../icons/icons-other-navigation-buttons.svg?react'
import IconsOtherNullBg from '../icons/icons-other-null-bg.svg?react'
import IconsOtherProgress from '../icons/icons-other-progress.svg?react'
import IconsOtherResultDescription from '../icons/icons-other-result-description.svg?react'
import IconsOtherResultMarkers from '../icons/icons-other-result-markers.svg?react'
import IconsOtherRow from '../icons/icons-other-row.svg?react'
import IconsOtherSidebar from '../icons/icons-other-sidebar.svg?react'
import IconsOtherSingleChoose from '../icons/icons-other-single-choose.svg?react'
import IconsOtherSlider from '../icons/icons-other-slider.svg?react'
import IconsOtherSwitch from '../icons/icons-other-switch.svg?react'
import IconsOtherTableEditTableEraser from '../icons/icons-other-table-edit-table-eraser.svg?react'
import IconsOtherTable from '../icons/icons-other-table.svg?react'
import IconsOtherTabs from '../icons/icons-other-tabs.svg?react'
import IconsOtherVideo from '../icons/icons-other-video.svg?react'
import IllustrationPageLock from '../icons/illustration-page-lock.svg?react'
import ImagePicture from '../icons/image-picture.svg?react'
import ImproveWriting from '../icons/improve-writing.svg?react'
import InterfaceEssentialAnchor from '../icons/interface-essential-anchor.svg?react'
import LayersFill from '../icons/layers-fill.svg?react'
import LifeRingHelpSupportBug from '../icons/life-ring-help-support-bug.svg?react'
import Link2 from '../icons/link-2.svg?react'
import LinkedinOld from '../icons/linkedinOld.svg?react'
import MainMenuBackButton from '../icons/main-menu-back-button.svg?react'
import MainMenuBell from '../icons/main-menu-bell.svg?react'
import MainMenuBranding from '../icons/main-menu-branding.svg?react'
import MainMenuDashboard from '../icons/main-menu-dashboard.svg?react'
import MainMenuFiles from '../icons/main-menu-files.svg?react'
import MainMenuFont from '../icons/main-menu-font.svg?react'
import MainMenuProjects from '../icons/main-menu-projects.svg?react'
import MainMenuSettings from '../icons/main-menu-settings.svg?react'
import MainMenuTags from '../icons/main-menu-tags.svg?react'
import MainMenuTasks from '../icons/main-menu-tasks.svg?react'
import MainMenuUsers from '../icons/main-menu-users.svg?react'
import MakeLonger from '../icons/make-longer.svg?react'
import MakeShorter from '../icons/make-shorter.svg?react'
import MenuBurgerCircle2 from '../icons/menu-burger-circle-2.svg?react'
import MenuBurgerSquare from '../icons/menu-burger-square.svg?react'
import Menu from '../icons/menu.svg?react'
import MessageFilterApplied from '../icons/message-filter-applied.svg?react'
import MessageFilter from '../icons/message-filter.svg?react'
import MessagesChatRegular from '../icons/messages-chat-regular.svg?react'
import MessagesPickerApplied from '../icons/messages-picker-applied.svg?react'
import MessagesPicker from '../icons/messages-picker.svg?react'
import MessagesReply from '../icons/messages-reply.svg?react'
import MissingFont from '../icons/missing-font.svg?react'
import OpenLink from '../icons/open-link.svg?react'
import OtherAdd from '../icons/other-add.svg?react'
import OtherAlert from '../icons/other-alert.svg?react'
import OtherArchive from '../icons/other-archive.svg?react'
import OtherAsk from '../icons/other-ask.svg?react'
import OtherBack from '../icons/other-back.svg?react'
import OtherBg from '../icons/other-bg.svg?react'
import OtherBlock from '../icons/other-block.svg?react'
import OtherBrokenLink from '../icons/other-broken-link.svg?react'
import OtherBurger from '../icons/other-burger.svg?react'
import OtherCalendar from '../icons/other-calendar.svg?react'
import OtherCards from '../icons/other-cards.svg?react'
import OtherCircleCrop from '../icons/other-circle-crop.svg?react'
import OtherClock from '../icons/other-clock.svg?react'
import OtherClose from '../icons/other-close.svg?react'
import OtherCopy from '../icons/other-copy.svg?react'
import OtherCrop from '../icons/other-crop.svg?react'
import OtherDown from '../icons/other-down.svg?react'
import OtherDownload from '../icons/other-download.svg?react'
import OtherEnter from '../icons/other-enter.svg?react'
import OtherFile from '../icons/other-file.svg?react'
import OtherFolder from '../icons/other-folder.svg?react'
import OtherFont from '../icons/other-font.svg?react'
import OtherFullScreen from '../icons/other-full-screen.svg?react'
import OtherImage from '../icons/other-image.svg?react'
import OtherLeft from '../icons/other-left.svg?react'
import OtherLinkToResource from '../icons/other-link-to-resource.svg?react'
import OtherList from '../icons/other-list.svg?react'
import OtherLock from '../icons/other-lock.svg?react'
import OtherMessages from '../icons/other-messages.svg?react'
import OtherPause from '../icons/other-pause.svg?react'
import OtherPlay from '../icons/other-play.svg?react'
import OtherRefresh from '../icons/other-refresh.svg?react'
import OtherReload from '../icons/other-reload.svg?react'
import OtherReset from '../icons/other-reset.svg?react'
import OtherRight from '../icons/other-right.svg?react'
import OtherSmallScreen from '../icons/other-small-screen.svg?react'
import OtherSort from '../icons/other-sort.svg?react'
import OtherSquare from '../icons/other-square.svg?react'
import OtherSwitch from '../icons/other-switch.svg?react'
import OtherTask from '../icons/other-task.svg?react'
import OtherText from '../icons/other-text.svg?react'
import OtherTrashDeleteBin24Px from '../icons/other-trash-delete-bin-24-px.svg?react'
import OtherTrashDeleteBin from '../icons/other-trash-delete-bin.svg?react'
import OtherTrash from '../icons/other-trash.svg?react'
import OtherUndo from '../icons/other-undo.svg?react'
import OtherUp from '../icons/other-up.svg?react'
import OtherZoom from '../icons/other-zoom.svg?react'
import PasswordFail from '../icons/password-fail.svg?react'
import PasswordSuccess from '../icons/password-success.svg?react'
import PenEdit from '../icons/pen-edit.svg?react'
import PinFill from '../icons/pin-fill.svg?react'
import Pin from '../icons/pin.svg?react'
import Play2 from '../icons/play-2.svg?react'
import PlayLoading from '../icons/play-loading.svg?react'
import Play from '../icons/play.svg?react'
import PortfolioOld from '../icons/portfolioOld.svg?react'
import Prompt from '../icons/prompt.svg?react'
import ProtectionSecurityKey from '../icons/protection-security-key.svg?react'
import RightPanelCollapse from '../icons/right panel collapse.svg?react'
import SearchField from '../icons/search-field.svg?react'
import Send from '../icons/send.svg?react'
import Settings from '../icons/settings.svg?react'
import SimplifyLanguage from '../icons/simplify-language.svg?react'
import SingleUserSales from '../icons/single-user-sales.svg?react'
import Solid from '../icons/solid.svg?react'
import Sort from '../icons/sort.svg?react'
import Spinner from '../icons/spinner.svg?react'
import Splitter from '../icons/splitter.svg?react'
import StatusApply from '../icons/status-apply.svg?react'
import StatusBug from '../icons/status-bug.svg?react'
import StatusComments from '../icons/status-comments.svg?react'
import StatusDanger20Px from '../icons/status-danger-20-px.svg?react'
import StatusDeadline20Px from '../icons/status-deadline-20-px.svg?react'
import StatusFail20Px from '../icons/status-fail-20-px.svg?react'
import StatusFail from '../icons/status-fail.svg?react'
import StatusGear24Px from '../icons/status-gear-24-px.svg?react'
import StatusHide20Px from '../icons/status-hide-20-px.svg?react'
import StatusLink20Px from '../icons/status-link-20-px.svg?react'
import StatusLock20Px from '../icons/status-lock-20-px.svg?react'
import StatusPending from '../icons/status-pending.svg?react'
import StatusSuccess20Px from '../icons/status-success-20-px.svg?react'
import StatusSumm20Px from '../icons/status-summ-20-px.svg?react'
import StatusSumm24Px from '../icons/status-summ-24-px.svg?react'
import StopPlay from '../icons/stop-Play.svg?react'
import Success from '../icons/success.svg?react'
import TaskBacklog from '../icons/task-backlog.svg?react'
import TaskDone from '../icons/task-done.svg?react'
import TaskInProgress from '../icons/task-in-progress.svg?react'
import TaskNew from '../icons/task-new.svg?react'
import TaskReview from '../icons/task-review.svg?react'
import TestResultsNumberOfAttempts from '../icons/test-results-number-of-attempts.svg?react'
import TestResultsPercent from '../icons/test-results-percent.svg?react'
import TestResultsRightQuestions from '../icons/test-results-right-questions.svg?react'
import TestResultsTestTime from '../icons/test-results-test-time.svg?react'
import TextDown from '../icons/text-down.svg?react'
import TrashDeleteBin from '../icons/trash-delete-bin.svg?react'
import TwitterOld from '../icons/twitterOld.svg?react'
import UserApply from '../icons/user-apply.svg?react'
import UserBlock from '../icons/user-block.svg?react'
import UserEmailSentOld from '../icons/user-email-sentOld.svg?react'
import UserValidatedOld from '../icons/user-validatedOld.svg?react'
import VolumeHalf from '../icons/volume-half.svg?react'

export const ICON_MAP = {
  group: Group,
  add: Add,
  alertCheck: AlertCheck,
  alertDangerTemp: AlertDangerTemp,
  alertDanger: AlertDanger,
  alertFailRed: AlertFailRed,
  alertFailWhite: AlertFailWhite,
  alertInfo: AlertInfo,
  alignmentAlignBottom: AlignmentAlignBottom,
  alignmentAlignCenter: AlignmentAlignCenter,
  alignmentAlignFull: AlignmentAlignFull,
  alignmentAlignLeft: AlignmentAlignLeft,
  alignmentAlignMid: AlignmentAlignMid,
  alignmentAlignRight: AlignmentAlignRight,
  alignmentAlignTop: AlignmentAlignTop,
  annotation: Annotation,
  arrowRight: ArrowRight,
  arrowTabs: ArrowTabs,
  blocksFill: BlocksFill,
  book: Book,
  bookmarkFill: BookmarkFill,
  bookmarkStroke: BookmarkStroke,
  bookmarksDoubleFill: BookmarksDoubleFill,
  boxCollectionsArchive: BoxCollectionsArchive,
  builderAbsolut: BuilderAbsolut,
  builderAddComponent: BuilderAddComponent,
  builderAdd: BuilderAdd,
  builderAi: BuilderAi,
  builderAlignCenter: BuilderAlignCenter,
  builderAlignFullWidth: BuilderAlignFullWidth,
  builderAlignLeft: BuilderAlignLeft,
  builderAlignRight: BuilderAlignRight,
  builderArrowDown: BuilderArrowDown,
  builderArrowLeft: BuilderArrowLeft,
  builderArrowRight: BuilderArrowRight,
  builderArrowScale: BuilderArrowScale,
  builderArrowUp: BuilderArrowUp,
  builderBlockquote: BuilderBlockquote,
  builderBold: BuilderBold,
  builderColumn: BuilderColumn,
  builderContentModeSettings: BuilderContentModeSettings,
  builderContent: BuilderContent,
  builderDesktop: BuilderDesktop,
  builderDrugAndDrop: BuilderDrugAndDrop,
  builderDrug: BuilderDrug,
  builderElement: BuilderElement,
  builderH1: BuilderH1,
  builderH2: BuilderH2,
  builderH3: BuilderH3,
  builderH4: BuilderH4,
  builderInputBack: BuilderInputBack,
  builderInputDown: BuilderInputDown,
  builderInputForward: BuilderInputForward,
  builderInputUp: BuilderInputUp,
  builderItalic: BuilderItalic,
  builderLeftDown: BuilderLeftDown,
  builderLeftUp: BuilderLeftUp,
  builderListNumbers: BuilderListNumbers,
  builderList: BuilderList,
  builderLock: BuilderLock,
  builderLower: BuilderLower,
  builderMobile: BuilderMobile,
  builderProMode: BuilderProMode,
  builderPromode2: BuilderPromode2,
  builderRightDown: BuilderRightDown,
  builderRightUp: BuilderRightUp,
  builderRow: BuilderRow,
  builderSpace: BuilderSpace,
  builderSwitchLeft: BuilderSwitchLeft,
  builderSwitchRight: BuilderSwitchRight,
  builderSymbol: BuilderSymbol,
  builderTabBlocks: BuilderTabBlocks,
  builderTabLayers: BuilderTabLayers,
  builderTablet: BuilderTablet,
  builderText: BuilderText,
  builderTimeline: BuilderTimeline,
  builderTooltip: BuilderTooltip,
  builderTypeParagraphCharacterAllCaps: BuilderTypeParagraphCharacterAllCaps,
  builderTypeParagraphCharacterTextSizeSmall: BuilderTypeParagraphCharacterTextSizeSmall,
  builderTypeParagraphCharacterTextSize: BuilderTypeParagraphCharacterTextSize,
  builderUnderline: BuilderUnderline,
  builderUnderlinetextCross: BuilderUnderlinetextCross,
  builderUpper: BuilderUpper,
  burgerMenu: BurgerMenu,
  cc: Cc,
  changeCompany: ChangeCompany,
  checkbox: Checkbox,
  checkmark1: Checkmark1,
  checkmarkCircle1: CheckmarkCircle1,
  collapse2Submenu2Reverse: Collapse2Submenu2Reverse,
  collapse2Submenu2: Collapse2Submenu2,
  collapseOpen: CollapseOpen,
  contentsChapter: ContentsChapter,
  contentsLanding: ContentsLanding,
  contentsSlides: ContentsSlides,
  contentsTest: ContentsTest,
  courseAnswerCorrect: CourseAnswerCorrect,
  courseAnswerIncorrect: CourseAnswerIncorrect,
  courseArrowBack: CourseArrowBack,
  courseArrowForward: CourseArrowForward,
  dash: Dash,
  dots: Dots,
  emptyColor: EmptyColor,
  error: Error,
  eyeCloseEye: EyeCloseEye,
  eyeOpenEye: EyeOpenEye,
  facebookOld: FacebookOld,
  fixSpelling: FixSpelling,
  folder: Folder,
  gear2: Gear2,
  headphonesSupport: HeadphonesSupport,
  iconFillBell: IconFillBell,
  iconFillFolder: IconFillFolder,
  iconFillOwner: IconFillOwner,
  iconFillStatusMarked: IconFillStatusMarked,
  iconSendDisabled: IconSendDisabled,
  iconSend: IconSend,
  iconsBuilderLock: IconsBuilderLock,
  iconsExpandLeft: IconsExpandLeft,
  iconsExpandRight: IconsExpandRight,
  iconsForElementsDown: IconsForElementsDown,
  iconsForElementsLeftSlider: IconsForElementsLeftSlider,
  iconsForElementsLeft: IconsForElementsLeft,
  iconsForElementsRightSlider: IconsForElementsRightSlider,
  iconsForElementsRight: IconsForElementsRight,
  iconsForElementsRotateRight: IconsForElementsRotateRight,
  iconsOtherAddColumn: IconsOtherAddColumn,
  iconsOtherAddRow: IconsOtherAddRow,
  iconsOtherAudio: IconsOtherAudio,
  iconsOtherBoxHome: IconsOtherBoxHome,
  iconsOtherButton: IconsOtherButton,
  iconsOtherCode: IconsOtherCode,
  iconsOtherDistribution: IconsOtherDistribution,
  iconsOtherElementCards: IconsOtherElementCards,
  iconsOtherEmbedCode: IconsOtherEmbedCode,
  iconsOtherFigure: IconsOtherFigure,
  iconsOtherFolderCheckmark: IconsOtherFolderCheckmark,
  iconsOtherGraphic: IconsOtherGraphic,
  iconsOtherHeader: IconsOtherHeader,
  iconsOtherLanguageTranslateCircle: IconsOtherLanguageTranslateCircle,
  iconsOtherLine: IconsOtherLine,
  iconsOtherMarginsLandingPages: IconsOtherMarginsLandingPages,
  iconsOtherMarginsTestPages: IconsOtherMarginsTestPages,
  iconsOtherMore: IconsOtherMore,
  iconsOtherMoveTo: IconsOtherMoveTo,
  iconsOtherNavigationButtons: IconsOtherNavigationButtons,
  iconsOtherNullBg: IconsOtherNullBg,
  iconsOtherProgress: IconsOtherProgress,
  iconsOtherResultDescription: IconsOtherResultDescription,
  iconsOtherResultMarkers: IconsOtherResultMarkers,
  iconsOtherRow: IconsOtherRow,
  iconsOtherSidebar: IconsOtherSidebar,
  iconsOtherSingleChoose: IconsOtherSingleChoose,
  iconsOtherSlider: IconsOtherSlider,
  iconsOtherSwitch: IconsOtherSwitch,
  iconsOtherTableEditTableEraser: IconsOtherTableEditTableEraser,
  iconsOtherTable: IconsOtherTable,
  iconsOtherTabs: IconsOtherTabs,
  iconsOtherVideo: IconsOtherVideo,
  illustrationPageLock: IllustrationPageLock,
  imagePicture: ImagePicture,
  improveWriting: ImproveWriting,
  interfaceEssentialAnchor: InterfaceEssentialAnchor,
  layersFill: LayersFill,
  lifeRingHelpSupportBug: LifeRingHelpSupportBug,
  link2: Link2,
  linkedinOld: LinkedinOld,
  mainMenuBackButton: MainMenuBackButton,
  mainMenuBell: MainMenuBell,
  mainMenuBranding: MainMenuBranding,
  mainMenuDashboard: MainMenuDashboard,
  mainMenuFiles: MainMenuFiles,
  mainMenuFont: MainMenuFont,
  mainMenuProjects: MainMenuProjects,
  mainMenuSettings: MainMenuSettings,
  mainMenuTags: MainMenuTags,
  mainMenuTasks: MainMenuTasks,
  mainMenuUsers: MainMenuUsers,
  makeLonger: MakeLonger,
  makeShorter: MakeShorter,
  menuBurgerCircle2: MenuBurgerCircle2,
  menuBurgerSquare: MenuBurgerSquare,
  menu: Menu,
  messageFilterApplied: MessageFilterApplied,
  messageFilter: MessageFilter,
  messagesChatRegular: MessagesChatRegular,
  messagesPickerApplied: MessagesPickerApplied,
  messagesPicker: MessagesPicker,
  messagesReply: MessagesReply,
  missingFont: MissingFont,
  openLink: OpenLink,
  otherAdd: OtherAdd,
  otherAlert: OtherAlert,
  otherArchive: OtherArchive,
  otherAsk: OtherAsk,
  otherBack: OtherBack,
  otherBg: OtherBg,
  otherBlock: OtherBlock,
  otherBrokenLink: OtherBrokenLink,
  otherBurger: OtherBurger,
  otherCalendar: OtherCalendar,
  otherCards: OtherCards,
  otherCircleCrop: OtherCircleCrop,
  otherClock: OtherClock,
  otherClose: OtherClose,
  otherCopy: OtherCopy,
  otherCrop: OtherCrop,
  otherDown: OtherDown,
  otherDownload: OtherDownload,
  otherEnter: OtherEnter,
  otherFile: OtherFile,
  otherFolder: OtherFolder,
  otherFont: OtherFont,
  otherFullScreen: OtherFullScreen,
  otherImage: OtherImage,
  otherLeft: OtherLeft,
  otherLinkToResource: OtherLinkToResource,
  otherList: OtherList,
  otherLock: OtherLock,
  otherMessages: OtherMessages,
  otherPause: OtherPause,
  otherPlay: OtherPlay,
  otherRefresh: OtherRefresh,
  otherReload: OtherReload,
  otherReset: OtherReset,
  otherRight: OtherRight,
  otherSmallScreen: OtherSmallScreen,
  otherSort: OtherSort,
  otherSquare: OtherSquare,
  otherSwitch: OtherSwitch,
  otherTask: OtherTask,
  otherText: OtherText,
  otherTrashDeleteBin24Px: OtherTrashDeleteBin24Px,
  otherTrashDeleteBin: OtherTrashDeleteBin,
  otherTrash: OtherTrash,
  otherUndo: OtherUndo,
  otherUp: OtherUp,
  otherZoom: OtherZoom,
  passwordFail: PasswordFail,
  passwordSuccess: PasswordSuccess,
  penEdit: PenEdit,
  pinFill: PinFill,
  pin: Pin,
  play2: Play2,
  playLoading: PlayLoading,
  play: Play,
  portfolioOld: PortfolioOld,
  prompt: Prompt,
  protectionSecurityKey: ProtectionSecurityKey,
  rightPanelCollapse: RightPanelCollapse,
  searchField: SearchField,
  send: Send,
  settings: Settings,
  simplifyLanguage: SimplifyLanguage,
  singleUserSales: SingleUserSales,
  solid: Solid,
  sort: Sort,
  spinner: Spinner,
  splitter: Splitter,
  statusApply: StatusApply,
  statusBug: StatusBug,
  statusComments: StatusComments,
  statusDanger20Px: StatusDanger20Px,
  statusDeadline20Px: StatusDeadline20Px,
  statusFail20Px: StatusFail20Px,
  statusFail: StatusFail,
  statusGear24Px: StatusGear24Px,
  statusHide20Px: StatusHide20Px,
  statusLink20Px: StatusLink20Px,
  statusLock20Px: StatusLock20Px,
  statusPending: StatusPending,
  statusSuccess20Px: StatusSuccess20Px,
  statusSumm20Px: StatusSumm20Px,
  statusSumm24Px: StatusSumm24Px,
  stopPlay: StopPlay,
  success: Success,
  taskBacklog: TaskBacklog,
  taskDone: TaskDone,
  taskInProgress: TaskInProgress,
  taskNew: TaskNew,
  taskReview: TaskReview,
  testResultsNumberOfAttempts: TestResultsNumberOfAttempts,
  testResultsPercent: TestResultsPercent,
  testResultsRightQuestions: TestResultsRightQuestions,
  testResultsTestTime: TestResultsTestTime,
  textDown: TextDown,
  trashDeleteBin: TrashDeleteBin,
  twitterOld: TwitterOld,
  userApply: UserApply,
  userBlock: UserBlock,
  userEmailSentOld: UserEmailSentOld,
  userValidatedOld: UserValidatedOld,
  volumeHalf: VolumeHalf,
}
