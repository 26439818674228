import { LinkElement, SlateElementType } from '@vedalib/rich-text'
import { Editor, Element, Transforms, Range, MaximizeMode } from 'slate'

import { validateLinkUrl } from 'utils/websiteValidation'

import { clearTextMark } from '../formatOperations/commands'

export const withInlines = (editor: Editor) => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = (element) => [SlateElementType.link].includes(element.type) || isInline(element)

  editor.insertText = (text) => {
    if (text && validateLinkUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = (data) => {
    const text = data.getData('text/plain')

    if (text && validateLinkUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

export const unwrapLink = (editor: Editor, mode?: MaximizeMode) => {
  const selection = editor.selection
  let at = undefined
  if (selection && mode === 'all') {
    const path = Editor.path(editor, selection)
    const start = Editor.start(editor, path)
    const end = Editor.end(editor, path)
    const range = { anchor: start, focus: end }
    // @ts-expect-error TODO
    at = { range }
  }
  Transforms.unwrapNodes(editor, {
    mode,
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === SlateElementType.link,
    at,
  })
}

const updateLink = (editor: Editor, url: string) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === SlateElementType.link,
  })
  Transforms.setNodes(editor, { url }, { at: link ? link[1] : undefined })
}

export const wrapLink = (editor: Editor, url: string) => {
  if (!url) {
    unwrapLink(editor)
    return
  }
  if (isLinkActive(editor)) {
    updateLink(editor, url)
    return
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link: LinkElement = {
    type: SlateElementType.link,
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  unwrapLink(editor)
  clearTextMark(editor)

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
  }
}

export const getLinkElement = (editor: Editor) => {
  if (!editor.selection) {
    return null
  }

  const [link] = Editor.nodes(editor, {
    at: editor.selection && Editor.start(editor, editor.selection),
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === SlateElementType.link,
  })
  return link?.[0]
}

export const getInlineElement = (editor: Editor) => {
  if (!editor.selection) {
    return null
  }

  const [inline] = Editor.nodes(editor, {
    at: editor.selection && Editor.start(editor, editor.selection),
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && editor.isInline(n),
  })
  return inline?.[0]
}

export const isLinkActive = (editor: Editor) => {
  return !!getLinkElement(editor)
}
