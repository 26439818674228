import { ButtonFontSchemaType, ButtonSchemaType } from '@vedalib/editor/lib/brand'
import { ButtonActionType, ButtonElementValue } from '@vedalib/editor/lib/elements'

import { DEMO_MODE } from 'services/Store/Project/constants'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import BaseButtonElement from './BaseButtonElement'

const DEMO_VALUE: ButtonElementValue = {
  label: t('elements.button.label'),
  actionType: ButtonActionType.navigation,
}

const ButtonDemo: React.FC<IElementDemoProps<ButtonSchemaType, ButtonFontSchemaType>> = ({
  styles,
  fonts,
}) => {
  return (
    <DemoWrapper>
      <BaseButtonElement font={fonts} label={DEMO_VALUE.label} mode={DEMO_MODE} styles={styles} />
      <BaseButtonElement
        font={fonts}
        label={DEMO_VALUE.label}
        mode={DEMO_MODE}
        styles={styles}
        disabled
      />
    </DemoWrapper>
  )
}

export default ButtonDemo
