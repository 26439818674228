import { DeepPartial } from '@vedalib/editor/lib/utils/types'
import { createContext } from 'use-context-selector'

import { KitSize } from 'components/uiKit/KitTypes'

import { FieldLayoutType, IFieldMeta, IOnChangeData } from '../Field/Field.types'
import { IFormMeta } from './types'

export type FormContextType<Data = unknown> = {
  initialValues: Data
  values: DeepPartial<Data>
  defaultValues?: DeepPartial<Data>
  layout?: FieldLayoutType
  size?: KitSize
  fullValues: Data
  inheritValues: Data
  onChange: (data: IOnChangeData) => void
  onChangeMeta: (name?: string, meta?: IFieldMeta) => void
  submit: () => void
  resetFields: () => void
  metas: Record<string, IFieldMeta>
  formMeta: IFormMeta
  submitting?: boolean
  disabled?: boolean
}

const FormContext = createContext<FormContextType>({
  initialValues: {},
  values: {},
  fullValues: {},
  defaultValues: {},
  inheritValues: {},

  onChange: () => console.error('Form context is not exist'),
  onChangeMeta: () => console.error('Form context is not exist'),
  submit: () => console.error('Form context is not exist'),
  resetFields: () => console.error('Form context is not exist'),

  metas: {},
  formMeta: { dirty: false, touched: false, valid: true, errors: [] },
  submitting: false,
})

export default FormContext
