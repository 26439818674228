import lodash from 'lodash'
import { useCallback } from 'react'

import { useProjectT } from 'services/ProjectLabels'
import { continueBlock } from 'services/Store/Project/actions'
import { PreviewMode } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getBlocksState, getBlockState, getSection } from 'services/Store/Project/selectors'

import BaseButtonElement from '../BaseButtonElement'
import { ButtonElementType } from '../ButtonElement.types'

const ContinueButtonElement: ButtonElementType = ({ element, block, mode, styles, font }) => {
  const pt = useProjectT()

  const dispatch = useProjectDispatch()
  const blockState = useProjectContext(getBlockState, block?.uuid)
  const blocks = useProjectContext(getBlocksState)
  const section = useProjectContext(getSection)
  const isPreview = mode.previewMode !== PreviewMode.editor

  const hasResult = Boolean(blockState?.result)

  const prevBlocks =
    section?.blocksOrder.slice(0, section?.blocksOrder.indexOf(block?.uuid || '') || 0) || []

  const lastBlock = lodash.last(prevBlocks || []) || ''
  const blocksState = lodash.pick(blocks, prevBlocks)
  const isSomePrevBlocksIncomplete = lodash.some(blocksState, (el) => Boolean(el?.incomplete))
  const isLastBlockIncomplete = Boolean(blocks[lastBlock]?.incomplete)

  const disabledCondition = {
    last: isLastBlockIncomplete,
    all: isSomePrevBlocksIncomplete,
    none: false,
  }

  const label = !hasResult ? element?.value?.label : element?.value?.secondLabel

  const disabled = disabledCondition[element.value.condition || 'none']

  const onClick = useCallback(() => {
    if (block) {
      dispatch(continueBlock({ blockId: block.uuid }))
    }
  }, [dispatch, block])

  if (blockState?.continueCompleted) {
    return null
  }

  return (
    <BaseButtonElement
      action={element.value.action}
      disabled={disabled}
      font={font}
      label={label}
      mode={mode}
      onClick={onClick}
      styles={styles}
      tooltip={disabled && isPreview ? pt('elements.button.continue.tooltip') : ''}
    />
  )
}

export default ContinueButtonElement
