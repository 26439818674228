import { TabsSchemaType } from '@vedalib/editor/lib/brand'
import styled from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

import { DirectionTabsEnum } from '../types'

const NavButtonStyled = styled.div<{
  $styles: ElementStyleCss<TabsSchemaType>
  $iconSide: DirectionTabsEnum
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${({ $styles }) => $styles.icons.backgroundColor};
  &:hover {
    background-color: ${({ $styles }) => $styles.iconsHover.backgroundColor};
  }
  background-image: ${({ $styles: { iconRight, iconLeft }, $iconSide }) =>
    $iconSide === DirectionTabsEnum.next ? iconRight.backgroundImage : iconLeft.backgroundImage};
`

export default NavButtonStyled
