// extracted by mini-css-extract-plugin
var _1 = "TrialLogo_centered__HHf6p";
var _2 = "TrialLogo_description__waC+M";
var _3 = "TrialLogo_footer__Mr8nW";
var _4 = "TrialLogo_link__jtU7V";
var _5 = "TrialLogo_logo__A0ALo";
var _6 = "TrialLogo_mobile__Z0qXf";
var _7 = "TrialLogo_root__3Kol7";
var _8 = "TrialLogo_tablet__svdrU";
var _9 = "TrialLogo_title__pOtdJ";
export { _1 as "centered", _2 as "description", _3 as "footer", _4 as "link", _5 as "logo", _6 as "mobile", _7 as "root", _8 as "tablet", _9 as "title" }
