import { t } from 'services/Translation'

import { IFieldRule } from './Field.types'

export const validateRules = <V>(value: unknown, rules?: IFieldRule<V>[]) => {
  if (!rules) {
    return false
  }

  const message = rules.reduce<string | false>((acc, rule) => {
    if (acc) {
      return acc
    }

    if (
      rule.required &&
      (value === null || value === undefined || (isArray(value) && value.length === 0))
    ) {
      return rule.message || t('input.error.requiredField')
    }

    if (isString(value)) {
      if (rule.required && value === '') {
        return rule.message || t('input.error.requiredField')
      }

      if (rule.whitespace && value[0] === ' ') {
        return rule.message || t('input.error.whitespace')
      }

      if (rule.min && value && value.length < rule.min) {
        return rule.message || t('input.error.minLength', { value: rule.min })
      }

      if (rule.max && value && value.length > rule.max) {
        return rule.message || t('input.error.maxLength', { value: rule.max })
      }

      if (rule.pattern && !rule.pattern.test(value)) {
        return rule.message || t('input.error.invalidFormat')
      }
    }

    if (isNumber(value)) {
      if (rule.min && value < rule.min) {
        return rule.message || t('input.error.min', { value: rule.min })
      }

      if (rule.max && value > rule.max) {
        return rule.message || t('input.error.max', { value: rule.max })
      }
    }

    if (rule.validator) {
      try {
        return rule.validator(rule, value as V)
      } catch (e) {
        //@ts-expect-error `e` has message property
        return e.message
      }
    }

    return false
  }, false)

  return message
}

const isString = (value: unknown): value is string => {
  return typeof value === 'string'
}

const isArray = (value: unknown): value is string[] => {
  return Array.isArray(value)
}

const isNumber = (value: unknown): value is number => {
  return typeof value === 'number'
}
