import React, { useCallback } from 'react'

import { IControlProps } from 'components/controls/Field/Field.types'
import Divider from 'components/uiKit/Divider'
import TimePicker, { ITimePickerProps } from 'components/uiKit/TimePicker/TimePicker'
import { determineTimeFormat } from 'utils/editorUtils'

import * as s from './FieldTimeRange.module.scss'

interface ITimeRangePickerProps extends Omit<ITimePickerProps, 'value'> {
  value?: [number, number] | null
}

const FieldTimeRange: React.FC<IControlProps<ITimeRangePickerProps>> = ({
  onChange,
  name,
  value,
  maxTime,
  size,
  ...rest
}) => {
  const [start = 0, finish = maxTime] = value || []
  const format = determineTimeFormat(finish || 0)

  const handleChange = useCallback(
    (value: [number, number]) => onChange({ value, name }),
    [name, onChange],
  )

  const handleTimeStart = useCallback(
    (value: number | null) => {
      handleChange([Math.min(value || 0, finish || Infinity), finish || 0])
    },
    [handleChange, finish],
  )

  const handleTimeFinish = useCallback(
    (value: number | null) => {
      handleChange([start || 0, Math.max(value || maxTime || 0, start)])
    },
    [handleChange, start, maxTime],
  )

  const handleResetStart = () => {
    handleChange([0, finish || maxTime || 0])
  }

  const handleResetFinish = () => {
    handleChange([start || 0, Math.trunc(maxTime || Infinity)])
  }

  return (
    <div className={s.root}>
      <TimePicker
        {...rest}
        format={format}
        maxTime={Math.min(finish || Infinity, maxTime || Infinity)}
        name='timeStart'
        onChange={handleTimeStart}
        onReset={handleResetStart}
        size={size}
        value={start}
        hideIcon
      />
      <Divider styleType='dash' />
      <TimePicker
        {...rest}
        format={format}
        maxTime={maxTime}
        minTime={start}
        name='timeEnd'
        onChange={handleTimeFinish}
        onReset={handleResetFinish}
        size={size}
        value={finish || 0}
        hideIcon
      />
    </div>
  )
}

export default FieldTimeRange
