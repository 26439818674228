import { PermissionsKey } from '@vedalib/crud'
import { colorsTypes } from 'utils'

import { genField } from 'components/controls/Field'
import { BASE_TAGS } from 'components/editor-v3/Editor/Modals/TemplatePickerModal/helper'
import { ISelectOption } from 'components/uiKit/Select'
import { BlockMode } from 'services/Store/Project/enums'
import { t } from 'services/Translation'
import { getEnumOption } from 'utils/enum'

const TEST_TAGS = [
  getEnumOption('BlockModeEnum', BlockMode.start),
  getEnumOption('BlockModeEnum', BlockMode.end),
]

export const name = (createdFor?: string, autoFocus?: boolean) =>
  genField({
    name: 'name',
    type: 'text',
    label: t('input.label.name'),
    params: {
      placeholder: t('input.placeholder.enterName'),
      autoFocus,
      disabled: createdFor === 'employee',
    },
    permission: {
      disabled: PermissionsKey.company_collections_company_u,
    },
    rules: [
      {
        required: true,
        message: t('input.error.required', { field: t('input.label.name') }),
      },
      {
        whitespace: true,
        message: t('input.error.whitespace'),
      },
      { max: 256 },
    ],
  })

export const color = genField({
  name: 'color',
  type: 'palette',
  label: t('input.label.borderColor'),
  params: { options: Object.values(colorsTypes) },
  permission: {
    disabled: PermissionsKey.company_collections_company_u,
  },
})

export const brand = (options: ISelectOption<string | null>[]) =>
  genField({
    type: 'select',
    name: 'brandId',
    label: t('input.label.branding'),
    layout: 'vertical',
    params: {
      options,
      dropdownType: 'dropdown',
      resettable: true,
      placeholder: t('common.platformName'),
      fluid: true,
    },
    permission: {
      disabled: PermissionsKey.company_collections_company_u,
    },
  })

export const templateName = genField({
  name: 'name',
  type: 'text',
  label: t('input.label.name'),
  params: { placeholder: t('input.placeholder.enterName'), autoFocus: true },
  permission: {
    disabled: PermissionsKey.company_collections_company_u,
  },
  rules: [
    {
      whitespace: true,
      message: t('input.error.whitespace'),
    },
    { max: 256 },
    { required: true },
  ],
})

export const tagsField = (mode?: string, required?: boolean) =>
  genField({
    label: t('input.label.elementGroup'),
    name: 'tags',
    type: 'select',
    params: () => ({
      fluid: true,
      isMultiple: true,
      options:
        mode === 'start' || mode === 'end'
          ? [...BASE_TAGS['landing'], ...TEST_TAGS]
          : BASE_TAGS['landing'] || [],
      hidden: mode === 'cover',
      disabled: mode === 'questions' || mode === 'start' || mode === 'end',
    }),
    ...(required ? { rules: [{ required: true }] } : {}),
  })

export const InputErrorValues = {
  TEMPLATE_GROUP_NAME_NOT_UNIQUE: t('input.error.nameExist'),
}
