import { useDebounceFn } from 'ahooks'
import cn from 'classnames'
import * as R from 'ramda'
import React from 'react'

import Icon from 'components/uiKit/Icon'
import Input, { AdditionWrapper } from 'components/uiKit/Input'
import { InputDefaultType } from 'components/uiKit/Input/Input'
import { KitSize } from 'components/uiKit/KitTypes'
import { t } from 'services/Translation'

import * as s from './Search.module.scss'
import { searchTrim } from './searchTrim'

const isValuable = (value: unknown) => !R.isNil(value) && !R.isEmpty(value)
export const isEmptyKeys = (values: unknown) =>
  R.isEmpty(R.filter(isValuable, values as Record<string, unknown>))

export interface ISearchProps extends InputDefaultType {
  name: string
  onChange: (search?: string) => void
  fluid?: boolean
  autoFocus?: boolean
  size?: KitSize.S | KitSize.M
  wait?: number
  width?: number
  value?: string
}

const Search: React.FC<ISearchProps> = ({
  name,
  value,
  onChange,
  fluid,
  autoFocus,
  size,
  wait = 0,
  width,
  ...rest
}) => {
  const { run } = useDebounceFn((value: string) => onChange(value || undefined), { wait })

  return (
    <div className={cn(s.root, { [s.fluid]: fluid })} style={{ width }}>
      <Input
        {...rest}
        autoFocus={autoFocus}
        name={`${name}.search`}
        onChange={run}
        parser={searchTrim}
        placeholder={t('common.search')}
        prefix={
          <AdditionWrapper>
            <Icon name='searchField' size={KitSize.S} />
          </AdditionWrapper>
        }
        size={size}
        value={value}
      />
    </div>
  )
}

export default Search
