import { ProjectActions } from './reducer'

export type Actions = ReturnType<ProjectActions[keyof ProjectActions]>
export type Action<A extends keyof ProjectActions> = ReturnType<ProjectActions[A]>

export const isAction = <A extends keyof ProjectActions>(
  action: unknown,
  a: A,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
): action is Action<A> => action.type === `project/${a}`
