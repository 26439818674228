import * as R from 'ramda'
import { useCallback, useEffect, useState } from 'react'
import { BaseSelection } from 'slate'
import { ReactEditor, useSlate, useSlateSelection } from 'slate-react'

import { CustomEditor } from './RichTextTypes'

const getInitStart = (editor: CustomEditor, selection: BaseSelection) => {
  try {
    const anchorDomRange = selection && ReactEditor.toDOMRange(editor, selection)
    const allRects = [...(anchorDomRange?.getClientRects() || [])]
    const rects = [...(anchorDomRange?.getClientRects() || [])].filter((rect) => rect.width > 1)
    return R.head(rects) || R.head(allRects)
  } catch (error) {
    return undefined
  }
}

const getInitEnd = (editor: CustomEditor, selection: BaseSelection) => {
  try {
    const anchorDomRange = selection && ReactEditor.toDOMRange(editor, selection)
    const allRects = [...(anchorDomRange?.getClientRects() || [])]
    const rects = [...(anchorDomRange?.getClientRects() || [])].filter((rect) => rect.width > 1)
    return R.last(rects) || R.last(allRects)
  } catch (error) {
    return undefined
  }
}

export const useSelectionRect = () => {
  const editor = useSlate()
  const selection = useSlateSelection()
  const [start, setStart] = useState<DOMRect | undefined>(() => getInitStart(editor, selection))
  const [end, setEnd] = useState<DOMRect | undefined>(() => getInitEnd(editor, selection))

  const update = useCallback(() => {
    try {
      const anchorDomRange = selection && ReactEditor.toDOMRange(editor, selection)
      const allRects = [...(anchorDomRange?.getClientRects() || [])]
      const rects = [...(anchorDomRange?.getClientRects() || [])].filter((rect) => rect.width > 1)
      setStart(R.head(rects) || R.head(allRects))
      setEnd(R.last(rects) || R.last(allRects))
    } catch (error) {
      console.warn(error)
    }
  }, [editor, selection])

  useEffect(() => {
    update()
    const mutationObserver = new MutationObserver(update)

    const node = ReactEditor.toDOMNode(editor, editor)
    if (node) {
      mutationObserver.observe(node, { subtree: true, characterData: true, childList: true })
    }

    document.addEventListener('scroll', update, true)
    document.addEventListener('resize', update)
    return () => {
      mutationObserver.disconnect()
      document.removeEventListener('scroll', update, true)
      document.removeEventListener('resize', update)
    }
  }, [editor, update])

  return { start, end }
}
