import { ButtonSchemaType, ButtonFontSchemaType } from '@vedalib/editor/lib/brand'
import {
  EditorElement,
  ButtonActionType,
  ButtonBlockAction,
  ButtonElementValue,
  ButtonCustomNavigationData,
  ButtonNavigationAction,
} from '@vedalib/editor/lib/elements'
import lodash from 'lodash'

import { genDataField } from 'components/controls/Field'
import { IOption } from 'components/uiKit/KitTypes'
import { BlockMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { Block, Section } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { getParent } from 'utils/form'
import { linkUrlValidation } from 'utils/websiteValidation'

import {
  filterByBlockAction,
  filterByNavigation,
  getButtonActionTypesByMode,
  valueByAction,
  valueByType,
} from './helper'

type ButtonsElementType = EditorElement<ButtonElementValue, ButtonSchemaType, ButtonFontSchemaType>

const genField = genDataField<Block>()

interface IGetLabelConfigs {
  nextSection?: Section | null
  section?: Section | null
  blocks: Block[]
  value: ButtonElementValue
  block?: Block | null
  isProMode: boolean
}

const secondLabel = (labelNumber: number) =>
  genField({
    name: 'secondLabel',
    type: 'text',
    label: `${t('elements.button.form.value')} ${labelNumber}`,
    layout: 'horizontal',
    rules: [{ max: 90 }],
  })

const thirdLabel = (labelNumber: number) =>
  genField({
    name: 'thirdLabel',
    type: 'text',
    label: `${t('elements.button.form.value')} ${labelNumber}`,
    layout: 'horizontal',
    rules: [{ max: 90 }],
  })

const fourthLabel = (labelNumber: number) =>
  genField({
    name: 'fourthLabel',
    type: 'text',
    label: `${t('elements.button.form.value')} ${labelNumber}`,
    layout: 'horizontal',
    rules: [{ max: 90 }],
  })

export const getLabelConfigs = ({
  value,
  section,
  blocks,
  nextSection,
  block,
  isProMode,
}: IGetLabelConfigs) => {
  const indexCurrentBlock = blocks.findIndex((b) => b.uuid === block?.uuid)
  const isNextBlockQuestions = blocks[indexCurrentBlock + 1]?.mode === BlockMode.questions
  const labels = []
  if (value.action === ButtonBlockAction.courseStart) {
    labels.push(secondLabel)
  }

  if (value.action === ButtonBlockAction.testEnd) {
    if (!isProMode) {
      if (block?.mode === BlockMode.end && nextSection) {
        labels.push(secondLabel)
      }

      if (block?.mode === BlockMode.end && !nextSection) {
        labels.push(thirdLabel)
      }
    } else {
      labels.push(secondLabel, thirdLabel)
    }
  }

  if (value.action === ButtonBlockAction.validate) {
    if (!isProMode) {
      if (
        section?.type === SectionTypeEnum.test &&
        ((block?.mode === BlockMode.questions && !isNextBlockQuestions) ||
          block?.mode !== BlockMode.end)
      ) {
        labels.push(secondLabel)
      }

      if (section?.type === SectionTypeEnum.landing) {
        labels.push(thirdLabel)
      }

      if (section?.type === SectionTypeEnum.test && !isNextBlockQuestions) {
        labels.push(fourthLabel)
      }
    } else {
      labels.push(secondLabel, thirdLabel, fourthLabel)
    }
  }

  return labels
}

export const label = genField({
  name: 'label',
  type: 'text',
  label: t('input.label.text'),
  layout: 'horizontal',
  rules: [{ max: 90 }],
})

export const url = genField({
  name: 'actionData.url',
  type: 'text',
  label: t('elements.button.form.url'),
  layout: 'horizontal',
  rules: [{ validator: linkUrlValidation }],
  params: (block, config) => {
    const { parent: element } = getParent<ButtonsElementType>(config.name, block, 3)
    return { hidden: element?.value.actionType !== ButtonActionType.link }
  },
})

export const file = genField({
  name: 'actionData.file',
  type: 'file',
  layout: 'horizontal',
  params: (block, config) => {
    const { parent: element } = getParent<ButtonsElementType>(config.name, block, 3)
    return {
      hidden: element?.value.actionType !== ButtonActionType.file,
      nullable: true,
      preview: true,
      hasAccessibility: true,
    }
  },
})

export const actionType = genField({
  name: 'actionType',
  type: 'select',
  label: t('input.label.type'),
  layout: 'horizontal',
  params: (blocks, config) => {
    const { parent: block } = getParent<Block>(config.name, blocks, 4)
    return {
      options: getButtonActionTypesByMode(block?.mode),
      fluid: true,
    }
  },
  effect: (_block, changeData) => {
    const { parentName } = getParent(changeData.name)

    const val = valueByType[changeData.value as keyof typeof valueByType]
    const newVal = val || { actionType: changeData.value }
    const newChangeData = { name: parentName, value: newVal }
    return newChangeData
  },
})

export const action = (sectionType?: SectionTypeEnum) =>
  genField({
    name: 'action',
    type: 'select',
    label: t('input.label.actions'),
    layout: 'horizontal',
    params: (blocks, config) => {
      const { parent: block } = getParent<Block>(config.name, blocks, 4)
      const { parent: element } = getParent<ButtonsElementType>(config.name, blocks, 2)

      const type = element?.value?.actionType

      const filteredOptions =
        type === ButtonActionType.blockAction
          ? filterByBlockAction({
              mode: block?.mode,
              sectionType,
            })
          : filterByNavigation({ mode: block?.mode })

      return {
        hidden: type !== ButtonActionType.blockAction && type !== ButtonActionType.navigation,
        options: filteredOptions,
        fluid: true,
      }
    },
    effect: (block, changeData) => {
      const { parentName, parent } = getParent<ButtonsElementType['value']>(changeData.name, block)
      const val = valueByAction[changeData.value as keyof typeof valueByAction]
      const newVal = val || { ...lodash.pick(parent, ['actionType']), action: changeData.value }
      const newChangeData = { name: parentName, value: newVal }
      return newChangeData
    },
  })

export const sectionNavigation = (sectionOptions: IOption<string>[]) =>
  genField({
    name: 'actionData.sectionId',
    type: 'select',
    label: t('elements.button.form.section'),
    layout: ['horizontal', 'overflow'],
    params: (block, config) => {
      const { parent: element } = getParent<ButtonsElementType>(config.name, block, 3)
      return {
        hidden: element?.value.action !== ButtonNavigationAction.custom,
        options: sectionOptions,
        fluid: true,
        dropdownType: 'dropdown',
      }
    },
    effect: (_block, changeData) => {
      const { parentName } = getParent(changeData.name)
      const newValue = { sectionId: changeData.value }
      return { name: parentName, value: newValue }
    },
  })

export const blockNavigation = (sections: Section[]) =>
  genField({
    name: 'actionData.blockId',
    type: 'select',
    label: t('elements.button.form.block'),
    layout: 'horizontal',
    params: (block, config) => {
      const { parent } = getParent<ButtonCustomNavigationData>(config.name, block)

      const section = sections.find((section) => section.id === parent?.sectionId)
      const options = section?.blocksOrder.map((value, i) => ({
        value,
        label: `${i + 1} ${t('elements.button.form.blockCount')}`,
        disabled: value === block?.uuid,
      }))

      return {
        hidden:
          !parent?.sectionId ||
          section?.type === SectionTypeEnum.test ||
          section?.type === SectionTypeEnum.cover,
        options: options,
        fluid: true,
        placeholder: t('input.option.start'),
      }
    },
  })

export const position = genField({
  name: 'position',
  type: 'select',
  label: t('input.label.icon'),
  layout: 'horizontal',
  defaultValue: 'hide',
  params: (block, config) => {
    const { parent: element } = getParent<ButtonsElementType>(config.name, block, 2)
    return {
      hidden: element?.value.action !== ButtonBlockAction.courseStart,
      options: [
        { value: 'hide', label: t('input.option.withoutIcon') },
        { value: 'left', label: t('input.option.left') },
        { value: 'right', label: t('input.option.right') },
      ],
      fluid: true,
    }
  },
})

export const condition = genField({
  name: 'condition',
  type: 'select',
  label: t('elements.button.form.condition'),
  layout: 'horizontal',
  defaultValue: 'none',
  params: (block, config) => {
    const { parent: element } = getParent<ButtonsElementType>(config.name, block, 2)
    return {
      hidden: element?.value.actionType !== ButtonActionType.continue,
      options: [
        { value: 'none', label: t('input.option.no') },
        { value: 'all', label: t('input.option.allBlockViewed') },
        { value: 'last', label: t('input.option.blockViewed') },
      ],
      fluid: true,
    }
  },
})
