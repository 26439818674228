import { IArea } from '@vedalib/editor/lib/elements'
import cn from 'classnames'
import { motion } from 'framer-motion'
import { useCallback, useEffect, useState } from 'react'

import { IconButton } from 'components/uiKit/Button'
import { KitSize } from 'components/uiKit/KitTypes'

import * as s from './HotspotArea.module.scss'

const ANIMATION_VARIANTS = {
  hidden: { opacity: 0, x: 0 },
  visible: { opacity: 1, x: 0 },
}

interface IHotspotAreaProps {
  children?: React.ReactNode
  area: IArea
  selected: boolean
  onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => void
  setAreas: (fn: (p: IArea[]) => IArea[]) => void
  styles: React.CSSProperties
  animate?: boolean
  enableDelete?: boolean
}

const HotspotArea: React.FC<IHotspotAreaProps> = ({
  children,
  area,
  selected,
  onMouseDown,
  setAreas,
  styles,
  animate,
  enableDelete,
}) => {
  const [isDragging, setIsDragging] = useState(false)
  const { x, y, width, height, id } = area

  const onMouseDownWrapper = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true)
    onMouseDown(e)
  }

  const onMouseUp = () => {
    setIsDragging(false)
  }

  const onDelete = useCallback(() => {
    setAreas?.((prev) => prev?.filter((item) => item.id !== id))
  }, [setAreas, id])

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (selected && (e.key === 'Delete' || e.key === 'Backspace')) {
        if (enableDelete) {
          onDelete()
        }
      }
    }

    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [selected, onDelete, enableDelete])

  return animate ? (
    <motion.div
      animate={animate ? 'visible' : 'hidden'}
      className={cn(s.root)}
      initial='hidden'
      key={area.id}
      style={{
        ...styles,
        left: `${x}%`,
        top: `${y}%`,
        width: `${width}%`,
        height: `${height}%`,
      }}
      transition={{ duration: 1 }}
      variants={ANIMATION_VARIANTS}
    >
      {children}
    </motion.div>
  ) : (
    <div
      className={cn(s.root, { [s.selected]: selected, [s.dragging]: isDragging })}
      key={area.id}
      onMouseDown={onMouseDownWrapper}
      onMouseUp={onMouseUp}
      style={{
        ...styles,
        left: `${x}%`,
        top: `${y}%`,
        width: `${width}%`,
        height: `${height}%`,
      }}
    >
      {children}
      {children && selected && (
        <div className={s.deleteIcon}>
          <IconButton
            disabled={!enableDelete}
            icon='otherTrash'
            iconSize={KitSize.XS}
            name='deleteArea'
            onClick={onDelete}
            styleType='secondary'
          />
        </div>
      )}
    </div>
  )
}

export default HotspotArea
