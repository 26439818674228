import { isShellNode } from 'components/editor-v3/types/data.guards'
import { IProjectContext, Block } from 'services/Store/Project/types'

import { getNodeId } from '..'
import { getBlock } from '..'

const getNode = (block: Block, id: string) => block?.schema?.nodes?.[id]

const getActiveElementId = (state: IProjectContext) => {
  const nodeId = getNodeId(state)
  return nodeId ? getElementId(state, { nodeId }) : null
}

export const getElement = (state: IProjectContext, params?: { id?: string }) => {
  const block = getBlock(state)
  const id = params?.id || getActiveElementId(state) || ''
  return block?.elements[id] || null
}

export const getIsActiveElement = (state: IProjectContext, elementId: string) => {
  return getActiveElementId(state) === elementId
}

export const getElementId = (state: IProjectContext, { nodeId }: { nodeId: string }) => {
  const block = getBlock(state)
  if (!block) {
    return null
  }

  const node = getNode(block, nodeId)
  if (isShellNode(node)) {
    return node.elementId || null
  }

  return null
}
