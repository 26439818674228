/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { sendEvent } from 'analytics'
import lodash from 'lodash'

import { logger } from 'services/Log'
import { throwLocal } from 'utils/dev'

import { saveStringToFile } from '../saveStringToFile'

const parseTestData = (testDataStr: string): any => {
  try {
    return testDataStr.split('|').reduce((acc, str) => {
      const [key, ...rest] = str.split(':')
      if (!key) {
        return acc
      }
      // last element after split by '|'
      try {
        // @ts-ignore
        acc[JSON.parse(key)] = JSON.parse(rest.join(':'))
      } catch (error) {
        throwLocal(`Error parsing test data: ${testDataStr}`)
      }
      return acc
    }, {})
  } catch (e) {
    console.error(e)
  }
}

const formatToJs = (testDataObj: any) => {
  Object.keys(testDataObj).forEach((key) => {
    const data = testDataObj[key]
    // remove _UNDEFINED_ values
    if (data === '_UNDEFINED_') {
      delete testDataObj[key]
    }

    // remove uuid and nanoid
    if (
      data &&
      typeof data === 'string' &&
      (data.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i) ||
        data.match(/^[0-9a-z_-]{21}$/i))
    ) {
      delete testDataObj[key]
    }
  })
  // single quotes are required for JSON.stringify
  return JSON.stringify(testDataObj).replace(/"/g, "'")
}

const logToTestStr = (log: any) => {
  return `${log.message}${formatToJs(log.data)})`
}

const EXCLUDE_ELEMENTS: string[] = ['icon']

export const initCaptureClick = () => {
  const onAction = (type: 'fill' | 'selectText' | 'click', data: any) => {
    // console.info(logToTestStr({ message, data }))
    if (type === 'fill') {
      logger.action(`I.$fill('${data.value}', `, lodash.omit(data, ['value']))
    }

    if (type === 'selectText') {
      logger.action(`I.$waitAndSee('${data.selectText}', `, lodash.omit(data, ['selectText']))
    } else if (type === 'click') {
      logger.action('I.$click(', data)
    }

    if (type === 'click') {
      sendEvent('click', data)
    }
  }
  document.addEventListener(
    'click',
    (e) => {
      let el = e.target as HTMLElement
      flushLastChange()
      while (el) {
        if (el.dataset.qaData) {
          const data = parseTestData(el.dataset.qaData)
          if (data?.el && !EXCLUDE_ELEMENTS.includes(data.el)) {
            onAction('click', parseTestData(el.dataset.qaData))
            break
          }
        }
        el = el.parentElement as HTMLElement
      }
    },
    true,
  )
  let lastChange: { value: string; key: string; data: any } | null = null
  const flushLastChange = () => {
    if (lastChange) {
      onAction('fill', lastChange)
      lastChange = null
    }
  }
  document.addEventListener(
    'change',
    (e) => {
      let el = e.target as HTMLElement
      const value = (el as HTMLInputElement).value
      while (el) {
        if (el?.dataset?.qaData) {
          break
        }

        el = el.parentElement as HTMLElement
      }
      if (el?.dataset?.qaData) {
        const testData: any = parseTestData(el?.dataset?.qaData)
        if (!testData) {
          return
        }

        const key = `${testData.el}.${testData.name}`
        if (lastChange?.key !== key) {
          flushLastChange()
        }

        lastChange = {
          value,
          key,
          data: testData,
        }
      }
    },
    true,
  )

  document.addEventListener('mouseup', (e) => {
    let el = e.target as HTMLElement
    while (el) {
      if (el.dataset.qaData) {
        const selectedTest = document.getSelection()?.toString()
        if (selectedTest) {
          onAction('selectText', { ...parseTestData(el.dataset.qaData), selectedTest })
        }

        break
      }
      el = el.parentElement as HTMLElement
    }
  })
  // save logs to file on ctrl+l
  document.addEventListener(
    'keydown',
    (e) => {
      if (e.ctrlKey && e.key === 'l') {
        const logs = logger.getLogs()
        const logsStr = logs
          .filter((log) => log.level === 'action')
          .map(logToTestStr)
          .join('\n')
        // copy to clipboard
        const el = document.createElement('textarea')
        el.value = logsStr
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)

        saveStringToFile('logs.txt', logsStr)
      }
    },
    true,
  )
  // @ts-ignore
  window.__recordActions = {
    start: () => {
      logger.clear()
    },
  }
}
