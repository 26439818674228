import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import React, { useEffect, useMemo, useRef } from 'react'
import { useContextSelector } from 'use-context-selector'

import { LayoutScroll } from 'components/LayoutPage'
import Empty from 'components/uiKit/Empty'
import Loader from 'components/uiKit/Loader'
import { ENUM_VALUE_MAP } from 'components/uiKit/SortPopover/constants'
import {
  EditorTemplateOrder,
  EmployeePreferencesTab,
  TemplateType,
} from 'gql/__generated__/graphql'
import { t } from 'services/Translation'

import MasonryTemplate from '../MasonryTemplate'
import { IItemProps } from '../MasonryTemplate/MasonryTemplate'
import { TemplatePickerContext, useTemplates } from '../hooks'
import { UiTemplateTagsBySection } from '../types'
import * as s from './TemplatesList.module.scss'

interface ITemplatesListProps {
  templateProps: IItemProps
  tab: EmployeePreferencesTab
}
const TemplatesList: React.FC<ITemplatesListProps> = ({ templateProps, tab }) => {
  const {
    companyId,
    mode,
    projectId,
    search,
    tag: tagProps,
    collectionId,
    type,
    columns,
    setTemplates,
    order,
  } = useContextSelector(TemplatePickerContext, (value) => value)
  const scrollRef = useRef<OverlayScrollbarsComponent>(null)
  const tag = useMemo(
    () =>
      type && UiTemplateTagsBySection[type].includes(tagProps) ? tagProps : TemplateType.ALL,
    [type, tagProps],
  )

  const vars = useMemo(
    () => ({
      search,
      tag,
      editorTemplateGroupId: collectionId,
      companyId,
      type,
      projectId,
      tab,
      ...(order && {
        order: {
          field: ENUM_VALUE_MAP[order][0][0],
          value: ENUM_VALUE_MAP[order][0][1],
        } as EditorTemplateOrder,
      }),
    }),
    [search, tag, collectionId, companyId, type, projectId, tab, order],
  )

  const { templates, loading, fetchMore } = useTemplates(vars)

  useEffect(() => {
    setTemplates(templates)
  }, [setTemplates, tab, templates])

  return (
    <div className={s.root}>
      <LayoutScroll ref={scrollRef}>
        {loading ? (
          <Loader name='dataTemplatesLoader' resources />
        ) : templates.length ? (
          <MasonryTemplate
            columns={columns}
            fetchMore={fetchMore}
            itemProps={templateProps}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            items={templates as any}
            mode={mode}
            showFavorite
          />
        ) : (
          <Empty search={Boolean(search)} text={t('modal.pickerTemplate.nothingFound')} grow />
        )}
      </LayoutScroll>
    </div>
  )
}

export default React.memo(TemplatesList)
