import React, { useCallback, useEffect, useState } from 'react'

import { IControlProps } from 'components/controls/Field/Field.types'
import TextArea, { ITextArea, TextAreaRef } from 'components/uiKit/TextArea'

const FieldTextArea = React.forwardRef<TextAreaRef, IControlProps<ITextArea>>(
  ({ onChange, name, defaultValue, autoSize, value, ...rest }, ref) => {
    const [localValue, setValue] = useState(value ?? defaultValue ?? '')

    const onChangeWrapper = useCallback(
      (value: string) => {
        setValue(value)
        return onChange({ name, value })
      },
      [name, onChange],
    )

    useEffect(() => {
      setValue(value ?? defaultValue ?? '')
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return (
      <TextArea
        name={name}
        {...rest}
        autoSize={autoSize}
        onChange={onChangeWrapper}
        ref={ref}
        value={(localValue ?? defaultValue) as string}
      />
    )
  },
)

FieldTextArea.displayName = 'FieldTextArea'

export default FieldTextArea
