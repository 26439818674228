import { createMp3Encoder } from 'wasm-media-encoders'

let blobUrlPromise = Promise.resolve('')
// let blobDuration = 0
let newDuration = 0

export async function encodeSilentBufferInner() {
  if (newDuration < 1) {
    return ''
  }

  //@ts-expect-error TODO
  const audioContext = new (window.AudioContext || window.webkitAudioContext)()
  const sampleRate = 8000 // Standard sample rate
  const numChannels = 1 // Mono audio

  // Create an empty buffer
  const silentBuffer = audioContext.createBuffer(numChannels, newDuration * sampleRate, sampleRate)
  // Fill the buffer with silence (zeros)
  for (let channel = 0; channel < numChannels; channel++) {
    const nowBuffering = silentBuffer.getChannelData(channel)
    nowBuffering.fill(0)
  }

  const channelData = []
  for (let i = 0; i < silentBuffer.numberOfChannels; i++) {
    channelData.push(silentBuffer.getChannelData(i))
  }

  const encoder = await createMp3Encoder()
  encoder.configure({
    sampleRate: silentBuffer.sampleRate,
    channels: silentBuffer.numberOfChannels as 1 | 2,
    vbrQuality: 9.999, // Lowest quality for minimal file size
  })

  // Encode the silent buffer
  const mp3Data = encoder.encode(channelData)
  const finalMp3Data = encoder.finalize()

  // Combine the encoded data
  const combinedData = new Uint8Array(mp3Data.length + finalMp3Data.length)
  combinedData.set(mp3Data, 0)
  combinedData.set(finalMp3Data, mp3Data.length)

  const blob = new Blob([combinedData], { type: 'audio/mp3' })
  const blobUrl = URL.createObjectURL(blob)
  // blobDuration = newDuration

  return blobUrl
}

export async function encodeSilentBuffer(durationInSeconds = 1) {
  if (durationInSeconds > newDuration) {
    newDuration = Math.max(newDuration, durationInSeconds)
    await new Promise((resolve) => setTimeout(resolve, 10))
    blobUrlPromise = encodeSilentBufferInner()
  }
  return await blobUrlPromise
}
