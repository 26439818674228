import { rtValueToText, RichTextValue, textToRtValue } from '@vedalib/rich-text'
import cn from 'classnames'
import React, { useCallback, useState } from 'react'

import RichText from 'components/AppText'
import { deserializePseudoSelection } from 'components/AppText/formatOperations/internal'
import { CommentMock } from 'components/comments/types'
import { IconButton } from 'components/uiKit/Button'
import { AccountMock } from 'components/uiKit/Employee'
import { KitSize } from 'components/uiKit/KitTypes'
import { CommentCreateInput } from 'gql/__generated__/graphql'
import { useCommentCreate } from 'gql/comments/apollo'
import { t } from 'services/Translation'
import { isCtrl } from 'utils/events'

import * as s from './CreateComment.module.scss'

const RICH_TEXT_DEFAULT_VALUE = textToRtValue('')

interface ICreateCommentProps {
  name: string
  mentions: AccountMock[]
  avatar?: React.ReactNode
  disabled?: boolean
  autofocus?: boolean
  onSubmit?: (data?: CommentMock | null) => void
  payload: {
    sectionId?: string | null
    taskId?: string | null
    blockUuid?: string | null
    elementId?: string | null
    parentId?: string | null
  }
}

const CreateComment: React.FC<ICreateCommentProps> = ({
  mentions,
  avatar,
  disabled,
  payload,
  autofocus,
  onSubmit,
}) => {
  const { sectionId, taskId, blockUuid, elementId, parentId } = payload
  const [commentCreate, { loading }] = useCommentCreate()
  const [value, setValue] = useState<RichTextValue>(RICH_TEXT_DEFAULT_VALUE)
  const isEmpty = !rtValueToText(value)

  const onSend = useCallback(async () => {
    const params = { sectionId, blockUuid, elementId, parentId, taskId }
    const payload: CommentCreateInput = { ...params, text: deserializePseudoSelection(value) }
    const data = await commentCreate({ variables: { payload } })
    onSubmit?.(data.data?.data.data)
    setValue(textToRtValue(''))
  }, [commentCreate, onSubmit, sectionId, blockUuid, elementId, parentId, taskId, value])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => !isEmpty && e.key === 'Enter' && isCtrl(e) && onSend(),
    [isEmpty, onSend],
  )

  return (
    <div className={cn(s.root, { [s.notEmpty]: !isEmpty })}>
      <div className={s.text}>
        {avatar && <div className={s.avatar}>{avatar}</div>}
        <RichText
          autofocus={autofocus}
          disabled={disabled}
          mentions={mentions}
          name='newCommentText'
          onChange={setValue}
          onKeyDown={handleKeyDown}
          placeholder={t('appText.placeholder.comment')}
          readOnly={loading}
          value={value}
        />
      </div>
      <div className={s.send}>
        <IconButton
          disabled={disabled || isEmpty}
          icon='builderInputUp'
          loading={loading}
          name='sendButton'
          onClick={onSend}
          size={KitSize.XS}
          round
        />
      </div>
    </div>
  )
}

export default CreateComment
