// extracted by mini-css-extract-plugin
var _1 = "Modal_body__FYV2N";
var _2 = "Modal_bodyScroll__WWHYm";
var _3 = "Modal_bordered__e6JnU";
var _4 = "Modal_center__+Iadw";
var _5 = "Modal_close__JlsPz";
var _6 = "Modal_containerWrap__dfnYC";
var _7 = "Modal_crop__+p0Si";
var _8 = "Modal_description__Z0-Ol";
var _9 = "Modal_dialog__-U2bK";
var _a = "Modal_end__LHE0N";
var _b = "Modal_footer__tPqrJ";
var _c = "Modal_fullScreen__u3Iqs";
var _d = "Modal_gapLarge__XK+dh";
var _e = "Modal_gapMedium__vc4uy";
var _f = "Modal_gapMini__EGMTa";
var _10 = "Modal_header__qpjvP";
var _11 = "Modal_overlay__7TZRH";
var _12 = "Modal_portalRoot__U493E";
var _13 = "Modal_root__CrfmI";
var _14 = "Modal_start__DQfhv";
var _15 = "Modal_tabs__YDtVQ";
var _16 = "Modal_title__JCnxz";
export { _1 as "body", _2 as "bodyScroll", _3 as "bordered", _4 as "center", _5 as "close", _6 as "containerWrap", _7 as "crop", _8 as "description", _9 as "dialog", _a as "end", _b as "footer", _c as "fullScreen", _d as "gapLarge", _e as "gapMedium", _f as "gapMini", _10 as "header", _11 as "overlay", _12 as "portalRoot", _13 as "root", _14 as "start", _15 as "tabs", _16 as "title" }
