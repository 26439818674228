import { useMediaQueries } from '@react-hook/media-query'
import { ElementsTypes } from '@vedalib/editor/lib/elements'
import * as R from 'ramda'
import { useEffect } from 'react'

import { CommentMock } from 'components/comments/types'
import { DEVICE_ASK_ORDER } from 'components/editor-v3/types/data.constants'
import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import { TaskMock } from 'components/uiKit/Task'
import { CommentStatus } from 'gql/__generated__/graphql'
import { BlockModeEnum } from 'gql/__generated__/graphql'
import { usePathParams, usePathQuery } from 'routes/hooks'
import { SCORM_API_Wrapper } from 'scormWrapper/vendors/SCORM_API_Wrapper'
import { t } from 'services/Translation'

import { useAppDispatch } from '../hooks'
import {
  init,
  reset,
  setDataProject,
  setDataSections,
  setDataBlocks,
  setDataComments,
  setDataTasks,
  setScormSettings,
  setUrlParams,
  setSettings,
} from './actions'
import { setDeviceMode } from './actions'
import { MEDIA_QUERIES_DEFAULT } from './constants'
import {
  DeviceMode,
  AppMode,
  ElementTab,
  PreviewMode,
  SectionTypeEnum,
  TasksTab,
  CommentsTab,
} from './enums'
import { shuffleQuestions } from './shuffleQuestions'
import { suspendDataToState } from './suspendData'
import {
  Block,
  ElementState,
  IBlockState,
  IEditorSettings,
  ISectionState,
  MapType,
  Project,
  ProjectPaths,
  ScormSettings,
  Section,
} from './types'

export const makeTestState = (section: Section) => {
  const state: ISectionState = {
    status: 'notStarted',
  }
  if (section.test?.isShuffleQuestions) {
    state.order = shuffleQuestions(section.blocks, section.test.randomCount)
  }

  return state
}
const makeSectionsState = (sections: Section[]) =>
  R.reduce(
    (map, section) =>
      section.type === 'test' ? R.mergeRight(map, { [section.id]: makeTestState(section) }) : map,
    {},
    sections,
  )

const INTERACTIVE_ELEMENTS: ElementsTypes[] = [
  'accordion',
  'card',
  'audio',
  'video',
  'labeledGraphic',
  'slider',
  'splitter',
  'tabs',
  'grouping',
]

const makeBlocksState = (blocks: Block[]) => {
  const blockWithStates = blocks.reduce<MapType<Partial<IBlockState>>>((acc, block) => {
    if (block.mode === BlockModeEnum.questions) {
      acc[block.uuid] = {
        incomplete: true,
        isReady: false,
        currentTry: 0,
      }
      return acc
    }
    const hasInteractiveElement = Object.values(block.elements || {}).some((el) =>
      INTERACTIVE_ELEMENTS.includes(el.type),
    )
    if (hasInteractiveElement) {
      acc[block.uuid] = { incomplete: true }
    }

    return acc
  }, {})

  return blockWithStates
}

const makeElementsState = (blocks: Block[]) => {
  const elements = blocks.flatMap((block) =>
    Object.values(block.elements || {}).map((element) => {
      return {
        ...element,
        blockId: block.uuid,
      }
    }),
  )

  const elementWithStates = elements.reduce<MapType<ElementState>>((acc, element) => {
    if (INTERACTIVE_ELEMENTS.includes(element.type)) {
      acc[element.id] = { incomplete: true }
    }

    return acc
  }, {})

  return elementWithStates
}
const getCurrentMatchedMedia = (
  queriesMatches: { [key in DeviceMode]: boolean },
  controlledMode?: DeviceMode,
): DeviceMode => {
  for (const device of DEVICE_ASK_ORDER) {
    if (queriesMatches[device] || device === controlledMode) {
      return device
    }
  }

  return DeviceMode.desktop
}
export const useReduxUpdate = (
  path: ProjectPaths,
  mode: PreviewMode,
  project: Project,
  sections: MapType<Section>,
  blocks: MapType<Block>,
  comments: CommentMock[],
  tasks: TaskMock[],
  settings: IEditorSettings,
  scormSettings?: ScormSettings,
) => {
  const { matches } = useMediaQueries(MEDIA_QUERIES_DEFAULT)
  const dispatch = useAppDispatch()
  const deviceMode = getCurrentMatchedMedia(matches, DeviceMode.desktop)
  const params = usePathParams(path)
  const query = usePathQuery()

  useEffect(() => {
    const {
      course,
      sections: stateSections,
      blocks: stateBlocks,
      elements: stateElements,
    } = suspendDataToState(
      mode === PreviewMode.scorm ? { sections, blocks } : { sections: {}, blocks: {} },
      SCORM_API_Wrapper.getSuspendData(),
    )

    const threadId = query.threadId || query.commentId
    const thread = comments.find((c) => c.id === threadId)

    if (threadId && !thread) {
      notify({
        type: NotificationType.error,
        message: t('notify.comment.noLongerExists'),
        duration: 2000,
      })
    }

    dispatch(
      init({
        urlParams: params,
        path,
        mode,
        state: {
          comments: {
            filter: {
              resolved: thread?.status === CommentStatus.resolved ? null : CommentStatus.pending,
            },
            opened: { threadId, commentId: query.commentId },
          },
          tasks: {
            opened: { taskId: query.taskId },
          },
          mode: threadId ? AppMode.comment : mode === PreviewMode.editor ? AppMode.fill : null,
          deviceMode,
          editor: {
            highlight: null,
            selectedBlocks: params.blockId ? [params.blockId] : [],
            settings,
            navBar: {
              tab: null,
            },
            toolbar: {
              commentTab: threadId ? CommentsTab.section : null,
              elementTab: ElementTab.fill,
              taskTab: query.taskId ? TasksTab.current : null,
            },
          },
          course: R.mergeDeepRight(
            {
              status: R.values(sections)?.some(({ type }) => type === SectionTypeEnum.cover)
                ? ('not attempted' as const)
                : ('incomplete' as const),
            },
            course,
          ),
          sections: R.mergeDeepRight(makeSectionsState(R.values(sections)), stateSections),
          blocks: R.mergeDeepRight(makeBlocksState(R.values(blocks)), stateBlocks),
          elements: R.mergeDeepRight(makeElementsState(R.values(blocks)), stateElements),
        },
        data: {
          project,
          sections,
          blocks,
          comments,
        },
      }),
    )
    return () => {
      dispatch(reset())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    dispatch(setDataProject(project))
  }, [dispatch, project])

  useEffect(() => {
    dispatch(setDataSections(sections))
  }, [dispatch, sections])

  useEffect(() => {
    dispatch(setDataBlocks(blocks))
  }, [dispatch, blocks])

  useEffect(() => {
    dispatch(setDataComments(comments))
  }, [dispatch, comments])

  useEffect(() => {
    dispatch(setDataTasks(tasks))
  }, [dispatch, tasks])

  useEffect(() => {
    dispatch(setUrlParams(params))
  }, [dispatch, params])

  useEffect(() => {
    dispatch(setSettings(settings))
  }, [dispatch, settings])

  useEffect(() => {
    dispatch(setDeviceMode({ mode: deviceMode }))
  }, [deviceMode, dispatch])

  useEffect(() => {
    dispatch(setScormSettings(scormSettings))
  }, [dispatch, scormSettings])
}
