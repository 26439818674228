import { CONFIG_ELEMENTS } from '@vedalib/editor/lib/elements'

import { ElementGroup } from 'components/editor-v2/EditorElements/elements.types'
import { genElementConfig } from 'components/editor-v2/EditorElements/genElementConfig'
import { t } from 'services/Translation'

import Demo from '../Demo'
import Element from '../Element'
import Form from '../Form'
import videoIcon from '../assets/video.svg'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './VideoBrandFormSchema'

export default genElementConfig(CONFIG_ELEMENTS.video, {
  label: t('elements.video.label'),
  icon: videoIcon,
  group: ElementGroup.view,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  Element,
  ValueForm: Form,
  Demo: Demo('video'),
})
