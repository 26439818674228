import React from 'react'

import { IDropdownCourseContent } from './DropdownCourse'
import { IChildrenHandler } from './types'

interface IGetHandlersParams {
  children: IChildrenHandler
  triggerOpen: (nextOpen: boolean) => void
  type: IDropdownCourseContent['type']
}

export const getMergedHandlers = ({ children, type, triggerOpen }: IGetHandlersParams) => {
  return {
    onMouseEnter: (e: React.MouseEvent) => {
      children.props.onMouseEnter?.(e)
      if (type === 'tooltip') {
        triggerOpen(true)
      }
    },
    onClick: (e: React.MouseEvent) => {
      children.props.onClick?.(e)
      if (type === 'dropdown') {
        triggerOpen(true)
      }
    },
  }
}
