// extracted by mini-css-extract-plugin
var _1 = "Notification_block__tXc3C";
var _2 = "Notification_closeIcon__JFsWx";
var _3 = "Notification_closeIconWrapper__GP7TT";
var _4 = "Notification_content__EpxEc";
var _5 = "Notification_divider__rall+";
var _6 = "Notification_error__rWsSJ";
var _7 = "Notification_icon__Nz0Ck";
var _8 = "Notification_info__5Gofm";
var _9 = "Notification_list__y0ucw";
var _a = "Notification_message__K2Ib8";
var _b = "Notification_notification__psqhD";
var _c = "Notification_opacity__9Qjw5";
var _d = "Notification_root__xiPtZ";
var _e = "Notification_success__lkvwL";
export { _1 as "block", _2 as "closeIcon", _3 as "closeIconWrapper", _4 as "content", _5 as "divider", _6 as "error", _7 as "icon", _8 as "info", _9 as "list", _a as "message", _b as "notification", _c as "opacity", _d as "root", _e as "success" }
