import * as s from './FixedElementsContainer.module.scss'

interface IFixedElementsContainerProps {
  children: React.ReactNode
}

const FixedElementsContainer: React.FC<IFixedElementsContainerProps> = ({ children }) => (
  <div className={s.root}>{children}</div>
)

export default FixedElementsContainer
