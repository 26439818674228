import { RichTextFontSchemaType } from '@vedalib/editor/lib/brand'
import { createContext } from 'use-context-selector'

import { NOOP } from 'constants/commonConstans'
import { AiTextPromptTogglesEnum } from 'gql/__generated__/graphql'
import { ElementFontCss } from 'services/Branding/types'

import { SlateFormats } from './RichTextConstants'
import { ToolbarForm } from './RichTextTypes'

export type RichTextContextType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedFormat: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateFormat: (format: SlateFormats, value?: any) => void
  toolbarForm: ToolbarForm | null
  setToolbarForm: (value: ToolbarForm | null) => void
  styles: ElementFontCss<RichTextFontSchemaType>
  prompt: AiTextPromptTogglesEnum | null
  setPrompt: (value: AiTextPromptTogglesEnum | null) => void
}

const RichTextContext = createContext<RichTextContextType>({
  selectedFormat: {},
  updateFormat: (_format, _value) => null,
  toolbarForm: null,
  setToolbarForm: NOOP,
  styles: {} as ElementFontCss<RichTextFontSchemaType>,
  prompt: null,
  setPrompt: NOOP,
})

export default RichTextContext
