// extracted by mini-css-extract-plugin
var _1 = "Button_accent__IO34J";
var _2 = "Button_active__0t62E";
var _3 = "Button_button__Zf-WF";
var _4 = "Button_content__h69rs";
var _5 = "Button_disabled__hXLd0";
var _6 = "Button_fluid__9izsU";
var _7 = "Button_free__SSml-";
var _8 = "Button_ghost__BTbNa";
var _9 = "Button_group__ErlsA";
var _a = "Button_icon-large__kkeGR";
var _b = "Button_icon-medium__L5gOX";
var _c = "Button_icon-mini__07EA8";
var _d = "Button_icon-xmini__zPx5Z";
var _e = "Button_inheritColor__+JBam";
var _f = "Button_large__R-xFt";
var _10 = "Button_loading__J8iUb";
var _11 = "Button_medium__b11dd";
var _12 = "Button_mini__rsdV4";
var _13 = "Button_primary__Tt5Mx";
var _14 = "Button_round__YNX-a";
var _15 = "Button_secondary__iCiem";
var _16 = "Button_xmini__QeGgb";
export { _1 as "accent", _2 as "active", _3 as "button", _4 as "content", _5 as "disabled", _6 as "fluid", _7 as "free", _8 as "ghost", _9 as "group", _a as "icon-large", _b as "icon-medium", _c as "icon-mini", _d as "icon-xmini", _e as "inheritColor", _f as "large", _10 as "loading", _11 as "medium", _12 as "mini", _13 as "primary", _14 as "round", _15 as "secondary", _16 as "xmini" }
