import { ButtonActionType, EditorElement } from '@vedalib/editor/lib/elements'
import { CONFIG_COURSE } from '@vedalib/editor/lib/elements'
import { useInViewport, useKeyPress, useMount } from 'ahooks'
import { motion } from 'framer-motion'
import React, { useEffect, useRef } from 'react'

import { LayoutScroll } from 'components/LayoutPage'
import { AbsolutePortalProvider } from 'components/editor-v3/components/AbsolutePortal'
import LandingFooterNavigation from 'components/editor-v3/cource/controls/LandingFooterNavigation'
import BlockRenderContainer from 'components/editor-v3/cource/layout/Block/BlockContainer'
import { PassOrderEnum } from 'gql/__generated__/graphql'
import { useElementStyleCss, useElementFontCss } from 'services/Branding/hooks'
import ScrollService from 'services/Scroll/ScrollService'
import { ScrollContainerEnum } from 'services/Scroll/enums'
import { scroll, setBlockViewed } from 'services/Store/Project/actions'
import { AppMode, PreviewMode } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import {
  getBlockId,
  getBlocks,
  getBlocksState,
  getProject,
  getThreadId,
} from 'services/Store/Project/selectors'
import { getNextSectionTrigger, getPrevSectionTrigger } from 'services/Store/Project/triggers'
import { Block, IBlockMode, Section } from 'services/Store/Project/types'
import { IBlockState } from 'services/Store/Project/types'

import * as s from './CourseLanding.module.scss'

interface ICourseLandingProps {
  section: Section
  mode: IBlockMode
}

const ScrollControlBlockWrapper: React.FC<{ id: string; children?: React.ReactNode }> = ({
  children,
  id,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const endRef = useRef<HTMLDivElement>(null)
  const dispatch = useProjectDispatch()
  const blocks = useProjectContext(getBlocksState)
  const project = useProjectContext(getProject)
  const passOrder = project?.passOrder
  const [inViewPort] = useInViewport(endRef, {
    threshold: 1.0,
  })
  const blockCompleted = !blocks[id]?.incomplete
  const isViewed = blocks[id]?.viewed

  useEffect(() => {
    if (!inViewPort) {
      return
    }

    const canViewBlock =
      passOrder === PassOrderEnum.free || (passOrder === PassOrderEnum.linear && blockCompleted)

    if (canViewBlock && !isViewed) {
      dispatch(setBlockViewed(id))
    }
  }, [inViewPort, blockCompleted, dispatch, id, passOrder, isViewed])

  return (
    <AbsolutePortalProvider viewport={ref}>
      <div className={s.block} ref={ref}>
        {children}
        <div ref={endRef} style={{ position: 'absolute', bottom: 0 }} />
      </div>
    </AbsolutePortalProvider>
  )
}

const useLandingArrowNavigation = () => {
  const dispatch = useProjectDispatch()
  const prevTrigger = useProjectContext(getPrevSectionTrigger)
  const nextTrigger = useProjectContext(getNextSectionTrigger)

  const onGoLeft = prevTrigger.disabled ? null : () => dispatch(prevTrigger.actionCreator())

  const onGoRight = () => !nextTrigger.disabled && dispatch(nextTrigger.actionCreator())

  useKeyPress(['left', 'right'], (e) => {
    if (e.key === 'ArrowLeft') {
      onGoLeft?.()
    }

    if (e.key === 'ArrowRight') {
      onGoRight?.()
    }
  })
}

const notCompleteBlock = (block: Block, states: Record<string, IBlockState>) => {
  const elements = Object.values(block.elements)
  const hasContinueButton = elements.some(
    (element: EditorElement) =>
      element.type === 'button' && element.value?.actionType === ButtonActionType.continue,
  )
  return hasContinueButton && !states[block.uuid]?.continueCompleted
}

const getPreviewBlockList = (
  blocks: Block[],
  states: Record<string, IBlockState>,
  mode: IBlockMode,
) => {
  const isSliceByContinue =
    mode.previewMode !== PreviewMode.pdf && mode.editorMode !== AppMode.comment

  if (isSliceByContinue) {
    let resultBlock = blocks
    const continueBlockIndex = blocks.findIndex((block) => notCompleteBlock(block, states))

    if (continueBlockIndex !== -1) {
      resultBlock = blocks.slice(0, continueBlockIndex + 1)
    }

    return resultBlock.filter((block) => !states[block.uuid]?.continueCompleted)
  }
  return blocks
}

const isShowFooterNavigation = (blocks: Block[], states: Record<string, IBlockState>) => {
  return !blocks.some((block) => notCompleteBlock(block, states))
}

const CourseLanding: React.FC<ICourseLandingProps> = ({ section, mode }) => {
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useProjectDispatch()
  const { deviceMode } = mode
  const blockId = useProjectContext(getBlockId)
  const threadId = useProjectContext(getThreadId)
  const blocks = useProjectContext(getBlocks, section.id)
  const sectionsBlockStates = useProjectContext(getBlocksState, { sectionId: section.id })
  const previewBlocks = getPreviewBlockList(blocks, sectionsBlockStates, mode)
  const styles = useElementStyleCss(CONFIG_COURSE.landingSectionLayout.type, deviceMode)
  const navButtonsStyles = useElementStyleCss(CONFIG_COURSE.navigationButtons.type, deviceMode)
  const navButtonsFonts = useElementFontCss(CONFIG_COURSE.navigationButtons.type, deviceMode)

  useLandingArrowNavigation()

  useMount(() => {
    if (mode.editorMode === AppMode.comment) {
      dispatch(
        scroll({
          container: ScrollContainerEnum.canvas,
          id: threadId,
          block: 'begin',
          scroll: 'always',
        }),
      )
    } else if (mode.previewMode !== PreviewMode.scorm) {
      dispatch(
        scroll({
          container: ScrollContainerEnum.canvas,
          id: blockId,
          block: 'begin',
          scroll: 'always',
        }),
      )
    }
  })

  return (
    <LayoutScroll ref={ScrollService.createContainerSetter(ScrollContainerEnum.canvas)}>
      <div className={s.root} ref={ref} style={styles.indents}>
        {previewBlocks.map((block, index) => (
          <ScrollControlBlockWrapper id={block.uuid} key={block.uuid}>
            {styles.effect.fadeEffect ? (
              <motion.div
                className={s.blockContainer}
                initial={{ opacity: 0 }}
                transition={{ duration: 1.5 }}
                viewport={{ once: true }}
                whileInView={{ opacity: 1, y: 0 }}
              >
                <BlockRenderContainer block={block} index={index} mode={mode} />
              </motion.div>
            ) : (
              <BlockRenderContainer block={block} index={index} mode={mode} />
            )}
          </ScrollControlBlockWrapper>
        ))}
        {isShowFooterNavigation(blocks, sectionsBlockStates) && (
          <div className={s.footerNav}>
            <LandingFooterNavigation
              fonts={navButtonsFonts}
              mode={mode}
              styles={navButtonsStyles}
            />
          </div>
        )}
      </div>
    </LayoutScroll>
  )
}

export default CourseLanding
