import { t } from 'i18next'
import moment from 'moment'
import numeral from 'numeral'
import { dateFormatTable } from 'utils'

import { FinderItem } from 'store/models'
import { isFinderFolder } from 'utils/useFinder'

import * as s from './Description.module.scss'

interface IDescriptionProps {
  item: FinderItem
}

const Description: React.FC<IDescriptionProps> = ({ item }) => {
  return (
    <div className={s.root}>
      {!isFinderFolder(item) ? (
        <>
          {numeral(item.size).format('0.0 b')}
          <span className={s.divider}>&#8729;</span>
          {moment(parseInt(item.createdAt)).format(dateFormatTable)}
        </>
      ) : (
        <div>{t('common.folder')}</div>
      )}
    </div>
  )
}
export default Description
