import { ListFontSchemaType, ListSchemaType } from '@vedalib/editor/lib/brand'
import { ListElementValue, ListItem } from '@vedalib/editor/lib/elements'
import * as R from 'ramda'
import React from 'react'

import { getObjectFitDivMap } from 'components/editor-v2/EditorElements/utils/getObjectFitDivMap'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { useImageWithCrop } from 'utils/files'

import { DEFAULT_SIZE, POINT_SIZE } from '../../ListConstants'
import ListImage from '../ListImage'
import ListNumber from '../ListNumber/ListNumber'
import ListPoint from '../ListPoint'
import * as s from './ListMarker.module.scss'

interface IProps {
  styles: ElementStyleCss<ListSchemaType>
  mode: ListElementValue['mode']
  item: ListItem
  font: ElementFontCss<ListFontSchemaType>
}

const labelMap = {
  number: ListNumber,
  images: ListImage,
  point: ListPoint,
}

const ListMarker: React.FC<IProps> = ({ item, styles, mode, font }) => {
  const LabelItemComponent = labelMap[mode]
  const { icon, number } = item
  const isPointView = mode === 'point'
  const { labels } = styles
  const innerLayerStyles = R.pick(
    ['backgroundColor', 'borderColor', 'borderWidth', 'borderStyle'],
    labels,
  )

  const image = useImageWithCrop(icon)

  const size = isPointView ? POINT_SIZE : DEFAULT_SIZE
  const innerLayerAllStyles = {
    ...innerLayerStyles,
    ...font.numbering,
    width: size,
    height: size,
    boxShadow: styles.effects.boxShadow,
    backgroundImage: mode === 'images' ? styles.labels.backgroundImage : undefined,
    backgroundSize: getObjectFitDivMap(icon?.objectFit),
  }

  return (
    <div className={s.root}>
      {mode === 'images' ? (
        <LabelItemComponent path={image?.path || icon?.url} styles={innerLayerAllStyles}>
          \u00A0
        </LabelItemComponent>
      ) : (
        <LabelItemComponent path={image?.path || icon?.url} styles={innerLayerAllStyles}>
          {mode === 'number' ? (number ?? '\u00A0') : '\u00A0'}
        </LabelItemComponent>
      )}
      <div className={s.layer}>
        <p className={s.label}>
          <span style={R.pick(['fontSize', 'lineHeight'], font.base)}>{'\u00A0'}</span>
        </p>
      </div>
    </div>
  )
}

export default ListMarker
