import { ButtonSchemaType, ButtonFontSchemaType } from '@vedalib/editor/lib/brand'
import styled, { css } from 'styled-components'

import { outline } from 'components/editor-v3/styled/Outline'
import { border, font, hover, shell } from 'services/Branding/common.styled'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'

const image = ({ $styles }: { $styles: ElementStyleCss<ButtonSchemaType> }) => css`
  &:after {
    content: ${$styles.button.backgroundImage} ${$styles.disabled.backgroundImage};
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
  }
`

const icon = ({ $iconPosition }: { $iconPosition?: 'left' | 'right' }) => css`
  gap: 10px;
  flex-direction: ${$iconPosition === 'left' ? 'row-reverse' : 'row'};
`

const disabled = (disabled: ElementStyleCss<ButtonSchemaType>['disabled']) => css`
  cursor: not-allowed;
  background-color: ${disabled.backgroundColor};
  background-image: ${disabled.backgroundImage};
`

const BaseButtonElementStyled = styled.button<{
  $disabled?: boolean
  $iconPosition?: 'left' | 'right'
  $styles: ElementStyleCss<ButtonSchemaType>
  $fonts: ElementFontCss<ButtonFontSchemaType>
}>`
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  white-space: nowrap;
  cursor: pointer;
  position: relative;

  ${outline}

  ${icon}

  padding: ${({ $styles }) => `${$styles.padding.paddingTop} ${$styles.padding.paddingLeft}`};
  background-color: ${({ $styles }) => $styles.button.backgroundColor};
  background-image: ${({ $styles }) => $styles.button.backgroundImage};
  box-shadow: ${({ $styles }) => $styles.effects.boxShadow};

  ${({ $disabled, $styles }) => ($disabled ? disabled($styles.disabled) : hover({ $styles }))}

  ${({ $styles }) => border($styles.border)}
  ${({ $styles }) => shell($styles.root)}
  ${({ $disabled, $fonts }) => ($disabled ? font($fonts.disabled) : font($fonts.active))}
 
  ${image}
`

export default BaseButtonElementStyled
