import { Tuple, configureStore } from '@reduxjs/toolkit'
import { Middlewares } from '@reduxjs/toolkit/src/configureStore'
import { gqlClient } from 'gql'

import { notify } from 'components/uiKit/Notification'
import { SCORM_API_Wrapper } from 'scormWrapper/vendors/SCORM_API_Wrapper'

import { blockPatch } from './Project/middlewares/blockPatch'
import { employeePreferences } from './Project/middlewares/employeePreferences'
// import { logger } from './Project/middlewares/logger'
import { navigation } from './Project/middlewares/navigation'
import { scorm } from './Project/middlewares/scorm'
import { scroll } from './Project/middlewares/scroll'
import projectReducer from './Project/reducer'
import preferencesMiddleware from './Users/preferencesMiddleware'
import usersReducer from './Users/reducer'

const store = configureStore({
  reducer: {
    project: projectReducer,
    users: usersReducer,
  },

  middleware: (getDefaultMiddleware) =>
    [
      ...getDefaultMiddleware(),
      ...(SCORM_API_Wrapper.isConnected() ? [scorm] : []),
      // logger,
      blockPatch({ client: gqlClient.core, notify }),
      scroll,
      navigation,
      preferencesMiddleware,
      employeePreferences,
    ] as Tuple<Middlewares<unknown>>,
})

export default store
