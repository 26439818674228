import { CONFIG_ELEMENTS } from '@vedalib/editor/lib/elements'

import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './TestMatchingBrandFormSchema'
import Demo from './TestMatchingDemo'
import TestMatchingEditorActions from './TestMatchingEditorActions'
import Element from './TestMatchingElement/TestMatchingElement'
import Form from './TestMatchingForm'
import matchingIcon from './assets/testMatching.svg'

export default genElementConfig(CONFIG_ELEMENTS.testMatching, {
  label: t('elements.testMatching.label'),
  icon: matchingIcon,
  group: ElementGroup.test,
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  EditorActions: TestMatchingEditorActions,
  Demo,
})
