import cn from 'classnames'

import { OptionLabel } from 'components/uiKit/KitUtils'
import { NOOP } from 'constants/commonConstans'
import { testProps } from 'utils/test/qaData'

import { IOption, KitSize } from '../../KitTypes'
import * as s from './RadioItem.module.scss'

interface IProps<T extends number | string | string[] | boolean> {
  item: IOption<T>
  value?: T
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: any) => void
  hideRadio?: boolean
  disabled?: boolean
  name: string
  direction: 'vertical' | 'horizontal'
}

const RadioItem = <T extends number | string | string[] | boolean>({
  item,
  value,
  onChange,
  hideRadio,
  disabled,
  name,
  direction,
}: IProps<T>) => {
  const checked = value === item.value

  const cnRoot = cn(s.root, s[direction], {
    [s.checked]: checked,
    [s.disabled]: disabled,
    [s.hideRadio]: hideRadio,
  })

  return (
    <label className={cnRoot} {...testProps({ el: 'radioItem', name, value: item.value, checked })}>
      <input
        checked={checked}
        className={s.input}
        disabled={disabled}
        name={name}
        onChange={NOOP}
        onClick={() => onChange(item.value)}
        type='radio'
        value={String(item.value)}
      />
      {!hideRadio && <span className={s.radio} />}
      <span className={s.label}>
        <OptionLabel option={item} size={KitSize.M} />
      </span>
    </label>
  )
}

export default RadioItem
