import { Locale } from 'gql/__generated__/graphql'

import i18n, { getCurrentLocale } from './i18n'

const locale = getCurrentLocale()

const DEFAULT_PARAMS = {
  PlatformName:
    window.PLATFORM.PlatformName === 'Lynda' && locale === Locale.RU
      ? window.PLATFORM.PlatformNameRu
      : window.PLATFORM.PlatformName,
  emailDomain: window.PLATFORM.emailDomain,
}

const t = (key: string, options?: Record<string, string | number | undefined | null>): string =>
  i18n.t(key, { ...options, ...DEFAULT_PARAMS })

export default t
