import { RichTextFontSchemaType } from '@vedalib/editor/lib/brand'
import { SlateElementType, SlateMark } from '@vedalib/rich-text'
import { CSSProperties } from 'react'
import { Editor } from 'slate'

import { ElementFontCss } from 'services/Branding/types'

import { MARK_CONFIGS } from '../RichTextConstants'
import { CustomEditor } from '../RichTextTypes'
import { getInlineElement } from '../inline/withInline'
import { getActiveElements, getElementMarks } from './elements'
import { getTextMark } from './textMarks'

const MAP_BRAND_TAG: Partial<Record<SlateElementType, 'h1' | 'h2' | 'h3' | 'h4'>> = {
  heading1: 'h1',
  heading2: 'h2',
  heading3: 'h3',
  heading4: 'h4',
}

const getMarkDefaultValues = (style?: CSSProperties) => {
  const markFormats = Object.values(MARK_CONFIGS).reduce((acc, config) => {
    const { name, defaultValue } = config
    const value = style?.[name as keyof CSSProperties] || defaultValue
    return { ...acc, [name]: value }
  }, {})

  return { ...markFormats }
}

export const getCurrentFormat = (
  editor: CustomEditor,
  styles?: ElementFontCss<RichTextFontSchemaType>,
) => {
  const elements = getActiveElements(editor)
  const elementWithFormat: SlateElementType =
    (Object.keys(elements) as SlateElementType[]).find((key) => MAP_BRAND_TAG[key]) ||
    SlateElementType.elementDefault
  const style = styles?.[MAP_BRAND_TAG[elementWithFormat] || 'base'] || styles?.['base']
  const defaultMarks = getMarkDefaultValues(style)
  const elementMarks = getElementMarks(editor)
  const marks = getTextMark(editor)
  const inline = getInlineElement(editor) as Record<string, string> | undefined

  const inlineMark = {
    [SlateElementType.code]: {
      [SlateMark.backgroundColor]: 'var(--brand-borderColor)',
    },
    [SlateElementType.annotation]: {},
    [SlateElementType.link]: {
      [SlateMark.color]: styles?.link?.color,
      [SlateMark.underline]: styles?.link?.textDecoration === 'underline',
    },
    [SlateElementType.crossLink]: {
      [SlateMark.color]: styles?.link?.color,
      [SlateMark.underline]: styles?.link?.textDecoration === 'underline',
    },
  }

  return {
    ...elements,
    ...defaultMarks,
    ...elementMarks,
    ...inlineMark[inline?.type as keyof typeof inlineMark],
    ...marks,
    inline,
  }
}

export const isFormatActive = (editor: CustomEditor, format: SlateMark | string): boolean => {
  const marks = Editor.marks(editor)
  if (!marks) {
    return false
  }

  const isActive = marks[format as keyof typeof marks]

  return !!isActive
}

// export type RichTextCurrentFormatType = Partial<ReturnType<typeof getCurrentFormat>>
