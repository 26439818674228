import { DividerFontSchemaType, DividerSchemaType } from '@vedalib/editor/lib/brand'
import { DividerTypes, DividerViews } from '@vedalib/editor/lib/elements'
import lodash from 'lodash'

import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'

import * as s from './Divider.module.scss'

interface IDividerProps {
  styles: ElementStyleCss<DividerSchemaType>
  font: ElementFontCss<DividerFontSchemaType>
  view?: DividerViews
  type: DividerTypes
  isNumbered?: boolean
  number: string | undefined
}

const DividerBase: React.FC<IDividerProps> = ({ styles, view, type, number, isNumbered, font }) => {
  const dividerLineStyles = { ...styles.line, ...styles.effects, borderTopStyle: type }

  return (
    <div className={s.root} style={lodash.pick(styles.numbered, ['justifyContent'])}>
      <div className={s.lineContainer}>
        <div className={s.divider} style={dividerLineStyles} />
        {view === DividerViews.double && (
          <div className={s.divider} style={{ ...dividerLineStyles, ...styles.double }} />
        )}
      </div>
      {isNumbered && (
        <div
          className={s.number}
          style={{
            ...font.base,
            ...lodash.omit(styles.numbered, ['justifyContent']),
            ...styles.effects,
          }}
        >
          {number}
        </div>
      )}
    </div>
  )
}

export default DividerBase
