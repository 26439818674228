import { TestMatchingFontSchemaType, TestMatchingSchemaType } from '@vedalib/editor/lib/brand'
import { CONFIG_ELEMENTS, TestMatchingElementValue } from '@vedalib/editor/lib/elements'
import { textToRtValue } from '@vedalib/rich-text'

import { DEMO_MODE } from 'services/Store/Project/constants'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './TestMatchingElement/TestMatchingElement'

const DEMO_VALUE: TestMatchingElementValue = {
  items: [
    {
      leftLabel: textToRtValue(t('elements.testMatching.value.firstLeftLabel')),
      points: 1,
      rightLabel: textToRtValue(t('elements.testMatching.value.firstRightLabel')),
      value: 'matching_1',
    },
    {
      leftLabel: textToRtValue(t('elements.testMatching.value.secondLeftLabel')),
      points: 1,
      rightLabel: textToRtValue(t('elements.testMatching.value.secondRightLabel')),
      value: 'matching_2',
    },
  ],
}

const TestMatchingDemo: React.FC<
  IElementDemoProps<TestMatchingSchemaType, TestMatchingFontSchemaType>
> = ({ styles, fonts }) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.testMatching.type}-demo`,
        type: CONFIG_ELEMENTS.testMatching.type,
        value: DEMO_VALUE,
        font: {},
        style: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

export default TestMatchingDemo
