// extracted by mini-css-extract-plugin
var _1 = "Typography_fluid__TVWeN";
var _2 = "Typography_heading__OQzJr";
var _3 = "Typography_hint__G7lqa";
var _4 = "Typography_large__tO-L0";
var _5 = "Typography_link__VlADi";
var _6 = "Typography_medium__7GxV9";
var _7 = "Typography_mini__L6xko";
var _8 = "Typography_placeholder__N+q5f";
var _9 = "Typography_pointer__lGnfp";
var _a = "Typography_root__EGG7x";
var _b = "Typography_weight-bold__olbrO";
var _c = "Typography_weight-medium__vFcfs";
var _d = "Typography_weight-regular__oYEg5";
var _e = "Typography_weight-semi__l2eM2";
var _f = "Typography_xmini__yJdTo";
export { _1 as "fluid", _2 as "heading", _3 as "hint", _4 as "large", _5 as "link", _6 as "medium", _7 as "mini", _8 as "placeholder", _9 as "pointer", _a as "root", _b as "weight-bold", _c as "weight-medium", _d as "weight-regular", _e as "weight-semi", _f as "xmini" }
