import React from 'react'

import { WithSuspense } from './WithSuspense'

const Lazy = (
  factory: () => Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: React.ComponentType<any>
  }>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Fallback?: React.ComponentType<any>,
) => {
  const Component = React.lazy(factory)
  return WithSuspense(Component, Fallback)
}

export default Lazy
