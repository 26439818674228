import { GroupingSchemaType } from '@vedalib/editor/lib/brand'
import styled from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const AnswerWrapperStyled = styled.div<{ $styles: ElementStyleCss<GroupingSchemaType> }>`
  width: 200px;
  height: 200px;
  box-sizing: content-box;
  margin: 0 auto;
  padding: 1px;
`
/* 
  ${hover}
 */
export default AnswerWrapperStyled
