import { useMemo } from 'react'

import { BlockSchemaFragment, SectionOrderSchemaFragment } from 'gql/__generated__/graphql'

import { notEmpty } from './notEmpty'

const orderByMode: Record<string, number> = {
  start: -1,
  end: 1,
}
const getOrderIndex = (block: BlockSchemaFragment) => orderByMode[block.mode] || 0

export const useBlocksRightOrder = (blocks?: BlockSchemaFragment[], blocksOrder?: string[]) => {
  return useMemo(() => {
    if (!blocks || !blocksOrder) {
      return []
    }

    const orders = blocksOrder.map((e) => blocks.find((sec) => sec.uuid === e)).filter(notEmpty)
    orders.sort((a, b) => getOrderIndex(a) - getOrderIndex(b))
    return orders
  }, [blocksOrder, blocks])
}

export const useSectionsRightOrder: <T extends { id: string }>(
  sections?: T[],
  sectionsOrder?: SectionOrderSchemaFragment[],
) => T[] = (sections, sectionsOrder) => {
  return useMemo(() => {
    return sectionsOrder
      ? sectionsOrder.map((e) => sections?.find((sec) => sec.id === e.id)).filter(notEmpty)
      : sections || []
  }, [sectionsOrder, sections])
}

export const filterSectionsByType: <T extends { type: string }>(
  sections: T[],
  type: string,
) => T[] = (sections, type) => {
  if (!sections) {
    return []
  }

  return sections.filter((s) => s.type === type)
}

export const filterHidden: <T extends { isHide: boolean }>(items: T[]) => T[] = (sections) => {
  return sections.filter((s) => !s.isHide)
}

export const usePrevSection: <T extends { id: string; isChapter: boolean }>(
  sectionId?: string,
  sections?: T[],
) => T | undefined = (sectionId, sections) =>
  useMemo(() => {
    const index = sections?.findIndex((e) => e.id === sectionId)
    return (
      index !== undefined &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sections?.reduceRight((result: any, el, indexPrev: number) => {
        if (!result && indexPrev < index && !el.isChapter) {
          return el
        }

        return result
      }, undefined)
    )
  }, [sectionId, sections])

export const useNextSection: <T extends { id: string; isChapter: boolean }>(
  sectionId?: string,
  sections?: T[],
) => T | undefined = (sectionId, sections) =>
  useMemo(() => {
    const index = sections?.findIndex((e) => e.id === sectionId)
    return (
      index !== undefined &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sections?.reduce((result: any, el, indexPrev: number) => {
        if (!result && indexPrev > index && !el.isChapter) {
          return el
        }

        return result
      }, undefined)
    )
  }, [sectionId, sections])
