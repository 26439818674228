import { CONFIG_ELEMENTS } from '@vedalib/editor/lib/elements'

import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './SliderBrandFormSchema'
import Demo from './SliderDemo'
import Element from './SliderElement'
import Form from './SliderForm'
import sliderIcon from './assets/slider.svg'

export default genElementConfig(CONFIG_ELEMENTS.slider, {
  label: t('elements.slider.label'),
  icon: sliderIcon,
  group: ElementGroup.interactive,
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  Demo,
})
