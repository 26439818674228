import {
  TestMultipleChoiceFontSchemaType,
  TestMultipleChoiceSchemaType,
} from '@vedalib/editor/lib/brand'
import { CONFIG_ELEMENTS, TestMultipleChoiceElementValue } from '@vedalib/editor/lib/elements'
import { textToRtValue } from '@vedalib/rich-text'

import { DEMO_MODE } from 'services/Store/Project/constants'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './TestMultipleChoiceElement/TestMultipleChoiceElement'

const DEMO_VALUE: TestMultipleChoiceElementValue = {
  items: [
    {
      label: textToRtValue(t('elements.testMultipleChoice.value.rightAnswer')),
      value: 'id_checkbox_item_1',
      points: 1,
      isValid: true,
    },
    {
      label: textToRtValue(t('elements.testMultipleChoice.value.rightAnswer')),
      value: 'id_checkbox_item_2',
      points: 1,
      isValid: true,
    },
    {
      label: textToRtValue(t('elements.testMultipleChoice.value.wrongAnswer')),
      value: 'id_checkbox_item_3',
      points: 1,
      isValid: false,
    },
    {
      label: textToRtValue(t('elements.testMultipleChoice.value.wrongAnswer')),
      value: 'id_checkbox_item_4',
      points: 1,
      isValid: false,
    },
  ],
}

const TestMultipleChoiceDemo: React.FC<
  IElementDemoProps<TestMultipleChoiceSchemaType, TestMultipleChoiceFontSchemaType>
> = ({ styles, fonts }) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.testMultipleChoice.type}-demo`,
        type: CONFIG_ELEMENTS.testMultipleChoice.type,
        value: DEMO_VALUE,
        font: {},
        style: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

export default TestMultipleChoiceDemo
