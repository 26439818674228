import { TestSingleChoiceSchemaType } from '@vedalib/editor/lib/brand'
import React, { useCallback, useId } from 'react'

import { ElementStyleCss } from 'services/Branding/types'
import { testPropsEl } from 'utils/test/qaData'

import LabelStyled from '../../styled/LabelStyled'
import MarkStyled from '../../styled/MarkStyled'
import RadioStyled from '../../styled/RadioStyled'

interface IRadioProps {
  disabled?: boolean
  value: string
  checked: boolean
  onChange?: (value: string) => void
  setIsValid?: (value: boolean) => void
  styles: ElementStyleCss<TestSingleChoiceSchemaType>
  testData: string
  isEditor?: boolean
  children: React.ReactNode
}

const Radio: React.FC<IRadioProps> = ({
  disabled,
  value,
  checked,
  onChange,
  setIsValid,
  children,
  styles,
  testData,
  isEditor,
}) => {
  const id = useId()
  const handleOnChange = useCallback(() => onChange?.(value), [value, onChange])
  const handleSetIsValid = useCallback(() => setIsValid?.(!checked), [checked, setIsValid])

  return (
    <RadioStyled
      {...testPropsEl('courseRadio', { value }, { checked, testData })}
      $disabled={disabled}
      $styles={styles}
      htmlFor={id}
    >
      <input
        checked={checked}
        disabled={disabled}
        id={isEditor ? undefined : id}
        onChange={handleOnChange}
        type='checkbox'
      />
      <MarkStyled
        {...testPropsEl('checkmarkRadio', { value }, { checked, testData })}
        $checked={checked}
        $disabled={disabled}
        $styles={styles}
        onClick={isEditor ? handleSetIsValid : undefined}
      />
      <LabelStyled $styles={styles}>{children}</LabelStyled>
    </RadioStyled>
  )
}

export default Radio
