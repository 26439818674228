import React from 'react'

import { MediaStyles } from 'components/MediaPlayer/types'
import { SCREENS } from 'components/editor-v3/types/data.constants'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { stopPropagation } from 'constants/commonConstans'

import * as s from './VolumeSlider.module.scss'

interface IVolumeSliderProps {
  onChange: (volume: number) => void
  value: number
  styles?: Partial<Record<MediaStyles, React.CSSProperties>>
}

const VolumeSlider: React.FC<IVolumeSliderProps> = ({ onChange, value, styles }) => {
  const isMobile = window.innerWidth <= SCREENS.mobile
  const loadedStyles: React.CSSProperties = {
    width: `${value * 100}%`,
    ...styles?.sliderFilled,
  }

  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange(parseFloat(event.target.value))

  if (isMobile) {
    return null
  }

  return (
    <div className={s.root}>
      <Icon name='volumeHalf' size={KitSize.S} />
      <div className={s.inputRoot}>
        <div className={s.track} style={styles?.slider}>
          <div className={s.volume} style={loadedStyles} />
        </div>
        <input
          className={s.input}
          max={1}
          min={0}
          onChange={handleVolumeChange}
          onMouseDown={stopPropagation}
          step={0.01}
          type='range'
          value={value}
        />
      </div>
    </div>
  )
}

export default VolumeSlider
