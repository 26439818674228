import { AudioSchemaType, AudioFontSchemaType } from '@vedalib/editor/lib/brand'
import { VideoSchemaType, VideoFontSchemaType } from '@vedalib/editor/lib/brand'
import { AudioElementValue, VideoElementValue } from '@vedalib/editor/lib/elements'

import { FCValueFormType, FCElementType } from 'components/editor-v2/EditorElements/elements.types'

export type MediaElementType = FCElementType<
  AudioElementValue | VideoElementValue,
  AudioSchemaType | VideoSchemaType,
  AudioFontSchemaType | VideoFontSchemaType
>

export type MediaValueFormType = FCValueFormType<
  AudioElementValue | VideoElementValue,
  AudioSchemaType | VideoSchemaType,
  AudioFontSchemaType | VideoFontSchemaType
>

export enum VideoPointActionUiEnum {
  EDIT = 'EDIT',
  REMOVE = 'REMOVE',
}
