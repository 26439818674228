import { useProjectT } from 'services/ProjectLabels'
import { setQuestion } from 'services/Store/Project/actions'
import { SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getBlockState, getSection } from 'services/Store/Project/selectors'
import { testPropsEl } from 'utils/test/qaData'

import { TestAnswerFeedback } from '../../decorators/TestAnswerFeedback/TestAnswerFeedback'
import { useElementRootStyles } from '../../hooks/useElementRootStyles'
import * as s from './TestKeyboardInputEl.module.scss'
import { TestKeyboardInputElementType } from './TestKeyboardInputTypes'
import InputStyled from './styled/InputStyled'

const TestKeyboardInputElement: TestKeyboardInputElementType = ({
  element: {
    id: elementId,
    value: { items = [] },
  },
  mode,
  styles,
  state,
  block,
  font,
}) => {
  const pt = useProjectT()
  const dispatch = useProjectDispatch()
  const section = useProjectContext(getSection)
  const blockResult = useProjectContext(getBlockState, block?.uuid)?.result
  const inputValue = state?.rawValue || ''

  const disabled = Boolean(blockResult)
  const isTrueAnswer = Boolean(blockResult && state?.value?.length)
  const isVisible = Boolean(
    blockResult &&
      (section?.test?.isValidationVisible ||
        (section?.type === SectionTypeEnum.landing && block?.test?.isResult)),
  )

  const onInternalInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    const elGuessed = items.find((el) => el.label.toLowerCase() === inputValue.toLowerCase())
    const value = elGuessed ? items.map((item) => item.value) : []
    dispatch(
      setQuestion({
        elementId,
        value: { value, rawValue: inputValue },
        isReady: Boolean(inputValue),
        blockId: block?.uuid || '',
      }),
    )
  }

  const rootStyles = useElementRootStyles(styles.root)

  return (
    <div style={rootStyles}>
      <TestAnswerFeedback
        deviceMode={mode.deviceMode}
        isTrueAnswer={isTrueAnswer}
        isVisible={isVisible}
        showAnswerForCurrentElement={isVisible}
      >
        <InputStyled
          $fonts={font}
          $styles={styles}
          {...testPropsEl('courseInput', {})}
          autoComplete='off'
          // eslint-disable-next-line react/forbid-component-props
          className={s.input}
          disabled={disabled}
          name='testKeyboardInput'
          onChange={onInternalInputChange}
          placeholder={pt('elements.testKeyboardInput.placeholder')}
          value={inputValue as string}
        />
      </TestAnswerFeedback>
    </div>
  )
}

export default TestKeyboardInputElement
