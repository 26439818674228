import cn from 'classnames'
import React, { ReactElement } from 'react'

import { ValueType } from 'components/uiKit/KitTypes'
import Menu from 'components/uiKit/Menu'
import { IMenuOption } from 'components/uiKit/Menu/MenuItem'

import * as s from './Header.module.scss'

export const HeaderLeft: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className={s.left}>{children}</div>
)

interface IHeaderProps<V extends ValueType> {
  tableActions?: IMenuOption<V>[]
  onClickTableMenu?: ({ value }: IMenuOption<V>) => void
  showMenu?: boolean
  children: React.ReactNode
  contentLeft?: boolean
}

const Header = <V extends ValueType>({
  children,
  tableActions,
  onClickTableMenu,
  showMenu,
  contentLeft,
}: IHeaderProps<V>) => {
  const childrenArray = React.Children.toArray(children) as ReactElement[]
  const headerLeft = childrenArray.find((element) => element.type === HeaderLeft)
  const content = childrenArray.filter((element) => element.type !== HeaderLeft)

  return (
    <header className={cn(s.root, contentLeft && s.reverse)}>
      {headerLeft}
      {showMenu && !!tableActions?.length && (
        <div className={s.actions}>
          <Menu name='headerMenu' onClick={onClickTableMenu} options={tableActions} horizontal />
        </div>
      )}
      <div className={s.content}>{content}</div>
    </header>
  )
}

export default Header
