import { GroupingGroup } from '@vedalib/editor/lib/elements'
import cn from 'classnames'
import { useContext } from 'use-context-selector'

import GroupingContext from 'components/editor-v2/EditorElements/elements/GroupingElement/GroupingContext'
import { borderRadiusContent } from 'components/editor-v2/EditorElements/elements/GroupingElement/helper'
import { useProjectT } from 'services/ProjectLabels'
import { useImageWithCrop } from 'utils/files'

import Mask from '../../Mask/Mask'
import * as s from './Group.module.scss'

interface ICardProps {
  activeId: string
  group: GroupingGroup
  isCorrect: boolean
}

const Group = ({ activeId, group, isCorrect }: ICardProps) => {
  const pt = useProjectT()
  const { styles, hasGroupImage, mode, font } = useContext(GroupingContext)
  const img = useImageWithCrop(group.target.image)
  const labelClasses = cn(s.label, { [s.placeholder]: !group.target.label })

  const label =
    group.target.label || (group?.target.image?.id && hasGroupImage)
      ? group.target.label || ''
      : pt('elements.grouping.group.placeholder')

  return (
    <div>
      <div
        className={cn(s.group, { [s.card]: hasGroupImage })}
        id={group.value}
        style={{
          ...styles.group,
          ...font?.base,
          padding: styles.indents.padding,
          backgroundImage: img?.path && hasGroupImage ? `url(${img.path})` : undefined,
        }}
      >
        {activeId === group.value && (
          <Mask
            borderRadius={borderRadiusContent(styles.group) || styles.group.borderRadius}
            deviceMode={mode.deviceMode}
            isCorrect={isCorrect}
          />
        )}
        <span className={labelClasses} id={group.value}>
          {label}
        </span>
      </div>
    </div>
  )
}
export default Group
