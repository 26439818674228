import cn from 'classnames'
import React, { useCallback } from 'react'

import { TooltipCourse } from 'components/editor-v2/EditorElements/courseKit/DropDownCourse'
import Icon from 'components/uiKit/Icon'
import { questionValidate } from 'services/Store/Project/actions'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getBlockId, getBlockState, getBlock } from 'services/Store/Project/selectors'
import { getTestNextTrigger } from 'services/Store/Project/triggers'
import { t } from 'services/Translation'

import { useTestTimer } from '../useTestTimer'
import * as s from './BlockTestTimer.module.scss'

const BlockTestTimer: React.FC = () => {
  const dispatch = useProjectDispatch()
  const time = useProjectContext(getBlock)?.test?.time || 0
  const startTime = useProjectContext(getBlockState)?.startTime
  const trigger = useProjectContext(getTestNextTrigger)
  const blockId = useProjectContext(getBlockId) || ''

  const onFinish = useCallback(() => {
    dispatch(questionValidate({ blockId }))
    if (!trigger.disabled) {
      dispatch(trigger.actionCreator())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, blockId])

  const { isFire, left } = useTestTimer({ startTime, time, stop: !startTime, onFinish })

  return (
    <TooltipCourse overlay={t('elements.testFooter.tooltip.timeBlock')}>
      <div className={cn(s.root, { [s.fireTime]: isFire })}>
        <Icon className={s.icon} name={isFire ? 'statusDeadline20Px' : 'otherClock'} />
        {left}
      </div>
    </TooltipCourse>
  )
}

export default BlockTestTimer
