import { AccordionSchemaType } from '@vedalib/editor/lib/brand'
import { AccordionDisplayDivider, AccordionType } from '@vedalib/editor/lib/elements'
import styled, { css } from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const all = (divider: ElementStyleCss<AccordionSchemaType>['divider']) => css`
  border-top-style: solid;
  border-top-color: ${divider.borderTopColor};
  border-top-width: ${divider.borderTopWidth};
  &:last-child {
    border-bottom-style: solid;
    border-bottom-color: ${divider.borderTopColor};
    border-bottom-width: ${divider.borderTopWidth};
  }
`

const inner = (divider: ElementStyleCss<AccordionSchemaType>['divider']) => css`
  &:not(:first-child) {
    border-top-style: solid;
    border-top-color: ${divider.borderTopColor};
    border-top-width: ${divider.borderTopWidth};
  }
`

const first = (divider: ElementStyleCss<AccordionSchemaType>['divider']) => css`
  border-top-style: solid;
  border-top-color: ${divider.borderTopColor};
  border-top-width: ${divider.borderTopWidth};
`

const solid = ({ divider }: ElementStyleCss<AccordionSchemaType>) => css`
  ${divider.displayDivider === AccordionDisplayDivider.ALL && all(divider)}
  ${divider.displayDivider === AccordionDisplayDivider.FIRST && first(divider)}
  ${divider.displayDivider === AccordionDisplayDivider.INNER && inner(divider)}
`

const splitted = (styles: ElementStyleCss<AccordionSchemaType>) => css`
  border-style: ${styles.border.borderStyle};
  border-color: ${styles.border.borderColor};
  border-width: ${styles.border.borderWidth};
  border-radius: ${styles.border.borderRadius};
  box-shadow: ${styles.effects.boxShadow};
`

const ItemStyled = styled.div<{ $styles: ElementStyleCss<AccordionSchemaType> }>`
  overflow: hidden;
  ${({ $styles }) => $styles.collapse.type === AccordionType.SOLID && solid($styles)}
  ${({ $styles }) => $styles.collapse.type === AccordionType.SPLITTED && splitted($styles)}
`

export default ItemStyled
