import { DeviceMode } from '@vedalib/editor'
import { generateId } from '@vedalib/editor/lib/utils/id'

import { NOOP } from 'constants/commonConstans'
import { t } from 'services/Translation'

import image from '../../assets/imageDemo.svg'
import HotspotArea from '../HotspotArea'
import Spot from '../Spot'
import * as s from './TestHotspotDemo.module.scss'

const spots = [
  { id: generateId(), x: 22.5, y: 23.5, items: ['1'] },
  { id: generateId(), x: 77.5, y: 76.5, items: [] },
]

const areas = [
  {
    id: '1',
    x: 5,
    y: 7,
    width: 35,
    height: 33,
  },
  {
    id: '2',
    x: 60,
    y: 7,
    width: 35,
    height: 33,
  },
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TestHotspotDemo = ({ styles }: any) => {
  return (
    <div className={s.root}>
      <div className={s.blockWrapper}>
        {t('elements.testHotspot.demo.spot')}
        <div className={s.block} style={{ ...styles.image, ...styles.effects }}>
          <div className={s.image}>
            <img alt='image' src={image} />
          </div>
          <Spot deviceMode={DeviceMode.desktop} spot={spots[0]} styles={styles} hasIcon />
          <Spot deviceMode={DeviceMode.desktop} spot={spots[1]} styles={styles} hasIcon />
        </div>
      </div>
      <div className={s.blockWrapper}>
        {t('elements.testHotspot.demo.feedback')}
        <div className={s.block} style={{ ...styles.image, ...styles.effects }}>
          <div className={s.image}>
            <img alt='image' src={image} />
          </div>
          <HotspotArea
            area={areas[0]}
            onMouseDown={NOOP}
            selected={false}
            setAreas={NOOP}
            styles={styles.area}
          />
          <HotspotArea
            area={areas[1]}
            onMouseDown={NOOP}
            selected={false}
            setAreas={NOOP}
            styles={styles.area}
          />
          <Spot deviceMode={DeviceMode.desktop} spot={spots[0]} styles={styles} hasIcon result />
          <Spot deviceMode={DeviceMode.desktop} spot={spots[1]} styles={styles} hasIcon result />
        </div>
      </div>
    </div>
  )
}

export default TestHotspotDemo
