const isSecured = location.protocol === 'https:'

const rootLocation = `http${isSecured ? 's' : ''}://${location.host}/`

export type AppEnvironment = 'local' | 'dev' | 'static' | 'uat' | 'production' | 'test' | 'release'
const getEnvironment = (): AppEnvironment => {
  if (location.hostname === 'localhost') {
    return 'local'
  } else if (location.hostname.startsWith('release-')) {
    return 'release'
  } else if (location.hostname.startsWith('dev-static')) {
    return 'static'
  } else if (location.hostname.startsWith('dev')) {
    return 'dev'
  } else if (location.hostname.startsWith('stage-uat')) {
    return 'uat'
  } else if (location.hostname.startsWith('app')) {
    return 'production'
  } else if (location.hostname.match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/)) {
    return 'test'
  }

  return 'local'
}

const config = {
  io: {
    oauthEndpoint: `${rootLocation}auth/oauth/phrase/authorize`,
    authEndpoint: `${rootLocation}auth/graphql`,
    coreEndpoint: `${rootLocation}core/graphql`,
    coreEndpointWs: `ws${isSecured ? 's' : ''}://${location.host}/core/graphql`,
    fileEndpoint: `${rootLocation}files`,
    exportEndpoint: `${rootLocation}export`,
    lmsEndpoint: `${rootLocation}lms`,
  },
  default: {
    environment: getEnvironment(),
  },
}

export { config }
