import cn from 'classnames'
import React, { useCallback, useState } from 'react'

import CanvasComment from 'components/comments/EditorCommentBubble'
import CanvasCreateComment from 'components/comments/EditorCreateComment'
import EditorElementComponent from 'components/editor-v2/EditorElements/EditorElementComponent'
import { getShellName } from 'components/editor-v3/context/EditorContext/selectors/blockLabels'
import { NodeType } from 'components/editor-v3/types/date.enums'
import { setCommentForm, setElement } from 'services/Store/Project/actions'
import { AppMode } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import {
  getEditorMode,
  getElementState,
  getIsCommentedElement,
  getIsCommenting,
  getIsOpenComment,
} from 'services/Store/Project/selectors'
import { ElementState } from 'services/Store/Project/types'

import { FCNode } from '../../Node/types'
import ControlSelection from '../../controls/ControlSelection'
import * as s from './PreviewShell.module.scss'

const PreviewShell: FCNode<NodeType.shell> = React.forwardRef(
  ({ mode, block, style, wrapperStyle, node }, ref) => {
    const { uuid: blockId } = block
    const { id: nodeId, type, elementId } = node
    const [highlight, setHighlight] = useState(false)
    const editorMode = useProjectContext(getEditorMode)
    const dispatch = useProjectDispatch()
    const shellCn = cn(s.shell, s[type])
    const elementState = useProjectContext(getElementState, elementId || '')
    const label = getShellName(block, nodeId)
    const isCommented = useProjectContext(getIsCommentedElement, elementId)
    const isCommenting = useProjectContext(getIsCommenting, blockId, elementId)
    const isOpenedComment = useProjectContext(getIsOpenComment, { blockId, nodeId, elementId })

    const handleSetState = useCallback(
      (value: ElementState) => elementId && dispatch(setElement({ id: elementId, value })),
      [dispatch, elementId],
    )

    const onMouseEnter = () => setHighlight(true)
    const onMouseLeave = () => setHighlight(false)

    const handleClick = useCallback(
      (e: React.MouseEvent) => {
        if (elementId && mode.editorMode === AppMode.comment) {
          e.stopPropagation()
          dispatch(setCommentForm({ blockId: blockId, elementId: elementId }))
        }
      },
      [dispatch, blockId, mode.editorMode, elementId],
    )

    const cnRoot = cn(s.wrapper, {
      [s.zeroLineHeight]: elementId && block.elements[elementId]?.type === 'image',
    })

    return (
      <div className={cnRoot} ref={ref} style={wrapperStyle}>
        <div
          className={shellCn}
          onClick={handleClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={style}
        >
          {elementId && block.elements[elementId] && (
            <EditorElementComponent
              block={block}
              element={block.elements[elementId]}
              elementState={elementState}
              mode={mode}
              setState={handleSetState}
              shellStyles={style}
            />
          )}
          {isCommented && mode.editorMode === AppMode.comment && (
            <CanvasComment blockId={blockId} elementId={elementId} nodeId={nodeId} />
          )}
          {isCommenting && <CanvasCreateComment blockId={blockId} elementId={elementId} />}
          {((highlight && editorMode === AppMode.comment) || isCommenting || isOpenedComment) && (
            <ControlSelection
              active={false}
              highlighted={highlight || isOpenedComment || isCommenting}
              label={label}
              name='shellSelection'
            />
          )}
        </div>
      </div>
    )
  },
)

PreviewShell.displayName = 'PreviewShell'

export default PreviewShell
