import cn from 'classnames'
import React from 'react'

import { BlockMode } from 'services/Store/Project/enums'
import { getEnumLabel } from 'utils/enum'

import * as s from './BlockTypeLabel.module.scss'

interface IBlockTypeLabelProps {
  mode?: BlockMode
}

const BlockTypeLabel: React.FC<IBlockTypeLabelProps> = ({ mode }) => {
  const modeLabel = getEnumLabel('BlockModeEnum', mode || BlockMode.view)

  if (mode === BlockMode.view || mode === BlockMode.questions) {
    return null
  }

  return <div className={cn(s.mode, mode && s[mode])}>{modeLabel}</div>
}

export default BlockTypeLabel
