import { useEffect, useState } from 'react'

import { shuffle } from 'utils/shuffle'

export const useShuffleRadioOrCheckboxItems = (length: number, isRandom?: boolean): number[] => {
  const [randomIndexes, setRandomIndexes] = useState([])
  useEffect(() => {
    const randoms = [...Array(length)].map((_num, index) => index)

    return setRandomIndexes(shuffle(randoms) as never[])
  }, [length, isRandom])

  return randomIndexes
}
