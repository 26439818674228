import { PermissionsKey } from '@vedalib/crud'
import { FileTypeEnum } from 'utils'

import useFilePicker from 'components/finder-v2/useFilePicker'
import { NOOP } from 'constants/commonConstans'
import { FileMetaFor } from 'gql/__generated__/graphql'
import { usePermissions } from 'services/Permission/PermissionContext'
import { UppyType } from 'store/models'

interface IUseFilesUploader {
  createdFor: FileMetaFor
  type?: string
  onChangeUploadFiles?: () => void
  uppy: UppyType
  params: {
    fileMetaGroupId: string
    companyId: string
    projectId?: string
  }
  contentType: FileTypeEnum[]
}

const useFilesUpload = ({
  createdFor,
  type,
  onChangeUploadFiles,
  uppy,
  params,
  contentType,
}: IUseFilesUploader) => {
  const canFilesUpload = usePermissions(
    createdFor === FileMetaFor.companies
      ? PermissionsKey.company_resource_c
      : PermissionsKey.project_resource_c,
  )

  const { onClickUploadFile } = useFilePicker({
    createdFor,
    multiple: true,
    name: 'uploadFile',
    onChange: onChangeUploadFiles,
    uppy,
    type,
    params,
    fileTypes: contentType,
  })

  return canFilesUpload ? onClickUploadFile : NOOP
}

export default useFilesUpload
