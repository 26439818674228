// extracted by mini-css-extract-plugin
var _1 = "CardElement_active__o5Vhh";
var _2 = "CardElement_book__vgr5i";
var _3 = "CardElement_card__0HCNY";
var _4 = "CardElement_cardFlip__UM8CM";
var _5 = "CardElement_cardUnFlip__uukVW";
var _6 = "CardElement_content__7PQp8";
var _7 = "CardElement_diagonalFlip__i11Wg";
var _8 = "CardElement_fadeEffect__BHHZi";
var _9 = "CardElement_fill__z9QcW";
var _a = "CardElement_horizontalFlip__fmB65";
var _b = "CardElement_icon__xKL5L";
var _c = "CardElement_left__R6Cvs";
var _d = "CardElement_leftFull__tRqC-";
var _e = "CardElement_pauseAnimation__qpKrg";
var _f = "CardElement_right__CMO17";
var _10 = "CardElement_rightFull__eGXht";
var _11 = "CardElement_root__fApEj";
var _12 = "CardElement_transparentBack__Vd1NG";
var _13 = "CardElement_transparentFront__Fmv7u";
var _14 = "CardElement_verticalFlip__FuY20";
export { _1 as "active", _2 as "book", _3 as "card", _4 as "cardFlip", _5 as "cardUnFlip", _6 as "content", _7 as "diagonalFlip", _8 as "fadeEffect", _9 as "fill", _a as "horizontalFlip", _b as "icon", _c as "left", _d as "leftFull", _e as "pauseAnimation", _f as "right", _10 as "rightFull", _11 as "root", _12 as "transparentBack", _13 as "transparentFront", _14 as "verticalFlip" }
