import * as R from 'ramda'

import {
  IProjectContext,
  IBlockState,
  ISectionState,
  ProjectUrlType,
} from 'services/Store/Project/types'

import { BlockMode, SectionTypeEnum } from '../enums'
import { sectionLens, blocksLens, blockLens, courseLens } from '../lenses'
import { ICourseNavigationPayload } from '../reducer'
import { getSection } from '../selectors'

// navigation
export const updateNavigation = (navigation: ICourseNavigationPayload) =>
  R.compose<[IProjectContext], IProjectContext, IProjectContext, IProjectContext>(
    updateQuestionStartTime(navigation),
    (state: IProjectContext) =>
      R.assocPath(
        ['urlParams'],
        R.pickBy<ICourseNavigationPayload, ProjectUrlType>((v) => v !== undefined, {
          ...state.urlParams,
          ...navigation,
        }),
        state,
      ),
    R.over(courseLens, (course) => ({
      ...course,
      status: course.status === 'not attempted' ? 'incomplete' : course.status,
    })),
  )

export const setBlock = (id: string, state: IBlockState) => R.set(blockLens(id), state)

// block
export const updateBlockState = (id: string, state: Partial<IBlockState>) =>
  R.over(blockLens(id), (old) => R.mergeRight(old, state))

export const clearBlocks = (ids: string[]) => R.over(blocksLens, R.omit(ids))

// section
export const updateSection = (
  id: string,
  { incTry, ...state }: Partial<ISectionState & { incTry: true }>,
) =>
  R.over(sectionLens(id), (old) =>
    R.mergeRight(old, {
      ...state,
      currentTry: incTry ? R.inc(old?.currentTry || 0) : old?.currentTry,
    }),
  )

const updateQuestionStartTime =
  ({
    sectionId,
    blockId,
  }: {
    sectionId?: string | null
    blockId?: string | null
    nodeId?: string | null
  }) =>
  (state: IProjectContext) => {
    const section = getSection(state, { id: sectionId })

    if (section?.type == SectionTypeEnum.test) {
      if (blockId) {
        const block = section?.blocks.find(({ uuid }) => uuid === blockId)
        if (block?.mode === BlockMode.questions) {
          return updateBlockState(blockId, { startTime: Date.now() })(state)
        }
      } else {
        console.warn('sectionId or blockId is required for inTest navigation')
        return state
      }
    }

    return state
  }
