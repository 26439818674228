import * as R from 'ramda'
import React, { useEffect, useId } from 'react'
import { ReactEditor, useSlate } from 'slate-react'

import { getLinkElement } from 'components/AppText/inline/withInline'
import {
  getFormat,
  getOnUpdateFormat,
  getSetForm,
  useAppTextSelector,
} from 'components/AppText/useAppTextSelector'
import { IconButton } from 'components/uiKit/Button'
import { useDropDownContext } from 'components/uiKit/Dropdown/context'
import Input from 'components/uiKit/Input'
import { KitSize } from 'components/uiKit/KitTypes'
import { validateLinkUrl } from 'utils/websiteValidation'

import { useSelectionRect } from '../../useSelectionRect'
import * as s from './LinkForm.module.scss'

const calcTop = (formRef: { width: number; height: number }, _anchor: DOMRect, focus: DOMRect) => {
  const focusTop = focus.top
  const toolbarHeight = formRef?.height
  const top = focusTop - 8 - toolbarHeight
  return top
}

const calcLeft = (formRef: { width: number; height: number }, anchor: DOMRect, focus: DOMRect) => {
  const focusLeft = focus.left
  const focusTop = focus.top
  const anchorLeft = anchor.left
  const anchorTop = anchor.top
  const toolbarWidth = formRef?.width

  const left =
    focusTop < anchorTop
      ? focusLeft
      : focusTop > anchorTop
        ? focusLeft - toolbarWidth
        : focusLeft < anchorLeft || focusTop < anchorTop
          ? focusLeft
          : focusLeft - toolbarWidth

  return R.clamp(0, window.innerWidth, left)
}

const calcTopFromElement = (size: { width: number; height: number }, node: DOMRect) => {
  const nodeTop = node.top
  return nodeTop - 8 - size.height
}

const calcLeftFromElement = (size: { width: number; height: number }, node: DOMRect) => {
  const nodeLeft = node.left
  const nodeRight = node.right
  const left = (nodeRight + nodeLeft - (size.width || 0)) / 2
  return R.clamp(0, window.innerWidth, left)
}

interface ILinkFormProps {
  anchor?: DOMRect
  focus?: DOMRect
  size?: { width: number; height: number }
}

const LinkForm = React.forwardRef<HTMLDivElement, ILinkFormProps>(({ size }, outerRef) => {
  const id = useId()

  const ref = outerRef as React.MutableRefObject<HTMLDivElement>
  const { parentContext } = useDropDownContext()
  const { anchor, focus } = useSelectionRect()
  const editor = useSlate()
  const setForm = useAppTextSelector(getSetForm)
  const format = useAppTextSelector(getFormat)
  const onUpdateFormat = useAppTextSelector(getOnUpdateFormat)

  const [value, setValue] = React.useState<string>(format?.inline?.url || '')
  const isValid = validateLinkUrl(value)
  const isEditing = Boolean(format?.inline?.url)
  const element = getLinkElement(editor)

  const onClose = () => setForm('default')

  const onSave = () => {
    onUpdateFormat('link', value)
    onClose()
  }

  const onDelete = () => {
    onUpdateFormat('link', null)
    onClose()
  }

  useEffect(() => {
    if (ref.current && element) {
      const rect = element && ReactEditor.toDOMNode(editor, element)?.getBoundingClientRect()
      ref.current.style.left = `${calcLeftFromElement(ref.current.getBoundingClientRect(), rect)}px`
      ref.current.style.top = `${calcTopFromElement(ref.current.getBoundingClientRect(), rect)}px`
    } else if (ref.current && anchor && focus) {
      ref.current.style.left = `${calcLeft(ref.current.getBoundingClientRect(), anchor, focus)}px`
      ref.current.style.top = `${calcTop(ref.current.getBoundingClientRect(), anchor, focus)}px`
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, anchor, focus, size, element])

  useEffect(() => {
    setValue(format?.inline?.url || '')
  }, [format?.inline?.url])

  useEffect(() => {
    if (ref.current) {
      parentContext?.registerSubPopup(id, ref.current)
    }
  })

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
      onSave()
    }
    if (e.key === 'Escape') {
      e.stopPropagation()
      e.preventDefault()
      onClose()
    }
  }

  if (!element && (!anchor || !focus)) {
    return null
  }

  return (
    <div className={s.root} onKeyDown={onKeyDown} ref={ref}>
      <div className={s.input}>
        <Input
          autoFocus={!value}
          error={!isValid}
          name='appTextLink'
          onChange={setValue}
          styleType='ghost'
          value={value}
        />
      </div>
      <div className={s.controls}>
        {isEditing && (
          <>
            <IconButton
              icon='otherBrokenLink'
              name='delete'
              onClick={onDelete}
              size={KitSize.S}
              styleType='ghost'
            />
            <IconButton
              icon='checkmark1'
              name='update'
              onClick={onSave}
              size={KitSize.S}
              styleType='ghost'
            />
          </>
        )}
        {!isEditing && value && (
          <IconButton
            icon='checkmark1'
            name='create'
            onClick={onSave}
            size={KitSize.S}
            styleType='ghost'
          />
        )}
        {!isEditing && !value && (
          <IconButton
            icon='otherClose'
            name='cancel'
            onClick={onClose}
            size={KitSize.S}
            styleType='ghost'
          />
        )}
      </div>
    </div>
  )
})

LinkForm.displayName = 'LinkForm'

export default LinkForm
