import React from 'react'

import Collapse from 'components/uiKit/Collapse'
import Input from 'components/uiKit/Input'
import { KitSize } from 'components/uiKit/KitTypes'
import { FULL_COLLAPSE_PADDING } from 'constants/styles'
import { SCORM_API_Wrapper } from 'scormWrapper/vendors/SCORM_API_Wrapper'
import { CMIElement } from 'scormWrapper/vendors/SCORM_API_types'

import ScormDataItem from './ScormDataItem'
import * as s from './ScormDebug.module.scss'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deepsLimit = (obj: any, deeps = 3): any => {
  if (deeps <= 0) {
    return '...'
  }

  if (obj && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      return {
        ...acc,
        [key]: deepsLimit(obj[key], deeps - 1),
      }
    }, {})
  }

  return obj
}

const ScormDebug: React.FC = () => {
  const scormCommit = () => SCORM_API_Wrapper.commit()
  const api = SCORM_API_Wrapper.getAPIInstance()
  const [customField, setCustomField] = React.useState('')
  const saveDebugData = () => SCORM_API_Wrapper.saveDebugData()

  return (
    <div className={s.root}>
      <h1 className={s.header}>SCORM DEBUG</h1>
      <div className={s.items}>
        <button onClick={saveDebugData}>save debug data</button>
        <button onClick={() => SCORM_API_Wrapper.exit()}>Exit</button>
      </div>
      <Collapse header='api' name='api' paddings={FULL_COLLAPSE_PADDING}>
        <pre>{JSON.stringify(deepsLimit(api), null, 2)}</pre>
      </Collapse>

      <Collapse header='setters' name='api' paddings={FULL_COLLAPSE_PADDING}>
        <button onClick={scormCommit}>commit:</button>
        <div className={s.customField}>
          <Input
            name='customField'
            onChange={setCustomField}
            size={KitSize.S}
            value={customField}
          />
          <ScormDataItem cmiKey={customField as CMIElement} label='custom' />
        </div>
        <div className={s.content}>
          <ScormDataItem cmiKey='cmi.score.max' label='maxScore' />
          <ScormDataItem cmiKey='cmi.location' label='location' />
          <ScormDataItem cmiKey='cmi.progress_measure' label='progressMeasure' />
          <ScormDataItem
            cmiKey='cmi.completion_status'
            defaultValue='unknown'
            label='completionStatus'
            options={[
              { value: 'unknown', label: 'unknown' },
              { value: 'completed', label: 'completed' },
              { value: 'incomplete', label: 'incomplete' },
            ]}
          />
          <ScormDataItem cmiKey='cmi.score.raw' label='scoreRaw' />
          <ScormDataItem cmiKey='cmi.score.scaled' label='scoreScaled' />
          <ScormDataItem
            cmiKey='cmi.success_status'
            defaultValue='unknown'
            label='successStatus'
            options={[
              { value: 'unknown', label: 'unknown' },
              { value: 'passed', label: 'passed' },
              { value: 'failed', label: 'failed' },
            ]}
          />
          <ScormDataItem cmiKey='cmi.suspend_data' label='suspendData' />
        </div>
      </Collapse>
    </div>
  )
}

export default ScormDebug
