"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.TYPE = void 0;
const constants_1 = require("../../constants");
exports.TYPE = 'link';
exports.DEFAULT_VALUE = {
    linkLabel: '',
    linkURL: '',
    showPreview: false,
    image: constants_1.imageValueWithObjectFit,
    showCustomImage: false,
    overlay: '#FFFFFF00',
};
