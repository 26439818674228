import cn from 'classnames'
import { useEffect, useRef, useState } from 'react'
import React from 'react'

import { testProps } from 'utils/test/qaData'

import { KitSize } from '../KitTypes'
import * as s from './Input.module.scss'

export type InputDefaultType = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'prefix' | 'size' | 'ref'
>

export interface IInputProps extends InputDefaultType {
  name: string
  value?: string
  styleType?: 'base' | 'ghost'
  size?: KitSize
  error?: boolean
  onChange?: (value: string) => void
  onChangeEvent?: (e: React.ChangeEvent<HTMLInputElement>) => void
  prefix?: React.ReactNode
  postfix?: React.ReactNode
  parser?: (value: string) => string
  /**
   * @formatter USE ONLY FOR DISABLED OR READONLY FIELD
   *  */
  formatter?: (value?: string | null) => string
  testData?: string
}

const Input = (
  {
    name,
    size = KitSize.M,
    error = false,
    onChange,
    parser,
    formatter,
    prefix,
    postfix,
    onFocus,
    styleType = 'base',
    onBlur,
    value: valueProp,
    onChangeEvent,
    testData,
    ...rest
  }: IInputProps,
  upperRef: React.RefObject<HTMLInputElement> | null,
) => {
  const localRef = useRef<HTMLInputElement | null>(null)
  const ref = upperRef || localRef

  const [focus, setFocus] = useState(false)
  const [value, setValue] = useState(valueProp || '')
  const [pos, setPos] = useState(valueProp?.length || 0)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = parser ? parser(e.target.value) : e.target.value
    setPos(e.target.selectionStart || 0)
    setValue(parsedValue)

    if (onChangeEvent) {
      onChangeEvent(e)
    } else {
      onChange?.(parsedValue)
    }
  }

  const rootCn = cn(s.root, s[size], s[styleType], {
    [s.disabled]: rest.disabled,
    [s.focus]: focus,
    [s.error]: error,
    [s.hasPrefix]: !!prefix,
    [s.hasPostfix]: !!postfix,
  })

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocus(true)
    onFocus?.(e)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocus(false)
    onBlur?.(e)
  }

  useEffect(() => {
    setValue(valueProp || '')
    if (ref.current) {
      ref.current.value = valueProp || ''
      ref.current.selectionStart = pos
      ref.current.selectionEnd = pos
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueProp])

  return (
    <div className={rootCn}>
      {prefix && prefix}
      <input
        type='text'
        {...rest}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyDown={
          rest.onKeyDown
            ? rest.onKeyDown
            : (e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                }
              }
        }
        ref={ref}
        value={(rest.disabled || rest.readOnly) && formatter ? formatter(value) : value}
        {...testProps({ el: 'input', name, value, ...rest, focus, testData })}
      />
      {postfix && postfix}
    </div>
  )
}

export default React.memo(
  React.forwardRef(Input as React.ForwardRefRenderFunction<HTMLInputElement, IInputProps>),
)
