import { textToRtValue } from '@vedalib/rich-text'
import lodash from 'lodash'

import { TestAnswerFeedback } from 'components/editor-v2/EditorElements/decorators/TestAnswerFeedback/TestAnswerFeedback'
import { useElementRootStyles } from 'components/editor-v2/EditorElements/hooks/useElementRootStyles'
import { useShuffleRadioOrCheckboxItems } from 'components/editor-v2/EditorElements/hooks/useShuffleRadioOrCheckboxItems'
import { RichTextViewer } from 'components/form/RichText/RichTextViewer'
import { setQuestion } from 'services/Store/Project/actions'
import { PreviewMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getBlockState, getSection } from 'services/Store/Project/selectors'
import { extendTestData } from 'utils/test/qaData'

import { TestMultipleChoiceElementType } from '../TestMultipleChoice.types'
import Checkbox from './Checkbox'
import * as s from './TestMultipleChoiceElement.module.scss'

const TestMultipleChoiceElementPreview: TestMultipleChoiceElementType = ({
  element,
  mode,
  styles,
  block,
  state,
  font,
}) => {
  const isPdf = mode.previewMode === PreviewMode.pdf
  const { id: elementId, value } = element
  const dispatch = useProjectDispatch()
  const section = useProjectContext(getSection)
  const blockResult = useProjectContext(getBlockState, block?.uuid)?.result

  // const rtStyles: RTStyles = useElementStyle(DeviceMode.desktop, 'richText', {
  //   [DeviceMode.desktop]: { body__var: styles.text },
  // })

  const valueItems = value.items || []

  const disabled = Boolean(blockResult)
  const isRandom = value.isRandom && !isPdf
  const isValidated = Boolean(blockResult)
  const isVisible = Boolean(
    isValidated &&
      (section?.test?.isValidationVisible ||
        (section?.type === SectionTypeEnum.landing && block?.test?.isResult)),
  )
  const validIds = isVisible && valueItems.filter((item) => item.isValid).map((item) => item.value)
  const randomIndexes = useShuffleRadioOrCheckboxItems(valueItems.length, isRandom)

  const onInternalCheckboxChange = (checkedValue: string[]) => {
    const value = checkedValue.map((e) => e.toString())
    dispatch(
      setQuestion({
        elementId,
        value: { value },
        isReady: Boolean(checkedValue.length),
        blockId: block?.uuid || '',
      }),
    )
  }

  const rootStyles = useElementRootStyles(styles.root, {
    marginBottom: `-${styles.offset.marginTop || 0}`,
  })

  return (
    <div style={rootStyles}>
      {valueItems.map((item, index) => {
        const randomIndex = randomIndexes[index]
        const itemToShow = isRandom ? valueItems[randomIndex] : item

        const currentIds = state?.value || []
        const isIncludeValidId = validIds && validIds.includes(itemToShow?.value)
        const isIncludeCurrentId = validIds && currentIds?.includes(itemToShow?.value)
        const isVisibleAnswer = isIncludeCurrentId || isIncludeValidId

        const onChange = (value: boolean) => {
          if (value) {
            onInternalCheckboxChange(lodash.uniq([...currentIds, itemToShow?.value]))
          } else {
            onInternalCheckboxChange(currentIds.filter((id: string) => id !== itemToShow?.value))
          }
        }

        if (itemToShow) {
          return (
            <div
              className={s.wrapItem}
              key={itemToShow.value}
              style={{ marginBottom: styles.offset.marginTop }}
            >
              <TestAnswerFeedback
                deviceMode={mode.deviceMode}
                isTrueAnswer={isIncludeValidId}
                isVisible={!!validIds}
                showAnswerForCurrentElement={!!isVisibleAnswer}
              >
                <Checkbox
                  checked={currentIds.includes(itemToShow.value)}
                  disabled={disabled}
                  onChange={onChange}
                  styles={styles}
                  testData={extendTestData({
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    label: itemToShow?.label ? (itemToShow?.label[0] as any).children[0].text : '',
                    index,
                  })}
                  value={item.value}
                >
                  <RichTextViewer styles={font} value={itemToShow?.label || textToRtValue('')} />
                </Checkbox>
              </TestAnswerFeedback>
            </div>
          )
        }

        return null
      })}
    </div>
  )
}

export default TestMultipleChoiceElementPreview
