import { DividerFontSchemaType, DividerSchemaType } from '@vedalib/editor/lib/brand'
import { CONFIG_ELEMENTS, DividerValue, DividerViews } from '@vedalib/editor/lib/elements'

import { DEMO_MODE } from 'services/Store/Project/constants'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './DividerElement'

const DEMO_VALUE_SOLID: DividerValue = { dividerType: 'solid', dividerView: DividerViews.double }
const DEMO_VALUE_DOTTED: DividerValue = { dividerType: 'dotted', dividerView: DividerViews.double }
const DEMO_VALUE_DASHED: DividerValue = { dividerType: 'dashed', dividerView: DividerViews.double }
const DEMO_VALUE_NUMBERED: DividerValue = {
  dividerType: 'solid',
  isNumbered: true,
  dividerNumber: '1',
}

const DemoSolid: React.FC<IElementDemoProps<DividerSchemaType, DividerFontSchemaType>> = ({
  styles,
  fonts,
}) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.divider.type}-solid-demo`,
        type: CONFIG_ELEMENTS.divider.type,
        value: DEMO_VALUE_SOLID,
        style: {},
        font: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

const DemoDashed: React.FC<IElementDemoProps<DividerSchemaType, DividerFontSchemaType>> = ({
  styles,
  fonts,
}) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.divider.type}-dashed-demo`,
        type: CONFIG_ELEMENTS.divider.type,
        value: DEMO_VALUE_DASHED,
        style: {},
        font: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

const DemoDotted: React.FC<IElementDemoProps<DividerSchemaType, DividerFontSchemaType>> = ({
  styles,
  fonts,
}) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.divider.type}-dotted-demo`,
        type: CONFIG_ELEMENTS.divider.type,
        value: DEMO_VALUE_DOTTED,
        style: {},
        font: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

const DemoNumbered: React.FC<IElementDemoProps<DividerSchemaType, DividerFontSchemaType>> = ({
  styles,
  fonts,
}) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.divider.type}-none-demo`,
        type: CONFIG_ELEMENTS.divider.type,
        value: DEMO_VALUE_NUMBERED,
        style: {},
        font: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

export { DemoSolid, DemoDashed, DemoDotted, DemoNumbered }
