import { CONFIG_ELEMENTS } from '@vedalib/editor/lib/elements'

import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './ChartBrandFormSchema'
import { BarChartDemo, LineChartDemo, PieChartDemo } from './ChartDemo'
import Element from './ChartElement'
import Form from './ChartForm'
import ChartIcon from './assets/chart.svg'

export default genElementConfig(CONFIG_ELEMENTS.chart, {
  label: t('elements.chart.label'),
  icon: ChartIcon,
  group: ElementGroup.interactive,
  Element,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  ValueForm: Form,
  Demo: [LineChartDemo, BarChartDemo, PieChartDemo],
})
