import { GroupingItem } from '@vedalib/editor/lib/elements'
import lodash from 'lodash'
import { useContext } from 'use-context-selector'

import GroupingContext from 'components/editor-v2/EditorElements/elements/GroupingElement/GroupingContext'
import {
  borderRadiusContent,
  cardStyles,
} from 'components/editor-v2/EditorElements/elements/GroupingElement/helper'
import { useImageWithCrop } from 'utils/files'

import Mask from '../../Mask/Mask'
import * as s from './ScaledAnswer.module.scss'

interface IScaledAnswerProps {
  isCorrect?: boolean
  item: GroupingItem
  value: string
}

const ScaledAnswer = ({ isCorrect, item, value }: IScaledAnswerProps) => {
  const img = useImageWithCrop(item.image) || undefined
  const { styles, mode } = useContext(GroupingContext)
  const cropped = useImageWithCrop(item.image)
  const groupBorderWidth = parseInt(styles.group.borderWidth as string)
  const answerBorderWidth = parseInt(styles.answer.borderWidth as string)
  const borderDiff = groupBorderWidth - answerBorderWidth

  const transform = `translateY(${borderDiff}px) translateX(${borderDiff * 2}px)`

  return (
    <div
      className={s.item}
      data-grouping-id={value}
      style={{
        ...cardStyles,
        ...lodash.omit(styles.answer, ['borderColor']),
        backgroundImage: img?.path && `url(${cropped || img?.path})`,
        transform,
        top: -groupBorderWidth,
        left: -answerBorderWidth,
        // @ts-expect-error css vars
        '--translateX': `${-borderDiff}px`,
        '--translateY': `${-borderDiff}px`,
      }}
    >
      <div
        className={s.mask}
        style={{
          borderRadius: borderRadiusContent(styles.answer) || styles.answer.borderRadius,
        }}
      >
        <Mask
          borderRadius={borderRadiusContent(styles.answer) || styles.answer.borderRadius}
          deviceMode={mode.deviceMode}
          isCorrect={isCorrect}
        />
      </div>
      <div className={s.label}>{item.label}</div>
    </div>
  )
}

export default ScaledAnswer
