import { CONFIG_COURSE } from '@vedalib/editor/lib/elements'
import React from 'react'

import { useElementStyleCss, useElementFontCss } from 'services/Branding/hooks'
import { BlockMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import {
  getSection,
  getTotalQuestions,
  getDoneQuestions,
  getBlock,
  getBlockState,
  getSectionState,
} from 'services/Store/Project/selectors'
import { IBlockMode } from 'services/Store/Project/types'

import TestProgressFooter from './TestProgressFooter'

interface ITestProgressFooterProps {
  mode: IBlockMode
}

const TestProgressFooterContainer: React.FC<ITestProgressFooterProps> = ({ mode }) => {
  const section = useProjectContext(getSection)
  const { currentTry } = useProjectContext(getSectionState) || {}
  const totalQuestions = useProjectContext(getTotalQuestions)
  const totalQuestionsDone = useProjectContext(getDoneQuestions)
  const testProgress = (totalQuestionsDone / totalQuestions) * 100
  const block = useProjectContext(getBlock)
  const blockState = useProjectContext(getBlockState)
  const styles = useElementStyleCss(CONFIG_COURSE.testFooter.type, mode.deviceMode)
  const fonts = useElementFontCss(CONFIG_COURSE.testFooter.type, mode.deviceMode)

  const { isProgressShown, testLimit, testRetryCount, time } = section?.test || {}

  const isSectionTime = block?.mode !== BlockMode.end && time
  const isBlockTime = block?.mode !== BlockMode.end && block?.test?.time && !blockState?.result

  return (
    <TestProgressFooter
      currentTry={currentTry}
      fonts={fonts}
      isBlockTime={isBlockTime}
      isProgressShown={isProgressShown}
      isSectionTime={isSectionTime}
      styles={styles}
      testLimit={testLimit}
      testProgress={testProgress}
      testRetryCount={testRetryCount}
      totalQuestions={totalQuestions}
      totalQuestionsDone={totalQuestionsDone}
    />
  )
}

export default TestProgressFooterContainer
