import * as R from 'ramda'

import { IProjectContext } from 'services/Store/Project/types'

import { IQuestionPayload } from '../reducer'
import { getBlock, getBlockElementsState, getBlockState } from '../selectors'
import { updateBlockState, setBlock } from './common'
import { updateElement, clearElementValues } from './element'
import validate from './questionValidate'

export const questionSetValue = (state: IProjectContext, payload: IQuestionPayload) => {
  const { blockId, isReady, elementId, value } = payload
  return R.compose(updateBlockState(blockId, { isReady }), updateElement(elementId, value))(state)
}

export const questionValidate = (state: IProjectContext, blockId: string) => {
  const block = getBlock(state, { id: blockId })
  const blockState = getBlockState(state, blockId)
  if (block) {
    const elements = getBlockElementsState(state, block.uuid)
    const result = validate(block.elements, elements as Record<string, { value: unknown[] }>)
    const answerRequired = block.test?.answerRequired
    return updateBlockState(block.uuid, {
      result,
      incomplete: answerRequired ? !result.isValid : false,
      currentTry: (blockState.currentTry || 0) + 1,
    })(state)
  }

  return state
}

export const questionReset = (state: IProjectContext, blockId: string) => {
  const block = getBlock(state, { id: blockId })
  const elements = getBlockElementsState(state, blockId)
  const blockState = getBlockState(state, blockId)

  if (block) {
    return R.compose(
      setBlock(blockId, R.omit(['result', 'isReady'], blockState)),
      clearElementValues(R.keys(elements)),
    )(state)
  }

  return state
}

export const reset = (state: IProjectContext, blockId: string) => {
  const block = getBlock(state, { id: blockId })
  const elements = getBlockElementsState(state, blockId)

  if (block) {
    return R.compose(
      updateBlockState(blockId, { isReady: false }),
      clearElementValues(R.keys(elements) as string[]),
    )(state)
  }

  return state
}
