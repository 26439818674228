import React from 'react'

import { NodeType } from 'components/editor-v3/types/date.enums'

import { NodeProps } from '../../Node/types'
import * as s from './PreviewRow.module.scss'

const PreviewRow = React.forwardRef<HTMLDivElement, NodeProps<NodeType.row>>(
  ({ children, style }, ref) => {
    return (
      <div className={s.row} ref={ref} style={style}>
        {children}
      </div>
    )
  },
)

PreviewRow.displayName = 'PreviewRow'

export default PreviewRow
