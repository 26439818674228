import { DraggableStateSnapshot } from '@hello-pangea/dnd'
import { TestRankingFontSchemaType, TestRankingSchemaType } from '@vedalib/editor/lib/brand'
import React from 'react'

import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { testProps } from 'utils/test/qaData'

import TestRankingItemStyled from '../../styled/TestRankingItemStyled'
import * as s from './TestRankingItem.module.scss'

type TestRankingItemProps = {
  snapshot: DraggableStateSnapshot
  styles: ElementStyleCss<TestRankingSchemaType>
  font: ElementFontCss<TestRankingFontSchemaType>
  children: React.ReactNode
  index: number
}

const TestRankingItem = ({ snapshot, styles, children, index, font }: TestRankingItemProps) => {
  return (
    <TestRankingItemStyled $isDragging={snapshot.isDragging} $styles={styles}>
      <div
        className={s.icon}
        {...testProps({ el: 'courseRankingDrag', index })}
        style={{ color: font?.base.color }}
      >
        <Icon name='otherBurger' size={KitSize.M} />
      </div>
      {children}
    </TestRankingItemStyled>
  )
}

export default TestRankingItem
