import { Middleware } from 'utils/useReducerWithMiddleware'

import type * as actions from './actions'

export type MediaReducerMiddleware = Middleware<IState, Actions>

export type Actions = ReturnType<(typeof actions)[keyof typeof actions]>

export enum Action {
  START_SEEKING = 'START_SEEKING',
  TOGGLE_FULLSCREEN = 'TOGGLE_FULLSCREEN',
  TOGGLE_PLAY = 'TOGGLE_PLAY',
  PLAY = 'PLAY',
  PAUSE = 'PAUSE',
  SET_PLAYED = 'SET_PLAYED',
  SET_VOLUME = 'SET_VOLUME',
  SET_PLAYBACK_RATE = 'SET_PLAYBACK_RATE',
  SET_DURATION = 'SET_DURATION',
  SET_PROGRESS = 'SET_PROGRESS',
  UPDATE = 'UPDATE',
  SEEK_TO = 'SEEK_TO',
}

export interface IState {
  fullscreen?: boolean
  started: boolean
  playing?: boolean
  volume: number
  played?: number
  playedSeconds?: number
  loaded?: number
  loadedSeconds?: number
  start: number
  duration: number
  playbackRate: number
  seeking?: boolean
  loop?: boolean
}

export interface IUpdateState {
  playbackRate: number
  volume: number
  duration: number
  start: number
  loop: boolean
}
