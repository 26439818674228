import { HeaderSchemaType } from '@vedalib/editor/lib/brand'
import styled, { css } from 'styled-components'

import { border } from 'services/Branding/common.styled'
import { ElementStyleCss } from 'services/Branding/types'

import LeftStyled from './LeftStyled'

const PanelStyled = styled.div<{ $styles: ElementStyleCss<HeaderSchemaType>; $reverse: boolean }>`
  display: flex;
  height: 56px;
  align-items: center;
  border-radius: 6px;
  background-color: ${({ $styles }) => $styles.panel.backgroundColor};
  box-shadow: ${({ $styles }) => $styles.effects.boxShadow};

  ${({ $styles }) => border($styles.border)}

  ${({ $reverse }) =>
    $reverse &&
    css`
      flex-direction: row-reverse;

      ${LeftStyled} {
        justify-content: flex-end;
        margin-right: 0;
        margin-left: 24px;
      }
    `}
`

export default PanelStyled
