// extracted by mini-css-extract-plugin
var _1 = "CollapseControlled_border__CEQ6U";
var _2 = "CollapseControlled_content__WYRDg";
var _3 = "CollapseControlled_contentWrapper__ukthS";
var _4 = "CollapseControlled_defaultTitle__IPRQa";
var _5 = "CollapseControlled_defaultTitleContent__Kg5QX";
var _6 = "CollapseControlled_ghost__MIoDx";
var _7 = "CollapseControlled_root__FpmqA";
var _8 = "CollapseControlled_title__YMlSU";
export { _1 as "border", _2 as "content", _3 as "contentWrapper", _4 as "defaultTitle", _5 as "defaultTitleContent", _6 as "ghost", _7 as "root", _8 as "title" }
