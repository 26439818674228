import { LayoutScroll } from 'components/LayoutPage'

import * as s from './Modal.module.scss'

interface IModalBodyProps {
  children: React.ReactNode
  autoUpdateInterval?: number
  autoUpdate?: boolean
  sizeAutoCapable?: boolean
}

const ModalBody: React.FC<IModalBodyProps> = ({
  children,
  autoUpdateInterval,
  autoUpdate,
  sizeAutoCapable,
}) => (
  <LayoutScroll
    autoUpdate={autoUpdate}
    autoUpdateInterval={autoUpdateInterval}
    sizeAutoCapable={sizeAutoCapable}
    showBorder
  >
    <div className={s.body}>{children}</div>
  </LayoutScroll>
)

export default ModalBody
