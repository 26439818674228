import { SCHEMA } from '@vedalib/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.testSingleChoice.style, {
  radio: {
    META: { label: t('elements.testSingleChoice.tags.answer') },
    color: { label: t('elements.testSingleChoice.schema.buttonColor') },
  },
  item: {
    META: { hidden: true },
    backgroundColor: {},
  },
  item_hover: {
    META: { hidden: true },
    backgroundColor: {
      label: t('style.hoverColor'),
      params: {
        resettable: true,
      },
    },
  },
  border: {
    META: { hidden: true },
    borderRadius: {},
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
  },
  effects: {
    META: { label: t('style.effects') },
    boxShadow: { label: t('elements.testSingleChoice.schema.boxShadow') },
  },
  text: {
    META: { hidden: true },
    padding: { label: t('input.label.innerAnswerIndents') },
  },
  offset: {
    META: { hidden: true },
    marginTop: { label: t('input.label.answerIndents') },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.testSingleChoice.font, FONT_SCHEMA_FIELDS)
