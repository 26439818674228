import { FileUsageImageSource, FileUsageTypeEnum } from '@vedalib/editor/lib/files'
import { saveAs } from 'file-saver'
import numeral from 'numeral'
import { useMemo } from 'react'

import Icon from 'components/uiKit/Icon'
import { AppMode, PreviewMode } from 'services/Store/Project/enums'
import { getFileByUsage } from 'utils/files'
import { testProps } from 'utils/test/qaData'

import * as s from './DownloaderElement.module.scss'
import { DownloaderElementType } from './DownloaderElement.types'
import DownloaderStyled from './styled/DownloaderStyled'

const maxLengthLabel = 150

const DownloaderElement: DownloaderElementType = ({ element, styles, mode, font }) => {
  const { file, label } = element.value

  const fileData = getFileByUsage(file, FileUsageTypeEnum.common)

  const size = useMemo(
    () => (fileData?.file?.size && numeral(fileData?.file?.size).format('0.0 b')) || '0.0 MB',
    [fileData?.file?.size],
  )

  const isTypeFile = fileData?.file?.ext

  const disabled = mode.previewMode === PreviewMode.editor || mode.editorMode === AppMode.comment

  const labelValue = label && label?.length >= maxLengthLabel ? `${label}...` : label

  const onClick = () => {
    if (!fileData && file?.source === 'url') {
      return
    }

    if (!disabled) {
      saveAs(fileData?.path || '', `${fileData?.name}`)
    }
  }

  // todo change icon
  return (
    <DownloaderStyled $fonts={font} $styles={styles} onClick={onClick}>
      <div className={s.icon}>
        <Icon name='otherFile' style={styles.icon} />
        {isTypeFile && (
          <div className={s.iconType} style={styles.label}>
            <span style={styles.label}>.{fileData?.file?.ext}</span>
          </div>
        )}
      </div>
      <a
        className={s.text}
        {...testProps({
          el: 'downloaderElLink',
          name: file,
          type: fileData?.file?.ext,
        })}
        href={file?.source === FileUsageImageSource.url && !disabled ? file?.url : undefined}
      >
        <div>{labelValue || 'Empty'}</div>
      </a>
      {file?.source === FileUsageImageSource.file && <div className={s.size}>{size}</div>}
    </DownloaderStyled>
  )
}

export default DownloaderElement
