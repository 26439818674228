// extracted by mini-css-extract-plugin
var _1 = "CardSide_active__Zd-oI";
var _2 = "CardSide_back__1HqtS";
var _3 = "CardSide_book__A5gZc";
var _4 = "CardSide_diagonalFlip__SdwGW";
var _5 = "CardSide_fadeEffect__grsz-";
var _6 = "CardSide_horizontalFlip__+4tV0";
var _7 = "CardSide_icon__DPAMJ";
var _8 = "CardSide_mask__gzVRr";
var _9 = "CardSide_root__A0bv2";
var _a = "CardSide_textContainer__4arI5";
var _b = "CardSide_textInner__hdStB";
var _c = "CardSide_verticalFlip__AFopQ";
export { _1 as "active", _2 as "back", _3 as "book", _4 as "diagonalFlip", _5 as "fadeEffect", _6 as "horizontalFlip", _7 as "icon", _8 as "mask", _9 as "root", _a as "textContainer", _b as "textInner", _c as "verticalFlip" }
