import { BoxShadowType } from '@vedalib/editor/lib/brand'
import { useCallback, useMemo, useState } from 'react'

import { IControlProps } from 'components/controls/Field/Field.types'
import FieldLayout from 'components/controls/Field/Layout'
import ColorPicker, { ColorRoot } from 'components/uiKit/ColorPicker'
import { toColor } from 'components/uiKit/ColorPicker/helpers'
import DropDown from 'components/uiKit/Dropdown'
import { KitSize } from 'components/uiKit/KitTypes'
import { flatOptions } from 'components/uiKit/KitUtils'
import Slider from 'components/uiKit/Slider'
import { t } from 'services/Translation'

import { genColorOptions } from '../constants/fields'
import { useBrandTheme } from '../hooks'
import * as s from './BrandCustomShadowField.module.scss'
import Presets, { PRESETS } from './Presets'

interface IBrandCustomShadowFieldProps {
  value: string
  size: KitSize
  inline?: boolean
}

const safeValue = (value?: string): BoxShadowType => {
  try {
    return value ? JSON.parse(value) : { preset: 'none', size: 0, color: '#0000001A' }
  } catch (error) {
    return { preset: 'none', size: 0, color: '#0000001A' }
  }
}

const BrandCustomShadowField: React.FC<IControlProps<IBrandCustomShadowFieldProps>> = (props) => {
  const { value, name, size, disabled, defaultValue, inline } = props
  const { onChange, onBlur, onFocus } = props
  const shadow = safeValue(value || defaultValue)
  const theme = useBrandTheme()
  const options = useMemo(() => flatOptions(genColorOptions(theme)), [theme])
  const activeOption = options?.find((item) => item.value === shadow.color)
  const [visible, setVisible] = useState(false)
  const { hexa } = useMemo(() => toColor(shadow.color, options), [options, shadow.color])
  const isNone = shadow.preset === 'none'

  const handleChangePreset = useCallback(
    (preset: BoxShadowType['preset']) => {
      onChange({ value: JSON.stringify({ ...shadow, preset }), name })
      if (preset === 'none') {
        setVisible(false)
      }
    },
    [onChange, name, shadow],
  )
  const handleChangeSize = useCallback(
    (size: number) => onChange({ value: JSON.stringify({ ...shadow, size }), name }),
    [onChange, name, shadow],
  )

  const handleChangeColor = useCallback(
    (color: string | null) => onChange({ value: JSON.stringify({ ...shadow, color }), name }),
    [onChange, name, shadow],
  )

  const controls = (
    <div className={s.root} tabIndex={-1}>
      <Presets
        disabled={disabled}
        name={name}
        onChange={handleChangePreset}
        value={shadow.preset}
      />
      {!isNone && (
        <FieldLayout label={t('input.label.shadowColor')} layout='horizontal'>
          <ColorPicker
            disabled={disabled}
            name={`${name}.color`}
            onChange={handleChangeColor}
            options={options}
            size={size}
            value={shadow.color}
            labeled
          />
        </FieldLayout>
      )}
      {!isNone && (
        <FieldLayout label={t('input.label.shadowSize')} layout='horizontal'>
          <Slider
            disabled={disabled}
            max={20}
            min={-20}
            name={`${name}.size`}
            onChange={handleChangeSize}
            size={size}
            value={shadow.size}
            showInput
          />
        </FieldLayout>
      )}
    </div>
  )

  if (inline) {
    return controls
  }

  return (
    <DropDown
      onBlur={onBlur}
      onFocus={onFocus}
      onVisibleChange={setVisible}
      overlay={controls}
      placement='topRight'
      visible={visible}
    >
      <ColorRoot
        color={hexa}
        forceLabel={PRESETS.find((item) => item.key === shadow.preset)?.label}
        name={name}
        option={activeOption}
        size={size}
        value={isNone ? null : shadow.color}
        labeled
      />
    </DropDown>
  )
}

export default BrandCustomShadowField
