// extracted by mini-css-extract-plugin
var _1 = "CellRendererBlock_actions__R3U-Q";
var _2 = "CellRendererBlock_active__LDV+v";
var _3 = "CellRendererBlock_blockTitle__tmBm0";
var _4 = "CellRendererBlock_blockWrapper__m5Jcx";
var _5 = "CellRendererBlock_bottomGroup__N2Sv2";
var _6 = "CellRendererBlock_card__lUXyx";
var _7 = "CellRendererBlock_favIcon__wfCrY";
var _8 = "CellRendererBlock_favIconSelected__4u5R8";
var _9 = "CellRendererBlock_header__uvdb9";
var _a = "CellRendererBlock_multipleChoiceIcon__lrqDf";
var _b = "CellRendererBlock_root__pbNNV";
var _c = "CellRendererBlock_withActions__TPZYv";
export { _1 as "actions", _2 as "active", _3 as "blockTitle", _4 as "blockWrapper", _5 as "bottomGroup", _6 as "card", _7 as "favIcon", _8 as "favIconSelected", _9 as "header", _a as "multipleChoiceIcon", _b as "root", _c as "withActions" }
