import { gqlClient } from 'gql'
import { produce } from 'immer'

import {
  FileMetaGroupAllQueryVariables,
  FileMetaSchemaFragment,
  FilesGroupsSubscriptionSubscription,
  SubcribeOnCompanyFilesUpdateSubscription,
} from 'gql/__generated__/graphql'

import { fileFragment } from './gql/fragments'
import { fileMetaGroupAllQuery } from './gql/queries'

export const fileMetaAndGroupRemove = (
  data: FilesGroupsSubscriptionSubscription['data'],
  params: FileMetaGroupAllQueryVariables,
): void => {
  const cache = gqlClient.core.cache

  try {
    const cachedFiles = cache.readQuery({
      query: fileMetaGroupAllQuery,
      variables: params,
    })

    if (cachedFiles) {
      cache.writeQuery({
        query: fileMetaGroupAllQuery,
        variables: params,
        data: {
          breadcrumb: cachedFiles.breadcrumb,
          data: {
            ...cachedFiles.data,
            fileMetas: cachedFiles.data.fileMetas.filter(
              (file) =>
                !data.fileMetas.find((el) => {
                  return el.id === file.id
                }),
            ),
            groups: cachedFiles.data.groups.filter(
              (group) =>
                !data.groups.find((elem) => {
                  return elem.id === group.id
                }),
            ),
          },
        },
      })
    }
  } catch (err) {
    console.error('"fileMetaAndGroupRemove" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const fileMetaAndGroupCreate = (
  data: FilesGroupsSubscriptionSubscription['data'],
  params: FileMetaGroupAllQueryVariables,
): void => {
  const cache = gqlClient.core.cache
  try {
    const cachedFiles = cache.readQuery({
      query: fileMetaGroupAllQuery,
      variables: params,
    })

    if (cachedFiles) {
      const notExistingFileMetas = data.fileMetas.filter(
        (item) => !cachedFiles.data.fileMetas.find((el) => el.id === item.id),
      )
      const notExistingGroups = data.groups.filter(
        (item) => !cachedFiles.data.groups.find((el) => el.id === item.id),
        // && cachedFiles.data.groups.find(element=> element.parentId === params.parentId)
      )

      cache.writeQuery({
        query: fileMetaGroupAllQuery,
        variables: params,
        data: {
          breadcrumb: cachedFiles.breadcrumb,
          data: {
            ...cachedFiles.data,
            fileMetas: [...notExistingFileMetas, ...cachedFiles.data.fileMetas],
            groups: [...notExistingGroups, ...cachedFiles.data.groups],
          },
        },
      })
    }
  } catch (err) {
    console.error('"fileMetaAndGroupCreate" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const fileMetaCreate = (
  data: SubcribeOnCompanyFilesUpdateSubscription['data'],
  params: FileMetaGroupAllQueryVariables,
): void => {
  const cache = gqlClient.core.cache

  try {
    const cachedFiles = cache.readQuery({
      query: fileMetaGroupAllQuery,
      variables: params,
    })

    if (cachedFiles) {
      const existingFilesMeta = cachedFiles.data.fileMetas.find(
        (item) => data.files[0].id === item.id,
      )
      if (!existingFilesMeta) {
        cache.writeQuery({
          query: fileMetaGroupAllQuery,
          variables: params,
          data: {
            breadcrumb: cachedFiles.breadcrumb,
            data: {
              ...cachedFiles.data,
              fileMetas: [data.files[0], ...cachedFiles.data.fileMetas],
            },
          },
        })
      }
    }
  } catch (err) {
    console.error('"fileMetaCreate" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const fileMetaRemoveFakeFile = (id: string): void => {
  const cache = gqlClient.core.cache

  try {
    cache.evict({
      id: cache.identify({ __typename: 'FileMeta', id }),
    })
    cache.gc()
  } catch (err) {
    console.error('"fileMetaRemoveFakeFile" fn is crashed on operation: ".evict"', err)
  }
}

// upsert after mutation
export const fileMetaUpsertUpdateFileList = (
  params: FileMetaGroupAllQueryVariables,
  fileMetas: FileMetaSchemaFragment,
): void => {
  const cache = gqlClient.core.cache
  try {
    const cachedFiles = cache.readQuery({
      query: fileMetaGroupAllQuery,
      variables: params,
    })

    const isExist = cachedFiles?.data.fileMetas.find((oldFile) => oldFile.id === fileMetas.id)
    if (cachedFiles && !isExist) {
      cache.writeQuery({
        query: fileMetaGroupAllQuery,
        variables: params,
        data: produce(cachedFiles, (draft) => {
          draft!.data.fileMetas.push(fileMetas)
        }),
      })
    }
  } catch (err) {
    console.error('"fileMetaUpsertUpdateFileList" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const updateFileMeta = (fileMeta: FileMetaSchemaFragment): void => {
  const cache = gqlClient.core.cache
  try {
    cache.writeFragment({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      id: cache.identify(fileMeta as any),
      fragment: fileFragment,
      fragmentName: 'FileMetaSchema',
      data: fileMeta,
    })
  } catch (err) {
    console.error('"updateFileMeta" fn is crashed on operation: ".writeFragment"', err)
  }
}
