import { AccordionSchemaType, SCHEMA } from '@vedalib/editor/lib/brand'
import { AccordionDisplayDivider, AccordionType } from '@vedalib/editor/lib/elements'
import { getParent } from 'utils'

import { genField } from 'components/controls/Field'
import { IArrayOption } from 'components/controls/inputComponents/FieldNumber/FieldNumber'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { OFFSET_ARRAY, simplifyStyleValue } from 'services/Branding/constants/fields'
import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

import accordionIndents from './assets/accordionIndents.svg'
import componentIndents from './assets/componentIndents.svg'
import contentIndents from './assets/contentIndents.svg'
import iconIndents from './assets/iconIndents.svg'

const INTERVAL_ARRAY: IArrayOption[] = [
  {
    icon: (
      <div>
        <Icon name='builderInputUp' />
        <Icon name='builderInputDown' />
      </div>
    ),
  },
]

const ICON_INDENTS_ARRAY: IArrayOption[] = [
  { icon: <Icon name='builderInputForward' /> },
  { icon: <Icon name='builderInputBack' /> },
]

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.accordion.style, {
  collapse: {
    META: { label: t('elements.accordion.tags.accordion') },
    type: genField({
      type: 'select',
      layout: ['horizontal', 'solid'],
      label: t('elements.accordion.schema.type'),
      params: {
        options: [
          {
            label: t('elements.accordion.schema.typeOptions.splitted'),
            value: AccordionType.SPLITTED,
          },
          { label: t('elements.accordion.schema.typeOptions.solid'), value: AccordionType.SOLID },
        ],
      },
    }),
    backgroundColor: {},
  },
  hover: {
    META: { hidden: true },
    backgroundColor: {
      label: t('style.hoverColor'),
      params: {
        resettable: true,
      },
    },
  },
  select: {
    META: { hidden: true },
    backgroundColor: {
      label: t('elements.accordion.tags.select'),
      params: {
        resettable: true,
      },
    },
  },
  border: {
    META: { hidden: true },
    borderRadius: {},
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
  },
  divider: {
    META: { hidden: true },
    displayDivider: genField({
      type: 'select',
      layout: ['horizontal', 'solid'],
      label: t('elements.accordion.schema.displayDivider'),
      params: (block, { name }) => {
        const { parent } = getParent<{ collapse: { type?: string } }>(name, block, 2)
        const typeStyle = simplifyStyleValue(parent?.collapse.type)
        return {
          hidden: typeStyle !== AccordionType.SOLID,
          options: [
            {
              label: t('elements.accordion.schema.displayDividerOptions.all'),
              value: AccordionDisplayDivider.ALL,
            },
            {
              label: t('elements.accordion.schema.displayDividerOptions.inner'),
              value: AccordionDisplayDivider.INNER,
            },
            {
              label: t('elements.accordion.schema.displayDividerOptions.first'),
              value: AccordionDisplayDivider.FIRST,
            },
            {
              label: t('elements.accordion.schema.displayDividerOptions.hide'),
              value: AccordionDisplayDivider.HIDE,
            },
          ],
        }
      },
    }),
    borderTopColor: genField({
      type: 'color',
      label: t('elements.accordion.schema.colorDivider'),
      layout: ['horizontal', 'solid'],
      params: (block, { name }) => {
        const { parent } = getParent<AccordionSchemaType>(name, block, 2)
        const type = simplifyStyleValue(parent?.collapse.type)
        const divider = simplifyStyleValue(parent?.divider.displayDivider)

        return {
          hidden: divider === AccordionDisplayDivider.HIDE || type !== AccordionType.SOLID,
          labeled: true,
        }
      },
    }),
    borderTopWidth: genField({
      type: 'slider',
      label: t('elements.accordion.schema.thicknessDivider'),
      layout: 'horizontal',
      params: (block, { name }) => {
        const { parent } = getParent<AccordionSchemaType>(name, block, 2)
        const type = simplifyStyleValue(parent?.collapse.type)
        const divider = simplifyStyleValue(parent?.divider.displayDivider)

        return {
          min: 1,
          max: 8,
          hidden: divider === AccordionDisplayDivider.HIDE || type !== AccordionType.SOLID,
          showInput: true,
        }
      },
    }),
  },
  effects: {
    META: { label: t('style.effects') },
    boxShadow: { label: t('elements.accordion.schema.boxShadow') },
  },
  icon: {
    META: { label: t('elements.accordion.tags.icon') },
    position: genField({
      type: 'segmented',
      layout: ['horizontal', 'solid'],
      label: t('elements.accordion.schema.place'),
      params: {
        options: [
          { label: <Icon name='builderArrowLeft' size={KitSize.S} />, value: 'left' },
          { label: <Icon name='builderArrowRight' size={KitSize.S} />, value: 'right' },
        ],
      },
    }),
    color: { label: t('input.label.iconColor') },
    backgroundImage: {
      label: t('elements.accordion.schema.closed'),
      params: { preview: false },
    },
    backgroundOpen: genField({
      type: 'fileId',
      layout: 'vertical',
      params: { fileType: 'image' },
      label: t('elements.accordion.schema.open'),
    }),
  },
  indents: {
    META: { label: t('elements.accordion.tags.indents') },
    accordion: genField({
      type: 'number',
      label: t('elements.accordion.schema.accordionIndents'),
      layout: 'vertical',
      info: <img alt='image' src={accordionIndents} />,
      params: { array: OFFSET_ARRAY, min: 0 },
    }),
    content: genField({
      type: 'number',
      label: t('elements.accordion.schema.contentIndents'),
      layout: 'vertical',
      info: <img alt='image' src={contentIndents} />,
      params: { array: OFFSET_ARRAY, min: 0 },
    }),
    icon: genField({
      type: 'number',
      label: t('input.label.iconIndents'),
      layout: 'vertical',
      info: <img alt='image' src={iconIndents} />,
      params: { array: ICON_INDENTS_ARRAY, min: 0 },
    }),
    gap: {
      label: t('elements.accordion.schema.componentInterval'),
      layout: 'vertical',
      info: <img alt='image' src={componentIndents} />,
      params: { array: INTERVAL_ARRAY, min: 0 },
    },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.accordion.font, FONT_SCHEMA_FIELDS)
