import { gql } from 'gql/__generated__'

export const tasks = gql(`
  query TasksQuery($companyId: ID!, $search: TasksSearch!) {
    data: tasks(companyId: $companyId, search: $search) {
      tasks {
        ...TaskSmallSchema
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`)

export const tasksCSV = gql(`
  query TaskCSVQuery($companyId: ID!, $search: TasksSearch!) {
    data: tasks(companyId: $companyId, search: $search) {
      tasks {
        ...TaskFullSchema
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`)

export const task = gql(`
  query TaskQuery($companyId: ID!, $id: ID!) {
    data: task(companyId: $companyId, id: $id) {
      ...TaskFullSchema
    }
  }
`)
