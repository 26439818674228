import { CourseProgressFontSchemaType, CourseProgressSchemaType } from '@vedalib/editor/lib/brand'
import { CONFIG_ELEMENTS, CourseProgressElementValue } from '@vedalib/editor/lib/elements'

import { DEMO_MODE } from 'services/Store/Project/constants'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './CourseProgressElement'

const DEMO_VALUE: CourseProgressElementValue = {
  percentPosition: 'right',
}

const CourseProgressDemo: React.FC<
  IElementDemoProps<CourseProgressSchemaType, CourseProgressFontSchemaType>
> = ({ styles, fonts }) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.courseProgress.type}-demo`,
        type: CONFIG_ELEMENTS.courseProgress.type,
        value: DEMO_VALUE,
        style: {},
        font: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

export default CourseProgressDemo
