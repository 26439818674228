import React from 'react'

import { t } from 'services/Translation'
import { dateMathFormat } from 'utils/contentTypes'
import { getDate } from 'utils/getDate'
import { testProps } from 'utils/test/qaData'

import EmployeeAvatar from '../EmployeeAvatar'
import { AvatarMock } from '../EmployeeAvatar/EmployeeAvatar'
import * as s from './EmployeeCard.module.scss'

interface IEmployeeCardProps {
  employee: {
    id: string
    updatedAt: string
    status?: string | null
    kUser: {
      name: string
      email: string
      avatar: AvatarMock | null
    }
  }
  children?: React.ReactNode
}

const EmployeeCard: React.FC<IEmployeeCardProps> = ({ employee, children }) => {
  return (
    <div
      className={s.root}
      {...testProps({ el: 'employeeCard', name: employee.kUser.name, id: employee.id })}
    >
      <EmployeeAvatar employee={employee} size={80} />
      <div className={s.info}>
        <div className={s.titleRow}>
          <div className={s.title}>{employee.kUser.name}</div>
          <div className={s.description}>
            {t('uiKit.employee.updated')} {getDate(employee.updatedAt, dateMathFormat)}
          </div>
        </div>
        <div className={s.description}>
          {t('uiKit.employee.id')} {employee.id}
        </div>
        {children}
      </div>
    </div>
  )
}

export default EmployeeCard
