import { FileUsage, FileUsageImageSource } from '@vedalib/editor/lib/files'
import { useCallback } from 'react'
import { FileTypeEnum } from 'utils'

import { IAsyncOnChange } from 'components/controls/Field/Field.types'
import { useFilePickerModal } from 'components/pages/FinderPage'

import { FileTypes } from './FileLoader'

type FieldFileValue = FileUsage | null

type IFieldFile<V> = {
  value: V
  fileType: FileTypes
  onBlur: ((e?: unknown) => void) | undefined
  onFocus: ((e?: unknown) => void) | undefined
  name: string
  onChange: IAsyncOnChange<V>
}

export const useFileHandlers = <V extends FieldFileValue = FieldFileValue>({
  onChange,
  name,
  value,
  onFocus,
  onBlur,
  fileType,
}: IFieldFile<V>) => {
  const filePick = useFilePickerModal({
    defaultContentType: (fileType === 'document' ? undefined : fileType) as FileTypeEnum,
  })

  const handlePickFile = async () => {
    onFocus?.()
    const fileId = await filePick.open({})
    if (fileId) {
      onChange?.({ value: { ...value, id: fileId, source: FileUsageImageSource.file }, name })
    }
    onBlur?.()
  }

  const handleResetFile = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (value) {
      onChange?.({ value: { ...value, id: null, source: FileUsageImageSource.file }, name })
    }
  }

  const handleChangeUrl = useCallback(
    (url: string) => onChange({ name, value: { ...value, source: FileUsageImageSource.url, url } }),
    [name, onChange, value],
  )

  const handleChangeObjectFit = useCallback(
    (objectFit: string) => onChange({ name, value: { ...value, objectFit } }),
    [name, onChange, value],
  )

  return {
    handlePickFile,
    handleResetFile,
    handleChangeUrl,
    handleChangeObjectFit,
  }
}
