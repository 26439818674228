import { StyleFullValue } from '@vedalib/editor/lib/brand'
import { StyleValue } from '@vedalib/editor/lib/elements'
import React from 'react'
import { css } from 'styled-components'

import { ElementStyleCss } from './types'

// IF WE WILL HAVE PROBLEMS WITH PERFORMANCE WE CAN USE INLINE STYLES (NEED CHECK FROM PRODUCTION)
export const border = (
  border: Pick<React.CSSProperties, 'borderStyle' | 'borderWidth' | 'borderColor' | 'borderRadius'>,
) => css`
  border-style: ${border.borderStyle};
  border-color: ${border.borderColor};
  border-width: ${border.borderWidth};
  border-radius: ${border.borderRadius};
`

export const font = (
  font: Pick<
    React.CSSProperties,
    'color' | 'fontFamily' | 'fontSize' | 'fontStyle' | 'fontWeight' | 'lineHeight'
  > & { hoverColor?: StyleValue },
) => css`
  color: ${font.color};
  font-size: ${font.fontSize};
  font-weight: ${font.fontWeight};
  font-family: ${font.fontFamily};
  font-style: ${font.fontStyle};
  line-height: ${font.lineHeight};
  ${typeof font.hoverColor === 'string' &&
  css`
    &:hover {
      color: ${font.hoverColor};
    }
  `}
`

export const shell = (shell: React.CSSProperties) => css`
  width: ${shell.width};
  height: ${shell.height};
  ${shell.maxHeight ? `max-height: ${shell.maxHeight}px` : ''};
  ${shell.maxWidth ? `max-width: ${shell.maxWidth}px` : ''};
  ${shell.minHeight ? `min-height: ${shell.minHeight}px` : ''};
  ${shell.minWidth ? `min-width: ${shell.minWidth}px` : ''};
`

export const hover = <
  V extends ElementStyleCss<S>,
  S extends { hover: { backgroundColor?: StyleFullValue<string> } },
>({
  $styles: { hover },
}: {
  $styles: V
}) =>
  hover && 'backgroundColor' in hover
    ? css`
        &:hover {
          background-color: ${hover.backgroundColor};
        }
      `
    : ''
