// extracted by mini-css-extract-plugin
var _1 = "Segmented_active__2kM8d";
var _2 = "Segmented_disabled__cWklU";
var _3 = "Segmented_divider__e3B2B";
var _4 = "Segmented_fluid__Ape6m";
var _5 = "Segmented_input__7WFRG";
var _6 = "Segmented_item__px4I0";
var _7 = "Segmented_label__CgGYW";
var _8 = "Segmented_large__bMBVA";
var _9 = "Segmented_medium__BxITj";
var _a = "Segmented_mini__xRJ33";
var _b = "Segmented_option__CqUmN";
var _c = "Segmented_root__cIpMu";
var _d = "Segmented_xmini__+SV+0";
export { _1 as "active", _2 as "disabled", _3 as "divider", _4 as "fluid", _5 as "input", _6 as "item", _7 as "label", _8 as "large", _9 as "medium", _a as "mini", _b as "option", _c as "root", _d as "xmini" }
