import { RichTextValue, SlateMark } from '@vedalib/rich-text'
import { addMark, Editor, Element } from 'slate'

export const getCurrentElementTypes = (editor: Editor) => {
  const [element] = Editor.nodes(editor, {
    match: (n) => Element.isElement(n) && Editor.isBlock(editor, n),
    mode: 'lowest',
  })
  // @ts-expect-error TODO
  return element ? element[0]?.type : undefined
}

export const isInSlate = (el?: HTMLElement) => {
  let current: HTMLElement | null = el || null
  while (current) {
    if (current.dataset?.slateEditor === 'true') {
      return true
    }

    current = current.parentElement
  }
  return false
}

export const insertWithFormating = (
  editor: Editor,
  format: Record<string, unknown>,
  value: RichTextValue,
) => {
  const marks = Object.keys(format).filter(
    (key) => format[key] && Object.values(SlateMark).includes(key as SlateMark),
  )
  const anchor =
    editor.selection && editor.selection.anchor.offset < editor.selection.focus.offset
      ? editor.selection.anchor
      : editor.selection?.focus
  editor.insertFragment(value)
  editor.setSelection({
    anchor: anchor,
    focus: editor.selection?.focus,
  })
  marks.forEach((mark) => {
    addMark(editor, mark, format[mark])
  })
}
