// extracted by mini-css-extract-plugin
var _1 = "AiForm_bottomControls__zWnnu";
var _2 = "AiForm_buttonsRight__yT+go";
var _3 = "AiForm_commandsList__nQVIA";
var _4 = "AiForm_controls__zO6YX";
var _5 = "AiForm_iconWrapper__BKESU";
var _6 = "AiForm_input__g7TE0";
var _7 = "AiForm_loader__u4unQ";
var _8 = "AiForm_prompt__MUXgg";
var _9 = "AiForm_promtTextCard__AX4BE";
var _a = "AiForm_responseContainer__BPwRi";
var _b = "AiForm_result__zT3j3";
var _c = "AiForm_resultText__0aBUp";
var _d = "AiForm_resultTitle__1yvNn";
var _e = "AiForm_root__gVvYs";
var _f = "AiForm_title__V189e";
export { _1 as "bottomControls", _2 as "buttonsRight", _3 as "commandsList", _4 as "controls", _5 as "iconWrapper", _6 as "input", _7 as "loader", _8 as "prompt", _9 as "promtTextCard", _a as "responseContainer", _b as "result", _c as "resultText", _d as "resultTitle", _e as "root", _f as "title" }
