import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { IDropdownCourseContent } from '../DropdownCourse'

interface IContentRootStyledParams {
  $disbale?: boolean
  $type: IDropdownCourseContent['type']
  $theme?: IDropdownCourseContent['theme']
  $styleType?: IDropdownCourseContent['styleType']
}

const ligthTheme = css`
  background-color: var(--color-background-main);
  color: var(--color-text-main);
`

const darkTheme = css`
  background-color: var(--color-background-dark);
  color: var(--color-white);
`

const warningTheme = css`
  background-color: var(--color-orange);
  color: var(--color-white);
`

const purpleTheme = css`
  background-color: var(--color-violet);
  color: var(--color-white);
`

const disabled = (disbale?: boolean) =>
  disbale &&
  css`
    cursor: not-allowed;
  `

const tooltip = css`
  padding: 4px 8px;
  background-color: var(--color-background-dark);
  color: var(--color-white);
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  word-break: break-word;
`

const clearStyleType = css`
  padding: 0;
`

const ContentStyled = styled(motion.div)<IContentRootStyledParams>`
  display: flex;
  position: absolute;
  z-index: 10000;
  pointer-events: all;
  padding: 8px 0;
  border-radius: 6px;
  box-shadow: var(--box-shadow-default);
  background: var(--color-background-main);
  color: var(--color-text-main);
  overflow: auto;
  overflow-y: auto;
  flex-direction: column;
  ${({ $type }) => $type === 'tooltip' && tooltip};
  ${({ $disbale }) => disabled($disbale)};
  ${({ $theme }) => $theme === 'dark' && darkTheme};
  ${({ $theme }) => $theme === 'light' && ligthTheme};
  ${({ $theme }) => $theme === 'warning' && warningTheme};
  ${({ $theme }) => $theme === 'purple' && purpleTheme};
  ${({ $styleType }) => $styleType === 'clear' && clearStyleType};
`

export default ContentStyled
