import { MutableRefObject, useEffect } from 'react'

interface IUseEvents {
  inPopupOrChild: (element: Node) => boolean
  popupEle: HTMLDivElement | null
  openRef: MutableRefObject<boolean>
  triggerOpen: (nextOpen: boolean) => void
  onVisibleChange: ((visible: boolean) => void) | undefined
}

export const useEvents = ({
  inPopupOrChild,
  triggerOpen,
  onVisibleChange,
  openRef,
  popupEle,
}: IUseEvents) => {
  useEffect(() => {
    if (popupEle) {
      const onWindowClick = (e: MouseEvent) => {
        const target = e.target as Node
        if (openRef?.current && !inPopupOrChild(target)) {
          triggerOpen(false)
          onVisibleChange?.(false)
        }
      }

      const closeOnEsc = (e: KeyboardEvent) => {
        const target = e.target as Node
        if (e.key === 'Escape' && openRef?.current && !inPopupOrChild(target)) {
          triggerOpen(false)
          onVisibleChange?.(false)
        }
      }

      document.addEventListener('mousedown', onWindowClick, true)
      document.addEventListener('keydown', closeOnEsc)
      return () => {
        document.removeEventListener('mousedown', onWindowClick, true)
        document.removeEventListener('keydown', closeOnEsc)
      }
    }
    return () => undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupEle])
}
