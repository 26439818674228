import { useSize, useInViewport, useUpdateEffect } from 'ahooks'
import { motion } from 'framer-motion'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import Loader from 'components/uiKit/Loader'
import { IMenuOption } from 'components/uiKit/Menu'
import { EmployeePreferencesTab, TemplatesListSchemaFragment } from 'gql/__generated__/graphql'
import { TemplateActionsUiEnum } from 'routes/routes/App/routes/Company/routes/Templates/components/enum'
import { extendTestData } from 'utils/test/qaData'

import { TemplatePickerMode } from '..'
import CellRendererBlock from '../CellRendererBlock'
import {
  COUNT_LIMIT,
  FAKE_SIZE,
  HORIZONTAL_GAP,
  OPACITY_FINAL,
  OPACITY_INITIAL,
  SCALE_FINAL,
  SCALE_INITIAL,
  TEMPLATE_MIN_WIDTH,
  TRANSITION_DURATION,
  TRANSITION_EASE,
  VERTICAL_GAP,
} from '../helper'
import * as s from './MasonaryTemplate.module.scss'
import { useMasonry } from './useMasonry'

export interface IItemProps {
  selected: string[]
  onClick: (value: TemplatesListSchemaFragment, e: React.MouseEvent) => void
  removeByIndex: (value: number, e: React.MouseEvent) => void
  tab?: EmployeePreferencesTab
  search?: string
  companyId: string
  projectId?: string
}

interface IMasonryTemplateProps {
  items: TemplatesListSchemaFragment[]
  itemProps: IItemProps
  mode: TemplatePickerMode
  actions?: IMenuOption<TemplateActionsUiEnum>[]
  onClickActions?: (value: string, uuid: string) => void
  showFavorite?: boolean
  columns?: number
  fetchMore?: () => void
}

const calculateColumns = (containerWidth: number, columnWidth: number, xGap: number) => {
  const availableWidth = containerWidth + xGap
  return Math.floor(availableWidth / (columnWidth + xGap))
}

const MasonryTemplate: React.FC<IMasonryTemplateProps> = ({
  items,
  itemProps,
  mode,
  actions,
  onClickActions,
  showFavorite,
  columns = 3,
  fetchMore,
}) => {
  const keys = useMemo(() => items.map((t) => t.uuid), [items])
  const [rootRef, setRootRef] = useState<HTMLDivElement | null>(null)
  const size = useSize(rootRef)
  const ref = useRef<HTMLDivElement | null>(null)
  const bottomRef = useRef<HTMLDivElement | null>(null)
  const [inViewport] = useInViewport(ref)
  const [inViewportBottom] = useInViewport(bottomRef)
  const isCalculating = useRef(false)
  const isLoading = useRef(true)
  const fakeSize = fetchMore ? FAKE_SIZE : 0

  const config = useMemo(() => {
    const baseConfig = {
      columns,
      columnWidth: TEMPLATE_MIN_WIDTH,
      xGap: HORIZONTAL_GAP,
      yGap: VERTICAL_GAP,
    }

    if (size?.width) {
      baseConfig.columns =
        columns === 0 ? calculateColumns(size.width, TEMPLATE_MIN_WIDTH, HORIZONTAL_GAP) : columns
      baseConfig.columnWidth = Math.floor(
        (size.width - baseConfig.xGap * (baseConfig.columns - 1)) / baseConfig.columns,
      )
    }

    return baseConfig
  }, [size?.width, columns])

  const { positions, setHeight } = useMasonry(keys, config, isCalculating)

  const height = useMemo(() => {
    return positions.reduce(
      (acc, position) => Math.max(acc, position.style.top + position.style.height),
      0,
    )
  }, [positions])

  useUpdateEffect(() => {
    if ((inViewport || inViewportBottom) && fetchMore && !isCalculating.current) {
      fetchMore()
    }
  }, [inViewport, inViewportBottom])

  useEffect(() => {
    if (positions.length > 0) {
      isLoading.current = false
    }
  }, [positions])

  return (
    <div
      className={s.root}
      ref={setRootRef}
      style={{ height: items.length ? height + fakeSize : 'auto' }}
    >
      {isLoading.current && (
        <div className={s.loader}>
          <Loader name='dataTemplatesLoader' resources />
        </div>
      )}

      {positions.map(({ key, style }, index) => {
        const item = items.find((t) => t.uuid === key)
        if (!item) {
          return null
        }

        return (
          <motion.div
            animate={{ opacity: OPACITY_FINAL, scale: SCALE_FINAL }}
            className={s.item}
            exit={{ opacity: OPACITY_INITIAL, scale: SCALE_INITIAL }}
            initial={{ opacity: OPACITY_INITIAL, scale: SCALE_INITIAL }}
            key={key}
            onClick={(e: React.MouseEvent) => itemProps.onClick(item, e)}
            ref={
              index === positions.length - COUNT_LIMIT
                ? ref
                : index === positions.length - 1
                  ? bottomRef
                  : undefined
            }
            style={{
              height: style.height,
              width: style.width,
              position: 'absolute',
              left: style.left,
              top: style.top,
            }}
            transition={{ duration: TRANSITION_DURATION, ease: TRANSITION_EASE }}
          >
            <CellRendererBlock
              actions={actions}
              index={index}
              key={key}
              mode={mode}
              {...itemProps}
              onClickActions={onClickActions}
              onHeight={setHeight}
              showFavorite={showFavorite}
              template={item}
              testData={extendTestData({ label: item.name, value: item.uuid })}
              width={config.columnWidth}
            />
          </motion.div>
        )
      })}
      {fetchMore && inViewport !== undefined && (
        <div className={s.loaderRow} style={{ top: height }}>
          <Loader name='dataFallbackLoader' />
        </div>
      )}
    </div>
  )
}

export default React.memo(MasonryTemplate)
