import lodash from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'

import { NOOP } from 'constants/commonConstans'

type MasonryConfig = {
  columns: number
  columnWidth: number
  xGap: number
  yGap: number
}

type RefData = {
  items: string[]
  recalculate: () => void
  setHeight: (key: string, height: number) => void
  config: MasonryConfig
}

type ItemPosition = {
  key: string
  style: {
    left: number
    top: number
    height: number
    width: number
  }
}

const DEFAULT_TEMPLATE_HEIGHT = 150
const BOX_SHADOW = 20

const cache: { [key: string]: number } = {}

const calculatePositions = (
  items: string[],
  config: MasonryConfig,
  getHeight: (key: string) => number,
) => {
  const columns = Array.from({ length: config.columns }, (_item, i) => ({
    keys: [] as string[],
    index: i,
    height: 0,
  }))

  return items.map((item) => {
    const height = getHeight(item) + BOX_SHADOW
    const column = columns.reduce((prev, curr) => (prev.height < curr.height ? prev : curr))

    column.keys.push(item)
    const position = {
      key: item,
      style: {
        left: (config.columns - 1 - column.index) * (config.columnWidth + config.xGap),
        top: column.height,
        height,
        width: config.columnWidth,
      },
    }
    column.height += height + config.yGap
    return position
  })
}

export const useMasonry = (
  items: string[],
  configParam: MasonryConfig,
  isCalculating: React.MutableRefObject<boolean>,
) => {
  const renderData = useRef<RefData>({
    items: items || [],
    recalculate: NOOP,
    config: configParam,
    setHeight: (key: string, height: number) => {
      if (cache[key] !== height) {
        cache[key] = height
        renderData.current.recalculate()
      }
    },
  })
  const positionsRef = useRef<ItemPosition[]>([])
  const [positions, setPositions] = useState<ItemPosition[]>([])
  const result = renderData.current
  const getHeight = useMemo(() => (key: string) => cache[key] || DEFAULT_TEMPLATE_HEIGHT, [])

  useEffect(() => {
    isCalculating.current = true
    result.config = configParam

    const recalculateForce = () => {
      const newPositions = calculatePositions(items, result.config, getHeight)
      isCalculating.current = false

      if (!lodash.isEqual(positionsRef.current, newPositions)) {
        positionsRef.current = newPositions
        setPositions(newPositions)
      }
    }

    result.recalculate = lodash.debounce(recalculateForce, 500)

    if (items.length > 0) {
      result.recalculate()
    }
  }, [items, configParam, isCalculating, getHeight, result])

  return { positions, setHeight: result.setHeight }
}
