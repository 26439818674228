import { useCallback, useMemo } from 'react'
import { ColorWrapChangeHandler } from 'react-color/lib/components/common/ColorWrap'

import { IKitControl } from 'components/uiKit/KitTypes'
import { testPropsEl } from 'utils/test/qaData'

import ColorCircle from '../ColorCircle'
import { isColorResult, isRGB, toColor } from '../helpers'
import * as s from './Palette.module.scss'

export interface IPaletteProps extends IKitControl<string> {
  options?: string[]
}

const Palette: React.FC<IPaletteProps> = ({ value, options, onChange, size, name }) => {
  const { hexa } = useMemo(() => toColor(value), [value])
  const palette = useMemo(() => options?.map((item) => toColor(item)), [options])

  const handleOnChange: ColorWrapChangeHandler = useCallback(
    (value) => {
      let result = `rgba(255, 255, 255, 1)`
      if (typeof value === 'string') {
        result = value
      } else if (isColorResult(value)) {
        const { r, g, b, a = 1 } = value.rgb || {}
        result = `rgba(${r}, ${g}, ${b}, ${a})`
      } else if (isRGB(value)) {
        const { r, g, b, a = 1 } = value
        result = `rgba(${r}, ${g}, ${b}, ${a})`
      }

      onChange?.(result)
    },
    [onChange],
  )
  // {...testProps({ el: 'palette', name, color: item.hexa })}

  return (
    <div {...testPropsEl('palette', name)} className={s.root}>
      {palette?.map((item) => (
        <ColorCircle
          active={item.hexa === hexa}
          color={item.hexa}
          key={item.hexa}
          name={name}
          onClick={() => handleOnChange(item.rgba)}
          size={size}
        />
      ))}
    </div>
  )
}

export default Palette
