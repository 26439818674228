import { htmlToSlate, textToRtValue } from '@vedalib/rich-text'
import { marked } from 'marked'
import { useCallback } from 'react'
import { Editor } from 'slate'
import { useSlate } from 'slate-react'

import { AiTextPromptTogglesEnum } from 'gql/__generated__/graphql'
import { useGenerateText, useAiUsageAccept } from 'gql/neuron/apollo'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getProjectId, getSectionId } from 'services/Store/Project/selectors'
import { getCurrentLocale } from 'services/Translation/i18n'

export const useAi = () => {
  const [genText, { data, loading, error, called }] = useGenerateText()
  const [acceptAiUsage] = useAiUsageAccept()
  const projectId = useProjectContext(getProjectId)
  const sectionId = useProjectContext(getSectionId)
  const locale = getCurrentLocale()
  const editor = useSlate()
  const text = editor.selection && Editor.string(editor, editor.selection)

  const sendPrompt = useCallback(
    async (preprompt: AiTextPromptTogglesEnum, custom: string = '') => {
      const { data } = await genText({
        variables: {
          data: {
            projectId,
            sectionId,
            instruction: preprompt === AiTextPromptTogglesEnum.CUSTOM ? custom : '',
            text: text || '',
            key: preprompt,
            locale: locale,
          },
        },
      })

      if (!data?.data) {
        return
      }

      return data.data
    },
    [genText, locale, projectId, sectionId, text],
  )

  let slate = textToRtValue('')
  if (data?.data) {
    const html = marked.parse(data?.data.content) as string
    slate = htmlToSlate(html)
  }

  return {
    sendPrompt,
    acceptAiUsage,
    slate,
    response: {
      data: data?.data,
      loading,
      error,
      called,
    },
  }
}
