import {
  PositionTabsElementType,
  TabsSchemaType,
  ViewTabsElementType,
} from '@vedalib/editor/lib/brand'
import styled, { css } from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

export const OVERFLOW_OFFSET = 48

const getMaskByPosition = (
  styles: ElementStyleCss<TabsSchemaType>,
  hasPrevScroll?: boolean,
  hasNextScroll?: boolean,
) => css`
  background: transparent;
  border: none;
  border-radius: 0;
  padding: ${OVERFLOW_OFFSET}px;
  mask: none;

  ${(styles.tabs.position as PositionTabsElementType) === PositionTabsElementType.top &&
  hasPrevScroll &&
  hasNextScroll &&
  css`
    mask: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) ${OVERFLOW_OFFSET}px,
      rgba(0, 0, 0, 1) ${OVERFLOW_OFFSET * 2}px,
      rgba(0, 0, 0, 1) calc(100% - ${OVERFLOW_OFFSET * 2}px),
      rgba(0, 0, 0, 0) calc(100% - ${OVERFLOW_OFFSET}px)
    );
  `}

  ${(styles.tabs.position as PositionTabsElementType) === PositionTabsElementType.top &&
  hasPrevScroll &&
  !hasNextScroll &&
  css`
    mask: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) ${OVERFLOW_OFFSET}px,
      rgba(0, 0, 0, 1) ${OVERFLOW_OFFSET * 2}px,
      rgba(0, 0, 0, 1) calc(100% - ${OVERFLOW_OFFSET}px)
    );
  `}

  ${(styles.tabs.position as PositionTabsElementType) === PositionTabsElementType.top &&
  hasNextScroll &&
  !hasPrevScroll &&
  css`
    mask: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) ${OVERFLOW_OFFSET}px,
      rgba(0, 0, 0, 1) calc(100% - ${OVERFLOW_OFFSET * 2}px),
      rgba(0, 0, 0, 0) calc(100% - ${OVERFLOW_OFFSET}px)
    );
  `}


  ${(styles.tabs.position as PositionTabsElementType) !== PositionTabsElementType.top &&
  hasPrevScroll &&
  hasNextScroll &&
  css`
    mask: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) ${OVERFLOW_OFFSET}px,
      rgba(0, 0, 0, 1) ${OVERFLOW_OFFSET * 2}px,
      rgba(0, 0, 0, 1) calc(100% - ${OVERFLOW_OFFSET * 2}px),
      rgba(0, 0, 0, 0) calc(100% - ${OVERFLOW_OFFSET}px)
    );
  `}

  ${(styles.tabs.position as PositionTabsElementType) !== PositionTabsElementType.top &&
  hasPrevScroll &&
  !hasNextScroll &&
  css`
    mask: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) ${OVERFLOW_OFFSET}px,
      rgba(0, 0, 0, 1) ${OVERFLOW_OFFSET * 2}px,
      rgba(0, 0, 0, 1) calc(100% - ${OVERFLOW_OFFSET}px)
    );
  `}

  ${(styles.tabs.position as PositionTabsElementType) !== PositionTabsElementType.top &&
  hasNextScroll &&
  !hasPrevScroll &&
  css`
    mask: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) ${OVERFLOW_OFFSET}px,
      rgba(0, 0, 0, 1) calc(100% - ${OVERFLOW_OFFSET * 2}px),
      rgba(0, 0, 0, 0) calc(100% - ${OVERFLOW_OFFSET}px)
    );
  `}
`

const TabItemsScrollStyled = styled.div<{
  $styles: ElementStyleCss<TabsSchemaType>
  $hasPrevScroll?: boolean
  $hasNextScroll?: boolean
}>`
  position: relative;
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  ${({ $styles, $hasPrevScroll, $hasNextScroll }) =>
    $styles.view.type === ViewTabsElementType.splitted &&
    getMaskByPosition($styles, $hasPrevScroll, $hasNextScroll)};
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

export default TabItemsScrollStyled
