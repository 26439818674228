import { CONFIG_ELEMENTS } from '@vedalib/editor/lib/elements'

import EditableProMode from 'components/editor-v3/cource/layout/controls/ElementControls/EditableProMode'
import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './AccordionBrandFormSchema'
import Demo from './AccordionDemo'
import Element from './AccordionElement'
import Form from './AccordionForm'
import accordionIcon from './assets/accordion.svg'

export default genElementConfig(CONFIG_ELEMENTS.accordion, {
  label: t('elements.accordion.label'),
  icon: accordionIcon,
  group: ElementGroup.interactive,
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  EditorActions: EditableProMode,
  Demo,
})
