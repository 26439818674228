import { SCHEMA } from '@vedalib/editor/lib/brand'

import { genField } from 'components/controls/Field'
import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.divider.style, {
  line: {
    META: { label: t('elements.divider.tags.line') },
    borderColor: { label: t('input.label.color') },
    borderWidth: { params: { min: 1, max: 10 } },
  },
  numbered: {
    META: { label: t('elements.divider.tags.numbering') },
    backgroundColor: {},
    justifyContent: {},
    height: {},
    width: {},
    borderRadius: { params: { min: 0, max: 100 } },
    borderStyle: {},
    borderColor: {},
    borderWidth: { params: { min: 1, max: 10 } },
  },
  effects: {
    META: { label: t('style.effects') },
    boxShadow: { label: t('elements.divider.schema.boxShadow') },
  },
  double: {
    META: { label: t('elements.divider.tags.double') },
    marginTop: genField({
      label: t('elements.divider.schema.interval'),
      type: 'number',
      params: { min: 1, max: 20 },
    }),
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.divider.font, {
  base: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('input.label.numbering') },
  },
})
