// extracted by mini-css-extract-plugin
var _1 = "CourseModal_closeDesktop__kTCDI";
var _2 = "CourseModal_closeFix__3sD4A";
var _3 = "CourseModal_closeMobile__h-qgu";
var _4 = "CourseModal_content__73EL4";
var _5 = "CourseModal_contentWrapper__igkDz";
var _6 = "CourseModal_desktop__JBsNn";
var _7 = "CourseModal_mobile__rusFZ";
var _8 = "CourseModal_overlay__3cfN7";
var _9 = "CourseModal_root__iP81D";
var _a = "CourseModal_tablet__1stCR";
export { _1 as "closeDesktop", _2 as "closeFix", _3 as "closeMobile", _4 as "content", _5 as "contentWrapper", _6 as "desktop", _7 as "mobile", _8 as "overlay", _9 as "root", _a as "tablet" }
