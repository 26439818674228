import { useDebounceFn } from 'ahooks'
import * as R from 'ramda'
import { useState } from 'react'

import { IAsyncOnChange } from '../Field/Field.types'

interface IUseRealtimeState<T> {
  defaultValue: T
  onChange: IAsyncOnChange<T | undefined>
  wait?: number
  plain?: boolean
  leading?: boolean
}

export const useRealtimeState = <T>({
  defaultValue,
  onChange,
  wait = 0,
  plain = false,
  leading = false,
}: IUseRealtimeState<T>): [
  T,
  IAsyncOnChange<T | undefined>,
  React.Dispatch<React.SetStateAction<T>>,
] => {
  const [localValue, setLocalValue] = useState<T>(defaultValue)

  const setRealtimeValue: IAsyncOnChange<T | undefined> = useDebounceFn(
    (data) => {
      if (data.name) {
        const name: string[] = plain ? [data.name] : data.name.split('.')
        setLocalValue(R.assocPath(name, data.value, defaultValue))
      } else {
        setLocalValue((prev) => R.mergeAll([prev, data.value]))
      }

      onChange(data)
    },
    { wait, leading },
  ).run

  return [R.mergeAll<T>([defaultValue, localValue]), setRealtimeValue, setLocalValue]
}
