import { createSelector } from '@reduxjs/toolkit'
import lodash from 'lodash'
import * as R from 'ramda'

import { IProjectContext } from 'services/Store/Project/types'

import { blockLens, blocksLens } from '../../lenses'
import { getSection } from '../data'
import { getBlockId } from '../url'

export const getBlocksState = createSelector(
  (state: IProjectContext) => state,
  (_state: IProjectContext, params?: { sectionId?: string }) => params,
  (state, params) => {
    const order = getSection(state, { id: params?.sectionId })?.blocksOrder
    const blocks = R.view(blocksLens)(state)
    return order ? lodash.pick(blocks, order) : blocks
  },
)

export const getBlockState = (state: IProjectContext, blockId = getBlockId(state)) =>
  R.view(blockLens(blockId))(state)

export const getBlockWaiting = (state: IProjectContext, blockId: string) =>
  state.state.blocks[blockId]?.waiting
