import { CourseStructureSchemaType, CourseStructureFontSchemaType } from '@vedalib/editor/lib/brand'
import { CourseStructureElementValue, CourseStructureItem } from '@vedalib/editor/lib/elements'

import { ISectionTreeNode } from 'components/sections/ProjectStructure/utils'
import Collapse from 'components/uiKit/Collapse'
import { ElementStyleCss, ElementFontCss } from 'services/Branding/types'
import { Section } from 'services/Store/Project/types'

import { StructureNodeElement } from './components/StructureNodeElement'

interface IStructureCollapse {
  nodes: ISectionTreeNode<CourseStructureItem>[]
  onSectionPick: (sectionId: string) => void
  section?: Section | null
  value: CourseStructureElementValue
  styles: ElementStyleCss<CourseStructureSchemaType>
  font: ElementFontCss<CourseStructureFontSchemaType>
  sections: Section[]
  getDisabledSection: (id: string) => boolean
  getSectionProgress: (id: string) => number
}

const CourseStructureTree: React.FC<IStructureCollapse> = ({
  nodes,
  onSectionPick,
  section,
  value,
  font,
  styles,
  sections,
  getSectionProgress,
  getDisabledSection,
}) => (
  <>
    {nodes.map((node) =>
      node.children?.length ? (
        <Collapse
          collapsible='icon'
          header={(props) => (
            <StructureNodeElement
              {...props}
              font={font}
              getDisabledSection={getDisabledSection}
              getSectionProgress={getSectionProgress}
              node={node}
              sections={sections}
              styles={styles}
              value={value}
            />
          )}
          initialOpen={value.initialOpen}
          key={node.item.id}
          name={node.item.name || node.item.id}
          styleType='ghost'
        >
          <CourseStructureTree
            font={font}
            getDisabledSection={getDisabledSection}
            getSectionProgress={getSectionProgress}
            nodes={node.children}
            onSectionPick={onSectionPick}
            section={section}
            sections={sections}
            styles={styles}
            value={value}
          />
        </Collapse>
      ) : (
        <StructureNodeElement
          font={font}
          getDisabledSection={getDisabledSection}
          getSectionProgress={getSectionProgress}
          key={node.item.id}
          node={node}
          onClick={() => onSectionPick(node.item.id)}
          sections={sections}
          styles={styles}
          value={value}
          open
        />
      ),
    )}
  </>
)

export default CourseStructureTree
