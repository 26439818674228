import { PermissionsKey } from '@vedalib/crud'
import React, { useMemo } from 'react'
import { useParams } from 'react-router'

import Form from 'components/controls/Form'
import FormControls from 'components/controls/FormControls'
import FormField from 'components/controls/FormField'
import Divider from 'components/uiKit/Divider'
import { KitSize } from 'components/uiKit/KitTypes'
import Modal, { MCWithParams, ModalBody, ModalFooter } from 'components/uiKit/Modal'
import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import { TemplateType } from 'gql/__generated__/graphql'
import { useTemplatesCreate, useTemplatesGetGroup } from 'gql/templates/apollo'
import { usePermissions } from 'services/Permission/PermissionContext'
import { SectionTypeEnum } from 'services/Store/Project/enums'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { CompanyParams } from 'store/models'
import { notEmpty } from 'utils/notEmpty'

import { blockModeToTemplateTag } from '../../editor-v3/Editor/Modals/TemplatePickerModal/types'
import { nameField, createdForField, templateTypes, InputErrorValues } from './fieldConfigs'

interface ITemplateSaveValues {
  name: string
  templateTypes?: TemplateType[]
  editorTemplateGroupId?: string
}

interface IParams {
  block: Block
  sectionType: SectionTypeEnum
}

const TemplateSaveModal: MCWithParams<IParams> = ({ onClose, params: { block, sectionType } }) => {
  const { projectId, companyId } = useParams<CompanyParams & { projectId: string }>()

  const permissions = usePermissions()
  const allowedCollectionTypes = ['project']
  if (permissions.has(PermissionsKey.company_collections_company_u)) {
    allowedCollectionTypes.push('company')
  }

  if (permissions.has(PermissionsKey.company_collections_self)) {
    allowedCollectionTypes.push('employee')
  }

  const { data } = useTemplatesGetGroup({ companyId, data: { projectId } })

  const collections = data?.data?.templateCollections && [
    ...data.data.templateCollections
      .filter((collection) => allowedCollectionTypes.includes(collection.createdFor))
      .map((group) => ({
        value: group.id,
        label: group.name,
      })),
  ]

  const initialFormValues: ITemplateSaveValues = useMemo(
    () => {
      return {
        name: block?.name || '',
        editorTemplateGroupId: '',
        ...([SectionTypeEnum.cover, SectionTypeEnum.test].includes(sectionType) && block?.mode
          ? { templateTypes: [blockModeToTemplateTag(block?.mode)].filter(notEmpty) }
          : {}),
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [block],
  )

  const [createTemplate] = useTemplatesCreate()

  const onFinish = async (values: ITemplateSaveValues) => {
    if (!block) {
      return {}
    }

    if (values?.editorTemplateGroupId) {
      const { data } = await createTemplate({
        variables: {
          data: {
            projectId,
            name: values?.name,
            templateTypes: values.templateTypes || [],
            blockUuid: block?.uuid,
            editorTemplateGroupId: values?.editorTemplateGroupId,
          },
        },
      })
      if (data?.data.data) {
        notify({ type: NotificationType.success, message: t('notify.templateSaved') })
        onClose?.(!!values)
      }
      return data
    }
    return
  }

  return (
    <Form
      initialValues={initialFormValues}
      inputErrorValues={InputErrorValues}
      name='createTemplate'
      onReset={onClose}
      onSubmit={onFinish}
    >
      <Modal name='saveTemplate' title={t('modal.saveTemplate.title')}>
        <ModalBody sizeAutoCapable>
          <FormField config={nameField} />
          <FormField config={createdForField(collections || [])} />
          <Divider size={KitSize.L} />
          <FormField config={templateTypes({ sectionType, mode: block.mode })} />
        </ModalBody>
        <ModalFooter>
          <FormControls justify='right' />
        </ModalFooter>
      </Modal>
    </Form>
  )
}

export default TemplateSaveModal
