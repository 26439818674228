import { SCHEMA } from '@vedalib/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.testResult.style, {
  chartCorrectAnswerEmpty: {
    META: { label: t('elements.testResult.tags.correct') },
    color: { label: t('input.label.progressBackground') },
  },
  chartCorrectAnswerFill: {
    META: { hidden: true },
    color: { label: t('input.label.progressCompleted') },
  },
  chartFailAnswerEmpty: {
    META: { label: t('elements.testResult.tags.fail') },
    color: { label: t('input.label.progressBackground') },
  },
  chartFailAnswerFill: {
    META: { hidden: true },
    color: { label: t('input.label.progressCompleted') },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.testResult.font, {
  h2: {
    ...FONT_SCHEMA_FIELDS.h2,
    META: { label: t('elements.testResult.tags.percents') },
  },
  h3: {
    ...FONT_SCHEMA_FIELDS.h3,
    META: { label: t('elements.testResult.tags.result') },
  },
  h4: {
    ...FONT_SCHEMA_FIELDS.h4,
    META: { label: t('elements.testResult.tags.feedback') },
  },
})
