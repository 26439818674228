import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'

import { DeviceMode, PreviewMode } from 'services/Store/Project/enums'

import { ChartElementType } from '../ChartTypes'

export const PieChartRender: ChartElementType = ({ element, styles, mode, font }) => {
  const isPdf = mode.previewMode === PreviewMode.pdf
  const legendTextFormatter = (value: string) => {
    return <span style={font?.base}>{value}</span>
  }
  const items = element.value.items
  const isMobile = mode.deviceMode === DeviceMode.mobile
  const legendHeight = document.getElementsByClassName('recharts-default-legend')[0]?.clientHeight
  const height = isMobile
    ? Number(styles.shell?.minHeight || 300) + legendHeight * 1.25
    : Number(styles.shell?.minHeight) || 300

  return (
    <ResponsiveContainer
      minHeight={!isMobile ? Number(styles.shell?.minHeight || 300) : height}
      minWidth={Number(styles.shell?.minWidth) || 400}
    >
      <PieChart
        height={!isMobile ? Number(styles.shell?.minHeight || 300) : height}
        width={Number(styles.shell?.minWidth) || 500}
      >
        <Pie
          cx='50%'
          cy='50%'
          data={element.value.items}
          dataKey='val'
          innerRadius='50%'
          isAnimationActive={!isPdf}
          nameKey='label'
          outerRadius='100%'
        >
          {items.map((entry) => (
            <Cell fill={entry.color} key={entry.value} />
          ))}
        </Pie>
        <Tooltip />
        <Legend
          align={isMobile ? 'center' : 'right'}
          formatter={legendTextFormatter}
          iconSize={24}
          iconType='circle'
          layout='vertical'
          verticalAlign={isMobile ? 'bottom' : 'top'}
          wrapperStyle={{
            //@ts-expect-error TODO
            columnCount: !isMobile && legendHeight > (styles.shell?.minHeight || 300) ? 2 : 1,
          }}
        />
      </PieChart>
    </ResponsiveContainer>
  )
}
