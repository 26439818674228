import React, { useCallback } from 'react'

import { t } from 'services/Translation'

import { IconButton } from '../Button'
import { Tooltip } from '../Dropdown'
import { KitSize } from '../KitTypes'
import PageButton from './PageButton'
import * as s from './Pagination.module.scss'
import { getPagesStructure } from './utils'

export interface IPaginationProps {
  /**
   * Total count of items.
   */
  total: number
  /**
   * Count of items on one Page.
   */
  pageSize: number
  /**
   * Current page.
   */
  page: number
  /**
   * Callback for page change.
   */
  onChange: (page: number) => void
  /**
   * Simple pagination in {current page} / {total pages} format.
   */
  simple?: boolean
}

const Pagination: React.FC<IPaginationProps> = ({ total, page, pageSize, onChange, simple }) => {
  const pages = Math.ceil(total / pageSize)
  const prevFive = page - 5 < 1 ? 1 : page - 5
  const nextFive = page + 5 > pages ? pages : page + 5

  const handleNextPage = useCallback(() => {
    onChange(page + 1)
  }, [onChange, page])

  const handlePrevPage = useCallback(() => {
    onChange(page - 1)
  }, [onChange, page])

  return (
    <nav className={s.root} role='navigation'>
      <Tooltip mouseEnterDelay={1} overlay={t('uiKit.pagination.previousPage')}>
        <IconButton
          disabled={page === 1}
          icon='builderInputBack'
          name='prevPage'
          onClick={handlePrevPage}
          size={KitSize.S}
          styleType='ghost'
          round
        />
      </Tooltip>
      <div className={s.pages}>
        {simple && (
          <span>
            {page} / {pages}
          </span>
        )}
        {!simple &&
          getPagesStructure(page, pages).map((p, i) => {
            if (p === '...') {
              return (
                <Tooltip
                  key={i === 1 ? 'prev' : 'next'}
                  mouseEnterDelay={1}
                  overlay={
                    i === 1 ? t('uiKit.pagination.previousFive') : t('uiKit.pagination.nextFive')
                  }
                >
                  <PageButton onClick={() => onChange(i === 1 ? prevFive : nextFive)} page='...' />
                </Tooltip>
              )
            }

            return (
              <PageButton
                active={p === page}
                key={p}
                onClick={() => onChange(p as number)}
                page={p}
              />
            )
          })}
      </div>
      <Tooltip mouseEnterDelay={1} overlay={t('uiKit.pagination.nextPage')}>
        <IconButton
          disabled={page === pages}
          icon='builderInputForward'
          name='nextPage'
          onClick={handleNextPage}
          size={KitSize.S}
          styleType='ghost'
          round
        />
      </Tooltip>
    </nav>
  )
}

export default Pagination
