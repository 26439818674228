export const getPagesStructure = (page: number, pages: number) => {
  if (pages < 7) {
    return [...Array(pages).keys()].map((i) => i + 1)
  }

  if (page < 4) {
    return [1, 2, 3, 4, 5, '...', pages]
  }

  if (page > pages - 3) {
    return [1, '...', pages - 4, pages - 3, pages - 2, pages - 1, pages]
  }

  return [1, '...', page - 2, page - 1, page, page + 1, page + 2, '...', pages].filter(
    (_item, i, arr) => {
      if (i === 1 && Number(arr[2]) - 1 === 1) {
        return false
      }

      if (i === arr.length - 2 && Number(arr[arr.length - 3]) + 1 === pages) {
        return false
      }

      return true
    },
  )
}
