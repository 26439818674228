import { CollapseIconPosition, ProgressIconPosition } from '@vedalib/editor/lib/elements'
import { chainName } from 'utils'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { getEnumOption } from 'utils/enum'

import { CourseStructureValueFormType } from './CourseStructure.types'

const genField = genDataField<Block>()

const showDescription = genField({
  name: 'showDescription',
  type: 'segmented',
  label: t('elements.courseStructure.form.showDescription'),
  layout: ['horizontal', 'solid'],
})

const showChapterIcon = genField({
  name: 'showChapterIcon',
  type: 'segmented',
  label: t('elements.courseStructure.form.showChapterIcon'),
  layout: ['horizontal', 'solid'],
})

const showLandingIcon = genField({
  name: 'showLandingIcon',
  type: 'segmented',
  label: t('elements.courseStructure.form.showLandingIcon'),
  layout: ['horizontal', 'solid'],
})

const showTestIcon = genField({
  name: 'showTestIcon',
  type: 'segmented',
  label: t('elements.courseStructure.form.showTestIcon'),
  layout: ['horizontal', 'solid'],
})

const initialOpen = genField({
  name: 'initialOpen',
  type: 'segmented',
  label: t('elements.courseStructure.form.open'),
  layout: 'horizontal',
})

const progressPosition = genField({
  name: 'progressPosition',
  type: 'select',
  params: {
    options: [
      getEnumOption('ProgressIconPosition', ProgressIconPosition.none),
      getEnumOption('ProgressIconPosition', ProgressIconPosition.left),
      getEnumOption('ProgressIconPosition', ProgressIconPosition.right),
    ],
    fluid: true,
  },
  label: t('input.label.progress'),
  layout: 'horizontal',
})

const collapsePosition = genField({
  name: 'collapsePosition',
  type: 'select',
  params: {
    options: [
      getEnumOption('CollapseIconPosition', CollapseIconPosition.none),
      getEnumOption('CollapseIconPosition', CollapseIconPosition.leftOutside),
      getEnumOption('CollapseIconPosition', CollapseIconPosition.left),
      getEnumOption('CollapseIconPosition', CollapseIconPosition.right),
      getEnumOption('CollapseIconPosition', CollapseIconPosition.rightOutside),
    ],
    fluid: true,
  },
  label: t('elements.courseStructure.form.collapse'),
  layout: 'horizontal',
})

const CourseStructureForm: CourseStructureValueFormType = ({ name }) => {
  return (
    <Padding sides>
      <RealtimeField config={chainName(name, showDescription)} />
      <RealtimeField config={chainName(name, showChapterIcon)} />
      <RealtimeField config={chainName(name, showLandingIcon)} />
      <RealtimeField config={chainName(name, showTestIcon)} />
      <RealtimeField config={chainName(name, initialOpen)} />
      <RealtimeField config={chainName(name, progressPosition)} />
      <RealtimeField config={chainName(name, collapsePosition)} />
    </Padding>
  )
}

export default CourseStructureForm
