import { gqlClient } from 'gql'
import { produce } from 'immer'

import { CompanyListSchemaFragment } from 'gql/__generated__/graphql'

import { companyGetAllQuery } from './gql/queries'

export const companiesUpdateCache = (companies: CompanyListSchemaFragment, userId: string) => {
  const cache = gqlClient.core.cache
  try {
    const data = cache.readQuery({
      query: companyGetAllQuery,
      // @ts-expect-error TODO
      variables: { userId },
    })
    if (data) {
      cache.writeQuery({
        query: companyGetAllQuery,
        // @ts-expect-error TODO
        variables: { userId },
        data: produce(data, (draft) => {
          if (companies && draft?.data) {
            const index = draft.data.companies.findIndex((e) => e.id === companies.id)
            draft.data.companies[index] = companies
          }
        }),
      })
    }
  } catch (err) {
    console.error('"companiesUpdateCache" fn is crashed on operation: ".writeQuery"', err)
  }
}

export const companyLogoUpdate = (companies: Pick<CompanyListSchemaFragment, 'logo' | 'id'>) => {
  const cache = gqlClient.core.cache
  try {
    cache.modify({
      id: cache.identify({
        __typename: 'Company',
        id: companies?.id,
      }),
      fields: {
        //@ts-expect-error TODO
        logo: () => companies.logo,
      },
    })
  } catch (err) {
    console.error('"companyLogoUpdate" fn is crashed on operation: ".modify"', err)
  }
}

export const companyCacheDelete = (companyId: string) => {
  const cache = gqlClient.core.cache
  try {
    cache.evict({
      id: cache.identify({ __typename: 'Company', id: companyId }),
    })
    cache.gc()
  } catch (err) {
    console.error('"companyUpdateCache" fn is crashed on operation: ".evict"', err)
  }
}
