/* eslint-disable react/forbid-component-props */
import { SlateElementType, validateSlate } from '@vedalib/rich-text'
import cn from 'classnames'
import { isCodeHotkey } from 'is-hotkey'
import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react'
import { createEditor, Descendant, Transforms, Range, Editor, Node, Point } from 'slate'
import { Editable, ReactEditor, Slate, withReact } from 'slate-react'

import { EMPTY_ARRAY } from 'constants/commonConstans'
import { AiTextPromptTogglesEnum } from 'gql/__generated__/graphql'
import { t } from 'services/Translation'
import { testProps } from 'utils/test/qaData'

import { IRichTextProps } from './RichText'
import * as s from './RichText.module.scss'
import {
  HOT_KEYS_CONFIG,
  isCommandFormat,
  isElementFormat,
  isElementMarkFormat,
  isMarkFormat,
  SLATE_CLIPBOARD_KEY,
  SlateCommand,
  SlateFormats,
  SYMBOLS_CODES,
} from './RichTextConstants'
import RichTextContext from './RichTextContext'
import { RichTextErrorBoundary } from './RichTextErrorBoundary'
import { RichTextRef, ToolbarForm } from './RichTextTypes'
import BubbleToolbar from './Toolbar/BubbleToolbar'
import { withFeatureControl } from './featureControl/featureControl'
import {
  clearAllMarks,
  inertSymbol,
  insertBreak,
  insertSymbolByCode,
} from './formatOperations/commands'
import { getCurrentFormat, isFormatActive } from './formatOperations/common'
import { toggleElement, updateElementMark } from './formatOperations/elements'
import { setMark } from './formatOperations/textMarks'
import { withInlines, wrapAnnotation, wrapCode, wrapCrossLink, wrapLink } from './inline/withInline'
import ElementResolver from './nodes/Elements'
import Text from './nodes/Text'
import { withLists } from './nodes/withLists'
import { useFontToVars } from './useFontToVars'
import { useForceRender } from './useForceRender'
import { useSelectionDecorate } from './useSelectionDecorate'
import { getCurrentElementTypes } from './utils/common'
import { htmlToSlatePaste } from './utils/htmlToSlate'

const RichTextCore = React.forwardRef<RichTextRef, IRichTextProps>((props, ref) => {
  const { value, styles, name, waiting, active, iterable, onChange, onUp, onDown } = props

  const editor = useMemo(
    () => withFeatureControl(withLists(withInlines(withReact(createEditor())))),
    [],
  )

  useImperativeHandle(ref, () => ({
    editor,
    focus: (cursor?: 'start' | 'end' | 'full') => {
      ReactEditor.focus(editor)
      const start = Editor.start(editor, [])
      const end = Editor.end(editor, [])
      if (cursor === 'start') {
        Transforms.select(editor, start)
      } else if (cursor === 'end') {
        Transforms.select(editor, end)
      } else if (cursor === 'full') {
        Transforms.select(editor, { anchor: start, focus: end })
      }
    },
  }))
  const forceRender = useForceRender(editor, value)
  const [selectedFormat, setSelectedFormat] = useState({})
  const [toolbarForm, setToolbarForm] = useState<ToolbarForm | null>(null)
  const [prompt, setPrompt] = useState<AiTextPromptTogglesEnum | null>(null)
  const style = useFontToVars(styles)
  const decorate = useSelectionDecorate(editor, active)

  const updateFormat = useCallback(
    (format: SlateFormats, value: string | number | boolean) => {
      if (format === SlateElementType.link) {
        wrapLink(editor, value as string)
      } else if (format === SlateElementType.crossLink) {
        wrapCrossLink(editor, value)
      } else if (format === SlateElementType.code) {
        wrapCode(editor, value as boolean)
      } else if (format === SlateElementType.annotation) {
        wrapAnnotation(editor, value as string)
      } else if (format === SlateCommand.clear) {
        clearAllMarks(editor)
      } else if (isMarkFormat(format)) {
        setMark(editor, format, value)
      } else if (isElementFormat(format)) {
        toggleElement(editor, format)
      } else if (isCommandFormat(format)) {
        inertSymbol(editor, format)
      } else if (isElementMarkFormat(format)) {
        updateElementMark(editor, format, value)
      }
    },
    [editor],
  )

  // You can't separate onSelectionChange and onValueChange
  // because when you select text and then write it with the replacement,
  // onValueChange doesn't always work. (slate lib problem)
  const handleChange = useCallback(
    (changeValue: Descendant[]) => {
      setSelectedFormat(getCurrentFormat(editor, styles))

      if (changeValue !== value) {
        onChange?.(changeValue)
        const valid = validateSlate(changeValue)
        if (!valid) {
          console.error(changeValue)
        }
      }
    },
    [onChange, editor, styles, value],
  )

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      // Default left/right behavior is unit:'character'.
      // This fails to distinguish between two cursor positions, such as
      // <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
      // Here we modify the behavior to unit:'offset'.
      // This lets the user step into and out of the inline without stepping over characters.
      // You may wish to customize this further to only use unit:'offset' in specific cases.
      if (editor.selection && Range.isCollapsed(editor.selection)) {
        const { nativeEvent } = event
        if (isCodeHotkey('left', nativeEvent)) {
          event.preventDefault()
          Transforms.move(editor, { unit: 'offset', reverse: true })
          return
        }
        if (isCodeHotkey('right', nativeEvent)) {
          event.preventDefault()
          Transforms.move(editor, { unit: 'offset' })
          return
        }
      }

      if (iterable) {
        if (isCodeHotkey('tab', event.nativeEvent)) {
          return event.preventDefault()
        }

        if (isCodeHotkey('up', event.nativeEvent)) {
          const start = Editor.start(editor, [])
          const isCursorAtStart =
            editor.selection && Point.compare(start, editor.selection.focus) === 0
          if (isCursorAtStart) {
            event.preventDefault()
            return onUp?.()
          }
        }
        if (isCodeHotkey('down', event.nativeEvent)) {
          const end = Editor.end(editor, [])
          const isCursorAtEnd = editor.selection && Point.compare(end, editor.selection.focus) === 0
          if (isCursorAtEnd) {
            event.preventDefault()
            return onDown?.()
          }
        }
        if (isCodeHotkey('backspace', event.nativeEvent) && Node.string(editor) === '') {
          event.preventDefault()
          return onChange?.(null)
        }
      }

      if (isCodeHotkey('shift+enter', event.nativeEvent)) {
        event.preventDefault()
        insertSymbolByCode(editor, SYMBOLS_CODES.lineBreak)
        return
      }

      if (isCodeHotkey('enter', event.nativeEvent)) {
        event.preventDefault()
        insertBreak(editor)
        return
      }

      const hotkey = HOT_KEYS_CONFIG.find(({ hotkey }) => isCodeHotkey(hotkey, event.nativeEvent))
      if (hotkey) {
        const { name, value } = hotkey

        event.stopPropagation()
        event.preventDefault()

        if (name === SlateElementType.link) {
          setToolbarForm('link')
        } else {
          const isActive = isFormatActive(editor, name)
          if (isActive) {
            updateFormat(name as SlateFormats, false)
          } else {
            updateFormat(name as SlateFormats, value)
          }
        }
      }
    },
    [editor, iterable, onChange, onDown, onUp, updateFormat],
  )

  const handleCopy = useCallback(
    (event: React.ClipboardEvent<HTMLDivElement>) => {
      const fragment = editor.selection && Editor.fragment(editor, editor.selection)
      const serializedFragment = JSON.stringify(fragment)
      event.clipboardData.setData(SLATE_CLIPBOARD_KEY, serializedFragment)
    },
    [editor],
  )

  const handlePaste = useCallback(
    (event: React.ClipboardEvent<HTMLDivElement>) => {
      event.preventDefault()
      const clipboardData = event.clipboardData.getData(SLATE_CLIPBOARD_KEY)
      if (clipboardData) {
        const parsedFragment = JSON.parse(clipboardData)
        Transforms.insertFragment(editor, parsedFragment)
      } else {
        const type = getCurrentElementTypes(editor)
        const html = event.clipboardData.getData('text/html')
        let nodes = null
        if (html) {
          try {
            nodes = htmlToSlatePaste(html, type)
          } catch (e) {
            console.error('error in parse', e)
          }
        }

        if (nodes) {
          Transforms.insertFragment(editor, htmlToSlatePaste(html, type))
        } else {
          const text = event.clipboardData.getData('text/plain')
          Transforms.insertText(editor, text)
        }
      }
    },
    [editor],
  )

  const handleError = useCallback(() => {
    editor.selection = null
    forceRender()
  }, [editor, forceRender])

  const contextValue = useMemo(
    () => ({
      updateFormat,
      setToolbarForm,
      setPrompt,
      selectedFormat,
      toolbarForm,
      prompt,
      styles,
    }),
    [updateFormat, prompt, selectedFormat, toolbarForm, styles],
  )

  return (
    <RichTextErrorBoundary onError={handleError}>
      <RichTextContext.Provider value={contextValue}>
        <Slate editor={editor} initialValue={value || EMPTY_ARRAY} onChange={handleChange}>
          <Editable
            {...testProps({ el: 'rich-text', name })}
            className={cn(s.root, !waiting && s.editable)}
            decorate={decorate}
            onCopy={handleCopy}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            placeholder={t('elements.richText.placeholder')}
            readOnly={waiting}
            renderElement={(p) => <ElementResolver isEdit {...p} />}
            renderLeaf={(p) => <Text {...p} />}
            style={style}
          />
          {active && <BubbleToolbar />}
        </Slate>
      </RichTextContext.Provider>
    </RichTextErrorBoundary>
  )
})

RichTextCore.displayName = 'RichTextCore'

export default React.memo(RichTextCore)
