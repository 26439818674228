import { useEffect, useMemo, useRef, useState } from 'react'

export function createAction<T extends string>(type: T) {
  return <P>() =>
    (...[payload]: P extends null ? [] : [P]) =>
      ({ type, payload }) as P extends null ? { type: T } : { type: T; payload: P }
}

export type MiddlewareAPI<A, B> = { getState: () => A; dispatch: Dispatch<B> }
export type Middleware<A, B> = (api: MiddlewareAPI<A, B>) => (next: Dispatch<B>) => Dispatch<B>
export type Dispatch<B> = (action: B) => void

export const useReducerWithMiddleware = <S extends object, A, D = object>(
  reducer: (state: S & D, action: A) => S,
  initialState: S,
  middlewares: Middleware<S & D, A>[] = [],
  data: D = {} as D,
): [S, Dispatch<A>] => {
  const [state, setState] = useState(initialState)

  const stateRef = useRef(state)
  const dataRef = useRef(data)

  const dispatch = useMemo(() => {
    let dispatch: Dispatch<A> = () => {
      throw new Error(
        `Dispatching while constructing your middleware is not allowed. ` +
          `Other middleware would not be applied to this dispatch.`,
      )
    }

    const middlewareAPI = {
      getState: () => Object.assign(stateRef.current, dataRef.current),
      dispatch: (action: A) => dispatch(action),
    }

    const localDispatch = (action: A) => {
      stateRef.current = reducer(Object.assign(stateRef.current, dataRef.current), action)
      setState(stateRef.current)
    }

    dispatch = middlewares
      .map((middleware) => middleware(middlewareAPI))
      .reduceRight((acc, middleware) => middleware(acc), localDispatch)

    return dispatch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dataRef.current = data
  }, [data])

  return [state, dispatch]
}
