import { EditorElement, ElementValue } from '@vedalib/editor/lib/elements'
import React, { useCallback } from 'react'

import { ELEMENTS_CONFIG } from 'components/editor-v2/EditorElements/elements.config'
import { FCEditorActions } from 'components/editor-v2/EditorElements/elements.types'
import AbsolutePortal from 'components/editor-v3/components/AbsolutePortal'
import { ABSOLUTE_PORTAL_GAP } from 'components/editor-v3/components/AbsolutePortal/constants'
import { setElement } from 'services/Store/Project/actions'
import { AppMode } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getElementState } from 'services/Store/Project/selectors'
import { Block, ElementState, IBlockMode } from 'services/Store/Project/types'

import ControlsGroup from '../ControlsGroup/ControlsGroup'

interface IElementControlsProps {
  element: EditorElement
  active: boolean
  setAppMode: (value: AppMode) => void
  customMode: IBlockMode | null
  mode: IBlockMode
  block: Block
  onChange: (value: ElementValue) => void
}

const ElementControls: React.FC<IElementControlsProps> = ({
  element,
  active,
  setAppMode,
  block,
  customMode,
  mode,
  onChange,
}) => {
  const dispatch = useProjectDispatch()
  const elementState = useProjectContext(getElementState, element.id)

  const handleSetState = useCallback(
    (value: ElementState) => dispatch(setElement({ id: element.id, value })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element.id],
  )

  const Actions = ELEMENTS_CONFIG[element.type]?.EditorActions as FCEditorActions | null
  const actionsContent = Actions ? (
    <Actions
      block={block}
      customMode={customMode}
      element={element}
      mode={mode}
      onChange={onChange}
      setAppMode={setAppMode}
      setState={handleSetState}
      state={elementState}
    />
  ) : null

  if (!(active && Actions)) {
    return null
  }

  return (
    <AbsolutePortal
      name='elementControls'
      placement='bottomLeft'
      translateY={`${ABSOLUTE_PORTAL_GAP}px`}
    >
      {active && <ControlsGroup>{actionsContent}</ControlsGroup>}
    </AbsolutePortal>
  )
}

export default React.memo(ElementControls)
