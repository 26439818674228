// extracted by mini-css-extract-plugin
var _1 = "PointTooltip_arrows__cwnuF";
var _2 = "PointTooltip_content__n2k9B";
var _3 = "PointTooltip_description__1pvmS";
var _4 = "PointTooltip_disabled__pZVwp";
var _5 = "PointTooltip_footer__J6aUZ";
var _6 = "PointTooltip_image__QqS-i";
var _7 = "PointTooltip_imageWrapper__-ZmbZ";
var _8 = "PointTooltip_inner__G3QRO";
var _9 = "PointTooltip_mobile__8kLY2";
var _a = "PointTooltip_navigation__vRMRH";
var _b = "PointTooltip_overlay__MyI2Q";
var _c = "PointTooltip_root__ksaHk";
var _d = "PointTooltip_title__jP-DB";
export { _1 as "arrows", _2 as "content", _3 as "description", _4 as "disabled", _5 as "footer", _6 as "image", _7 as "imageWrapper", _8 as "inner", _9 as "mobile", _a as "navigation", _b as "overlay", _c as "root", _d as "title" }
