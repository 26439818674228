import cn from 'classnames'
import moment from 'moment'
import React from 'react'

import { CommentMock } from 'components/comments/types'
import { KitSize } from 'components/uiKit/KitTypes'
import { Typography } from 'components/uiKit/Typography'

import * as s from './NameRow.module.scss'

export const format = 'DD MMM HH:mm'

interface INameRowProps {
  comment: CommentMock
}

const NameRow: React.FC<INameRowProps> = ({ comment }) => {
  const { createdBy, createdAt, isRead } = comment

  return (
    <div className={s.root}>
      <Typography size={KitSize.XS} styleType={isRead ? 'hint' : 'base'} weight='medium'>
        {createdBy?.kUser.name}
      </Typography>
      <div className={cn(s.dot, { [s.read]: isRead })} />
      <Typography size={KitSize.XS} styleType={isRead ? 'hint' : 'base'} weight='medium'>
        {moment(createdAt).format(format)}
      </Typography>
    </div>
  )
}

export default NameRow
