import { useLocalStorageState } from 'ahooks'
import { SetState } from 'ahooks/lib/createUseStorageState'
import { gqlClient } from 'gql'
import lodash from 'lodash'
import * as R from 'ramda'
import { useCallback, useMemo, useState } from 'react'
import { createContext } from 'use-context-selector'

import { SortUiEnum } from 'components/uiKit/SortPopover/types'
import { EMPTY_ARRAY, EMPTY_OBJECT, NOOP } from 'constants/commonConstans'
import {
  TemplateCollectionCreatedFor,
  EditorTemplateAllTab,
  EmployeePreferencesTab,
  TemplatesListSchemaFragment,
  EditorTemplateOrder,
  RecentlyUsedTemplatesType,
  TemplateType,
} from 'gql/__generated__/graphql'
import { useTemplatesAll } from 'gql/templates/apollo'
import { templatesGetAllIdsQuery } from 'gql/templates/gql/mutations'
import { BlockMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getBlock } from 'services/Store/Project/selectors'
import { isCtrl } from 'utils/events'

import { COUNT_LIMIT } from './helper'
import { TemplatePickerMode, templateTagToBlockMode } from './types'

export const useTags = ({
  sectionType,
  propTag,
  tagFromLocalStorage,
}: {
  sectionType?: SectionTypeEnum
  propTag?: TemplateType
  tagFromLocalStorage?: boolean
}) => {
  const block = useProjectContext(getBlock)
  const landingSection = sectionType === SectionTypeEnum.landing

  const defaultTag = useMemo(() => {
    if (
      !landingSection &&
      (block?.mode === BlockMode.start ||
        block?.mode === BlockMode.questions ||
        block?.mode === BlockMode.end)
    ) {
      return block.mode as unknown as TemplateType
    }

    if (!sectionType) {
      return TemplateType.ALL
    }
    return TemplateType.ALL
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block?.mode])

  const initialTag = useMemo(() => propTag || defaultTag, [propTag, defaultTag])

  const [tag, setTag] = useState<TemplateType>(initialTag || TemplateType.ALL)

  const [tagsCollection, setTagsCollection] = useLocalStorageState<TemplateType>(`tags-templates`, {
    defaultValue: TemplateType.ALL,
  })

  const showTags = landingSection || !sectionType

  return {
    tag: tagFromLocalStorage
      ? tagsCollection || TemplateType.ALL
      : landingSection || !sectionType
        ? tag
        : initialTag,
    setTag: (tagFromLocalStorage ? setTagsCollection : setTag) as React.Dispatch<
      React.SetStateAction<TemplateType>
    >,
    showTags,
  }
}

export const useSelected = () => {
  const [selected, setSelected] = useState<{
    [BlockMode.cover]: string | null
    [BlockMode.start]: string | null
    [BlockMode.end]: string | null
    multi: string[]
  }>({
    multi: [],
    [BlockMode.cover]: null,
    [BlockMode.start]: null,
    [BlockMode.end]: null,
  })

  const removeByIndex = useCallback((value: number, e: React.MouseEvent) => {
    if (!isCtrl(e)) {
      setSelected((prev) => ({ ...prev, multi: prev.multi.filter((_str, i) => i !== value - 1) }))
    }
  }, [])

  const addItem = useCallback(
    (item: TemplatesListSchemaFragment, e: React.MouseEvent) => {
      const lastIndex = selected.multi.map((uuid) => uuid === item.uuid).lastIndexOf(true)
      if (lastIndex >= 0 && !isCtrl(e)) {
        removeByIndex(lastIndex + 1, e)
        return
      }
      if ((lastIndex < 0 && !isCtrl(e)) || isCtrl(e) || !selected.multi.length) {
        setSelected((prev) => {
          return { ...prev, multi: [...prev.multi, item.uuid] }
        })
      }
    },
    [selected.multi, removeByIndex],
  )

  const clearSelect = useCallback(() => {
    setSelected((prev) => ({ ...prev, multi: [] }))
  }, [])

  return { selected, setSelected, addItem, removeByIndex, clearSelect }
}

export const getQueryVariables = (
  tag?: TemplateType,
  type?: SectionTypeEnum,
  tab?: EmployeePreferencesTab | EditorTemplateAllTab,
): { templateType?: TemplateType; mode?: BlockMode[] } => {
  if (tab === EmployeePreferencesTab.favorites || tab === EmployeePreferencesTab.recently_used) {
    if (!type) {
      return { mode: [], templateType: undefined }
    }

    if (type === SectionTypeEnum.cover) {
      return { templateType: TemplateType.COVER }
    }

    if (type === SectionTypeEnum.test && !tag) {
      return { templateType: TemplateType.QUESTIONS, mode: [BlockMode.questions] }
    }

    if (type === SectionTypeEnum.test && tag) {
      const mode = templateTagToBlockMode(tag)
      if (!mode) {
        console.error('Unknown tag', tag)
        return { templateType: tag }
      }
      return { templateType: tag, mode: [mode] }
    }

    if (type === SectionTypeEnum.landing) {
      const mode: BlockMode[] = [BlockMode.view, BlockMode.questions]
      return { mode, templateType: undefined }
    }
  }

  if (!type) {
    if (tag === TemplateType.ALL) {
      return { templateType: undefined }
    }
    if (tag === TemplateType.COVER) {
      return { mode: [BlockMode.cover] }
    }
  }

  if (type === SectionTypeEnum.cover) {
    return { mode: [BlockMode.cover] }
  }

  if (type === SectionTypeEnum.test && tag) {
    const mode = templateTagToBlockMode(tag)
    if (!mode) {
      console.error('Unknown tag', tag)
      return { templateType: tag }
    }
    return { mode: [mode] }
  }

  if (type === SectionTypeEnum.landing) {
    const mode: BlockMode[] = [BlockMode.view, BlockMode.questions]
    if (tag === TemplateType.ALL) {
      return { mode, templateType: undefined }
    }
    return { mode, templateType: tag }
  }

  return {
    mode: [],
    templateType: tag,
  }
}

const sectionTypeToRecentlyUsedTemplatesType = (type?: SectionTypeEnum) => {
  switch (type) {
    case SectionTypeEnum.test:
      return RecentlyUsedTemplatesType.test
    case SectionTypeEnum.landing:
      return RecentlyUsedTemplatesType.landing
  }
  return null
}

type HookParams = {
  type?: SectionTypeEnum
  tag?: TemplateType
  search?: string
  editorTemplateGroupId?: string
  companyId: string
  projectId?: string
  tab?: EmployeePreferencesTab
  sectionId?: string
  order?: EditorTemplateOrder | null
  limit?: number
  offset?: number
}

const paramsToVariables = ({
  type,
  tag,
  search,
  editorTemplateGroupId,
  companyId,
  projectId,
  sectionId,
  tab = EmployeePreferencesTab.collections,
  order,
  limit = COUNT_LIMIT,
  offset = 0,
}: HookParams) => {
  return {
    companyId,
    data: {
      projectId: projectId || undefined,
      ...getQueryVariables(tag, type, tab),
      editorTemplateGroupId:
        tab === EmployeePreferencesTab.collections ? editorTemplateGroupId : undefined,
      query: search,
      tab: tab as unknown as EditorTemplateAllTab,
      sectionId,
      type: sectionTypeToRecentlyUsedTemplatesType(type),
      order,
      pagination: {
        limit,
        offset,
      },
    },
  }
}

export const useLoadAllIds = (params: HookParams) => {
  return async () =>
    gqlClient.core.query({
      query: templatesGetAllIdsQuery,
      variables: lodash.omit(paramsToVariables(params), 'pagination'),
    })
}

export const useTemplates = (params: HookParams) => {
  const variables = useMemo(() => paramsToVariables(params), [params])

  const { data: dataTemplates, loading, fetchMore } = useTemplatesAll(variables)

  const needFetchMore =
    !loading &&
    (dataTemplates?.data.pagination.total || 0) > (dataTemplates?.data.templates.length || 0)

  const fetchMoreTemplates = useCallback(async () => {
    await fetchMore({
      variables: R.mergeDeepRight(variables, {
        data: { pagination: { offset: dataTemplates?.data.templates.length } },
      }),
    })
  }, [fetchMore, variables, dataTemplates?.data.templates.length])

  return {
    loading,
    templates: dataTemplates?.data.templates || EMPTY_ARRAY,
    fetchMore: needFetchMore ? fetchMoreTemplates : undefined,
  }
}

export interface ITemplatePickerContext {
  collectionId?: string
  companyId: string
  mode: TemplatePickerMode
  onClick: (value: TemplatesListSchemaFragment, e: React.MouseEvent) => void
  projectId: string
  removeByIndex: (value: number, e: React.MouseEvent) => void
  search?: string
  setSearch: React.Dispatch<React.SetStateAction<string | undefined>>
  selected: {
    multi: string[]
    [BlockMode.cover]: string | null
    [BlockMode.start]: string | null
    [BlockMode.end]: string | null
  }
  setCollection: React.Dispatch<
    React.SetStateAction<{
      id: string
      createdFor?: TemplateCollectionCreatedFor | undefined
      name?: string | undefined
    } | null>
  >
  setTemplates: React.Dispatch<React.SetStateAction<TemplatesListSchemaFragment[]>>
  step: number | null
  type?: SectionTypeEnum
  sectionId?: string
  setStep: React.Dispatch<React.SetStateAction<number | null>>
  allSelected: string[]
  blocksByMode?: Record<string, boolean>
  tag: TemplateType
  setTag: React.Dispatch<React.SetStateAction<TemplateType>>
  showTags: boolean
  columns?: number
  setColumns: (value?: number | SetState<number> | undefined) => void
  tab: EmployeePreferencesTab
  order: SortUiEnum | null
  setOrder: React.Dispatch<React.SetStateAction<SortUiEnum | null>>
  showBrand?: boolean
  collection: {
    id: string
    createdFor?: TemplateCollectionCreatedFor | undefined
    name?: string | undefined
  } | null
}

export const DEFAULT_CONTEXT_VALUE = {
  collectionId: '',
  companyId: '',
  mode: TemplatePickerMode.multi,
  onClick: NOOP,
  projectId: '',
  removeByIndex: NOOP,
  search: '',
  setSearch: NOOP,
  selected: {
    multi: EMPTY_ARRAY,
    [BlockMode.cover]: null,
    [BlockMode.start]: null,
    [BlockMode.end]: null,
  },
  setCollection: NOOP,
  setTemplates: NOOP,
  step: null,
  type: undefined,
  sectionId: '',
  setStep: NOOP,
  allSelected: EMPTY_ARRAY,
  blocksByMode: EMPTY_OBJECT,
  tag: TemplateType.ALL,
  setTag: NOOP,
  showTags: false,
  columns: 3,
  setColumns: NOOP,
  tab: EmployeePreferencesTab.collections,
  order: null,
  setOrder: NOOP,
  showBrand: false,
  collection: null,
}

export const TemplatePickerContext = createContext<ITemplatePickerContext>(DEFAULT_CONTEXT_VALUE)
