import { EmployeeListSchemaFragment, Locale } from 'gql/__generated__/graphql'
import { t } from 'services/Translation'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EMPTY_OBJECT = Object.freeze({}) as any
export const EMPTY_ARRAY = []
export const NOOP = () => null
export const VOID = () => undefined

export const getOptionsAuthorSelect = (employees: EmployeeListSchemaFragment[]) => {
  return employees.map(({ id, kUser }) => ({ value: id, label: kUser.name }))
}

export const IS_MAC = navigator.platform.toUpperCase().indexOf('MAC') >= 0

export const preventDefault = (e: React.SyntheticEvent | Event) => e.preventDefault()

export const stopPropagation = (e: React.SyntheticEvent | Event) => e.stopPropagation()

export const LANGUAGE_MAP = {
  [Locale.RU]: t('input.option.ru'),
  [Locale.EN]: t('input.option.en'),
}
