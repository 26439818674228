import { CONFIG_ELEMENTS } from '@vedalib/editor/lib/elements'

import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './TestKeyboardInputBrandFormSchema'
import Demo from './TestKeyboardInputDemo'
import Element from './TestKeyboardInputElement'
import Form from './TestKeyboardInputForm'
import keyboardInputIcon from './assets/testKeyboardInput.svg'

export default genElementConfig(CONFIG_ELEMENTS.testKeyboardInput, {
  label: t('elements.testKeyboardInput.label'),
  icon: keyboardInputIcon,
  group: ElementGroup.test,
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  Demo,
})
