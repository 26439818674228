import { DeviceMode } from 'services/Store/Project/enums'

import { NodeType } from './date.enums'

export const MIN_WIDTH_LIMIT = 0
export const DEFAULT_CANVAS_WIDTH = 960

export const DEVICE_DESK_ORDER: DeviceMode[] = [
  DeviceMode.desktop,
  DeviceMode.tablet,
  DeviceMode.mobile,
]

export const DEVICE_ASK_ORDER: DeviceMode[] = [
  DeviceMode.mobile,
  DeviceMode.tablet,
  DeviceMode.desktop,
]

export const SCREENS = {
  [DeviceMode.desktop]: DEFAULT_CANVAS_WIDTH,
  [DeviceMode.tablet]: 760,
  [DeviceMode.mobile]: 410,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DEFAULT_NODE: { [key in NodeType]: { [key: string]: any } } = {
  [NodeType.row]: {
    style: {
      flexGrow: 0,
    },
  },
  [NodeType.column]: {
    style: {
      flexBasis: 0,
      display: 'flex',
    },
  },
  [NodeType.shell]: {
    grow: false,
    widthType: 'auto',
    heightType: 'auto',
  },
  [NodeType.absolute]: {
    style: {
      zIndex: 0,
    },
  },
}

export const ELEMENTS_WITH_CHILDREN = ['tabs', 'accordion', 'slider', 'layoutList']

export const SHELL_SIZE_KEYS = ['maxWidth', 'maxHeight', 'minWidth', 'minHeight', 'width', 'height']
