import { useInViewport, useUpdateEffect } from 'ahooks'
import React, { useRef, useMemo } from 'react'

import LayoutScroll, { ScrollRef } from 'components/LayoutPage/LayoutScroll/LayoutScroll'
import Button from 'components/uiKit/Button'
import { t } from 'services/Translation'

import * as s from './InfiniteLoadGrid.module.scss'

interface IInfiniteLoadGridProps {
  name: string
  hasMore?: boolean
  loading: boolean
  chunkLoading?: boolean
  fetchMore?: () => Promise<unknown>
  children: React.ReactNode
  padding?: string
  grid?: {
    width?: string
    columnGap?: string
    rowGap?: string
  }
}

const InfiniteLoadGrid = React.forwardRef<ScrollRef, IInfiniteLoadGridProps>(
  ({ grid, name, padding, chunkLoading, hasMore, loading, children, fetchMore }, ref) => {
    const loadMoreRef = useRef<HTMLDivElement>(null)
    const [inViewport] = useInViewport(loadMoreRef)

    const gridVariables = useMemo(
      () =>
        ({
          padding,
          '--infiniteColumnWidth': grid?.width,
          '--infiniteColumnGap': grid?.columnGap,
          '--infiniteRowGap': grid?.rowGap,
        }) as React.CSSProperties,
      [grid, padding],
    )

    useUpdateEffect(() => {
      if (inViewport && hasMore && fetchMore && !loading) {
        fetchMore()
      }
    }, [inViewport, hasMore, loading])

    return (
      <LayoutScroll ref={ref} sizeAutoCapable>
        <div className={s.root} style={gridVariables}>
          {children}
        </div>
        {hasMore && fetchMore && (
          <div className={s.loadingItem} ref={loadMoreRef}>
            <Button
              loading={chunkLoading || loading}
              name={`${name}.infiniteScrollButtonLoadMore`}
              onClick={fetchMore}
            >
              {t('uiKit.infiniteScroll.loadMore')}
            </Button>
          </div>
        )}
      </LayoutScroll>
    )
  },
)

InfiniteLoadGrid.displayName = 'InfiniteLoadGrid'

export default InfiniteLoadGrid
