import { SCHEMA } from '@vedalib/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.chart.style, {
  chart: {
    META: { hidden: true },
    backgroundColor: { label: t('elements.chart.schema.chartColor') },
    color: { label: t('elements.chart.schema.gridColor') },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.chart.font, {
  base: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.chart.tags.fontMain') },
  },
  caption: {
    ...FONT_SCHEMA_FIELDS.caption,
    META: { label: t('elements.chart.tags.fontDescription') },
  },
})
