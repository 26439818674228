import cn from 'classnames'
import React from 'react'

import { NodeType } from 'components/editor-v3/types/date.enums'
import { scrollProps } from 'services/Scroll/ScrollService'
import { AppMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getIsActiveNode } from 'services/Store/Project/selectors'

import { NodeProps } from '../../Node/types'
import RowControls from '../../controls/RowControls'
import * as s from './EditorRow.module.scss'

const EditorRow = React.forwardRef<HTMLDivElement, NodeProps<NodeType.row>>(
  ({ children, node, block, style, mode }, ref) => {
    const isActive = useProjectContext(getIsActiveNode, node.id)
    const isPro = mode.editorMode === AppMode.pro
    return (
      <div
        {...scrollProps(node.id)}
        className={cn(s.row, {
          [s.fillMode]: mode.editorMode === AppMode.fill,
        })}
        ref={ref}
        style={style}
      >
        {children}
        {isPro && <RowControls block={block} isActive={isActive} nodeId={node.id} />}
      </div>
    )
  },
)

EditorRow.displayName = 'EditorRow'

export default React.memo(EditorRow)
