import { RichTextValue } from '@vedalib/rich-text'
import { useEffect, useReducer } from 'react'
import { Descendant } from 'slate'

import { CustomEditor } from './slate'

export const useExternalValue = (
  editor: CustomEditor,
  value?: RichTextValue,
  defaultValue?: RichTextValue,
) => {
  const forceRender = useReducer((s) => !s, false)[1]

  useEffect(() => {
    editor.children = (value || defaultValue) as Descendant[]
    if (isEmptyRichText(value)) {
      editor.selection = { anchor: { path: [0, 0], offset: 0 }, focus: { path: [0, 0], offset: 0 } }
    }

    forceRender()
  }, [forceRender, editor, value, defaultValue])
}

const isEmptyRichText = (v?: RichTextValue) =>
  // @ts-expect-error rude check
  v?.length === 1 && v?.[0]?.children?.length === 1 && v?.[0]?.children?.[0]?.text === ''
