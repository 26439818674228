import { useCallback } from 'react'

import { useBlocksDeleteByIds } from 'gql/blocks/apollo'
import { ScrollContainerEnum } from 'services/Scroll/enums'
import { setProjectNavigation } from 'services/Store/Project/actions'
import { useProjectDispatch } from 'services/Store/Project/hooks'
import {
  getSection,
  getSelectedBlocksIds,
} from 'services/Store/Project/selectors'
import store from 'services/Store/store'

export const useDeleteBlock = () => {
  const dispatch = useProjectDispatch()
  const [deleteBlock] = useBlocksDeleteByIds()

  const remove = useCallback(async () => {
    const projectState = store.getState().project
    const selectedBlocks = getSelectedBlocksIds(projectState)
    const section = getSection(projectState)
    const blocksOrder = section?.blocksOrder || []
    let index = -1
    const newBlocksOrder = blocksOrder.filter((block, i) => {
      if (selectedBlocks.includes(block)) {
        index = i
        return false
      }
      return true
    })

    let activeUUID: undefined | string = undefined
    if (index !== -1) {
      if (index === blocksOrder.length - 1) {
        activeUUID = newBlocksOrder[index - selectedBlocks.length]
      } else {
        activeUUID = newBlocksOrder[index - selectedBlocks.length + 1]
      }
    }

    dispatch(
      setProjectNavigation({
        blockId: activeUUID,
        nodeId: null,
        scroll: [
          {
            container: ScrollContainerEnum.canvas,
            id: activeUUID,
            block: 'begin',
            scroll: ['never', 'always'],
          },
          {
            container: ScrollContainerEnum.blocksNav,
            id: activeUUID,
            block: 'begin',
            scroll: 'always',
          },
        ],
      }),
    )
    await deleteBlock({
      variables: {
        uuid: selectedBlocks,
      },
      optimisticResponse: {
        editorBlocksDeleteByIds: {
          __typename: 'BlocksRemoveResult',
          blocksOrder: newBlocksOrder,
          uuid: selectedBlocks,
        },
      },
    })
  }, [deleteBlock, dispatch])
  return remove
}
