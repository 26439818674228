import { isThemeVariable, SchemaKeys } from '@vedalib/editor/lib/brand'
import { StyleValue } from '@vedalib/editor/lib/elements'
import { DeepPartial } from '@vedalib/editor/lib/utils/types'
import lodash from 'lodash'
import * as R from 'ramda'

import { DEVICE_ASK_ORDER, DEVICE_DESK_ORDER } from 'components/editor-v3/types/data.constants'
import { DeviceMode } from 'services/Store/Project/enums'
import { getOriginalImageById } from 'utils/files'
import { validateFontFamilyCss } from 'utils/font'

import { validateBoxShadowCss } from './BrandCustomShadowField/validateBoxShadowCss'
import { BrandType, ElementStyleValue } from './types'

export const getBrandFontValues = ({ font }: BrandType) => {
  const base = R.mapObjIndexed(R.prop('base'), font)
  const withBase = R.mapObjIndexed(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    (obj, key) => R.mapObjIndexed((font) => merge(base[key], font), obj),
    font,
  )

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  return merge(font, withBase)
}

export const getBrandElementFontValues = <S extends SchemaKeys>(brand: BrandType, type: S) => {
  const font = brand.font
  const fontSchema = brand.schema[type].font

  const brandFont = merge(font, fontSchema as typeof font)
  const base = R.mapObjIndexed(R.prop('base'), brandFont)

  const final = brandFont
  const withBase = R.mapObjIndexed(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    (obj, key) => R.mapObjIndexed((font) => merge(base[key], font), obj),
    final,
  )
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const a = R.mapObjIndexed(R.pick(R.keys(fontSchema.desktop)), merge(final, withBase))
  return a
}

const IMG_KEYWORDS = ['icon', 'backgroundImage', 'disabledImage', 'backgroundOpen']
const keywordRegex = new RegExp(IMG_KEYWORDS.join('|'))

const mergeCustomize = <T>(objValue: T, srcValue: T) => {
  if (lodash.isArray(objValue) || lodash.isArray(srcValue)) {
    return srcValue
  }

  return
}

export const mergeWithDeviceMode = <T>(
  obj: { [key in DeviceMode]?: DeepPartial<T> },
  deviceMode: DeviceMode,
  initialData = {},
) => {
  const index = DEVICE_DESK_ORDER.indexOf(deviceMode)
  const matchDevices = DEVICE_DESK_ORDER.slice(0, index + 1)
  return matchDevices.reduce<T>(
    (acc, deviceMode) => lodash.mergeWith(acc, obj[deviceMode], mergeCustomize),
    lodash.cloneDeep(initialData) as T,
  )
}

export const getParentDeviceMode = (deviceMode: DeviceMode): DeviceMode | undefined => {
  const parentIndex = DEVICE_ASK_ORDER.indexOf(deviceMode)
  const parentDeviceMode = DEVICE_ASK_ORDER[parentIndex + 1]
  return parentDeviceMode
}
export const unwrapStyleSchema = R.mapObjIndexed(R.mapObjIndexed(R.mapObjIndexed(R.prop('value'))))

export const merge = <T>(l: T, r: DeepPartial<T>): T =>
  R.is(Object, l) && R.is(Object, r)
    ? R.mergeDeepWith(merge, l, r)
    : ((r !== undefined ? r : l) as T)

export const replaceImageIdToPath = (brandStyle: ElementStyleValue) => {
  for (const tagName in brandStyle) {
    const tagValue = brandStyle[tagName]
    for (const styleName in tagValue) {
      if (keywordRegex.test(styleName)) {
        const img = getOriginalImageById(String(tagValue[styleName]))
        if (img?.path) {
          tagValue[styleName] = `url(${img.path})`
        }
      }
    }
  }
}

export const collectCssVars = (brandStyle: ElementStyleValue) =>
  Object.entries(lodash.pickBy(brandStyle, (_value, key) => key.includes('__var'))).reduce<
    Record<string, string | number | null>
  >((acc, [tag, tagSchema]) => {
    Object.entries(tagSchema || {}).forEach(([key, value]) => {
      if (key !== 'META') {
        acc[`--${tag.replace('__var', '')}-${key}`] = String(validateCss(value as StyleValue, key))
      }
    })
    return acc
  }, {})

export const validateCss = (style: StyleValue, key: string) => {
  if (style === null || style === undefined || isThemeVariable(style)) {
    return style
  }

  switch (key) {
    case 'padding':
    case 'margin': {
      return Array.isArray(style)
        ? style.map((val) => `${parseInt(String(val || 0))}px`).join(' ')
        : String(style)
    }
    case 'width':
    case 'gap':
    case 'height':
    case 'paddingTop':
    case 'paddingLeft':
    case 'paddingRight':
    case 'paddingBottom':
    case 'marginTop':
    case 'marginLeft':
    case 'marginRight':
    case 'marginBottom':
    case 'borderWidth':
    case 'borderTopWidth':
    case 'borderRadius':
    case 'spacing':
    case 'fontSize': {
      return Array.isArray(style)
        ? style.map((val) => `${parseInt(String(val || 0))}px`).join(' ')
        : `${parseInt(String(style))}px`
    }
    case 'fontFamily': {
      return validateFontFamilyCss(style)
    }
    case 'boxShadow': {
      return validateBoxShadowCss(style)
    }
  }

  return style
}
