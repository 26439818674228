// extracted by mini-css-extract-plugin
var _1 = "SelectRootItem_base__A5uZa";
var _2 = "SelectRootItem_disabled__VDOfV";
var _3 = "SelectRootItem_error__Snb+4";
var _4 = "SelectRootItem_label__ScBLc";
var _5 = "SelectRootItem_large__xWXWI";
var _6 = "SelectRootItem_medium__00EFI";
var _7 = "SelectRootItem_mini__2Fc5c";
var _8 = "SelectRootItem_single__9pgg4";
var _9 = "SelectRootItem_tag__8e1aH";
var _a = "SelectRootItem_xmini__JAEpp";
export { _1 as "base", _2 as "disabled", _3 as "error", _4 as "label", _5 as "large", _6 as "medium", _7 as "mini", _8 as "single", _9 as "tag", _a as "xmini" }
