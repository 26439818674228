import * as R from 'ramda'
import { Dispatch, SetStateAction, useCallback } from 'react'

import Card from 'components/uiKit/Card'
import Checkbox from 'components/uiKit/Checkbox'
import { KitSize } from 'components/uiKit/KitTypes'
import { Typography } from 'components/uiKit/Typography'
import { FinderItem } from 'store/models/entry.model'
import { FileTypeEnum } from 'utils/fileTypes'
import { testProps } from 'utils/test/qaData'
import { isFinderFolder } from 'utils/useFinder'

import { FinderFileIcons } from '../../FinderFileIcons'
import { Description } from '../Description'
import * as s from './GridItemModal.module.scss'

interface IFinderItem {
  item: FinderItem
  onClick: (item: FinderItem) => void
  onChangeSelection: Dispatch<SetStateAction<FinderItem[]>>
  selectedItems: FinderItem[]
  clearQuery: () => void
}

const GridItemModal: React.FC<IFinderItem> = ({
  item,
  onClick,
  onChangeSelection,
  selectedItems,
  clearQuery,
}) => {
  const typeFile = item.type?.split('/')[0] as Exclude<FileTypeEnum, FileTypeEnum.FONT> | undefined

  const onSelectItem = useCallback(
    (val: boolean) => {
      onChangeSelection((prev: FinderItem[]) => {
        if (!val) {
          return R.filter<FinderItem, FinderItem[]>(({ id }) => id !== item.id, prev)
        } else {
          return [...prev, item]
        }
      })
    },
    [item, onChangeSelection],
  )

  const selected = !!selectedItems.find((i) => i.id === item.id)

  const handleClick = () => {
    if (selectedItems.length) {
      onChangeSelection((prev) => {
        if (prev.find((i) => i.id === item.id)) {
          return R.filter<FinderItem, FinderItem[]>(({ id }) => id !== item.id, prev)
        }

        return [...prev, item]
      })
      return
    }
    onClick(item)
    clearQuery()
    onChangeSelection([])
  }

  return (
    <div
      className={s.item}
      key={item.id}
      {...testProps({ el: 'fileItem', label: item.name, id: item.id })}
    >
      <Card name='fileModalCard' onClick={handleClick} selected={selected} size={KitSize.XS} fluid>
        <div className={s.block}>
          <div className={s.blockWrapper}>
            <FinderFileIcons item={item} typeFile={typeFile} />
          </div>
        </div>
      </Card>
      <div className={s.title}>
        <div className={s.name}>
          <Checkbox name='resource' onChange={onSelectItem} value={selected} />
          <div onClick={handleClick}>
            <Typography
              rows={1}
              styleType={isFinderFolder(item) ? 'link' : 'base'}
              tooltip={item.name}
            >
              {item.name}
            </Typography>
          </div>
        </div>
        <Description item={item} />
      </div>
    </div>
  )
}

export default GridItemModal
