import { useSize } from 'ahooks'
import React, { useEffect, useRef, useState } from 'react'

import { getRootNode } from 'components/editor-v3/context/EditorContext/selectors/block'
import { DEFAULT_CANVAS_WIDTH } from 'components/editor-v3/types/data.constants'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import ProjectPositionUsersList from 'components/users/ProjectPositionUsersList'
import { SKELETON_MODE } from 'services/Store/Project/constants'
import { BlockMode, DeviceMode } from 'services/Store/Project/enums'
import { Block } from 'services/Store/Project/types'

import SkeletonBlock from '../../layout/Block/SkeletonBlock'
import BlockTypeLabel from './BlockTypeLabel'
import * as s from './ScaledBlockViewport.module.scss'

interface IScaledBlockSkeletonProps {
  block: Block | null
  onlyCanvasById?: string
  viewportSize: number
  deviceMode: DeviceMode
  onHeight?: (height: number) => void
  mode?: BlockMode
  isWarning?: boolean
  isComments?: boolean
  sectionId?: string
}

const MIN_HEIGHT = 36

const ScaledBlockViewport: React.FC<IScaledBlockSkeletonProps> = ({
  block,
  viewportSize,
  deviceMode,
  mode,
  isWarning,
  isComments,
  onHeight,
  sectionId,
}) => {
  const scalableContainer = useRef<HTMLDivElement>(null)
  const [viewportHeight, setViewportHeight] = useState<number>(0)
  const size = useSize(scalableContainer.current)

  const root = block ? getRootNode(block) : null

  const canvasMaxWidth = Number(root?.style[deviceMode]?.maxWidth || DEFAULT_CANVAS_WIDTH)
  const scaleRate = canvasMaxWidth / viewportSize
  const canvasScaleValue = 1 / scaleRate

  useEffect(() => {
    const renderedHeight = size?.height
    const processedHeight = Math.max(
      MIN_HEIGHT,
      renderedHeight ? renderedHeight / scaleRate : viewportHeight + 1,
    )

    if (processedHeight !== +viewportHeight) {
      setViewportHeight(processedHeight)
    }

    onHeight?.(processedHeight)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size?.height, viewportSize])

  return (
    <div className={s.render} style={{ width: viewportSize, height: viewportHeight }}>
      <div className={s.additionInfo}>
        <div className={s.left}>
          {isComments && <Icon name='statusComments' />}
          {block?.isDone && <Icon name='statusLock20Px' />}
        </div>
        <div className={s.right}>
          <BlockTypeLabel mode={mode || block?.mode} />
          {isWarning && <Icon name='alertDanger' />}
        </div>
      </div>
      <div className={s.users}>
        <ProjectPositionUsersList
          filter={{ sectionId, blockId: block?.uuid }}
          maxCount={1}
          size={KitSize.S}
        />
      </div>
      <div style={{ width: canvasMaxWidth, height: viewportHeight }}>
        <div
          className={s.scalableContainer}
          ref={scalableContainer}
          style={{ transform: `scale(${canvasScaleValue})` }}
        >
          {block && <SkeletonBlock block={block} index={0} mode={SKELETON_MODE} />}
        </div>
      </div>
    </div>
  )
}

export default React.memo(ScaledBlockViewport)
