import {
  TestSingleChoiceFontSchemaType,
  TestSingleChoiceSchemaType,
} from '@vedalib/editor/lib/brand'
import { CONFIG_ELEMENTS, TestSingleChoiceElementValue } from '@vedalib/editor/lib/elements'
import { textToRtValue } from '@vedalib/rich-text'

import { DEMO_MODE } from 'services/Store/Project/constants'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './TestSingleChoiceElement/TestSingleChoiceElement'

const DEMO_VALUE: TestSingleChoiceElementValue = {
  items: [
    {
      isValid: true,
      label: textToRtValue(t('elements.testSingleChoice.value.rightAnswer')),
      points: 1,
      value: 'radio_1',
    },
    {
      isValid: false,
      label: textToRtValue(t('elements.testSingleChoice.value.wrongAnswer')),
      points: 1,
      value: 'radio_2',
    },
    {
      isValid: false,
      label: textToRtValue(t('elements.testSingleChoice.value.wrongAnswer')),
      points: 1,
      value: 'radio_3',
    },
  ],
  isRandom: true,
}

const TestSingleChoiceDemo: React.FC<
  IElementDemoProps<TestSingleChoiceSchemaType, TestSingleChoiceFontSchemaType>
> = ({ styles, fonts }) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.testSingleChoice.type}-demo`,
        type: CONFIG_ELEMENTS.testSingleChoice.type,
        value: DEMO_VALUE,
        font: {},
        style: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

export default TestSingleChoiceDemo
