import cn from 'classnames'

import LayoutScroll from '../LayoutScroll'
import * as s from './LayoutContent.module.scss'

interface ILayoutContent {
  children: React.ReactNode
  onMouseDown?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  size?: 'mini' | 'medium' | 'large'
  free?: boolean
}

const LayoutContent: React.FC<ILayoutContent> = ({
  children,
  onMouseDown,
  size = 'large',
  free,
}) => (
  <LayoutScroll>
    <div className={cn(s.root, { [s.free]: free })} onMouseDown={onMouseDown}>
      <div className={cn(s.content, s[size])}>{children}</div>
    </div>
  </LayoutScroll>
)

export default LayoutContent
