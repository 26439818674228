// extracted by mini-css-extract-plugin
var _1 = "SplitterElement_blockHidden__SoRho";
var _2 = "SplitterElement_cropperLeft__J8hPx";
var _3 = "SplitterElement_cropperRight__7aZcq";
var _4 = "SplitterElement_customIcon__Bb6gE";
var _5 = "SplitterElement_image__C2cbH";
var _6 = "SplitterElement_img__YmhRP";
var _7 = "SplitterElement_item__tLYFp";
var _8 = "SplitterElement_left__uyTmD";
var _9 = "SplitterElement_pdf__j-EhL";
var _a = "SplitterElement_right__3KnW5";
var _b = "SplitterElement_root__SRPge";
var _c = "SplitterElement_splitterBody__8n8EV";
var _d = "SplitterElement_text__efzq2";
var _e = "SplitterElement_wrap__voKKz";
export { _1 as "blockHidden", _2 as "cropperLeft", _3 as "cropperRight", _4 as "customIcon", _5 as "image", _6 as "img", _7 as "item", _8 as "left", _9 as "pdf", _a as "right", _b as "root", _c as "splitterBody", _d as "text", _e as "wrap" }
