import { RichTextValue } from '@vedalib/rich-text'
import { useEffect, useReducer } from 'react'
import { Descendant } from 'slate'

import { CustomEditor } from './RichTextTypes'

export const useForceRender = (editor: CustomEditor, value?: RichTextValue) => {
  const [, forceRender] = useReducer((s) => !s, false)

  useEffect(() => {
    editor.children = (value || []) as Descendant[]
    forceRender()
  }, [editor, value])

  return forceRender
}
