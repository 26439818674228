import { createItem } from '@vedalib/editor/lib/elements/elements/LayoutList/constants'
import React from 'react'
import { chainName } from 'utils'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import { Typography } from 'components/uiKit/Typography'
import { DEFAULT_BOTTOM_INDENT } from 'components/uiKit/Typography/constants'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import { throughElementItemsArray } from '../../utils/formEffects'
import { Form } from './LayoutList.types'

const genField = genDataField<Block>()

const items = genField({
  name: 'items',
  type: 'array',
  fields: [],
  effect: throughElementItemsArray,
  params: {
    itemLabel: t('elements.layoutList.form.component'),
    ordering: true,
    styleType: 'hovered',
    min: 1,
    onAdd: (changeData) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const value = changeData.value as any[]
      return [...value, createItem()]
    },
  },
})

const LayoutListForm: Form = ({ name }) => {
  return (
    <Padding sides>
      <Typography indents={DEFAULT_BOTTOM_INDENT} styleType='hint'>
        {t('elements.layoutList.form.addSlideHint')}
      </Typography>
      <RealtimeField config={chainName(name, items)} />
    </Padding>
  )
}

export default LayoutListForm
