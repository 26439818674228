// extracted by mini-css-extract-plugin
var _1 = "Table_body__MElxC";
var _2 = "Table_cell__XZRBo";
var _3 = "Table_cellHeader__mcGhd";
var _4 = "Table_center__IF3Ng";
var _5 = "Table_checkboxHeader__5YIJB";
var _6 = "Table_disabledRow__hLZsm";
var _7 = "Table_emptyWrapper__oGgNg";
var _8 = "Table_header__aVTCi";
var _9 = "Table_left__A55fV";
var _a = "Table_loader__8uV55";
var _b = "Table_loaderRow__JBCy7";
var _c = "Table_noBordersHeader__XjWNg";
var _d = "Table_right__WjX-D";
var _e = "Table_root__YZthP";
var _f = "Table_sticky__GRO0j";
var _10 = "Table_stickyCheckbox__Pt0ij";
var _11 = "Table_tableFooter__byhgr";
var _12 = "Table_tableLayout__X+785";
var _13 = "Table_tableWrapper__TiQpe";
var _14 = "Table_wrapperPagination__L5Phg";
export { _1 as "body", _2 as "cell", _3 as "cellHeader", _4 as "center", _5 as "checkboxHeader", _6 as "disabledRow", _7 as "emptyWrapper", _8 as "header", _9 as "left", _a as "loader", _b as "loaderRow", _c as "noBordersHeader", _d as "right", _e as "root", _f as "sticky", _10 as "stickyCheckbox", _11 as "tableFooter", _12 as "tableLayout", _13 as "tableWrapper", _14 as "wrapperPagination" }
