import { CONFIG_ELEMENTS } from '@vedalib/editor/lib/elements'

import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA } from './LayoutListBrandFormSchema'
import Demo from './LayoutListDemo'
import Element from './LayoutListElement'
import Form from './LayoutListForm'
import icon from './assets/list.svg'

export default genElementConfig(CONFIG_ELEMENTS.layoutList, {
  icon,
  group: ElementGroup.view,
  label: t('elements.layoutList.label'),
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA: {},
  Demo,
})
