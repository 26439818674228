import React from 'react'

import { NodeType } from 'components/editor-v3/types/date.enums'

import { FCNode } from '../../Node/types'
import * as s from './PreviewAbsolute.module.scss'

const PreviewAbsolute: FCNode<NodeType.absolute> = React.forwardRef(({ children, style }, ref) => {
  return (
    <div className={s.root} ref={ref} style={style}>
      {children}
    </div>
  )
})

PreviewAbsolute.displayName = 'PreviewAbsolute'

export default PreviewAbsolute
