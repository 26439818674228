import { getEntityStyles } from 'utils'

import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { Typography } from 'components/uiKit/Typography'
import {
  ProjectListSchemaFragment,
  ProjectGroupListSchemaFragment,
} from 'gql/__generated__/graphql'
import {
  checkIsFolder,
  checkIsProject,
} from 'routes/routes/App/routes/Company/routes/Catalog/utils'

import { Highlighted } from '../../types'
import * as s from './RenderedColumnName.module.scss'

interface IRenderedColumnNameProps {
  record: Highlighted<ProjectListSchemaFragment | ProjectGroupListSchemaFragment>
}

const RenderedColumnName: React.FC<IRenderedColumnNameProps> = ({ record }) => (
  <div className={s.projectNameWrapper}>
    {checkIsProject(record) && (
      <Icon
        className={s.projectIcons}
        name='mainMenuProjects'
        size={KitSize.S}
        style={getEntityStyles(record.accent || undefined)}
      />
    )}
    {checkIsFolder(record) && (
      <Icon
        className={s.projectIcons}
        name='iconFillFolder'
        size={KitSize.S}
        style={getEntityStyles(record.color || undefined)}
      />
    )}
    <Typography rows={5} fluid>
      {record.nodeName || record.name}
    </Typography>
  </div>
)

export default RenderedColumnName
