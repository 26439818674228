import lodash from 'lodash'

import { SuspendDataType } from 'gql/__generated__/graphql'
import { getWindowData } from 'services/ScormData/ScormData'
import { parsePako, stringifyPako } from 'utils/gzip'
import { saveStringToFile } from 'utils/saveStringToFile'

import { SCORM_API } from './SCORM_API'
import {
  Api,
  CMIElement,
  IInteractionValues,
  ScormSettings,
  ScormSuspendData,
} from './SCORM_API_types'

let scorm: ScormSettings

export type SaveType = 'suspend' | 'force' | 'test'

type CmiElementConfig = {
  saveType: SaveType
  restore?: boolean
  initRead?: boolean
  readRule?: (data: string) => string
  default: string
}

type CmiData = { data: string; flushed: boolean }

const CmiConfig: Partial<{ [key in CMIElement]: CmiElementConfig }> = {
  'cmi.completion_status': {
    initRead: true,
    saveType: 'force',
    default: 'unknown',
  },
  'cmi.success_status': {
    initRead: true,
    saveType: 'test',
    default: 'unknown',
  },
  'cmi.score.raw': {
    saveType: 'test',
    default: '0',
  },
  'cmi.score.scaled': {
    saveType: 'test',
    default: '0',
  },
  'cmi.score.min': {
    saveType: 'test',
    default: '0',
  },
  'cmi.score.max': {
    saveType: 'test',
    default: '0',
  },
  'cmi.location': {
    initRead: true,
    saveType: 'suspend',
    default: '',
  },
  'cmi.suspend_data': {
    initRead: true,
    saveType: 'suspend',
    default: '',
  },
  'cmi.progress_measure': {
    initRead: true,
    saveType: 'force',
    default: '0',
  },
}

const dataStorage: Partial<{ [key in CMIElement]: CmiData }> = {}

const getCmiConfig = (key: CMIElement): CmiElementConfig => {
  const config = CmiConfig[key]
  return config || { saveType: 'force', default: '' }
}

const debug = (str: string): void => {
  SCORM_API.debug(str, 3, scorm)
}

const setValue = (
  cmiKey: CMIElement,
  data: string,
  force = getCmiConfig(cmiKey).saveType === 'force',
) => {
  let update = false
  const currentData = dataStorage[cmiKey] || { data: '', flushed: false }
  if (currentData.data !== data) {
    currentData.data = data
    currentData.flushed = false
    dataStorage[cmiKey] = currentData
    update = true
  }

  if (force && update) {
    flushKeys([cmiKey])
  }

  return update
}

const readOnInit = () => {
  Object.keys(CmiConfig).forEach((key) => {
    const config = getCmiConfig(key as CMIElement)
    if (config.initRead) {
      const value = SCORM_API.getvalue(key as CMIElement, scorm)
      if (value !== 'false') {
        lodash.set(dataStorage, key, { data: value, flushed: true })
      }
    }
  })
}

const flushKeys = (keys: CMIElement[]) => {
  keys.forEach((key) => {
    const data = lodash.get(dataStorage, key)
    if (data && !data.flushed) {
      SCORM_API.setvalue(key, data.data, scorm)
      data.flushed = true
    }
  })
}

const flushType = (saveType: SaveType) => {
  const keys = Object.keys(dataStorage).filter((key) => {
    const config = getCmiConfig(key as CMIElement)
    return config.saveType === saveType
  })
  SCORM_API.getDebugArray().push(`flushType: ${saveType} ${keys}`)
  flushKeys(keys as CMIElement[])
  debug(`flushType: ${saveType}`)
  SCORM_API.commit(scorm)
}

const getValue = (key: CMIElement): string => {
  if (!isConnected()) {
    return ''
  }

  const data = lodash.get(dataStorage, key)
  if (data) {
    return data.data
  }

  let scormData = SCORM_API.getvalue(key, scorm)
  scormData = data === 'false' ? '' : scormData

  console.warn(
    `SCORM_API_Wrapper: getValue: ${key} not found in dataStorage. Reading from SCORM. Maybe you forgot to add it to CmiConfig?`,
  )
  lodash.set(dataStorage, key, { data: scormData, flushed: true })
  return scormData
}

let flushSuspendThrottled = () => {}

const init = (debug = false, windowDebug = false): ScormSettings => {
  scorm = SCORM_API.scormSettings(debug, windowDebug)
  const windowData = getWindowData()
  if (!windowData.project) {
    return scorm
  }

  const exist = SCORM_API.init(scorm)
  if (!exist) {
    return scorm
  }

  SCORM_API.initialize(scorm)
  readOnInit()
  if (windowData.settings?.data?.suspendDataType.includes(SuspendDataType.interval)) {
    flushSuspendThrottled = lodash.throttle(
      () => flushType('suspend'),
      (windowData.settings.data?.suspendDataInterval || 60) * 1000,
      { trailing: true },
    )
  }

  const successStatus = getValue('cmi.completion_status')
  const completionStatus = getValue('cmi.success_status')
  SCORM_API.setvalue('cmi.success_status', successStatus || 'unknown', scorm)
  SCORM_API.setvalue('cmi.completion_status', completionStatus || 'unknown', scorm)

  return scorm
}

const getAPIInstance = (): Api | null =>
  typeof scorm?.API !== 'undefined' && scorm.API.isActive ? scorm.API : null

const isConnected = (): boolean => (getAPIInstance() ? scorm.API.isActive : false)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setSuspendData = (data: { sections: any; blocks: any; course: any }) => {
  setValue('cmi.suspend_data', stringifyPako(data))
  flushSuspendThrottled()
}

const getSuspendData = (): ScormSuspendData => {
  const defaultData = { blocks: {}, sections: {}, course: {}, elements: {} }

  const data = getValue('cmi.suspend_data')
  // TODO scorm can save 4000 chars in v1.2 and 64000 chars in v2004
  // if more - JSON.parse will throw an error
  try {
    if (data && data !== 'false') {
      const parsedData = parsePako<typeof defaultData>(data)
      return {
        blocks: parsedData.blocks || {},
        sections: parsedData.sections || {},
        course: parsedData.course || {},
        elements: parsedData.elements || {},
      }
    }
  } catch (err) {
    return defaultData
  }

  return defaultData
}

const setInteraction = (index: number, values: Partial<IInteractionValues>) => {
  Object.entries(values).forEach(([key, value]) => {
    if (value) {
      setValue(`cmi.interactions.${index}.${key}` as CMIElement, value)
    }
  })
}

const commit = (): void => {
  SCORM_API.commit(scorm)
}

const exit = (): void => {
  flushType('test')
  flushType('suspend')
  SCORM_API.setvalue('cmi.exit', '', scorm)
  SCORM_API.terminate(scorm)
  // SCORM_API.commit(scorm)
  window.close()
}

const reset = (): void => {
  for (const cmiConfigKey in CmiConfig) {
    const config = CmiConfig[cmiConfigKey as CMIElement]
    if (config) {
      setValue(cmiConfigKey as CMIElement, config.default, true)
    }
  }
  commit()
}

const suspend = (): void => {
  if (isConnected()) {
    flushType('suspend')
    SCORM_API.setvalue('cmi.exit', 'suspend', scorm)
    SCORM_API.terminate(scorm)
  }
}

const getDebugArray = (): string[] => {
  return SCORM_API.getDebugArray()
}

const saveDebugData = () => {
  saveStringToFile('debug.txt', SCORM_API.getDebugArray().join('\n'))
}

const SCORM_API_Wrapper = {
  init,

  isConnected,

  setValue,
  getValue,
  setSuspendData,
  getSuspendData,
  setInteraction,

  flushType,
  commit,
  exit,
  suspend,
  reset,

  getAPIInstance,
  getDebugArray,
  saveDebugData,
}

export { SCORM_API_Wrapper }
