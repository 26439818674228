import { LabeledGraphicFontSchemaType } from '@vedalib/editor/lib/brand'
import { LabeledGraphicPoint, LabeledGraphicElementValue } from '@vedalib/editor/lib/elements'
import cn from 'classnames'

import Icon from 'components/uiKit/Icon'
import { ElementFontCss } from 'services/Branding/types'
import { DeviceMode } from 'services/Store/Project/enums'
import { getImageByUsage } from 'utils/files'
import { extendTestData } from 'utils/test/qaData'

import { useLabeledGraphicContext } from '../../LabeledGraphicContext'
import PointContent from './PointContent'
import * as s from './PointTooltip.module.scss'

interface IPointTooltipProps {
  point: LabeledGraphicPoint
  index: number
  count: number
  value: LabeledGraphicElementValue
  id: string
  items?: LabeledGraphicPoint[]
  font: ElementFontCss<LabeledGraphicFontSchemaType>
}

const PointTooltip = ({ point, count, index, value, font, id }: IPointTooltipProps) => {
  const { image } = point
  const styles = useLabeledGraphicContext((c) => c.styles)
  const mode = useLabeledGraphicContext((c) => c.mode)
  const setActivePoint = useLabeledGraphicContext((c) => c.setActivePoint)
  const file = getImageByUsage(image)
  const isMobile = mode.deviceMode === DeviceMode.mobile
  const isFirst = index === 0
  const isLast = index === count - 1

  const style = {
    ...styles.tooltip,
    ...(!isMobile ? { borderRadius: styles.image.borderRadius } : {}),
  }

  const goPrev = () => {
    const prevItem = value.items[index - 1]
    setActivePoint(prevItem?.value)
  }
  const goNext = () => {
    const nextItem = value.items[index + 1]
    setActivePoint(nextItem?.value)
  }

  return (
    <div className={s.root} style={style}>
      <PointContent
        font={font}
        id={id}
        index={index}
        path={file?.path || point.image?.url}
        point={point}
        value={value}
      />
      {styles.navigation.switch && !isMobile && (
        <div className={cn(s.navigation, s[mode.deviceMode])} style={font?.navigation}>
          <div className={s.arrows}>
            <Icon
              className={cn({ [s.disabled]: isFirst })}
              name='iconsForElementsLeft'
              onClick={() => !isFirst && goPrev()}
              testData={extendTestData({
                index,
                disabled: isFirst,
              })}
            />
            <Icon
              className={cn({ [s.disabled]: isLast })}
              name='iconsForElementsRight'
              onClick={() => !isLast && goNext()}
              testData={extendTestData({
                index,
                disabled: isLast,
              })}
            />
          </div>
          <div>
            {index + 1}/{count}
          </div>
        </div>
      )}
    </div>
  )
}

export default PointTooltip
