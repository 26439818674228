import cn from 'classnames'
import React, { ReactNode } from 'react'

import { KitSize } from 'components/uiKit/KitTypes'
import { t } from 'services/Translation'

import { Typography } from '../Typography'
import * as s from './Empty.module.scss'
import BlockSVG from './assets/block.svg?react'
import ContentSVG from './assets/content.svg?react'
import EmptySVG from './assets/empty.svg?react'
import FolderSVG from './assets/folder.svg?react'
import FontsSVG from './assets/fonts.svg?react'
import ProjectSVG from './assets/project.svg?react'
import SearchSVG from './assets/search.svg?react'
import SettingsSVG from './assets/settings.svg?react'

export const ILLUSTRATIONS = {
  content: ContentSVG,
  empty: EmptySVG,
  folder: FolderSVG,
  fonts: FontsSVG,
  search: SearchSVG,
  project: ProjectSVG,
  settings: SettingsSVG,
  block: BlockSVG,
}

/**
 * Props for the Empty component.
 */
export interface IEmptyProps {
  /**
   * The text to display in the Empty component.
   */
  text?: ReactNode

  /**
   * The icon to display in the Empty component.
   */
  icon?: keyof typeof ILLUSTRATIONS | null

  /**
   * A custom icon component to display in the Empty component.
   */
  CustomIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>

  /**
   * The size of the Icon.
   */
  size?: KitSize

  /**
   * Whether the Empty component should display a search icon and search text.
   */
  search?: boolean

  /**
   * Whether the Empty component should grow to fill available space.
   * @example
   * ```scss
   * .grow {
   *  width: 100%;
   *  height: 100%;
   *  flex-grow: 1;
   * }
   * ```
   */
  grow?: boolean

  /**
   * Whether the component should be positioned absolutely.
   */
  absolute?: boolean
}

const Empty: React.FC<IEmptyProps> = ({
  text,
  icon = 'empty',
  size = KitSize.S,
  grow,
  absolute,
  search,
  CustomIcon,
}) => {
  const Icon = search ? ILLUSTRATIONS.search : icon && ILLUSTRATIONS[icon]
  return (
    <div className={cn(s.root, s[size], { [s.grow]: grow, [s.absolute]: absolute })}>
      {(CustomIcon && <CustomIcon height='1em' width='1em' />) ||
        (Icon && <Icon height='1em' width='1em' />)}
      {(search || text) && (
        <Typography styleType='hint' textAlign='center'>
          {search ? t('common.emptySearch') : text}
        </Typography>
      )}
    </div>
  )
}

export default Empty
