import { AiConfigSchemaFragment } from '../../gql/__generated__/graphql'

export const leftAiTokens = (aiConfigs?: AiConfigSchemaFragment[]) => {
  const active = aiConfigs?.find((aiConfig) => aiConfig.active)
  if (!active) {
    return 0
  }

  if (Number(active.totalLimit) === -1) {
    return Infinity
  }

  return Number(active.totalLimit) - Number(active.usedTokens)
}

export const hasActiveAiConfig = (aiConfigs?: AiConfigSchemaFragment[]) => {
  return aiConfigs?.some((aiConfig) => aiConfig.active)
}
