import cn from 'classnames'

import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { useProjectT } from 'services/ProjectLabels'
import { AppMode, PreviewMode } from 'services/Store/Project/enums'
import { testProps } from 'utils/test/qaData'
import { prepareLinkUrl } from 'utils/websiteValidation'

import { useElementRootStyles } from '../../hooks/useElementRootStyles'
import * as s from './LinkElement.module.scss'
import { LinkElementType } from './LinkElement.types'
import { PreviewCard } from './PreviewCard'
import { labelFromUrl } from './helper'
import LinkStyled from './styled/LinkStyled'

const LinkElement: LinkElementType = ({ element: { value }, styles, mode, font }) => {
  const pt = useProjectT()
  const { showPreview, linkLabel, linkURL } = value
  const disabled = mode.previewMode === PreviewMode.editor || mode.editorMode === AppMode.comment
  const correctLink = linkURL && prepareLinkUrl(linkURL || '')
  const linkURLValueByMode = disabled ? undefined : correctLink

  const rootStyles = useElementRootStyles(styles.root)
  const linkCn = cn(s.root, { [s.placeholder]: !linkLabel })
  const existingLabel = linkLabel || labelFromUrl(linkURL)

  return (
    <a
      className={linkCn}
      href={linkURLValueByMode}
      rel='noreferrer'
      style={rootStyles}
      target='_blank'
      {...testProps({ el: 'linkEl', value: linkURL, label: linkLabel })}
    >
      <LinkStyled $font={font} $label={existingLabel}>
        <span className={s.linkIcon}>
          {styles.icon.backgroundImage ? (
            <div className={s.icon} style={styles.icon} />
          ) : (
            <Icon name='link2' size={KitSize.M} />
          )}
        </span>
        <span>{existingLabel || pt('elements.link.placeholder')}</span>
      </LinkStyled>
      {showPreview && (
        <PreviewCard {...value} deviceMode={mode.deviceMode} font={font} styles={styles} />
      )}
    </a>
  )
}

export default LinkElement
