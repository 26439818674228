import cn from 'classnames'
import React, { useState } from 'react'

import Icon from 'components/uiKit/Icon'

import { IconButton } from '../Button'
import { KitSize } from '../KitTypes'
import * as s from './Notification.module.scss'
import { INotificationProps, NotificationType } from './types'

const notificationIcon = {
  error: (
    <span className={cn(s.icon, s.error)}>
      <Icon name='alertFailWhite' size={KitSize.S} />
    </span>
  ),
  success: (
    <span className={cn(s.icon, s.success)}>
      <Icon name='alertCheck' size={KitSize.S} />
    </span>
  ),
  warning: (
    <span className={cn(s.icon)}>
      <Icon name='alertDanger' size={KitSize.S} />
    </span>
  ),
  info: (
    <span className={cn(s.icon, s.info)}>
      <Icon name='alertDanger' size={KitSize.S} />
    </span>
  ),
}
const Notification: React.FC<INotificationProps> = ({ type, message, className }) => {
  const [visible, setVisible] = useState(true)
  const handleClose = () => {
    setVisible(false)
  }
  if (!visible) {
    return null
  }

  const icon = notificationIcon[type]

  return (
    <div className={cn(s.notification, s[type], className)}>
      <div className={s.block}>
        {icon}
        <div className={s.content}>
          <div className={cn(s.message, s[type])}>{message}</div>
          <div className={cn(s.divider, { [s.opacity]: type === NotificationType.error })} />
        </div>
      </div>
      <div className={cn(s.closeIconWrapper)} onClick={handleClose}>
        <div className={cn(s.closeIcon, s[type])}>
          <IconButton
            icon='otherClose'
            name='closeNotification'
            size={KitSize.XS}
            styleType='ghost'
          />
        </div>
      </div>
    </div>
  )
}
export default Notification
