import lodash from 'lodash'
import { centerCrop, makeAspectCrop } from 'react-image-crop'
import { Crop as CropType } from 'react-image-crop/src/types'

import { IImageEditValue } from './ImageEditModal'

export const scaleCrop = (crop?: CropType, scale = 1) => {
  return (
    crop && {
      ...crop,
      x: crop.x * scale,
      y: crop.y * scale,
      width: crop.width * scale,
      height: crop.height * scale,
    }
  )
}

export const makeCrop = (
  image: HTMLImageElement,
  crop?: CropType,
  aspect?: number,
): CropType | undefined => {
  const { naturalHeight: height, naturalWidth: width } = image
  const min = Math.min(height, width)

  if (aspect === null) {
    return
  }

  if (aspect) {
    //@ts-expect-error TODO
    return makeAspectCrop(crop || { height: min, width: min }, aspect, width, height)
  }

  if (crop) {
    return crop
  }

  //@ts-expect-error TODO
  return centerCrop({ height: min, width: min }, width, height)
}

export const isDisabledApply = (
  value: IImageEditValue,
  localValue: IImageEditValue,
  ref: HTMLImageElement | null,
) => {
  if (lodash.isEqual(localValue, value)) {
    return true
  }

  if (localValue.cropParams?.crop && !localValue.cropParams?.round) {
    const { width, height } = localValue.cropParams?.crop || {}
    const { naturalWidth: imgWidth, naturalHeight: imgHeight } = ref || {}

    if (width < 8 && height < 8) {
      return true
    }

    if (imgWidth === width && imgHeight === height) {
      return true
    }
  }

  return false
}
