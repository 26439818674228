import { AudioFontSchemaType, AudioSchemaType } from '@vedalib/editor/lib/brand'
import { VideoFontSchemaType, VideoSchemaType } from '@vedalib/editor/lib/brand'
import { VideoElementValue } from '@vedalib/editor/lib/elements'
import { FileUsageImageSource } from '@vedalib/editor/lib/files'
import React from 'react'

import DemoWrapper from 'components/editor-v2/EditorElements/decorators/DemoWrapper'
import { IElementDemoProps } from 'components/editor-v2/EditorElements/elements.types'
import { useBrandTheme } from 'services/Branding/hooks'
import { DEMO_MODE } from 'services/Store/Project/constants'

import Element from './Element'

const fileValue = {
  source: FileUsageImageSource.file,
  id: '',
}

const Demo = (
  TYPE: 'audio' | 'video',
): React.FC<
  IElementDemoProps<AudioSchemaType | VideoSchemaType, AudioFontSchemaType | VideoFontSchemaType>
> => {
  const Component: React.FC<
    IElementDemoProps<AudioSchemaType | VideoSchemaType, AudioFontSchemaType | VideoFontSchemaType>
  > = ({ styles, fonts }) => {
    const theme = useBrandTheme()

    const DEMO_VALUE_VIDEO: VideoElementValue = {
      duration: 0,
      playbackRate: 1,
      controls: true,
      autoPlay: false,
      repeat: false,
      volume: 50,
      file: fileValue,
      coverImage: null,
      captions: null,
      overlay: theme.neutralColor,
    }
    return (
      <DemoWrapper>
        <Element
          element={{
            id: `${TYPE}-demo`,
            type: TYPE,
            value: DEMO_VALUE_VIDEO,
            style: {},
            font: {},
          }}
          font={fonts}
          mode={DEMO_MODE}
          styles={styles}
        />
      </DemoWrapper>
    )
  }

  return Component
}

export default Demo
