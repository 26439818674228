import { config } from 'config'
import saveAs from 'file-saver'
import { FileTypeEnum } from 'utils'

import { IControlProps } from 'components/controls/Field/Field.types'
import useFilePicker from 'components/finder-v2/useFilePicker'
import { useUppy } from 'components/finder-v2/useUppy'
import Button, { IconButton } from 'components/uiKit/Button'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { FileMetaSchemaFragment, UpsertType } from 'gql/__generated__/graphql'
import { t } from 'services/Translation'

import * as s from './FieldFiles.module.scss'

interface IFieldFiles {
  value: FileMetaSchemaFragment[] | null
  fileType?: FileTypeEnum
  nullable?: boolean
  companyId: string
  taskId: string
}

const FieldFiles: React.FC<IControlProps<IFieldFiles>> = ({
  value,
  onChange,
  name,
  disabled,
  companyId,
  taskId,
}) => {
  const uppy = useUppy()

  const { onClickUploadFile } = useFilePicker({
    createdFor: UpsertType.task,
    multiple: true,
    name: 'uploadFile',
    uppy,
    params: { companyId, taskId },
  })

  const handleRemoveOnClick = (id: string) => () =>
    onChange?.({ value: value?.filter((item) => item.id !== id) || [], name })

  const handleDownload = (file: FileMetaSchemaFragment) => () =>
    saveAs(`${config.io.fileEndpoint}/download/${file.files[0]?.id}`, file.name)

  return (
    <div className={s.root}>
      {Boolean(value?.length) && (
        <div className={s.content}>
          {value?.map((item) => (
            <div className={s.file} key={item.id}>
              <Icon name='otherFile' size={KitSize.S} />
              <span className={s.fileName} onClick={handleDownload(item)}>
                {item.name}
              </span>
              <IconButton
                icon='otherClose'
                name='removeFile'
                onClick={handleRemoveOnClick(item.id)}
                size={KitSize.XS}
                styleType='ghost'
              />
            </div>
          ))}
        </div>
      )}
      <Button
        disabled={disabled}
        name={`${name}.download`}
        onClick={onClickUploadFile}
        size={KitSize.M}
        styleType='secondary'
      >
        {t('uiKit.button.upload')}
      </Button>
    </div>
  )
}

export default FieldFiles
