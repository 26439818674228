import { IArea } from '@vedalib/editor/lib/elements'
import cn from 'classnames'

import { ResizeDirection } from '../../TestHotspotElement.types'
import * as s from './ResizerHandlers.module.scss'

interface IResizerHandlersProps {
  selected: boolean
  area: IArea
  onResizeMouseDown: (
    e: React.MouseEvent<HTMLDivElement>,
    area: IArea,
    direction: ResizeDirection,
  ) => void
}

const handlers: { style: React.CSSProperties; direction: ResizeDirection }[] = [
  { style: { left: -5, top: -5, cursor: 'nwse-resize' }, direction: 'top-left' },
  { style: { right: -5, top: -5, cursor: 'nesw-resize' }, direction: 'top-right' },
  { style: { left: -5, bottom: -5, cursor: 'nesw-resize' }, direction: 'bottom-left' },
  { style: { right: -5, bottom: -5, cursor: 'nwse-resize' }, direction: 'bottom-right' },
]

const ResizerHandlers = ({ selected, area, onResizeMouseDown }: IResizerHandlersProps) => {
  return handlers.map((handler, index) => (
    <div
      className={cn(s.root, { [s.visible]: selected })}
      key={index}
      onMouseDown={(e) => onResizeMouseDown(e, area, handler.direction)}
      style={handler.style}
    />
  ))
}

export default ResizerHandlers
