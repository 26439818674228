import { CONFIG_ELEMENTS } from '@vedalib/editor/lib/elements'

import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './TestResultBrandFormSchema'
import Demo from './TestResultDemo'
import Element from './TestResultElement'
import Form from './TestResultForm'
import resultIcon from './assets/testResult.svg'

export default genElementConfig(CONFIG_ELEMENTS.testResult, {
  label: t('elements.testResult.label'),
  icon: resultIcon,
  group: ElementGroup.test,
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  Demo,
})
