import { PermissionsKey } from '@vedalib/crud'
import React, { useCallback } from 'react'

import { LayoutScroll } from 'components/LayoutPage'
import { EditorThread } from 'components/comments/comment'
import { CommentMock } from 'components/comments/types'
import { IconButton } from 'components/uiKit/Button'
import { KitSize } from 'components/uiKit/KitTypes'
import { Typography } from 'components/uiKit/Typography'
import {
  SORT_BY_STATUS,
  useEmployeesByPermissionInProject,
} from 'services/Permission/PermissionContext'
import { setOpenedComment } from 'services/Store/Project/actions'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getProjectId } from 'services/Store/Project/selectors'
import { t } from 'services/Translation'

import * as s from './DropdownThreads.module.scss'

interface IDropdownThreadsProps {
  comments?: CommentMock[]
  setVisible: (visible: boolean) => void
}

const DropdownThreads: React.FC<IDropdownThreadsProps> = ({ setVisible, comments }) => {
  const handleClose = () => setVisible(false)
  const dispatch = useProjectDispatch()
  const projectId = useProjectContext(getProjectId)

  const { all } = useEmployeesByPermissionInProject({
    projectId,
    pk: PermissionsKey.project_employeesFilter_comments_mention,
    includeCommentator: true,
    excludeSelf: true,
    sort: SORT_BY_STATUS,
  })

  const handleSetThread = useCallback(
    (comment: CommentMock) =>
      dispatch(setOpenedComment({ blockId: comment.blockUuid, threadId: comment.id })),
    [dispatch],
  )

  return (
    <div className={s.root}>
      <div className={s.title}>
        <Typography size={KitSize.XS} styleType='hint' weight='medium'>
          {t('page.editor.comments')}
        </Typography>
        <IconButton
          icon='otherClose'
          name='closeComments'
          onClick={handleClose}
          size={KitSize.S}
          styleType='ghost'
          round
        />
      </div>
      <LayoutScroll sizeAutoCapable>
        <div className={s.content}>
          {comments?.map((comment) => (
            <EditorThread
              comment={comment}
              key={comment.id}
              mentions={all}
              setThread={handleSetThread}
            />
          ))}
        </div>
      </LayoutScroll>
    </div>
  )
}

export default DropdownThreads
