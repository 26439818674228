import { useMutation, useQuery, useSubscription, WatchQueryFetchPolicy } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'
import { gqlClient } from 'gql'

import { gql } from 'gql/__generated__'
import {
  FileMetaGroupsSearch,
  FileMetaSchemaFragment,
  UnsplashExternalImagesQueryVariables,
  UpsertFileMetaMutationVariables,
} from 'gql/__generated__/graphql'
import { companyGetByIdQuery } from 'gql/companies/gql/queries'
import { fileMetaCreate } from 'gql/files/cache'

import { fileMetaAndGroupCreate, fileMetaAndGroupRemove } from './cache'
import { fileFragment } from './gql/fragments'
import {
  fileMetaAndGroupMove,
  fileMetaGroupCreateMutation,
  fileMetaGroupUpdateByIdMutation,
  fileMetasUpdateById,
  finderForceDelete,
  unsplashCreateFileMeta,
  unsplashTrackDownloads,
  upsertFileMeta,
} from './gql/mutations'
import {
  fileMetaAllQuery,
  fileMetaGroupAllQuery,
  fileMetaPreview,
  getFileUrlInfoQuery,
  unsplashExternalImages,
} from './gql/queries'
import {
  fileMetaGroupSubscription,
  filesSubscription,
  projectFilesSubscription,
} from './gql/subscriptions'

export interface IFileMetaGroupAllProps {
  companyId: string
  search?: FileMetaGroupsSearch
  parentId: string | null
  isInfiniteLoad?: boolean
  fetchPolicy?: WatchQueryFetchPolicy
  nextFetchPolicy?: WatchQueryFetchPolicy
}

interface IUseFileMetaPreviewParams {
  companyId: string
  parentId: string
  fileId: string
  search: FileMetaGroupsSearch
}

export type FilesSubscriptionsTypes = {
  companyId: string
  projectId?: string | null
  parentId: string | null
  search?: FileMetaGroupsSearch
}

export const upsertFileMetaCore = (variables: UpsertFileMetaMutationVariables) => {
  return gqlClient.core.mutate({
    mutation: upsertFileMeta,
    variables,
    refetchQueries: [String(getOperationName(companyGetByIdQuery))],
  })
}

export const useFileMetasDeleteByIds = () =>
  useMutation(
    gql(`
      mutation FileMetasDeleteByIds(
        $ids: [String!]!
        $companyId: String
        $projectId: String
        $createdById: String
      ) {
        data: fileMetasDeleteByIds(
          ids: $ids
          companyId: $companyId
          projectId: $projectId
          createdById: $createdById
        )
      }
    `),
    {
      onError: (err) =>
        console.error('"useFileMetasDeleteByIds" fn is crashed on operation: "useMutation"', err),
    },
  )

export const useFileMetaPreview = (variables: IUseFileMetaPreviewParams) =>
  useQuery(fileMetaPreview, {
    variables,
    fetchPolicy: 'cache-and-network',
    skip: !variables.fileId,
    onError: ({ graphQLErrors }) => {
      console.error('useFileMetaPreview', graphQLErrors)
    },
  })

export const getFileMetaByIdFromCache = (id?: string | null) => {
  const cache = gqlClient.core.cache

  return id
    ? cache.readFragment<FileMetaSchemaFragment>({
        id: cache.identify({
          __typename: 'FileMeta',
          id: id,
        }),
        fragment: fileFragment,
        fragmentName: 'FileMetaSchema',
      })
    : undefined
}

export const getFileMetasByIdFromCache = (ids?: string[] | null) => {
  const cache = gqlClient.core.cache

  return (
    ids?.map((id) =>
      cache.readFragment<FileMetaSchemaFragment>({
        id: cache.identify({ __typename: 'FileMeta', id: id }),
        fragment: fileFragment,
        fragmentName: 'FileMetaSchema',
      }),
    ) || null
  )
}

export const useFileMetaGroupAll = ({
  companyId,
  search,
  parentId,
  isInfiniteLoad,
  fetchPolicy = 'cache-and-network',
  nextFetchPolicy,
}: IFileMetaGroupAllProps) =>
  useQuery(fileMetaGroupAllQuery, {
    //@ts-expect-error TODO
    variables: { companyId, search, parentId, isInfiniteLoad },
    fetchPolicy,
    nextFetchPolicy,
    onError: (err) =>
      console.error('"useFileMetaGroupAll" fn is crashed on operation: "useLazyQuery"', err),
  })

export const useUnsplashExternalImages = (variables: UnsplashExternalImagesQueryVariables) =>
  useQuery(unsplashExternalImages, {
    variables,
    fetchPolicy: 'cache-and-network',
  })

export const useFileMetaGroupCreate = () =>
  useMutation(fileMetaGroupCreateMutation, {
    onError: (err) =>
      console.error('"useFileMetaGroupCreate" fn is crashed on operation: "useMutation"', err),
    refetchQueries: [String(getOperationName(fileMetaGroupAllQuery))],
  })

export const useFileMetaGroupUpdateById = () =>
  useMutation(fileMetaGroupUpdateByIdMutation, {
    onError: (err) =>
      console.error('"useFileMetaGroupUpdateById" fn is crashed on operation: "useMutation"', err),
  })
export const useFileMetasUpdateById = () =>
  useMutation(fileMetasUpdateById, {
    onError: (err) =>
      console.error('"useFileMetasUpdateById" fn is crashed on operation: "useMutation"', err),
  })

export const useFileMetaAndGroupMove = () =>
  useMutation(fileMetaAndGroupMove, {
    onError: (err) =>
      console.error('"useFileMetaAndGroupMove" fn is crashed on operation: "useMutation"', err),
    refetchQueries: [String(getOperationName(fileMetaGroupAllQuery))],
  })

export const useFinderForceDeleteMetaAll = () =>
  useMutation(finderForceDelete, {
    refetchQueries: [
      String(getOperationName(fileMetaAllQuery)),
      String(getOperationName(fileMetaGroupAllQuery)),
    ],
    onError: (err) =>
      console.error('"useFinderForceDelete" fn is crashed on operation: "useMutation"', err),
  })

export const useFinderForceDeleteGroupAll = () =>
  useMutation(finderForceDelete, {
    refetchQueries: [String(getOperationName(fileMetaGroupAllQuery))],
    onError: (err) =>
      console.error('"useFinderForceDelete" fn is crashed on operation: "useMutation"', err),
  })

export const useUnsplashCreateFileMeta = () =>
  useMutation(unsplashCreateFileMeta, {
    onError: (err) =>
      console.error('"useUnsplashCreateFileMeta" fn is crashed on operation: "useMutation"', err),
  })

export const useUnsplashTrackDownloads = () =>
  useMutation(unsplashTrackDownloads, {
    onError: (err) =>
      console.error('"useUnsplashTrackDownloads" fn is crashed on operation: "useMutation"', err),
  })

export const checkUrlRequest = async (url: string) => {
  try {
    const data = await gqlClient.core.query({
      query: getFileUrlInfoQuery,
      variables: {
        url,
      },
    })

    return data.data.data
  } catch (err) {
    console.error('"checkUrlRequest" fn is crashed on operation: ".query"', err)
    return
  }
}

export const useFilesSubscription = (params: FilesSubscriptionsTypes) =>
  useSubscription(filesSubscription, {
    onData: (options) => {
      const data = options.data.data?.data
      switch (data?.type) {
        case 'create':
          fileMetaCreate(data, params)
          // profileUpdateCacheAvatar(data.files) //Useless
          break
      }
    },
    variables: {
      companyId: params.companyId,
      projectId: params.projectId,
      parentId: params.parentId || 'root',
    },
  })

export const useFileMetaGroupSubscription = (params: FilesSubscriptionsTypes) =>
  useSubscription(fileMetaGroupSubscription, {
    onData: (options) => {
      const data = options.data.data?.data
      switch (data?.type) {
        case 'create':
          fileMetaAndGroupCreate(data, params)
          break
        case 'delete':
          fileMetaAndGroupRemove(data, params)
          break
      }
    },
    variables: {
      companyId: params.companyId,
      projectId: params.projectId,
      parentId: params.parentId || 'root',
    },
  })

export const useProjectFilesSubscription = (projectId: string) =>
  useSubscription(projectFilesSubscription, {
    variables: {
      projectId,
    },
  })
