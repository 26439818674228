import { useFloating, offset, shift, hide, flip, Placement } from '@floating-ui/react'
import { cloneElement, isValidElement, useEffect, useMemo } from 'react'

import { IDropdownCourseContent } from './DropdownCourse'
import { FnChild, IChildrenHandler } from './types'
import { getMergedHandlers } from './utils'

interface IUseEvents {
  inPopupOrChild: (element: Node) => boolean
  popupEle: HTMLDivElement | null
  mergedOpen: boolean
  triggerOpen: (nextOpen: boolean) => void
  type: IDropdownCourseContent['type']
}

interface IUseAlignParams {
  targetEle: HTMLElement | null
  popupEle: HTMLDivElement | null
  alignConfig: {
    x?: number
    y?: number
  }
  placement: IDropdownCourseContent['placement']
  triggerOpen: (open: boolean) => void
  fluid?: boolean
  open: boolean
}

interface IUseGetCloneElement {
  children: IChildrenHandler | FnChild
  type: IDropdownCourseContent['type']
  triggerOpen: (nextOpen: boolean) => void
  stopPropagation?: boolean
  mergedOpen: boolean
}

const isBottom = (placement: Placement) => placement.toLowerCase().startsWith('bottom')
const isTop = (placement: Placement) => placement.toLowerCase().startsWith('top')
const isLeft = (placement: Placement) => placement.toLowerCase().startsWith('left')
const isRight = (placement: Placement) => placement.toLowerCase().startsWith('right')
const DEFAULT_SHIFT_OFFSET = 8

export const useAlign = ({
  targetEle,
  popupEle,
  alignConfig,
  placement = 'bottom',
  triggerOpen,
  fluid,
  open,
}: IUseAlignParams) => {
  const { width } = targetEle?.getBoundingClientRect() || {}
  const isCrossAxis = isTop(placement) || isLeft(placement)
  const isMainAxis = isRight(placement) || isBottom(placement)

  const {
    x: left,
    y: top,
    update,
    middlewareData,
  } = useFloating({
    strategy: 'absolute',
    elements: { reference: targetEle, floating: popupEle },
    placement,
    open,
    onOpenChange: triggerOpen,
    middleware: [
      offset({
        mainAxis: alignConfig.y || (isMainAxis ? DEFAULT_SHIFT_OFFSET : 0),
        crossAxis: alignConfig.x || (isCrossAxis ? DEFAULT_SHIFT_OFFSET : 0),
      }),
      flip(),
      shift({ padding: DEFAULT_SHIFT_OFFSET }),
      hide(),
    ],
  })

  useEffect(() => {
    const abortController = new AbortController()
    const { signal } = abortController
    update()
    const resizeObserver = new ResizeObserver(() => {
      update()
    })

    if (targetEle) {
      resizeObserver.observe(targetEle)
    }
    if (middlewareData.hide?.referenceHidden) {
      triggerOpen(false)
    }

    window.addEventListener('resize', update, { signal })
    window.addEventListener('scroll', update, { capture: true, signal })

    return () => {
      abortController.abort()
      resizeObserver.disconnect()
    }
  }, [targetEle, triggerOpen, update, middlewareData.hide?.referenceHidden])

  return useMemo(
    () => ({
      top,
      left,
      ...(fluid && { width }),
    }),
    [fluid, left, top, width],
  )
}

export const useEvents = ({
  inPopupOrChild,
  triggerOpen,
  mergedOpen,
  popupEle,
  type,
}: IUseEvents) => {
  useEffect(() => {
    if (popupEle) {
      const abortController = new AbortController()
      const signal = abortController.signal

      const onWindowClick = (e: MouseEvent) => {
        const target = e.target as Node
        if (mergedOpen && !inPopupOrChild(target)) {
          triggerOpen(false)
        }
      }

      const closeOnEsc = (e: KeyboardEvent) => {
        const target = e.target as Node
        if (e.key === 'Escape' && mergedOpen && !inPopupOrChild(target)) {
          triggerOpen(false)
        }
      }

      const onOutsideLeave = (e: MouseEvent) => {
        const target = e.target as Node
        if (type === 'tooltip' && mergedOpen && !inPopupOrChild(target)) {
          triggerOpen(false)
        }
      }
      document.addEventListener('mouseover', onOutsideLeave, { signal })
      document.addEventListener('mousedown', onWindowClick, { signal, capture: true })
      document.addEventListener('keydown', closeOnEsc, { signal })

      return () => {
        abortController.abort()
      }
    }
    return () => undefined
  }, [inPopupOrChild, mergedOpen, popupEle, triggerOpen, type])
}

export const useGetCloneElement = ({
  children,
  type,
  triggerOpen,
  mergedOpen,
}: IUseGetCloneElement) => {
  const clonedChild = useMemo(
    () =>
      isValidElement(children)
        ? cloneElement(children, {
            ...getMergedHandlers({ children, type, triggerOpen }),
          })
        : children,
    [type, children, triggerOpen],
  )

  const childNode = useMemo(
    () =>
      typeof clonedChild === 'function'
        ? clonedChild({ open: mergedOpen, setOpen: triggerOpen })
        : clonedChild,
    [clonedChild, mergedOpen, triggerOpen],
  )

  return childNode
}
