import { TestRankingFontSchemaType, TestRankingSchemaType } from '@vedalib/editor/lib/brand'
import { TestRankingElementValue } from '@vedalib/editor/lib/elements'
import { CONFIG_ELEMENTS } from '@vedalib/editor/lib/elements'
import { textToRtValue } from '@vedalib/rich-text'

import { DEMO_MODE } from 'services/Store/Project/constants'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './TestRankingElement'

const DEMO_VALUE: TestRankingElementValue = {
  items: [
    {
      value: 'ranking_1',
      label: textToRtValue(t('elements.testRanking.value.firstElem')),
      points: 1,
    },
    {
      value: 'ranking_2',
      label: textToRtValue(t('elements.testRanking.value.secondElem')),
      points: 1,
    },
    {
      value: 'ranking_3',
      label: textToRtValue(t('elements.testRanking.value.thirdElem')),
      points: 1,
    },
  ],
}

const TestRankingDemo: React.FC<
  IElementDemoProps<TestRankingSchemaType, TestRankingFontSchemaType>
> = ({ styles, fonts }) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.testRanking.type}-demo`,
        type: CONFIG_ELEMENTS.testRanking.type,
        value: DEMO_VALUE,
        font: {},
        style: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

export default TestRankingDemo
