// extracted by mini-css-extract-plugin
var _1 = "LinkElement_description__1TU-t";
var _2 = "LinkElement_emptyTitle__DMLtE";
var _3 = "LinkElement_icon__cz2Sz";
var _4 = "LinkElement_linkIcon__5kp35";
var _5 = "LinkElement_mobile__VBbak";
var _6 = "LinkElement_placeholder__XtKgZ";
var _7 = "LinkElement_preview__eev7o";
var _8 = "LinkElement_previewImg__oIMH7";
var _9 = "LinkElement_previewInfo__2vURn";
var _a = "LinkElement_root__0uO8c";
export { _1 as "description", _2 as "emptyTitle", _3 as "icon", _4 as "linkIcon", _5 as "mobile", _6 as "placeholder", _7 as "preview", _8 as "previewImg", _9 as "previewInfo", _a as "root" }
