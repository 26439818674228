import * as R from 'ramda'
import React, { useCallback, useEffect } from 'react'
import { useContextSelector } from 'use-context-selector'

import { useProjectContext } from 'services/Store/Project/hooks'
import { getScrollReady } from 'services/Store/Project/selectors'

import AbsolutePortalContext from './AbsolutePortalContext'
import { ABSOLUTE_PORTAL_GAP } from './constants'
import { BoxType, IPositionArgs, PlacementType } from './types'

const TOOLBAR_WIDTH = 320

export const usePortalPosition = (
  id: string,
  measure: React.RefObject<HTMLDivElement>,
  ref: React.RefObject<HTMLDivElement>,
  args: IPositionArgs,
) => {
  const { placement = 'filled', zIndex = 0, chain = 'vertical' } = args
  const { translateX: tX, translateY: tY } = args
  const scrollReady = useProjectContext(getScrollReady)
  const portalsRef = useContextSelector(AbsolutePortalContext, (v) => v.portalsRef)
  const portals = useContextSelector(AbsolutePortalContext, (v) => v.portals)
  const viewport = useContextSelector(AbsolutePortalContext, (v) => v.viewport)
  const setPortals = useContextSelector(AbsolutePortalContext, (v) => v.setPortals)

  const update = useCallback(() => {
    requestAnimationFrame(() => {
      if (!portalsRef.current || !measure.current || !ref.current) {
        return
      }

      const portalsBox = portalsRef.current.getBoundingClientRect()
      const measureBox = measure.current.getBoundingClientRect()
      ref.current.style.transform = `translateX(${tX || 0}) translateY(${tY || 0})`
      ref.current.style.zIndex = String(zIndex)
      ref.current.style.top = `${calcTop[placement](portalsBox, measureBox)}px`
      ref.current.style.left = `${calcLeft[placement](portalsBox, measureBox)}px`

      if (placement === 'filled') {
        ref.current.style.zIndex = '-1'
        ref.current.style.pointerEvents = 'none'
        ref.current.style.width = `${measureBox.width}px`
        ref.current.style.height = `${measureBox.height}px`
      } else {
        intersection(R.omit([id], portals.current), portalsBox, viewport, ref, chain, zIndex)
        const final = {
          ...relativePosition(portalsBox, ref.current.getBoundingClientRect()),
          zIndex,
        }
        setPortals({ ...portals.current, [id]: final })
      }
    })
  }, [
    portalsRef,
    viewport,
    measure,
    ref,
    tX,
    tY,
    zIndex,
    placement,
    id,
    portals,
    chain,
    setPortals,
  ])

  useEffect(() => {
    update()
    const resizeObserver = new ResizeObserver(update)
    if (measure.current) {
      resizeObserver.observe(measure.current)
    }

    if (portalsRef.current) {
      resizeObserver.observe(portalsRef.current)
    }

    if (viewport?.current) {
      resizeObserver.observe(viewport.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [update, scrollReady, portalsRef, viewport, measure, id])

  useEffect(() => {
    window.addEventListener('resize', update)
    return () => {
      window.removeEventListener('resize', update)
      setPortals(R.omit([id]))
    }
  }, [id, setPortals, update])
}

const relativePosition = (root: BoxType, element: BoxType): BoxType => ({
  x: -root.x + element.x,
  y: -root.y + element.y,
  width: element.width,
  height: element.height,
  zIndex: element.zIndex,
})

const calcLeft: Record<PlacementType, (root: BoxType, measure: BoxType) => number> = {
  filled: (root, measure) => measure.x - root.x,
  top: (root, measure) => -root.x + measure.x + measure.width / 2,
  topLeft: (root, measure) => -root.x + measure.x,
  topRight: (root, measure) => -root.x + measure.x + measure.width,
  bottom: (root, measure) => -root.x + measure.x + measure.width / 2,
  bottomLeft: (root, measure) => -root.x + measure.x,
  bottomRight: (root, measure) => -root.x + measure.x + measure.width,
  left: (root, measure) => -root.x + measure.x,
  leftBottom: (root, measure) => -root.x + measure.x,
  leftTop: (root, measure) => -root.x + measure.x,
  right: (root, measure) => -root.x + measure.x + measure.width,
  rightBottom: (root, measure) => -root.x + measure.x + measure.width,
  rightTop: (root, measure) => -root.x + measure.x + measure.width,
}

const calcTop: Record<PlacementType, (root: BoxType, measure: BoxType) => number> = {
  filled: (root, measure) => measure.y - root.y,
  top: (root, measure) => -root.y + measure.y,
  topLeft: (root, measure) => -root.y + measure.y,
  topRight: (root, measure) => -root.y + measure.y,
  bottom: (root, measure) => -root.y + measure.y + measure.height,
  bottomLeft: (root, measure) => -root.y + measure.y + measure.height,
  bottomRight: (root, measure) => -root.y + measure.y + measure.height,
  left: (root, measure) => -root.y + measure.y + measure.height / 2,
  leftBottom: (root, measure) => -root.y + measure.y + measure.height,
  leftTop: (root, measure) => -root.y + measure.y,
  right: (root, measure) => -root.y + measure.y + measure.height / 2,
  rightBottom: (root, measure) => -root.y + measure.y + measure.height,
  rightTop: (root, measure) => -root.y + measure.y,
}

const intersection = (
  rectangles: Record<string, BoxType | null>,
  root: BoxType,
  viewport: React.RefObject<HTMLDivElement> | undefined,
  ref: React.RefObject<HTMLDivElement>,
  chain: 'vertical' | 'horizontal',
  zIndex: number,
) => {
  if (ref.current) {
    if (viewport?.current) {
      const rect = ref.current.getBoundingClientRect()
      const box = relativePosition(root, rect)
      const viewRect = viewport.current.getBoundingClientRect()
      const viewBox = relativePosition(root, viewRect)
      if (rect.x < viewRect.x) {
        ref.current.style.transform = ''
        ref.current.style.left = `${viewBox.x}px`
      } else if (box.x + box.width > viewBox.x + viewBox.width - TOOLBAR_WIDTH) {
        ref.current.style.transform = ''
        ref.current.style.left = `${viewBox.x + viewBox.width - TOOLBAR_WIDTH - box.width - ABSOLUTE_PORTAL_GAP}px`
      }
    }

    const box = { ...relativePosition(root, ref.current.getBoundingClientRect()), zIndex }
    for (const key in rectangles) {
      const o = rectangles[key]
      if (
        o &&
        o.zIndex === box.zIndex &&
        o.x < box.x + box.width &&
        o.x + o.width > box.x &&
        o.y < box.y + box.height &&
        o.y + o.height > box.y
      ) {
        if (chain === 'vertical') {
          ref.current.style.top = `${o.y + o.height + ABSOLUTE_PORTAL_GAP}px`
        } else if (chain === 'horizontal') {
          ref.current.style.left = `${o.x + o.width + ABSOLUTE_PORTAL_GAP}px`
        }

        intersection(R.omit([key], rectangles), root, viewport, ref, chain, zIndex)
      }
    }
  }
  return null
}
