import { SCHEMA } from '@vedalib/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.video.style, {
  player: {
    META: { label: t('elements.video.tags.player') },
    backgroundColor: {},
  },
  play: {
    META: { hidden: true },
    backgroundColor: { label: t('elements.video.tags.play') },
  },
  hover: {
    META: { hidden: true },
    backgroundColor: {
      label: t('style.hoverColor'),
      params: {
        resettable: true,
      },
    },
  },
  icons: {
    META: { hidden: true },
    color: { label: t('input.label.iconsColor') },
  },
  activeCC: {
    META: { hidden: true },
    color: { label: t('elements.video.schema.activeCC') },
  },
  slider: {
    META: { hidden: true },
    backgroundColor: { label: t('elements.video.schema.sliderBackground') },
  },
  sliderFilled: {
    META: { hidden: true },
    backgroundColor: { label: t('elements.video.schema.sliderFilled') },
  },
  border: {
    META: { label: t('elements.video.tags.border') },
    borderRadius: {},
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
  },
  effects: {
    META: { label: t('style.effects') },
    boxShadow: { label: t('elements.video.schema.boxShadow') },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.video.font, {
  player: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.video.tags.player') },
  },
  tooltip: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.video.tags.tooltip') },
  },
})
