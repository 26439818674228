// extracted by mini-css-extract-plugin
var _1 = "TabMenu_activeTab__8Cmjw";
var _2 = "TabMenu_arrowLeft__hHe59";
var _3 = "TabMenu_arrowRight__qZNHR";
var _4 = "TabMenu_content__0Z4qx";
var _5 = "TabMenu_contentPosition__rHQXG";
var _6 = "TabMenu_controls__73Tmo";
var _7 = "TabMenu_grow__NNDNG";
var _8 = "TabMenu_isDrawArrow__MesBw";
var _9 = "TabMenu_left__tvtoJ";
var _a = "TabMenu_right__y710p";
var _b = "TabMenu_root__uJiDb";
var _c = "TabMenu_spaceBetween__DTGeY";
var _d = "TabMenu_tabBarExtraContentGrow__iOIE4";
var _e = "TabMenu_tabList__OXnYj";
var _f = "TabMenu_tabListWrap__lRqv8";
var _10 = "TabMenu_tabsGrow__uaXTE";
var _11 = "TabMenu_top__OkNzc";
export { _1 as "activeTab", _2 as "arrowLeft", _3 as "arrowRight", _4 as "content", _5 as "contentPosition", _6 as "controls", _7 as "grow", _8 as "isDrawArrow", _9 as "left", _a as "right", _b as "root", _c as "spaceBetween", _d as "tabBarExtraContentGrow", _e as "tabList", _f as "tabListWrap", _10 as "tabsGrow", _11 as "top" }
