import * as R from 'ramda'
import { useEffect, useMemo } from 'react'

import { AutoHeightProp } from './TextArea'
import { BORDER, LINE_HEIGHT } from './constants'

export const useAutoHeight = (
  textareaRef: React.MutableRefObject<HTMLTextAreaElement | null>,
  verticalPadding: number,
  autoHeight: AutoHeightProp,
  deps: unknown[],
  lineHeight?: number,
) => {
  const dep1 = typeof autoHeight === 'object' && autoHeight?.minRows
  const dep2 = typeof autoHeight === 'object' && autoHeight?.maxRows

  const autoHeightMemo = useMemo(() => {
    const { minRows = 1, maxRows = Infinity } = typeof autoHeight === 'object' ? autoHeight : {}
    return { minRows, maxRows }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dep1, dep2])

  useEffect(() => {
    if (autoHeightMemo) {
      const textarea = textareaRef.current
      if (!textarea) {
        return
      }

      textarea.style.height = 'auto'
      textarea.style.overflow = 'hidden' // fix scroll extra padding
      const scrollHeight = textarea.scrollHeight
      const { minRows, maxRows } = autoHeightMemo
      const rows = Math.floor((scrollHeight - verticalPadding * 2) / (lineHeight || LINE_HEIGHT))

      const visibleRows = R.clamp(minRows, maxRows, rows)

      const height = `${visibleRows * (lineHeight || LINE_HEIGHT) + (verticalPadding + BORDER) * 2}px`

      textarea.style.overflow = 'auto'
      textarea.style.height = height
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, autoHeightMemo, lineHeight])
}
