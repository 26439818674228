import {
  EditorElement,
  TestMultipleChoiceElementValue,
  TestKeyboardInputElementValue,
  TestMatchingElementValue,
  TestSingleChoiceElementValue,
  TestRankingElementValue,
  TestHotspotElementValue,
} from '@vedalib/editor/lib/elements'
import lodash from 'lodash'

import {
  ISpot,
  TestHotspotStateType,
} from 'components/editor-v2/EditorElements/elements/TestHotspotElement/TestHotspotElement.types'
import { MapType, ElementState } from 'services/Store/Project/types'

const questionValidate = <S extends { value: unknown[] }>(
  elements: MapType<EditorElement>,
  state: MapType<ElementState<S>>,
) => {
  let isValid = true

  for (const id in elements) {
    const stateValue = state[id]?.value || []
    const element = elements[id]
    if (!stateValue) {
      isValid = false
      continue
    }

    if (isRadio(element) || isCheckbox(element)) {
      const rule = element.value.items.filter((e) => e.isValid)
      const valid = lodash.isEqual(rule.map((i) => i.value).sort(), stateValue.slice().sort())
      if (!rule.length || !valid) {
        isValid = false
      }
    }

    if (isInput(element)) {
      const rule = element.value.items
      const valid = lodash.isEqual(rule.map((i) => i.value).sort(), stateValue.slice().sort())
      if (!rule.length || !valid) {
        isValid = false
      }
    }

    if (isRanking(element)) {
      const rule = element.value.items.map((i) => i.value)
      const valid = lodash.isEqual(rule, stateValue)
      if (!valid) {
        isValid = false
      }
    }

    if (isMatch(element)) {
      const rule = element.value.items.map((i) => i.value)
      const valid = lodash.isEqual(rule, stateValue)
      if (!valid) {
        isValid = false
      }
    }

    if (isHotspot(element)) {
      const spots = [...((state[id] as unknown as TestHotspotStateType)?.spots || [])]
      if (element.value.response === 'single') {
        const anySpotsInAreas = spots.some((item) => (item as ISpot).items.length > 0)
        isValid = anySpotsInAreas
      } else if (element.value.response === 'all') {
        const allSpotsInAreas = spots.every((item) => (item as ISpot).items.length > 0)
        const spotsAreas = spots.flatMap((spot) => (spot as ISpot).items)
        const areasIds = element.value.items.map((area) => area.id)
        const unusedAreas = lodash.difference(areasIds, spotsAreas)
        isValid = allSpotsInAreas && !unusedAreas.length
      } else {
        throw new Error('Invalid response type')
      }
    }
  }

  return { isValid, points: 0 }
}

const isRadio = (el?: EditorElement): el is EditorElement<TestSingleChoiceElementValue> =>
  el?.type === 'testSingleChoice'

const isCheckbox = (el?: EditorElement): el is EditorElement<TestMultipleChoiceElementValue> =>
  el?.type === 'testMultipleChoice'

const isRanking = (el?: EditorElement): el is EditorElement<TestRankingElementValue> =>
  el?.type === 'testRanking'

const isMatch = (el?: EditorElement): el is EditorElement<TestMatchingElementValue> =>
  el?.type === 'testMatching'

const isInput = (el?: EditorElement): el is EditorElement<TestKeyboardInputElementValue> =>
  el?.type === 'testKeyboardInput'

const isHotspot = (el?: EditorElement): el is EditorElement<TestHotspotElementValue> =>
  el?.type === 'testHotspot'

export default questionValidate
