import { SlateFormats, SYMBOLS_CODES } from 'components/form/RichText/RichTextConstants'
import {
  getUpdateFormat,
  getSelectedFormat,
  useRichTextContext,
} from 'components/form/RichText/useRichTextContext'
import Button from 'components/uiKit/Button'
import { Tooltip } from 'components/uiKit/Dropdown'
import { t } from 'services/Translation'

import * as s from './SymbolItem.module.scss'

const SymbolItem = ({ name }: { name: SlateFormats }) => {
  const onUpdateFormat = useRichTextContext(getUpdateFormat)
  const selectedFormat = useRichTextContext(getSelectedFormat)

  return (
    <Tooltip overlay={t(`uiKit.tooltip.${name}` as const)} trigger={['hoverWithoutContent']}>
      <div className={s.root}>
        <Button name={name} onClick={() => onUpdateFormat(name, selectedFormat)} styleType='ghost'>
          {String.fromCodePoint(SYMBOLS_CODES[name])}
        </Button>
      </div>
    </Tooltip>
  )
}

export default SymbolItem
