import cn from 'classnames'
import { useId } from 'react'

import { t } from 'services/Translation'
import { testPropsEl } from 'utils/test/qaData'

import { IOption, KitSize } from '../KitTypes'
import * as s from './Segmented.module.scss'
import SegmentedElement from './SegmentedElement'

export const DEFAULT_OPTIONS: IOption<boolean>[] = [
  { value: false, label: t('common.no') },
  { value: true, label: t('common.yes') },
]

export const INVERTED_DEFAULT_OPTIONS: IOption<boolean>[] = [
  { value: true, label: t('common.no') },
  { value: false, label: t('common.yes') },
]
export type Value = string | boolean | number
export interface ISegmentedProps<T extends Value = Value> {
  options?: IOption<T>[]
  size?: KitSize
  disabled?: boolean
  name: string
  value?: T
  fluid?: boolean
  onChange: (value: T) => void
  onFocus?: () => void
  onBlur?: () => void
  resettable?: boolean
}

const Segmented = <T extends number | string | boolean>({
  name,
  value,
  onChange,
  onFocus,
  onBlur,
  disabled,
  size = KitSize.M,
  fluid = false,
  options = DEFAULT_OPTIONS as IOption<T>[],
}: ISegmentedProps<T>) => {
  const groupName = useId()

  return (
    <div
      className={cn(s.root, disabled && s.disabled, fluid && s.fluid)}
      {...testPropsEl('switcher', { name }, { disabled: !!disabled })}
    >
      {options.map((item, i) => (
        <div className={s.option} key={i}>
          <SegmentedElement
            disabled={disabled}
            groupName={groupName}
            item={item}
            key={String(item.value)}
            name={name}
            onBlur={onBlur}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={onChange as any}
            onFocus={onFocus}
            size={size}
            value={value}
          />
          <div className={s.divider} />
        </div>
      ))}
    </div>
  )
}

export default Segmented
