export const mergeCustomize = <T>(objValue: T, srcValue: T) => {
  if (srcValue instanceof File) {
    return srcValue
  }

  if (Array.isArray(objValue) || Array.isArray(srcValue)) {
    return srcValue
  }

  return
}
