import cn from 'classnames'
import React from 'react'

import { ColumnActionUiEnum } from 'components/editor-v3/Editor/types'
import { NodeType } from 'components/editor-v3/types/date.enums'
import { scrollProps } from 'services/Scroll/ScrollService'
import { AppMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getIsActiveNode, getIsHighlighted } from 'services/Store/Project/selectors'
import { t } from 'services/Translation'

import { FCNode } from '../../Node/types'
import ColumnControls from '../../controls/ColumnControls'
import ControlSelection from '../../controls/ControlSelection'
import * as s from './EditorAbsolute.module.scss'

const CONTROLS = [
  ColumnActionUiEnum.DELETE,
  ColumnActionUiEnum.SPLIT_HORIZONTAL,
  ColumnActionUiEnum.SPLIT_VERTICAL,
  ColumnActionUiEnum.ADD_SHELL,
]

const EditorAbsolute: FCNode<NodeType.absolute> = React.forwardRef(
  ({ children, node, style, block, mode }, ref) => {
    const isActive = useProjectContext(getIsActiveNode, node.id)
    const isHighlighted = useProjectContext(getIsHighlighted, node.id)
    const editorMode = mode.editorMode
    const isPro = editorMode === AppMode.pro
    const isFill = editorMode === AppMode.fill

    return (
      <div
        {...scrollProps(node.id)}
        className={cn(s.root, { [s.fillMode]: isFill })}
        ref={ref}
        style={style}
      >
        {children}
        {isActive && isPro && <ColumnControls block={block} controls={CONTROLS} nodeId={node.id} />}
        {(isActive || isHighlighted) && (
          <ControlSelection
            active={isActive}
            highlighted={isHighlighted}
            label={t('uiKit.tooltip.absolute')}
            name='colSelection'
          />
        )}
      </div>
    )
  },
)

EditorAbsolute.displayName = 'EditorAbsolute'

export default React.memo(EditorAbsolute)
