"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.TYPE = void 0;
const translation_1 = require("../../../translation");
const rich_text_1 = require("@vedalib/rich-text");
exports.TYPE = 'richText';
exports.DEFAULT_VALUE = [
    {
        type: rich_text_1.SlateElementType.elementDefault,
        children: [{ text: (0, translation_1.t)('elements.richText.placeholder') }],
    },
];
