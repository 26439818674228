import { DeepPartial } from '@vedalib/editor/lib/utils/types'
import lodash from 'lodash'
import React, { useState } from 'react'

import Form from 'components/controls/Form'
import FormControls from 'components/controls/FormControls'
import { FILTER_LABELS } from 'components/controls/FormControls/constants'
import Button, { IconButton } from 'components/uiKit/Button'
import Dropdown from 'components/uiKit/Dropdown'
import { IPlacement } from 'components/uiKit/Dropdown/types'
import Group from 'components/uiKit/Group'
import { KitSize } from 'components/uiKit/KitTypes'
import { t } from 'services/Translation'

interface IFilterPopoverProps<T extends object> {
  name: string
  defaultValue?: T
  value: DeepPartial<T>
  onFiltersChange: (values: T) => void
  children: React.ReactNode
  size?: KitSize
  placement?: IPlacement
  isShort?: boolean
  width?: number
}

const FilterPopover = <T extends object>({
  name,
  defaultValue = {} as T,
  value,
  size,
  width,
  isShort,
  placement = 'bottomLeft',
  onFiltersChange,
  children,
}: IFilterPopoverProps<T>) => {
  const isFilterApplied = !lodash.isEqual(defaultValue, value)
  const [showFilter, setShowFilter] = useState(false)

  const onSubmit = (values: T) => {
    setShowFilter(false)
    onFiltersChange(values)
  }

  const onClear = () => {
    setShowFilter(false)
    onFiltersChange(defaultValue)
  }

  const handleReset = (e: React.MouseEvent) => {
    e.stopPropagation()
    onFiltersChange(defaultValue)
  }

  return (
    <Dropdown
      onVisibleChange={setShowFilter}
      overlay={
        <div style={{ width }}>
          <Form
            initialValues={value}
            name={`${name}.filter`}
            onReset={onClear}
            onSubmit={onSubmit}
            size={size}
          >
            {children}
            <FormControls justify='right' label={FILTER_LABELS} size={size} />
          </Form>
        </div>
      }
      placement={placement}
      visible={showFilter}
    >
      {isShort ? (
        <IconButton
          active={isFilterApplied || showFilter}
          icon='messageFilter'
          name='sort'
          size={size}
          styleType='ghost'
        />
      ) : (
        <Group join>
          <Button
            active={Boolean(isFilterApplied) || showFilter}
            name={`${name}.filterButton`}
            size={size}
            styleType='ghost'
          >
            {t('uiKit.filterPopover.label')}
          </Button>
          {isFilterApplied && (
            <IconButton
              active={Boolean(isFilterApplied) || showFilter}
              icon='otherClose'
              name='otherClose'
              onClick={handleReset}
              size={size}
              styleType='ghost'
            />
          )}
        </Group>
      )}
    </Dropdown>
  )
}

export default FilterPopover
