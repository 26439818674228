import { DraggableProvided } from '@hello-pangea/dnd'
import { generateId } from '@vedalib/editor/lib/utils/id'
import cn from 'classnames'
import * as R from 'ramda'
import React, { useEffect } from 'react'

import { IFieldConfig, IOnChange, IOnChangeData } from 'components/controls/Field/Field.types'
import { IconButton } from 'components/uiKit/Button'
import { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { scrollProps } from 'services/Scroll/ScrollService'
import { t } from 'services/Translation'
import { testProps } from 'utils/test/qaData'

import { ArrayValue } from '../FieldArray'
import * as s from './Item.module.scss'

interface IItemProps {
  onChange: IOnChange<ArrayValue[]>
  value: ArrayValue[]
  Parent: React.FC<{ config: IFieldConfig }>
  index: number
  dnd?: DraggableProvided
  isDragging?: boolean
  name: string
  active?: string | null
  max?: number
  min?: number
  onActive?: (id: string) => void
  onFocus?: (index: string | null) => void
  onClone?: (changeData: IOnChangeData) => ArrayValue[]
  styleType?: 'padding' | 'bordered' | 'clear' | 'hovered' | 'collapse' | 'horizontal'
  id: string
  label?: string
  fields: IFieldConfig[]
}

const Item: React.FC<IItemProps> = ({
  Parent,
  value,
  onChange,
  dnd,
  index,
  name,
  isDragging,
  label,
  fields,
  active,
  max,
  min,
  onActive,
  onClone,
  onFocus,
  id,
  styleType = 'bordered',
}) => {
  const isActive = id === active

  const handleClone = () => {
    let newValue
    if (onClone) {
      newValue = onClone({ value, name, meta: { item: value[index], index } })
    } else {
      newValue = [...value, { ...R.clone(value[index]), value: generateId() }]
    }

    onChange({ value: newValue, name, meta: { command: 'clone', item: value[index], index } })
  }

  const handleDelete = () => {
    const newValue = value.filter((_item, i) => i !== index)
    onChange({ value: newValue, name, meta: { command: 'delete', item: value[index] } })
  }

  const handleFocus = () => onFocus && onFocus(id)

  const handleBlur = () => onFocus && onFocus(null)

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => e.currentTarget.focus()

  useEffect(() => {
    if (isActive) {
      onActive?.(id)
    }
  }, [isActive, onActive, id])

  const forbiddenDuplicateItem = Boolean(max && value.length >= max)
  const forbiddenRemoveItem = Boolean(min && value.length <= min)

  return (
    <div
      {...scrollProps(id)}
      className={cn(s.root, s[styleType], isActive && s.active)}
      onClick={handleFocus}
    >
      <div
        ref={dnd?.innerRef}
        {...dnd?.draggableProps}
        className={cn(s.item, s[styleType], isDragging && s.dragging)}
        style={dnd?.draggableProps.style}
      >
        <div className={cn(s.header, s[styleType])}>
          <span
            className={cn(s.title, s[styleType])}
            {...testProps({
              el: 'arrayItem',
              name: `${name}[${index}].titleItem`,
              label: `${label || t('uiKit.button.element')} #${index + 1}`,
              index,
            })}
          >
            {dnd && (
              <span {...dnd?.dragHandleProps} className={s.dragIcon} onMouseDown={onMouseDown}>
                <Icon name='builderDrugAndDrop' size={KitSize.S} />
              </span>
            )}
            <span>
              {label || t('uiKit.button.element')} #{index + 1}
            </span>
          </span>
          <div className={cn(s.actions, s[styleType])}>
            {styleType !== 'collapse' && (
              <Tooltip overlay={t('uiKit.tooltip.duplicate')}>
                <IconButton
                  disabled={forbiddenDuplicateItem}
                  icon='otherCopy'
                  name='cloneItem'
                  onClick={handleClone}
                  size={KitSize.S}
                  styleType='ghost'
                />
              </Tooltip>
            )}

            <Tooltip overlay={t('uiKit.tooltip.delete')}>
              <IconButton
                disabled={forbiddenRemoveItem}
                icon='otherTrash'
                name='deleteItem'
                onClick={handleDelete}
                size={KitSize.S}
                styleType='ghost'
              />
            </Tooltip>
          </div>
        </div>
        {Boolean(fields.length) && (
          <div className={cn(s.fields, s[styleType])} onBlur={handleBlur} onFocus={handleFocus}>
            {fields.map((field) => {
              return (
                <Parent
                  config={{
                    ...field,
                    name: `${name}[${index}].${field.name}`,
                    layout: field.layout || 'vertical',
                  }}
                  key={`${name}[${index}].${field.name}:${field.keyPostfix || ''}}`}
                />
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default Item
