import { MouseEventHandler } from 'react'

import { ActionsConfig } from '../../editor-v3/Editor/types'
import { IMenuOption } from './MenuItem'

export const optionToIconButton = <V extends string>(
  option: IMenuOption<V>,
  handle: ActionsConfig<V>['handleAction'],
) => {
  if (!option.icon) {
    throw new Error('Icon is required')
  }

  const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    handle({ value: option.value })
  }
  return {
    icon: option.icon,
    name: option.value,
    onClick,
  }
}
