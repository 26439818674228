import { useMutation, useQuery } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'
import { useEffect } from 'react'

import { CommentMock } from 'components/comments/types'
import {
  CommentsAllQueryVariables,
  DashboardCommentsQueryVariables,
  TaskCommentsQueryVariables,
} from 'gql/__generated__/graphql'

import {
  commentCreate,
  commentDelete,
  commentSetRead,
  commentSubscribe,
  commentUnsubscribe,
  commentUpdate,
} from './gql/mutations'
import { commentsAll, dashboardComments, taskComments } from './gql/queries'
import {
  commentsSubscription,
  feedThreadsSubscription,
  taskCommentsSubscription,
} from './gql/subscription'
import { updateQuery } from './updateQuerys'

export const useDashboardComments = (variables: DashboardCommentsQueryVariables) => {
  const { data, loading, subscribeToMore, fetchMore } = useQuery(dashboardComments, {
    variables,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(
    () =>
      subscribeToMore({
        document: feedThreadsSubscription,
        variables: { companyId: variables.companyId },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return { data, loading, fetchMore }
}
export const useTaskComments = (variables: TaskCommentsQueryVariables, companyId: string) => {
  const { data, loading, subscribeToMore, fetchMore } = useQuery(taskComments, {
    variables,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(
    () =>
      subscribeToMore({
        document: taskCommentsSubscription,
        variables: { companyId, taskId: variables.filter.taskId },
        updateQuery: (prev, { subscriptionData }) => updateQuery(prev, subscriptionData.data),
      }),
    [companyId, subscribeToMore, variables.filter.taskId],
  )

  return { data, loading, fetchMore }
}

export const useCommentsAll = (
  variables: CommentsAllQueryVariables & { companyId: string },
  skip = false,
) => {
  const { data, subscribeToMore, loading } = useQuery(commentsAll, {
    variables,
    skip,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(
    () =>
      subscribeToMore({
        document: commentsSubscription,
        variables: {
          companyId: variables.companyId,
          projectId: variables.filter.projectId,
          sectionId: variables.filter.sectionId,
        },
        updateQuery: (prev, { subscriptionData }) => updateQuery(prev, subscriptionData.data),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return { data: data?.data.comments as CommentMock[], loading }
}

export const useCommentCreate = () => useMutation(commentCreate)

export const useCommentUpdate = () => useMutation(commentUpdate)

export const useCommentDelete = () =>
  useMutation(commentDelete, {
    refetchQueries: [
      // String(getOperationName(commentsAll)), // all deleted comment`s will be sended by subscription
      String(getOperationName(dashboardComments)),
    ],
  })

export const useCommentSubscribe = () => useMutation(commentSubscribe)

export const useCommentUnsubscribe = () => useMutation(commentUnsubscribe)

export const useCommentSetRead = () => useMutation(commentSetRead)
