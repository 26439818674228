import * as R from 'ramda'

import { BlockModeEnum } from 'gql/__generated__/graphql'
import { notEmpty } from 'utils/notEmpty'
import { shuffle } from 'utils/shuffle'

import { Block } from './types'

type PartBlock = Pick<Block, 'mode' | 'uuid'>

export const shuffleQuestions = (blocks: PartBlock[], count?: number | null) => {
  const start = R.find(({ mode }) => mode === BlockModeEnum.start, blocks)
  const questions = R.compose<[PartBlock[]], PartBlock[], PartBlock[], PartBlock[]>(
    R.slice(0, count || blocks.length),
    shuffle,
    R.filter<PartBlock>(({ mode }) => mode === BlockModeEnum.questions),
  )(blocks)
  const end = R.findLast(({ mode }) => mode === BlockModeEnum.end, blocks)

  return [start, ...questions, end].filter(notEmpty).map(({ uuid }) => uuid)
}
