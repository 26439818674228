// extracted by mini-css-extract-plugin
var _1 = "EditorShell_active__A8OeQ";
var _2 = "EditorShell_comments__Zotsy";
var _3 = "EditorShell_disableClicks__tgVIa";
var _4 = "EditorShell_fillMode__0S2oT";
var _5 = "EditorShell_highlighter__WJxdx";
var _6 = "EditorShell_noClick__ge1gr";
var _7 = "EditorShell_shell__zRsks";
var _8 = "EditorShell_wrapper__SSazY";
var _9 = "EditorShell_zeroLineHeight__8gyYh";
export { _1 as "active", _2 as "comments", _3 as "disableClicks", _4 as "fillMode", _5 as "highlighter", _6 as "noClick", _7 as "shell", _8 as "wrapper", _9 as "zeroLineHeight" }
