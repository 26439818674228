import { Block, LayoutNode } from '@vedalib/editor'
import { gqlClient } from 'gql'
import lodash from 'lodash'
import { useCallback, useMemo } from 'react'

import { NO_PANEL_ELEMENTS } from 'components/editor-v2/EditorElements/elements.config'
import { isShellNode } from 'components/editor-v3/types/data.guards'
import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import { useBlocksCopy } from 'gql/blocks/apollo'
import { blocksGetById } from 'gql/blocks/gql/queries'
import { ScrollContainerEnum } from 'services/Scroll/enums'
import {
  setProjectNavigation,
  setNavbar,
  updateBlock,
  setCommentForm,
  setToolbar,
} from 'services/Store/Project/actions'
import { AppMode, NavigationEnum, SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import {
  getBlock,
  getBlockId,
  getNavBar,
  getNodeId,
  getSection,
  getSectionId,
  getSelectedBlocksIds,
} from 'services/Store/Project/selectors'
import store from 'services/Store/store'
import { t } from 'services/Translation'
import { isCtrl } from 'utils/events'

import { ICopyData } from './EditorHotkeys'
import { pasteNode } from './mutators/block'
import { getDeepChildrenIds, getElementByNode, getNodeSafe, getPathNodes } from './selectors/block'

interface IArgs {
  mode: AppMode | null
  blockId: string | null
  nodeId: string | null
}

export const useEditorCanvasMouse = ({ mode, blockId, nodeId }: IArgs) => {
  const dispatch = useProjectDispatch()

  const onMouseDown = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()

      const ctrl = isCtrl(event)
      const shift = event?.shiftKey

      const state = store.getState().project
      const navbar = getNavBar(state)
      const block = getBlock(state, { id: blockId })
      const section = getSection(state)
      const node = getNodeSafe(block, nodeId)

      if (mode === AppMode.fill /*  || mode === AppMode.task || mode === AppMode.view */) {
        if (section?.type !== SectionTypeEnum.landing) {
          dispatch(setProjectNavigation({ nodeId, ctrl, shift }))
        } else {
          dispatch(setProjectNavigation({ blockId, nodeId, ctrl, shift }))
        }
      } else if (mode === AppMode.comment) {
        event.stopPropagation()
        const elementId = isShellNode(node) ? node.elementId || null : null
        dispatch(setCommentForm({ blockId, elementId }))
      } else if (mode === AppMode.pro) {
        const currBlockId = getBlockId(state)
        if (currBlockId && currBlockId !== blockId) {
          dispatch(setProjectNavigation({ nodeId: null }))
        } else if (ctrl) {
          event.preventDefault()
          const currNodeId = getNodeId(state)
          const isRoot = block?.schema.rootId === currNodeId
          const current = getNodeSafe(block, currNodeId)
          const currentPath = (block && current?.id && getPathNodes(block, current.id)) || []
          const currentParent: LayoutNode | null = currentPath[currentPath.length - 2] || null
          const currentChildren = getDeepChildrenIds(block, currentParent?.id) || []
          if (!isRoot && (currentChildren.includes(nodeId || '') || nodeId === current?.id)) {
            dispatch(setProjectNavigation({ blockId, nodeId: currentParent?.id || null }))
          } else if (!isRoot) {
            dispatch(setProjectNavigation({ blockId, nodeId }))
          }
        } else {
          const currNodeId = getNodeId(state)
          const current = getNodeSafe(block, currNodeId)
          const currentPath = (block && current?.id && getPathNodes(block, current.id)) || []
          const currentParent: LayoutNode | null = currentPath[currentPath.length - 2] || null
          const newNodeId = currNodeId === nodeId ? currentParent?.id : nodeId
          dispatch(setProjectNavigation({ blockId, nodeId: newNodeId || nodeId }))
        }
      }

      if (navbar.tab === NavigationEnum.project) {
        dispatch(setNavbar({ tab: null }))
      }
    },
    [dispatch, mode, blockId, nodeId],
  )

  const onDoubleClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      const state = store.getState().project
      const block = getBlock(state, { id: blockId })
      const node = getNodeSafe(block, nodeId)
      const element = getElementByNode(block, node)

      const isPanel = element?.type && !NO_PANEL_ELEMENTS.includes(element?.type)
      if (isPanel && mode === AppMode.fill) {
        dispatch(
          setToolbar({ commentTab: null, taskTab: null, blockSettings: null, hidden: false }),
        )
      }

      dispatch(setToolbar({ commentTab: null, taskTab: null, blockSettings: null, hidden: false }))
    },
    [dispatch, mode, blockId, nodeId],
  )

  // const onMouseUp = useCallback((event: React.MouseEvent) => {}, [dispatch, mode, blockId, nodeId])

  // const onMouseOver = useCallback(
  //   (event: React.MouseEvent) => {
  //     event.stopPropagation()
  //     if (!nodeId) {
  //       return
  //     }

  //     const state = store.getState().project
  //     const block = getBlock(state, { id: blockId })
  //     const node = getNodeSafe(block, nodeId)

  //     if (
  //       (mode === AppMode.comment || mode === AppMode.fill || mode === AppMode.task) &&
  //       isShellNode(node)
  //     ) {
  //       dispatch(setHighlight(nodeId))
  //     }

  //     if (mode === AppMode.pro) {
  //       dispatch(setHighlight(nodeId))
  //     }
  //   },
  //   [dispatch, mode, blockId, nodeId],
  // )

  // const onMouseOut = useCallback(() => dispatch(setHighlight(null)), [dispatch])

  return useMemo(
    () => ({
      onDoubleClick,
      onMouseDown /* , onMouseDownCapture */,
      // onMouseUp /* , onMouseUpCapture */,
      // onMouseOver,
      // onMouseOut,
    }),
    [
      onDoubleClick,
      onMouseDown /* , onMouseDownCapture, */,
      // onMouseUp /* , onMouseUpCapture */,
      // onMouseOver,
      // onMouseOut,
    ],
  )
}

export const useBlocksDuplicate = (paramsSectionId?: string) => {
  const dispatch = useProjectDispatch()
  const secId = useProjectContext(getSectionId)
  const sectionId = paramsSectionId || secId
  const [copyBlocks] = useBlocksCopy(sectionId)

  return useCallback(
    async (copedBlockIds?: string[]) => {
      const projectState = store.getState().project
      const selectedBlockIds = getSelectedBlocksIds(projectState)
      const { blocksOrder } = getSection(projectState) || { blocksOrder: [] as string[] }
      const orderedSelectedBlocks = blocksOrder.filter((id) => selectedBlockIds.includes(id))
      const lastSelected = orderedSelectedBlocks[orderedSelectedBlocks.length - 1]
      const lastSelectedIndex = blocksOrder.findIndex((id) => id === lastSelected)
      const uuids = lodash.sortBy(copedBlockIds || selectedBlockIds, (id) =>
        blocksOrder.indexOf(id),
      )
      const res = await copyBlocks({
        variables: {
          payload: {
            uuids,
            activeBlockUUID: lastSelected || null,
            sectionId: sectionId || '',
          },
        },
      })
      const newBlocksOrder = res.data?.data.blocksOrder
      if (newBlocksOrder) {
        notify({
          message: t('notify.pasteBlockToAnotherSection.success', {
            count: selectedBlockIds.length,
          }),
          type: NotificationType.success,
        })
        const newSelectedBlocks = newBlocksOrder.slice(
          lastSelectedIndex + 1,
          lastSelectedIndex + selectedBlockIds.length + 1,
        )
        if (res.data?.data.blocks.length) {
          const blocksIds = [...(res?.data?.data?.blocks || [])].map((b) => b.uuid)
          dispatch(
            setProjectNavigation({
              blockId: blocksIds[0],
              blockIds: blocksIds,
              nodeId: null,
              sectionId,
              scroll: [
                {
                  container: ScrollContainerEnum.canvas,
                  id: newSelectedBlocks[0],
                  block: 'begin',
                  scroll: ['never', 'always'],
                },
                {
                  container: ScrollContainerEnum.blocksNav,
                  id: newSelectedBlocks[0],
                  block: 'begin',
                  scroll: 'always',
                },
              ],
            }),
          )
          dispatch(setNavbar({ tab: NavigationEnum.section }))
        }
      }
    },
    [copyBlocks, dispatch, sectionId],
  )
}

export const useNodeDuplicate = () => {
  const dispatch = useProjectDispatch()
  const block = useProjectContext(getBlock)
  const nodeId = useProjectContext(getNodeId)

  const onChange = useCallback(
    async (value: unknown) => block?.uuid && dispatch(updateBlock({ id: block.uuid, value })),
    [dispatch, block],
  )

  return useCallback(
    async (data: ICopyData) => {
      const blockData = await gqlClient.core.query<{ data: Block }>({
        query: blocksGetById,
        variables: {
          uuid: data.blockId || '',
          sectionId: data.sectionId || '',
        },
      })

      const fromBlock = blockData.data.data
      const fromNodeId = data.nodeId

      if (fromNodeId && fromBlock && nodeId && block) {
        const update = pasteNode(fromBlock, block, fromNodeId, nodeId, false)
        if (update) {
          onChange(update.block)
        } else {
          notify({
            message: 'Paste failed',
            type: NotificationType.info,
          })
        }
      }
    },
    [block, nodeId, onChange],
  )
}
