import React, { ErrorInfo } from 'react'

import { t } from 'services/Translation'

const DEFAULT_ERROR_TEXT = t('editor.systemError')

interface IProps {
  children: React.ReactNode

  errorChildren?: React.ReactNode
  errorText?: string
}

interface IState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<IProps, IState> {
  public state: IState = {
    hasError: false,
  }

  public static getDerivedStateFromError(_error: Error): IState {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('ErrorBoundary error:', error, errorInfo)
  }

  public render() {
    const { hasError } = this.state
    const { errorText, errorChildren, children } = this.props

    if (hasError) {
      if (errorChildren) {
        return errorChildren
      }

      return <h3>{errorText || DEFAULT_ERROR_TEXT}</h3>
    }

    return children
  }
}

export default ErrorBoundary
