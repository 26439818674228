// extracted by mini-css-extract-plugin
var _1 = "CheckboxItem_checkIcon__Z0bbY";
var _2 = "CheckboxItem_checkbox__mCJ0R";
var _3 = "CheckboxItem_checked__YUns5";
var _4 = "CheckboxItem_disabled__ASsuV";
var _5 = "CheckboxItem_hideCheckbox__HIpUS";
var _6 = "CheckboxItem_indeterminate__YDS8P";
var _7 = "CheckboxItem_input__XWFq7";
var _8 = "CheckboxItem_label__O+9Pn";
var _9 = "CheckboxItem_medium__7tlJP";
var _a = "CheckboxItem_root__cKnl4";
export { _1 as "checkIcon", _2 as "checkbox", _3 as "checked", _4 as "disabled", _5 as "hideCheckbox", _6 as "indeterminate", _7 as "input", _8 as "label", _9 as "medium", _a as "root" }
