import { PermissionsKey } from '@vedalib/crud'
import { CONFIG_COURSE } from '@vedalib/editor/lib/elements'
import React, { useCallback } from 'react'

import { useElementStyleCss, useElementFontCss } from 'services/Branding/hooks'
import { usePermissions } from 'services/Permission/PermissionContext'
import { setSidebar } from 'services/Store/Project/actions'
import { DeviceMode } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import {
  getProject,
  getScormSettings,
  getSection,
  getSidebar,
} from 'services/Store/Project/selectors'
import { IBlockMode } from 'services/Store/Project/types'

import CourseHeader from './CourseHeader'

interface ICourseHeaderProps {
  isPartView?: boolean
  mode?: IBlockMode
}

const CourseHeaderContainer: React.FC<ICourseHeaderProps> = ({ isPartView, mode }) => {
  const dispatch = useProjectDispatch()
  const canEditorView = usePermissions(PermissionsKey.project_pages_editor)
  const scormSettings = useProjectContext(getScormSettings)
  const section = useProjectContext(getSection)
  const cover = useProjectContext(getSection, { cover: true })
  const firstSection = useProjectContext(getSection, { first: true })
  const { open } = useProjectContext(getSidebar)

  const currentSection = section || cover || firstSection
  const project = useProjectContext(getProject)
  const title = !section?.id || cover?.id === section?.id ? project?.name : currentSection?.name

  const styles = useElementStyleCss(
    CONFIG_COURSE.header.type,
    mode?.deviceMode || DeviceMode.desktop,
  )
  const fonts = useElementFontCss(CONFIG_COURSE.header.type, mode?.deviceMode || DeviceMode.desktop)
  const toggleSidebar = useCallback(() => dispatch(setSidebar({ open: !open })), [dispatch, open])

  return (
    <CourseHeader
      deviceMode={mode?.deviceMode}
      fonts={fonts}
      isDisabledSidebarAndHome={isPartView && !canEditorView}
      project={project}
      scormSettings={scormSettings}
      styles={styles}
      title={title}
      toggleSidebar={toggleSidebar}
    />
  )
}

export default CourseHeaderContainer
