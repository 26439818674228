import lodash from 'lodash'
import * as R from 'ramda'

import { DEFAULT_NODE, ELEMENTS_WITH_CHILDREN } from 'components/editor-v3/types/data.constants'
import { isCommonNode, isShellNode } from 'components/editor-v3/types/data.guards'
import { CommonNode, LayoutNode, ShellNode } from 'components/editor-v3/types/data.types'
import { mergeWithDeviceMode, getParentDeviceMode } from 'services/Branding/utils'
import { DeviceMode } from 'services/Store/Project/enums'
import { Block } from 'services/Store/Project/types'
import { cloneElement, cloneNode, idMapper } from 'utils/editor/clone'
import { notEmpty } from 'utils/notEmpty'

export const getNode = (block: Pick<Block, 'schema'>, id: string) => block?.schema?.nodes?.[id]
export const getNodeSafe = (block?: Block | null, id?: string | null) =>
  (id && block?.schema?.nodes?.[id]) || null

export const getElement = (block: Pick<Block, 'elements'>, id: string) => block.elements[id]
export const getElementByNode = (block?: Block | null, node?: LayoutNode | null) => {
  if (block && node && isShellNode(node)) {
    return (node.elementId && getElement(block, node.elementId)) || null
  }

  return null
}

export const getElementByNodeId = (block: Block, id: string) => {
  const node = getNode(block, id)
  if (isShellNode(node) && node.elementId) {
    return getElement(block, node.elementId)
  }

  return null
}

export const getRootId = (block: Block) => block.schema.rootId

export const getShellByElementId = (block: Pick<Block, 'schema'>, id: string) => {
  const shell = Object.values(block.schema.nodes).find(
    (node) => isShellNode(node) && node.elementId === id,
  )
  return shell ? shell.id : null
}

export const getRootNode = (block: Block): CommonNode =>
  getNode(block, getRootId(block)) as CommonNode

export const nodeWithDeviceMode = (node: LayoutNode, deviceMode: DeviceMode) => {
  const defaultNode = DEFAULT_NODE[node.type]
  if (isShellNode(node)) {
    return {
      ...node,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(mergeWithDeviceMode(node, deviceMode, defaultNode) as any),
    }
  }

  if (isCommonNode(node)) {
    return {
      ...node,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      style: mergeWithDeviceMode(node.style, deviceMode, defaultNode.style) as any,
    }
  }

  return
}

export const nodeWithParentDeviceMode = (node: LayoutNode, deviceMode: DeviceMode) =>
  nodeWithDeviceMode(node, getParentDeviceMode(deviceMode) || DeviceMode.desktop)

const getPathUnsafe = (
  block: Block,
  findId: string,
  currentId = block.schema.rootId,
): string[] | void => {
  if (currentId === findId) {
    return [findId]
  }

  const children = getFullChildrenIds(block, currentId)
  for (const id of children) {
    const path = getPathUnsafe(block, findId, id)
    if (path) {
      return [currentId, ...path]
    }
  }
}

export const getPath = (block: Block, nodeId: string): string[] =>
  getPathUnsafe(block, nodeId) || []

export const getPathNodes = (block: Block, nodeId: string): LayoutNode[] =>
  getPath(block, nodeId).map((id) => getNode(block, id) as LayoutNode)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNodeElement = (block: Block, nodeId: string): any => {
  const node = getNode(block, nodeId)
  if (isShellNode(node)) {
    return node.elementId && getElement(block, node.elementId)
  }
}

export const getNodeShell = (block: Block, nodeId: string) => {
  const node = getNode(block, nodeId)
  const shells = node?.children.map((id) => getNode(block, id)).filter(isShellNode)
  return shells?.[0]
}

export const hasContent = (block: Block, id: string) => {
  const node = getNode(block, id)
  const children = node?.children.map((id) => getNode(block, id))
  return children?.some((node) => {
    if (isCommonNode(node)) {
      return true
    }

    if (isShellNode(node)) {
      return node?.elementId
    }

    return false
  })
}

export const getNodeWithFullChildren = (
  block: Block,
  id: string,
  options?: { deviceMode: DeviceMode },
): LayoutNode[] => {
  const node = block.schema.nodes[id]

  if (!node) {
    return []
  }

  if (options?.deviceMode) {
    const hidden = isCommonNode(node) && node.style[options.deviceMode]?.display === 'none'
    if (hidden) {
      return []
    }
  }
  const children = getFullChildrenIds(block, id)
  const childrenNodes = children.flatMap((id) => getNodeWithFullChildren(block, id, options)) || []
  return [node, ...childrenNodes]
}

export const getNodeUsages = (block: Block, options?: { deviceMode: DeviceMode }) => {
  const { rootId } = block.schema

  const usedNodes: LayoutNode[] = getNodeWithFullChildren(block, rootId, options)
  const shells: ShellNode[] = usedNodes.filter(isShellNode)

  const usedElementIds: string[] = shells.flatMap((shell) => shell?.elementId).filter(notEmpty)
  const notUsedNodes = Object.values(
    lodash.omit(
      block.schema.nodes,
      usedNodes.map((node) => node.id),
    ),
  )
  const notUsedElements = Object.entries(lodash.omit(block.elements, usedElementIds)).map(
    ([id, data]) => ({ ...data, id }),
  )

  const usedElements = Object.entries(lodash.pick(block.elements, usedElementIds)).map(
    ([id, data]) => ({ ...data, id }),
  )

  return {
    shells,
    usedElements,
    notUsedElements,
    notUsedNodes,
  }
}

export const getStashedElements = (block: Block) => {
  const { rootId } = block.schema
  const usedNodes: LayoutNode[] = getNodeWithFullChildren(block, rootId)
  const shells: ShellNode[] = usedNodes.filter(isShellNode)
  const usedElementIds: string[] = shells.flatMap((shell) => shell?.elementId).filter(notEmpty)
  return lodash.omit(block.elements, usedElementIds)
}

export const getFullChildrenIds = (block: Block, id: string): string[] => {
  const node = getNode(block, id)
  if (isShellNode(node)) {
    if (node?.elementId) {
      const element = getElement(block, node.elementId)
      if (ELEMENTS_WITH_CHILDREN.includes(element?.type)) {
        const ids = element.value.items
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((item: any) => {
            if (!item) {
              console.warn(
                `ERROR IN ELEMENT: section:${block?.sectionId}, block:${block?.uuid}, element:${element?.id}`,
              )
            }

            return item?.value
          })
          .filter(notEmpty)
        return ids
      }
    }
  }

  return node?.children || []
}

export const getDeepChildrenIds = (block?: Block | null, id?: string): string[] => {
  const node = getNodeSafe(block, id)
  if (isShellNode(node)) {
    const element = getElementByNode(block, node)
    if (element && ELEMENTS_WITH_CHILDREN.includes(element?.type)) {
      const ids = element.value.items.map((item: { value: string }) => item.value)
      return [node.id, ...ids.flatMap((id: string) => getDeepChildrenIds(block, id))]
    } else {
      return [node.id]
    }
  } else if (node?.children) {
    return [...node.children, ...node.children.flatMap((id) => getDeepChildrenIds(block, id))]
  }

  return []
}

export const getDeepCloneNode = (block: Block, id: string) => {
  const mapId = idMapper()
  const childNodes = getNodeWithFullChildren(block, id)
  const elements = childNodes.map((node) => getNodeElement(block, node.id)).filter(notEmpty)
  return {
    nodes: childNodes.map((node) => cloneNode(node, mapId)),
    elements: elements.map((element) => cloneElement(element, mapId)),
    id: mapId(id),
  }
}

export const getFillShells = (block: Block, nodeId: string) => {
  const getNodeWithChildren = (block: Block, id: string): LayoutNode[] => {
    const node = block.schema.nodes[id]
    if (!node) {
      return []
    }

    const children = isShellNode(node) ? [] : node.children
    const childrenNodes = children.flatMap((id) => getNodeWithChildren(block, id)) || []
    return [node, ...childrenNodes]
  }
  const plainNodes = getNodeWithChildren(block, nodeId)
  const shells: ShellNode[] = plainNodes.filter(isShellNode).filter((shell) => !shell.hideOnFill)
  return shells
}

export const getBlockWidth = (block: Block, device = DeviceMode.desktop) =>
  R.pathOr<number | null>(
    null,
    ['schema', 'nodes', block.schema.rootId, 'style', device, 'maxWidth'],
    block,
  )
