import cn from 'classnames'

import { FieldLayoutType } from '../Field.types'
import * as s from './FieldLayout.module.scss'

interface IProps {
  label?: string | JSX.Element
  layout?: FieldLayoutType | FieldLayoutType[]
  children?: React.ReactNode
}

const FieldGroup: React.FC<IProps> = ({ children, label, layout }) => {
  const layouts = layout ? (Array.isArray(layout) ? layout.map((item) => s[item]) : s[layout]) : ''

  return (
    <div className={cn(s.root, layouts)}>
      {Boolean(label) && <label className={s.label}>{label}</label>}
      <div className={cn(s.input)}>{children}</div>
    </div>
  )
}

export default FieldGroup
