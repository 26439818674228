import { LEVEL_INDENT, LINE_CHAPTER_OFFSET } from '../../constants'
import * as s from './LineVerticalComponent.module.scss'

interface ILineVerticalComponent {
  lvl: number
  open?: boolean
  children?: React.ReactNode
}

const LineVerticalComponent: React.FC<ILineVerticalComponent> = ({ children, lvl, open }) => (
  <>
    {open && (
      <div className={s.content} style={{ left: lvl * LEVEL_INDENT + LINE_CHAPTER_OFFSET }} />
    )}
    {children}
  </>
)

export default LineVerticalComponent
