import { CardSchemaType } from '@vedalib/editor/lib/brand'
import styled, { css } from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const IconStyled = styled.div<{
  $styles: ElementStyleCss<CardSchemaType>
  $isBack: boolean
  $hover: boolean
}>`
  position: absolute;
  display: flex;
  width: ${({ $styles }) => $styles.icon.width || '40px'};
  height: ${({ $styles }) => $styles.icon.height || '40px'};
  top: 16px;
  left: 16px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 24px;
  cursor: pointer;
  backface-visibility: hidden;
  transition: 0.2s;
  ${({ $styles, $isBack }) =>
    $isBack
      ? css`
          background-color: ${$styles.sideBIcon.backgroundColor};
          color: ${$styles.sideBIcon.color};
        `
      : css`
          background-color: ${$styles.sideAIcon.backgroundColor};
          color: ${$styles.sideAIcon.color};
        `};

  &:hover {
    cursor: pointer;
    ${({ $styles, $hover }) => $hover && `background-color:${$styles.hover.backgroundColor}`};
  }
`

export default IconStyled
