import React, { useCallback } from 'react'

import { usePickElement } from 'components/editor-v3/Editor/Modals/usePickElement'
import { ActionsConfig, ColumnActionUiEnum } from 'components/editor-v3/Editor/types'
import AbsolutePortal from 'components/editor-v3/components/AbsolutePortal'
import { ABSOLUTE_PORTAL_GAP } from 'components/editor-v3/components/AbsolutePortal/constants'
import {
  addAbsolute,
  addColumn,
  addRow,
  removeNode,
  splitHorizontal,
  splitVertical,
  UpdateBlockResult,
} from 'components/editor-v3/context/EditorContext/mutators/block'
import { InsertPlace } from 'components/editor-v3/types/date.enums'
import { IconButton } from 'components/uiKit/Button'
import { KitSize } from 'components/uiKit/KitTypes'
import { optionToIconButton } from 'components/uiKit/Menu'
import { NOOP } from 'constants/commonConstans'
import { setProjectNavigation, updateBlock } from 'services/Store/Project/actions'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getToolbar } from 'services/Store/Project/selectors'
import { Block } from 'services/Store/Project/types'
import { getEnumOption } from 'utils/enum'

import ControlItem from '../ControlItem'
import ControlsGroup from '../ControlsGroup'
import Separator from '../Separator'

interface ICellsManagementControlsProps {
  nodeId: string
  controls: ColumnActionUiEnum[]
  block: Block
}

const COLUMN_ACTIONS: ActionsConfig<ColumnActionUiEnum>['options'] = {
  [ColumnActionUiEnum.ADD_ROW_ABOVE]: getEnumOption(
    'ColumnActionUiEnum',
    ColumnActionUiEnum.ADD_ROW_ABOVE,
    {
      icon: 'otherAdd',
    },
  ),
  [ColumnActionUiEnum.ADD_ROW_BELOW]: getEnumOption(
    'ColumnActionUiEnum',
    ColumnActionUiEnum.ADD_ROW_BELOW,
    {
      icon: 'otherAdd',
    },
  ),
  [ColumnActionUiEnum.ADD_COL_PREV]: getEnumOption(
    'ColumnActionUiEnum',
    ColumnActionUiEnum.ADD_COL_PREV,
    {
      icon: 'otherAdd',
    },
  ),
  [ColumnActionUiEnum.ADD_COL_NEXT]: getEnumOption(
    'ColumnActionUiEnum',
    ColumnActionUiEnum.ADD_COL_NEXT,
    {
      icon: 'otherAdd',
    },
  ),
  [ColumnActionUiEnum.SPLIT_VERTICAL]: getEnumOption(
    'ColumnActionUiEnum',
    ColumnActionUiEnum.SPLIT_VERTICAL,
    {
      icon: 'builderColumn',
    },
  ),
  [ColumnActionUiEnum.SPLIT_HORIZONTAL]: getEnumOption(
    'ColumnActionUiEnum',
    ColumnActionUiEnum.SPLIT_HORIZONTAL,
    {
      icon: 'builderRow',
    },
  ),
  [ColumnActionUiEnum.ADD_ABSOLUTE]: getEnumOption(
    'ColumnActionUiEnum',
    ColumnActionUiEnum.ADD_ABSOLUTE,
    {
      icon: 'builderAbsolut',
    },
  ),
  [ColumnActionUiEnum.ADD_SHELL]: getEnumOption(
    'ColumnActionUiEnum',
    ColumnActionUiEnum.ADD_SHELL,
    {
      icon: 'builderAdd',
    },
  ),
  [ColumnActionUiEnum.DELETE]: getEnumOption('ColumnActionUiEnum', ColumnActionUiEnum.DELETE, {
    icon: 'otherTrash',
  }),
}

const useColumnActions = ({
  nodeId,
  block,
  controls,
}: Pick<
  ICellsManagementControlsProps,
  'nodeId' | 'block' | 'controls'
>): ActionsConfig<ColumnActionUiEnum> => {
  const dispatch = useProjectDispatch()
  const toolbar = useProjectContext(getToolbar)
  const onChange = useCallback(
    (block: Block) => dispatch(updateBlock({ id: block?.uuid, value: block })),
    [dispatch],
  )
  const pickElement = usePickElement(block, nodeId)

  const onUpdate = useCallback(
    (update: UpdateBlockResult) => {
      onChange(update.block)
      if (update.nodeId) {
        dispatch(setProjectNavigation({ nodeId: update.nodeId }))
      }
    },
    [onChange, dispatch],
  )

  const onAddRowAbove = useCallback(
    () => onUpdate(addRow(block, nodeId, InsertPlace.above)),
    [onUpdate, block, nodeId],
  )
  const onAddColPrev = useCallback(
    () => onUpdate(addColumn(block, nodeId, InsertPlace.above)),
    [onUpdate, block, nodeId],
  )
  const onAddColNext = useCallback(
    () => onUpdate(addColumn(block, nodeId, InsertPlace.below)),
    [onUpdate, block, nodeId],
  )
  const onAddRowBelow = useCallback(
    () => onUpdate(addRow(block, nodeId, InsertPlace.below)),
    [onUpdate, block, nodeId],
  )

  const onSplitVertical = useCallback(
    () => onUpdate(splitVertical(block, nodeId)),
    [onUpdate, block, nodeId],
  )
  const onSplitHorizontal = useCallback(
    () => onUpdate(splitHorizontal(block, nodeId)),
    [onUpdate, block, nodeId],
  )
  const onAddAbsolute = useCallback(
    () => onUpdate(addAbsolute(block, nodeId)),
    [onUpdate, block, nodeId],
  )

  const onAddShell = useCallback(() => pickElement(), [pickElement])

  const onDelete = useCallback(
    async (update: UpdateBlockResult, e?: React.MouseEvent) => {
      e?.stopPropagation()
      onChange(update.block)
    },
    [onChange],
  )

  const onRemoveCell = useCallback(
    () => onDelete(removeNode(block, nodeId)),
    [onDelete, block, nodeId],
  )

  if (toolbar.commentTab) {
    return {
      availableActions: [],
      options: COLUMN_ACTIONS,
      handleAction: NOOP,
      hasAction: () => false,
    }
  }

  const actionHandlers: { [key in ColumnActionUiEnum]: () => void } = {
    [ColumnActionUiEnum.ADD_ROW_ABOVE]: onAddRowAbove,
    [ColumnActionUiEnum.ADD_ROW_BELOW]: onAddRowBelow,
    [ColumnActionUiEnum.ADD_COL_PREV]: onAddColPrev,
    [ColumnActionUiEnum.ADD_COL_NEXT]: onAddColNext,
    [ColumnActionUiEnum.SPLIT_VERTICAL]: onSplitVertical,
    [ColumnActionUiEnum.SPLIT_HORIZONTAL]: onSplitHorizontal,
    [ColumnActionUiEnum.ADD_ABSOLUTE]: onAddAbsolute,
    [ColumnActionUiEnum.ADD_SHELL]: onAddShell,
    [ColumnActionUiEnum.DELETE]: onRemoveCell,
  }
  const hasAction = (...actions: ColumnActionUiEnum[]) => {
    return actions.some((action) => controls.includes(action))
  }
  const handleAction = ({ value }: { value: ColumnActionUiEnum }) => {
    actionHandlers[value]()
  }
  return {
    availableActions: controls,
    options: COLUMN_ACTIONS,
    handleAction,
    hasAction,
  }
}

const ColumnControls: React.FC<ICellsManagementControlsProps> = ({ nodeId, controls, block }) => {
  const { hasAction, handleAction, options } = useColumnActions({ nodeId, block, controls })
  const showGroup = hasAction(
    ColumnActionUiEnum.DELETE,
    ColumnActionUiEnum.SPLIT_HORIZONTAL,
    ColumnActionUiEnum.SPLIT_VERTICAL,
    ColumnActionUiEnum.ADD_ABSOLUTE,
    ColumnActionUiEnum.ADD_SHELL,
  )

  return (
    <>
      {hasAction(ColumnActionUiEnum.ADD_COL_NEXT) && (
        <>
          <AbsolutePortal
            name={ColumnActionUiEnum.ADD_ROW_ABOVE}
            placement='top'
            translateX='-50%'
            translateY='-50%'
            zIndex={1}
          >
            <IconButton
              {...optionToIconButton(options[ColumnActionUiEnum.ADD_ROW_ABOVE], handleAction)}
              size={KitSize.XS}
              styleType='accent'
              round
            />
          </AbsolutePortal>
          <AbsolutePortal
            name={ColumnActionUiEnum.ADD_ROW_BELOW}
            placement='bottom'
            translateX='-50%'
            translateY='-50%'
            zIndex={1}
          >
            <IconButton
              {...optionToIconButton(options[ColumnActionUiEnum.ADD_ROW_BELOW], handleAction)}
              size={KitSize.XS}
              styleType='accent'
              round
            />
          </AbsolutePortal>
          <AbsolutePortal
            name={ColumnActionUiEnum.ADD_COL_PREV}
            placement='left'
            translateX='-50%'
            translateY='-50%'
            zIndex={1}
          >
            <IconButton
              {...optionToIconButton(options[ColumnActionUiEnum.ADD_COL_PREV], handleAction)}
              size={KitSize.XS}
              styleType='accent'
              round
            />
          </AbsolutePortal>
          <AbsolutePortal
            name={ColumnActionUiEnum.ADD_COL_NEXT}
            placement='right'
            translateX='-50%'
            translateY='-50%'
            zIndex={1}
          >
            <IconButton
              {...optionToIconButton(options[ColumnActionUiEnum.ADD_COL_NEXT], handleAction)}
              size={KitSize.XS}
              styleType='accent'
              round
            />
          </AbsolutePortal>
        </>
      )}
      {showGroup && (
        <AbsolutePortal
          name='colControls'
          offset={`${ABSOLUTE_PORTAL_GAP}px 0`}
          placement='bottomLeft'
        >
          <ControlsGroup>
            {hasAction(ColumnActionUiEnum.DELETE) && (
              <>
                <ControlItem
                  handler={handleAction}
                  option={COLUMN_ACTIONS[ColumnActionUiEnum.DELETE]}
                />
                <Separator />
              </>
            )}
            {hasAction(ColumnActionUiEnum.SPLIT_VERTICAL, ColumnActionUiEnum.SPLIT_HORIZONTAL) && (
              <>
                <ControlItem
                  handler={handleAction}
                  option={options[ColumnActionUiEnum.SPLIT_VERTICAL]}
                />
                <ControlItem
                  handler={handleAction}
                  option={options[ColumnActionUiEnum.SPLIT_HORIZONTAL]}
                />
              </>
            )}
            {hasAction(ColumnActionUiEnum.ADD_ABSOLUTE) && (
              <ControlItem
                handler={handleAction}
                option={options[ColumnActionUiEnum.ADD_ABSOLUTE]}
              />
            )}
            {hasAction(ColumnActionUiEnum.ADD_SHELL) && (
              <>
                <Separator />
                <ControlItem
                  handler={handleAction}
                  option={options[ColumnActionUiEnum.ADD_SHELL]}
                />
              </>
            )}
          </ControlsGroup>
        </AbsolutePortal>
      )}
    </>
  )
}

export default ColumnControls
