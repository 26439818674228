import { useApolloClient } from '@apollo/client'
import { getCoreWsLink } from 'gql'
import React, { useEffect } from 'react'
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'

import Lazy from 'components/lazy'
import { PATHS } from 'constants/paths'
import { useGetToken } from 'gql/kusers/apollo'
import generateLink from 'routes/generateLink'

export const UserRoute: React.FC<RouteProps> = (props) => {
  const location = useLocation()
  const { data: tokenData, loading } = useGetToken()
  const client = useApolloClient()

  useEffect(() => {
    if (tokenData?.token) {
      client.setLink(getCoreWsLink(tokenData.token))
    }
  }, [tokenData, client])

  const redirectLink = generateLink(PATHS.auth, {
    search: `?redirectTo=${encodeURIComponent(location.pathname)}`,
  })

  const isLogged =
    tokenData?.token ||
    Boolean(sessionStorage.getItem('visitorToken')) ||
    Boolean(sessionStorage.getItem('token'))

  if (loading) {
    return null
  }

  if (!isLogged) {
    return <Redirect to={redirectLink} />
  }

  return <Route {...props} />
}

const App = Lazy(() => import('./App.routes' /* webpackChunkName:  "private-app-routes" */))

export default App
