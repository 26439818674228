import { Placement } from '@floating-ui/react'
import { AnimatePresence, MotionProps } from 'framer-motion'
import { ReactNode, useCallback, useEffect, useId, useMemo, useRef, useState } from 'react'
import React from 'react'

import DropDownContent from './DropdownCourseContent'
import { DropDownCourseContext, useDropDownCourseContext } from './context'
import { useAlign, useEvents, useGetCloneElement } from './hooks'
import BindingStyled from './styled/BindingStyled'
import { FnChild, IChildrenHandler } from './types'

type DropdownRootAnimationProps = Pick<
  MotionProps,
  'whileTap' | 'whileHover' | 'onAnimationComplete' | 'transition' | 'variants'
>

export type DropdownAnimationProps = Pick<MotionProps, 'initial' | 'animate' | 'exit'>

export interface IDropdownCourseContent extends DropdownAnimationProps, DropdownRootAnimationProps {
  children: IChildrenHandler | FnChild
  overlay?: ReactNode
  defaultPopupVisible?: boolean
  visible?: boolean
  onVisibleChange?: (visible: boolean) => void
  name?: string
  disabled?: boolean
  overlayStyle?: React.CSSProperties
  container?: HTMLElement
  delay?: number
  placement?: Placement
  offsets?: {
    x?: number
    y?: number
  }
  type?: 'dropdown' | 'tooltip'
  theme?: 'light' | 'dark' | 'warning' | 'purple'
  fluid?: boolean
  styleType?: 'default' | 'clear'
}

const ALIGN_CONNFIG = { x: 0, y: 0 }

const DropdownCourse: React.FC<IDropdownCourseContent> = ({
  children,
  overlay,
  defaultPopupVisible = false,
  visible = false,
  onVisibleChange,
  name,
  disabled,
  overlayStyle,
  delay,
  placement = 'bottom-start',
  whileTap,
  whileHover,
  offsets = ALIGN_CONNFIG,
  type = 'dropdown',
  fluid,
  ...rest
}) => {
  const id = useId()
  const { subPopupElements, context } = useDropDownCourseContext()
  const bindingRef = useRef<HTMLDivElement>(null)
  const targetEle = (bindingRef.current?.nextElementSibling || bindingRef.current) as HTMLElement
  const [internalOpen, setInternalOpen] = useState(defaultPopupVisible)
  const [popupEle, setPopupEle] = useState<HTMLDivElement | null>(null)
  const mergedOpen = visible || internalOpen

  // Delay if we are have some action inner oberlay, but dropdown close faster
  const delayRef = React.useRef<NodeJS.Timeout | null>()
  const clearDelay = useCallback(() => {
    if (delayRef.current) {
      clearTimeout(delayRef.current)
      delayRef.current = null
    }
  }, [])
  //

  const triggerOpenBase = useCallback(
    (nextOpen: boolean, disabled?: boolean) => {
      if (mergedOpen !== nextOpen && ((!disabled && nextOpen) || !nextOpen)) {
        onVisibleChange?.(nextOpen)
        setInternalOpen(nextOpen)
      }
    },
    [mergedOpen, onVisibleChange],
  )

  const triggerOpen = useCallback(
    (nextOpen: boolean) => {
      if (delay) {
        clearDelay()
        delayRef.current = setTimeout(() => {
          triggerOpenBase(nextOpen, disabled)
        }, delay)
      } else {
        triggerOpenBase(nextOpen, disabled)
      }
    },
    [delay, clearDelay, triggerOpenBase, disabled],
  )

  const setPopupRef = useCallback((node: HTMLDivElement) => {
    if (node instanceof HTMLElement) {
      setPopupEle(node)
      context?.registerSubPopup(id, node)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const inPopupOrChild = useCallback(
    (element: Node) => {
      return (
        targetEle?.contains(element) ||
        element === targetEle ||
        popupEle?.contains(element) ||
        element === popupEle ||
        (subPopupElements &&
          Object.values(subPopupElements.current).some(
            (subPopupEle) => subPopupEle?.contains(element) || element === subPopupEle,
          ))
      )
    },
    [popupEle, subPopupElements, targetEle],
  )

  // recalculate popup position and change Resize browser
  const popupStyle = useAlign({
    targetEle,
    popupEle,
    alignConfig: offsets,
    placement,
    triggerOpen,
    fluid,
    open: mergedOpen,
  })

  const childNode = useGetCloneElement({ type, children, mergedOpen, triggerOpen })

  const mergedStyles = useMemo(
    () => ({ ...popupStyle, ...overlayStyle }),
    [overlayStyle, popupStyle],
  )

  // close on esc, click outside and over if hover
  useEvents({ inPopupOrChild, triggerOpen, mergedOpen, popupEle, type })

  useEffect(() => {
    clearDelay()
    return () => {
      context?.unregisterSubPopup(id)
    }
  }, [clearDelay, context, id])

  useEffect(() => {
    triggerOpenBase(visible)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  return (
    <>
      <BindingStyled ref={bindingRef} />
      {childNode}
      <DropDownCourseContext.Provider value={context}>
        <AnimatePresence>
          {mergedOpen && overlay && (
            <DropDownContent
              {...rest}
              disbale={disabled}
              ref={setPopupRef}
              // eslint-disable-next-line react/forbid-component-props
              style={mergedStyles}
              type={type}
            >
              {overlay}
            </DropDownContent>
          )}
        </AnimatePresence>
      </DropDownCourseContext.Provider>
    </>
  )
}
export default DropdownCourse
