// extracted by mini-css-extract-plugin
var _1 = "EmployeesGroup_avatar__OeUUJ";
var _2 = "EmployeesGroup_avatarBorder__ALp6g";
var _3 = "EmployeesGroup_base__-0tTx";
var _4 = "EmployeesGroup_editorHeader__mvvgY";
var _5 = "EmployeesGroup_large__BGdRp";
var _6 = "EmployeesGroup_medium__APfKM";
var _7 = "EmployeesGroup_mentionOverflow__QD5hP";
var _8 = "EmployeesGroup_mentioned__TTAPh";
var _9 = "EmployeesGroup_mini__dvAEz";
var _a = "EmployeesGroup_overflow__sYHXP";
var _b = "EmployeesGroup_overflowList__3-uxN";
var _c = "EmployeesGroup_overflowShifted__p2ia1";
var _d = "EmployeesGroup_overlayContainer__fR-zI";
var _e = "EmployeesGroup_overlayShifted__ITsV4";
var _f = "EmployeesGroup_shifted__VQld8";
export { _1 as "avatar", _2 as "avatarBorder", _3 as "base", _4 as "editorHeader", _5 as "large", _6 as "medium", _7 as "mentionOverflow", _8 as "mentioned", _9 as "mini", _a as "overflow", _b as "overflowList", _c as "overflowShifted", _d as "overlayContainer", _e as "overlayShifted", _f as "shifted" }
