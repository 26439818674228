import { CONFIG_ELEMENTS } from '@vedalib/editor/lib/elements'
import lodash from 'lodash'
import * as R from 'ramda'
import { useMemo } from 'react'

import { getNodeUsages } from 'components/editor-v3/context/EditorContext/selectors/block'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getBlocksMap, getSection } from 'services/Store/Project/selectors'

const MAX_VALUE = 99

export const useDividerNumber = (id: string) => {
  const section = useProjectContext(getSection)
  const blocksMap = useProjectContext(getBlocksMap)

  const resMap = useMemo(() => {
    const blocks = R.values(lodash.pick(blocksMap, section?.blocksOrder || []))
    const dividers = (blocks || []).flatMap((block) =>
      getNodeUsages(block).usedElements.filter(
        (element) => element.type === CONFIG_ELEMENTS.divider.type && element.value.isNumbered,
      ),
    )
    const resMap: Record<string, string> = {}
    let lastNumber = 0
    for (let i = 0; i < dividers.length; i++) {
      const element = dividers[i]
      const parsedNum = parseInt(element.value.dividerNumber, 10)

      const num =
        parsedNum === 0
          ? '0'
          : Math.min(parsedNum, MAX_VALUE) || Math.min(lastNumber + 1, MAX_VALUE)

      lastNumber = Number(num)
      resMap[element.id] = element.value.dividerNumber || num.toString()
    }
    return resMap
  }, [blocksMap, section])

  return resMap[id]
}
