import cn from 'classnames'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import React, { useRef, useCallback, useLayoutEffect } from 'react'

import { LayoutScroll } from 'components/LayoutPage'

import { generateNumbers, getMinMaxValues } from '../helper'
import { InternalTime, TimeUnit, TimeFormat } from '../types'
import * as s from './TimePickerDropdownContent.module.scss'

interface ITimePickerDropdownContent {
  setNewValue: (value: InternalTime) => void
  value: InternalTime
  format: TimeFormat
  maxTime?: number | null
  minTime?: number | null
}

const CELL_HEIGHT = 26

const TimePickerDropdownContent = ({
  setNewValue,
  value,
  format = 'HH:mm:ss',
  maxTime,
  minTime = 0,
}: ITimePickerDropdownContent) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columnsRef = useRef<Record<TimeUnit, OverlayScrollbarsComponent | null>>({} as any)
  const { minValue, maxValue } = getMinMaxValues({ maxTime, minTime, selectedTime: value })
  const columnTypes = format.split(':') as TimeUnit[]

  const shiftColumn = useCallback((time: number, unitType: TimeUnit) => {
    const column = columnsRef.current[unitType]?.osInstance()
    if (column) {
      column.scroll({ top: time * CELL_HEIGHT }, { behavior: 'smooth' })
    }
  }, [])

  const handleCellClick = (time: number, unitType: TimeUnit) => {
    const newVal = { ...value, [unitType]: time }
    setNewValue(newVal)
  }

  useLayoutEffect(() => {
    columnTypes.forEach((type) => {
      shiftColumn(value[type] - (minTime || 0), type)
    })
  }, [columnTypes, minTime, value, shiftColumn])

  const renderColumn = (timeUnits: string[], unitType: TimeUnit) => (
    <LayoutScroll key={unitType} ref={(ref) => (columnsRef.current[unitType] = ref)}>
      {timeUnits.map((timeUnit) => (
        <div
          className={cn(s.cell, { [s.selected]: value[unitType] === Number(timeUnit) })}
          key={timeUnit}
          onClick={() => handleCellClick(Number(timeUnit), unitType)}
        >
          {timeUnit}
        </div>
      ))}

      <div className={s.lastFlex}></div>
    </LayoutScroll>
  )

  return (
    <div className={s.timeWrapper}>
      {columnTypes.map((type) =>
        renderColumn(
          generateNumbers((maxTime && maxValue[type]) ?? (type === 'HH' ? 23 : 59), minValue[type]),
          type,
        ),
      )}
    </div>
  )
}

export default React.memo(TimePickerDropdownContent)
