"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.createItem = exports.TYPE = void 0;
const id_1 = require("../../../utils/id");
const constants_1 = require("../../constants");
exports.TYPE = 'testHotspot';
const createItem = () => ({ id: (0, id_1.generateId)(), x: 30, y: 50, width: 60, height: 30 });
exports.createItem = createItem;
exports.DEFAULT_VALUE = {
    response: 'all',
    image: constants_1.imageValueWithObjectFit,
    type: 'icon',
    items: [(0, exports.createItem)()],
    overlay: '#FFFFFF00',
};
