import cn from 'classnames'
import React from 'react'

import Icon, { KeyIconsType } from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import Loader from 'components/uiKit/Loader'
import { testProps } from 'utils/test/qaData'

import { IButtonPropsType } from '../Button'
import * as s from '../Button.module.scss'

/**
 * Props for the IconButton component.
 */
export interface IIconButtonProps extends Omit<IButtonPropsType, 'children' | 'fluid'> {
  /**
   * The icon to display in the button.
   */
  icon: KeyIconsType
  /**
   * The custom size of the icon inside button.
   */
  iconSize?: KitSize
  /**
   * Optional test data attribute to be added to the IconButton.
   */
  testData?: string
  /**
   * Animation transform
   */
  transform?: React.CSSProperties['transform']
}

const IconButton = React.forwardRef<HTMLButtonElement, IIconButtonProps>(
  (
    {
      icon,
      iconSize,
      testData,
      name,
      active,
      size = KitSize.M,
      styleType = 'primary',
      loading,
      round,
      disabled,
      type = 'button',
      inheritColor,
      transform,

      ...htmlProps
    },
    ref,
  ) => {
    const { value, onClick, className, tabIndex, style, ...restHtmlProps } = htmlProps

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
      !disabled && onClick?.(e)

    const buttonClasses = cn(s.button, className, s[styleType], s[size], s[`icon-${size}`], {
      [s.loading]: loading,
      [s.disabled]: disabled,
      [s.active]: active,
      [s.round]: round,
      [s.inheritColor]: inheritColor,
    })

    return (
      <button
        {...restHtmlProps}
        {...testProps({ el: 'button', name, value, testData, disabled: Boolean(disabled) })}
        className={buttonClasses}
        name={name}
        onClick={handleClick}
        ref={ref}
        style={{ ...style, transform }}
        tabIndex={disabled ? -1 : tabIndex || 0}
        type={type}
        value={value}
      >
        {loading ? (
          <Loader
            color={styleType === 'primary' || styleType === 'accent' ? 'background' : 'primary'}
            name='buttonLoader'
            styleType='svg'
          />
        ) : (
          <Icon name={icon} size={iconSize || size} />
        )}
      </button>
    )
  },
)

IconButton.displayName = 'IconButton'

export default IconButton
