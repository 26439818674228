import { CONFIG_ELEMENTS } from '@vedalib/editor/lib/elements'

import EditableProMode from 'components/editor-v3/cource/layout/controls/ElementControls/EditableProMode'
import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './GroupingBrandFormSchema'
import Demo from './GroupingDemo'
import Element from './GroupingElement'
import Form from './GroupingForm'
import groupingIcon from './assets/grouping.svg'

export default genElementConfig(CONFIG_ELEMENTS.grouping, {
  label: t('elements.grouping.label'),
  icon: groupingIcon,
  group: ElementGroup.interactive,
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  Demo,
  EditorActions: EditableProMode,
})
