import { CONFIG_ELEMENTS } from '@vedalib/editor/lib/elements'

import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './ButtonBrandFormSchema'
import ButtonDemo from './ButtonDemo'
import Element from './ButtonElement'
import Form from './ButtonForm'
import buttonIcon from './assets/button.svg'

export default genElementConfig(CONFIG_ELEMENTS.button, {
  label: t('elements.button.label'),
  icon: buttonIcon,
  group: ElementGroup.view,
  Element,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  ValueForm: Form,
  Demo: ButtonDemo,
})
