import cn from 'classnames'
import React from 'react'

import * as s from './Group.module.scss'

type Child<P extends { style: React.CSSProperties }> =
  | React.ReactElement<P>
  | boolean
  | null
  | undefined

/**
 * Props for the Group component.
 */
interface IGroupProps<P extends { style: React.CSSProperties }> {
  /**
   * The child elements of the button group.
   */
  children?: Child<P> | Child<P>[]
  join?: boolean
}

const Group = <P extends { style: React.CSSProperties }>({ children, join }: IGroupProps<P>) => {
  const childrenArray = React.Children.toArray(children).filter(React.isValidElement<P>)

  return <div className={cn(s.root, { [s.join]: join })}>{childrenArray}</div>
}

export default Group
