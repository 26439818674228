import { TestMatchingFontSchemaType, TestMatchingSchemaType } from '@vedalib/editor/lib/brand'
import { TestMatchingElementValue, RichTextElementValue } from '@vedalib/editor/lib/elements'
import { createContext } from 'use-context-selector'

// import { RTStyles } from 'components/form/RichText/richText.types'
import { NOOP } from 'constants/commonConstans'
import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'
import { PREVIEW_MODE } from 'services/Store/Project/constants'
import { Block, IBlockMode } from 'services/Store/Project/types'

import { MatchingGroups, TestMatchingStateType } from '../TestMatchingElement.types'

interface ITestMatchingContextProps {
  left: MatchingGroups[]
  right: MatchingGroups[]
  elementId: string
  draggableId?: string
  setDraggableId: React.Dispatch<React.SetStateAction<string | undefined>>
  validOrderMap: {
    [id: string]: number
  }
  isDisabled: boolean
  isVisible: boolean
  isMobile: boolean
  styles: ElementStyleCss<TestMatchingSchemaType>
  font: ElementFontCss<TestMatchingFontSchemaType>
  block?: Block | null
  value: TestMatchingElementValue
  mode: IBlockMode
  state?: TestMatchingStateType
  isActiveElement: boolean
  activeIndex: string
  onChangeLabel: (label: RichTextElementValue, side: string, index: number) => void
  onLabelSelect: (index: string, e: React.MouseEvent<Element, MouseEvent>) => void
  isFill: boolean
  onChange?: (value: TestMatchingElementValue) => void
  // rtStyles: RTStyles
  waiting?: boolean
}

const TestMatchingContext = createContext<ITestMatchingContextProps>({
  left: [],
  right: [],
  elementId: '',
  draggableId: '',
  setDraggableId: NOOP,
  validOrderMap: {},
  isDisabled: false,
  isVisible: false,
  isMobile: false,
  font: {} as ElementFontCss<TestMatchingFontSchemaType>,
  styles: {} as ElementStyleCss<TestMatchingSchemaType>,
  block: {} as Block,
  value: {} as TestMatchingElementValue,
  mode: PREVIEW_MODE,
  isActiveElement: false,
  activeIndex: '',
  onChangeLabel: NOOP,
  onLabelSelect: NOOP,
  isFill: false,
  // rtStyles: null as any,
  waiting: false,
})

export default TestMatchingContext
