import { useMemo } from 'react'

import Checkbox from 'components/uiKit/Checkbox'

import { ITablesExtendsData } from '../types'

interface IHeaderCheckboxProps<T extends ITablesExtendsData> {
  selected?: T[]
  dataSource: T[]
  onChangeSelection?: (records: T[]) => void
  getCheckboxProps?: (record: T) => { disabled: boolean }
}

const HeaderCheckbox = <T extends ITablesExtendsData>({
  onChangeSelection,
  dataSource,
  selected,
  getCheckboxProps,
}: IHeaderCheckboxProps<T>) => {
  const enabledDataSource = useMemo(() => {
    if (getCheckboxProps) {
      return dataSource.filter((el) => !getCheckboxProps(el).disabled)
    }

    return dataSource
  }, [dataSource, getCheckboxProps])

  const toggleSelectAll = () => {
    if (selected && onChangeSelection) {
      if (enabledDataSource.length === selected.length) {
        onChangeSelection([])
      } else {
        onChangeSelection(enabledDataSource)
      }
    }
  }

  const indeterminate = Boolean(selected?.length && selected?.length < enabledDataSource.length)
  const value = Boolean(selected?.length && selected.length === enabledDataSource.length)
  const disabled = !enabledDataSource.length

  return (
    <Checkbox
      disabled={disabled}
      indeterminate={indeterminate}
      name='tableAll'
      onChange={toggleSelectAll}
      value={value}
    />
  )
}

export default HeaderCheckbox
