import { CustomElement, DescendantData } from '@vedalib/rich-text'
import React from 'react'
import { Text } from 'slate'

import { IRichTextProps } from './RichText'
import * as s from './RichText.module.scss'
import ElementResolver from './nodes/Elements'
import TextComponent from './nodes/Text'
import { useFontToVars } from './useFontToVars'

const serializeLeaves = (node: DescendantData, index: number): React.ReactNode => {
  if (Text.isText(node)) {
    const nodeText = node.text || '\uFEFF'
    return (
      <TextComponent key={`${index}_${nodeText?.length}_${nodeText[0]}`} leaf={node}>
        {nodeText}
      </TextComponent>
    )
  }
  if (Array.isArray(node)) {
    return node.map(serializeLeaves)
  }

  return serializeElements(node as CustomElement, index)
}

const serializeElements = (node: CustomElement, index: number) => {
  const children = node.children?.map(serializeLeaves)
  return (
    <ElementResolver element={node} key={`${node.type}_${index}_${node.children?.length}`}>
      {children}
    </ElementResolver>
  )
}

type Props = Pick<IRichTextProps, 'value' | 'styles'>

export const RichTextViewer: React.FC<Props> = ({ value, styles }) => {
  const style = useFontToVars(styles)
  return (
    <div className={s.root} style={style}>
      {value?.map(serializeLeaves)}
    </div>
  )
}
