import { CONFIG_ELEMENTS } from '@vedalib/editor/lib/elements'

import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA } from './ImageBrandFormSchema'
import Demo from './ImageDemo'
import Form from './ImageElForm'
import Element from './ImageElView'
import imageIcon from './assets/image.svg'

export default genElementConfig(CONFIG_ELEMENTS.image, {
  label: t('elements.image.label'),
  group: ElementGroup.view,
  icon: imageIcon,
  Element,
  ValueForm: Form,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA: {},
  Demo,
})
