import CountUp from 'react-countup'
import { useContext } from 'use-context-selector'

import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { useProjectT } from 'services/ProjectLabels'

import GroupingContext from '../../GroupingContext'
import * as s from './Result.module.scss'

const Result = () => {
  const pt = useProjectT()
  const { styles, setRetry, testResult, setTestResult, font } = useContext(GroupingContext)
  const totalItems = Object.values(testResult)
  const countSuccess = Object.values(testResult).filter((e) => e)

  const per = Math.round((countSuccess.length / totalItems.length) * 100)

  const onRetryClick = () => {
    setRetry((prev) => prev + 1)
    setTestResult({})
  }

  return (
    <div className={s.root}>
      <div className={s.count} style={font?.h4}>
        <CountUp end={countSuccess.length} /> / {totalItems.length} {pt('elements.grouping.result')}
      </div>
      <div
        className={s.progress}
        style={{ ...styles.progress, borderRadius: styles.progressResult__var.borderRadius }}
      >
        <div
          style={{
            ...styles.progressResult__var,
            width: `${per}%`,
            height: styles.progress.height,
          }}
        />
      </div>
      <div className={s.tryAgain} onClick={onRetryClick}>
        <button className={s.buttonTry} name='try-again'>
          <Icon name='otherUndo' size={KitSize.S} style={{ color: font?.h4?.color }} />
          <div className={s.textTry} style={{ ...font?.h4 }}>
            {pt('elements.grouping.retry')}
          </div>
        </button>
      </div>
    </div>
  )
}

export default Result
