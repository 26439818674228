// extracted by mini-css-extract-plugin
var _1 = "MenuItem_content__-eIMu";
var _2 = "MenuItem_disabled__zOKmo";
var _3 = "MenuItem_group__LAMoB";
var _4 = "MenuItem_groupLabel__cY8oF";
var _5 = "MenuItem_horizontal__+wknG";
var _6 = "MenuItem_hovered__JOdX8";
var _7 = "MenuItem_label__8FdY6";
var _8 = "MenuItem_large__9b1IO";
var _9 = "MenuItem_medium__V0rkS";
var _a = "MenuItem_mini__Je38F";
var _b = "MenuItem_remove__wijc7";
var _c = "MenuItem_root__xNoBE";
var _d = "MenuItem_selected__QtUE1";
var _e = "MenuItem_xmini__LYSYJ";
export { _1 as "content", _2 as "disabled", _3 as "group", _4 as "groupLabel", _5 as "horizontal", _6 as "hovered", _7 as "label", _8 as "large", _9 as "medium", _a as "mini", _b as "remove", _c as "root", _d as "selected", _e as "xmini" }
