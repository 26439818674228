import cn from 'classnames'
import { motion, Variants } from 'framer-motion'
import React from 'react'

import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { testProps } from 'utils/test/qaData'

import { ModalInstanceContext } from '../ModalRender/ModalRender'
import { EXIT_ANIMATION_DURATION, OPEN_ANIMATION_DURATION } from '../contants'
import * as s from './Modal.module.scss'

interface IContainerModalProps {
  styleType: 'dialog' | 'free' | 'tabs' | 'crop' | 'fullScreen'
  style?: React.CSSProperties
  name: string
  children?: React.ReactNode
}

const OVERLAY_ANIMATIONS: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 0.9, transition: { duration: OPEN_ANIMATION_DURATION } },
  exit: { opacity: [0.9, 0.8, 0], transition: { duration: EXIT_ANIMATION_DURATION } },
}

const CONTENT_ANIMATIONS: Variants = {
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: OPEN_ANIMATION_DURATION,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.8,
    transition: {
      duration: EXIT_ANIMATION_DURATION,
    },
  },
  initial: {
    opacity: 0,
    scale: 0.8,
  },
}

const ModalContainer: React.FC<IContainerModalProps> = ({ children, styleType, style, name }) => {
  const { onClose } = React.useContext(ModalInstanceContext) || {}
  return (
    <div className={cn(s.portalRoot, s[styleType])}>
      <motion.div
        className={s.overlay}
        onClick={() => onClose?.()}
        {...testProps({ el: 'modalOverlay' })}
        {...OVERLAY_ANIMATIONS}
      />
      <motion.div
        className={s.root}
        style={style}
        {...testProps({ el: 'modal', name })}
        {...CONTENT_ANIMATIONS}
      >
        <div
          className={s.close}
          {...testProps({ el: 'modalClose', name })}
          onClick={() => onClose?.()}
        >
          <Icon name='otherClose' size={KitSize.M} />
          <div>esc</div>
        </div>
        {children}
      </motion.div>
    </div>
  )
}

export default ModalContainer
