import { AudioSchemaType, VideoSchemaType } from '@vedalib/editor/lib/brand'
import {
  CONFIG_ELEMENTS,
  EditorElement,
  AudioElementValue,
  VideoElementValue,
  ElementsTypes,
} from '@vedalib/editor/lib/elements'
import { FileUsageImageSource } from '@vedalib/editor/lib/files'
import { useMemo } from 'react'
import { FileTypeEnum, getParent } from 'utils'

import { PLAYBACK_RATE_OPTIONS } from 'components/MediaPlayer/constants'
import { genDataField } from 'components/controls/Field'
import { KitSize } from 'components/uiKit/KitTypes'
import { genColorOptions } from 'services/Branding/constants/fields'
import { useBrandTheme } from 'services/Branding/hooks'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'

type MediaSchemaType = AudioSchemaType | VideoSchemaType
type MediaValue = AudioElementValue | VideoElementValue

type MediaElement = EditorElement<MediaValue, MediaSchemaType>

const genField = genDataField<Block>()

export const file = (value: AudioElementValue | VideoElementValue) =>
  genField({
    name: 'file',
    type: 'file',
    layout: 'horizontal',
    params: (block, { name }) => {
      const { parent } = getParent<MediaElement>(name, block, 2)

      return {
        fileType: parent?.type as FileTypeEnum.AUDIO | FileTypeEnum.VIDEO,
        nullable: true,
        fileParams: value,
        preview: true,
        showSource: true,
      }
    },
    effect: (block, changeData) => {
      const { value: file, name } = changeData
      const { parent, parentName } = getParent<MediaValue>(name, block)

      return {
        ...changeData,
        name: parentName,
        value: {
          ...CONFIG_ELEMENTS.audio.defaultValue,
          file,
          coverImage: parent?.coverImage,
          controls: parent?.controls,
          repeat: parent?.repeat,
          volume: parent?.volume,
          playbackRate: parent?.playbackRate,
          percent: parent?.percent,
          autoPlay: parent?.autoPlay,
        },
      }
    },
  })
export const coverImage = genField({
  name: 'coverImage',
  type: 'file',
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<MediaElement>(name, block, 2)
    return {
      hidden: parent?.type !== FileTypeEnum.VIDEO,
      fileType: FileTypeEnum.IMAGE,
      nullable: true,
      preview: true,
      showSource: true,
      hasAccessibility: true,
    }
  },
})

export const autoPlay = genField({
  name: 'autoPlay',
  type: 'segmented',
  label: t('elements.media.form.autoPlay'),
  layout: 'horizontal',
})

export const repeat = genField({
  name: 'repeat',
  type: 'segmented',
  label: t('elements.media.form.repeat'),
  layout: 'horizontal',
})

export const controls = genField({
  name: 'controls',
  type: 'segmented',
  label: t('elements.media.form.controls'),
  info: t('input.tooltip.mediaPlayerControls'),
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<MediaElement>(name, block, 2)

    return {
      hidden: parent?.type !== 'video',
    }
  },
})

export const volume = genField({
  name: 'volume',
  type: 'slider',
  label: t('elements.media.form.volume'),
  layout: 'horizontal',
  params: { showInput: true, min: 0, max: 100 },
})

export const playbackRate = genField({
  name: 'playbackRate',
  type: 'select',
  label: t('elements.media.form.playbackRate'),
  layout: 'horizontal',
  params: {
    size: KitSize.S,
    options: PLAYBACK_RATE_OPTIONS,
    fluid: true,
  },
})

export const breakpoints = genField({
  name: 'file.params.breakpoints',
  type: 'timeRange',
  label: t('elements.media.form.slice'),
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<MediaValue>(name, block, 3)
    const { duration } = parent || {}
    return {
      hidden: parent?.file?.source === FileUsageImageSource.url,
      maxTime: duration,
      disabled: !duration,
      format: duration && duration > 3600 ? 'HH:mm:ss' : 'mm:ss',
      placeholder: duration && duration > 3600 ? '00:00:00' : '00:00',
      suffixIcon: null,
      hideDisabledOptions: true,
    }
  },
})

export const percent = (type: ElementsTypes) =>
  genField({
    name: 'percent',
    type: 'slider',
    label: t('input.label.percent'),
    layout: 'horizontal',
    info:
      type === 'video'
        ? t('elements.media.form.videoPercentHint')
        : t('elements.media.form.audioPercentHint'),
    params: {
      max: 100,
      min: 0,
      showInput: true,
    },
  })

export const captions = genField({
  name: 'captions',
  type: 'file',
  layout: 'horizontal',
  params: {
    fileType: FileTypeEnum.CAPTIONS,
    nullable: true,
    showSource: true,
  },
})

export const enableCCByDefault = genField({
  name: 'enableCCByDefault',
  type: 'segmented',
  label: t('elements.media.form.enableCCByDefault'),
  layout: 'horizontal',
  defaultValue: false,
})

export const overlay = genField({
  name: 'overlay',
  type: 'color',
  label: t('input.label.overlay'),
  defaultValue: '#FFFFFF00',
  layout: 'horizontal',
  useParams: (block, { name }) => {
    const theme = useBrandTheme()
    const options = useMemo(() => genColorOptions(theme), [theme])
    const { parent } = getParent<MediaElement>(name, block, 2)
    return {
      options,
      hidden: parent?.type !== 'video',
      labeled: true,
    }
  },
})
