import { CONFIG_ELEMENTS } from '@vedalib/editor/lib/elements'

import { t } from 'services/Translation'

import { ElementGroup } from '../../elements.types'
import { genElementConfig } from '../../genElementConfig'
import { STYLE_FORM_SCHEMA, FONT_FORM_SCHEMA } from './TestSingleChoiceBrandFormSchema'
import Demo from './TestSingleChoiceDemo'
import TestSingleChoiceEditorActions from './TestSingleChoiceEditorActions'
import Element from './TestSingleChoiceElement'
import ValueForm from './TestSingleChoiceForm'
import radioIcon from './assets/test-single-choise.svg'

export default genElementConfig(CONFIG_ELEMENTS.testSingleChoice, {
  label: t('elements.testSingleChoice.label'),
  icon: radioIcon,
  group: ElementGroup.test,
  Element,
  ValueForm,
  STYLE_FORM_SCHEMA,
  FONT_FORM_SCHEMA,
  EditorActions: TestSingleChoiceEditorActions,
  Demo,
})
