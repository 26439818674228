import { useCallback } from 'react'

import { createDeferred } from 'utils/defered'

import {
  ModalComponent,
  MCWithParams,
  MCWithoutParams,
  useUpdateModalData,
  useModalContext,
  getLastModalIndex,
} from './ModalContext'
import ModalMount from './ModalMount'

export const useOpenModal = <C extends ModalComponent<P, R>, P, R, Keys extends keyof P>(
  ...[component, params]: C extends MCWithoutParams<R>
    ? [MCWithoutParams<R>]
    : C extends MCWithParams<P, R>
      ? [MCWithParams<P, R>, Pick<P, Keys>]
      : never
) => {
  const updateModal = useUpdateModalData(component)
  const modalContext = useModalContext()

  const open = useCallback(
    (
      openParams: C extends MCWithParams<P, R>
        ? Exclude<keyof P, Keys> extends never
          ? Partial<P> | void
          : Partial<P> & Omit<P, Keys>
        : undefined,
    ) => {
      const deferred = createDeferred<R>()
      updateModal((modalData) => ({
        ...modalData,
        open: true,
        index: getLastModalIndex(modalContext.modals) + 1,
        params: openParams ? ({ ...params, ...openParams } as unknown as P) : (params as P),
        deferred,
      }))
      return deferred.promise
    },
    [updateModal, modalContext.modals, params],
  )

  const close = useCallback(
    (result: R) =>
      updateModal((modalData) => ({
        ...modalData,
        ...(modalData.deferred && {
          deferred: modalData.deferred?.resolve(result),
        }),
        open: false,
      })),
    [updateModal],
  )

  const update = useCallback(
    (params: Partial<P>) =>
      updateModal((modalData) => ({ ...modalData, params: { ...modalData, ...params } as P })),
    [updateModal],
  )

  const mount = <ModalMount component={component} userMount />

  return { open, close, update, mount }
}
