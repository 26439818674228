import { useFloating, flip, hide, offset, shift } from '@floating-ui/react'
import { useEffect, useMemo } from 'react'

import { IPlacement, IType } from './types'

const OFFSET = 4
const isBottom = (placement: IPlacement) => placement.toLowerCase().startsWith('bottom')
const isTop = (placement: IPlacement) => placement.toLowerCase().startsWith('top')
const isLeft = (placement: IPlacement) => placement.toLowerCase().startsWith('left')
const isRight = (placement: IPlacement) => placement.toLowerCase().startsWith('right')

interface IPlacementType {
  targetEle: HTMLDivElement | null
  popupEle: HTMLDivElement | null
  placement: IPlacement
  type: IType
  offset: [number, number]
  triggerOpen: (open: boolean) => void
  open: boolean
}

type IVisibilityType = 'visible' | 'hidden' | 'collapse' | 'initial' | 'inherit' | undefined

const IPLACEMENT_TO_PLACEMENT_ADAPTER = {
  bottom: 'bottom' as const,
  bottomLeft: 'bottom-start' as const,
  bottomRight: 'bottom-end' as const,
  top: 'top' as const,
  topLeft: 'top-start' as const,
  topRight: 'top-end' as const,
  right: 'right' as const,
  rightBottom: 'right-end' as const,
  rightTop: 'right-start' as const,
  left: 'left' as const,
  leftTop: 'left-start' as const,
  leftBottom: 'left-end' as const,
}

const DEFAULT_POSITIONS = {
  top: 0,
  left: 0,
  visibility: 'hidden' as IVisibilityType,
  width: 'auto',
  maxWidth: '100%',
  minWidth: 'auto',
}

// need unite with courseKit hook and move to main hooks
export const useGetPosition = ({
  targetEle,
  popupEle,
  placement,
  type,
  offset: offsetParams,
  triggerOpen,
  open,
}: IPlacementType) => {
  const [y, x] = offsetParams
  const { width: targetEleWidth } = targetEle?.getBoundingClientRect() || {}

  const placementFloating = IPLACEMENT_TO_PLACEMENT_ADAPTER[placement]
  const isCrossAxis = isTop(placement) || isLeft(placement)
  const isMainAxis = isRight(placement) || isBottom(placement)

  const {
    x: left,
    y: top,
    update,
  } = useFloating({
    strategy: 'absolute',
    elements: { reference: targetEle, floating: popupEle },
    placement: placementFloating,
    open,
    onOpenChange: triggerOpen,
    middleware: [
      offset({
        mainAxis: y || (isMainAxis ? OFFSET : 0),
        crossAxis: x || (isCrossAxis ? OFFSET : 0),
      }),
      flip(),
      shift({ padding: OFFSET }),
      hide(),
    ],
  })

  useEffect(() => {
    const abortController = new AbortController()
    const { signal } = abortController
    update()
    const resizeObserver = new ResizeObserver(() => {
      update()
    })

    if (targetEle) {
      resizeObserver.observe(targetEle)
    }

    window.addEventListener('resize', update, { signal })
    window.addEventListener('scroll', update, { capture: true, signal })

    return () => {
      abortController.abort()
      resizeObserver.disconnect()
    }
  }, [placement, popupEle, type, targetEle, x, y, triggerOpen, update])

  return useMemo(
    () => ({
      ...DEFAULT_POSITIONS,
      ...(type === 'tooltip' && { maxWidth: '300px' }),
      top,
      left,
      visibility: 'visible' as const,
      width: 'auto',
      ...(type !== 'tooltip' && { minWidth: targetEleWidth, width: targetEleWidth }),
      ...(type === 'popover' && { width: 'auto' }),
    }),
    [left, top, type, targetEleWidth],
  )
}
