import 'overlayscrollbars/css/OverlayScrollbars.css'
import { createRoot } from 'react-dom/client'

import { initAnalytics } from './analytics'
import './fonts/inter.config'
import App from './routes'
import './theme.scss'
import './theme/deprecated_reset.css'
import { initTheme } from './theme/initTheme'
import './theme/reset.css'
import './theme/theme.scss'
import { initCaptureClick } from './utils/test/test'

const container = document.getElementById('root')

initCaptureClick()
initAnalytics()
initTheme()

if (container) {
  const root = createRoot(container)
  root.render(<App />)
}
