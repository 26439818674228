import cn from 'classnames'
import React, { useState } from 'react'

import { IconButton } from 'components/uiKit/Button'
import DropdownMenu from 'components/uiKit/DropdownMenu'
import { KitSize, ValueType } from 'components/uiKit/KitTypes'
import Loader from 'components/uiKit/Loader'
import { IMenuOption } from 'components/uiKit/Menu/MenuItem'
import { preventDefault, stopPropagation } from 'constants/commonConstans'
import { testProps } from 'utils/test/qaData'

import * as s from './Card.module.scss'

interface ICardProps<T extends ValueType> {
  name: string
  size?: KitSize | 'free'
  styleType?: 'defaultCard' | 'primary'
  fluid?: boolean
  children?: React.ReactNode
  loading?: boolean
  onClick?: (e: React.MouseEvent) => void
  actions?: IMenuOption<T>[]
  onClickActions?: ({ value }: IMenuOption<T>) => void
  hiddenActions?: boolean
  testData?: string
  width?: number
  height?: number
  focused?: boolean
  shadow?: boolean
  padding?: number
  badge?: string | boolean
  selected?: boolean
  hovered?: boolean
}

const Card = <T extends ValueType>({
  name,
  children,
  styleType = 'defaultCard',
  size = KitSize.M,
  fluid,
  loading,
  actions,
  onClick,
  onClickActions,
  hiddenActions,
  testData,
  width,
  height,
  focused,
  shadow,
  padding,
  badge,
  selected,
  hovered = true,
}: ICardProps<T>) => {
  const [isActionsOpened, setIsActionsOpened] = useState(false)

  const cnRoot = cn(s.root, s[styleType], s[size], {
    [s.active]: onClick,
    [s.fluid]: fluid,
    [s.focused]: focused,
    [s.shadow]: shadow,
    [s.hiddenActions]: hiddenActions && !isActionsOpened,
    [s.selected]: selected,
    [s.hovered]: hovered,
  })

  const handlePropagation = (e: React.MouseEvent) => {
    stopPropagation(e)
    preventDefault(e)
  }

  return (
    <div
      className={cnRoot}
      onClick={onClick}
      style={{ minWidth: width, height, padding }}
      tabIndex={-1}
      {...testProps({ el: 'card', name, testData })}
    >
      {actions && Boolean(actions.length) && (
        <div
          className={cn(s.actions, {
            [s.hiddenActions]: hiddenActions && !isActionsOpened,
          })}
          onClick={handlePropagation}
          onMouseDown={handlePropagation}
        >
          <DropdownMenu
            name={`${name}DropdownMenu`}
            onClick={onClickActions}
            onVisibleChange={setIsActionsOpened}
            options={actions}
            placement='bottomRight'
          >
            {({ open }) => (
              <IconButton
                active={open}
                icon='iconsOtherMore'
                name={`${name}.actions`}
                size={KitSize.S}
                styleType='ghost'
                testData={testData}
              />
            )}
          </DropdownMenu>
        </div>
      )}
      {loading ? <Loader name='cardLoader' /> : children}
      {badge && <div className={s.badge}>{badge}</div>}
    </div>
  )
}

export default Card
