// extracted by mini-css-extract-plugin
var _1 = "Card_actions__DZ-ej";
var _2 = "Card_active__SwgP2";
var _3 = "Card_badge__BOYhm";
var _4 = "Card_defaultCard__RtOfX";
var _5 = "Card_fluid__fBLhK";
var _6 = "Card_focused__r8jo2";
var _7 = "Card_free__2T2il";
var _8 = "Card_hiddenActions__ij2wI";
var _9 = "Card_hovered__Cb8g1";
var _a = "Card_large__g+W93";
var _b = "Card_medium__aYxQx";
var _c = "Card_mini__bjqMx";
var _d = "Card_primary__5vDeJ";
var _e = "Card_root__Ns3rD";
var _f = "Card_selected__DoM9i";
var _10 = "Card_shadow__19EYy";
var _11 = "Card_xmini__S-WW6";
export { _1 as "actions", _2 as "active", _3 as "badge", _4 as "defaultCard", _5 as "fluid", _6 as "focused", _7 as "free", _8 as "hiddenActions", _9 as "hovered", _a as "large", _b as "medium", _c as "mini", _d as "primary", _e as "root", _f as "selected", _10 as "shadow", _11 as "xmini" }
