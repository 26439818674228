import { cpp } from '@codemirror/lang-cpp'
import { css } from '@codemirror/lang-css'
import { html } from '@codemirror/lang-html'
import { java } from '@codemirror/lang-java'
import { javascript } from '@codemirror/lang-javascript'
import { markdown } from '@codemirror/lang-markdown'
import { php } from '@codemirror/lang-php'
import { python } from '@codemirror/lang-python'
import { sql } from '@codemirror/lang-sql'
import { LanguagesEnum } from '@vedalib/editor/lib/elements/elements/Code/constants'

export const languagesMap = {
  [LanguagesEnum.html]: html,
  [LanguagesEnum.javascript]: javascript,
  [LanguagesEnum.css]: css,
  [LanguagesEnum.markdown]: markdown,
  [LanguagesEnum.php]: php,
  [LanguagesEnum.java]: java,
  [LanguagesEnum.python]: python,
  [LanguagesEnum['c/c++']]: cpp,
  [LanguagesEnum.sql]: sql,
  [LanguagesEnum.node]: javascript,
}
