/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
export type ConvertObjectToArrayPrams = { [key: string]: string | unknown }

export const isIncludeEqual = (obj: any, compareValue: any): boolean => {
  for (const [key, value] of Object.entries(compareValue)) {
    if (value && value !== obj[key]) {
      return false
    }
  }

  return true
}

export const getDeepValuesArray = (obj: ConvertObjectToArrayPrams): string[] => {
  const result = []
  for (const prop in obj) {
    const value = obj[prop]
    if (isObject(value)) {
      result.push(...getDeepValuesArray(value))
    } else if (typeof value === 'string') {
      result.push(value)
    }
  }
  return result
}

export type PermissionKeys = string

export const getDeepValuesObject = <T extends Record<string, unknown>>(
  obj: T,
  objValue = true,
): { [key in PermissionKeys]: boolean } => {
  let result = {} as { [key in PermissionKeys]: boolean }
  for (const prop in obj) {
    const value = obj[prop]
    if (isObject(value)) {
      result = { ...result, ...getDeepValuesObject(value, objValue) }
    } else if (typeof value === 'string') {
      result = { ...result, [value]: objValue }
    }
  }
  return result
}

const isObject = (value: unknown): value is ConvertObjectToArrayPrams => {
  if (typeof value === 'object') {
    return true
  }

  return false
}

export const notChange = Symbol('notChange')
const deleted = '??}}DELETED//SAFRQFA'

export const joinArray = (a: any[], b: any[]) => {
  const res: { [key: string]: any[] } = {
    left: [],
    right: [],
    inner: [],
    outer: a.slice(),
  }
  for (const k of a) {
    if (b.includes(k)) {
      res.inner.push(k)
    } else {
      res.left.push(k)
    }
  }

  for (const k of b) {
    if (!a.includes(k)) {
      res.right.push(k)
      res.outer.push(k)
    }
  }

  return res
}

export const getDiff = (a: any, b: any) => {
  if (typeof a !== 'object') {
    return a === b ? notChange : b
  }

  if (a === null || a === undefined || b === null || b === undefined) {
    return a === b ? notChange : b
  }

  if (a === b) {
    return notChange
  }

  const isArrayA = false // Array.isArray(a)
  const isArrayB = false // Array.isArray(b)
  if ((isArrayA && !isArrayB) || (!isArrayA && isArrayB)) {
    return b
  } else if (isArrayA) {
    if (a.length === b.length) {
      const res: any[] = []
      let hasDiff = false
      for (const k in a) {
        const d = getDiff(a[k], b[k])
        if (d !== notChange) {
          // @ts-ignore
          res[k] = d
          hasDiff = true
        }
      }
      return hasDiff ? b : notChange
    }
    return b
  } else {
    const res = {}
    let hasDiff
    const keys = joinArray(Reflect.ownKeys(a), Reflect.ownKeys(b))
    for (const k of keys.inner) {
      const d = getDiff(a[k], b[k])
      if (d !== notChange) {
        // @ts-ignore
        res[k] = d
        hasDiff = true
      }
    }
    for (const k of keys.right) {
      // @ts-ignore
      res[k] = b[k]
      hasDiff = true
    }

    for (const k of keys.left) {
      // @ts-ignore
      res[k] = deleted
      hasDiff = true
    }
    return hasDiff ? res : notChange
  }
}

export const applyDiff = (doc: any, patch: any) => {
  if (typeof patch !== 'object' || patch === null || Array.isArray(patch)) {
    return patch
  }

  doc = { ...doc }

  for (const k in patch) {
    const v = patch[k]
    if (v === deleted && doc[k] !== null) {
      delete doc[k]
      continue
    }
    doc[k] = applyDiff(doc[k], v)
  }

  return doc
}

export const shouldReplaceDefault = (fromValue: any, toValue: any, _path: any) => {
  const fromType = typeof fromValue
  const toType = typeof toValue
  return (
    fromType !== toType ||
    fromValue === null ||
    toValue === null ||
    fromType !== 'object' ||
    toType !== 'object' ||
    Array.isArray(fromValue) ||
    Array.isArray(toValue)
  )
}

export const ANY = Symbol('*')

const ACTION_TYPES = {
  leave: 'L',
  replace: 'R',
  remove: 'D',
  patch: 'P',
}

export const getExtendedDiff = (
  fromValue: any,
  toValue: any,
  {
    isEqual = (a: any, b: any, _path: any) => a === b,
    shouldReplace = shouldReplaceDefault,
    isRemove = (_fromValue: any, toValue: any, _path: any) => toValue === undefined,
  } = {},
) => {
  const getExtendedDiffInner = (fromValue: any, toValue: any, path: any): any => {
    if (!isEqual(fromValue, toValue, path)) {
      if (shouldReplace(fromValue, toValue, path)) {
        if (isRemove(fromValue, toValue, path)) {
          return { t: ACTION_TYPES.remove }
        }

        return { t: ACTION_TYPES.replace, value: toValue }
      }
      const isObject = !Array.isArray(toValue)
      const keys = Object.keys(toValue)
      const actions = []
      const fromKeys = new Set(Object.keys(fromValue))
      let changed = keys.length !== fromKeys.size
      for (const key of keys) {
        fromKeys.delete(key)
        const action = getExtendedDiffInner(fromValue[key], toValue[key], path.concat(key))
        const isLeave = action.t === ACTION_TYPES.leave
        changed = changed || !isLeave
        if (!isLeave) {
          actions.push([key, action])
        }
      }
      if (changed || fromKeys.size) {
        if (fromKeys.size) {
          actions.push(...Array.from(fromKeys).map((key) => [key, { t: ACTION_TYPES.remove }]))
        }

        return { t: ACTION_TYPES.patch, o: isObject, a: actions }
      }
    }
    return { t: ACTION_TYPES.leave }
  }
  const patch = getExtendedDiffInner(fromValue, toValue, [])
  return patch
}

export const applyExtendedDiff = (fromValue: any, diff: any = {}) => {
  switch (diff.t) {
    case ACTION_TYPES.leave:
      return fromValue
    case ACTION_TYPES.replace:
      return diff.value
    case ACTION_TYPES.remove:
      return undefined
    case ACTION_TYPES.patch: {
      const result = diff.o ? { ...fromValue } : [...fromValue]
      for (const [key, keyAction] of diff.a) {
        if (keyAction.t === ACTION_TYPES.remove) {
          delete result[key]
        } else {
          result[key] = applyExtendedDiff(fromValue[key], keyAction)
        }
      }

      return result
    }
  }
  console.error(`Invalid patch type: ${diff.t}`)
  return fromValue
}
