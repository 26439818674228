import { SliderSchemaType } from '@vedalib/editor/lib/brand'
import styled from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const ArrowStyled = styled.div<{ $styles: ElementStyleCss<SliderSchemaType>; $disabled: boolean }>`
  transition: 0.2s;
  display: flex;
  border-radius: 50%;
  font-size: 24px;
  padding: 4px;

  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  color: ${({ $styles }) => $styles.icons.color};
  background-color: ${({ $styles, $disabled, theme }) =>
    $disabled ? theme.inactiveColor : $styles.icons.backgroundColor};
  box-shadow: ${({ $styles }) => $styles.effects.boxShadow};

  &:hover {
    background-color: ${({ $styles: { hover }, $disabled }) => !$disabled && hover.backgroundColor};
  }
`

export default ArrowStyled
