import { textToRtValue } from '@vedalib/rich-text'
import classNames from 'classnames'
import { produce } from 'immer'
import { MouseEvent, useEffect, useState } from 'react'
import { useDrag } from 'react-dnd'

import Dropdown from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import Menu from 'components/uiKit/Menu'
import { IMenuOption } from 'components/uiKit/Menu/MenuItem'
import { getEnumOption } from 'utils/enum'
import { testProps } from 'utils/test/qaData'

import { useTableContext } from '../../TableContext'
import { DragType } from '../../TableElement.types'
import { createCell, createColumn, createRow, delayedUpdate } from '../../utils'
import * as s from './TableDragControl.module.scss'
import { ElementTableActionUiEnum } from './types'

export type TableDragControlProps = {
  id: string
  axis: DragType
  width: number
  height: number
  onClick: () => void
  name: string
  hidden: boolean
}

const rowMenuOptions = [
  getEnumOption('ElementTableActionUiEnum', ElementTableActionUiEnum.INSERT_TOP),
  getEnumOption('ElementTableActionUiEnum', ElementTableActionUiEnum.INSERT_BOTTOM),
  getEnumOption('ElementTableActionUiEnum', ElementTableActionUiEnum.DUPLICATE),
  getEnumOption('ElementTableActionUiEnum', ElementTableActionUiEnum.CLEAR),
  getEnumOption('ElementTableActionUiEnum', ElementTableActionUiEnum.DELETE),
]

const colMenuOptions = [
  getEnumOption('ElementTableActionUiEnum', ElementTableActionUiEnum.INSERT_LEFT),
  getEnumOption('ElementTableActionUiEnum', ElementTableActionUiEnum.INSERT_RIGHT),
  getEnumOption('ElementTableActionUiEnum', ElementTableActionUiEnum.DUPLICATE),
  getEnumOption('ElementTableActionUiEnum', ElementTableActionUiEnum.CLEAR),
  getEnumOption('ElementTableActionUiEnum', ElementTableActionUiEnum.DELETE),
]

const TableDragControl = ({ id, axis, width, height, hidden, onClick }: TableDragControlProps) => {
  const { tableValue, setDragState, onTableChange, selectRow, selectCol } = useTableContext()
  const [visible, setVisible] = useState(false)
  const [row, col] = id.split('.').map(Number)

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'cell',
      item: { axis, id, width, height },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: () => {
        setDragState({ dragType: null, dragIndex: null })
      },
    }),
    [id, axis, width, height],
  )

  useEffect(() => {
    if (isDragging) {
      const [x, y] = id.split('.')
      const dragIndex = axis === 'row' ? Number(x) : Number(y)
      setDragState({ dragType: axis, dragIndex })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging])

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation()
    if (!isDragging) {
      onClick()
    }
  }

  const handleMenuAction = (item: IMenuOption<ElementTableActionUiEnum>) => {
    switch (item.value) {
      case ElementTableActionUiEnum.INSERT_TOP:
        onTableChange(
          produce(tableValue, (draft) => {
            draft.cells.splice(row, 0, createRow(draft.columns.length))
          }),
        )

        delayedUpdate(() => {
          selectRow(row)
        })
        break
      case ElementTableActionUiEnum.INSERT_BOTTOM:
        onTableChange(
          produce(tableValue, (draft) => {
            draft.cells.splice(row + 1, 0, createRow(draft.columns.length))
          }),
        )

        delayedUpdate(() => {
          selectRow(row + 1)
        })
        break
      case ElementTableActionUiEnum.INSERT_LEFT:
        onTableChange(
          produce(tableValue, (draft) => {
            draft.columns.splice(col, 0, createColumn())
            draft.cells.forEach((row) => {
              row.splice(col, 0, { value: textToRtValue('') })
            })
          }),
        )
        delayedUpdate(() => {
          selectCol(col)
        })
        break
      case ElementTableActionUiEnum.INSERT_RIGHT:
        onTableChange(
          produce(tableValue, (draft) => {
            draft.columns.splice(col + 1, 0, createColumn())
            draft.cells.forEach((row) => {
              row.splice(col + 1, 0, createCell())
            })
          }),
        )
        delayedUpdate(() => {
          selectCol(col + 1)
        })
        break
      case ElementTableActionUiEnum.DUPLICATE:
        onTableChange(
          produce(tableValue, (draft) => {
            if (axis === 'row') {
              draft.cells.splice(row, 0, draft.cells[row])
            } else {
              draft.columns.splice(col, 0, draft.columns[col])
              draft.cells.forEach((row) => {
                row.splice(col, 0, row[col])
              })
            }
          }),
        )
        delayedUpdate(() => {
          if (axis === 'row') {
            selectRow(row + 1)
          } else {
            selectCol(col + 1)
          }
        })
        break
      case ElementTableActionUiEnum.CLEAR:
        onTableChange(
          produce(tableValue, (draft) => {
            if (axis === 'row') {
              draft.cells[row] = createRow(draft.columns.length)
            } else {
              draft.cells.forEach((row) => {
                row[col] = createCell()
              })
            }
          }),
        )
        break
      case ElementTableActionUiEnum.DELETE:
        onTableChange(
          produce(tableValue, (draft) => {
            if (axis === 'row' && draft.cells.length === 1) {
              draft.cells[0] = createRow(draft.columns.length)
              return
            }

            if (axis === 'column' && draft.columns.length === 1) {
              draft.columns[0] = createColumn()
              draft.cells.forEach((row) => {
                row[0] = createCell()
              })
              return
            }

            if (axis === 'row') {
              draft.cells.splice(row, 1)
              if (draft.cells.length === row) {
                selectRow(row - 1)
              }
            } else {
              draft.columns.splice(col, 1)
              draft.cells.forEach((row) => {
                row.splice(col, 1)
              })
              if (draft.columns.length === col) {
                selectCol(col - 1)
              }
            }
          }),
        )
        break
      default:
        break
    }
    setVisible(false)
  }

  const menuOptions = axis === 'row' ? rowMenuOptions : colMenuOptions

  return (
    <div
      className={classNames(s.tableControl, {
        [s.hidden]: hidden && !visible,
        [s.menuOpen]: visible,
        [s.row]: axis === 'row',
        [s.col]: axis === 'column',
      })}
      onClick={handleClick}
      onMouseDown={(e) => e.stopPropagation()}
      ref={drag}
    >
      <Dropdown
        onVisibleChange={(value) => setVisible(value)}
        overlay={
          <div onClick={(e) => e.stopPropagation()}>
            <Menu name='tableDragControls' onClick={handleMenuAction} options={menuOptions} />
          </div>
        }
        placement='bottomLeft'
        styleType='clear'
        trigger={['click']}
        visible={visible}
      >
        <div {...testProps({ el: 'tableDragControl', col, row, axis })}>
          <Icon name='builderDrugAndDrop' />
        </div>
      </Dropdown>
    </div>
  )
}

export default TableDragControl
