import { useKeyPress } from 'ahooks'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { generateSection } from 'utils'

import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import { IS_MAC } from 'constants/commonConstans'
import { EDITOR_PATHS, PROJECT_HOME_PATHS } from 'constants/paths'
import { SectionEditorSchemaFragment, SectionOrderSchemaFragment } from 'gql/__generated__/graphql'
import { useSectionsGetById } from 'gql/sections/apollo'
import generateLink from 'routes/generateLink'
import { usePathParams } from 'routes/hooks'
import { BlockMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { t } from 'services/Translation'
import { useNextSection, usePrevSection } from 'utils/project.hooks'

import {
  LEVEL_INDENT,
  CHAPTER_WITH_CHILDREN_OFFSET,
  MAX_NESTED_LEVEL,
  CIRCLE_DRAG_LINE_WIDTH,
} from '../SectionsTree/constants'
import { useGetProjectContentContext } from './ProjectContentContext/ProjectContentContext'
import { ITreeItem, getNameSection } from './utils'

interface IGetDragLineShift {
  marginLeft: number
  hasChildren: boolean
  puttingInChapter: boolean
  isChapter?: boolean
}

const NOTIFICATION_TEXT = t('notify.nestedElem', { count: MAX_NESTED_LEVEL })
const SHORT_KEY_CTRL = IS_MAC ? 'meta' : 'ctrl'
const KEY_DELETE = IS_MAC ? 'backspace' : 'delete'
const KEY_DUPLICATE = 'd'

export const checkCanCreateSection = (
  type: SectionTypeEnum,
  sections: SectionEditorSchemaFragment[],
  sectionId?: string,
) => {
  const activeSection = sectionId
  let index = sections.findIndex((e) => e.id === activeSection)
  const section = sections && sections[index]
  const isExistCover = sections.some(({ type }) => type === SectionTypeEnum.cover)
  if (isExistCover && index === -1) {
    index = 0
  }

  if (isExistCover && type === SectionTypeEnum.cover) {
    notify({
      type: NotificationType.error,
      message: t('notify.coverError'),
      duration: 1500,
    })
    return false
  }

  if (section && section.lvl >= MAX_NESTED_LEVEL - 1 && type === SectionTypeEnum.chapter) {
    notify({ type: NotificationType.error, message: NOTIFICATION_TEXT })
    return false
  }
  return true
}

interface IGetVariablesOnSectionCreate {
  type: SectionTypeEnum
  projectId: string
  sections: SectionEditorSchemaFragment[]
  activeSection?: string
  sectionsOrder?: SectionOrderSchemaFragment[]
}

export const getVariablesOnSectionCreate = ({
  type,
  projectId,
  sections,
  activeSection,
  sectionsOrder,
}: IGetVariablesOnSectionCreate) => {
  const name = getNameSection({ sections, type })
  let index = sections.findIndex((e) => e.id === activeSection)

  const section = sections && sections[index]
  const lvl =
    section && type !== SectionTypeEnum.cover
      ? section.isChapter
        ? section.lvl + 1
        : section.lvl
      : 0
  if (index === -1) {
    index = 0
  }

  const newSection = generateSection(name, type, lvl)

  let positionNewSection = 0

  if (activeSection) {
    positionNewSection = index + 1
  } else {
    positionNewSection = sections.length
  }

  if (type === SectionTypeEnum.cover) {
    positionNewSection = 0
  }

  const listOrder = [...(sectionsOrder || [])]

  listOrder.splice(positionNewSection, 0, {
    __typename: 'EditorSectionOrderOptionalType',
    id: '',
    lvl,
  })

  return { projectId, data: { ...newSection, sectionsOrder: listOrder } }
}

export const getSectionLeftOffsetEditor = <T extends ITreeItem>(item: T, hasChildren: boolean) => {
  if (item.type === SectionTypeEnum.cover) {
    return 0
  }

  if (item.type === SectionTypeEnum.chapter) {
    return item.lvl * LEVEL_INDENT + (hasChildren ? CHAPTER_WITH_CHILDREN_OFFSET : 0)
  }

  return item.lvl * LEVEL_INDENT
}

export const getSectionLeftOffset = <T extends ITreeItem>(item: T) => {
  if (item.isChapter) {
    return 30 + CIRCLE_DRAG_LINE_WIDTH
  }

  return CIRCLE_DRAG_LINE_WIDTH
}

export const getDragLineShift = ({
  marginLeft,
  hasChildren,
  puttingInChapter,
  isChapter,
}: IGetDragLineShift) => {
  if (!isChapter) {
    return marginLeft
  }

  return (
    marginLeft -
    (puttingInChapter && hasChildren ? -CIRCLE_DRAG_LINE_WIDTH : 30) +
    (puttingInChapter && !hasChildren ? 40 : 0)
  )
}

const getFirstBlock = (section?: SectionEditorSchemaFragment | null) => {
  if (section?.type === 'test') {
    return section.blocks.find((block) => block.mode === BlockMode.start)?.uuid || BlockMode.start
  }

  return section?.blocksOrder?.[0]
}

export const useGetLinkToEditor = () => {
  const {
    companyId,
    projectId,
    sectionId: sectionId = ' ',
  } = usePathParams(PROJECT_HOME_PATHS.sections)
  const { data: sectionData } = useSectionsGetById(sectionId, projectId, true)
  const section = sectionData?.data

  const linkToEditor = generateLink(EDITOR_PATHS.main, {
    companyId,
    projectId,
    sectionId,
    blockId: getFirstBlock(section),
  })
  return linkToEditor
}

export const useSectionsShortKeys = () => {
  const { sections, setNewSelectedNodes, onDelete, onDuplicate } = useGetProjectContentContext()
  const [tabPressing, setTabPressing] = useState(false)
  const history = useHistory()
  const { sectionId } = usePathParams(PROJECT_HOME_PATHS.sections)
  const nextSection = useNextSection(sectionId, sections || [])
  const prevSection = usePrevSection(sectionId, sections || [])

  const goToSection = useCallback(
    (sectionId: string) => {
      setNewSelectedNodes([sectionId])
      history.replace(sectionId)
    },
    [history, setNewSelectedNodes],
  )

  useKeyPress(`${SHORT_KEY_CTRL}.${KEY_DELETE}`, (e) => {
    e.preventDefault()
    onDelete()
  })

  useKeyPress(`${SHORT_KEY_CTRL}.${KEY_DUPLICATE}`, (e) => {
    e.preventDefault()
    onDuplicate()
  })

  useKeyPress(['uparrow', 'downarrow', 'tab'], (e) => {
    if (sections.length && sectionId) {
      if (e.key === 'Tab') {
        e.preventDefault()
        e.stopPropagation()
        setTabPressing(true)
      }
      if (e.key === 'ArrowDown' && tabPressing) {
        e.preventDefault()
        e.stopPropagation()
        if (nextSection) {
          goToSection(nextSection.id)
        } else {
          const firstSectionId = sections[0].id
          goToSection(firstSectionId)
        }
      }
      if (e.key === 'ArrowUp' && tabPressing) {
        e.preventDefault()
        e.stopPropagation()
        if (prevSection) {
          goToSection(prevSection.id)
        } else {
          const lastSectionId = sections[sections.length - 1].id
          goToSection(lastSectionId)
        }
      }
    }
  })

  useEffect(() => {
    const onKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        setTabPressing(false)
      }
    }

    document.addEventListener('keyup', onKeyUp, true)
    return () => {
      document.removeEventListener('keyup', onKeyUp, true)
    }
  }, [])
}

export const useSetActiveSection = () => {
  const history = useHistory()
  const params = usePathParams(PROJECT_HOME_PATHS.sections)

  const setActiveSection = useCallback(
    (sectionId?: string) => {
      if (sectionId !== params.sectionId) {
        const linkToSection = generateLink(PROJECT_HOME_PATHS.sections, {
          companyId: params.companyId,
          projectId: params.projectId,
          ...(sectionId && { sectionId }),
        })
        history.push(linkToSection)
      }
    },
    [params, history],
  )
  return setActiveSection
}
