// extracted by mini-css-extract-plugin
var _1 = "Employee_defaultName__wPCTK";
var _2 = "Employee_disabled__U1lE9";
var _3 = "Employee_empty__lZTjK";
var _4 = "Employee_error__vjeRS";
var _5 = "Employee_full__8cDaS";
var _6 = "Employee_fullName__oW0rC";
var _7 = "Employee_fullRight__tyNcp";
var _8 = "Employee_fullRole__96Khx";
var _9 = "Employee_info__3yKOM";
var _a = "Employee_interactive__djWIF";
var _b = "Employee_large__KNHJI";
var _c = "Employee_medium__bK+ts";
var _d = "Employee_mention__eryJM";
var _e = "Employee_mini__cRDCD";
var _f = "Employee_name__yVXr8";
var _10 = "Employee_native__9GGrH";
var _11 = "Employee_removed__n9VEI";
var _12 = "Employee_xmini__JWim1";
export { _1 as "defaultName", _2 as "disabled", _3 as "empty", _4 as "error", _5 as "full", _6 as "fullName", _7 as "fullRight", _8 as "fullRole", _9 as "info", _a as "interactive", _b as "large", _c as "medium", _d as "mention", _e as "mini", _f as "name", _10 as "native", _11 as "removed", _12 as "xmini" }
