import Uppy, { InternalMetadata } from '@uppy/core'
import Tus from '@uppy/tus'
import { config } from 'config'
import React, { useMemo, useEffect } from 'react'

import {
  FileMetaGroupAllQueryVariables,
  UpsertFileMetaMutationVariables,
} from 'gql/__generated__/graphql'
import { UppyType } from 'store/models'

import { UppyHeicFix } from './customPluginsUppy/HeicFix'
import { PreUploadCustomPlugin } from './customPluginsUppy/PreUploadCustomPlugin'

export interface ITypeFileMeta extends InternalMetadata {
  dataVariables: UpsertFileMetaMutationVariables
  paramsUpdateCacheFiles: FileMetaGroupAllQueryVariables
  fileMetaId: string | undefined
  companyId?: string
  contentType?: string
  createdById: string
  projectId?: string
}

// for file size upload/uppy sync front/bec patch request upload-offset header https://tus.io/protocols/resumable-upload.html#patch
const CHUNK_SIZE = 8388608

const defaultContext = {} as UppyType

export const UppyContext = React.createContext(defaultContext)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UppyProvider = ({ children }: { children: any }) => {
  const uppy = useMemo(() => {
    const internalUppy = new Uppy({
      meta: {},
      autoProceed: true,
      allowMultipleUploads: true,
    })

    // @ts-expect-error TODO
    internalUppy.use(PreUploadCustomPlugin)

    // @ts-expect-error TODO
    internalUppy.use(UppyHeicFix)
    internalUppy.use(Tus, {
      endpoint: config.io.fileEndpoint + '/upload',
      chunkSize: CHUNK_SIZE,
    })
    return internalUppy
  }, [])

  useEffect(() => {
    return () => uppy.close()
  }, [uppy])

  return <UppyContext.Provider value={uppy}>{children}</UppyContext.Provider>
}
