import moment, { MomentInput } from 'moment'

export const getDate = (date: string | number, format: string) => {
  return moment(Number(date)).format(format)
}

type DateType = number | string | (string | null)[] | null

export const convertToTimestamp = (date: DateType): number | undefined =>
  date ? (isNaN(Number(date)) ? Number(moment(date as MomentInput)) : Number(date)) : undefined

export const includeDay = (date: DateType): DateType | undefined =>
  date
    ? moment(date as MomentInput)
        .endOf('day')
        .valueOf()
    : undefined

export const getDaysLeft = (date: DateType): number | undefined => {
  let sign = 1
  if (moment(date as MomentInput).isBefore(moment())) {
    sign = -1
  }

  return date
    ? sign * (Math.abs(moment(date as MomentInput).diff(moment(), 'days')) + 1)
    : undefined
}
