import { PermissionsKey } from '@vedalib/crud'
import lodash from 'lodash'

import TemplateSaveModal from 'components/custom-forms/TemplateSaveModal/TemplateSaveModal'
import BlockHistory from 'components/editor-v3/Editor/Modals/BlockHistory'
import { MoveBlockToAnotherSectionModal } from 'components/editor-v3/Editor/Modals/BlockMoveToAnotherSectionModal'
import { useChangeTemplate } from 'components/editor-v3/Editor/Modals/useChangeTemplate'
import { useDeleteBlock } from 'components/editor-v3/Editor/Modals/useDeleteBlock'
import { useBlocksDuplicate } from 'components/editor-v3/context/EditorContext/actions'
import { useReorder } from 'components/editor-v3/cource/layout/controls/BlockControls/useReorder'
import { IMenuOption } from 'components/uiKit/Menu/MenuItem'
import { useOpenModal } from 'components/uiKit/Modal'
import { NOOP } from 'constants/commonConstans'
import { ScrollContainerEnum } from 'services/Scroll/enums'
import { setProjectNavigation, setToolbar } from 'services/Store/Project/actions'
import { BlockMode, AppMode, SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import {
  getBlock,
  getBlockIndex,
  getEditorMode,
  getSection,
  getSelectedBlocks,
  getToolbar,
} from 'services/Store/Project/selectors'
import { Block, Section } from 'services/Store/Project/types'
import { getEnumOption } from 'utils/enum'

import { useAddBlockTemplate } from '../Modals/useAddBlockTemplate'
import { ActionsConfig, BlockActionUiEnum } from '../types'

const getOptions = ({
  hasPrev,
  hasNext,
}: {
  hasPrev: boolean
  hasNext: boolean
}): { [key in BlockActionUiEnum]: IMenuOption<BlockActionUiEnum> } => ({
  [BlockActionUiEnum.GO_PREV]: getEnumOption('BlockActionUiEnum', BlockActionUiEnum.GO_PREV, {
    disabled: !hasPrev,
    icon: 'iconsForElementsLeft',
  }),
  [BlockActionUiEnum.GO_NEXT]: getEnumOption('BlockActionUiEnum', BlockActionUiEnum.GO_NEXT, {
    disabled: !hasNext,
    icon: 'iconsForElementsRight',
  }),
  [BlockActionUiEnum.MOVE_UP]: getEnumOption('BlockActionUiEnum', BlockActionUiEnum.MOVE_UP, {
    disabled: !hasPrev,
    icon: 'builderInputUp',
  }),
  [BlockActionUiEnum.MOVE_DOWN]: getEnumOption('BlockActionUiEnum', BlockActionUiEnum.MOVE_DOWN, {
    disabled: !hasNext,
    icon: 'builderInputDown',
  }),
  [BlockActionUiEnum.BLOCK_HISTORY]: getEnumOption(
    'BlockActionUiEnum',
    BlockActionUiEnum.BLOCK_HISTORY,
    {
      permission: {
        hidden: PermissionsKey.project_blocks_r,
      },
      icon: 'builderTimeline',
    },
  ),
  [BlockActionUiEnum.CLONE]: getEnumOption('BlockActionUiEnum', BlockActionUiEnum.CLONE, {
    permission: {
      hidden: PermissionsKey.project_blocks_c,
    },
    icon: 'otherCopy',
  }),
  [BlockActionUiEnum.COPY_TO_ANOTHER_SECTION]: getEnumOption(
    'BlockActionUiEnum',
    BlockActionUiEnum.COPY_TO_ANOTHER_SECTION,
    {
      permission: {
        hidden: PermissionsKey.project_blocks_c,
      },
      icon: 'iconsOtherMoveTo',
    },
  ),
  [BlockActionUiEnum.DELETE]: getEnumOption('BlockActionUiEnum', BlockActionUiEnum.DELETE, {
    permission: {
      hidden: PermissionsKey.project_blocks_d,
    },
    icon: 'otherTrash',
  }),
  [BlockActionUiEnum.SAVE_TEMPLATE]: getEnumOption(
    'BlockActionUiEnum',
    BlockActionUiEnum.SAVE_TEMPLATE,
    {},
  ),
  [BlockActionUiEnum.CHANGE]: getEnumOption('BlockActionUiEnum', BlockActionUiEnum.CHANGE, {
    icon: 'otherSwitch',
  }),
  [BlockActionUiEnum.ADD_BEFORE]: getEnumOption('BlockActionUiEnum', BlockActionUiEnum.ADD_BEFORE, {
    icon: 'otherAdd',
  }),
  [BlockActionUiEnum.ADD_AFTER]: getEnumOption('BlockActionUiEnum', BlockActionUiEnum.ADD_AFTER, {
    icon: 'otherAdd',
  }),
  [BlockActionUiEnum.SETTINGS]: getEnumOption('BlockActionUiEnum', BlockActionUiEnum.SETTINGS, {
    icon: 'mainMenuSettings',
  }),
})

const getAvailableActions = (section: Section | null, blocks: Block[], editorMode: AppMode) => {
  const actions: BlockActionUiEnum[] = []
  const hasStart = blocks.some((block) => block.mode === BlockMode.start)
  const hasEnd = blocks.some((block) => block.mode === BlockMode.end)
  const onlyView = blocks.every((block) => block.mode === BlockMode.view)
  const hasCover = blocks.some((block) => block.mode === BlockMode.cover)
  const isSingle = blocks.length === 1

  const arrowType = hasCover
    ? 'none'
    : (onlyView || section?.type === SectionTypeEnum.landing) && editorMode === AppMode.fill
      ? 'move'
      : 'go'
  if (editorMode === AppMode.fill) {
    actions.push(BlockActionUiEnum.SETTINGS)
  }

  if (arrowType === 'move') {
    actions.push(BlockActionUiEnum.MOVE_UP, BlockActionUiEnum.MOVE_DOWN)
  }

  if (arrowType === 'go') {
    actions.push(BlockActionUiEnum.GO_PREV, BlockActionUiEnum.GO_NEXT)
  }

  if (isSingle) {
    actions.push(
      BlockActionUiEnum.CHANGE,
      BlockActionUiEnum.SAVE_TEMPLATE,
      BlockActionUiEnum.BLOCK_HISTORY,
    )
  }

  if (!hasCover) {
    actions.push(
      BlockActionUiEnum.COPY_TO_ANOTHER_SECTION,
      BlockActionUiEnum.ADD_BEFORE,
      BlockActionUiEnum.ADD_AFTER,
    )
    if (!hasStart && !hasEnd) {
      actions.push(BlockActionUiEnum.CLONE, BlockActionUiEnum.DELETE)
    }
  }

  return actions
}

export const useBlockActions = (): ActionsConfig<BlockActionUiEnum> => {
  const section = useProjectContext(getSection)
  const dispatch = useProjectDispatch()
  const prev = useProjectContext(getBlock, { prev: true })
  const next = useProjectContext(getBlock, { next: true })
  const block = useProjectContext(getBlock)
  const index = useProjectContext(getBlockIndex, block?.uuid || '')
  const editorMode = useProjectContext(getEditorMode)
  const toolbar = useProjectContext(getToolbar)
  const cloneBlocks = useBlocksDuplicate(section?.id)
  const deleteBlock = useDeleteBlock()
  const onReorder = useReorder()
  const addTemplate = useAddBlockTemplate()
  const changeTemplate = useChangeTemplate()
  const versionModal = useOpenModal(BlockHistory)

  const selectedBlocks = useProjectContext(getSelectedBlocks)
  const moveBlockToAnotherSectionModal = useOpenModal(MoveBlockToAnotherSectionModal, {
    selectedBlocks,
  })

  const createTemplateModal = useOpenModal(TemplateSaveModal, {
    sectionType: section?.type || SectionTypeEnum.landing,
  })

  const options = getOptions({ hasPrev: !!prev, hasNext: !!next })

  const addBlockTop = () => addTemplate(false)
  const addBlockBottom = () => addTemplate()
  const moveBlockDown = () => onReorder(index, index + 1)
  const moveBlockUp = () => onReorder(index, index - 1)

  const handleGoLeft = () => {
    if (prev) {
      dispatch(
        setProjectNavigation({
          blockId: prev.uuid,
          scroll: {
            container: ScrollContainerEnum.canvas,
            id: prev.uuid,
            block: 'begin',
            scroll: ['never', 'always'],
          },
        }),
      )
    }
  }

  const handleGoRight = () => {
    if (next) {
      dispatch(
        setProjectNavigation({
          blockId: next.uuid,
          scroll: {
            container: ScrollContainerEnum.canvas,
            id: next.uuid,
            block: 'begin',
            scroll: ['never', 'always'],
          },
        }),
      )
    }
  }

  if (!block?.uuid || !block?.mode || !editorMode) {
    return {
      availableActions: [],
      options,
      handleAction: NOOP,
      hasAction: () => false,
    }
  }

  const openBlockSettings = () => {
    if (editorMode === AppMode.pro) {
      dispatch(setProjectNavigation({ nodeId: null }))
    }

    if (editorMode === AppMode.fill) {
      if (toolbar.blockSettings) {
        dispatch(setToolbar({ blockSettings: null }))
      } else {
        dispatch(
          setToolbar({
            commentTab: null,
            taskTab: null,
            blockSettings: block.uuid,
          }),
        )
      }
    }
  }

  const actionHandlers: { [key in BlockActionUiEnum]: () => void } = {
    [BlockActionUiEnum.CHANGE]: changeTemplate,
    [BlockActionUiEnum.CLONE]: () => cloneBlocks(),
    [BlockActionUiEnum.DELETE]: deleteBlock,
    [BlockActionUiEnum.SAVE_TEMPLATE]: () => createTemplateModal.open({ block }),
    [BlockActionUiEnum.BLOCK_HISTORY]: () => versionModal.open(),
    [BlockActionUiEnum.COPY_TO_ANOTHER_SECTION]: () => moveBlockToAnotherSectionModal.open(),
    [BlockActionUiEnum.ADD_BEFORE]: addBlockTop,
    [BlockActionUiEnum.ADD_AFTER]: addBlockBottom,
    [BlockActionUiEnum.MOVE_UP]: moveBlockUp,
    [BlockActionUiEnum.MOVE_DOWN]: moveBlockDown,
    [BlockActionUiEnum.GO_NEXT]: handleGoRight,
    [BlockActionUiEnum.GO_PREV]: handleGoLeft,
    [BlockActionUiEnum.SETTINGS]: openBlockSettings,
  }
  const availableActions = getAvailableActions(section, selectedBlocks, editorMode)

  const handleAction = ({ value }: IMenuOption<BlockActionUiEnum>) => {
    actionHandlers[value]()
  }

  const hasAction = (...actions: BlockActionUiEnum[]) =>
    lodash.intersection(availableActions, actions).length > 0

  return {
    availableActions,
    options,
    handleAction,
    hasAction,
  }
}
