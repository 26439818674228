import { PermissionsKey } from '@vedalib/crud'
import cn from 'classnames'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router'

import EditorCommentBubble from 'components/comments/EditorCommentBubble'
import EditorCreateComment from 'components/comments/EditorCreateComment'
import { PreviewBlockActionUiEnum } from 'components/editor-v3/Editor/types'
import { getRootNode } from 'components/editor-v3/context/EditorContext/selectors/block'
import { IMenuOption } from 'components/uiKit/Menu'
import { NOOP } from 'constants/commonConstans'
import { EDITOR_PATHS } from 'constants/paths'
import generateLink from 'routes/generateLink'
import { usePathParams } from 'routes/hooks'
import { usePermissions } from 'services/Permission/PermissionContext'
import { scrollProps } from 'services/Scroll/ScrollService'
import { setCommentForm } from 'services/Store/Project/actions'
import { DeviceMode, AppMode, PreviewMode } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch, useProjectPath } from 'services/Store/Project/hooks'
import { getIsCommentedBlock, getIsCommenting, getSection } from 'services/Store/Project/selectors'
import { Block, IBlockMode } from 'services/Store/Project/types'
import { getEnumOption } from 'utils/enum'
import { testProps } from 'utils/test/qaData'

import NodeContainer from '../../Node/NodeContainer'
import ControlItem from '../../controls/ControlItem'
import ControlsGroup from '../../controls/ControlsGroup/ControlsGroup'
import * as s from './PreviewBlock.module.scss'

interface IPreviewBlockProps {
  block: Block
  mode: IBlockMode
  index: number
  rootRef?: React.RefObject<HTMLDivElement>
}

const BLOCK_ACTIONS: { [key in PreviewBlockActionUiEnum]: IMenuOption<PreviewBlockActionUiEnum> } =
  {
    [PreviewBlockActionUiEnum.EDIT]: getEnumOption(
      'PreviewBlockActionUiEnum',
      PreviewBlockActionUiEnum.EDIT,
      {
        icon: 'penEdit',
      },
    ),
    [PreviewBlockActionUiEnum.ADD_COMMENT]: getEnumOption(
      'PreviewBlockActionUiEnum',
      PreviewBlockActionUiEnum.ADD_COMMENT,
      {
        icon: 'messagesChatRegular',
      },
    ),
  }

const PreviewBlock: React.FC<IPreviewBlockProps> = ({ block, index, mode }) => {
  const permissions = usePermissions()
  const path = useProjectPath()
  const history = useHistory()
  const root = getRootNode(block)
  const section = useProjectContext(getSection)
  const isDone = (section?.isDone || block?.isDone) && mode.previewMode === PreviewMode.skeleton
  const maxWidth = root?.style?.[DeviceMode.desktop]?.maxWidth
  const isPreview = mode.previewMode === PreviewMode.preview
  const isCommented = useProjectContext(getIsCommentedBlock, block.uuid, null)
  const isCommenting = useProjectContext(getIsCommenting, block.uuid, null)
  const params = usePathParams(path)
  const dispatch = useProjectDispatch()
  const canComment = permissions.has(PermissionsKey.project_comments_c)

  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    const link = generateLink(EDITOR_PATHS.main, { ...params, blockId: block.uuid })
    history.push(link)
  }

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (canComment && mode.editorMode === AppMode.comment) {
        e.stopPropagation()
        dispatch(setCommentForm({ blockId: block.uuid, elementId: null }))
      }
    },
    [canComment, mode.editorMode, dispatch, block.uuid],
  )

  const canUpdateSection = permissions.has(PermissionsKey.project_sections_u)

  return (
    <div
      {...scrollProps(block.uuid)}
      {...testProps({ el: 'block', name: block.name, id: block.uuid, index })}
      className={cn(s.canvas, {
        [s.isDone]: isDone,
        [s.active]: isCommenting,
        [s.comments]: canComment && mode.editorMode === AppMode.comment,
      })}
      onClick={handleClick}
      style={{ maxWidth }}
    >
      <NodeContainer block={block} id={root.id} mode={mode} />
      {isCommented && mode.editorMode === AppMode.comment && (
        <EditorCommentBubble blockId={block.uuid} />
      )}
      {isCommenting && <EditorCreateComment blockId={block.uuid} />}
      {isPreview && canUpdateSection && mode.editorMode !== AppMode.comment && (
        <div className={s.blockActions}>
          <ControlsGroup>
            {canUpdateSection && (
              <div onClick={handleEditClick}>
                <ControlItem handler={NOOP} option={BLOCK_ACTIONS[PreviewBlockActionUiEnum.EDIT]} />
              </div>
            )}
          </ControlsGroup>
        </div>
      )}
    </div>
  )
}

export default PreviewBlock
