// extracted by mini-css-extract-plugin
var _1 = "FileLoader_active__g-jyU";
var _2 = "FileLoader_bold__NljIt";
var _3 = "FileLoader_buttons__FMvWv";
var _4 = "FileLoader_description__2Z4qx";
var _5 = "FileLoader_editButton__xaXbI";
var _6 = "FileLoader_empty__+l6DJ";
var _7 = "FileLoader_fields__CaxpU";
var _8 = "FileLoader_footer__TXzCQ";
var _9 = "FileLoader_full__HoWQe";
var _a = "FileLoader_icon__rruk2";
var _b = "FileLoader_info__qWTJy";
var _c = "FileLoader_infoIcon__zvvkA";
var _d = "FileLoader_label__xKeVA";
var _e = "FileLoader_link__zZ3gl";
var _f = "FileLoader_preview__xkfnq";
var _10 = "FileLoader_root__HhqLf";
var _11 = "FileLoader_title__9a0Uy";
var _12 = "FileLoader_wrapper__pE5Du";
export { _1 as "active", _2 as "bold", _3 as "buttons", _4 as "description", _5 as "editButton", _6 as "empty", _7 as "fields", _8 as "footer", _9 as "full", _a as "icon", _b as "info", _c as "infoIcon", _d as "label", _e as "link", _f as "preview", _10 as "root", _11 as "title", _12 as "wrapper" }
