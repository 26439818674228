import lodash from 'lodash'

import {
  FileMetaSchemaFragment,
  FilesGroupsFragment,
  ProjectGroupListSchemaFragment,
  ProjectListSchemaFragment,
} from 'gql/__generated__/graphql'
import { EntryTypeName, FinderItem } from 'store/models'

import { IHighlighterProps } from '../Highlighter'
import { ColumnState, ColumnType, Highlighted } from './types'

export const getFinderRecordName = (
  record: FileMetaSchemaFragment | FilesGroupsFragment,
): string => {
  if (!('type' in record)) {
    return record.name
  }

  const stringifiedName = record.name.toString()
  const lastIndexOfDot = stringifiedName.lastIndexOf('.')

  return stringifiedName.slice(0, lastIndexOfDot)
}

export const getNameString = (
  name?: string | React.ReactElement<IHighlighterProps> | null,
): string | null | undefined => {
  if (!name) {
    return name
  }

  if (typeof name === 'string') {
    return name
  }

  return name?.props.text
}

const FOLDER_TYPES = [EntryTypeName.projectGroup, EntryTypeName.fileMetaGroup]

type ItemsForGrouping = (
  | FinderItem
  | Highlighted<ProjectListSchemaFragment | ProjectGroupListSchemaFragment>
)[]

export const getDataGrouping = (items?: ItemsForGrouping) => {
  const { groups, elements } = lodash.groupBy(items, (i) =>
    FOLDER_TYPES.includes(i?.__typename as EntryTypeName) ? 'groups' : 'elements',
  )

  return {
    groupIds: groups?.length ? groups.map((i) => i.id) : null,
    elementIds: elements?.length ? elements.map((i) => i.id) : null,
  }
}

export const getColumnKey = (dataIndex: string | string[]) => {
  if (typeof dataIndex === 'string') {
    return dataIndex
  }

  return dataIndex.join('.')
}

const getColumnDefaultState = (
  column: Pick<ColumnType<unknown>, 'dataIndex'>,
): ColumnState | undefined =>
  column.dataIndex
    ? {
        key: getColumnKey(column.dataIndex),
        visible: true,
      }
    : undefined

export const getColumnsDefaultState = (columns: Pick<ColumnType<unknown>, 'dataIndex'>[]) =>
  columns.map(getColumnDefaultState).filter(Boolean) as ColumnState[]

export const getColumnTitle = (
  columnKey: string,
  columns: Pick<ColumnType<unknown>, 'dataIndex' | 'title'>[],
) => {
  const column = columns.find(
    (column) => column.dataIndex && getColumnKey(column.dataIndex) === columnKey,
  )
  return column?.title
}
