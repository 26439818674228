import cn from 'classnames'
import React, { useState, useEffect, ReactNode, useRef, CSSProperties } from 'react'

import { ITabsMain } from '../Tabs/types'
import TabItem from './TabItem'
import * as s from './TabMenu.module.scss'

export interface ITabItemProps<V> {
  value: V
  label: string | ReactNode
  disabled?: boolean
  hidden?: boolean
}

export interface ITabMenuProps<V> extends Partial<ITabsMain> {
  children?: ReactNode
  onChange?: (tabKey: V) => void
  tabBarExtraContent?: { right?: ReactNode | string; left?: ReactNode | string; grow?: boolean }
  tabsGrow?: boolean
  tabList: ITabItemProps<V>[]
  activeKey: V
  spaceTabsAndExtraContent?: boolean
  tabMenuStyle?: React.CSSProperties
}

const TabMenu = <V extends string>({
  children,
  styleType = 'base',
  direction = 'top',
  onChange,
  grow,
  tabPosition = 'tabLeft',
  tabBarExtraContent,
  size,
  tabsGrow = false,
  tabList,
  activeKey,
  tabMenuStyle,
  spaceTabsAndExtraContent = false,
}: ITabMenuProps<V>) => {
  // const refContainer = useRef<HTMLDivElement>(null)
  // const state = useSize(refContainer)
  // const [activeKey, setActiveKey] = useState(activeKeyProps)
  const [checkedStyles, setCheckedStyles] = useState<CSSProperties>({})
  const directionHorizontalTabs = direction === 'left' || direction === 'right'
  const directionLeftTabs = direction === 'left'
  const scrollableTabsContainer = useRef<HTMLDivElement>(null)
  const activeTabItemRef = useRef<HTMLDivElement>(null)

  // const isDrawArrow = useMemo(() => {
  //   // const tabsContainerWidth = scrollableTabsContainer.current?.clientWidth || 0
  //   // const tabsContainerScrollWidth = scrollableTabsContainer.current?.scrollWidth || 0
  //   // return tabsContainerWidth < tabsContainerScrollWidth
  // }, [state, scrollableTabsContainer.current, refContainer?.current])

  const cnControls = cn(s.controls, s[direction], { [s.spaceBetween]: spaceTabsAndExtraContent })
  const cnTabListWrap = cn(s.tabListWrap, {
    [s.contentPosition]: direction === 'right',
    // [s.isDrawArrow]: isDrawArrow,
  })
  const cnTabList = cn(s.tabList, s[direction], s[styleType], {
    [s.tabsGrow]: tabsGrow,
  })
  const cnTabBarExtraContent = cn(tabBarExtraContent?.grow && s.tabBarExtraContentGrow)

  const onChangeTab = (tabKey: V) => onChange?.(tabKey)

  // const handleNext = (isRight: boolean) => {
  //   const directionScroll = isRight ? 1 : -1
  //   const indexItem = tabList.findIndex((tab) => tab.value === activeKey)
  //   if (tabList[indexItem + directionScroll]) {
  //     onChange && onChange(tabList[indexItem + directionScroll].value)
  //   }
  // }

  useEffect(() => {
    // calculate styles for underline by direction tabs
    if (activeTabItemRef.current) {
      const { offsetLeft, offsetTop, clientWidth, clientHeight } = activeTabItemRef.current
      const transform = `translate(${offsetLeft}px, ${offsetTop}px)`
      if (directionHorizontalTabs) {
        setCheckedStyles({
          height: clientHeight,
          width: 2,
          top: 0,
          right: directionLeftTabs ? 'auto' : 0,
          transform,
        })
      } else {
        setCheckedStyles({
          left: 0,
          width: clientWidth,
          transform,
        })
      }
    }
  }, [
    activeTabItemRef.current?.clientWidth,
    activeTabItemRef.current?.offsetLeft,
    children,
    direction,
    directionHorizontalTabs,
    directionLeftTabs,
    activeKey,
  ])

  return (
    <div className={cn(s.root, s[direction])} style={tabMenuStyle}>
      <div className={cnControls}>
        {/* {isDrawArrow && (
          <span className={s.arrowLeft} onClick={() => handleNext(false)}>
            <Icon name='arrowTabs' size={KitSize.M} />
          </span>
        )} */}
        {tabBarExtraContent?.left && (
          <div className={cnTabBarExtraContent}>{tabBarExtraContent.left}</div>
        )}
        <div className={cnTabListWrap} ref={scrollableTabsContainer}>
          <div className={cnTabList}>
            {tabList
              .filter(({ hidden }) => !hidden)
              .map(({ label, value, disabled }) => {
                const isActive = activeKey === value
                return (
                  <TabItem
                    direction={direction}
                    directionHorizontalTabs={directionHorizontalTabs}
                    disabled={disabled}
                    grow={grow}
                    isActive={isActive}
                    key={value}
                    onChangeTab={onChangeTab}
                    ref={(isActive && activeTabItemRef) || null}
                    size={size}
                    styleType={styleType}
                    tabKey={value}
                    tabPosition={tabPosition}
                    title={label}
                  />
                )
              })}
            {styleType !== 'text' && (
              <span
                className={cn(s.activeTab, { [s[direction]]: direction })}
                style={checkedStyles}
              />
            )}
          </div>
        </div>
        {tabBarExtraContent?.right && (
          <div className={cnTabBarExtraContent}>{tabBarExtraContent.right}</div>
        )}
        {/* {isDrawArrow && (
          <span className={s.arrowRight} onClick={() => handleNext(true)}>
            <Icon name='arrowTabs' size={KitSize.M} />
          </span>
        )} */}
      </div>
    </div>
  )
}

export default TabMenu
