import lodash from 'lodash'

import Progress, { ProgressType } from 'components/editor-v3/cource/components/Progress'
import { PreviewMode } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getProgress } from 'services/Store/Project/selectors'

import { useElementRootStyles } from '../../hooks/useElementRootStyles'
import { CourseProgressElementType } from './CourseProgress.types'
import * as s from './CourseProgressElement.module.scss'

const DEFAULT_PROGRESS = 50

const Element: CourseProgressElementType = ({ styles, element, mode, font }) => {
  const isEditor = mode.previewMode === PreviewMode.editor
  const isDemo = mode.previewMode === PreviewMode.demo
  const progressFractional = useProjectContext(getProgress)
  const {
    value: { percentPosition },
  } = element
  const progress = isEditor || isDemo ? DEFAULT_PROGRESS : Math.round(progressFractional)
  const rootStyles = useElementRootStyles(styles.root)
  const shellStyles = lodash.pick(styles.shell, [
    'maxHeight',
    'maxWidth',
    'minHeight',
    'minWidth',
    'width',
    'height',
  ])

  return (
    <div className={s.root} style={{ ...rootStyles, ...shellStyles, ...font.base }}>
      <Progress
        boxShadow={styles.effects.boxShadow}
        customStyles={{
          trail: styles.chartEmpty.color as string,
          stroke: styles.chartFill.color as string,
        }}
        label={percentPosition !== 'hide' ? `${progress}%` : undefined}
        name='courseProgress'
        percent={progress}
        reverseLabel={percentPosition === 'left'}
        styleType={ProgressType.line}
      />
    </div>
  )
}

export default Element
