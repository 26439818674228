import { motion, MotionProps } from 'framer-motion'
import React, { useId, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useContextSelector } from 'use-context-selector'

import { stopPropagation } from 'constants/commonConstans'
import { scrollProps } from 'services/Scroll/ScrollService'
import { testPropsEl } from 'utils/test/qaData'

import * as s from './AbsolutePortal.module.scss'
import AbsolutePortalContext from './AbsolutePortalContext'
import { usePortalPosition } from './hooks'
import { IPositionArgs } from './types'

type AnimationProps = Pick<
  MotionProps,
  'initial' | 'animate' | 'layout' | 'layoutId' | 'whileTap' | 'whileHover'
>

interface IBlockAbsolutePortalProps extends IPositionArgs, AnimationProps {
  children: React.ReactNode
  scrollId?: string
  name: string
  offset?: number | string
}

const AbsolutePortal: React.FC<IBlockAbsolutePortalProps> = ({
  children,
  initial,
  offset,
  animate,
  layout,
  layoutId,
  scrollId,
  whileHover,
  whileTap,
  ...props
}) => {
  const id = useId()
  const ref = useRef<HTMLDivElement>(null)
  const measureRef = useRef<HTMLDivElement>(null)
  const portalsRef = useContextSelector(AbsolutePortalContext, (v) => v.portalsRef.current)
  usePortalPosition(id, measureRef, ref, props)

  return (
    <>
      <div className={s.measure} ref={measureRef} />
      {portalsRef &&
        createPortal(
          <div
            {...testPropsEl('absolutePortal', { name: props.name })}
            {...scrollProps(scrollId)}
            className={s.portal}
            onClick={stopPropagation}
            onMouseDown={stopPropagation}
            ref={ref}
            style={{ margin: offset }}
          >
            <motion.div
              animate={animate}
              className={s.children}
              initial={initial}
              layout={layout}
              layoutId={layoutId}
              whileHover={whileHover}
              whileTap={whileTap}
            >
              {children}
            </motion.div>
          </div>,
          portalsRef,
        )}
    </>
  )
}

export default React.memo(AbsolutePortal)
