import { FORMAT_FIELDS, SlateFormats } from 'components/AppText/constants'
import { getOnUpdateFormat, useAppTextSelector } from 'components/AppText/useAppTextSelector'
import { IconButton } from 'components/uiKit/Button'
import { Tooltip } from 'components/uiKit/Dropdown'
import { KeyIconsType } from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { preventDefault } from 'constants/commonConstans'
import { t } from 'services/Translation'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RadioButtonTool: React.FC<{ name: SlateFormats } & { [key: string]: any }> = ({
  name,
  ...rest
}) => {
  const onUpdateFormat = useAppTextSelector(getOnUpdateFormat)

  return (
    <Tooltip overlay={t(`uiKit.tooltip.${name}` as const)}>
      <IconButton
        icon={FORMAT_FIELDS[name]?.icon as KeyIconsType}
        name={name}
        onClick={() => onUpdateFormat(name, null)}
        onMouseDown={preventDefault}
        size={KitSize.S}
        styleType='ghost'
        {...rest}
      />
    </Tooltip>
  )
}

export default RadioButtonTool
