// extracted by mini-css-extract-plugin
var _1 = "TabItem_active__qLWtv";
var _2 = "TabItem_base__p2mwu";
var _3 = "TabItem_clear__3eucI";
var _4 = "TabItem_disabled__gqdIl";
var _5 = "TabItem_grow__Onayg";
var _6 = "TabItem_horizontal__Ixmhl";
var _7 = "TabItem_large__L7hVi";
var _8 = "TabItem_medium__VJL+Y";
var _9 = "TabItem_mini__O9vC8";
var _a = "TabItem_root__fadDf";
var _b = "TabItem_tabCenter__JyODk";
var _c = "TabItem_tabLeft__qXDWe";
var _d = "TabItem_tabRight__YpeZ5";
var _e = "TabItem_text__V0oZx";
export { _1 as "active", _2 as "base", _3 as "clear", _4 as "disabled", _5 as "grow", _6 as "horizontal", _7 as "large", _8 as "medium", _9 as "mini", _a as "root", _b as "tabCenter", _c as "tabLeft", _d as "tabRight", _e as "text" }
