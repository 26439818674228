import cn from 'classnames'
import React from 'react'

import Icon from 'components/uiKit/Icon'
import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import {
  TemplatesListSchemaFragment,
  EmployeePreferencesTab,
  EditorTemplateAllTab,
} from 'gql/__generated__/graphql'
import { useTemplatesAddToFavs, useTemplatesRemoveFromFavs } from 'gql/templates/apollo'
import { t } from 'services/Translation'
import { testProps } from 'utils/test/qaData'

import * as s from './FavoriteIcon.module.scss'

interface IFavoriteIcon {
  template: TemplatesListSchemaFragment
  tab?: EmployeePreferencesTab | EditorTemplateAllTab
  companyId: string
  projectId?: string
}

const FavoriteIcon: React.FC<IFavoriteIcon> = ({ template, companyId, tab, projectId }) => {
  const { uuid, isFavorite } = template
  const [templatesAddToFavs] = useTemplatesAddToFavs()
  const [templatesRemoveFromFavs] = useTemplatesRemoveFromFavs()

  const onFavButtonClick = async () => {
    if (isFavorite) {
      await templatesRemoveFromFavs({
        //@ts-expect-error TODO
        variables: { companyId, uuids: [uuid], isFav: tab !== EmployeePreferencesTab.favorites },
      })
      notify({ type: NotificationType.success, message: t('notify.removeTemplateFromFav') })
    } else {
      await templatesAddToFavs({
        variables: { companyId, uuids: [uuid], ...(tab === 'collections' && { projectId }) },
      })
      notify({ type: NotificationType.success, message: t('notify.addTemplateToFav') })
    }
  }
  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onFavButtonClick()
  }

  return (
    <div
      className={cn(s.root, isFavorite && s.active)}
      onClick={onClick}
      {...testProps({ el: 'favorite', active: isFavorite, label: template?.name })}
    >
      <Icon name={isFavorite ? 'bookmarkFill' : 'bookmarkStroke'} />
    </div>
  )
}

export default FavoriteIcon
