"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.MIN_CELL_WIDTH = exports.DEFAULT_CELL_WIDTH = exports.TYPE = void 0;
const rich_text_1 = require("@vedalib/rich-text");
exports.TYPE = 'table';
exports.DEFAULT_CELL_WIDTH = 150;
exports.MIN_CELL_WIDTH = 100;
exports.DEFAULT_VALUE = {
    headerRow: false,
    headerColumn: false,
    columns: [
        {
            width: exports.DEFAULT_CELL_WIDTH,
        },
        {
            width: exports.DEFAULT_CELL_WIDTH,
        },
    ],
    cells: [
        [{ value: (0, rich_text_1.textToRtValue)('') }, { value: (0, rich_text_1.textToRtValue)('') }],
        [{ value: (0, rich_text_1.textToRtValue)('') }, { value: (0, rich_text_1.textToRtValue)('') }],
    ],
};
