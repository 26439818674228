import cn from 'classnames'

import { getFileMetaByIdFromCache } from 'gql/files/apollo'
import { useBrandTheme } from 'services/Branding/hooks'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getSection, isDisabledNavigation } from 'services/Store/Project/selectors'
import { useNavigationAction } from 'services/Store/Project/triggers'
import { COMPANY_DEFAULT_LOGO, getOriginalImageById } from 'utils/files'
import { testProps } from 'utils/test/qaData'

import * as s from './CourseHeaderLogo.module.scss'

interface ICourseHeaderLogoProps {
  projectLogoId?: string | null
  title?: string | null
  disabled?: boolean
}

const CourseHeaderLogo: React.FC<ICourseHeaderLogoProps> = ({ projectLogoId, title, disabled }) => {
  const cover = useProjectContext(getSection, { cover: true })
  const firstSection = useProjectContext(getSection, { first: true })
  const firstSectionId = cover?.id || firstSection?.id
  const onNavigate = useNavigationAction()
  const isDisabledSidebarAndHome = useProjectContext(isDisabledNavigation)
  const theme = useBrandTheme()
  const logoData = getOriginalImageById(theme?.logoHeader || projectLogoId)
  const logo = getFileMetaByIdFromCache(String(theme?.logoHeader || projectLogoId || ''))
  const logoPath = logoData?.path || COMPANY_DEFAULT_LOGO
  const disabledNavigate = isDisabledSidebarAndHome || disabled || !firstSectionId

  const goFirstSection = () => !disabledNavigate && onNavigate({ sectionId: firstSectionId })

  return (
    <div
      {...testProps({ el: 'courseLogo', logo: logo?.id, title })}
      className={cn(s.root, !disabledNavigate && s.active)}
      onClick={goFirstSection}
    >
      <img alt='Logo' className={s.image} src={logoPath} />
    </div>
  )
}

export default CourseHeaderLogo
