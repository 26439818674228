import { chainName, FileTypeEnum } from 'utils'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import { ImageValueFormType } from './ImageEl.types'

const genField = genDataField<Block>()

const image = genField({
  name: 'image',
  type: 'file',
  layout: 'horizontal',
  params: {
    fileType: FileTypeEnum.IMAGE,
    nullable: true,
    preview: true,
    showSource: true,
    hasAccessibility: true,
  },
})

const zoomable = genField({
  name: 'zoomable',
  label: t('elements.image.form.zoomable'),
  type: 'segmented',
  layout: 'horizontal',
  defaultValue: false,
})

export const overlay = genField({
  name: 'overlay',
  type: 'color',
  label: t('input.label.overlay'),
  defaultValue: '#FFFFFF00',
  layout: 'horizontal',
  params: {
    labeled: true,
  },
})

const ImageElForm: ImageValueFormType = ({ name }) => {
  return (
    <Padding sides>
      <RealtimeField config={chainName(name, image)} />
      <RealtimeField config={chainName(name, overlay)} />
      <RealtimeField config={chainName(name, zoomable)} />
    </Padding>
  )
}

export default ImageElForm
