import cn from 'classnames'
import React from 'react'

import { testPropsEl } from 'utils/test/qaData'

import { KitIndents, KitSize } from '../KitTypes'
import * as s from './Divider.module.scss'

interface IDividerProps {
  styleType?: 'vertical' | 'horizontal' | 'dash'
  size?: 'auto' | KitSize
  free?: boolean
  dark?: boolean
  indents?: KitIndents
}

const Divider: React.FC<IDividerProps> = ({
  styleType = 'horizontal',
  size = KitSize.M,
  indents,
  free,
  dark,
}) => {
  return (
    <hr
      {...testPropsEl('divider', {})}
      className={cn(s[styleType], s[size], { [s.free]: free, [s.dark]: dark })}
      style={indents}
    />
  )
}

export default React.memo(Divider)
