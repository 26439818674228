import { RichTextValue, textToRtValue } from '@vedalib/rich-text'
import cn from 'classnames'
import lodash from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useContext } from 'use-context-selector'
import { swapItems } from 'utils'

import Dropdown from 'components/editor-v2/EditorElements/courseKit/DropDownCourse'
import {
  COURSE_TOOLTIP,
  COURSE_TOOLTIP_TEST_MATCHING,
} from 'components/editor-v2/EditorElements/courseKit/portalsCurseKit'
import { TestAnswerFeedback } from 'components/editor-v2/EditorElements/decorators/TestAnswerFeedback/TestAnswerFeedback'
import RichText from 'components/form/RichText/RichText'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { setQuestion } from 'services/Store/Project/actions'
import { PreviewMode } from 'services/Store/Project/enums'
import { useProjectDispatch } from 'services/Store/Project/hooks'

import { MatchingGroups } from '../../TestMatchingElement.types'
import TestMatchingContext from '../TestMatchingContext'
import AnswerTooltip from './AnswerTooltip'
import * as s from './MobileElement.module.scss'

const OVERLAY_STYLE = { top: 0, left: 0, right: 0, bottom: 0 }
const DELAY_ACTION = 100

const MobileElement = ({ originLeft }: { originLeft: MatchingGroups[] }) => {
  const {
    setDraggableId,
    right,
    left,
    elementId,
    block,
    validOrderMap,
    styles,
    isVisible,
    draggableId,
    mode,
    value,
    state,
    font,
    isActiveElement,
    onLabelSelect,
    activeIndex,
    onChangeLabel,
    isFill,
    waiting,
  } = useContext(TestMatchingContext)
  const dispatch = useProjectDispatch()
  const [pairs, setPairs] = useState(left.reduce((acc, item) => ({ ...acc, [item.value]: -1 }), {}))
  const [isAdditionOpen, setAdditionOpen] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const isPreview = mode.previewMode !== PreviewMode.editor
  const getContainer = () =>
    (isPreview
      ? document.getElementById(COURSE_TOOLTIP)
      : document.getElementById(COURSE_TOOLTIP_TEST_MATCHING)) || document.body

  const borderStyle = (direction: string) => ({
    [`border${direction}RightRadius`]: styles.pair.borderRadius,
    [`border${direction}LeftRadius`]: styles.pair.borderRadius,
    ...styles.border,
  })

  const handleLeftClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setDraggableId((e.currentTarget as Element).id)
  }

  const handleRightClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const id = Number((e.currentTarget as Element).id)
      setPairs((prev) => {
        const newPrev = lodash.omit(prev, lodash.findKey(prev, (o) => o === id) || '')
        return draggableId && { ...newPrev, [draggableId]: id }
      })
      const leftId = left.findIndex((item) => item.value === draggableId)

      const newLeftItems = swapItems(left, leftId, Number((e.currentTarget as Element).id)).map(
        (item) => {
          return item.value === draggableId ? { ...item, isActive: true } : item
        },
      )
      if (isPreview) {
        setAdditionOpen(false)
      }
      dispatch(
        setQuestion({
          elementId,
          value: {
            value: newLeftItems.map((item) => item.value),
            shuffledItems: newLeftItems,
          },
          isReady: newLeftItems.every(({ isActive }) => isActive),
          blockId: block?.uuid || '',
        }),
      )
    },
    [block?.uuid, dispatch, draggableId, elementId, isPreview, left],
  )

  const rtToText = useCallback((rt: RichTextValue) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return rt ? (rt[0] as any)?.children[0]?.text : ''
  }, [])

  const findRightLabel = useCallback(
    (value: string) => {
      const item =
        pairs[value as keyof typeof pairs] !== -1
          ? right[pairs[value as keyof typeof pairs]]
          : { label: textToRtValue('') }
      return rtToText(item?.label)
    },
    [pairs, right, rtToText],
  )

  const hideTooltip = useCallback(() => setAdditionOpen(false), [])

  useEffect(() => {
    if (!state?.value) {
      setPairs({})
    } else {
      left.forEach((item, index) => {
        if (item.isActive) {
          setPairs((prev) => ({ ...prev, [item.value]: index }))
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.value])

  // const rtStyles = useElementStyle(DeviceMode.desktop, 'richText', {
  //   [DeviceMode.desktop]: { body__var: styles.text },
  // })

  return (
    <div className={s.root}>
      <Dropdown
        container={getContainer()}
        defaultPopupVisible={isAdditionOpen}
        delay={DELAY_ACTION}
        onVisibleChange={setAdditionOpen}
        overlay={
          <AnswerTooltip
            disabled={lodash.values(lodash.omit(pairs, draggableId as keyof typeof pairs))}
            font={font}
            handleRightClick={handleRightClick}
            hideTooltip={hideTooltip}
            index={currentIndex}
            selected={pairs[draggableId as keyof typeof pairs]}
            title={left.find((item) => item.value === draggableId)?.label || textToRtValue('')}
          />
        }
        overlayStyle={OVERLAY_STYLE}
        styleType='clear'
        visible={isAdditionOpen}
      >
        <div>
          {(isPreview ? originLeft : left).map((item, index) => {
            const res: { [id: string]: number } = lodash.mapValues(pairs, (val) => parseInt(val))
            return (
              <div
                className={s.item}
                id={item.value}
                key={item.value}
                onClick={(e) => {
                  setCurrentIndex(index)
                  handleLeftClick(e)
                }}
              >
                <TestAnswerFeedback
                  deviceMode={mode.deviceMode}
                  isTrueAnswer={validOrderMap[item.value] === res[item.value]}
                  isVisible={!!isVisible}
                  showAnswerForCurrentElement={!!isVisible}
                >
                  <div className={s.row}>
                    <div
                      className={cn(s.fact, { [s.active]: isVisible })}
                      style={{
                        ...font?.base,
                        marginBottom:
                          value.items.length - 1 !== index
                            ? styles.indents.marginBottom
                            : undefined,
                      }}
                    >
                      <div
                        className={s.topLabel}
                        onMouseDown={(e) => onLabelSelect(index.toString(), e)}
                        style={{ ...borderStyle('Top'), padding: styles.indents.padding }}
                      >
                        <RichText
                          active={isActiveElement && activeIndex === index.toString()}
                          disabled={!isFill}
                          name={`mobileMatchingLeft.${index}`}
                          onChange={(val) => onChangeLabel(val, 'leftLabel', index)}
                          styles={font}
                          value={item.label}
                          waiting={waiting}
                        />
                      </div>
                      <div
                        className={cn(s.bottomLabel, {
                          [s.empty]: lodash.isEmpty(findRightLabel(item.value)),
                        })}
                        style={{
                          ...borderStyle('Bottom'),
                          minHeight: styles.pair.borderRadius,
                          padding: styles.indents.padding,
                        }}
                      >
                        {findRightLabel(item.value)}
                      </div>
                    </div>
                    {!isVisible && (
                      <div className={s.icon}>
                        {styles.mobileIcon.backgroundImage ? (
                          <div className={s.customIcon} style={styles.mobileIcon} />
                        ) : (
                          <div className={s.arrowIcon}>
                            <Icon name='otherDown' size={KitSize.S} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </TestAnswerFeedback>
              </div>
            )
          })}
        </div>
      </Dropdown>
    </div>
  )
}

export default MobileElement
