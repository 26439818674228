import React from 'react'
import { createPortal } from 'react-dom'

import { DropdownAnimationProps, IDropdownCourseContent } from './DropdownCourse'
import ContentRootStyled from './styled/ContentStyled'

interface IDropdownCourseContentProps extends DropdownAnimationProps {
  children: React.ReactNode
  style: React.CSSProperties
  disbale?: boolean
  container?: HTMLElement
  type: IDropdownCourseContent['type']
  textError?: string
  theme?: IDropdownCourseContent['theme']
  styleType?: IDropdownCourseContent['styleType']
}

const DropdownCourseContent = React.forwardRef<HTMLDivElement, IDropdownCourseContentProps>(
  (
    {
      children,
      style,
      disbale,
      container = document.body,
      type,
      textError,
      theme,
      styleType,
      ...rest
    },
    ref,
  ) => {
    const child = (
      <ContentRootStyled
        $disbale={disbale}
        $styleType={styleType}
        $theme={theme}
        $type={type}
        ref={ref}
        style={style}
        {...(rest.animate && rest)}
      >
        {children}
      </ContentRootStyled>
    )
    return createPortal(child, container)
  },
)

DropdownCourseContent.displayName = 'DropdownCourseContent'
export default DropdownCourseContent
