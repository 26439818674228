import cn from 'classnames'
import React from 'react'

import { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { testProps } from 'utils/test/qaData'

import { FieldLabelType, FieldLayoutType, IFieldMeta } from '../Field.types'
import * as s from './FieldLayout.module.scss'

interface IFieldLayoutProps {
  meta?: IFieldMeta
  label?: React.ReactNode
  info?: FieldLabelType<unknown>
  required?: boolean
  layout?: FieldLayoutType | FieldLayoutType[]
  empty?: boolean
  size?: KitSize
  name?: string
  children: React.ReactNode
}

const FieldLayout: React.FC<IFieldLayoutProps> = ({
  children,
  label,
  required,
  layout = [],
  info,
  meta,
  empty,
  size,
  name,
}) => {
  const { error, touched, user } = meta || {}
  const errorMessage = touched && error
  const isHorizontal = Array.isArray(layout)
    ? layout.includes('horizontal')
    : layout === 'horizontal'
  const isTable = Array.isArray(layout) ? layout.includes('table') : layout === 'table'
  const isVertical = Array.isArray(layout) ? layout.includes('vertical') : layout === 'vertical'
  const layouts = Array.isArray(layout) ? layout.map((item) => s[item]) : s[layout]
  const cnRoot = cn(s.root, size && s[size], layouts)

  if (empty) {
    return children as JSX.Element
  }

  return (
    <div className={cnRoot}>
      {user && (
        <div className={s.user} style={{ borderColor: user.color, background: `${user.color}22` }}>
          <div className={s.userName} style={{ background: user.color }}>
            {user.name}
          </div>
        </div>
      )}
      {label && (
        <label className={cn(s.label, { [s.required]: required })}>
          {label}
          {info && (isHorizontal || isTable) && (
            <Tooltip overlay={info as React.ReactNode}>
              <Icon className={s.icon} name='builderTooltip' size={KitSize.S} />
            </Tooltip>
          )}
        </label>
      )}
      {info && isVertical && <div className={s.info}>{info as React.ReactNode}</div>}
      <div className={s.input}>{children}</div>
      {Boolean(errorMessage) && (
        <div
          className={s.errors}
          {...testProps({
            el: 'error',
            name,
            label: errorMessage,
          })}
        >
          {errorMessage}
        </div>
      )}
    </div>
  )
}

export default FieldLayout
