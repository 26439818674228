import { ElementsTypes } from '@vedalib/editor/lib/elements'

import { BlockMode, SectionTypeEnum } from 'services/Store/Project/enums'

import { ElementGroup } from '../elements.types'

export const testMode = [ElementGroup.test]
export const viewMode = [ElementGroup.view, ElementGroup.interactive]
export const coverMode = [ElementGroup.cover, ElementGroup.interactive, ElementGroup.view]

export const testConfig: Partial<Record<`${SectionTypeEnum}|${BlockMode}`, ElementsTypes[]>> = {
  [`${SectionTypeEnum.test}|${BlockMode.start}`]: ['testInfoStart'],
  [`${SectionTypeEnum.test}|${BlockMode.questions}`]: [
    'testSingleChoice',
    'testMultipleChoice',
    'testRanking',
    'testMatching',
    'testKeyboardInput',
    'testFeedback',
    'testHotspot',
  ],
  [`${SectionTypeEnum.landing}|${BlockMode.questions}`]: [
    'testSingleChoice',
    'testMultipleChoice',
    'testRanking',
    'testMatching',
    'testKeyboardInput',
    'testFeedback',
    'testHotspot',
  ],
  [`${SectionTypeEnum.test}|${BlockMode.end}`]: ['testInfoEnd', 'testResult'],
}

export const testElementTypes = [
  'testSingleChoice',
  'testMultipleChoice',
  'testRanking',
  'testKeyboardInput',
  'testMatching',
  'testHotspot',
]
