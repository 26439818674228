import { SlateElementMark, SlateElementType, SlateMark } from '@vedalib/rich-text'
import { Editor, Element, Transforms, Text } from 'slate'

import { LIST_ELEMENTS, SlateCommand, SlateInternal, SYMBOLS_CODES } from '../RichTextConstants'

export const insertSymbolByCode = (editor: Editor, symbolNumber: number) =>
  editor.insertText(String.fromCodePoint(symbolNumber))

export const inertSymbol = (editor: Editor, format: SlateCommand) => {
  if (format === SlateCommand.insertNpbs) {
    insertSymbolByCode(editor, SYMBOLS_CODES.npbs)
  } else {
    insertSymbolByCode(editor, SYMBOLS_CODES[format])
  }
}

export const clearTextMark = (editor: Editor) => {
  Object.values(SlateMark).forEach((markName) => {
    Editor.removeMark(editor, markName)
  })
}

export const clearAllMarks = (editor: Editor) => {
  Editor.removeMark(editor, SlateInternal.pseudoSelection)
  // TODO: clear format
  clearTextMark(editor)
  Transforms.unsetNodes(editor, Object.values(SlateElementMark), {
    match: (n) => Element.isElement(n) && Editor.isBlock(editor, n),
    mode: 'all',
  })
}

const isEmptyElement = (_editor: Editor, element: Element) => {
  if (!Element.isElement(element)) {
    return false
  }

  if (element.children.length === 1 && Text.isText(element.children[0])) {
    return element.children[0].text === ''
  }

  return false
}

const getLowestBlock = (editor: Editor): Element | null => {
  const { selection } = editor
  if (!selection) {
    return null
  }

  const [match] = Editor.nodes(editor, {
    at: selection,
    match: (n) => Element.isElement(n) && Editor.isBlock(editor, n),
    mode: 'lowest',
  })
  return (match && Element.isElement(match[0]) && match[0]) || null
}

export const insertBreak = (editor: Editor) => {
  const { selection } = editor
  if (selection) {
    const node = getLowestBlock(editor)
    if (!node) {
      return
    }

    const isListItem = node.type === SlateElementType.listItem
    if (isListItem) {
      const isEmpty = isEmptyElement(editor, node)
      if (isEmpty) {
        Transforms.unwrapNodes(editor, {
          match: (n) =>
            !Editor.isEditor(n) && Element.isElement(n) && LIST_ELEMENTS.includes(n.type),
          split: true,
        })
        Transforms.setNodes(editor, { type: SlateElementType.elementDefault })
      } else {
        editor.insertBreak()
      }
    } else {
      editor.insertBreak()
      const node = getLowestBlock(editor)
      if (node && isEmptyElement(editor, node)) {
        Transforms.setNodes(editor, { type: SlateElementType.elementDefault })
      }
    }
  }
}
