import { SectionEditorSchemaFragment, SectionOrderSchemaFragment } from 'gql/__generated__/graphql'
import { SectionTypeEnum } from 'services/Store/Project/enums'
import { getEnumLabel } from 'utils/enum'

interface IGetMaxIndexSection {
  sections: SectionEditorSchemaFragment[]
  type: SectionTypeEnum
}

export type ISectionsOrder = SectionOrderSchemaFragment

export type SectionTreeItemType = SectionEditorSchemaFragment
export interface ITreeItem {
  lvl: number
  isChapter: boolean
  id: string
  type: string
  isDone?: boolean
  isHide?: boolean
  name?: string | null
}
export interface ISectionTreeNode<S extends ITreeItem = ITreeItem> {
  item: S
  isLast?: boolean
  isFirst?: boolean
  children?: ISectionTreeNode<S>[]
  serialNumber: number
}

export interface ISectionTreeNodePartial<S extends ITreeItem = ITreeItem> {
  item: S
  isLast?: boolean
  isFirst?: boolean
  children?: ISectionTreeNodePartial<S>[]
  serialNumber?: number
}

export const extractLevel = <S extends ITreeItem>(list: S[]): ISectionTreeNodePartial[] => {
  const lvl = list[0]?.lvl
  const children: ISectionTreeNodePartial[] = []
  let currentNode: ISectionTreeNodePartial | null = null

  for (let i = 0; i < list.length; i++) {
    if (list[i].lvl === lvl) {
      currentNode = list[i].isChapter ? { item: list[i], children: [] } : { item: list[i] }
      children.push(currentNode)
    } else {
      currentNode?.children?.push({ item: list[i] })
    }
  }

  return children.map((child) =>
    child.children?.length
      ? { ...child, children: extractLevel(child.children.map(({ item }) => item)) }
      : child,
  )
}

export const markLastAndFirst = <S extends ITreeItem>(tree: ISectionTreeNodePartial<S>[]) => {
  tree.forEach((node, index) => {
    if (index === tree.length - 1) {
      if (node?.children) {
        markLastAndFirst(node.children)
      } else {
        node.isLast = true
      }
    }

    if (index === 0 && node.item.lvl === 0) {
      node.isFirst = true
    }
  })
}

export const addSerialNumberToNode = <S extends ITreeItem>(
  tree: ISectionTreeNodePartial<S>[],
  defCount = 1,
) => {
  let count = defCount
  tree.forEach((node) => {
    node.serialNumber = count
    count++
    if (node?.children) {
      count = node?.children && addSerialNumberToNode(node.children, count++)
    }
  })
  return count
}

// serialization sections to tree
export const listToTree = <S extends ITreeItem>(list: S[]): ISectionTreeNode<S>[] => {
  const tree = extractLevel(list)
  markLastAndFirst(tree)
  addSerialNumberToNode(tree)
  return tree as ISectionTreeNode<S>[]
}

// deserialization Tree to sections
export const treeToSections = <S extends ITreeItem>(tree: ISectionTreeNode<S>[], lvl = 0) => {
  return tree.reduce((acc, item) => {
    acc.push({ ...item.item, lvl })
    if (item.children?.length) {
      const child = treeToSections(item.children, lvl + 1)
      acc.push(...child)
    }
    return acc
  }, [] as S[])
}

export const nodesPath = <S extends ITreeItem>(
  tree: ISectionTreeNode<S>[],
  id: string,
): ISectionTreeNode<S>[] => {
  for (const iSectionTree of tree) {
    if (iSectionTree.item.id === id) {
      return [iSectionTree]
    }

    if (iSectionTree.children?.length) {
      const path = nodesPath(iSectionTree.children, id)
      if (path?.length) {
        return [iSectionTree, ...path]
      }
    }
  }
  return []
}

export const cutItemFromTree = <S extends ITreeItem>(
  dragId: string,
  tree: ISectionTreeNode<S>[],
): ISectionTreeNode<S> | undefined => {
  const path = nodesPath(tree, dragId) || []
  const [node, parent] = path.reverse()
  if (parent) {
    parent.children?.splice(parent.children.indexOf(node), 1)
  } else {
    tree.splice(tree.indexOf(node), 1)
  }

  return node
}

export const putItemInTree = <S extends ITreeItem>(
  targetId: string,
  tree: ISectionTreeNode<S>[],
  putSection: ISectionTreeNode<S>,
  innerChapter?: boolean,
  over?: boolean,
) => {
  const innerInThree = (parent: ISectionTreeNode<S> | undefined, tree: ISectionTreeNode<S>[]) => {
    const insertionSite = over ? 0 : 1
    if (parent) {
      parent.children?.splice(parent.children.indexOf(node) + insertionSite, 0, putSection)
    } else {
      tree.splice(tree.indexOf(node) + insertionSite, 0, putSection)
    }
  }

  const [node, parent] = (nodesPath(tree, targetId) || []).reverse()
  if (node?.item.isChapter) {
    if (innerChapter) {
      node.children?.splice(0, 0, putSection)
    } else {
      innerInThree(parent, tree)
    }
  } else {
    innerInThree(parent, tree)
  }
}

export const getTopParent = <S extends ITreeItem>(
  tree: ISectionTreeNode<S>[],
  id: string,
): ISectionTreeNode<S> => {
  const [node, parent] = (nodesPath(tree, id) || []).reverse()
  if (!parent) {
    return node
  }

  return getTopParent(tree, parent.item.id)
}

export const treeToSectionOrder = <S extends ITreeItem>(
  tree: ISectionTreeNode<S>[],
  lvl = 0,
): ISectionsOrder[] => {
  const sectionOrder = [] as ISectionsOrder[]
  tree.forEach((node) => {
    sectionOrder.push({
      id: node.item.id,
      lvl,
      __typename: 'EditorSectionOrderOptionalType' as const,
    })
    if (node.children?.length) {
      const res = treeToSectionOrder(node.children, lvl + 1)
      if (res.length) {
        const t = res.map((i) => ({
          id: i.id,
          lvl: i.lvl,
          __typename: 'EditorSectionOrderOptionalType' as const,
        }))
        sectionOrder.push(...t)
      }
    }
  })
  return sectionOrder
}

export const getDeepNode = <S extends ITreeItem>(
  tree?: ISectionTreeNode<S>[],
): ISectionTreeNode<S>[] => {
  if (!tree || !tree.length) {
    return []
  }

  return tree.flatMap((node) => [node, ...getDeepNode(node.children)])
}

export const getDeepNodesWithoutChaptersByTree = <S extends ITreeItem>(
  list?: ISectionTreeNode<S>[],
): ISectionTreeNode<S>[] => getDeepNode(list).filter((node) => !node.item.isChapter)

const getSiblingsArray = <S extends ITreeItem>(tree: ISectionTreeNode<S>[], nodeId: string) => {
  const [_node, parent] = nodesPath(tree, nodeId).reverse() || []
  return parent?.children || tree
}

// all sections without children
export const getSectionsBetweenNodes = <S extends ITreeItem>(
  tree: ISectionTreeNode<S>[],
  nodeStartId: string,
  nodeEndId: string,
) => {
  const startSiblings = getSiblingsArray(tree, nodeStartId)
  const startIdx = startSiblings.findIndex((n) => n.item.id === nodeStartId)
  const endIdx = startSiblings.findIndex((n) => n.item.id === nodeEndId)
  const [from, to] = [startIdx, endIdx].sort((a, b) => a - b)
  return startSiblings.slice(from, to + 1)
}

export const getNodePathById = <S extends ITreeItem>(
  nodes: ISectionTreeNode<S>[],
  sectionId: string,
): ISectionTreeNode<S>[] => nodesPath(nodes, sectionId).reverse()

export const getNodesFromTreeByIds = <S extends ITreeItem>(
  nodes: ISectionTreeNode<S>[],
  ids: string[],
): ISectionTreeNode<S>[] => getDeepNode(nodes).filter((node) => ids.includes(node.item.id))

export const getNodesForUpdateValues = <S extends ITreeItem>(
  nodes: ISectionTreeNode<S>[],
  ids: string[],
): string[] => {
  const selectedNodes = getNodesFromTreeByIds(nodes, ids)
  return getDeepNodesWithoutChaptersByTree(selectedNodes)
    .filter((node) => !node.item.isChapter)
    .map((node) => node.item.id)
}

export const getNameSection = ({ sections, type }: IGetMaxIndexSection) => {
  const sectionTypeLabel = getEnumLabel('SectionTypeEnum', type)
  const duplicateIndexes = sections
    .filter((item) => item.name?.replace(/\s\(\d{1,}\)/, '') === sectionTypeLabel)
    .map((item) => {
      const indexes = item.name?.match(/\d{1,}/)
      return indexes ? Number(indexes[0]) : 0
    })

  const index = Math.max(...duplicateIndexes)

  return duplicateIndexes.length ? `${sectionTypeLabel} (${index + 1})` : sectionTypeLabel
}

export const getNextSection = <S extends ITreeItem>(
  tree: ISectionTreeNode<S>[],
  id: string,
): ISectionTreeNode<S> | undefined => {
  const treeList = getDeepNode(tree)
  const idx = treeList.findIndex((n) => n.item.id === id)
  return treeList[idx + 1]
}
