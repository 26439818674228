import { SplitterSchemaType } from '@vedalib/editor/lib/brand'
import styled from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const SplitterIconStyled = styled.div<{
  $styles: ElementStyleCss<SplitterSchemaType>
  $drag: boolean
}>`
  transition: 0.2s;
  position: absolute;
  display: flex;
  width: 32px;
  height: 32px;
  left: -14px;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 32px;
  z-index: 2;
  color: ${({ $styles, $drag }) =>
    $drag ? $styles.hover.backgroundColor : $styles.icon.backgroundColor};

  &:hover {
    color: ${({ $styles }) => $styles.hover.backgroundColor};
  }
`

export default SplitterIconStyled
