import { RichTextValue, rtValueToText } from '@vedalib/rich-text'
import { useKeyPress } from 'ahooks'
import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react'

import RichText from 'components/AppText'
import { CommentMock } from 'components/comments/types'
import Button from 'components/uiKit/Button'
import { AccountMock } from 'components/uiKit/Employee'
import { KitSize } from 'components/uiKit/KitTypes'
import { useCommentUpdate } from 'gql/comments/apollo'
import { t } from 'services/Translation'
import { isCtrl } from 'utils/events'

import * as s from './CommentText.module.scss'

interface ICommentTextProps {
  comment: CommentMock
  mentions: AccountMock[]
}

export interface ICommentTextRef {
  edit: () => void
}

const CommentText = React.forwardRef<ICommentTextRef, ICommentTextProps>(
  ({ comment, mentions }, ref) => {
    const { id } = comment
    const [edit, setEdit] = useState(false)
    const [text, setText] = useState<RichTextValue>(comment.text)
    const [updateComment, { loading }] = useCommentUpdate()
    const isEmpty = !rtValueToText(text)

    const handleEdit = useCallback(() => setEdit(true), [])

    const handleCancel = useCallback(() => {
      ;(document.activeElement as HTMLElement)?.blur?.()
      setEdit?.(false)
      setText(comment.text)
    }, [setEdit, comment.text])

    const handleSave = useCallback(async () => {
      await updateComment({ variables: { id, payload: { text: text } } })
      setEdit?.(false)
    }, [updateComment, setEdit, id, text])

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent) => {
        if (!isEmpty && edit && e.key === 'Enter' && isCtrl(e)) {
          handleSave()
        }
      },
      [isEmpty, edit, handleSave],
    )

    useImperativeHandle(ref, () => ({ edit: handleEdit }))

    useKeyPress('esc', handleCancel)

    useEffect(() => {
      setText(comment.text)
    }, [comment.text])

    return (
      <div className={s.root}>
        <RichText
          autofocus={edit}
          mentions={mentions}
          name='commentText'
          onChange={setText}
          onKeyDown={handleKeyDown}
          placeholder={t('appText.placeholder.comment')}
          readOnly={!edit}
          value={text}
        />
        {edit && (
          <div className={s.footer}>
            <Button
              loading={loading}
              name='cancel'
              onClick={handleCancel}
              size={KitSize.S}
              styleType='secondary'
            >
              {t('uiKit.button.cancel')}
            </Button>
            <Button
              disabled={!rtValueToText(text)}
              loading={loading}
              name='save'
              onClick={handleSave}
              size={KitSize.S}
            >
              {t('uiKit.button.save')}
            </Button>
          </div>
        )}
      </div>
    )
  },
)

CommentText.displayName = 'CommentText'

export default CommentText
