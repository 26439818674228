import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'

import * as s from './SelectedLabel.module.scss'

const SelectedLabel = <V extends Record<string, unknown>>({ selected }: { selected: V[] }) => (
  <div className={s.selectedLabel}>
    {selected.length}
    <span className={s.selected}>Selected</span>
    <Icon name='otherReset' size={KitSize.S} />
  </div>
)

export default SelectedLabel
