import { TemplateType } from 'gql/__generated__/graphql'
import { BlockMode, SectionTypeEnum } from 'services/Store/Project/enums'

export const UiTemplateTagsBySection: { [key in SectionTypeEnum]: TemplateType[] } = {
  [SectionTypeEnum.landing]: [
    TemplateType.TEXT,
    TemplateType.IMAGE,
    TemplateType.LIST,
    TemplateType.MULTIMEDIA,
    TemplateType.INTERACTIVE,
    TemplateType.QUOTE,
    TemplateType.SEPARATORS,
    TemplateType.AFFIRMATION,
    TemplateType.QUESTIONS,
  ],
  [SectionTypeEnum.chapter]: [],
  [SectionTypeEnum.test]: [TemplateType.QUESTIONS, TemplateType.START, TemplateType.END],
  [SectionTypeEnum.cover]: [TemplateType.COVER],
}

export enum TemplatePickerMode {
  multi = 'multi',
  single = 'single',
  change = 'change',
}

export enum TemplatePickerModalType {
  change = 'change',
  add = 'add',
}

// TODO: not used - remove
export const templateTagToBlockMode = (tag?: TemplateType) => {
  if (tag === TemplateType.START) {
    return BlockMode.start
  }
  if (tag === TemplateType.QUESTIONS) {
    return BlockMode.questions
  }
  if (tag === TemplateType.END) {
    return BlockMode.end
  }
  if (tag === TemplateType.COVER) {
    return BlockMode.cover
  }

  return undefined
}

export const blockModeToTemplateTag = (mode: BlockMode) => {
  if (mode === BlockMode.start) {
    return TemplateType.START
  }

  if (mode === BlockMode.questions) {
    return TemplateType.QUESTIONS
  }

  if (mode === BlockMode.end) {
    return TemplateType.END
  }

  if (mode === BlockMode.cover) {
    return TemplateType.COVER
  }

  return undefined
}

export enum TemplateStepperState {
  completed = 'completed',
  disabled = 'disabled',
  alert = 'alert',
  active = 'active',
  notSelected = 'notSelected',
}
