import cn from 'classnames'
import React from 'react'

import { getRootNode } from 'components/editor-v3/context/EditorContext/selectors/block'
import { DEFAULT_CANVAS_WIDTH } from 'components/editor-v3/types/data.constants'
import { DeviceMode } from 'services/Store/Project/enums'
import { Block, IBlockMode } from 'services/Store/Project/types'
import { testProps } from 'utils/test/qaData'

import NodeContainer from '../../Node/NodeContainer'
import * as s from './SkeletonBlock.module.scss'

interface ISkeletonBlockProps {
  block: Block
  mode: IBlockMode
  index: number
  isActive?: boolean
}

const SkeletonBlock = React.forwardRef<HTMLDivElement, ISkeletonBlockProps>(
  ({ block, index, mode }, ref) => {
    const root = getRootNode(block)
    const maxWidth = root?.style?.[DeviceMode.desktop]?.maxWidth || DEFAULT_CANVAS_WIDTH

    return (
      <div
        {...testProps({ el: 'block', name: block.name, id: block.uuid, index })}
        className={cn(s.canvas)}
        ref={ref}
        style={{ width: maxWidth }}
      >
        <NodeContainer block={block} id={root.id} mode={mode} />
      </div>
    )
  },
)

SkeletonBlock.displayName = 'SkeletonBlock'

export default SkeletonBlock
