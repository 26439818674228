// extracted by mini-css-extract-plugin
var _1 = "LabeledGraphicElement_close__3dnpl";
var _2 = "LabeledGraphicElement_demo__zshSP";
var _3 = "LabeledGraphicElement_navigation__kjg5f";
var _4 = "LabeledGraphicElement_pdfItem__zyJUl";
var _5 = "LabeledGraphicElement_readonly__5Dr5b";
var _6 = "LabeledGraphicElement_root__s6FsS";
var _7 = "LabeledGraphicElement_slider__ZjdoB";
var _8 = "LabeledGraphicElement_tooltip__Zc8wg";
export { _1 as "close", _2 as "demo", _3 as "navigation", _4 as "pdfItem", _5 as "readonly", _6 as "root", _7 as "slider", _8 as "tooltip" }
