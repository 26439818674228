import { DraggableStateSnapshot, DraggingStyle, DropAnimation } from '@hello-pangea/dnd'
import { GroupingItem, GroupingGroup } from '@vedalib/editor/lib/elements'
import lodash from 'lodash'
import { CSSProperties } from 'react'

type GetCardsDateType = {
  validationMap: { [key: string]: string }
  source: GroupingItem[]
}

export const CARD_WIDTH = 200
const CARD_HEIGHT = 200

export const cardStyles = {
  position: 'absolute',
  width: CARD_WIDTH,
  height: CARD_HEIGHT,
  backgroundSize: 'cover',
  lineHeight: 1.24,
  padding: '24px',
  display: 'flex',
  aligItems: 'center',
  backgroundPosition: 'center center',
  justifyContent: 'center',
  textAlign: 'center',
} as CSSProperties

export function getDragableStyles(snapshot: DraggableStateSnapshot, style: DraggingStyle) {
  const isDragingCard = snapshot.isDragging
  const checkedTransform = style.transform || ''
  return style && isDragingCard
    ? ({
        ...style,
        transform: checkedTransform + ` scale(1)`,
        transformOrigin: `0px 0px`,
      } as CSSProperties)
    : style
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getStyle(snapshot: DraggableStateSnapshot, borderWidth: number, padding: any) {
  const isDropAnimating = snapshot.isDropAnimating
  const isExistedDropAnimate = snapshot.dropAnimation
  const isExistedDropable = isDropAnimating || isExistedDropAnimate
  if (!isExistedDropable) {
    return {}
  }

  const { moveTo } = snapshot.dropAnimation as DropAnimation
  const style = {
    transform: '',
  }
  const centerX = (CARD_WIDTH - 1 * CARD_WIDTH) / 2
  const centerY = (CARD_HEIGHT - 1 * CARD_HEIGHT) / 2
  const paddings = getAllPaddings(padding)

  if (snapshot.draggingOver) {
    const translate = `translate(${moveTo.x - centerX - 1 - paddings[3] - borderWidth}px, ${
      moveTo.y - centerY - borderWidth - paddings[0]
    }px)`
    style.transform = `${translate} scale(1)`
  }

  return style
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAllPaddings = (padding: any) => {
  const pad = String(padding)
    .split(' ')
    .map((el) => parseInt(el))
  const paddingsAll = pad.length === 1 ? lodash.fill(Array(4), pad[0]) : pad
  return paddingsAll
}

export const sortSourceByPreviewList = (source: GroupingItem[], shuffledSource: GroupingItem[]) =>
  shuffledSource.map((item) => source.find((el) => el.value === item.value) || item)

export const getCardsData = (items: GroupingGroup[]) => {
  const dest = items.map(({ value, target }) => ({
    target,
    value,
    items: [] as GroupingItem[],
  }))
  const { source, validationMap } = items.reduce(
    (result, el) => {
      ;(el.items || []).forEach((e) => {
        result.validationMap[e.value] = el.value
        result.source.push(e)
      })

      return result
    },
    {
      validationMap: {},
      source: [],
    } as GetCardsDateType,
  )

  return { dest, source, validationMap }
}

export const borderRadiusContent = (styles: CSSProperties) => {
  const borderRadius = parseInt(String(styles.borderRadius))
  const borderWidth = parseInt(String(styles.borderWidth))
  return borderRadius > borderWidth ? borderRadius - borderWidth : 0
}
