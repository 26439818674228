import { useMemo } from 'react'
import { FileTypeEnum } from 'utils'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import Collapse from 'components/uiKit/Collapse'
import { Typography } from 'components/uiKit/Typography'
import { FULL_COLLAPSE_PADDING } from 'constants/styles'
import { genColorOptions } from 'services/Branding/constants/fields'
import { useBrandTheme } from 'services/Branding/hooks'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { chainName } from 'utils/form'

import { TestHotspotValueFormType } from './TestHotspotElement.types'

const genField = genDataField<Block>()

const responseOptions = [
  { value: 'single', label: t('elements.testHotspot.form.response.any') },
  { value: 'all', label: t('elements.testHotspot.form.response.all') },
]

const typeOptions = [
  { value: 'icon', label: t('input.option.icons') },
  { value: 'withoutIcon', label: t('input.option.withoutIcon') },
]

const response = genField({
  name: 'response',
  label: t('elements.testHotspot.form.response.label'),
  type: 'segmented',
  layout: ['horizontal', 'solid'],
  defaultValue: 'all',
  params: { options: responseOptions },
})

const image = genField({
  name: 'image',
  type: 'file',
  layout: 'horizontal',
  params: {
    fileType: FileTypeEnum.IMAGE,
    nullable: true,
    preview: true,
    showSource: true,
    hasObjectFit: true,
    hasAccessibility: true,
  },
})

export const overlay = genField({
  name: 'overlay',
  type: 'color',
  label: t('input.label.overlay'),
  defaultValue: '#FFFFFF00',
  layout: 'horizontal',
  useParams: () => {
    const theme = useBrandTheme()
    const options = useMemo(() => genColorOptions(theme), [theme])
    return {
      options,
      labeled: true,
    }
  },
})

const type = genField({
  name: 'type',
  type: 'select',
  label: t('input.label.type'),
  layout: 'horizontal',
  params: { options: typeOptions, fluid: true },
  defaultValue: 'icon',
})

const TestHotspotForm: TestHotspotValueFormType = ({ name }) => {
  return (
    <>
      <Padding bottom sides top>
        <RealtimeField config={chainName(name, response)} />
        <Typography styleType='hint'>{t('elements.testHotspot.form.hint')}</Typography>
      </Padding>
      <Collapse
        header={t('elements.labeledGraphic.form.image')}
        iconPosition='right'
        name='image'
        paddings={FULL_COLLAPSE_PADDING}
        initialOpen
      >
        <RealtimeField config={chainName(name, image)} />
        <RealtimeField config={chainName(name, overlay)} />
      </Collapse>
      <Collapse
        header={t('elements.testHotspot.tags.spot')}
        iconPosition='right'
        name='spot'
        paddings={FULL_COLLAPSE_PADDING}
        initialOpen
      >
        <RealtimeField config={chainName(name, type)} />
      </Collapse>
    </>
  )
}

export default TestHotspotForm
