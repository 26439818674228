import React, { useCallback, useMemo, useState } from 'react'

import { IConfig } from 'components/comments/actions'
import { CommentActionUiEnum, CommentMock } from 'components/comments/types'
import { IconButton } from 'components/uiKit/Button'
import { Tooltip } from 'components/uiKit/Dropdown'
import DropdownMenu from 'components/uiKit/DropdownMenu'
import { KitSize } from 'components/uiKit/KitTypes'
import { IMenuOption } from 'components/uiKit/Menu'
import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import { stopPropagation } from 'constants/commonConstans'
import { CommentStatus } from 'gql/__generated__/graphql'
import {
  useCommentDelete,
  useCommentSubscribe,
  useCommentUnsubscribe,
  useCommentUpdate,
} from 'gql/comments/apollo'

import * as s from './Actions.module.scss'

interface IActionsProps {
  show?: boolean
  comment: CommentMock
  options: IConfig<CommentActionUiEnum>[]
  onClick: (item: IMenuOption<CommentActionUiEnum>) => Promise<void> | void
}

const Actions: React.FC<IActionsProps> = ({ onClick, comment, options, show = true }) => {
  const [update] = useCommentUpdate()
  const [remove] = useCommentDelete()
  const [subscribe] = useCommentSubscribe()
  const [unsubscribe] = useCommentUnsubscribe()
  const [opened, setOpened] = useState(false)

  const { inline, overflow } = useMemo(() => {
    const inline = options.filter((item) => item.inline && !item.hidden)
    const overflow = options.filter((item) => !item.inline)
    return { inline, overflow }
  }, [options])

  const handleActionClick = useCallback(
    async (option: IMenuOption<CommentActionUiEnum>) => {
      const { value } = option
      const { id } = comment
      let res = null
      if (value === CommentActionUiEnum.UNSUBSCRIBE) {
        res = await unsubscribe({ variables: { id } })
      } else if (value === CommentActionUiEnum.SUBSCRIBE) {
        res = await subscribe({ variables: { id } })
      } else if (value === CommentActionUiEnum.PENDING) {
        res = await update({ variables: { id, payload: { status: CommentStatus.pending } } })
      } else if (value === CommentActionUiEnum.RESOLVE) {
        res = await update({ variables: { id, payload: { status: CommentStatus.resolved } } })
      } else if (value === CommentActionUiEnum.DELETE) {
        res = await remove({ variables: { id } })
      } else {
        res = await onClick({ value })
      }

      if (res?.errors?.length) {
        notify({ type: NotificationType.error, message: res.errors[0].message })
      }
    },
    [unsubscribe, subscribe, update, remove, onClick, comment],
  )

  return (
    <div className={s.root} onClick={stopPropagation}>
      {(show || opened) && (
        <DropdownMenu
          name='commentMoreActions'
          onClick={handleActionClick}
          onVisibleChange={setOpened}
          options={overflow}
        >
          {({ open }) => (
            <IconButton
              active={open}
              icon='iconsOtherMore'
              name='moreThread'
              size={KitSize.S}
              styleType='ghost'
            />
          )}
        </DropdownMenu>
      )}
      {inline.map((item) => (
        <Tooltip key={item.value} overlay={item.tooltip} placement={item.tooltipPlacement}>
          <IconButton
            active={item.active}
            disabled={item.disabled}
            icon={item.icon || 'alertDanger'}
            name={item.value}
            onClick={() => handleActionClick(item)}
            size={KitSize.S}
            styleType={item.styleType || 'ghost'}
          />
        </Tooltip>
      ))}
    </div>
  )
}

export default Actions
