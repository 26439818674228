import cn from 'classnames'
import ReactPhoneInput, { Props } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import { testPropsEl } from 'utils/test/qaData'

import * as s from './PhoneInput.module.scss'

export type { Props } from 'react-phone-number-input'

const PhoneInput: React.FC<Props<{ name: string; error?: boolean }>> = (props) => {
  const { error } = props
  return (
    <ReactPhoneInput
      // eslint-disable-next-line react/forbid-component-props
      className={cn(s.root, { [s.error]: error })}
      placeholder='+1 (555) 000 1234'
      {...props}
      {...testPropsEl('phoneInput', props)}
    />
  )
}

export default PhoneInput
