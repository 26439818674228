import { getFontValue, setFontValue } from '@vedalib/editor/lib/brand'
import { StyleValue } from '@vedalib/editor/lib/elements'
import { useMemo } from 'react'

import { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { ISelectOption } from 'components/uiKit/Select'
import { COMPANY_PATHS } from 'constants/paths'
import { FontGroupFullSchemaFragment } from 'gql/__generated__/graphql'
import { useFontsGroupAll } from 'gql/fontsGroup/apollo'
import { usePathParams } from 'routes/hooks'
import { t } from 'services/Translation'

import { FONT_WEIGHT_OPTIONS, validateFontFamilyCss } from '.'
import * as s from './Styles.module.scss'

export const createFontWeightOptions = (
  font: { name: string },
  fontGroup?: FontGroupFullSchemaFragment,
  fontStyle?: string,
) =>
  FONT_WEIGHT_OPTIONS.map((option) => {
    const isFont = fontGroup
      ? fontGroup.fonts.some((font) => font.weight === option.value || !font.weight)
      : true
    return {
      ...option,
      label: (
        <div
          style={{
            fontFamily: validateFontFamilyCss(setFontValue(fontGroup || font)),
            fontWeight: option.value,
            fontStyle,
          }}
        >
          <Tooltip overlay={!isFont ? t('uiKit.tooltip.syntheticFont') : null} placement='left'>
            {option?.label}
            {!isFont && '*'}
          </Tooltip>
        </div>
      ),
    }
  })

const genFontOption = (name: string) => ({
  value: setFontValue({ name }),
  label: <div style={{ fontFamily: validateFontFamilyCss(setFontValue({ name })) }}>{name}</div>,
  textLabel: name,
})

export const FONT_FAMILY_OPTIONS = [
  genFontOption('Inter'),
  genFontOption('Arial'),
  genFontOption('Verdana'),
  genFontOption('Helvetica'),
  genFontOption('Tahoma'),
  genFontOption('Trebuchet MS'),
  genFontOption('Times New Roman'),
  genFontOption('Georgia'),
  genFontOption('Garamond'),
  genFontOption('Courier New'),
  genFontOption('Montserrat'),
  genFontOption('Brush Script MT'),
]

export const createFontFamilyOption = (
  fontGroup: FontGroupFullSchemaFragment,
): ISelectOption<string> => ({
  value: setFontValue(fontGroup),
  label: (
    <div
      className={s.fontFamily}
      style={{ fontFamily: validateFontFamilyCss(setFontValue(fontGroup)) }}
    >
      {fontGroup.isArchived && (
        <Tooltip overlay={t('uiKit.tooltip.fontArchived')} placement='topLeft'>
          <Icon name='missingFont' size={KitSize.S} />
        </Tooltip>
      )}
      {fontGroup.name}
    </div>
  ),
})

export const useFontFamilyOptions = (fontFamily?: StyleValue) => {
  const { companyId } = usePathParams(COMPANY_PATHS.main)
  const { data } = useFontsGroupAll({ companyId })

  return useMemo(() => {
    const font = (fontFamily && getFontValue(String(fontFamily))) || null
    return [
      {
        label: t('input.option.user'),
        value: 'custom',
        options: (data?.data || [])
          .filter(({ isArchived, id }) => id === font?.id || !isArchived)
          .map(createFontFamilyOption),
      },
      {
        label: t('input.option.general'),
        value: 'default',
        options: FONT_FAMILY_OPTIONS,
      },
    ]
  }, [data?.data, fontFamily])
}

export const useFontWeightOptions = (fontFamily?: StyleValue, fontStyle?: StyleValue) => {
  const { companyId } = usePathParams(COMPANY_PATHS.main)
  const { data } = useFontsGroupAll({ companyId })

  return useMemo(() => {
    const font = (fontFamily && getFontValue(String(fontFamily))) || null
    const fontGroup = data?.data.find(({ id }) => id === font?.id)
    return createFontWeightOptions(font as { name: string }, fontGroup, String(fontStyle))
  }, [data?.data, fontFamily, fontStyle])
}
