import { TestMultipleChoiceSchemaType } from '@vedalib/editor/lib/brand'
import styled, { css } from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

export const MarkStyled = styled.div<{
  $styles: ElementStyleCss<TestMultipleChoiceSchemaType>
  $checked: boolean
  $disabled?: boolean
}>`
  position: relative;
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  margin-right: 8px;
  border-style: solid;
  border-width: 2px;
  border-radius: 4px;
  background-color: transparent;
  border-color: ${({ $checked, $styles }) => ($checked ? $styles.checkbox.color : '#abb6c3')};
  background-color: ${({ $styles, $checked }) =>
    $checked ? $styles.checkbox.color : 'var(--color-background-main)'};

  &:after {
    content: '';
    position: absolute;
    display: ${({ $checked }) => ($checked ? 'block' : 'none')};
    width: 16px;
    height: 16px;
    top: 1px;
    left: 1px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIuMjIxNTYgOS40MjgxOEw1LjY5OTk3IDEyLjc1M0wxMy43Nzg3IDQuMjM5MTUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
    scale: 0.7;
  }

  ${({ $disabled, $checked }) =>
    $disabled &&
    css`
      cursor: not-allowed;
      border-color: var(--color-placeholder);
      background-color: ${$checked ? 'var(--color-placeholder)' : 'var(--color-delimiter-main)'};
    `}
`

export default MarkStyled
