import { TimePoint } from '@vedalib/editor/lib/elements'
import React, { useState } from 'react'

import { Actions } from 'components/MediaPlayer/store/types'
import DropdownCourse from 'components/editor-v2/EditorElements/courseKit/DropDownCourse'
import Icon from 'components/uiKit/Icon'

import Item from './Item'
import * as s from './TimePoints.module.scss'

interface ITimePointsProps {
  dispatch: React.Dispatch<Actions>
  timePoints: TimePoint[]
  container: () => HTMLElement
}

const TimePoints: React.FC<ITimePointsProps> = ({ timePoints, dispatch, container }) => {
  const [visible, setVisible] = useState(false)
  const close = () => setVisible(false)

  return (
    <DropdownCourse
      container={container()}
      onVisibleChange={setVisible}
      overlay={
        <div className={s.root} onClick={close}>
          {timePoints.map(({ time, title }) => (
            <Item dispatch={dispatch} key={time} time={time} title={title} />
          ))}
        </div>
      }
      placement='bottom-end'
      visible={visible}
    >
      <Icon name='builderTimeline' />
    </DropdownCourse>
  )
}

export default TimePoints
