import { createItem } from '@vedalib/editor/lib/elements/elements/Tabs/constants'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import ScrollService from 'services/Scroll/ScrollService'
import { ScrollContainerEnum } from 'services/Scroll/enums'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { chainName } from 'utils/form'

import { throughElementItemsArray } from '../../utils/formEffects'
import { TabsValueFormType } from './TabsElement.types'

// TODO move to utils or Field
export const getParentPath = (path: string, nesting = 1) => {
  return path.split('.').slice(0, -nesting).join('.')
}
export const getParentPathR = (path?: string, nesting = 1) => {
  return path?.split('.').slice(0, -nesting)
}

const genField = genDataField<Block>()

const tabsField = (
  onActive: (id: string) => void,
  onFocusItem: (index: string | null) => void,
  active?: string | null,
) =>
  genField({
    name: 'items',
    type: 'array',
    params: {
      onActive,
      onFocusItem,
      onAdd: (changeData) => {
        const value = changeData.value as { label: string; value: string }[]
        return [...value, createItem()]
      },
      active,
      itemLabel: t('input.label.tab'),
      ordering: true,
      min: 1,
      styleType: 'padding',
    },
    fields: [{ name: 'label', type: 'text' }],
    effect: throughElementItemsArray,
  })

const TabsForm: TabsValueFormType = ({ name, state, setState }) => {
  const onActive = (id: string) =>
    ScrollService.scroll({ container: ScrollContainerEnum.toolbar, id }, null)
  const onFocus = (value: string | null) =>
    setState({ ...state, active: value, pauseAnimation: false })

  return <RealtimeField config={chainName(name, tabsField(onActive, onFocus, state?.active))} />
}

export default TabsForm
