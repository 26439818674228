import { TestResultFontSchemaType } from '@vedalib/editor/lib/brand'
import cn from 'classnames'
import React from 'react'

import { ElementFontCss } from 'services/Branding/types'
import { testPropsEl } from 'utils/test/qaData'

import { ProgressType } from '.'
import * as s from './Progress.module.scss'

interface IProgress {
  name: string
  percent: number
  label?: string
  warning?: boolean
  danger?: boolean
  styleType?: ProgressType
  customStyles?: { trail?: string; stroke?: string }
  reverseLabel?: boolean
  size?: 'mini' | 'default' | 'medium'
  font?: ElementFontCss<TestResultFontSchemaType>
  fluid?: boolean
  strokeWidth?: number
  boxShadow?: React.CSSProperties['boxShadow']
}

const DEFAULT_RADIUS = { mini: 8, medium: 12, default: 78 }
const SIZE_PROPS = { mini: 18, medium: 24, default: 166 }

const Progress: React.FC<IProgress> = ({
  percent,
  warning,
  danger,
  styleType = ProgressType.line,
  name,
  label,
  customStyles,
  reverseLabel,
  font,
  size = 'default',
  fluid,
  strokeWidth = 1,
  boxShadow,
}) => {
  const adjustedRadius = DEFAULT_RADIUS[size] - strokeWidth / 2
  const dasharray = 2 * Math.PI * adjustedRadius
  const dashoffset = ((100 - Math.min(percent, 100)) / 100) * dasharray
  const circleCoordinates = SIZE_PROPS[size] / 2

  return (
    <div
      className={cn(s.root, s[styleType], s[size], { [s.reverse]: reverseLabel })}
      {...testPropsEl('progress', { name, value: percent })}
    >
      {styleType === ProgressType.circle ? (
        <div className={s.circle}>
          <div className={s.progressWrapper}>
            {label && (
              <div className={s.percent} style={font?.h2}>
                {label}
              </div>
            )}
            <svg className={s.circleProgress}>
              <circle
                className={s.trail}
                cx={circleCoordinates}
                cy={circleCoordinates}
                r={adjustedRadius}
                style={{ stroke: customStyles?.trail, strokeWidth }}
              />
              <circle
                className={cn(s.stroke, { [s.danger]: danger, [s.warning]: warning })}
                cx={circleCoordinates}
                cy={circleCoordinates}
                r={adjustedRadius}
                style={{
                  strokeDasharray: dasharray,
                  strokeDashoffset: dashoffset,
                  stroke: customStyles?.stroke,
                  strokeWidth,
                }}
              />
            </svg>
          </div>
        </div>
      ) : (
        <>
          <div
            className={cn(s.progressBar, { [s.fluid]: fluid })}
            style={{ backgroundColor: customStyles?.trail, boxShadow }}
          >
            <div
              className={cn(s.statusBar, {
                [s.danger]: danger,
                [s.warning]: warning,
              })}
              style={{
                width: `${percent > 100 ? 100 : percent}%`,
                backgroundColor: customStyles?.stroke,
              }}
            />
          </div>
          {label && <span>{label}</span>}
        </>
      )}
    </div>
  )
}

export default Progress
