import { DeviceMode } from '@vedalib/editor'
import { CONFIG_COURSE } from '@vedalib/editor/lib/elements'

import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { useElementStyleCss } from 'services/Branding/hooks'

import * as s from './FeedbackIcon.module.scss'

interface IFeedbackIconProps {
  deviceMode: DeviceMode
  isTrueAnswer: boolean
}

const FeedbackIcon: React.FC<IFeedbackIconProps> = ({ deviceMode, isTrueAnswer }) => {
  const styles = useElementStyleCss(CONFIG_COURSE.testAnswerFeedback.type, deviceMode)

  let content
  if (isTrueAnswer) {
    if (styles.correct.backgroundImage) {
      content = <div className={s.image} style={styles.correct} />
    } else {
      content = <Icon name='success' size={KitSize.S} />
    }
  } else if (styles.incorrect.backgroundImage) {
    content = <div className={s.image} style={styles.incorrect} />
  } else {
    content = <Icon name='error' size={KitSize.S} />
  }

  return content
}

export default FeedbackIcon
