import React from 'react'

import CollapseIcon from '../CollapseIcon'
import * as s from './DefaultHeader.module.scss'

interface IDefaultHeaderProps {
  open: boolean
  iconPosition?: 'left' | 'right' | 'none'
  children: React.ReactElement | string
  gap?: number
}

const DefaultHeader: React.FC<IDefaultHeaderProps> = ({
  children,
  open,
  iconPosition = 'left',
  gap,
}) => (
  <div className={s.root} style={{ gap }}>
    {iconPosition === 'left' && <CollapseIcon iconPosition='left' open={open} />}
    <div className={s.headerContent}>{children}</div>
    {iconPosition === 'right' && <CollapseIcon iconPosition='right' open={open} />}
  </div>
)

export default DefaultHeader
