import { ru, enGB } from 'date-fns/locale'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { Locale } from 'gql/__generated__/graphql'

import locales from './locales/en.json'
import locales0 from './locales/ru.json'

const LOCALE_KEY = 'locale'

export const setCurrentLocale = (locale: Locale) => localStorage.setItem(LOCALE_KEY, locale)
export const getCurrentLocale = (): Locale => {
  return (
    (localStorage.getItem(LOCALE_KEY) as Locale) ||
    new URLSearchParams(window.location.hash.split('?')[1]).get('locale') ||
    navigator.language.split('-')[0].toUpperCase() ||
    Locale.EN
  )
}

const DATE_LOCALE_MAP = {
  [Locale.RU]: ru,
  [Locale.EN]: enGB,
  [Locale.DE]: enGB,
  [Locale.FR]: enGB,
  [Locale.IT]: enGB,
  [Locale.ES]: enGB,
  [Locale.PT]: enGB,
  [Locale.JA]: enGB,
  [Locale.TR]: enGB,
  [Locale.PL]: enGB,
  [Locale.SK]: enGB,
  [Locale.RO]: enGB,
  [Locale.CN]: enGB,
  [Locale.UA]: enGB,
  [Locale.CUSTOM]: enGB,
}

export const DATE_LOCALE = DATE_LOCALE_MAP[getCurrentLocale()] || enGB

i18n.use(initReactI18next).init({
  fallbackLng: window.PLATFORM.fallbackLng,
  lng: getCurrentLocale().toLowerCase(),
  resources: {
    en: {
      translation: locales,
    },
    ru: {
      translation: locales0,
    },
  },
  compatibilityJSON: 'v4',
  debug: false,
  interpolation: { escapeValue: false, formatSeparator: ',' },
})

export default i18n
