import { EditorElement, ElementValue } from '@vedalib/editor/lib/elements'
import React, { CSSProperties, useMemo } from 'react'

import { useElementFontCss, useElementStyleCss } from 'services/Branding/hooks'
import { ElementStyleCss } from 'services/Branding/types'
import { Block, ElementState, IBlockMode } from 'services/Store/Project/types'

import Decorators from './decorators/Decorators'
import { getEditorElementComponent } from './elements.config'

interface IEditorElementComponentProps {
  element: EditorElement
  mode: IBlockMode
  block: Block
  shellStyles: CSSProperties
  elementState?: ElementState
  waiting?: boolean
  onChange?: (value: ElementValue) => void
  setState?: (value: ElementState<ElementState>) => void
}

const EditorElementComponent: React.FC<IEditorElementComponentProps> = ({
  block,
  element,
  shellStyles,
  elementState,
  mode,
  waiting,
  onChange,
  setState,
}) => {
  const Element = getEditorElementComponent(element.type)
  const elementStyles = useElementStyleCss(element.type, mode.deviceMode, element.style)
  const styles = useMemo(
    () => Object.assign({}, elementStyles, { shell: shellStyles }) as ElementStyleCss,
    [elementStyles, shellStyles],
  )

  const fonts = useElementFontCss(element.type, mode.deviceMode, element.font)

  return (
    <Decorators>
      <Element
        block={block}
        element={element}
        font={fonts}
        mode={mode}
        onChange={onChange}
        setState={setState}
        state={elementState}
        styles={styles}
        waiting={waiting}
      />
    </Decorators>
  )
}

export default React.memo(EditorElementComponent)
