import { createSelector } from '@reduxjs/toolkit'
import lodash from 'lodash'
import * as R from 'ramda'

import { EMPTY_ARRAY } from 'constants/commonConstans'
import { EmployeeListSchemaFragment, EmployeeStatusEnum } from 'gql/__generated__/graphql'
import { notEmpty } from 'utils/notEmpty'
import { isIncludeEqual } from 'utils/object'

import { RootState } from '../types'
import { Position } from './UsersPosition.types'

export const selectMeId = (state: RootState) => state.users.meId
export const selectMyPosition = (state: RootState) => state.users.myPosition
export const selectPinnedEmployee = (state: RootState) => state.users.pinnedEmployee
export const selectEmployees = (state: RootState) => state.users.employees
export const selectUsers = (state: RootState) => state.users.users

const selectUserFilter = R.memoizeWith(
  (_state, filter) => Object.values(filter).join('|'),
  (_state: RootState, filter: Partial<Position>) => filter,
)

export const selectRealtimeUsers = createSelector(
  selectUsers,
  selectEmployees,
  selectUserFilter,
  (users, employees, filter) => {
    const realtimeUsers = users
      .filter(({ fields }) => isIncludeEqual(fields, filter))
      .filter(({ isActive }) => isActive)
      .map((userPosition) => {
        const employee = employees.find((employee) => employee.kuserId === userPosition.id)
        if (!employee) {
          return null
        }

        return {
          employee,
          position: userPosition.fields,
          path: userPosition.path,
        }
      })
      .filter(notEmpty)
    return realtimeUsers.length > 0 ? realtimeUsers : EMPTY_ARRAY
  },
)

const sortEmployees = (
  employees: EmployeeListSchemaFragment[],
  params?: {
    field?: keyof Omit<EmployeeListSchemaFragment, '__typename'>
    employees?: (EmployeeListSchemaFragment & { disabled?: boolean })[]
    customSortList?: (
      | 'sortByIsActive'
      | 'sortByIsDisabled'
      | 'sortByInvited'
      | 'sortByNotAccepted'
      | 'sortByIsArchived'
      | 'sortByEmployeeField'
    )[]
  },
) => {
  const field = params?.field || 'updatedAt'
  const employeesAll =
    params?.employees || (employees as (EmployeeListSchemaFragment & { disabled?: boolean })[])

  const sortByIsDisabled = (e: EmployeeListSchemaFragment & { disabled?: boolean }) => e.disabled
  const sortByEmployeeField = (e: EmployeeListSchemaFragment) => e[field]
  const sortByIsArchived = (e: EmployeeListSchemaFragment) => !e.isArchived
  const sortByIsActive = (e: EmployeeListSchemaFragment) => e.status !== EmployeeStatusEnum.ACTIVE
  const sortByInvited = (e: EmployeeListSchemaFragment) => e.status !== EmployeeStatusEnum.INVITED
  const sortByNotAccepted = (e: EmployeeListSchemaFragment) =>
    e.status !== EmployeeStatusEnum.NOT_ACCEPTED

  const defSortMap = {
    sortByIsActive,
    sortByIsDisabled,
    sortByInvited,
    sortByNotAccepted,
    sortByIsArchived,
    sortByEmployeeField,
  }

  if (params?.customSortList?.length) {
    const listSorted = params?.customSortList
      .filter((i) => defSortMap[i])
      .map((item) => defSortMap[item])
    return lodash.sortBy(employeesAll, listSorted)
  }

  return lodash.sortBy(employeesAll, [
    sortByIsActive,
    sortByIsDisabled,
    sortByInvited,
    sortByNotAccepted,
    sortByIsArchived,
    sortByEmployeeField,
  ])
}

export const selectSortedEmployeesAll = createSelector(
  selectEmployees,
  (
    _state: RootState,
    params?: {
      field?: keyof Omit<EmployeeListSchemaFragment, '__typename'>
      employees?: (EmployeeListSchemaFragment & { disabled?: boolean })[]
      customSortList?: (
        | 'sortByIsActive'
        | 'sortByIsDisabled'
        | 'sortByInvited'
        | 'sortByNotAccepted'
        | 'sortByIsArchived'
        | 'sortByEmployeeField'
      )[]
    },
  ) => params,
  sortEmployees,
)

export const selectCurrentEmployee = (state: RootState) => {
  const meId = selectMeId(state)
  const employees = selectEmployees(state)
  return employees.find(({ kuserId }) => kuserId === meId)
}

export const selectEmployeesAllByIds = createSelector(
  [selectEmployees, (_state, ids) => ids],
  (employees, ids) => employees.filter((e) => ids.includes(e.id)),
)

export const selectEmployeeById = createSelector(
  [selectEmployees, (_state, id) => id],
  (employees, id) => employees.find((e) => e.id === id),
)

export const selectUserPreferences = (state: RootState) => state.users.userPreferences
