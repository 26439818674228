import { useKeyPress } from 'ahooks'
import lodash from 'lodash'
import React, { useCallback } from 'react'

import {
  useBlocksDuplicate,
  useNodeDuplicate,
} from 'components/editor-v3/context/EditorContext/actions'
import { EditorPanel } from 'components/editor-v3/types/date.enums'
import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import { IS_MAC } from 'constants/commonConstans'
import {
  setProjectNavigation,
  setToolbar,
  undoBlock,
  updateBlock,
  redoBlock,
  setCommentForm,
  setOpenedComment,
  setOpenedTask,
} from 'services/Store/Project/actions'
import { BlockMode, AppMode, NavigationEnum, SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import {
  getBlock,
  getEditorMode,
  getNavBar,
  getSelectedBlocksIds,
  getSyncing,
} from 'services/Store/Project/selectors'
import { Block, ProjectUrlType, Section } from 'services/Store/Project/types'
import store from 'services/Store/store'
import { t } from 'services/Translation'
import { getEnumLabel } from 'utils/enum'

import { useBlockActions } from '../../Editor/helpers/useBlockActions'
import { BlockActionUiEnum } from '../../Editor/types'
import { pasteNode, removeNode } from './mutators/block'

const shortcutKey = IS_MAC ? 'meta' : 'ctrl'

const DISABLED_CLONE_BLOCKS = [BlockMode.start, BlockMode.end, BlockMode.cover]
const DISABLED_CLONE_BLOCKS_IN_TEST = [
  BlockMode.start,
  BlockMode.end,
  BlockMode.cover,
  BlockMode.view,
]

const CLIPBOARD_ITEM_PREFIX = 'web '
const CLIPBOARD_ITEM_TYPE_CUSTOM_BLOCK = 'text/custom-editor-block'
const CLIPBOARD_ITEM_TYPE_CUSTOM_NODE = 'text/custom-editor-node'
const CLIPBOARD_KEY_BLOCK = `${CLIPBOARD_ITEM_PREFIX}${CLIPBOARD_ITEM_TYPE_CUSTOM_BLOCK}`
const CLIPBOARD_KEY_NODE = `${CLIPBOARD_ITEM_PREFIX}${CLIPBOARD_ITEM_TYPE_CUSTOM_NODE}`

type IClipboardItemData = typeof CLIPBOARD_KEY_BLOCK | typeof CLIPBOARD_KEY_NODE
export type ICopyData = ProjectUrlType & { blockIds: string[] }

export const disableCloneBlock = (block?: Block, isTest?: boolean) =>
  block && (isTest ? DISABLED_CLONE_BLOCKS_IN_TEST : DISABLED_CLONE_BLOCKS).includes(block?.mode)

const isPanel = (panel: EditorPanel) => {
  return document.activeElement?.closest(`[data-editor-panel="${panel}"]`) !== null
}

export const getBlocksError = (blocks?: Block[], targetSection?: Section | null) =>
  blocks?.filter((block) =>
    disableCloneBlock(block, targetSection?.type === SectionTypeEnum.test),
  ) || []

export const getPreparedNameBlocksError = (blocks?: Block[]) =>
  lodash.union(blocks?.map((b) => getEnumLabel('BlockModeEnum', b.mode))).join(', ')

const handleCopy = async (data: ICopyData, type: IClipboardItemData) => {
  const preparedData = JSON.stringify(data)
  try {
    await navigator.clipboard.write([
      new ClipboardItem({ [type]: new Blob([preparedData], { type: type }) }),
    ])
    notify({
      type: NotificationType.success,
      message:
        type === CLIPBOARD_KEY_BLOCK
          ? t('notify.moveBlockToAnotherSection.success', { count: data.blockIds.length })
          : t('notify.moveNodeToAnotherSection.success'),
    })
    console.info('blocks/node copy', data)
  } catch (err) {
    console.error('Failed to write to clipboard:', err)
  }
}

const usePaste = () => {
  const copyBlocks = useBlocksDuplicate()
  const copyNode = useNodeDuplicate()

  return useCallback(async () => {
    const items = await navigator.clipboard.read()

    for (const item of items) {
      const isBlockCopy = item.types.includes(CLIPBOARD_KEY_BLOCK)
      const isNodeCopy = item.types.includes(CLIPBOARD_KEY_NODE)
      if (isBlockCopy) {
        const blob = await item.getType(CLIPBOARD_KEY_BLOCK)
        const text = await blob.text()
        const data = JSON.parse(text) as ICopyData
        copyBlocks(data.blockIds)
      }
      if (isNodeCopy) {
        const blob = await item.getType(CLIPBOARD_KEY_NODE)
        const text = await blob.text()
        const data = JSON.parse(text) as ICopyData
        copyNode(data)
      }
    }
  }, [copyBlocks, copyNode])
}

export const useProModeHotkeys = (block?: Block | null) => {
  const dispatch = useProjectDispatch()

  const onChange = useCallback(
    async (value: unknown) => block?.uuid && dispatch(updateBlock({ id: block?.uuid, value })),
    [dispatch, block?.uuid],
  )

  useKeyPress(
    `${shortcutKey}.d`,
    (event) => {
      event.preventDefault()
      const urlParams = store.getState().project.urlParams
      if (block && urlParams.nodeId) {
        const update = pasteNode(block, block, urlParams.nodeId, urlParams.nodeId, false)
        if (update) {
          onChange(update.block)
        } else {
          notify({
            message: 'Paste failed',
            type: NotificationType.info,
            duration: 2000,
          })
        }
      }
    },
    { exactMatch: true },
  )
}

const EditorHotkeys = () => {
  const blockActions = useBlockActions()
  const dispatch = useProjectDispatch()
  const isPro = useProjectContext(getEditorMode) === AppMode.pro
  const pasteHandler = usePaste()
  const navTab = useProjectContext(getNavBar).tab
  const selectedBlockIds = useProjectContext(getSelectedBlocksIds)
  const block = useProjectContext(getBlock)
  const editorMode = useProjectContext(getEditorMode)
  const syncing = useProjectContext(getSyncing)

  const undo = () => dispatch(undoBlock())
  const redo = () => dispatch(redoBlock())
  const onChange = (value: Block) => dispatch(updateBlock({ id: value?.uuid, value }))

  useKeyPress(
    `${shortcutKey}.c`,
    () => {
      const urlParams = store.getState().project.urlParams
      const data = { ...urlParams, blockIds: selectedBlockIds }
      if (isPro && urlParams.nodeId) {
        handleCopy(data, CLIPBOARD_KEY_NODE)
      }

      if (urlParams.blockId && !urlParams.nodeId) {
        handleCopy(data, CLIPBOARD_KEY_BLOCK)
      }
    },
    { exactMatch: true },
  )

  useKeyPress(
    `${shortcutKey}.v`,
    () => {
      pasteHandler()
    },
    { exactMatch: true },
  )

  useKeyPress(
    'backspace',
    () => {
      if (
        !isPanel(EditorPanel.Navigation) ||
        block?.isDone ||
        block?.mode === BlockMode.start ||
        block?.mode === BlockMode.end ||
        block?.mode === BlockMode.cover
      ) {
        return
      }

      const urlParams = store.getState().project.urlParams
      if (!urlParams.nodeId && urlParams.blockId) {
        if (blockActions.hasAction(BlockActionUiEnum.DELETE)) {
          notify({
            message: `Can't remove ${block?.mode} block`,
            type: NotificationType.warning,
            duration: 2000,
          })
          return
        }
        console.info('deleteBlock', urlParams.blockId)
        blockActions.handleAction({ value: BlockActionUiEnum.DELETE })
      } else if (urlParams.nodeId && editorMode === AppMode.pro) {
        console.info('deleteNode', urlParams.nodeId)
        dispatch(setProjectNavigation({ nodeId: null }))
        if (block) {
          onChange(removeNode(block, urlParams.nodeId).block)
        }
      }
    },
    { exactMatch: true },
  )

  useKeyPress(
    `${shortcutKey}.z`,
    (event) => {
      if (event.repeat) {
        return
      }

      event.preventDefault()
      if (!syncing) {
        undo()
      }
    },
    { exactMatch: true },
  )

  useKeyPress(
    [`${shortcutKey}.shift.z`, `${shortcutKey}.y`],
    (event) => {
      if (event.repeat) {
        return
      }

      event.preventDefault()
      if (!syncing) {
        redo()
      }
    },
    { exactMatch: true },
  )

  useKeyPress(
    `${shortcutKey}.d`,
    (event) => {
      if (NavigationEnum.section !== navTab) {
        return
      }

      event.preventDefault()
      const urlParams = store.getState().project.urlParams
      if (!urlParams.nodeId && urlParams.blockId) {
        if (blockActions.hasAction(BlockActionUiEnum.CLONE)) {
          return
        }

        console.info('cloneBlock')
        blockActions.handleAction({ value: BlockActionUiEnum.CLONE })
      }
    },
    { exactMatch: true },
  )

  useKeyPress('esc', () => {
    dispatch(setCommentForm(null))
    dispatch(setOpenedTask(null))
    dispatch(setToolbar({ hidden: true, commentTab: null, taskTab: null }))
    dispatch(setOpenedComment(null))
  })

  return null
}

export default React.memo(EditorHotkeys)
