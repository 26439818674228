import cn from 'classnames'

import { testProps } from 'utils/test/qaData'

import * as s from './Switcher.module.scss'

export interface ISwitcherProps {
  onChange: (value: boolean) => void
  name: string
  value: boolean
  disabled?: boolean
}

const Switcher = ({ onChange, name, value = false, disabled }: ISwitcherProps) => {
  const handleChange = () => {
    onChange(!value)
  }

  return (
    <div className={s.root}>
      <label className={s.switch}>
        <input
          checked={value}
          disabled={disabled}
          name={name}
          onChange={handleChange}
          type='checkbox'
        />
        <span
          className={cn(s.slider, { [s.disabled]: disabled, [s.active]: !value })}
          {...testProps({ el: 'switch', name, checked: value })}
        />
      </label>
    </div>
  )
}

export default Switcher
