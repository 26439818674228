import cn from 'classnames'
import React, { useMemo, useRef } from 'react'

import { LayoutScroll } from 'components/LayoutPage'
import NoBlock from 'components/editor-v3/Editor/Canvas/BlockRender/NoBlock'
import { AbsolutePortalProvider } from 'components/editor-v3/components/AbsolutePortal'
import BlockRenderContainer from 'components/editor-v3/cource/layout/Block/BlockContainer'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import ScrollService from 'services/Scroll/ScrollService'
import { ScrollContainerEnum } from 'services/Scroll/enums'
import { courseStart } from 'services/Store/Project/actions'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import {
  getBlocks,
  getDeviceMode,
  getEditorMode,
  getPreviewMode,
} from 'services/Store/Project/selectors'
import { IBlockMode, Section } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import * as s from './CourseCover.module.scss'

interface ICourseCoverProps {
  section: Section
  mode: IBlockMode
}

const CONFIG = {
  description: (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Icon name='builderTooltip' size={KitSize.S} />
      <span>{t('entity.section.coverNotChosen')}</span>
    </div>
  ),
  buttonLabel: t('entity.section.nextSection'),
}

const CourseCover: React.FC<ICourseCoverProps> = ({ section: cover }) => {
  const ref = useRef<HTMLDivElement>(null)

  const dispatch = useProjectDispatch()
  const previewMode = useProjectContext(getPreviewMode)
  const editorMode = useProjectContext(getEditorMode)
  const deviceMode = useProjectContext(getDeviceMode)
  const blocks = useProjectContext(getBlocks, cover.id)
  const currentBlock = blocks[0]
  const emptyElementsOrNotBlock =
    Boolean(!currentBlock) || Boolean(!Object.keys(currentBlock?.elements || {}).length)
  const onCourseStart = () => {
    dispatch(courseStart())
  }
  const mode = useMemo(
    () => ({ deviceMode, previewMode, editorMode }),
    [deviceMode, previewMode, editorMode],
  )

  return (
    <LayoutScroll ref={ScrollService.createContainerSetter(ScrollContainerEnum.canvas)}>
      <AbsolutePortalProvider viewport={ref}>
        <div className={cn(s.root, { [s.emptyBlock]: emptyElementsOrNotBlock })} ref={ref}>
          {!emptyElementsOrNotBlock &&
            blocks.map((block, index) => (
              <React.Fragment key={block.uuid}>
                <BlockRenderContainer block={block} index={index} mode={mode} />
              </React.Fragment>
            ))}
          {emptyElementsOrNotBlock && <NoBlock config={CONFIG} onClick={onCourseStart} />}
        </div>
      </AbsolutePortalProvider>
    </LayoutScroll>
  )
}

export default CourseCover
