import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd'
import { RankingItem } from '@vedalib/editor/lib/elements'
import { textToRtValue } from '@vedalib/rich-text'
import { RichTextValue } from '@vedalib/rich-text'
import cn from 'classnames'
import * as R from 'ramda'

import { useElementRootStyles } from 'components/editor-v2/EditorElements/hooks/useElementRootStyles'
import AbsolutePortal from 'components/editor-v3/components/AbsolutePortal'
import RichText from 'components/form/RichText'
import { useGetRichTextProps } from 'components/form/RichText/useGetRichTextProps'
import { IconButton } from 'components/uiKit/Button'
import { Tooltip } from 'components/uiKit/Dropdown'
import { KitSize } from 'components/uiKit/KitTypes'
import { t } from 'services/Translation'

import { TestRankingElementType } from '../TestRankingElement.types'
import * as s from './TestRankingElement.module.scss'
import TestRankingItem from './TestRankingItem'

const TestRankingElementEditor: TestRankingElementType = ({
  element,
  mode,
  styles,
  waiting,
  font,
  onChange,
}) => {
  const { id: elementId, value } = element
  const { items } = value
  const rootStyles = useElementRootStyles(styles.root)

  const { isActiveElement, activeIndex, onLabelSelect, isFill } = useGetRichTextProps({
    elementId,
    mode,
  })

  const onChangeLabel = (labelValue: RichTextValue, index: number) =>
    onChange?.(R.assocPath(['items', index, 'label'], labelValue, value))

  const handleDelete = (item: RankingItem) => () =>
    onChange?.(
      R.assoc(
        'items',
        value.items.filter(({ value: id }) => id !== item.value),
        value,
      ),
    )

  const handleMove = ({ destination, source }: DropResult) =>
    destination &&
    onChange?.(R.assoc('items', R.move(source.index, destination.index, items), value))

  return (
    <div style={rootStyles}>
      <DragDropContext onDragEnd={handleMove}>
        <Droppable droppableId='droppable'>
          {(provided, { isDraggingOver }) => (
            <div className={s.items} ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => (
                <Draggable draggableId={index.toString()} index={index} key={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={cn(s.wrapItem, { [s.active]: activeIndex === index.toString() })}
                      key={item.value}
                      style={{
                        ...provided.draggableProps.style,
                        marginBottom: styles.offset.marginTop,
                      }}
                    >
                      <TestRankingItem
                        font={font}
                        index={index}
                        snapshot={snapshot}
                        styles={styles}
                      >
                        <div className={s.text} onMouseDown={() => onLabelSelect(index.toString())}>
                          <RichText
                            active={isActiveElement && activeIndex === index.toString()}
                            disabled={!isFill}
                            name={`rankingItem.${index}`}
                            onChange={(val) => onChangeLabel(val, index)}
                            styles={font}
                            value={item.label || textToRtValue('')}
                            waiting={waiting}
                          />
                        </div>
                      </TestRankingItem>
                      {isFill && isActiveElement && !isDraggingOver && (
                        <AbsolutePortal
                          name='deleteMultipleChoiceItem'
                          placement='right'
                          translateX='3px'
                          translateY='-50%'
                        >
                          <Tooltip overlay={t('uiKit.tooltip.deleteAnswer')}>
                            <IconButton
                              disabled={items.length < 3}
                              icon='otherClose'
                              name='deleteMultipleChoiceItem'
                              onClick={handleDelete(item)}
                              size={KitSize.XS}
                              styleType='secondary'
                            />
                          </Tooltip>
                        </AbsolutePortal>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default TestRankingElementEditor
