import { PermissionsKey } from '@vedalib/crud'
import { useCallback, useMemo } from 'react'
import React from 'react'

import { ConfirmModal } from 'components/modals/ConfirmModal/ConfirmModal'
import { IconButton } from 'components/uiKit/Button'
import DropdownMenu from 'components/uiKit/DropdownMenu'
import { KitSize } from 'components/uiKit/KitTypes'
import { IMenuOption } from 'components/uiKit/Menu'
import { useOpenModal } from 'components/uiKit/Modal'
import { notify } from 'components/uiKit/Notification'
import { NotificationType } from 'components/uiKit/Notification/types'
import { useSectionsDeleteById, useSectionsDuplicate } from 'gql/sections/apollo'
import { usePermissions } from 'services/Permission/PermissionContext'
import { SectionTypeEnum } from 'services/Store/Project/enums'
import { useProjectContext } from 'services/Store/Project/hooks'
import {
  getCompanyId,
  getProjectId,
  getSectionId,
  getSections,
} from 'services/Store/Project/selectors'
import { Section } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { useNextSection, usePrevSection } from 'utils/project.hooks'

interface IMoreActionsEditorSectionsProps {
  hidden: boolean
  handleSectionClick?: (item: Section) => void
  handleSectionEdit: (item: Section) => void
  item: Section
}

const CONFIRM_MODAL_PARAMS = {
  title: t('modal.delete.title'),
  info: t('modal.delete.description'),
  okButtonTitle: t('uiKit.button.delete'),
  cancelButtonTitle: t('uiKit.button.cancel'),
  stopPropagation: true,
}

const MoreActionsEditorSections: React.FC<IMoreActionsEditorSectionsProps> = ({
  hidden,
  handleSectionClick,
  handleSectionEdit,
  item,
}) => {
  const projectId = useProjectContext(getProjectId)
  const companyId = useProjectContext(getCompanyId)
  const sectionId = useProjectContext(getSectionId)
  const sections = useProjectContext(getSections)
  const nextSection = useNextSection(item.id, sections || [])
  const prevSection = usePrevSection(item.id, sections || [])
  const [sectionsDelete] = useSectionsDeleteById(projectId)
  const [sectionDuplicate] = useSectionsDuplicate(projectId)
  const confirmModal = useOpenModal(ConfirmModal, CONFIRM_MODAL_PARAMS)
  const permissions = usePermissions()
  const cover = item.type === SectionTypeEnum.cover
  const canUpdateSection = permissions.has(PermissionsKey.project_sections_u)
  const canDeleteSection = permissions.has(PermissionsKey.project_sections_d)
  const hasSections = sections.filter((sec) => sec.type !== SectionTypeEnum.chapter).length > 1
  const active = item.id === sectionId

  const handleMenuClick = useCallback(
    async ({ value }: IMenuOption<'edit' | 'delete' | 'duplicate'>) => {
      if (value === 'edit') {
        handleSectionEdit(item)
      }

      if (value === 'duplicate') {
        const response = await sectionDuplicate({ variables: { companyId, ids: [item.id] } })
        const firstDuplicated = response.data?.data?.data?.[0].section as unknown as Section
        if (firstDuplicated) {
          handleSectionClick?.(firstDuplicated)
          notify({
            type: NotificationType.success,
            message: t('notify.sectionDuplicate', { count: 1 }),
          })
        } else {
          notify({ type: NotificationType.error, message: t('notify.sectionErrorDuplicate') })
        }
      }
      if (value === 'delete') {
        const confirm = await confirmModal.open()
        if (confirm) {
          const response = await sectionsDelete({ variables: { ids: [item.id], projectId } })
          const firstDeletedId = response.data?.data.sectionIdsDeleted[0]
          if (response.errors?.length) {
            notify({ message: t('common.error'), type: NotificationType.error })
            return
          }
          if (firstDeletedId) {
            notify({
              type: NotificationType.success,
              message: t('notify.delete', { count: 1 }),
            })
          }

          if (active) {
            if (nextSection) {
              handleSectionClick?.(nextSection)
            } else if (prevSection) {
              handleSectionClick?.(prevSection)
            }
          }
        }
      }
    },
    [
      handleSectionEdit,
      item,
      sectionDuplicate,
      companyId,
      handleSectionClick,
      confirmModal,
      sectionsDelete,
      projectId,
      active,
      nextSection,
      prevSection,
    ],
  )

  const options = useMemo(() => {
    const menuOptions: IMenuOption<'edit' | 'delete' | 'duplicate'>[] = []

    if (!item.isDone) {
      if (canUpdateSection) {
        menuOptions.push({ label: t('page.editor.sections.actions.edit'), value: 'edit' })
      }

      if (canDeleteSection) {
        menuOptions.push({
          label: t('page.editor.sections.actions.delete'),
          value: 'delete',
          disabled: !hasSections,
        })
      }
    }
    if (!cover) {
      menuOptions.push({ label: t('page.editor.sections.actions.duplicate'), value: 'duplicate' })
    }

    return menuOptions
  }, [canUpdateSection, item.isDone, canDeleteSection, hasSections, cover])

  return (
    <DropdownMenu
      name='moreActionEditorSection'
      onClick={handleMenuClick}
      options={options}
      stopPropagation
    >
      {({ open }) => (
        <IconButton
          active={open}
          hidden={hidden || !options.length || item.type === SectionTypeEnum.chapter}
          icon='iconsOtherMore'
          name='moreActions'
          size={KitSize.S}
          styleType='ghost'
        />
      )}
    </DropdownMenu>
  )
}

export default MoreActionsEditorSections
