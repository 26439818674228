import { SizeTabsElementType, TabsFontSchemaType, TabsSchemaType } from '@vedalib/editor/lib/brand'
import styled from 'styled-components'

import { ElementFontCss, ElementStyleCss } from 'services/Branding/types'

import { Z_INDEX_TEXT_AND_SPLITER } from './TabElementStyled'

const TabPanelStyled = styled.div<{
  $styles: ElementStyleCss<TabsSchemaType>
  $font: ElementFontCss<TabsFontSchemaType>
  $active?: boolean
}>`
  align-items: center;
  margin-left: 0;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0;
  display: inline-block;
  width: 100%;
  position: relative;
  z-index: ${Z_INDEX_TEXT_AND_SPLITER};

  ${({ $font, $active }) => $active && $font.selected};
  text-align: ${({ $styles }) =>
    $styles.tabs.tabSize === SizeTabsElementType.fixed && $styles.tabs.textAlign};
`

export default TabPanelStyled
