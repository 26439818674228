import { AccountMock } from 'components/uiKit/Employee'
import { ISelectProps } from 'components/uiKit/Select'
import { ISelectOption } from 'components/uiKit/Select/SelectTypes'
import { CompanyRole, EmployeeSchemaFragment } from 'gql/__generated__/graphql'
import { notEmpty } from 'utils/notEmpty'

import { createEmployeeLabel } from './createEmployeeLabel'

export type EmployeePickerParamsType = Pick<
  ISelectProps<string>,
  'isSearch' | 'options' | 'inputable' | 'separators'
>

export type EmployeeShortedType = Pick<EmployeeSchemaFragment, 'id' | 'kUser' | 'role' | 'status'>

export type DefaultEmployeesType = AccountMock & { disabled?: boolean }

type EmployeePredicateParams = {
  excludeCommentator?: boolean
  excludeIds?: string[]
}
export const createEmployeePredicate =
  (params: EmployeePredicateParams) =>
  (employee: EmployeeShortedType): boolean => {
    const { excludeCommentator = true, excludeIds } = params

    if (excludeCommentator && employee?.role === CompanyRole.commentator) {
      return false
    }

    if (excludeIds && excludeIds.length && excludeIds.includes(employee.id)) {
      return false
    }

    return true
  }

export const getEmployeesSelectParams = <T extends EmployeeShortedType[]>(
  employees: T,
  { filter = createEmployeePredicate({}), enabled = createEmployeePredicate({}) } = {},
): EmployeePickerParamsType => {
  const options: ISelectOption<string>[] =
    employees.filter(filter).map((item) => ({
      value: item.id,
      label: createEmployeeLabel(item),
      textLabel: item.kUser.name,
      disabled: !enabled(item),
      name: item.kUser.name,
      searchable: [item.kUser.email, item.kUser.name].filter(notEmpty).join(' ').toLowerCase(),
    })) || []

  return {
    options,
    isSearch: true,
  }
}

export const getEmployeesLimitInfo = (
  employeeCount: number | undefined | null,
  availableEmployee: number | undefined | null,
  throwLimit: boolean | null = false,
) => ({
  disableEmployeesInvite:
    employeeCount && availableEmployee && throwLimit && employeeCount >= availableEmployee,
  showEmployeesLimitAlert: employeeCount && availableEmployee && employeeCount >= availableEmployee,
})
