import * as amplitude from './amplitude'
import { UserInfo } from './types'
import * as userguiding from './userguiding'

export { initAnalytics } from './init'

export const setUserInfo = (info: UserInfo) => {
  amplitude.setUserInfo(info)
  userguiding.setUserInfo(info)
}

export const sendEvent = (event: string, data: object) => {
  amplitude.sendEvent(event, data)
}
