import { Block, NodeType } from '@vedalib/editor'
import { SliderFontSchemaType, SliderSchemaType } from '@vedalib/editor/lib/brand'
import { CONFIG_ELEMENTS, SliderElementValue } from '@vedalib/editor/lib/elements'
import { SlateElementMark, SlateElementType, textToRtValue } from '@vedalib/rich-text'
import { useState } from 'react'

import { DEMO_MODE } from 'services/Store/Project/constants'
import { BlockMode } from 'services/Store/Project/enums'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import { SliderState } from './Slider.types'
import Element from './SliderElement'

const createAlignedRichTextValue = (
  textKey: string,
  elementType?: SlateElementType,
  alignment = 'center',
) => {
  return textToRtValue(t(textKey), elementType).map((e) => ({
    ...e,
    [SlateElementMark.textAlign]: alignment,
  }))
}

const BLOCK_MOCK: Block = {
  uuid: 'mock-slider-block',
  mode: BlockMode.view,
  name: 'mock-slider-block',
  isHide: false,
  isDone: false,
  sectionId: 'section-mock',
  createdAt: '2021-09-01T00:00:00.000Z',
  updatedAt: '2021-09-01T00:00:00.000Z',
  tasksCount: 0,
  publicLink: { isOpen: false, shortLink: '' },
  files: [],
  elements: {
    'mock-slider-element-1': {
      id: 'mock-slider-element-1',
      type: 'richText',
      font: {},
      style: {},
      value: createAlignedRichTextValue('elements.slider.value.title1', SlateElementType.heading4),
    },
    'mock-slider-element-2': {
      id: 'mock-slider-element-2',
      type: 'richText',
      style: {},
      font: {},
      value: createAlignedRichTextValue('elements.slider.value.title2', SlateElementType.heading4),
    },
    'mock-slider-element-3': {
      id: 'mock-slider-element-2',
      type: 'richText',
      style: {},
      font: {},
      value: createAlignedRichTextValue('elements.slider.value.subtitle1'),
    },
    'mock-slider-element-4': {
      id: 'mock-slider-element-2',
      type: 'richText',
      style: {},
      font: {},
      value: createAlignedRichTextValue('elements.slider.value.subtitle2'),
    },
    'mock-slider-element-5': {
      id: 'mock-slider-element-5',
      type: 'richText',
      style: {},
      font: {},
      value: createAlignedRichTextValue('elements.slider.value.title3', SlateElementType.heading4),
    },
    'mock-slider-element-6': {
      id: 'mock-slider-element-6',
      type: 'richText',
      style: {},
      font: {},
      value: createAlignedRichTextValue('elements.slider.value.subtitle3'),
    },
  },
  schema: {
    nodes: {
      'mock-node-1': {
        id: 'mock-node-1',
        type: NodeType.column,
        style: {},
        children: ['mock-node-1-children', 'mock-node-3-children'],
      },
      'mock-node-2': {
        id: 'mock-node-2',
        type: NodeType.column,
        style: {},
        children: ['mock-node-2-children', 'mock-node-4-children'],
      },
      'mock-node-3': {
        id: 'mock-node-3',
        type: NodeType.column,
        style: {},
        children: ['mock-node-5-children', 'mock-node-6-children'],
      },
      'mock-node-1-children': {
        id: 'mock-node-1-children',
        name: '',
        type: NodeType.shell,
        mobile: {},
        tablet: {},
        desktop: {
          color: 'red',
        },
        elementId: 'mock-slider-element-1',
        help: '',
        children: [],
      },
      'mock-node-2-children': {
        id: 'mock-node-2-children',
        name: '',
        type: NodeType.shell,
        mobile: {},
        tablet: {},
        desktop: {},
        elementId: 'mock-slider-element-2',
        help: '',
        children: [],
      },
      'mock-node-3-children': {
        id: 'mock-node-3-children',
        name: '',
        type: NodeType.shell,
        mobile: {},
        tablet: {},
        desktop: {},
        elementId: 'mock-slider-element-3',
        help: '',
        children: [],
      },
      'mock-node-4-children': {
        id: 'mock-node-4-children',
        name: '',
        type: NodeType.shell,
        mobile: {},
        tablet: {},
        desktop: {},
        elementId: 'mock-slider-element-4',
        help: '',
        children: [],
      },
      'mock-node-5-children': {
        id: 'mock-node-5-children',
        name: '',
        type: NodeType.shell,
        mobile: {},
        tablet: {},
        desktop: {},
        elementId: 'mock-slider-element-5',
        help: '',
        children: [],
      },
      'mock-node-6-children': {
        id: 'mock-node-6-children',
        name: '',
        type: NodeType.shell,
        mobile: {},
        tablet: {},
        desktop: {},
        elementId: 'mock-slider-element-6',
        help: '',
        children: [],
      },
    },
    rootId: 'rootId',
  },
}

const DEMO_VALUE: SliderElementValue = {
  items: [
    { label: 'label1', value: 'mock-node-1' },
    { label: 'label2', value: 'mock-node-2' },
    { label: 'label3', value: 'mock-node-3' },
  ],
  hasLoop: true,
  hasProgress: true,
}

const SliderDemo: React.FC<IElementDemoProps<SliderSchemaType, SliderFontSchemaType>> = ({
  styles,
  fonts,
}) => {
  const [state, setState] = useState<SliderState | undefined>()

  const handleSetState = (value: SliderState) => setState((prev) => ({ ...prev, ...value }))

  return (
    <DemoWrapper>
      <Element
        block={BLOCK_MOCK}
        element={{
          id: `${CONFIG_ELEMENTS.slider.type}-demo`,
          type: CONFIG_ELEMENTS.slider.type,
          value: DEMO_VALUE,
          style: {},
          font: {},
        }}
        font={fonts}
        mode={DEMO_MODE}
        setState={handleSetState}
        state={state}
        styles={styles}
      />
    </DemoWrapper>
  )
}
export default SliderDemo
