import * as R from 'ramda'
import { Dispatch, SetStateAction, useCallback } from 'react'

import Card from 'components/uiKit/Card'
import Checkbox from 'components/uiKit/Checkbox'
import { KitSize } from 'components/uiKit/KitTypes'
import Link from 'components/uiKit/Link'
import { Typography } from 'components/uiKit/Typography'
import { ExternalImage } from 'gql/__generated__/graphql'
import { isCtrl } from 'utils/events'
import { testProps } from 'utils/test/qaData'

import * as s from './ExternalImagesGridItem.module.scss'

interface IExternalImagesGridItem {
  item: ExternalImage
  onClick: (item: ExternalImage) => void
  onChangeSelection: Dispatch<SetStateAction<ExternalImage[]>>
  selectedItems: ExternalImage[]
  clearQuery: () => void
  resourceLink: string
}

const getOwnerProfileLink = (resourceLink: string, userName: string, platformName: string) =>
  `${resourceLink}/@${userName}?utm_source=${platformName}&utm_medium=referral`

const ExternalImagesGridItem: React.FC<IExternalImagesGridItem> = ({
  item,
  onClick,
  onChangeSelection,
  selectedItems,
  clearQuery,
  resourceLink,
}) => {
  const onSelectItem = useCallback(
    (val: boolean) => {
      onChangeSelection((prev: ExternalImage[]) => {
        if (!val) {
          return R.filter<ExternalImage, ExternalImage[]>(({ id }) => id !== item.id, prev)
        } else {
          return [...prev, item]
        }
      })
    },
    [item, onChangeSelection],
  )

  const selected = !!selectedItems.find((i) => i.id === item.id)

  const handleClick = (e: React.MouseEvent) => {
    if ((e && isCtrl(e)) || !!selectedItems.length) {
      onChangeSelection((prev) => {
        if (prev.find((i) => i.id === item.id)) {
          return R.filter<ExternalImage, ExternalImage[]>(({ id }) => id !== item.id, prev)
        }

        return [...prev, item]
      })
      return
    }
    onClick(item)
    clearQuery()
  }

  const linkOwnerProfile = getOwnerProfileLink(
    resourceLink,
    item.user.username,
    window.PLATFORM.PlatformName,
  )

  return (
    <div
      className={s.item}
      key={item.id}
      {...testProps({ el: 'fileItem', label: item.user.name, id: item.id })}
    >
      <Card name='fileModalCard' onClick={handleClick} selected={selected} size={KitSize.XS} fluid>
        <div className={s.block}>
          <div className={s.blockWrapper}>
            <div className={s.fileItem}>
              <img
                alt='img'
                className={s.img}
                loading='lazy'
                src={item.urls.raw}
                srcSet={item.urls.raw}
              />
            </div>
          </div>
        </div>
      </Card>
      <div className={s.title}>
        <Checkbox name='resource' onChange={onSelectItem} value={selected} />
        <Link name='ownerTitle' rel='noreferrer' target='_blank' to={linkOwnerProfile}>
          <Typography rows={1} styleType='link' tooltip={item.user.name}>
            {item.user.name}
          </Typography>
        </Link>
      </div>
    </div>
  )
}

export default ExternalImagesGridItem
