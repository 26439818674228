import cn from 'classnames'
import React, { useCallback } from 'react'

import { TooltipCourse } from 'components/editor-v2/EditorElements/courseKit/DropDownCourse'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { testEnd } from 'services/Store/Project/actions'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getBlock, getSection, getSectionState } from 'services/Store/Project/selectors'
import { t } from 'services/Translation'

import { useTestTimer } from '../useTestTimer'
import * as s from './SectionTestTimer.module.scss'

const SectionTestTimer: React.FC = () => {
  const dispatch = useProjectDispatch()
  const time = useProjectContext(getSection)?.test?.time || 0
  const startTime = useProjectContext(getSectionState)?.startTime
  const stop = useProjectContext(getBlock)?.mode === 'start' || !startTime

  const onFinish = useCallback(() => dispatch(testEnd()), [dispatch])

  const { isFire, left } = useTestTimer({ startTime, time, stop, onFinish })

  return (
    <TooltipCourse overlay={t('elements.testFooter.tooltip.timeAll')}>
      <div className={cn(s.root, { [s.fireTime]: isFire })}>
        <Icon
          className={s.icon}
          name={isFire ? 'statusDeadline20Px' : 'otherClock'}
          size={KitSize.S}
        />
        {left}
      </div>
    </TooltipCourse>
  )
}

export default SectionTestTimer
