import cn from 'classnames'
import React from 'react'

import { Tooltip } from 'components/uiKit/Dropdown'
import { KitSize } from 'components/uiKit/KitTypes'
import { getNameString } from 'components/uiKit/Table/utils'
import { SYSTEM_COLORS } from 'constants/colors'
import {
  AvatarSchemaFragment,
  EmployeeStatusEnum,
  FileMetaSchemaFragment,
} from 'gql/__generated__/graphql'
import { getFileInFileMeta } from 'utils/files'

import { AccountMock, EmployeeType } from '../Employee'
import * as s from './EmployeeAvatar.module.scss'
import SVGEmployeeDeleted from './deletedEmployee.svg'
import SVGEmployeePending from './pendingEmployee.svg'

export type AvatarMock = AvatarSchemaFragment

interface IEmployeeAvatarProps {
  employee: AccountMock
  tooltip?: React.ReactNode
  size?: KitSize | number
  onClick?: (event: React.MouseEvent) => void
  disabled?: boolean
  active?: boolean
  type?: EmployeeType
  grey?: boolean
}

export function generateColorFromUuid(uuid: string): string {
  const hex = uuid.replace(/-/g, '').substr(0, 6)
  const num = Math.floor((parseInt(hex.substr(0, 2), 16) / 256) * SYSTEM_COLORS.length)
  return SYSTEM_COLORS[num]
}
const EmployeeAvatar: React.FC<IEmployeeAvatarProps> = ({
  employee,
  // id,
  size = 'medium',
  tooltip,
  onClick,
  disabled = false,
  active = false,
  type = 'interactive',
  grey,
}) => {
  const {
    id,
    status,
    kUser: { name: userName, email },
  } = employee
  const name = userName || employee.kUser.name
  const customPath =
    status === EmployeeStatusEnum.ARCHIVED
      ? SVGEmployeeDeleted
      : status === EmployeeStatusEnum.INVITED || status === EmployeeStatusEnum.NOT_ACCEPTED
        ? SVGEmployeePending
        : undefined

  const avatar = employee.kUser.avatar

  const avatarData = avatar && getFileInFileMeta(avatar as FileMetaSchemaFragment, 'original')
  const avatarPath = customPath || avatarData?.path
  const isCustomSize = typeof size === 'number'
  const rootCn = cn(s.root, s[size], s[type], disabled && s.disabled, active && s.active)
  const cnAvatar = isCustomSize ? s.root : rootCn
  const style = isCustomSize
    ? { width: size, height: size, fontSize: Math.floor((size as number) / 2) }
    : undefined
  const nameString = getNameString(name || email)
  let avatarElement
  if (avatarPath) {
    avatarElement = (
      <img
        alt='avatar'
        className={cnAvatar}
        onClick={onClick}
        src={avatarPath}
        style={{ ...style, ...((disabled || grey) && { filter: 'grayscale(100%)' }) }}
      />
    )
  } else {
    avatarElement = (
      <div
        className={cn(s.stub, rootCn)}
        onClick={onClick}
        style={{
          backgroundColor:
            disabled || grey ? 'var(--color-placeholder)' : generateColorFromUuid(id),
          ...style,
        }}
      >
        {nameString?.slice(0, 1)}
      </div>
    )
  }

  if (tooltip) {
    return <Tooltip overlay={tooltip}>{avatarElement}</Tooltip>
  }

  return avatarElement
}

export default EmployeeAvatar
