import React from 'react'

import DropDownCourse from '..'
import { IDropdownCourseContent } from '../DropdownCourse'
import { IChildrenHandler } from '../types'

type ITooltip = Omit<IDropdownCourseContent, 'type' | 'children'> & {
  children: IChildrenHandler
}

const TooltipCourse: React.FC<ITooltip> = ({ children, overlay, ...restProps }) =>
  !overlay ? (
    children
  ) : (
    <DropDownCourse {...restProps} overlay={overlay} type='tooltip'>
      {children}
    </DropDownCourse>
  )

export default TooltipCourse
