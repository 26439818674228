import cn from 'classnames'
import { ReactNode, useCallback, useMemo } from 'react'

import { IconButton } from '../Button'
import * as s from './Drawer.module.scss'

interface IDrawer {
  open: boolean
  onClose: (open: boolean) => void
  width?: string | number
  closeIcon?: boolean
  placement?: 'left' | 'right' | 'top' | 'bottom'
  children: ReactNode
  title?: React.ReactNode
  destroyOnClose?: boolean
  position?: 'fixed' | 'absolute'
  showBg?: boolean
  boxShadow?: string
}

const Drawer: React.VFC<IDrawer> = ({
  open,
  onClose,
  width,
  children,
  title,
  placement = 'left',
  closeIcon = true,
  destroyOnClose = false,
  position = 'fixed',
  showBg = true,
  boxShadow,
}) => {
  const handleClose = useCallback(() => onClose(false), [onClose])
  const styleContent = useMemo(
    () => ({
      ...(placement === 'left' || placement === 'right'
        ? { width }
        : { height: width, width: '100%' }),
      ...(boxShadow && open && { boxShadow }),
    }),
    [placement, boxShadow, open, width],
  )

  const Content = useMemo(
    () => (!open && destroyOnClose ? null : children),
    [children, open, destroyOnClose],
  )

  const cnMask = cn(s.mask, open ? s.open : s.hidden, showBg && s.maskBg)
  const cnContentWrapper = cn(s.contentWrapper, s[placement], open ? s.open : s.hidden)

  return (
    <div className={s.root} style={{ position }} tabIndex={-1}>
      <div className={cnMask} onClick={handleClose} />
      <div className={cnContentWrapper} style={styleContent}>
        <div className={s.content}>
          {title && (
            <div className={s.header}>
              <div className={s.title}>{title}</div>
              {closeIcon && (
                <div className={s.close}>
                  <IconButton
                    icon='otherClose'
                    name='drawerClose'
                    onClick={handleClose}
                    styleType='secondary'
                  />
                </div>
              )}
            </div>
          )}
          {Content}
        </div>
      </div>
    </div>
  )
}

export default Drawer
