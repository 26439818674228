/* eslint-disable @typescript-eslint/no-explicit-any */
import lodash from 'lodash'

const replaceIds = (value: string) => {
  return value
    .replaceAll(
      /\.[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\./gi,
      '.{id}.',
    )
    .replaceAll(/\.[0-9a-z_-]{21}\./gi, '.{id}.')
}

const stringify = (value: any) => {
  try {
    const srt = JSON.stringify(value)
    if (srt.includes('.')) {
      return replaceIds(srt)
    }

    return srt
  } catch (e) {
    return '"Error"'
  }
}

const DEFAULT_KEYS = ['name', 'value', 'focus', 'label', 'active', 'disabled', 'index']

export const testPropsEl = (el: string, props: any, extra: any = {}) =>
  testProps({ el, ...lodash.pick(props, DEFAULT_KEYS), ...extra })

export const testProps = (data: any) => {
  // if (process.env.REACT_APP_ENV === 'autotest' || true) {
  const { testData, ...rest } = data
  const str = `${testData ? `${testData}` : ''}${Object.entries(rest)
    .map((pair) => `${pair.map((value = '_UNDEFINED_') => stringify(value)).join(':')}|`)
    .join('')}`
  return { 'data-qa-data': str /* title: str, */ }
  // }
  return {}
}

export const extendTestData = (testData: any): string => testProps(testData)['data-qa-data'] || ''
