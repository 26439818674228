// extracted by mini-css-extract-plugin
var _1 = "EditorBlock_active__CNHqO";
var _2 = "EditorBlock_block__jXgJL";
var _3 = "EditorBlock_comment__ks9BV";
var _4 = "EditorBlock_commentActive__9srwp";
var _5 = "EditorBlock_comments__0Nox9";
var _6 = "EditorBlock_commentsDeep__2ISK0";
var _7 = "EditorBlock_divider__C8bNI";
var _8 = "EditorBlock_icons__yU8ka";
var _9 = "EditorBlock_root__nn+gH";
var _a = "EditorBlock_task__d0Noq";
var _b = "EditorBlock_tooltipPortal__DjAfb";
var _c = "EditorBlock_waiting__wHWjt";
var _d = "EditorBlock_wrapper__s9CjX";
export { _1 as "active", _2 as "block", _3 as "comment", _4 as "commentActive", _5 as "comments", _6 as "commentsDeep", _7 as "divider", _8 as "icons", _9 as "root", _a as "task", _b as "tooltipPortal", _c as "waiting", _d as "wrapper" }
