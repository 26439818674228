import { TableElementValue } from '@vedalib/editor/lib/elements'
import { useDebounceFn } from 'ahooks'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { getNode } from 'components/editor-v3/context/EditorContext/selectors/block'
import { isShellNode } from 'components/editor-v3/types/data.guards'
import { useGetRichTextProps } from 'components/form/RichText/useGetRichTextProps'
import { useProjectContext } from 'services/Store/Project/hooks'
import { getUrlParams } from 'services/Store/Project/selectors'

import TableContext, { TableContextValue } from './TableContext'
import * as s from './TableElement.module.scss'
import {
  CellPosition,
  DndControlsCoordinates,
  DndControlsState,
  TableElementType,
} from './TableElement.types'
import Table from './components/Table'

const TableElement: TableElementType = ({
  styles,
  element: { value, id },
  mode,
  block,
  waiting,
  font,
  onChange: onChangeProp,
}) => {
  const tableRef = useRef<HTMLTableElement>(null)
  const [tableValue, setTableValue] = useState(value)
  const [cellNodes, setCellNodes] = useState<HTMLTableDataCellElement[][]>([])
  const [dndControlsCoordinates, setDndControlsCoordinates] = useState<DndControlsCoordinates>({
    x: null,
    y: null,
  })
  const [dragState, setDragState] = useState<DndControlsState>({
    dragType: null,
    dragIndex: null,
  })

  const rtProps = useGetRichTextProps({ elementId: id, mode })

  const { nodeId } = useProjectContext(getUrlParams)

  const node = getNode(block!, nodeId || '')

  const isActive = isShellNode(node) ? node.elementId === id : false

  const [selectedCells, setSelectedCells] = useState<CellPosition[]>([])
  const [startSelectPosition, setStartSelectPosition] = useState<CellPosition | null>(null)

  const { run: onChangeRun, flush } = useDebounceFn(
    (value: TableElementValue) => {
      onChangeProp?.(value)
    },
    { wait: 500 },
  )

  const onChange = useCallback(
    (value: TableElementValue) => {
      setTableValue(value)
      onChangeRun(value)
    },
    [onChangeRun],
  )

  const contextValue: TableContextValue = useMemo(
    () => ({
      cellNodes,
      styles,
      tableRef,
      mode,
      tableValue,
      onChange,
      dndControlsCoordinates,
      setDndControlsCoordinates,
      dragState,
      setDragState,
      selectedCells,
      setSelectedCells,
      startSelectPosition,
      setStartSelectPosition,
      setCellNodes,
      value,
      id,
      rtProps,
      waiting,
      font,
    }),
    [
      cellNodes,
      tableRef,
      styles,
      mode,
      tableValue,
      dndControlsCoordinates,
      setDndControlsCoordinates,
      dragState,
      setDragState,
      selectedCells,
      setSelectedCells,
      startSelectPosition,
      setStartSelectPosition,
      value,
      font,
      id,
      onChange,
      rtProps,
      waiting,
    ],
  )

  useEffect(() => {
    if (!isActive) {
      setTableValue(value)
      setSelectedCells([])
      flush()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, value])

  return (
    <TableContext.Provider value={contextValue}>
      <div className={s.root}>
        <Table />
      </div>
    </TableContext.Provider>
  )
}

export default TableElement
