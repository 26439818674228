import { SidebarFontSchemaType } from '@vedalib/editor/lib/brand'
import { CourseStructureItem, SectionTypeEnum } from '@vedalib/editor/lib/elements'

import { ElementFontCss } from 'services/Branding/types'
import { useProjectT } from 'services/ProjectLabels'
import { BlockMode } from 'services/Store/Project/enums'
import { getEnumLabel } from 'utils/enum'

import { NameMap } from '../../utils'
import * as s from './Description.module.scss'

const Description = <S extends CourseStructureItem>({
  item,
  childrenChapter,
  font,
}: {
  item: S
  childrenChapter: number
  font?: ElementFontCss<SidebarFontSchemaType>
}) => {
  const pt = useProjectT()

  if (item.type === SectionTypeEnum.landing) {
    return null
  }

  const { blocks, type } = item
  const name = NameMap[type]

  const questionsCount = blocks.filter(({ mode }) => mode === BlockMode.questions).length

  const countDetails = `${
    type === SectionTypeEnum.test
      ? `${questionsCount} ${pt('courseStructure.questions')}`
      : `${childrenChapter} ${pt('courseStructure.sections')}`
  }`

  return (
    <div style={font?.description}>
      <span>{name(pt) || getEnumLabel('SectionTypeEnum', item.type)}</span>
      <span className={s.separator}>&#45;</span>
      <span>{countDetails}</span>
    </div>
  )
}

export default Description
