// extracted by mini-css-extract-plugin
var _1 = "TemplatePickerFooter_actionsContainer__6cnNL";
var _2 = "TemplatePickerFooter_buttons__vGQie";
var _3 = "TemplatePickerFooter_close__GahR2";
var _4 = "TemplatePickerFooter_leftSide__GvNwP";
var _5 = "TemplatePickerFooter_modalFooter__B8ROg";
var _6 = "TemplatePickerFooter_remove__V8eu7";
var _7 = "TemplatePickerFooter_selected__95hsR";
var _8 = "TemplatePickerFooter_selectedTitle__e2L7H";
export { _1 as "actionsContainer", _2 as "buttons", _3 as "close", _4 as "leftSide", _5 as "modalFooter", _6 as "remove", _7 as "selected", _8 as "selectedTitle" }
