import { isPossiblePhoneNumber } from 'react-phone-number-input'

import { IFieldRule } from 'components/controls/Field/Field.types'
import { t } from 'services/Translation'

import { validateEmail } from './emailValidation'

export const validateUrl = (value?: string | null) => {
  if (!value) {
    return false
  }

  const regStr = /^(https?:\/\/)?[a-zа-я]+[a-zа-я0-9\-.]+\.[a-zа-я0-9-]{2,20}(:[0-9]{1,5})?(\/.*)?$/

  return regStr.test(String(value).toLowerCase())
}

export const validateLinkUrl = (value?: string | null) => {
  if (!value) {
    return false
  }

  return validateUrl(value) || validateEmail.test(value)
}

export const prepareLinkUrl = (link: string) => {
  const isURLCorrect = /^https?/.test(link)
  const isTemplatesLink = /\.\/templates/.test(link)
  const isEmail = validateEmail.test(link)
  const linkToEmail = isEmail && `mailto:${link}`
  const linkToUrl = isURLCorrect || isTemplatesLink ? link : `//${link}`
  const correctLink = linkToEmail || linkToUrl
  return correctLink
}
export const prepareUrl = (link: string) => {
  const isURLCorrect = /^https?/.test(link)
  const linkToUrl = isURLCorrect ? link : `//${link}`
  return linkToUrl
}

export const linkUrlValidation = <V>(rule: IFieldRule<V>, value?: string) => {
  const isValid = validateLinkUrl(value)
  if (!isValid && value) {
    return rule.message || t('input.error.invalidUrl')
  }
  return false
}

export const urlValidation = <V>(rule: IFieldRule<V>, value?: string) => {
  const isValid = validateUrl(value)
  if (!isValid && value) {
    return rule.message || t('input.error.invalidUrl')
  }
  return false
}

export const iframeLinkValidation = <V>(rule: IFieldRule<V>, value?: string) => {
  const regStr = /^https?:\/\/.+/
  regStr.test(String(value).toLowerCase())
  const isValid = regStr.test(String(value).toLowerCase())
  if (!isValid && value) {
    return rule.message || t('input.error.invalidUrl')
  }
  return false
}

export const phoneValidation = <V>(rule: IFieldRule<V>, value?: string) => {
  if (!value || (typeof value === 'string' && isPossiblePhoneNumber(value))) {
    return false
  }

  return rule.message || 'Incorrect phone number'
  return false
}
