import React, { useContext } from 'react'

import Pagination from 'components/uiKit/Pagination'

import { TableContext } from '../TableContext'
import * as s from './TablePagination.module.scss'
import { useRedirectAccessPage } from './hooks'

type PaginationProps = {
  total: number
}

const TablePagination: React.FC<PaginationProps> = ({ total }) => {
  const {
    pagination: { page, setPage, pageSize },
  } = useContext(TableContext)
  useRedirectAccessPage(total)

  if (total <= pageSize) {
    return null
  }

  return (
    <div className={s.root}>
      <Pagination onChange={setPage} page={page} pageSize={pageSize} total={total} />
    </div>
  )
}

export default TablePagination
