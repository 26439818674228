import { CourseStructureItem } from '@vedalib/editor/lib/elements'

import { ISectionTreeNode } from 'components/sections/ProjectStructure/utils'
import Collapse from 'components/uiKit/Collapse'

import { StructureNodeCourse } from './components/StructureNodeCourse'

interface ICourseStructure {
  nodes: ISectionTreeNode<CourseStructureItem>[]
  onSectionPick: (sectionId: string) => void
  initialOpen: boolean
}

const CourseStructure: React.FC<ICourseStructure> = ({ nodes, onSectionPick, initialOpen }) => (
  <>
    {nodes.map((node) =>
      node.children?.length ? (
        <Collapse
          header={(props) => <StructureNodeCourse {...props} node={node} />}
          initialOpen={initialOpen}
          key={node.item.id}
          name={node.item.name || node.item.id}
          styleType='ghost'
        >
          <CourseStructure
            initialOpen={initialOpen}
            nodes={node.children}
            onSectionPick={onSectionPick}
          />
        </Collapse>
      ) : (
        <StructureNodeCourse
          key={node.item.id}
          node={node}
          onClick={() => onSectionPick(node.item.id)}
          open
        />
      ),
    )}
  </>
)

export default CourseStructure
