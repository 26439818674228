"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.TYPE = void 0;
const translation_1 = require("../../../translation");
const constants_1 = require("../../constants");
exports.TYPE = 'testInfoEnd';
exports.DEFAULT_VALUE = {
    type: 'end',
    percentage: {
        text: (0, translation_1.t)('elements.testInfoEnd.percentage'),
        show: true,
        icon: constants_1.imageValueWithObjectFit,
    },
    attempts: {
        text: (0, translation_1.t)('elements.testInfoEnd.attempts'),
        show: true,
        icon: constants_1.imageValueWithObjectFit,
    },
    testTime: {
        text: (0, translation_1.t)('elements.testInfoEnd.testTime'),
        show: true,
        icon: constants_1.imageValueWithObjectFit,
    },
    questions: {
        text: (0, translation_1.t)('elements.testInfoEnd.questions'),
        show: true,
        icon: constants_1.imageValueWithObjectFit,
    },
};
