import { RichTextPanelItem } from '@vedalib/editor/lib/elements'
import { createItem } from '@vedalib/editor/lib/elements/elements/Accordion/constants'
import { chainName } from 'utils'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import ScrollService from 'services/Scroll/ScrollService'
import { ScrollContainerEnum } from 'services/Scroll/enums'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'

import { throughElementItemsArray } from '../../utils/formEffects'
import { AccordionValueFormType } from './Accordion.types'

const genField = genDataField<Block>()

const showFirstTab = genField({
  name: 'showFirstTab',
  type: 'segmented',
  label: t('elements.accordion.form.showFirstTab'),
  layout: 'horizontal',
  defaultValue: false,
  info: t('elements.accordion.form.firstTabInfo'),
})

const multipleExpand = genField({
  name: 'multipleExpand',
  type: 'segmented',
  label: t('elements.accordion.form.multipleExpand'),
  layout: 'horizontal',
  defaultValue: true,
  info: t('elements.accordion.form.multipleExpandInfo'),
})

const slidesField = (
  onActive: (id: string) => void,
  onFocusItem: (index: string | null) => void,
  active?: string | null,
) =>
  genField({
    name: 'items',
    type: 'array',
    params: {
      active,
      onActive,
      onFocusItem,
      ordering: true,
      itemLabel: t('elements.accordion.form.component'),
      styleType: 'padding',
      onAdd: (changeData) => {
        const value = changeData.value as RichTextPanelItem[]
        return [...value, createItem()]
      },
      min: 1,
    },
    fields: [],
    effect: throughElementItemsArray,
  })

const AccordionForm: AccordionValueFormType = ({ name, state, setState }) => {
  const onActive = (id: string) =>
    ScrollService.scroll({ container: ScrollContainerEnum.toolbar, id }, null)
  const onFocus = (i: string | null) => setState({ ...state, active: i })

  return (
    <>
      <Padding bottom sides>
        <RealtimeField config={chainName(name, showFirstTab)} />
        <RealtimeField config={chainName(name, multipleExpand)} />
      </Padding>
      <RealtimeField config={chainName(name, slidesField(onActive, onFocus, state?.active))} />
    </>
  )
}

export default AccordionForm
