import { SCHEMA, LayoutListSchemaType } from '@vedalib/editor/lib/brand'
import { getParent } from 'utils'

import { genField } from 'components/controls/Field'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { genFormSchema } from 'services/Branding/genFormSchema'
import { ElementStyleCss } from 'services/Branding/types'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.layoutList.style, {
  settings: {
    META: { hidden: true },
    flexDirection: {},
    justifyContent: {
      params: {
        resettable: true,
        options: [
          { value: 'normal', label: <Icon name='alignmentAlignFull' size={KitSize.S} /> },
          { value: 'start', label: <Icon name='alignmentAlignLeft' size={KitSize.S} /> },
          { value: 'center', label: <Icon name='alignmentAlignMid' size={KitSize.S} /> },
          { value: 'end', label: <Icon name='alignmentAlignRight' size={KitSize.S} /> },
        ],
      },
    },
  },
  indents: {
    META: { label: t('elements.layoutList.tags.indents') },
    columnGap: genField({
      label: t('elements.layoutList.schema.iconMargin'),
      type: 'number',
      layout: ['horizontal', 'solid'],
      params: (block, { name }) => {
        const { parent } = getParent<ElementStyleCss<LayoutListSchemaType>>(name, block, 2)
        return { disabled: parent?.settings?.flexDirection === 'column', width: 64 }
      },
    }),
    rowGap: genField({
      label: t('elements.layoutList.schema.sectionsMargin'),
      type: 'number',
      layout: ['horizontal', 'solid'],
      params: { width: 64, min: 0 },
    }),
  },
})
