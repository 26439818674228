import cn from 'classnames'
import React from 'react'

import { KitSize } from 'components/uiKit/KitTypes'
import { testProps } from 'utils/test/qaData'

import { IconButton } from '../Button'
import DropdownMenu from '../DropdownMenu'
import Icon, { KeyIconsType } from '../Icon'
import { IMenuOption } from '../Menu'
import * as s from './Breadcrumbs.module.scss'

export type BreadcrumbItem = {
  name?: string | null
  icon?: KeyIconsType
  id?: string
}
interface IBreadcrumbsProps<T extends BreadcrumbItem> {
  items: T[]
  onClickBreadcrumbs?: (item: T) => void
  separator?: React.ReactNode
}

const DefaultSeparator = <Icon name='otherRight' size={KitSize.S} />

type BreadcrumbsMenuProps<T extends BreadcrumbItem> = Pick<
  IBreadcrumbsProps<T>,
  'items' | 'onClickBreadcrumbs'
>

const BreadcrumbsMenu = <T extends BreadcrumbItem>({
  items,
  onClickBreadcrumbs,
}: BreadcrumbsMenuProps<T>): JSX.Element => {
  const options = items
    .map((item) => ({ label: item.name, value: item.name || '', icon: item.icon }))
    .reverse()

  const handleClick = ({ value }: IMenuOption<string>) => {
    const item = items.find((item) => item.name === value)
    if (item) {
      onClickBreadcrumbs?.(item)
    }
  }

  return (
    <DropdownMenu name='breadcrumbs' onClick={handleClick} options={options} placement='bottomLeft'>
      {({ open }) => (
        <IconButton
          active={open}
          icon='iconsOtherMore'
          name='breadcrumbsCollapsed'
          size={KitSize.XS}
          styleType='secondary'
          type='button'
        />
      )}
    </DropdownMenu>
  )
}

const Breadcrumbs = <T extends BreadcrumbItem>({
  items,
  onClickBreadcrumbs,
  separator = DefaultSeparator,
}: IBreadcrumbsProps<T>): JSX.Element => {
  const collapsedItems = items.length > 2 ? items.slice(0, -2) : null
  const shownItems = items.length > 2 ? items.slice(-2) : items

  const handleClickBreadcrumbs = (item: BreadcrumbItem & T) => {
    onClickBreadcrumbs?.(item)
  }

  return (
    <div className={s.wrapper}>
      {collapsedItems && (
        <>
          <BreadcrumbsMenu items={collapsedItems} onClickBreadcrumbs={handleClickBreadcrumbs} />
          {separator}
        </>
      )}
      {shownItems.map((item, index) => (
        <React.Fragment key={item.id || item.name}>
          <span
            className={cn(s.breadcrumbItem, { [s.active]: index === shownItems.length - 1 })}
            onClick={() => handleClickBreadcrumbs(item)}
            {...testProps({ el: 'breadcrumbItem', name: item.name, id: item.id })}
          >
            {item.name}
          </span>
          {index < shownItems.length - 1 && separator}
        </React.Fragment>
      ))}
    </div>
  )
}

export default Breadcrumbs
