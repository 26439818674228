import * as R from 'ramda'

import {
  deleteChildElementDeepMutator,
  deleteChildNodesDeepMutator,
} from 'components/editor-v3/context/EditorContext/mutators/block'
import { getDeepCloneNode } from 'components/editor-v3/context/EditorContext/selectors/block'
import { createColNode } from 'components/editor-v3/context/EditorContext/struct/block'
import { Block } from 'services/Store/Project/types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const throughElementItemsArray = (block: Block, changeData: any) => {
  const nameArr = changeData.name.split('.')
  if (changeData?.meta?.command === 'add') {
    const createNodes = []
    const createElements = []
    const nodeId = changeData.value[changeData.value.length - 1].value
    if (changeData.value.length > 1) {
      const { nodes, elements, id } = getDeepCloneNode(block, changeData.value[0].value)
      createNodes.push(...nodes.map((node) => (node.id === id ? { ...node, id: nodeId } : node)))
      createElements.push(...elements)
    } else {
      const column = createColNode()
      column.id = nodeId
      createNodes.push(column)
    }
    const value = R.compose(
      R.assocPath(nameArr, changeData.value),
      //@ts-expect-error R.compose retype needed
      ...createNodes.map((node) => R.assocPath(['schema', 'nodes', node.id], node)),
      ...createElements.map((element) => R.assocPath(['elements', element.id], element)),
    )(block)

    return { name: '', value }
  }
  if (changeData?.meta?.command === 'clone') {
    const createNodes = []
    const createElements = []
    const nodeId = changeData.value[changeData.value.length - 1].value
    const { nodes, elements, id } = getDeepCloneNode(block, changeData?.meta.item.value)
    createNodes.push(...nodes.map((node) => (node.id === id ? { ...node, id: nodeId } : node)))
    createElements.push(...elements)

    const value = R.compose(
      R.assocPath(nameArr, changeData.value),
      //@ts-expect-error R.compose retype needed
      ...createNodes.map((node) => R.assocPath(['schema', 'nodes', node.id], node)),
      ...createElements.map((element) => R.assocPath(['elements', element.id], element)),
    )(block)

    return { name: '', value }
  }
  if (changeData && changeData?.meta?.command === 'delete') {
    const value = R.compose(
      R.assocPath(nameArr, changeData.value),
      deleteChildNodesDeepMutator(block, changeData.meta.item.value),
      deleteChildElementDeepMutator(block, changeData.meta.item.value),
    )(block)
    return { name: '', value }
  }

  return changeData
}
