import { CardSchemaType } from '@vedalib/editor/lib/brand'
import styled from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

import IconStyled from './IconStyled'

const ElementRootStyled = styled.div<{
  $styles: ElementStyleCss<CardSchemaType>
  $noHover: boolean
}>`
  max-width: 100%;
  background-color: transparent;
  background-color: var(--color-background-main);
  perspective: 1000px;
  width: ${({ $styles }) => $styles.card.width};
  height: ${({ $styles }) => $styles.card.height};
  border-radius: ${({ $styles }) => $styles.card.borderRadius};

  &:hover ${IconStyled} {
    background-color: ${({ $styles, $noHover }) => !$noHover && $styles.hover.backgroundColor};
  }
`

export default ElementRootStyled
