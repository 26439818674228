import * as R from 'ramda'

import { getLastViewedSection } from 'components/editor-v2/EditorElements/elements/ButtonElement/helper'

import { getBlocksState, getContinueBlockId, getSection, getSections } from '../selectors'
import { IProjectContext, PreviewCourseStatus, Section } from '../types'
import { updateNavigation } from './common'

export const courseStart = (state: IProjectContext) => {
  const firstSection = getSection(state, { first: true })
  if (firstSection) {
    return R.compose(
      updateNavigation({
        sectionId: firstSection.id,
        blockId: R.head(firstSection.blocksOrder),
      }),
      R.assocPath<PreviewCourseStatus, IProjectContext>(
        ['state', 'course', 'status'],
        'incomplete',
      ),
    )(state)
  }

  return state
}

export const setCourseStatus = (value: PreviewCourseStatus) => (state: IProjectContext) => {
  return R.assocPath(['state', 'course', 'status'], value, state)
}

export const setSidebar = (open: boolean) => (state: IProjectContext) =>
  R.assocPath(['state', 'sidebar', 'open'], open, state)

export const setSidebarChapter = (chapter: Section | null) => (state: IProjectContext) =>
  R.assocPath(['state', 'sidebar', 'chapter'], chapter, state)

export const setSidebarSearch = (search?: string) => (state: IProjectContext) =>
  R.assocPath(['state', 'sidebar', 'search'], search, state)

export const courseContinue = (state: IProjectContext) => {
  const sections = getSections(state)
  const blocksState = getBlocksState(state)
  const lastViewedSection = getLastViewedSection(sections, blocksState)
  const continueBlock = getContinueBlockId(state, lastViewedSection?.id)
  if (lastViewedSection && continueBlock) {
    return updateNavigation({
      sectionId: lastViewedSection.id,
      blockId: continueBlock,
    })(state)
  }

  return state
}
