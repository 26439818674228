// extracted by mini-css-extract-plugin
var _1 = "Badge_animation__RgAGA";
var _2 = "Badge_badge__-6JqA";
var _3 = "Badge_count__BteWj";
var _4 = "Badge_dot__hX2sw";
var _5 = "Badge_left__vtbuJ";
var _6 = "Badge_medium__VTiKS";
var _7 = "Badge_mini__PNdf+";
var _8 = "Badge_right__-2l4q";
var _9 = "Badge_root__lrOjB";
export { _1 as "animation", _2 as "badge", _3 as "count", _4 as "dot", _5 as "left", _6 as "medium", _7 as "mini", _8 as "right", _9 as "root" }
