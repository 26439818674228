import Icon, { KeyIconsType } from 'components/uiKit/Icon'

import * as s from './ButtonIcon.module.scss'

interface IButtonIconProps {
  defaultName: KeyIconsType
  url?: string | null
}

const ButtonIcon = ({ url, defaultName }: IButtonIconProps) => {
  return url ? <img className={s.icon} src={url} /> : <Icon name={defaultName} />
}

export default ButtonIcon
