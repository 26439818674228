import * as R from 'ramda'
import { useMemo } from 'react'

import { ElementFontCss } from 'services/Branding/types'

// TODO: make it better
export const useFontToVars = (font: ElementFontCss) =>
  useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const style: any = {}
    R.forEachObjIndexed(
      (font, fontName) =>
        R.forEachObjIndexed((prop, propKey) => {
          style[`--${String(fontName)}-${String(propKey)}`] = prop
        }, font),
      font,
    )
    return style
  }, [font]) as React.CSSProperties
