import { PanelItem } from '@vedalib/editor/lib/elements'
import { SwipeEventData, useSwipeable } from 'react-swipeable'
import { useContext } from 'use-context-selector'

import NodeContainer from 'components/editor-v3/cource/layout/Node/NodeContainer'
import { Block } from 'services/Store/Project/types'

import SliderContext from '../SliderContext'
import * as s from './SlidesList.module.scss'

interface ISlideProps {
  data: PanelItem
  block: Block
}

interface ISlidesListProps {
  block: Block
}

const Slide = ({ data, block }: ISlideProps) => {
  const { mode, rightArrowNoLoop, leftArrowNoLoop, prevSlide, nextSlide } =
    useContext(SliderContext)

  const onSwipedLeft = (e: SwipeEventData) => {
    e.event.preventDefault()
    e.event.stopPropagation()
    if (!rightArrowNoLoop) {
      nextSlide()
    }
  }

  const onSwipedRight = (e: SwipeEventData) => {
    e.event.preventDefault()
    e.event.stopPropagation()
    if (!leftArrowNoLoop) {
      prevSlide()
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft,
    onSwipedRight,
  })

  return (
    <div className={s.slide} {...handlers}>
      <div className={s.nodeWrapper} key={data.value}>
        <NodeContainer block={block} id={data.value} level={0} mode={mode} />
      </div>
    </div>
  )
}

const SlidesList = ({ block }: ISlidesListProps) => {
  const { slideIndex, items } = useContext(SliderContext)
  return (
    <div
      className={s.list}
      style={{
        transform: `translate3d(-${slideIndex * 100}%, 0px, 0px)`,
      }}
    >
      {items.map((slide: PanelItem, index: number) => (
        <Slide block={block} data={slide} key={index + slideIndex} />
      ))}
    </div>
  )
}

export default SlidesList
