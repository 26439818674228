import { FileUsageTypeEnum } from '@vedalib/editor/lib/files'
import { FileTypeEnum } from 'utils'

import MediaPlayer from 'components/MediaPlayer'
import { getFileByUsage } from 'utils/files'

import * as s from './VideoPreview.module.scss'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const VideoPreview = ({ value }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mediaFile: any = getFileByUsage(value, FileUsageTypeEnum.video)

  return (
    <div className={s.root}>
      {(mediaFile || value?.id || value?.url) && (
        <MediaPlayer type={FileTypeEnum.VIDEO} url={mediaFile?.path || value.url || null} />
      )}
    </div>
  )
}

export default VideoPreview
