import { SectionTypeEnum } from '@vedalib/editor/lib/elements'

import SectionTreeItemEditor from 'components/editor-v3/Editor/Navigation/NavigationProject/components/SectionsTreeEditor/components/SectionTreeItemEditor'
import Empty from 'components/uiKit/Empty'
import { Section } from 'services/Store/Project/types'

import * as s from './SectionPlaneSearch.module.scss'

interface ISectionPlaneSearchProps {
  searchValue: string
  onSelect?: (item: Section) => void
  resultSections: Section[]
  setIsEditSectionId: (sectionId: string) => void
  sectionId?: string
  disabledByTypes?: SectionTypeEnum[]
}

const SectionPlaneSearch = ({
  resultSections,
  searchValue,
  onSelect,
  setIsEditSectionId,
  sectionId,
  disabledByTypes,
}: ISectionPlaneSearchProps) => {
  if (!resultSections.length) {
    return <Empty grow search />
  }

  return (
    <div className={s.root}>
      {resultSections.map((item) => (
        <SectionTreeItemEditor
          disabledByTypes={disabledByTypes}
          item={item}
          key={item.id}
          onSelect={onSelect}
          searchValue={searchValue}
          sectionId={sectionId}
          setIsEditSectionId={setIsEditSectionId}
        />
      ))}
    </div>
  )
}

export default SectionPlaneSearch
