import { RichTextValue, textToRtValue } from '@vedalib/rich-text'
import React, { useCallback, useMemo, useRef } from 'react'
import { useDrop } from 'react-dnd'

import RichText from 'components/form/RichText/RichText'
import { RichTextRef } from 'components/form/RichText/RichTextTypes'
import { PreviewMode } from 'services/Store/Project/enums'
import { testProps } from 'utils/test/qaData'

import { useTableContext } from '../../TableContext'
import { DragItem, ICellProps } from '../../TableElement.types'
import Resizer from '../Resizer'
import TableDragControl from '../TableDragControl'
import * as s from './Cell.module.scss'
import { getCellStyles } from './getCellStyles'

const parseId = (id: string) => {
  const [row, col] = id.split('.')
  return [Number(row), Number(col)]
}

const Cell = ({ cell, index, indexRow }: ICellProps) => {
  const {
    styles,
    mode,
    dndControlsCoordinates,
    changeOrder,
    selectRow,
    selectCol,
    dragState,
    setDndControlsCoordinates,
    resizeColumn,
    getWidth,
    tableValue,
    setSelected,
    startSelectPosition,
    setStartSelectPosition,
    tableRef,
    setCellNodes,
    rtProps,
    waiting,
    font,
    onChangeCellText,
  } = useTableContext()
  const containerRef = useRef<HTMLTableCellElement | null>(null)
  const richTextRef = useRef<RichTextRef>(null)
  const col = index
  const row = indexRow
  const width = getWidth(col)

  const handleWidthChange = (newWidth: number) => {
    resizeColumn(col, newWidth)
  }

  const [, drop] = useDrop(
    () => ({
      accept: 'cell',
      drop: (item: DragItem) => {
        const { axis, id: dragId } = item
        const [dragRow, dragCol] = parseId(dragId)
        const dropRow = row
        const dropCol = col
        const isSameCell = dragRow === dropRow && dragCol === dropCol
        const isSameRow = dragRow === dropRow
        const isSameCol = dragCol === dropCol
        const isRowDrag = axis === 'row'
        const isColDrag = axis === 'column'

        if (isSameCell) {
          return
        }

        if (isRowDrag && isSameRow) {
          return
        }

        if (isColDrag && isSameCol) {
          return
        }

        if (isRowDrag) {
          changeOrder(axis, dragRow, dropRow)
          selectRow(dropRow)
        }

        if (isColDrag) {
          changeOrder(axis, dragCol, dropCol)
          selectCol(dropCol)
        }
      },
    }),
    [row, col, tableValue],
  )

  const isPreview = mode.previewMode !== PreviewMode.editor

  const showRowControl =
    Boolean(col === 0 && row === dndControlsCoordinates.x) &&
    !isPreview &&
    dragState.dragType === null

  const showColControl =
    Boolean(row === 0 && col === dndControlsCoordinates.y) &&
    !isPreview &&
    dragState.dragType === null

  drop(containerRef)

  const isSelecting = Boolean(startSelectPosition)

  const handleMouseOver = () => {
    if (isSelecting) {
      setSelected({ row, col })
    } else {
      setDndControlsCoordinates({ x: row, y: col })
    }
  }

  const handleClick = () => {
    rtProps.onLabelSelect(name)
    setTimeout(() => richTextRef.current?.focus())
  }

  const handleMouseDown = () => {
    setSelected({ row, col }, true)
    setStartSelectPosition({ row, col })

    const onMouseUp = () => {
      setStartSelectPosition(null)
      document.removeEventListener('mouseup', onMouseUp)
    }

    document.addEventListener('mouseup', onMouseUp)
  }

  const handleOnChange = useCallback(
    (val: RichTextValue) => onChangeCellText(val, row, col),
    [onChangeCellText, col, row],
  )

  const cellDynamicProps = {
    onMouseOver: handleMouseOver,
    onMouseDown: handleMouseDown,
    onClick: handleClick,
  }

  const assignRef = useCallback(
    (el: HTMLTableCellElement | null) => {
      containerRef.current = el
      setCellNodes((oldNodes) => {
        if (!el) {
          return oldNodes
        }

        const newNodes = [...oldNodes]
        if (!oldNodes[row]) {
          newNodes[row] = []
        }

        newNodes[row][col] = el
        return newNodes
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [row, col],
  )

  const cellStyles = useMemo(
    () =>
      getCellStyles({
        cell,
        col,
        headerColumn: tableValue.headerColumn,
        headerRow: tableValue.headerRow,
        row,
        styles,
      }),
    [cell, col, row, styles, tableValue.headerColumn, tableValue.headerRow],
  )

  const name = `table.${row}.${col}`

  return (
    <td
      className={s.td}
      colSpan={cell.colspan}
      ref={assignRef}
      rowSpan={cell.rowspan}
      style={{ width, minWidth: width, ...cellStyles }}
      {...(!isPreview && cellDynamicProps)}
    >
      <pre className={s.root}>
        <span className={s.defaultTitleContent} {...testProps({ el: 'courseTableCell', col, row })}>
          <RichText
            active={rtProps.isActiveElement && rtProps.activeIndex === name}
            disabled={!rtProps.isFill}
            key={name}
            name={name}
            onChange={handleOnChange}
            ref={richTextRef}
            styles={font}
            value={cell?.value || textToRtValue(' ')}
            waiting={waiting}
          />
        </span>
      </pre>
      {row === 0 && (
        <TableDragControl
          axis='column'
          height={tableRef.current?.clientHeight || 0}
          hidden={!showColControl}
          id={`${row}.${col}`}
          name='table-column-control'
          onClick={() => selectCol(col)}
          width={width}
        />
      )}
      {col === 0 && (
        <TableDragControl
          axis='row'
          height={containerRef.current?.clientHeight || 0}
          hidden={!showRowControl}
          id={`${row}.${col}`}
          name='table-row-control'
          onClick={() => selectRow(row)}
          width={width}
        />
      )}
      {row === 0 && !isPreview && !isSelecting && (
        <Resizer
          height={tableRef.current?.clientHeight || 0}
          onResize={handleWidthChange}
          width={width}
        />
      )}
    </td>
  )
}

export default Cell
