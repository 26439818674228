import { SlateElementType } from '@vedalib/rich-text'
import { Editor } from 'slate'

export const withMentions = (editor: Editor) => {
  const { isInline, isVoid, markableVoid } = editor

  editor.isInline = (element) =>
    element.type === SlateElementType.mention ? true : isInline(element)

  editor.isVoid = (element) => (element.type === SlateElementType.mention ? true : isVoid(element))

  editor.markableVoid = (element) =>
    element.type === SlateElementType.mention || markableVoid(element)

  return editor
}
