"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.imageValueWithObjectFit = exports.imageValue = void 0;
const types_1 = require("../files/types");
exports.imageValue = {
    source: types_1.FileUsageImageSource.file,
    id: '',
    accessibility: '',
};
exports.imageValueWithObjectFit = Object.assign(Object.assign({}, exports.imageValue), { objectFit: types_1.ObjectFitEnum.cover });
