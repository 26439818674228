// extracted by mini-css-extract-plugin
var _1 = "EmployeeAvatar_active__ioYBD";
var _2 = "EmployeeAvatar_disabled__Jkxim";
var _3 = "EmployeeAvatar_icon__5Norr";
var _4 = "EmployeeAvatar_info__HcvzO";
var _5 = "EmployeeAvatar_large__KcrnR";
var _6 = "EmployeeAvatar_medium__aD9WK";
var _7 = "EmployeeAvatar_mini__maMwY";
var _8 = "EmployeeAvatar_root__5h88u";
var _9 = "EmployeeAvatar_stub__LHp1r";
var _a = "EmployeeAvatar_xmini__it6Sc";
export { _1 as "active", _2 as "disabled", _3 as "icon", _4 as "info", _5 as "large", _6 as "medium", _7 as "mini", _8 as "root", _9 as "stub", _a as "xmini" }
