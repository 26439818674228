import { Cell } from '@vedalib/editor/lib/elements'

import { Properties } from 'components/editor-v2/EditorElements/elements.types'

import { TableElementType } from '../../TableElement.types'

export const getCellStyles = ({
  cell,
  col,
  headerColumn,
  headerRow,
  row,
  styles,
}: {
  cell: Cell
  headerRow: boolean
  headerColumn: boolean
  row: number
  col: number
  styles: Properties<TableElementType>['styles'] | null
}) => {
  const isHeader = (row === 0 && headerRow) || (col === 0 && headerColumn)

  return {
    ...styles?.content,
    ...styles?.divider,
    ...(isHeader ? styles?.header : {}),
    ...styles?.indent,
    ...(cell?.color && { backgroundColor: cell?.color }),
  }
}
