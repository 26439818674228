import { Options } from 'overlayscrollbars'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import React, { createContext, useContext, useMemo, useState } from 'react'

import './LayoutScroll.module.scss'

const ScrollContext = createContext<OverlayScrollbars | null>(null)

export const useScrollLayout = () => useContext(ScrollContext)

export type ScrollRef = OverlayScrollbarsComponent

interface IHorizontalPosition {
  top?: number
  bottom?: number
}

interface IVerticalPosition {
  right?: number
  left?: number
}

interface ILayoutScrollProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Indicates whether the host element is capable of "auto" sizes such as: width: auto and height: auto. If set to false and the property width or height is "auto", the rendered width or height of the content will be zero.
   * If you are applying OverlayScrollbars to a flexbox-element set this option to false to ensure correct functionality.
   *
   * This option is ignored if the target-element is the body element, because in this case the size must be 100% (on both axis) to simulate the viewport correctly.
   *
   * {@link https://kingsora.github.io/OverlayScrollbars/v1/#!documentation/options DOCS}
   */
  sizeAutoCapable?: boolean
  children: React.ReactNode
  autoUpdate?: boolean | null
  autoUpdateInterval?: number
  autoHide?: 'never' | 'scroll' | 'leave' | 'move'
  vertical?: IVerticalPosition
  horizontal?: IHorizontalPosition
  showBorder?: boolean
}

const DEFAULT_AUTO_UPDATE_INTERVAL = 33

const LayoutScroll = React.forwardRef<ScrollRef, ILayoutScrollProps>(
  (
    {
      children,
      autoHide = 'scroll',
      sizeAutoCapable = false,
      autoUpdate = null,
      autoUpdateInterval = DEFAULT_AUTO_UPDATE_INTERVAL,
      horizontal,
      vertical,
      showBorder = false,
      ...rest
    },
    outerRef,
  ) => {
    const localRef = React.useRef<ScrollRef>(null)
    const ref = outerRef || localRef
    const [bottomBorder, setBottomBorder] = useState<boolean>(false)
    const [topBorder, setTopBorder] = useState<boolean>(false)
    const cssVars = useMemo(
      () =>
        ({
          ...(horizontal?.top && { '--os-scroll-horizontal-top': `${horizontal.top}px` }),
          ...(horizontal?.bottom && { '--os-scroll-horizontal-bottom': `${horizontal.bottom}px` }),
          ...(vertical?.right && { '--os-scroll-vertical-right': `${vertical.right}px` }),
          ...(vertical?.left && { '--os-scroll-vertical-left': `${vertical.left}px` }),
        }) as React.CSSProperties,
      [horizontal?.top, horizontal?.bottom, vertical?.left, vertical?.right],
    )
    const options: Options = useMemo(
      () => ({
        scrollbars: { autoHide },
        sizeAutoCapable,
        autoUpdate,
        autoUpdateInterval,
        callbacks: {
          onScroll: (e) => {
            const target = e?.target as HTMLElement
            if (target) {
              const isScrollable = target.scrollHeight > target.clientHeight

              if (isScrollable && showBorder) {
                setTopBorder(target.scrollTop > 0.5)
                setBottomBorder(target.scrollHeight - target.scrollTop > target.clientHeight)
              } else {
                setTopBorder(false)
                setBottomBorder(false)
              }
            }
          },
        },
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [autoHide, sizeAutoCapable, autoUpdate, autoUpdateInterval],
    )

    return (
      <ScrollContext.Provider
        value={(ref as React.RefObject<ScrollRef>).current?.osInstance() || null}
      >
        <OverlayScrollbarsComponent
          {...rest}
          // eslint-disable-next-line react/forbid-component-props
          className='os-host-flexbox'
          options={options}
          ref={ref}
          // eslint-disable-next-line react/forbid-component-props
          style={{
            ...cssVars,
            borderTop: topBorder ? '1px solid var(--color-delimiter-main)' : 'none',
            borderBottom: bottomBorder ? '1px solid var(--color-delimiter-main)' : 'none',
          }}
        >
          {children}
        </OverlayScrollbarsComponent>
      </ScrollContext.Provider>
    )
  },
)

LayoutScroll.displayName = 'LayoutScroll'

export default React.memo(LayoutScroll)
