// extracted by mini-css-extract-plugin
var _1 = "TextArea_additionRow__iMRVb";
var _2 = "TextArea_base__LFUd-";
var _3 = "TextArea_disabled__axhWG";
var _4 = "TextArea_error__4yUSX";
var _5 = "TextArea_fluid__5vJUE";
var _6 = "TextArea_ghost__BlYT1";
var _7 = "TextArea_hasPostfix__ubXb2";
var _8 = "TextArea_medium__c6Qur";
var _9 = "TextArea_mini__YxalG";
var _a = "TextArea_postfix__cRQJt";
var _b = "TextArea_root__-y3Eq";
var _c = "TextArea_textarea__T6-So";
export { _1 as "additionRow", _2 as "base", _3 as "disabled", _4 as "error", _5 as "fluid", _6 as "ghost", _7 as "hasPostfix", _8 as "medium", _9 as "mini", _a as "postfix", _b as "root", _c as "textarea" }
