import { AccordionSchemaType } from '@vedalib/editor/lib/brand'
import styled, { css } from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

import { Position } from '../AccordionElement'

interface IProps {
  $styles: ElementStyleCss<AccordionSchemaType>
  $isOpen?: boolean
}

const isDefaultIcon = (styles: ElementStyleCss<AccordionSchemaType>) =>
  !styles.icon.backgroundImage && !styles.icon.backgroundOpen

const left = ({ $styles, $isOpen }: IProps) => css`
  margin-right: ${$styles.indents.icon[0]}px;
  ${isDefaultIcon($styles) &&
  css`
    transform: ${$isOpen ? 'rotate(0deg)' : 'rotate(-90deg)'};
  `}
`

const right = ({ $styles, $isOpen }: IProps) => css`
  margin-left: ${$styles.indents.icon[1]}px;
  ${isDefaultIcon($styles) &&
  css`
    transform: ${$isOpen ? 'rotate(0deg)' : 'rotate(90deg)'};
  `}
`

const backgroundImage = ({ $styles, $isOpen }: IProps) => css`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${$isOpen ? $styles.icon.backgroundOpen : $styles.icon.backgroundImage};
`

const IconStyled = styled.div<IProps>`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;

  color: ${({ $styles }) => $styles.icon.color};

  ${backgroundImage}

  ${({ $styles }) => ($styles.icon.position as Position) === Position.left && left}
  ${({ $styles }) => ($styles.icon.position as Position) === Position.right && right}
`
export default IconStyled
