import cn from 'classnames'
import { useMemo } from 'react'
import { FileTypeEnum } from 'utils'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import Divider from 'components/uiKit/Divider'
import { genColorOptions } from 'services/Branding/constants/fields'
import { useBrandTheme } from 'services/Branding/hooks'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { chainName } from 'utils/form'

import { CardValueFormType } from './CardElement.types'
import * as s from './CardForm.module.scss'

const genField = genDataField<Block>()

export const frontImage = genField({
  name: 'front.image',
  label: t('elements.card.form.sideA'),
  type: 'file',
  layout: 'vertical',
  params: {
    fileType: FileTypeEnum.IMAGE,
    nullable: true,
    preview: true,
    showSource: true,
    hasObjectFit: true,
    hasAccessibility: true,
  },
})

export const frontOverlay = genField({
  name: 'front.overlay',
  type: 'color',
  label: t('input.label.overlay'),
  defaultValue: '#FFFFFF00',
  layout: 'horizontal',
  useParams: () => {
    const theme = useBrandTheme()
    const options = useMemo(() => genColorOptions(theme), [theme])
    return {
      options,
      labeled: true,
    }
  },
})

export const backImage = genField({
  name: 'back.image',
  type: 'file',
  layout: 'vertical',
  label: t('elements.card.form.sideB'),
  params: {
    fileType: FileTypeEnum.IMAGE,
    nullable: true,
    preview: true,
    showSource: true,
    hasObjectFit: true,
    hasAccessibility: true,
  },
})

export const backOverlay = genField({
  name: 'back.overlay',
  type: 'color',
  label: t('input.label.overlay'),
  defaultValue: '#FFFFFF00',
  layout: 'horizontal',
  useParams: () => {
    const theme = useBrandTheme()
    const options = useMemo(() => genColorOptions(theme), [theme])
    return {
      options,
      labeled: true,
    }
  },
})

const CardForm: CardValueFormType = ({ name, state, setState }) => {
  const handleAClick = () => setState({ isBack: false, pauseAnimation: false })
  const handleBClick = () => setState({ isBack: true, pauseAnimation: false })

  return (
    <>
      <div className={cn({ [s.active]: !state?.isBack })} onClick={handleAClick}>
        <Padding bottom sides top>
          <RealtimeField config={chainName(name, frontImage)} />
          <RealtimeField config={chainName(name, frontOverlay)} />
        </Padding>
      </div>
      <Divider free />
      <div className={cn({ [s.active]: state?.isBack })} onClick={handleBClick}>
        <Padding bottom sides top>
          <RealtimeField config={chainName(name, backImage)} />
          <RealtimeField config={chainName(name, backOverlay)} />
        </Padding>
      </div>
    </>
  )
}

export default CardForm
