import { ButtonActionType, ButtonBlockAction } from '@vedalib/editor/lib/elements'
import lodash from 'lodash'

import { SHELL_SIZE_KEYS } from 'components/editor-v3/types/data.constants'

import { ButtonElementType } from './ButtonElement.types'
import ContinueButtonElement from './Buttons/ContinueButtonElement'
import CourseButtonElement from './Buttons/CourseButtonElement'
import DefaultButtonElement from './Buttons/DefaultButtonElement'
import DownloadFileElement from './Buttons/DownloadFileElement'
import EndButtonElement from './Buttons/EndButtonElement'
import EndCourseButtonElement from './Buttons/EndCourseButtonElement'
import LinkButtonElement from './Buttons/LinkButtonElement'
import NavigationButtonElement from './Buttons/NavigationButtonElement'
import ResetButtonElement from './Buttons/ResetButtonElement'
import RestartButtonElement from './Buttons/RestartButtonElement'
import StartButtonElement from './Buttons/StartButtonElement'
import SubmitButtonElement from './Buttons/SubmitButtonElement'

const COMPONENT_BY_ACTION_TYPE = {
  default: DefaultButtonElement,
  [ButtonActionType.link]: LinkButtonElement,
  [ButtonActionType.navigation]: NavigationButtonElement,
  [ButtonActionType.file]: DownloadFileElement,
  [ButtonActionType.continue]: ContinueButtonElement,
  [ButtonBlockAction.testRestart]: RestartButtonElement,
  [ButtonBlockAction.testEnd]: EndButtonElement,
  [ButtonBlockAction.courseStart]: CourseButtonElement,
  [ButtonBlockAction.testStart]: StartButtonElement,
  [ButtonBlockAction.validate]: SubmitButtonElement,
  [ButtonBlockAction.courseEnd]: EndCourseButtonElement,
  [ButtonBlockAction.reset]: ResetButtonElement,
}

const ButtonElement: ButtonElementType = ({ element, styles, mode, ...rest }) => {
  const { action, actionType, actionData } = element.value

  const Component =
    COMPONENT_BY_ACTION_TYPE[action as keyof typeof COMPONENT_BY_ACTION_TYPE] ||
    COMPONENT_BY_ACTION_TYPE[actionType as keyof typeof COMPONENT_BY_ACTION_TYPE] ||
    DefaultButtonElement

  const stylesWithSize = {
    ...styles,
    root: {
      ...styles.root,
      ...lodash.pick(styles.shell, SHELL_SIZE_KEYS),
    },
  }

  return (
    <Component
      {...rest}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      element={{ ...element, element: { value: actionData } } as any}
      mode={mode}
      styles={stylesWithSize}
    />
  )
}
export default ButtonElement
