"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_VALUE = exports.TYPE = void 0;
const id_1 = require("../../../utils/id");
const rich_text_1 = require("@vedalib/rich-text");
exports.TYPE = 'testRanking';
exports.DEFAULT_VALUE = {
    items: [
        {
            value: (0, id_1.generateId)(),
            label: (0, rich_text_1.textToRtValue)(''),
            points: 1,
        },
        {
            value: (0, id_1.generateId)(),
            label: (0, rich_text_1.textToRtValue)(''),
            points: 1,
        },
    ],
};
