import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default class PortalTarget extends React.Component<{ id: string } | { [key: string]: any }> {
  shouldComponentUpdate() {
    return false
  }

  render() {
    return <div {...this.props} />
  }
}
