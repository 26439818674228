import { ReactNode } from 'react'

import { ValueType } from 'components/uiKit/KitTypes'
import Link from 'components/uiKit/Link'
import { IMenuOption } from 'components/uiKit/Menu/MenuItem'
import { COMPANY_COLLECTION_PATHS } from 'constants/paths'
import generateLink from 'routes/generateLink'
import { extendTestData } from 'utils/test/qaData'

import * as s from './TemplateCardContainer.module.scss'

interface ITemplateCardContainerProps<V extends ValueType> {
  actions?: IMenuOption<V>[]
  children: ReactNode
  collectionId: string
  companyId: string
  templatesCount: number
}

const TemplateCardContainer = <V extends ValueType>({
  actions,
  children,
  companyId,
  collectionId,
  templatesCount,
}: ITemplateCardContainerProps<V>) => {
  const templateLink = generateLink(COMPANY_COLLECTION_PATHS.collection, {
    companyId,
    collectionId,
  })
  return (
    <div className={s.root}>
      {actions ? (
        <Link
          name='templateCard'
          styleType='free'
          testData={extendTestData({ id: collectionId, templatesCount })}
          to={templateLink}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>{children}</div>
        </Link>
      ) : (
        children
      )}
    </div>
  )
}

export default TemplateCardContainer
