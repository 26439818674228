import { useDebounceFn } from 'ahooks'
import { useState } from 'react'
import { useContextSelector } from 'use-context-selector'

import Breadcrumbs from 'components/uiKit/Breadcrumbs'
import { BreadcrumbItem } from 'components/uiKit/Breadcrumbs/Breadcrumbs'
import Dropdown from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { IMenuOption } from 'components/uiKit/Menu'
import Menu from 'components/uiKit/Menu/Menu'
import Search from 'components/uiKit/Search'
import SortPopover from 'components/uiKit/SortPopover'
import {
  EditorTemplatesAndCollectionsQuery,
  EmployeePreferencesTab,
} from 'gql/__generated__/graphql'

import DisplayOptions from '../DisplayOptions'
import { SORT_OPTIONS, generateBreadcrumbItems } from '../helper'
import { TemplatePickerContext } from '../hooks'
import * as s from './HeaderTemplate.module.scss'

const HeaderTemplate = ({
  dataCollections,
}: {
  dataCollections: EditorTemplatesAndCollectionsQuery | undefined
}) => {
  const [visibleMenu, setVisibleMenu] = useState(false)
  const { search, tab, setSearch, setCollection, order, setOrder, collection } = useContextSelector(
    TemplatePickerContext,
    (value) => value,
  )

  const collections = dataCollections?.data?.templateCollections

  const collectionProps = collection
    ? { id: collection?.id || '', name: collection?.name || '' }
    : undefined

  const breadcrumbItems = generateBreadcrumbItems(search, collectionProps, tab)
  const { run: onSearch } = useDebounceFn(
    (value?: string) => {
      setSearch(value)
    },
    { wait: 500 },
  )

  const onClickBreadcrumbs = (item: BreadcrumbItem) => {
    if (item.id === 'search') {
      return
    }

    setSearch('')
    if (!item.id) {
      setCollection(null)
    }
  }

  const menuOptions =
    collections?.map((item) => ({
      label: item.name,
      value: item.id,
    })) || []

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onClickMenu = ({ value }: IMenuOption<any>) => {
    const collection = collections?.find((item) => item.id === value)
    if (collection) {
      setCollection(collection)
      setVisibleMenu(false)
    }
  }

  return (
    <div className={s.root}>
      <div className={s.breadcrumbs}>
        <Breadcrumbs items={breadcrumbItems} onClickBreadcrumbs={onClickBreadcrumbs} />
        {collection?.id && (
          <Dropdown
            onVisibleChange={(value) => setVisibleMenu(value)}
            overlay={
              <div onClick={(e) => e.stopPropagation()}>
                <Menu
                  name='collections'
                  onClick={onClickMenu}
                  options={menuOptions}
                  value={collection.id}
                />
              </div>
            }
            placement='bottomLeft'
            styleType='clear'
            trigger={['click']}
            visible={visibleMenu}
          >
            <div className={s.icon}>
              <Icon name='otherDown' size={KitSize.S} />
            </div>
          </Dropdown>
        )}
      </div>
      <div className={s.filter}>
        {(collection?.id || tab !== EmployeePreferencesTab.collections) && <DisplayOptions />}
        {!search && (
          <SortPopover
            name='tasks'
            onChange={setOrder}
            options={SORT_OPTIONS}
            placement='bottomRight'
            size={KitSize.S}
            value={order}
          />
        )}
        {tab !== EmployeePreferencesTab.recently_used && (
          <Search name='templatePickerSearch' onChange={onSearch} size={KitSize.S} value={search} />
        )}
      </div>
    </div>
  )
}

export default HeaderTemplate
