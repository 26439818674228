import React, { useCallback } from 'react'

import { EditorActionUiEnum } from 'components/editor-v3/Editor/types'
import { AppMode } from 'services/Store/Project/enums'
import { IBlockMode } from 'services/Store/Project/types'
import { getEnumOption } from 'utils/enum'

import ControlItem from '../ControlItem'

interface IEditableProModeProps {
  mode: IBlockMode
  customMode: IBlockMode | null
  setAppMode: (value: AppMode) => void
}

const FILL_MODE_ACTION = getEnumOption('EditorActionUiEnum', EditorActionUiEnum.ELEMENT_FILL_MODE, {
  icon: 'builderContentModeSettings',
})

const EditableProMode: React.FC<IEditableProModeProps> = ({ mode, customMode, setAppMode }) => {
  const toggledMode = customMode?.editorMode === AppMode.fill ? AppMode.pro : AppMode.fill

  const onChangeEditable = useCallback(() => setAppMode(toggledMode), [setAppMode, toggledMode])

  if (mode.editorMode === AppMode.pro) {
    return (
      <ControlItem
        active={customMode?.editorMode === AppMode.fill}
        handler={onChangeEditable}
        option={FILL_MODE_ACTION}
      />
    )
  }

  return null
}

export default EditableProMode
