import { LayoutListSchemaType } from '@vedalib/editor/lib/brand'
import { CONFIG_ELEMENTS, LayoutListElementValue } from '@vedalib/editor/lib/elements'

import { DEMO_MODE } from 'services/Store/Project/constants'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './LayoutListElement'

const DEMO_VALUE: LayoutListElementValue = {
  items: [{ label: 'label1', value: 'id1' }],
}

const LayoutListDemo: React.FC<IElementDemoProps<LayoutListSchemaType>> = ({ styles, fonts }) => (
  <DemoWrapper>
    <Element
      element={{
        id: `${CONFIG_ELEMENTS.layoutList.type}-demo`,
        type: CONFIG_ELEMENTS.layoutList.type,
        value: DEMO_VALUE,
        style: {},
        font: {},
      }}
      font={fonts}
      mode={DEMO_MODE}
      styles={styles}
    />
  </DemoWrapper>
)

export default LayoutListDemo
