import * as R from 'ramda'
import { useEffect } from 'react'

import { useSelectionRect } from '../useSelectionRect'

const NAVIGATION_WIDTH = 220
const TOOLBAR_WIDTH = 300
const MARGIN = 16
const OFFSET = 4

export const useToolbarPosition = (ref: React.RefObject<HTMLDivElement>) => {
  const { start, end } = useSelectionRect()

  useEffect(() => {
    const update = () => {
      if (ref.current) {
        ref.current.style.position = 'absolute'
        ref.current.style.zIndex = '10000'
      }

      if (ref.current && start && end) {
        const { width, height } = ref.current.getBoundingClientRect()
        const y = R.clamp(
          0,
          window.innerHeight,
          start.y - OFFSET - height > 0 ? start.y - OFFSET : end.y + end.height + height + OFFSET,
        )

        const x = R.clamp(
          NAVIGATION_WIDTH + MARGIN,
          R.max(NAVIGATION_WIDTH + MARGIN, window.innerWidth - MARGIN - width - TOOLBAR_WIDTH),
          start.y - OFFSET - height > 0 ? start.x : end.x,
        )

        ref.current.style.top = `${y - height}px`
        ref.current.style.left = `${x}px`
      }
    }

    update()
    const resizeObserver = new ResizeObserver(update)
    if (ref.current) {
      resizeObserver.observe(ref.current)
    }

    return () => resizeObserver.disconnect()
  }, [start, end, ref])
}
