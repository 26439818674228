import { PermissionsKey } from '@vedalib/crud'
import React, { useCallback } from 'react'

import CommentForm from 'components/comments/CreateComment'
import AbsolutePortal from 'components/editor-v3/components/AbsolutePortal'
import { ABSOLUTE_PORTAL_GAP } from 'components/editor-v3/components/AbsolutePortal/constants'
import Employee from 'components/uiKit/Employee'
import { KitSize } from 'components/uiKit/KitTypes'
import {
  SORT_BY_STATUS,
  useEmployeesByPermissionInProject,
  usePermissions,
} from 'services/Permission/PermissionContext'
import { scrollProps } from 'services/Scroll/ScrollService'
import { setCommentForm } from 'services/Store/Project/actions'
import { useProjectContext, useProjectDispatch } from 'services/Store/Project/hooks'
import { getProjectId, getSection, getSectionId } from 'services/Store/Project/selectors'
import { selectCurrentEmployee } from 'services/Store/Users/selectors'
import { useAppSelector } from 'services/Store/hooks'

import {
  CANVAS_COMMENT_ANIMATION_IN,
  CANVAS_COMMENT_ANIMATION_OUT,
  getCanvasCommentName,
} from '../constants'
import * as s from './EditorCreateComment.module.scss'

const ICON_SIZE = 32

interface ICanvasCommentProps {
  blockId: string
  elementId?: string | null
}
const EditorCreateComment: React.FC<ICanvasCommentProps> = ({ blockId, elementId }) => {
  const dispatch = useProjectDispatch()
  const canComment = usePermissions(PermissionsKey.project_comments_c)
  const me = useAppSelector(selectCurrentEmployee)
  const projectId = useProjectContext(getProjectId)
  const sectionId = useProjectContext(getSectionId)
  const coverId = useProjectContext(getSection, { cover: true })?.id

  const { all } = useEmployeesByPermissionInProject({
    projectId,
    pk: PermissionsKey.project_employeesFilter_comments_mention,
    includeCommentator: true,
    excludeSelf: true,
    sort: SORT_BY_STATUS,
  })

  const handleSubmit = useCallback(() => dispatch(setCommentForm(null)), [dispatch])

  if (!canComment) {
    return null
  }

  return (
    <AbsolutePortal
      animate={CANVAS_COMMENT_ANIMATION_IN}
      initial={CANVAS_COMMENT_ANIMATION_OUT}
      name='commentForm'
      placement='leftTop'
      translateX={`-${ICON_SIZE + ABSOLUTE_PORTAL_GAP}px`}
      zIndex={1}
    >
      <div {...scrollProps(getCanvasCommentName(blockId, elementId))} className={s.root}>
        <div className={s.me}>
          <Employee employee={me} size={KitSize.M} styleType='avatar' />
        </div>
        <div className={s.input}>
          <CommentForm
            mentions={all}
            name='editorCreateComment'
            onSubmit={handleSubmit}
            payload={{ sectionId: sectionId || coverId, blockUuid: blockId, elementId }}
            autofocus
          />
        </div>
      </div>
    </AbsolutePortal>
  )
}

export default EditorCreateComment
