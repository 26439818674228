import { BaseRange } from 'slate'
import { createContext } from 'use-context-selector'

import { AccountMock } from 'components/uiKit/Employee'
import { NOOP } from 'constants/commonConstans'

import { SlateFormats } from './constants'

export type FormType = 'default' | 'link' | 'mention' | null

export type AppTextContextType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format: any
  readOnly?: boolean
  disabled?: boolean
  form: FormType
  setForm: (from: FormType) => void
  employees: AccountMock[]
  mentionsMap: Record<string, AccountMock>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdateFormat: (format: SlateFormats, value?: any) => void
  mentionSearch: string | null
  mentionTarget: BaseRange | null
  mentionIndex: number | null
}

const AppTextContext = createContext<AppTextContextType>({
  format: {},
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdateFormat: (_format, _value: any) => null,
  form: 'default',
  setForm: NOOP,
  employees: [],
  mentionSearch: null,
  mentionTarget: null,
  mentionIndex: null,
  mentionsMap: {},
})

export default AppTextContext
