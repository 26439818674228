import { useQuery, useMutation, useSubscription } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'

import {
  BlocksAllQueryVariables,
  BlocksGetByIdQueryVariables,
  EditorBlockPatchSubscriptionSubscription,
  EditorBlockSubscriptionSubscription,
  EditorBlocksArchivedQueryVariables,
} from 'gql/__generated__/graphql'
import { tasks } from 'gql/tasks/gql/queries'

import { gqlClient } from '../index'
import { editorTemplatesAllTotal, templatesGetAllQuery } from '../templates/gql/queries'
import { updateBlocksOrderCache } from './cache'
import {
  blockApplyVersion,
  blockPatchMutation,
  blocksCopy,
  blocksDeleteByIds,
  blocksMultiCreate,
  blocksRevert,
} from './gql/mutations'
import { blocksGetAllQuery, blocksGetById, getBlockPatches, blocksDeleted } from './gql/queries'
import { blockPatchSubscription, blocksSubscription } from './gql/subscriptions'

export const blockByIdRequest = (variables: BlocksGetByIdQueryVariables) => {
  const client = gqlClient.core
  return client.query({
    query: blocksGetById,
    variables,
    fetchPolicy: 'network-only',
  })
}

export const useBlocksGetAll = (variables: BlocksAllQueryVariables, skip?: boolean) =>
  useQuery(blocksGetAllQuery, {
    variables,
    skip,
    fetchPolicy: 'network-only',
    onError: (err) =>
      console.error('"useBlocksGetAll" fn is crashed on operation: "useQuery"', err),
  })

export const useBlocksMultiCreate = (sectionId?: string) =>
  useMutation(blocksMultiCreate, {
    update: (_cache, { data: item }) => {
      if (item?.data.blocksOrder && sectionId) {
        updateBlocksOrderCache(sectionId, item?.data.blocksOrder)
      }
    },
    onError: (err) =>
      console.error('"useBlocksMultiCreate" fn is crashed on operation: "useMutation"', err),
    refetchQueries: [
      String(getOperationName(blocksGetAllQuery)),
      String(getOperationName(templatesGetAllQuery)),
    ],
  })

export const useBlockPatchById = () => {
  return useMutation(blockPatchMutation, {
    onError: (err) =>
      console.error('"useBlockPatchById" fn is crashed on operation: "useMutation"', err),
  })
}

export const useBlocksDeleteByIds = () =>
  useMutation(blocksDeleteByIds, {
    refetchQueries: [
      String(getOperationName(blocksGetAllQuery)),
      String(getOperationName(editorTemplatesAllTotal)),
      String(getOperationName(tasks)),
    ],
    onError: (err) =>
      console.error('"useBlocksDeleteById" fn is crashed on operation: "useMutation"', err),
  })

export const useBlocksCopy = (sectionId: string) =>
  useMutation(blocksCopy, {
    update: (_cache, { data: item }) => {
      if (item?.data.blocksOrder) {
        updateBlocksOrderCache(sectionId, item?.data.blocksOrder)
      }
    },
    refetchQueries: [String(getOperationName(blocksGetAllQuery))],
    onError: (err) =>
      console.error('"useBlocksCopy" fn is crashed on operation: "useMutation"', err),
  })

export const useEditorBlockPatchSubscription = ({
  sectionId,
  onPatch,
}: {
  sectionId: string
  onPatch: (patch: EditorBlockPatchSubscriptionSubscription) => void
}) => {
  useSubscription(blockPatchSubscription, {
    variables: {
      sectionId: sectionId,
    },
    onData: ({ data }) => {
      if (data.data) {
        onPatch(data.data)
      }
    },
  })
}

export const useEditorBlocksSubscription = ({
  sectionId,
  onData,
}: {
  sectionId: string
  onData: (data: EditorBlockSubscriptionSubscription['data']) => void
}) => {
  useSubscription(blocksSubscription, {
    variables: {
      sectionId: sectionId,
    },
    onData: ({ data }) => {
      if (data.data) {
        onData(data.data.data)
      }
    },
  })
}

export const useBlockPatches = (uuid: string) =>
  useQuery(getBlockPatches, {
    variables: { uuid },
    fetchPolicy: 'cache-and-network',
  })

export const useBlockApplyVersion = () =>
  useMutation(blockApplyVersion, {
    onError: (err) =>
      console.error('"useBlockApplyVersion" fn is crashed on operation: "useMutation"', err),
  })

export const useBlocksGetDeleted = (variables: EditorBlocksArchivedQueryVariables) =>
  useQuery(blocksDeleted, {
    variables,
    fetchPolicy: 'network-only',
  })

export const useBlocksRevert = (sectionId?: string) =>
  useMutation(blocksRevert, {
    refetchQueries: [
      String(getOperationName(editorTemplatesAllTotal)),
      String(getOperationName(tasks)),
    ],
    update: (_cache, { data: item }) => {
      if (item?.data.blocksOrder && sectionId) {
        updateBlocksOrderCache(sectionId, item?.data.blocksOrder)
      }
    },
    onError: (err) =>
      console.error('"useBlockApplyVersion" fn is crashed on operation: "useMutation"', err),
  })
