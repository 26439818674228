import lodash from 'lodash'
import { useCallback, useRef, useEffect } from 'react'
import { useContextSelector } from 'use-context-selector'

import { FormContext } from 'components/controls/Form'
import Button from 'components/uiKit/Button'
import { IButtonPropsType } from 'components/uiKit/Button/Button'
import { KitSize } from 'components/uiKit/KitTypes'

interface ISubmitButtonProps {
  name: string
  label: string | JSX.Element
  fluid?: boolean
  loading?: boolean
  styleType?: IButtonPropsType['styleType']
  disabled?: boolean
  resettable?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (a: any) => void
  size?: KitSize
}

const SubmitButton = ({
  name,
  label,
  fluid,
  loading,
  styleType,
  disabled,
  size,
  resettable,
  onClick,
}: ISubmitButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const onSubmit = useContextSelector(FormContext, (state) => state.submit)
  const submitting = useContextSelector(FormContext, (state) => state.submitting)
  const valid = useContextSelector(FormContext, (state) => state?.formMeta?.valid)
  const resetFields = useContextSelector(FormContext, (state) => state.resetFields)
  const initialValues = useContextSelector(FormContext, (state) => state.initialValues)
  const emptyValues = lodash.isEmpty(initialValues)

  const handleClick = useCallback(
    async (e: React.MouseEvent | React.KeyboardEvent<HTMLButtonElement>) => {
      e.preventDefault()
      if (disabled) {
        return
      }

      const a = await onSubmit?.()
      onClick?.(a)
      if (resettable) {
        resetFields()
      }
    },
    [onClick, onSubmit, resetFields, resettable, disabled],
  )

  const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter') {
      handleClick(e)
    }
  }

  useEffect(() => {
    if (emptyValues && buttonRef.current) {
      buttonRef.current.focus()
    }
  }, [emptyValues])

  return (
    <Button
      disabled={!valid || disabled}
      fluid={fluid}
      loading={loading || submitting}
      name={name}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      ref={buttonRef}
      size={size}
      styleType={styleType}
      type='button'
    >
      {label}
    </Button>
  )
}

export default SubmitButton
