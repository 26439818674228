import {
  CollapseIconPosition,
  CourseStructureItem,
  ProgressIconPosition,
} from '@vedalib/editor/lib/elements'
import cn from 'classnames'
import { useMemo } from 'react'

import { Description } from 'components/editor-v3/cource/controls/CourseSidebar/components/Description'
import { useCourseSidebarData } from 'components/editor-v3/cource/controls/CourseSidebar/context/selectors'
import StructureNodeCurseStyled from 'components/editor-v3/cource/controls/CourseSidebar/styled/StructureNodeCurseStyled'
import { getIconData } from 'components/editor-v3/cource/controls/CourseSidebar/utils'
import { ISectionTreeNode } from 'components/sections/ProjectStructure/utils'
import CollapseIcon from 'components/uiKit/Collapse/CollapseIcon'

import SectionStatus from '../SectionStatus/SectionStatus'
import { StructureItemCourse } from '../StructureItemCourse'
import * as s from './StructureNodeCourse.module.scss'

export const LEVEL_INDENT = 12
interface ITreeNodeProps {
  toggle?(active?: boolean): void
  open: boolean
  node: ISectionTreeNode<CourseStructureItem>
  onClick?: () => void
}

const StructureNodeCourse: React.FC<ITreeNodeProps> = ({ open, node, onClick, toggle }) => {
  const { item } = node
  const { styles, font, getActiveSection, getDisabledSection } = useCourseSidebarData()
  const active = getActiveSection(item)
  const nestedOffset = item.lvl * LEVEL_INDENT
  const disabled = useMemo(() => getDisabledSection(item.id), [getDisabledSection, item.id])
  const iconPosition = [CollapseIconPosition.right, CollapseIconPosition.rightOutside].includes(
    styles.collapse.collapsePosition as CollapseIconPosition,
  )
    ? 'right'
    : 'left'
  const customIconCollapse = open
    ? styles.collapseIcon.backgroundOpen
    : styles.collapseIcon.backgroundImage

  const collapseIcon = (
    <div className={s.collapseIcon} onClick={() => toggle?.()}>
      {item.isChapter &&
        toggle &&
        styles.collapse.collapsePosition !== CollapseIconPosition.none &&
        (customIconCollapse ? (
          <div className={s.customIcon} style={{ backgroundImage: customIconCollapse }} />
        ) : (
          <CollapseIcon iconPosition={iconPosition} open={open} />
        ))}
    </div>
  )

  const onClickHandler = () => {
    if (disabled) {
      return
    }

    if (item.isChapter) {
      toggle?.()
    } else {
      onClick?.()
    }
  }

  const collapseOffset = styles?.margins.offsetFromCollapse
  const statusOffset = styles?.margins.offsetFromProgress
  const { showIcon } = getIconData({ type: item.type, styles, font, disabled, nestedOffset })

  return (
    <StructureNodeCurseStyled
      $active={active}
      $disabled={disabled}
      $isChapter={item.isChapter}
      $open={open}
      $showIcon={showIcon}
      $styles={styles}
      onClick={onClickHandler}
    >
      {/* row1 */}
      {styles.collapse.collapsePosition === CollapseIconPosition.leftOutside && (
        <div className={s.collapse} style={{ marginRight: collapseOffset }}>
          {collapseIcon}
        </div>
      )}
      {styles.verticalProgress.progressPosition === ProgressIconPosition.left && (
        <div className={s.sectionStatus} style={{ marginRight: statusOffset }}>
          <SectionStatus disabled={disabled} node={node} open={open} />
        </div>
      )}
      {styles.collapse.collapsePosition === CollapseIconPosition.left && (
        <div className={s.nodeIndent} style={{ marginRight: collapseOffset }}>
          {collapseIcon}
        </div>
      )}
      <StructureItemCourse disabled={disabled} item={node.item} nestedOffset={nestedOffset} />
      {styles.collapse.collapsePosition === CollapseIconPosition.right && (
        <div className={cn(s.nodeIndent, s.collapse)} style={{ marginLeft: collapseOffset }}>
          {collapseIcon}
        </div>
      )}
      {styles.verticalProgress.progressPosition === ProgressIconPosition.right && (
        <div className={s.sectionStatus} style={{ marginLeft: statusOffset }}>
          <SectionStatus disabled={disabled} node={node} open={open} />
        </div>
      )}
      {styles.collapse.collapsePosition === CollapseIconPosition.rightOutside && (
        <div className={cn(s.nodeIndent, s.collapse)} style={{ marginLeft: collapseOffset }}>
          {collapseIcon}
        </div>
      )}
      {/* row2 */}
      {styles?.value.showDescription && (
        <div className={s.description}>
          <Description childrenChapter={node.children?.length || 0} font={font} item={item} />
        </div>
      )}
    </StructureNodeCurseStyled>
  )
}

export default StructureNodeCourse
