import cn from 'classnames'
import React, { useState } from 'react'

import { PLAYBACK_RATE_OPTIONS } from 'components/MediaPlayer/constants'
import DropdownCourse from 'components/editor-v2/EditorElements/courseKit/DropDownCourse'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'

import * as s from './PlaybackRateSelect.module.scss'

interface IPlaybackRateProps {
  value: number
  onChange: (playbackRate: number) => void
  container: () => HTMLElement
}

const PlaybackRateSelect: React.FC<IPlaybackRateProps> = ({ value, onChange, container }) => {
  const [visible, setVisible] = useState(false)
  const close = () => setVisible(false)

  return (
    <DropdownCourse
      container={container()}
      name='rateVideo'
      onVisibleChange={setVisible}
      overlay={
        <div className={s.root} onClick={close}>
          {PLAYBACK_RATE_OPTIONS.map((item) => (
            <div
              className={cn(s.item, { [s.active]: item.value === value })}
              key={item.value}
              onClick={() => onChange(item.value)}
            >
              {item.label}
            </div>
          ))}
        </div>
      }
      placement='bottom-end'
      visible={visible}
    >
      <Icon name='playLoading' size={KitSize.S} />
    </DropdownCourse>
  )
}

export default PlaybackRateSelect
