import { TestFeedbackFontSchemaType, TestFeedbackSchemaType } from '@vedalib/editor/lib/brand'
import {
  CONFIG_ELEMENTS,
  TestFeedbackElementValue,
  TestFeedbackType,
} from '@vedalib/editor/lib/elements'
import { textToRtValue } from '@vedalib/rich-text'
import { SlateElementType } from '@vedalib/rich-text'

import { DEMO_MODE } from 'services/Store/Project/constants'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { IElementDemoProps } from '../../elements.types'
import Element from './TestFeedbackElement'
import * as s from './TestFeedbackElement.module.scss'

const INFO_DEMO_VALUE: TestFeedbackElementValue = {
  show: true,
  type: TestFeedbackType.info,
  info: [
    ...textToRtValue(t('elements.testFeedback.value.infoTitle'), SlateElementType.heading3),
    ...textToRtValue(t('elements.testFeedback.value.infoTitle')),
  ],
  success: [
    ...textToRtValue(t('elements.testFeedback.value.successTitle'), SlateElementType.heading3),
    ...textToRtValue(t('elements.testFeedback.value.successTitle')),
  ],
  fail: [
    ...textToRtValue(t('elements.testFeedback.value.failTitle'), SlateElementType.heading3),
    ...textToRtValue(t('elements.testFeedback.value.failTitle')),
  ],
}

const VALIDATION_DEMO_VALUE: TestFeedbackElementValue = {
  show: true,
  type: TestFeedbackType.validation,
  info: [
    ...textToRtValue(t('elements.testFeedback.value.infoTitle'), SlateElementType.heading3),
    ...textToRtValue(t('elements.testFeedback.value.infoTitle')),
  ],
  success: [
    ...textToRtValue(t('elements.testFeedback.value.successTitle'), SlateElementType.heading3),
    ...textToRtValue(t('elements.testFeedback.value.successTitle')),
  ],
  fail: [
    ...textToRtValue(t('elements.testFeedback.value.failTitle'), SlateElementType.heading3),
    ...textToRtValue(t('elements.testFeedback.value.failTitle')),
  ],
}

const TestFeedbackDemo: React.FC<
  IElementDemoProps<TestFeedbackSchemaType, TestFeedbackFontSchemaType>
> = ({ styles, fonts }) => (
  <div className={s.demo}>
    <DemoWrapper>
      <Element
        element={{
          id: `${CONFIG_ELEMENTS.testFeedback.type}-demo`,
          type: CONFIG_ELEMENTS.testFeedback.type,
          value: INFO_DEMO_VALUE,
          font: {},
          style: {},
        }}
        font={fonts}
        mode={DEMO_MODE}
        state={{ isValid: true }}
        styles={styles}
      />
    </DemoWrapper>
    <DemoWrapper>
      <Element
        element={{
          id: `${CONFIG_ELEMENTS.testFeedback.type}-demo`,
          type: CONFIG_ELEMENTS.testFeedback.type,
          value: VALIDATION_DEMO_VALUE,
          font: {},
          style: {},
        }}
        font={fonts}
        mode={DEMO_MODE}
        state={{ isValid: true }}
        styles={styles}
      />
    </DemoWrapper>
    <DemoWrapper>
      <Element
        element={{
          id: `${CONFIG_ELEMENTS.testFeedback.type}-demo`,
          type: CONFIG_ELEMENTS.testFeedback.type,
          value: VALIDATION_DEMO_VALUE,
          font: {},
          style: {},
        }}
        font={fonts}
        mode={DEMO_MODE}
        state={{ isValid: false }}
        styles={styles}
      />
    </DemoWrapper>
  </div>
)

export default TestFeedbackDemo
