import { UserInfo } from './types'

export const initUserGuiding = () => {
  const script = document.createElement('script')
  script.innerHTML = `
  (function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n,arguments])};};var m=['previewGuide','finishPreview','track','identify','hideChecklist','launchChecklist'];for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','ZP5832887CPID');
`
  document.head.appendChild(script)
}

export const setUserInfo = (info: UserInfo) => {
  // @ts-expect-error userGuiding is a global variable
  const userGuiding = window['userGuiding']
  if (!userGuiding) {
    return
  }

  const permissionsMap = info.employeePermissions.reduce(
    (acc, permission) => ({ ...acc, [`permission_${permission}`]: true }),
    {},
  )
  userGuiding.identify(info.userId, {
    email: info.userEmail,
    name: info.employeeName,
    role: info.employeeRole,
    ...permissionsMap,
    company: {
      name: info.companyName,
      id: info.companyId,
    },
  })
}
