import { SystemRole } from '@vedalib/crud'
import cn from 'classnames'
import React from 'react'

import { CompanyRole, EmployeeStatusEnum } from 'gql/__generated__/graphql'
import { selectEmployeeById } from 'services/Store/Users/selectors'
import { useAppSelector } from 'services/Store/hooks'
import { throwLocal } from 'utils/dev'
import { getEnumLabel } from 'utils/enum'
import { testProps } from 'utils/test/qaData'

import { Tooltip } from '../Dropdown'
import { KitSize } from '../KitTypes'
import { Typography } from '../Typography'
import * as s from './Employee.module.scss'
import EmployeeAvatar from './EmployeeAvatar'
import { AvatarMock } from './EmployeeAvatar/EmployeeAvatar'

type IEmployeeByIdProps = Omit<IAccountProps, 'employee'> & { id?: string }

export type EmployeeType = 'info' | 'interactive'

export type AccountMock = {
  id: string
  role?: CompanyRole | SystemRole | null
  kUser: {
    avatar?: AvatarMock | null
    name: string
    email: string
  }
  kuserId?: string | null
  isArchived?: boolean | null
  status?: string | null
  expireData?: string | null
}
interface IAccountProps {
  employee?: AccountMock | null
  styleType?: 'base' | 'name' | 'avatar' | 'full' | 'mention' | 'link'
  size?: KitSize
  onClick?: (account: AccountMock) => void
  disabled?: boolean
  active?: boolean
  error?: boolean
  type?: EmployeeType
  testData?: string
  grey?: boolean
}

const Employee: React.FC<IAccountProps> = ({
  employee,
  styleType = 'base',
  size = KitSize.M,
  onClick: onClickProp,
  disabled = false,
  active = false,
  error,
  type = 'interactive',
  testData,
  grey,
}) => {
  if (!employee || !employee.kUser) {
    throwLocal('Employee component: employee prop is required')
    return null
  }
  const {
    role,
    isArchived,
    kUser: { email, name },
  } = employee
  // const name = employee?.kUser?.name || userName
  const onClick = () => {
    if (type === 'interactive' && employee && !disabled) {
      onClickProp?.(employee)
    }
  }
  if (!employee) {
    return null
  }

  if (styleType === 'name') {
    const cnName = cn(s.name, s[size])
    return (
      <span className={cnName} onClick={onClick}>
        {name}
      </span>
    )
  }

  if (styleType === 'avatar') {
    return (
      <EmployeeAvatar
        active={active}
        disabled={disabled}
        employee={employee}
        grey={grey}
        onClick={onClick}
        size={size}
      />
    )
  }

  const getRole = () => {
    return role ? getEnumLabel('CompanyRole', role) : email
  }

  if (styleType === 'full') {
    return (
      <span className={s.full} onClick={onClick}>
        <EmployeeAvatar
          active={active}
          disabled={disabled}
          employee={employee}
          size={size}
          {...testProps({
            el: 'employee',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            name: typeof name === 'string' ? name : (name as any)?.props.text,
            role,
            testData,
          })}
          type={type}
        />
        <div className={s.fullRight}>
          <Tooltip mouseEnterDelay={0.5} offset={[-5, 0]} overlay={name}>
            <div className={s.fullName}>{name}</div>
          </Tooltip>
          <div className={s.fullRole}>{getRole()}</div>
        </div>
      </span>
    )
  }

  if (styleType === 'mention') {
    return (
      <span className={cn(s.mention, { [s.removed]: isArchived })} onClick={onClick}>
        @{name}
      </span>
    )
  }

  const cnDefault = cn(s.native, s[size])
  const cnDefaultName = cn(s.defaultName, s[type], {
    [s.empty]: employee.status !== EmployeeStatusEnum.ACTIVE || employee.isArchived,
    [s.disabled]: disabled,
    [s.error]: error,
  })
  return (
    <div
      className={cnDefault}
      onClick={onClick}
      {...testProps({
        el: 'employee',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        name: typeof name === 'string' ? name : (name as any)?.props.text,
        role,
        testData,
      })}
    >
      <EmployeeAvatar active={active} disabled={disabled} employee={employee} size={size} />

      <div className={cnDefaultName}>
        <Typography name='employeeName' rows={1} styleType={styleType} tooltip={name}>
          {name}
        </Typography>
      </div>
    </div>
  )
}

export const EmployeeById: React.FC<IEmployeeByIdProps> = ({ id, ...props }) => {
  const employee = useAppSelector((state) => selectEmployeeById(state, id))

  if (!employee) {
    return null
  }

  return <Employee employee={employee} {...props} />
}

export default Employee
