import { ApolloProvider } from '@apollo/client'
import { gqlClient } from 'gql'
import moment from 'moment'
import 'moment/locale/ru'
import { PureComponent } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { Redirect } from 'react-router'
import { Router, Route, Switch } from 'react-router-dom'

import { Banner } from 'components/banner/Banner'
import { UppyProvider } from 'components/finder-v2/UppyContext'
import { FixedElementsProvider } from 'components/uiKit/FixedElementsContainer'
import { ModalProvider } from 'components/uiKit/Modal'
import NotificationRoot from 'components/uiKit/Notification/NotificationRoot'
import { PATHS, SCORM_PATHS } from 'constants/paths'
import store from 'services/Store/store'

import AppScormPreview from '../components/pages/ScormPreview/AppScormPreview'
import { FEATURES } from '../config/features'
import i18n from '../services/Translation/i18n'
import history from './history'
import NotFound from './pages/NotFound'
import AppRoutes, { UserRoute } from './routes/App'
import AuthRoutes, { GuestRoute } from './routes/Auth'
import DevRoutes from './routes/Dev'
import ErrorRoutes from './routes/Error'
import LmsRoutes from './routes/Lms'
import PublicRoutes from './routes/Public'

moment.locale(i18n.language)

class Application extends PureComponent {
  render() {
    return (
      // <StrictMode>
      <Provider store={store}>
        <HelmetProvider>
          <I18nextProvider i18n={i18n}>
            <ApolloProvider client={gqlClient.core}>
              <Router history={history}>
                <Banner>
                  <UppyProvider>
                    <FixedElementsProvider>
                      <ModalProvider>
                        <Switch>
                          <Route component={PublicRoutes} path={PATHS.public} />
                          <Route component={AppScormPreview} path={SCORM_PATHS.mainV2} />
                          {FEATURES.lms && <Route component={LmsRoutes} path={PATHS.lms} />}
                          {FEATURES.dev && <Route component={DevRoutes} path={PATHS.dev} />}
                          <GuestRoute component={AuthRoutes} path={PATHS.auth} />
                          <UserRoute component={AppRoutes} path={PATHS.app} />
                          <Route component={ErrorRoutes} path={PATHS.error} />
                          <Redirect from='/' to={PATHS.auth} exact />
                          <Redirect from='/index.html' to={PATHS.auth} exact />
                          <Route component={NotFound} />
                        </Switch>
                      </ModalProvider>
                    </FixedElementsProvider>
                    <NotificationRoot />
                  </UppyProvider>
                </Banner>
              </Router>
            </ApolloProvider>
          </I18nextProvider>
        </HelmetProvider>
      </Provider>
      // </StrictMode>
    )
  }
}

export default Application
