import { RefObject, useCallback, useEffect, useState } from 'react'

import { OVERFLOW_OFFSET } from './styled/TabItemsScrollStyled'

interface IUseTabScrollProps {
  scrollTabsRef: RefObject<HTMLDivElement | null>
  listTabRef: RefObject<HTMLUListElement | null>
  activeTabRef: RefObject<HTMLElement | null>
  activeTabId: string
  deps: unknown[]
}

export const useTabScroll = ({
  scrollTabsRef,
  listTabRef,
  activeTabRef,
  activeTabId,
  deps,
}: IUseTabScrollProps) => {
  const [hasPrevScroll, setHasPrevScroll] = useState(false)
  const [hasNextScroll, setHasNextScroll] = useState(false)

  const scrollToVisibleArea = useCallback(() => {
    if (scrollTabsRef.current && activeTabRef.current) {
      const container = scrollTabsRef.current
      const target = activeTabRef.current

      const containerRect = container.getBoundingClientRect()
      const targetRect = target.getBoundingClientRect()

      const isVisibleHorizontally =
        targetRect.left >= containerRect.left && targetRect.right <= containerRect.right

      const isVisibleVertically =
        targetRect.top >= containerRect.top && targetRect.bottom <= containerRect.bottom

      if (!isVisibleHorizontally || !isVisibleVertically) {
        let scrollLeft = container.scrollLeft
        let scrollTop = container.scrollTop

        if (targetRect.left < containerRect.left) {
          scrollLeft -= containerRect.left - targetRect.left + OVERFLOW_OFFSET
        } else if (targetRect.right > containerRect.right) {
          scrollLeft += targetRect.right - containerRect.right + OVERFLOW_OFFSET
        }

        if (targetRect.top < containerRect.top) {
          scrollTop -= containerRect.top - targetRect.top + OVERFLOW_OFFSET
        } else if (targetRect.bottom > containerRect.bottom) {
          scrollTop += targetRect.bottom - containerRect.bottom + OVERFLOW_OFFSET
        }

        container.scrollTo({ top: scrollTop, left: scrollLeft, behavior: 'smooth' })
      }
    }
  }, [activeTabRef, scrollTabsRef])

  // scroll to active tab
  useEffect(() => {
    scrollToVisibleArea()
  }, [activeTabId, scrollToVisibleArea])

  // show icons if tabs are scrollable
  useEffect(() => {
    const abortController = new AbortController()

    const updateScrollState = () => {
      if (!scrollTabsRef.current || !listTabRef.current) {
        return
      }

      const container = scrollTabsRef.current
      const content = listTabRef.current

      const isScrollableHorizontally = content.scrollWidth > container.clientWidth
      // const isScrollableVertically = content.scrollHeight > container.clientHeight

      const hasPrevScroll = isScrollableHorizontally
        ? container.scrollLeft > 0
        : container.scrollTop > 0

      const hasNextScroll = isScrollableHorizontally
        ? container.scrollLeft < container.scrollWidth - container.clientWidth
        : container.scrollTop < container.scrollHeight - container.clientHeight

      setHasPrevScroll(hasPrevScroll)
      setHasNextScroll(hasNextScroll)
    }

    const onScroll = (_event: Event) => updateScrollState()

    updateScrollState()

    scrollTabsRef.current?.addEventListener('scroll', onScroll, { signal: abortController.signal })

    return () => {
      abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTabsRef, listTabRef, ...deps])

  return { hasPrevScroll, hasNextScroll }
}
