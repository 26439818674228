import React, { useMemo, useRef } from 'react'

import ErrorBoundary from 'components/editor-v2/EditorElements/decorators/ErrorBoundary'
import { getNode } from 'components/editor-v3/context/EditorContext/selectors/block'
import { isCommonNode } from 'components/editor-v3/types/data.guards'
import { NodeType } from 'components/editor-v3/types/date.enums'
import { EMPTY_ARRAY, NOOP } from 'constants/commonConstans'
import { t } from 'services/Translation'

import Absolute from '../Absolute'
import ColumnContainer from '../Column/ColumnContainer'
import RowContainer from '../Row/RowContainer'
import ShellContainer from '../Shell/ShellContainer'
import * as s from './NodeContainer.module.scss'
import { NodeProps, FCNode } from './types'

const BLOCK_COMPONENTS: Record<NodeType, FCNode> = {
  [NodeType.absolute]: Absolute,
  [NodeType.shell]: ShellContainer,
  [NodeType.row]: RowContainer,
  [NodeType.column]: ColumnContainer,
}

const NODE_ORDER = [NodeType.absolute, NodeType.column, NodeType.row, NodeType.shell]

const NodeContainer: React.FC<NodeProps<'root'>> = ({ id, block, mode, level = 0 }) => {
  const ref = useRef<HTMLDivElement>(null)
  const node = getNode(block, id)
  const Component = (node && BLOCK_COMPONENTS[node?.type]) || NOOP

  const childNodes = useMemo(() => {
    const childNodes = node?.children?.map((id) => getNode(block, id)) || EMPTY_ARRAY
    childNodes?.sort((a, b) => {
      const aI = NODE_ORDER.indexOf(a.type)
      const bI = NODE_ORDER.indexOf(b.type)
      return aI - bI
    })
    return childNodes
  }, [block, node])

  return (
    <Component block={block} level={level} mode={mode} node={node} ref={ref}>
      {isCommonNode(node) &&
        childNodes.map((node) => (
          <NodeContainer block={block} id={node.id} key={node.id} level={level + 1} mode={mode} />
        ))}
    </Component>
  )
}

const NodeContainerErrorBoundary: React.FC<NodeProps<'root'>> = (props) => (
  <ErrorBoundary errorChildren={<div className={s.error}>{t('editor.systemBlockError')}</div>}>
    <NodeContainer {...props} />
  </ErrorBoundary>
)

export default React.memo(NodeContainerErrorBoundary)
