import React from 'react'

import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { t } from 'services/Translation'

import * as s from './PasswordTips.module.scss'

type Validator = (value: string) => boolean

type Rule = {
  label: string
  validate: Validator
  value: string
}

const caractersLengthValidator: Validator = (value) => {
  return value?.length > window.PLATFORM.passwordMinLength - 1
}

const upperCaseValidator: Validator = (value) => {
  return /[A-Z]/.test(value)
}

const loverCaseValidator: Validator = (value) => {
  return /[a-z]/.test(value)
}

const numberValidator: Validator = (value) => {
  return /[0-9]/.test(value)
}

const specialCharacterValidator: Validator = (value) => {
  // eslint-disable-next-line no-useless-escape
  return /[!"#$%&'()*+,-./:;<=>?@\[\\\]^_`{|}~]/.test(value)
}

const RULES: Rule[] = [
  {
    label: t('input.error.passwordLength', { value: window.PLATFORM.passwordMinLength }),
    validate: caractersLengthValidator,
    value: 'passwordLength',
  },
  {
    label: t('input.error.passwordUpperCase'),
    validate: upperCaseValidator,
    value: 'passwordUpperCase',
  },
  {
    label: t('input.error.passwordLowerCase'),
    validate: loverCaseValidator,
    value: 'passwordLowerCase',
  },
  {
    label: t('input.error.passwordNumber'),
    validate: numberValidator,
    value: 'passwordNumber',
  },
  {
    label: t('input.error.passwordSymbolTip'),
    validate: specialCharacterValidator,
    value: 'passwordSymbolTip',
  },
]

interface IPasswordTipsProps {
  value: string
}
const PasswordTips: React.FC<IPasswordTipsProps> = ({ value }) => {
  return (
    <div className={s.root}>
      {RULES.map((rule) => (
        <div className={s.rule} key={rule.value}>
          <Icon
            name={rule.validate(value || '') ? 'passwordSuccess' : 'passwordFail'}
            size={KitSize.S}
          />
          <span>{rule.label}</span>
        </div>
      ))}
    </div>
  )
}

export default PasswordTips
